import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { useParams } from "react-router";
import Loader from "../../Components/Loader"

function User_search() {
   document.title = "Administration | Seriti - Search User";
   const { group_id } = useParams();//this id coming from group default page Users tab
   // console.log(group_id)
   const [username, setUsername] = useState("");
   const [firstname, setFirstname] = useState("");
   const [surname, setSurname] = useState("");
   const [id_number, setIdnumber] = useState("");
   const [allGroup, setAllGroup] = useState([]);
   const [group, setGroup] = useState('');
   const [branch, setBranch] = useState('');
   const [allBranch, setAllBranch] = useState([]);
   const [email, setEmail] = useState("");
   const [searchData, setSearchData] = useState([]);
   const [isShown, setIsShown] = useState(false);
   const [btn_grpDetails, setbtn_grpDetails] = useState(false);

   const [groupText, setGroupText] = useState('select a group first');
   const [loader, setLoader] = useState(false);
   // console.log(btn_grpDetails)
   //---------------------onclick of search new table shows------------------------//
   const handleClick = event => {
      // 👇️ toggle shown state
      //  setIsShown(current => !current);
      // 👇️ or simply set it to true
      setIsShown(true);
   };

   //----------------------------dropdown of group----------------------------------// 
   useEffect(() => {
      //console.log(collection);
      axios
         .get("index/group")
         .then((response) => {
            // console.log(response)
            setAllGroup(response.data.data);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
         })
         .catch((response) => {
            //alert(response.data);
         });
   }, []);

   //------------------dropdown of branch depends on group---------------------------// 
   useEffect(() => {
      if (group !== '') {
         axios
            .get("index/branch/" + group)
            .then((response) => {
               // console.log(response)
               setAllBranch(response.data.data);
            })
            .catch((response) => {
               //alert(response.data);
            });
      }
   }, [group]);
   // console.log(group_id);
   //--------------search data if group id coming from group default page-------------// 
   useEffect(() => {
      if (group_id > 0) {
         // alert("group id : "+ group_id)
         const payload = {
            group_id: group_id
         };
         axios
            .post("/admin/search_user", payload)
            .then((response) => {
               if (response.data.status === 200) {
                  // console.log("response : ",response.data.data);
                  setbtn_grpDetails(true)
                  setIsShown(true)
                  setGroup(group_id);
                  if(group_id > 0) setGroupText('Select a Branch')
                  setSearchData(response.data.data);
               }
            })
            .catch((response) => {
               // console.log("response : "+response.data);
              // alert(response.data);
            });
      }
   }, [group_id]);
   // console.log(isShown);
   //----------------------------searching users-------------------------------------//
   const handleSubmit = (e) => {
      // setGroup(group)
      e.preventDefault();
      setLoader(true);
      const payload = {
         username: username,
         firstname: firstname,
         lastname: surname,
         email: email,
         id_number: id_number,
         division: group,
         department: branch,
      };
      // console.log(payload)
      axios
         .post("/admin/search_user", payload)
         .then((response) => {
            setLoader(false);
            if (response.data.status === 200) {
               // console.log("response : ",response.data.data);
               if(group_id === 0)  setbtn_grpDetails(false)
               setSearchData(response.data.data);
            }
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
         })
         .catch((response) => {
            setLoader(false);
            // alert(response.data);
         });
   };

   // console.log(searchData);
   return (
      <>
         <Admin_menu />
         {loader ? <Loader /> : null}
         <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
            <tbody>
               <tr valign="top" className="bg-white">
                  <td></td>
                  <td width="100%" className="bg-white">
                     <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                     <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                        <tbody>
                           <tr>
                              <td valign="top" align="center">
                                 <form method="post" action="" onSubmit={handleSubmit} >
                                    <input type="hidden" name="command" value="Search" />
                                    <table className="defaultTbl" width="500" cellSpacing="1">
                                       <tbody>
                                          <tr>
                                             <th colSpan="2" className="text-center">List Users</th>
                                          </tr>
                                          <tr id="dept_row">
                                             <td className="caption">Username</td>
                                             <td>
                                                <input type="text" name="username" id="username" autoComplete="off" className="sectext text-uppercase" value={username} onChange={(e) => { setUsername(e.target.value) }} />
                                             </td>
                                          </tr>
                                          <tr id="dept_row">
                                             <td className="caption">Last name</td>
                                             <td><input type="text" name="lastname" id="lastname" value={surname} className="text-uppercase" onChange={(e) => { setSurname(e.target.value) }} /></td>
                                          </tr>
                                          <tr id="dept_row">
                                             <td className="caption">Firstname</td>
                                             <td><input type="text" name="firstname" id="firstname" value={firstname} className="text-uppercase" onChange={(e) => { setFirstname(e.target.value) }} /></td>
                                          </tr>
                                          <tr id="dept_row">
                                             <td className="caption">ID/ PASSPORT</td>
                                             <td><input type="text" name="id_number" id="id_number" value={id_number} className="text-uppercase" onChange={(e) => { setIdnumber(e.target.value) }} /></td>
                                          </tr>
                                          <tr id="div_row">
                                             <td className="caption">Home Group</td>
                                             <td id="div_container">
                                                <select name="division" id="division" className="text-uppercase" value={group} onChange={(e) =>
                                                   setGroup(e.target.value)} style={{ width: '382px' }}>
                                                   <option value="0">-- Select a group --</option>
                                                   {allGroup.map((item) => {
                                                      return (
                                                         <option key={item.id} value={item.id} >
                                                            {item.name}
                                                         </option>
                                                      );
                                                   })}
                                                </select>
                                             </td>
                                          </tr>
                                          <tr id="dept_row">
                                             <td className="caption">Home Branch</td>
                                             <td id="dept_container">
                                                <select name="department" id="department" className="text-uppercase" value={branch} onChange={(e) =>
                                                   setBranch(e.target.value)} style={{ width: '382px' }}>
                                                   {/* onChange = {(e) => setBranch(e.target.value)} */}
                                                   <option value="0">-- {groupText} --</option>
                                                   {allBranch.map((item) => {
                                                      return (
                                                         <option key={item.id} value={item.id} >
                                                            {item.name}
                                                         </option>
                                                      );
                                                   })}
                                                </select>
                                             </td>
                                          </tr>
                                          <tr id="dept_row">
                                             <td className="caption">Email</td>
                                             <td><input type="text" name="email" id="email" value={email} className="text-uppercase" onChange={(e) => { setEmail(e.target.value) }} /></td>
                                          </tr>
                                          <tr>
                                             <td colSpan="2" className="caption text-center" ><input type="submit" name="command" value="Search" onClick={handleClick} /></td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </form>
                                 <br />
                                 {isShown && (
                                    <form method="post">
                                       <table className="defaultTbl" width="800">
                                          <tbody>

                                             <tr>
                                                <th colSpan="10" className="text-center">Results of User Search</th>
                                             </tr>
                                             <tr>
                                                <td className="caption" width="50"></td>
                                                <td className="caption">Username</td>
                                                <td className="caption">Lastname</td>
                                                <td className="caption">Firstname</td>
                                                <td className="caption">ID/ PASSPORT</td>
                                                <td className="caption">Group</td>
                                                <td className="caption">Branch</td>
                                                <td className="caption">email</td>
                                                <td className="caption">Last Login</td>
                                                <td className="caption">Deactivation date</td>
                                             </tr>
                                             {
                                                searchData && searchData.length ? (
                                                   searchData.map((item, index) => {
                                                      return (
                                                         <tr key={index}>
                                                            <td>
                                                               <input type="hidden" name="User" value={item.id} />
                                                               <input type="hidden" name="user_id" value={item.id} />
                                                               <input type="hidden" name="affiliate_id" value={item.affiliate_id} />
                                                               <input type="hidden" name="division" value={item.division_id} />
                                                               <input type="hidden" name="department" value={item.department_id} />
                                                               <Link to={"/admin/profile/" + item.id} className="btn btn-result-open">
                                                                  <input type="submit" name="command" value="Select" /></Link>
                                                               <Link to={"/admin/add_user/" + item.id} className="btn btn-result-open">
                                                                  <input type="button" name="copy" value="Copy User" />
                                                               </Link>
                                                            </td>
                                                            <td> {item.username} </td>
                                                            <td> {item.surname} </td>
                                                            <td> {item.firstname} </td>
                                                            <td> {item.id_number} </td>
                                                            <td> {item.division} </td>
                                                            <td> {item.department} </td>
                                                            <td> {item.email} </td>
                                                            <td> {item.last_online} </td>
                                                            <td> {item.deactivation_date} </td>
                                                            {/* <td> {(item.last_online !== null) ? format(new Date(item.last_online), 'yyyy-MM-dd kk:mm:ss') : ""}</td>
                                                            <td> {(item.deactivation_date !== null) ? format(new Date(item.deactivation_date), 'yyyy-MM-dd kk:mm:ss') : ""}</td> */}
                                                         </tr>
                                                      );
                                                   })
                                                ) : <tr><td colSpan={10}>"NO USER(S) WERE FOUND MATCHING YOUR SEARCH CRITERIA"</td></tr>
                                             }
                                             {btn_grpDetails && (
                                                <tr >
                                                   <td className="caption text-center" colSpan={10}>
                                                      <Link to={'/admin/group_admin/group_defaults/'+ group_id}>
                                                      <input type="submit" name="command" value="Group details" />
                                                      </Link>
                                                   </td>
                                                </tr>
                                             )}
                                             <tr className="caption"><td colSpan={10}>&nbsp;</td></tr>
                                          </tbody>
                                       </table>
                                    </form>
                                 )}
                                 <p></p>
                              </td>
                           </tr>

                        </tbody>
                     </table>
                  </td>
                  <td></td>

               </tr>
            </tbody>
         </table>
      </>
   );
}
export default User_search