import VechileAccessories_menu from "./VechileAccessories_menu"
import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../Components/Loader"

function Create_product() {
    document.title = "Products | Seriti - Vehicle & Accessories";
    const UserId = localStorage.getItem("UserId");
    //const { productId } = useParams();
    const [productName, setProductName] = useState('')

    const [category, setCategory] = useState('');
    const [allCategory, setAllCategory] = useState([]);

    const [costPrice, setCostPrice] = useState('');
    const [selling_price, setSellingPrice] = useState('')
    const [ProductDescription, setProductDescription] = useState('')

    const [template, setTemplate] = useState('');
    const [allTemplate, setAllTemplate] = useState([]);

    const [allGroup, setAllGroup] = useState([]);
    const [group, setGroup] = useState('');

    const [branch, setBranch] = useState('');
    const [allBranch, setAllBranch] = useState([]);

    const [code, setCode] = useState('');
    const [loader, setLoader] = useState(false);

    //------------------------------group dropdown------------------------------------//
    useEffect(() => {
        // if(flag){
        axios
            .get("index/group")
            .then((response) => {
                setAllGroup(response.data.data);
            })
            .catch((response) => {
                //alert(response.response.data.message);
            });
        // }
    }, []);

    //----------------------branch dropdown depends on group----------------------------//
    useEffect(() => {
        if (group !== '') {
            axios
                .get("index/branch/" + group)
                .then((response) => {
                    setAllBranch(response.data.data);
                })
                .catch((response) => {
                    //alert(response.response.data.message);
                });
        }
    }, [group]);

    //----------------------------dropdown of product----------------------------------// 
    useEffect(() => {
        //console.log(collection);
        axios
            .get("/product/dropdown")
            .then((response) => {
                // console.log(response.data.data)
                setAllCategory(response.data.data);
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, []);

    //---------------------handle validation------------------------//
    const handleValidation = () => {
        var flag = true;
        // var regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
        const regexp = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/
        if (productName === '') {
            alert('Please enter at least one character in name field.');
            flag = false;
        }
        if (code === '') {
            alert('Please enter code.');
            flag = false;
        }
        if (costPrice !== '') {
            if (!regexp.test(costPrice)) {
                alert('Please enter a valid amount for Cost Price.');
                flag = false;
            }
        }
        if (selling_price !== '') {
            if (!regexp.test(selling_price)) {
                alert('Please enter a valid amount for Selling Price.');
                flag = false;
            }
        }
        return flag;
    };

    //----------------------------fetch product data----------------------------------// 
    useEffect(() => {
        axios
            .get("/product/get/0")
            .then((response) => {
                //console.log(response.data.data)
                setAllTemplate(response.data.data)
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, []);

    //---------------------------------edit data--------------------------------------//
    const handleSubmit = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        if (flag) {
            setLoader(true);
            const payload = {
                user_id: UserId,
                //productId: productId,
                category: category,//product type
                name: productName,
                cost_price: costPrice,
                selling_price: selling_price,
                template: template,
                product_description: ProductDescription,
                code: code,
                division: group,
                department: branch
            };
            axios
                .post("product/add", payload)
                .then((response) => {
                    setLoader(false);
                    if (response.data.status === 200) {
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        setProductName('')
                        setCategory('')
                        setCostPrice('')
                        setSellingPrice('')
                        setProductDescription('')
                        setTemplate('')
                        setGroup('')
                        setBranch('')
                        setCode('')
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    setLoader(false);
                    if (response.response.data.status === 400)
                    toast.error(response.response.data.data, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    };

    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <VechileAccessories_menu />
            {loader ? <Loader /> : null}
            <form method="post" action="" onSubmit={handleSubmit} >
                <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                    <tbody>
                        <tr valign="top" className="bg-white">
                            <td></td>
                            <td width="100%" className="bg-white">
                                <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                                <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center">

                                                <table className="defaultTbl" width="500" cellSpacing="1">
                                                    <tbody>
                                                        <tr><th colSpan={2} className="text-center">Add a New Product</th></tr>

                                                        <tr>
                                                            <td width="30%" className="caption">Name<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="text" name="name" className="text-uppercase" value={productName} size="60" validate="text" onChange={(e) => setProductName(e.target.value)} /></td>
                                                        </tr>

                                                        <tr><td className="caption">Product Type</td><td>
                                                            <select name="category" id="category" className="text-uppercase" value={category} onChange={(e) =>
                                                                setCategory(e.target.value)} style={{ width: "390px" }}>
                                                                <option value="0">-- Select option --</option>
                                                                {allCategory.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id} >
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </td></tr>

                                                        <tr>
                                                            <td className="caption">GROUP</td>
                                                            <td>
                                                                <select name="group" id="group" value={group} onChange={(e) => setGroup(e.target.value)} className="text-uppercase" style={{ width: "390px" }}>
                                                                    <option value="">-- SELECT A GROUP --</option>
                                                                    {allGroup.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id} >
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">BRANCH</td>
                                                            <td>
                                                                <select name="branch" id="branch" className="text-uppercase" value={branch} onChange={(e) => setBranch(e.target.value)} style={{ width: "390px" }}>
                                                                    <option value="All">-- Select a Group First -</option>
                                                                    {allBranch.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id} >
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td width="30%" className="caption">Code<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="text" name="code" className="text-uppercase" value={code} size="60" validate="text" onChange={(e) => setCode(e.target.value)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Cost Price</td>
                                                            <td><input type="text" name="cost_price" className="text-uppercase" size="60" value={costPrice} validate="currency" onChange={(e) => setCostPrice(e.target.value)} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Selling Price</td>
                                                            <td><input type="text" name="selling_price" size="60" className="text-uppercase" value={selling_price} validate="currency" onChange={(e) => setSellingPrice(e.target.value)} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Product Description</td>
                                                            <td><textarea name="product_description" className="text-uppercase" cols="58" rows="4" value={ProductDescription} onChange={(e) => setProductDescription(e.target.value)}></textarea>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Template</td>
                                                            <td>
                                                                <select name="template" id="template" className="text-uppercase" value={template} onChange={(e) =>
                                                                    setTemplate(e.target.value)} style={{ width: '382px' }}>
                                                                    {/* onChange = {(e) => setBranch(e.target.value)} */}
                                                                    <option value="0">-- Select option --</option>
                                                                    {allTemplate.map((item) => {
                                                                        return (
                                                                            <option key={item.value} value={item.value} >
                                                                                {item.text}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr className="caption">
                                                            <td colSpan="2" className="text-center">
                                                                <input type="submit" name="command" value="Add" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <p></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    )
}

export default Create_product