import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';

const Pagination = (props) => {
   // console.warn(props);
    const navigate = useNavigate()
    const [limit, setLimit] = useState(10)
    const [Pages, setPages] = useState([])
    const [Call, setCall] = useState(false)

    useEffect(() => {
       // const pathname = 'http://localhost:3000';
        var temp = []
        var i = 0;
        do {
            temp.push(++i)
        } while (i < (props.NoOfRecords / limit));
        setPages(temp)
        if (Call) {
            navigate('/' + props.link + '?PageNo=1&Limit=' + limit + '&' + props.search_text)
        }
    }, [limit, props.link, props.search_text, props.NoOfRecords, Call, navigate])
   
    //console.log(props.SelectedPage);

    return (
        <>
            {

                <div className="row mt-2 align-items-center">
                    {/* <div className="col-md-1">
                        <select className="form-select"
                            value={limit}
                            onChange={(e) => {
                               // alert('sd');
                                setLimit(e.target.value)
                                setCall(true)
                            }}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div> */}

                    {
                        Pages.length > 1 ?
                        
                            <div className="col-md-11">

                                <div>
                                    <ul className='paginate-ul'>
                                        { 
                                            props.SelectedPage && parseInt(props.SelectedPage) !== 1 ? 
                                           
                                            <li className='d-inline'>
                                                <Link
                                                    className='paginate-li btn btn-primary'
                                                    to={{ pathname: '/' + props.link, search: 'PageNo=1&Limit=' + limit + '&' + props.search }}>«
                                                </Link>

                                                <Link
                                                    className='paginate-li btn btn-primary'
                                                    to={{ pathname: '/' + props.link, search: 'PageNo=' + (parseInt(props.SelectedPage) - 1) + '&Limit=' + limit + '&' + props.search }}>&#60;</Link>
                                            </li> : null
                                        }

                                        {
                                            Pages.map((item) => {
                                                return (Pages.length > 25) ? (item === 1 || item === 2 || item === 2 || item === (parseInt(props.SelectedPage) - 1) || item === parseInt(props.SelectedPage) || item === (parseInt(props.SelectedPage) + 1) || item === Pages[Pages.length - 1] || item === Pages[Pages.length - 2] || item === Pages[Pages.length - 2]) ? <li className='d-inline' key={item}>
                                                    | <Link
                                                        className='paginate-number btn btn-primary'
                                                        to={{ pathname: '/' + props.link, search: 'PageNo=' + item + '&Limit=' + limit + '&' + props.search }}
                                                        style={{
                                                            fontWeight: (parseInt(props.SelectedPage) === item) ? "bold" : "normal"
                                                        }}>{item}</Link>
                                                </li> : '' : <li className='d-inline' key={item}>
                                                    | <Link
                                                        className='paginate-number btn btn-primary'
                                                        to={{ pathname: '/' + props.link, search: 'PageNo=' + item + '&Limit=' + limit + '&' + props.search }}
                                                        style={{
                                                            fontWeight: (parseInt(props.SelectedPage) === item) ? "bold" : "normal"
                                                        }}>{item}</Link>
                                                </li>
                                            })
                                        }

                                        {
                                            props.SelectedPage && parseInt(props.SelectedPage) !== (Pages[Pages.length - 1]) ? <li className='d-inline'>
                                                <Link
                                                    className='paginate-li btn btn-primary'
                                                    to={{ pathname: '/' + props.link, search: 'PageNo=' + (parseInt(props.SelectedPage) + 1) + '&Limit=' + limit + '&' + props.search }}>&#62;</Link>
                                                <Link
                                                    className='paginate-li btn btn-primary'
                                                    to={{ pathname: '/' + props.link, search: 'PageNo=' + (Pages[Pages.length - 1]) + '&Limit=' + limit + '&' + props.search }} >»</Link>
                                            </li> : null
                                        }
                                    </ul>
                                </div>
                            </div> : null
                    }

                </div>
            }
        </>
    )
}

export default Pagination