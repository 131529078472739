import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//import table from 'react-bootstrap/table';
import { token } from "../features/TokenSlice";
import Logo from '../Images/logo/Seriti BW - system logo.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import Footer_bg from '../Images/core/h11_bg.gif';
import axios from "../axios";
import Loader from "../Components/Loader"

const Login = () => {
   
    const [user, setUsername] = useState("");
    const [pwd, setPassword] = useState("");
    const [LoginLoader, setLoginLoader] = useState(false);
    const [NameError, setNameError] = useState("");
    const userRef = useRef();
    const pwdRef  = useRef();
    const [isShown, setIsSHown] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [MsgFlag, setMsgFlag] = useState(0);
    const [loader, setLoader] = useState(false);
    const [StayLoggedIn, setStayLoggedIn] = useState(false);

    //console.log(new Date().toLocaleString() + "");
    //alert('test');
    var today = new Date();
    var exp_time = today.getHours() + ':' + today.getMinutes(); //+ ':' + today.getSeconds()
    //console.log(time);

    const togglePassword = () => {
        setIsSHown((isShown) => !isShown);
    };

    useEffect(() => {
        document.title = 'Login | Seriti BW';
        //localStorage.removeItem("sess_access_token");
        //localStorage.removeItem("sess_token_expire");
    }, []);

    const handleValidation = () => {
        var flag = true;
        setLoginLoader(true);
        if (user === "") {
          alert("Please Enter Username");
          userRef.current.focus();
          flag = false;
        }
        else if (pwd === "") {
            alert("Please Enter Password");
            pwdRef.current.focus();
          flag = false;
        }

        return flag;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        if (flag) {
            //alert('sdsd');
            setNameError("");
            setLoader(true);
            const payload = {
                username: user,
                password: pwd,
                stay_login : StayLoggedIn == true ? 1 : 0,
            };
            //console.log(payload);
              axios
                .post("singin", payload)
                .then((response) => {
                    //console.log(response)
                    if(response.data.status === 412){
                        setNameError(response.data.message);
                    }
                    
                    if(response.data.status === 413){
                        setMsgFlag(1);
                        setNameError(response.data.message);
                    }
                    else{
                       // alert('yes') 
                        localStorage.setItem(
                         "Token",
                          JSON.stringify({ token: response.data.data.token })
                        );
                        localStorage.setItem("TokenId", response.data.data.token_id);
                        localStorage.setItem("Username", response.data.data.user_name);
                        localStorage.setItem("UserId", response.data.data.user_id);
                        localStorage.setItem("super_user", response.data.data.super_user);
                        localStorage.setItem("system_type", response.data.data.system_type);
                       // localStorage.setItem("system_type", 1);
                        localStorage.setItem("user_country_id", response.data.data.user_country_id);
                        localStorage.setItem("division_id", response.data.data.division_id);
                        localStorage.setItem("department_id", response.data.data.department_id);
                        localStorage.setItem("section_id", response.data.data.section_id);
                        localStorage.setItem("Access", JSON.stringify(response.data.data.access));
                        localStorage.setItem("Modules", JSON.stringify(response.data.data.modules));
                        localStorage.setItem("dashboard_hide_show_flag", 0);
                        localStorage.setItem("stay_login_expired_time", response.data.data.stay_login_expired_time);
                        localStorage.setItem("stay_login_flag", response.data.data.stay_login_flag);
                        localStorage.setItem("change_password_flag", response.data.data.change_password_flag);
                        localStorage.setItem("change_password_date", response.data.data.change_password_date);
                    }

                    if(response.data.status === 400 || response.data.status === 412){
                        navigate('/singin'); 
                    }else{
                        if(response.data.data.change_password_flag == 'yes'){ // change password after 15 days
                            const user_id = response.data.data.user_id ? response.data.data.user_id : 0;
                            alert("Please reset your password for security purposes to a minimum of seven letters which should include a capital and a special character.");
                            navigate('/admin/profile/'+user_id);
                        }else{
                            navigate('/home');
                        }
                        // console.log(response.data.data);
                        dispatch(token({ token: response.data.data.token }));
                        // console.log(response.data.data.token);
                    }//else
                    setLoader(false);
                })
                .catch(() => {
                    //setLoginLoader(false);
                    setLoader(false);
                });
        }
    };
    
    //---------- right click disable
    // document.oncontextmenu = function (e) {
    //    // console.log(e.button);
    //     if (e.button == 2) {
    //         e.preventDefault();
    //         return false;
    //     }
    // }

    return (
        <>
        {loader ? <Loader /> : null}
        <div className='App'>
            <table width="100%" border="0" cellPadding={0} cellSpacing={0} style={{ left: 0, top: 0, position: 'fixed', zIndex: 1 }}>
                <tbody>
                    <tr valign="top">
                        <td width="100%" style={{ backgroundColor: '#ffffff' }} height="92" align='left'>
                            <img style={{ verticalAlign: 'top', padding: 10 }} src={Logo} />
                            &nbsp;
                        </td>
                        <td style={{ backgroundColor: '#ffffff' }} height="92">&nbsp;</td>
                        <td style={{ backgroundColor: '#ffffff' }} height="92">
                            <table width="20%" border="0" cellPadding={0} cellSpacing={0}>
                                <tbody>
                                    <tr valign="top">
                                        <td>
                                            <table border="0" cellPadding={0} cellSpacing={0} align="right" style={{ marginTop: 12 }}>
                                                <tbody>
                                                    <tr valign="top"><td height="56">&nbsp;</td>
                                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                                        <td height="56">&nbsp;</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {/* <img src={Gif} /> */}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            <table width="100%" cellPadding={0} cellSpacing={0} height="13" style={{ left: 0, top: 90, position: 'fixed', zIndex: 1 }}>
                <tbody>
                    <tr><td width="100%" className='line_bg'></td>
                    </tr>
                </tbody>
            </table>

            <table width="100%" border="0" cellPadding={0} cellSpacing={0} style={{ left: 0, top: 103, position: 'fixed', zIndex: 1 }}>
                <tbody>
                    <tr>
                        <td></td>
                        <td width="100%" bgcolor="#ffffff"><table width="100%" border="0" cellPadding={0} cellSpacing={0}>
                            <tbody>
                                <tr>

                                </tr>
                            </tbody>
                        </table></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            <table width="100%" border="0" cellPadding={0} cellSpacing={0} style={{ left: 0, top: 106, position: 'fixed', zIndex: 1 }}>
                <tbody>
                    <tr>
                        <td width="100%"></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            <table width="100%" height="1024" id="mainContainer" border="0" cellPadding={0} cellSpacing={0} style={{ backgroundColor: '#ffffff', position: 'absolute', left: 0, top: 170 }}>
                <tbody>
                    <tr valign="top" style={{ backgroundColor: '#ffffff' }}>
                        <td></td>
                        <td width="100%" style={{ backgroundColor: '#ffffff' }}>
                            <div id="parent_wrapper" style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: '#fff', zIndex: 1000, opacity: 0.3, display: 'none' }}></div>
                            <table width="100%" border="0" cellPadding={5} cellSpacing={5}>
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center" style={{fontSize: '8pt'}}>
                                            <span>
                                                {/* javascript code here */}
                                                <div id="login" align="center" style={{ borderRadius: '6px', backgroundColor: '#ffffff', width: 400, border: '1px solid #ccc', margin: '0px auto' }}>
                                                    <form name="loginform" id="loginform" method="post" onSubmit={handleSubmit} >
                                                        <input type="hidden" value="843" name="country_id" />
                                                        <div style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6, backgroundColor: '#f9f9f9', width: 397 }} >&nbsp;</div>

                                                        <div className='login-divs'><h2 style={{ color: '#555555', marginLeft: 15, marginTop: 0 }}>LOG IN TO SERITI</h2></div>
                                                        <div style={{ backgroundColor: '#ffffff', padding: 10, borderBottomLeftRadius: 6, borderBottomRightRadius: 6 }}>
                                                            <br />
                                                            <table width="100%" style={{ backgroundColor: '#ffffff', padding: 0, margin: 0 }} cellPadding={0} cellSpacing={0}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ textAlign: 'left', paddingBottom: 15, fontSize: '8pt', color: '#000000'}}>
                                                                <span style={{ fontFamily: 'Verdana, Arial, Sans-Serif' }}>USERNAME</span><br /><br /><input type="text" className="login-input" name="user" id="user_login" value={user} tabIndex={10} onChange={(e) => { setUsername(e.target.value) }} ref={userRef}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left', paddingBottom: 15, fontSize: '8pt', color: '#000000' }}>
                                                                <span style={{ fontFamily: 'Verdana, Arial, Sans-Serif' }}>PASSWORD</span><br /><br /><input type={isShown ? "text" : "password"} className="login-input-pass" name="pwd" id="user_pass" value={pwd} tabIndex={10} onChange={(e) => { setPassword(e.target.value) }} ref={pwdRef} />
                                                            </td>
                                                        </tr>
                                                        <tr><td style={{ textAlign: 'left',fontSize: '8pt', color: '#000000' }}>
                                                            <p> 
                                                                <input type="checkbox" id="remember_me" name="remember_me" value={StayLoggedIn} onChange={(e) => { setStayLoggedIn(!StayLoggedIn) }} /> &nbsp;STAY LOGGED IN
                                                            </p>

                                                            <input type="checkbox" id="showpass" checked={isShown} onChange={togglePassword}/> &nbsp;SHOW PASSWORD
                                                        </td></tr>
                                                        	<tr><td>&nbsp;</td></tr>
                                                            <tr><td style={{ borderTop: '1px solid #cccccc', padding: '0px 0px 10px 0px', backgroundColor: '#fafafa', fontSize: '8pt', color: '#000000' }} ><br />
                                                            <div id="login_buttons" style={{ float: 'left', marginLeft: 25 }} >
                                                                <input type="submit" id="submit" value="Log In" tabIndex={100} />
                                                            </div>
                                                          </td></tr>
                                                          <tr>
                                                            { MsgFlag == 1 ?
                                                               <td style={{ textAlign: 'center' }}>
                                                                  <span style={{fontSize: '10px', color: '#F23D18',textAlign: 'center', width: '100%'}} className="text-danger">you have reached maximum attempts of incorrect password.</span><br />
                                                                  <span style={{fontSize: '10px', color: '#000000',textAlign: 'center', width: '100%'}} >{NameError}</span>
                                                               </td>
                                                            : 
                                                               <td style={{ textAlign: 'center' }}><span style={{fontSize: '10px', color: '#F23D18',textAlign: 'center', width: '100%'}} className="text-danger">{NameError}</span></td>
                                                            }
                                                          </tr>
                                                         
                                                        </tbody>
                                                            </table>
                                                        </div>
                                                    </form>
                                                </div>

                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table width="100%" height="14" border="0" cellPadding={0} cellSpacing={0} style={{ position: 'fixed', bottom: 0,left: 0 }}>
            <tbody>
  <tr>
    <td width="100%" height="14" align="left" background={Footer_bg}>SYS: N/A | USER: N/A | ROLE: N/A N/A </td>
  </tr>
  </tbody>
</table>

        </div>
        </>
    );
};

export default Login;