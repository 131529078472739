import Report_menu from './Report_menu';
import React, { useState, useEffect } from "react";
import axios from "../axios";
import { Link } from 'react-router-dom';
import { useParams } from "react-router";
import Loader from "../Components/Loader";

const Report_individual = () => {
    const { FolderId } = useParams();

    const [reload, setReload] = useState(true);
    const [searchData, setSearchData] = useState([]);
    const [FolderName, setFolderName] = useState("");
    const [loader, setLoader] = useState(false);


    useEffect(() => {
        document.title = 'Reports | Seriti BW';
    }, []);
    
    //----------------------------dropdown of type----------------------------------// 
    useEffect(() => {
        setLoader(true);
        if (reload) {
            const payload = {
                command: 'Individual',
                folder_id: FolderId
            };
            axios
                .post("report/folders/add_edit", payload)
                .then((response) => {
                    // console.log(response.data.data);
                    setSearchData(response.data.data.aReports)
                    setFolderName(response.data.data.folder_name)
                    setReload(false);
                    setLoader(false);
                })
                .catch((response) => {
                    //alert(response.data);
                });
        }
        setLoader(false);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [FolderId, reload]);
    // console.log(searchData);
    return (
        <>
            <Report_menu />
            {loader ? <Loader /> : null}
            <form method="post" action="" >
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">

                                <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center"><p></p>
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="350">
                                                    <tbody>
                                                        <tr className="caption">
                                                            <th colSpan={2} style={{ textAlign: 'center' }}>Report Folders   ( {FolderName} )</th>
                                                        </tr>
                                                        {
                                                            searchData.length ? (
                                                                searchData.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{item.name}</td>
                                                                            <td style={{ textAlign: "center" }}>
                                                                                <Link to={"/reports/render_options/"+FolderId+"/"+item.id}>
                                                                                    <input type="submit" name="command" value="View" />
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : <tr><td colSpan={2}>"There is nothing to display."</td></tr>
                                                        }

                                                        <tr className="caption">
                                                            <td colSpan={2} className="text-center">
                                                                <Link to={"/reports/folders"}>
                                                                    <input type="submit" name="command" value="Back" />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    )
}

export default Report_individual
