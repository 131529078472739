import { Link } from "react-router-dom"
import React from "react";
import Back_to_admin_img from '../../Images/ribbon/icon_back_to_administration.png';
import View_transaction_img from '../../Images/ribbon/icon_view_transaction.png';
import Search_img from '../../Images/ribbon/icon_search.png';
import Add_products_img from '../../Images/ribbon/icon_add_products.png';
import Categories_img from '../../Images/ribbon/icon_categories.png';
import Stock_admin_img from '../../Images/ribbon/icon_stock_admin.png';
import Templates_img from '../../Images/ribbon/icon_templates.png';
import Import_img from '../../Images/ribbon/icon_import.png';
import Accessories_img from '../../Images/ribbon/icon_accessories.png';
import Accessories_import_img from '../../Images/ribbon/icon_accessories_import.png';
import Template from "../../Components/Template";

function VechileAccessories_menu() {
    return (
        <>
            <Template />
            <div>
                <table width="100%" border="0" cellPadding={0} cellSpacing={0} className="custom-icon-menu bg-white">
                    <tbody>
                        <tr>
                            <td></td>
                            <td width="100%">
                                <table width="100%" border="0" cellPadding={0} cellSpacing={0} >
                                    <tbody>
                                        <tr>
                                            <td className="text-center">
                                                <Link to="/admin" >
                                                    <img height="63" border="0" src={Back_to_admin_img} alt="" />
                                                </Link>
                                            </td>

                                            <td className="text-center">
                                                <Link to="/home" >
                                                    <img height="63" border="0" src={View_transaction_img} alt="" />
                                                </Link>
                                            </td>

                                            <td className="text-center">
                                                <Link to="/products/search/new_product" >
                                                    <img height="63" border="0" src={Search_img} alt="" />
                                                </Link>
                                            </td>

                                            <td className="text-center">
                                                <Link to="/product/create" >
                                                    <img height="63" border="0" src={Add_products_img} alt="" />
                                                </Link>
                                            </td>

                                            <td className="text-center">
                                                {/* <Link to="/admin/company_add_edit"> */}
                                                    <img height="63" border="0" src={Categories_img} alt="" />
                                                {/* </Link> */}
                                            </td>

                                            <td className="text-center">
                                                <img height="63" border="0" src={Stock_admin_img} alt="" />
                                            </td>

                                            <td className="text-center">
                                                {/* <Link to="/admin/roles"> */}
                                                    <img height="63" border="0" src={Templates_img} alt="" />
                                                {/* </Link> */}
                                            </td>

                                            <td className="text-center">
                                                <Link to="/import">
                                                    <img height="63" border="0" src={Import_img} alt="" />
                                                </Link>
                                            </td>

                                            <td className="text-center">
                                                <Link to="/products/accessories" >
                                                    <img height="63" border="0" src={Accessories_img} alt="" />
                                                </Link>
                                            </td>

                                            <td className="text-center">
                                                {/* <Link to="/admin/setup"> */}
                                                    <img height="63" className="titleHover_SETUP" border="0" src={Accessories_import_img} alt="" />
                                                {/* </Link> */}
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table width="100%" height="13" border="0" cellPadding={0} cellSpacing={0} className="custom-icon-menu-shadow ">
                    <tbody>
                        <tr>
                            <td width="100%" className="custom-shadow-img"></td>
                        </tr>
                    </tbody>
                </table>


            </div>

        </>
    );
}

export default VechileAccessories_menu