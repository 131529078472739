import Report_menu from './Report_menu';
import React, { useState, useEffect } from "react";
import axios from "../axios";
import { Link } from 'react-router-dom';
import { useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Loader from "../Components/Loader"

const Render_options = () => {
    const UserId = localStorage.getItem("UserId");

    const { FolderId } = useParams();
    const { ReportId } = useParams();

    const [Profile, setProfile] = useState('');
    const [allProfile, setAllProfile] = useState([]);
    const [ProfileFlag, setProfileFlag] = useState(false); 

    const [Group, setGroup] = useState('');

    const [allGroup, setAllGroup] = useState([]);
    const [GroupFlag, setGroupFlag] = useState(false);
    // console.log(Group);

    const [Dealer, setDealer] = useState('');
    const [allDealer, setAllDealer] = useState([]);
    const [DealerFlag, setDealerFlag] = useState(false);

    const [Bordereaux, setBordereaux] = useState('');
    const [allBordereaux, setAllBordereaux] = useState([]);
    const [BordereauxFlag, setBordereauxFlag] = useState(false);

    const [Insurer, setInsurer] = useState('');
    const [allInsurer, setAllInsurer] = useState([]);
    const [InsurerFlag, setInsurerFlag] = useState(false);

    const [StartDate, setStartDate] = useState('');
    const [StartDateFlag, setStartDateFlag] = useState(false);

    const [EndDate, setEndDate] = useState('');
    const FinalEndDate =moment(EndDate).add(1, 'days').format('YYYY-MM-DD');
 //console.log(FinalEndDate);
    const [EndDateFlag, setEndDateFlag] = useState(false);

    const [Start_DateFlag, setStart_DateFlag] = useState(0)
    const [End_DateFlag, setEnd_DateFlag] = useState(0)

    const [Financier, setFinancier] = useState('');
    const [allFinancier, setAllFinancier] = useState([]);
    const [FinancierFlag, setFinancierFlag] = useState(false);

    const [ProductCategory, setProductCategory] = useState('');
    const [allProductCategory, setAllProductCategory] = useState([]);
    const [ProductCategoryFlag, setProductCategoryFlag] = useState(false);

    const [ProductSubCategory, setProductSubCategory] = useState('');
    const [allProductSubCategory, setAllProductSubCategory] = useState([]);
    const [ProductSubCategoryFlag, setProductSubCategoryFlag] = useState(false);

    const [Product, setProduct] = useState('');
    const [allProduct, setAllProduct] = useState([]);
    const [ProductFlag, setProductFlag] = useState(false);

    const [Owner, setOwner] = useState('');
    const [allOwner, setAllOwner] = useState([]);
    const [OwnerFlag, setOwnerFlag] = useState(false);

    const [Underwriter, setUnderwriter] = useState('');
    const [allUnderwriter, setAllUnderwriter] = useState([]);
    const [UnderwriterFlag, setUnderwriterFlag] = useState(false);

    const [loader, setLoader] = useState(false);
    
    const [UserDepartmentAccess, setUserDepartmentAccess] = useState('');
    const [SelGroupFlag, setSelGroupFlag] = useState(true);
    const super_user = localStorage.getItem("super_user");
    
    useEffect(() => {
        document.title = 'Reports | Seriti BW';
    }, []);

    //-------------------------------------dropdowns---------------------------------// 
    useEffect(() => {
        //console.log(collection);
        setLoader(true);
        const payload = {
            folder_id: FolderId,
            report_id: ReportId,
            user_id: UserId
        }
        axios
            .post("report/folders/fields", payload)
            .then((response) => {
                // console.log(response.data.data.profile)
                //setAllReportFields(response.data.data);
                var obj = response.data.data
                if ("profile" in obj) { setAllProfile(response.data.data.profile); setProfileFlag(true) }
                if ("group" in obj) { setAllGroup(response.data.data.group); setGroupFlag(true) }
                if ("dealer" in obj) { setAllDealer(response.data.data.dealer); setDealerFlag(true) }
                if ("bordereaux" in obj) { setAllBordereaux(response.data.data.bordereaux); setBordereauxFlag(true) }
                if ("insurer" in obj) { setAllInsurer(response.data.data.insurer); setInsurerFlag(true) }
                if ("start_date" in obj) {
                    setStartDate(response.data.data.start_date); setStartDateFlag(true)
                    if (response.data.data.start_date !== "")
                        setStart_DateFlag(1)
                }
                if ("end_date" in obj) {
                    setEndDate(response.data.data.end_date); setEndDateFlag(true)
                    if (response.data.data.end_date !== "")
                        setEnd_DateFlag(1)
                }
                if ("financier" in obj) { setAllFinancier(response.data.data.financier); setFinancierFlag(true) }

                if ("product_category" in obj) { setAllProductCategory(response.data.data.product_category); setProductCategoryFlag(true) }
                if ("product_sub_category" in obj) { setAllProductSubCategory(response.data.data.product_sub_category); setProductSubCategoryFlag(true) }
                if ("product" in obj) { setAllProduct(response.data.data.product); setProductFlag(true) }
                if ("owner" in obj) { setAllOwner(response.data.data.owner); setOwnerFlag(true) }
                if ("underwriter" in obj) { setAllUnderwriter(response.data.data.underwriter); setUnderwriterFlag(true) }
                setLoader(false);
            })
            .catch((response) => {
                //alert(response.data);
                setLoader(false);
            });
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [FolderId, ReportId, UserId]);

    //-------------------------dropdown of group if profile---------------------------// 

        useEffect(() => {
            const payload = {
                user_department_access_rights: localStorage.getItem("user_department_access_rights"),
            };
            axios
                .post("user_right_accesable_division", payload)
                .then((response) => {
                    //console.log(response);
                    setAllGroup(response.data.data);
                    setSelGroupFlag(true);
                })
                .catch(() => {
                    console.log('error');
                // setLoader(false);
                });

        }, [SelGroupFlag]);

        useEffect(() => {
            //console.log(collection);
            if (Profile !== '' && Profile !== 'All') {
                const payload = {
                    broker_id: Profile,
                    user_id: UserId,
                    super_user: super_user
                }
                axios
                    .post("report/groups", payload)
                    .then((response) => {
                        // console.log(response.data.data.groups)
                        setAllGroup(response.data.data.groups)
                        setAllDealer([]);
                    })
                    .catch((response) => {
                        //alert(response.data);
                    });
            }else{
                setGroup('0');
                setSelGroupFlag(false);
            }
        }, [Profile]);
   
    //----------------------------dropdown of dealer----------------------------------// 
    useEffect(() => {
        if (Group !== '' && Group !== 'All' && Profile === '') {
            axios
                .get("index/branch/" + Group)
                .then((response) => {
                    // console.log(response);
                    if (response.status === 200) {
                        setAllDealer(response.data.data);
                    }
                })
                .catch(() => {
                    //setLoader(false);
                });
        }else{
            setAllDealer([]);
        }
    }, [Group]);

    //----- profile 
    useEffect(() => {
        //console.log(collection);
        if (Group !== '' && Profile !== '') {
            const payload = {
                broker_id: Profile,
                group_id: Group,
                user_id: UserId,
                super_user: super_user
            }
            axios
                .post("report/dealer",payload)
                .then((response) => {
                     //console.log(response)
                    setAllDealer(response.data.data.dealer)
                })
                .catch((response) => {
                    //alert(response.data);
                });
        }else{
            setAllDealer([]);
        }
    }, [Group,Profile]);

    
    const handleValidation = () => {
        var flag = true;

        if (Group === '0') {
            alert('Please select group');
            flag = false;
        }

        if (StartDate === '') {
            alert('Please select start date!');
            flag = false;
        }

        if (EndDate === '') {
            alert('Please select end date!');
            flag = false;
        }
        if (StartDate !== '' && EndDate !== '') {
            let date1 = new Date(StartDate).getTime();
            let date2 = new Date(EndDate).getTime();

            if (date1 > date2) {
                alert('Start date is greater than End date. Please select Valid End date');
                setEndDate('');
                flag = false;
            }
        }
        return flag;
    };
    // console.log(FolderId,ReportId,StartDate,EndDate,UserId);
    // console.log(Dealer);
    useEffect(() => {
            const payload = {
                user_id: UserId
            }
            axios
                .post("/admin/user_department_access_rights", payload)
                .then((response) => {
                    //console.log(response.data.data)
                    setUserDepartmentAccess(response.data.data)
                })
                .catch((response) => {
                    //alert(response.data);
                });
        
    }, []);


    const handleSubmit = (e) => {
        e.preventDefault();
        const flag = handleValidation();

        if (flag) {
            setLoader(true);
            const payload = {
                folder_id: FolderId,
                report_id: ReportId,
                broker: Profile,
                group: Group,
                dealer: Dealer,
                bordereaux: Bordereaux,
                insurer: Insurer,
                start_date: StartDate !== '' ? moment(StartDate).format("YYYY-MM-DD") : 0,
                end_date: EndDate !== '' ? FinalEndDate : 0,
                user_id: UserId,
                product_category: ProductCategory,
                product_sub_category: ProductSubCategory,
                product: Product,
                owner: Owner,
                underwriter: Underwriter,
                user_department_access:UserDepartmentAccess
            }
           // console.log(payload);
            axios
                .post("reports/all_report_export", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        setLoader(false);
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        var link = document.createElement("a");
                        link.target = "_blank";
                        link.href = response.data.data;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link)
                    }
                })
                .catch((response) => {
                    setLoader(false);
                });
        }
    };

    // console.log(Profile,Group,Dealer,Bordereaux,Insurer);

    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Report_menu />
            {loader ? <Loader /> : null}
            <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                <tbody>
                    <tr valign="top" className="bg-white">
                        <td></td>
                        <td width="100%" className="bg-white">
                            <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                            <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                            <form method="post" action="" onSubmit={handleSubmit}>
                                                <table className="defaultTbl" width="500" cellSpacing="1">
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan="2" className="text-center">FILTER OPTIONS</th>
                                                        </tr>
                                                        {
                                                            ProfileFlag ?
                                                                <tr>
                                                                    <td className="caption">PROFILE</td>
                                                                    <td>
                                                                        <select name="profile" className="text-uppercase" value={Profile} onChange={(e) => { setProfile(e.target.value) }} style={{ width: 300 }}>
                                                                            <option value=""> -- Please select a PROFILE  -- </option>
                                                                            <option value="All">select all</option>
                                                                            {allProfile.map((item) => {
                                                                                return (
                                                                                    <option key={item.id} value={item.id} >
                                                                                        {item.name}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </td>
                                                                </tr> : null
                                                        }
                                                        {
                                                            GroupFlag ?
                                                                <tr>
                                                                    <td className="caption">GROUP<span style={{ color: "red" }}> *</span></td>
                                                                    <td>
                                                                        <select name="group" className="text-uppercase" value={Group} onChange={(e) => { setGroup(e.target.value) }} style={{ width: 300 }}>
                                                                            <option value="0">-- Please select a GROUP --</option>
                                                                            <option value="All">select all</option>
                                                                           
                                                                            {allGroup.map((item) => {
                                                                                return (
                                                                                    <option key={item.id} value={item.id} >
                                                                                        {item.name}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </td>
                                                                </tr> : null
                                                        }
                                                        {
                                                            DealerFlag ?
                                                                <tr>
                                                                    <td className="caption">Dealer</td>
                                                                    <td>
                                                                        <select name="dealer" className="text-uppercase" value={Dealer} onChange={(e) => { setDealer(e.target.value) }} style={{ width: 300 }}>
                                                                            <option value="">-- Please select a DEALER --</option>
                                                                            <option value="All">select all</option>
                                                                            {allDealer.map((item) => {
                                                                                return (
                                                                                    <option key={item.id} value={item.id} >
                                                                                        {item.name}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </td>
                                                                </tr> : null
                                                        }
                                                        {
                                                            FinancierFlag ?
                                                                <tr>
                                                                    <td className="caption">FINANCIER</td>
                                                                    <td>
                                                                        <select name="financier" className="text-uppercase" value={Financier} onChange={(e) => { setFinancier(e.target.value) }} style={{ width: 300 }}>
                                                                            <option value="">- - Please select a FINANCIER - -</option>
                                                                            <option value="All">select all</option>
                                                                            {allFinancier.map((item) => {
                                                                                return (
                                                                                    <option key={item.id} value={item.id} >
                                                                                        {item.name}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </td>
                                                                </tr> : null
                                                        }
                                                        {
                                                            BordereauxFlag ?
                                                                <tr>
                                                                    <td className="caption">Bordereaux</td>
                                                                    <td>
                                                                        <select name="bordereaux" className="text-uppercase" value={Bordereaux} onChange={(e) => { setBordereaux(e.target.value) }} style={{ width: 300 }}>
                                                                            <option value="">- - Please select bordereaux - -</option>
                                                                            <option value="All">select all</option>
                                                                            {allBordereaux.map((item) => {
                                                                                return (
                                                                                    <option key={item.value} value={item.value} >
                                                                                        {item.txt}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </td>
                                                                </tr> : null
                                                        }
                                                        {
                                                            InsurerFlag ?
                                                                <tr>
                                                                    <td className="caption">Insurer</td>
                                                                    <td>
                                                                        <select name="insurer" className="text-uppercase" value={Insurer} onChange={(e) => { setInsurer(e.target.value) }} style={{ width: 300 }}>
                                                                            <option value="">- - Please select a Insurer - -</option>
                                                                            <option value="All">select all</option>
                                                                            {allInsurer.map((item) => {
                                                                                return (
                                                                                    <option key={item.value} value={item.value} >
                                                                                        {item.txt}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </td>
                                                                </tr> : null
                                                        }
                                                        {
                                                            ProductCategoryFlag ?
                                                                <tr>
                                                                    <td className="caption">Product Category</td>
                                                                    <td>
                                                                        <select name="ProductCategory" className="text-uppercase" value={ProductCategory} onChange={(e) => { setProductCategory(e.target.value) }} style={{ width: 300 }}>
                                                                            {/* <option value="">- - Please select a Product Category - -</option> */}
                                                                            <option value="All">select all</option>
                                                                            {/* {allProductCategory.map((item) => {
                                                                                return (
                                                                                    <option key={item.value} value={item.value} >
                                                                                        {item.txt}
                                                                                    </option>
                                                                                );
                                                                            })} */}
                                                                        </select>
                                                                    </td>
                                                                </tr> : null
                                                        }
                                                        {
                                                            ProductSubCategoryFlag ?
                                                                <tr>
                                                                    <td className="caption">Product Sub Category</td>
                                                                    <td>
                                                                        <select name="ProductSubCategory" className="text-uppercase" value={ProductSubCategory} onChange={(e) => { setProductSubCategory(e.target.value) }} style={{ width: 300 }}>
                                                                            {/* <option value="">- - Please select a Product SubCategory - -</option> */}
                                                                            <option value="All">select all</option>
                                                                            {/* {allProductSubCategory.map((item) => {
                                                                                return (
                                                                                    <option key={item.value} value={item.value} >
                                                                                        {item.txt}
                                                                                    </option>
                                                                                );
                                                                            })} */}
                                                                        </select>
                                                                    </td>
                                                                </tr> : null
                                                        }
                                                        {
                                                            ProductFlag ?
                                                                <tr>
                                                                    <td className="caption">Product </td>
                                                                    <td>
                                                                        <select name="Product" className="text-uppercase" value={Product} onChange={(e) => { setProduct(e.target.value) }} style={{ width: 300 }}>
                                                                            {/* <option value="">- - Please select a Product - -</option> */}
                                                                            <option value="All">select all</option>
                                                                            {/* {allProduct.map((item) => {
                                                                                return (
                                                                                    <option key={item.value} value={item.value} >
                                                                                        {item.txt}
                                                                                    </option>
                                                                                );
                                                                            })} */}
                                                                        </select>
                                                                    </td>
                                                                </tr> : null
                                                        }
                                                        {
                                                            OwnerFlag ?
                                                                <tr>
                                                                    <td className="caption">Owner</td>
                                                                    <td>
                                                                        <select name="Owner" className="text-uppercase" value={Owner} onChange={(e) => { setOwner(e.target.value) }} style={{ width: 300 }}>
                                                                            {/* <option value="">- - Please select a Owner - -</option> */}
                                                                            <option value="All">select all</option>
                                                                            {/* {allOwner.map((item) => {
                                                                                return (
                                                                                    <option key={item.value} value={item.value} >
                                                                                        {item.txt}
                                                                                    </option>
                                                                                );
                                                                            })} */}
                                                                        </select>
                                                                    </td>
                                                                </tr> : null
                                                        }
                                                        {
                                                            UnderwriterFlag ?
                                                                <tr>
                                                                    <td className="caption">Underwriter</td>
                                                                    <td>
                                                                        <select name="Underwriter" className="text-uppercase" value={Underwriter} onChange={(e) => { setUnderwriter(e.target.value) }} style={{ width: 300 }}>
                                                                            {/* <option value="">- - Please select a Underwriter - -</option> */}
                                                                            <option value="All">select all</option>
                                                                            {/* {allUnderwriter.map((item) => {
                                                                                return (
                                                                                    <option key={item.value} value={item.value} >
                                                                                        {item.txt}
                                                                                    </option>
                                                                                );
                                                                            })} */}
                                                                        </select>
                                                                    </td>
                                                                </tr> : null
                                                        }
                                                        {
                                                            StartDateFlag ?
                                                                <tr>
                                                                    <td className="caption">Start date<span style={{ color: "red" }}> *</span></td>
                                                                    <td>
                                                                        {/* <input type="date" name="start_date" className="date hasDatepicker" value={StartDate} id="start_date" onChange={(e) => setStartDate(e.target.value)} /> */}
                                                                        {Start_DateFlag === 1 ?
                                                                            <DatePicker id="start_date" name="start_date" value={StartDate} onChange={(date) => { setStartDate(date); setStart_DateFlag(0) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                            :
                                                                            <DatePicker id="start_date" name="start_date" selected={StartDate} onChange={(date) => setStartDate(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                        }
                                                                    </td>
                                                                </tr> : null
                                                        }
                                                        {
                                                            EndDateFlag ?
                                                                <tr>
                                                                    <td className="caption">End date<span style={{ color: "red" }}> *</span></td>
                                                                    <td>
                                                                        {/* <input type="date" name="end_date" className="date hasDatepicker" value={EndDate} id="end_date" onChange={(e) => setEndDate(e.target.value)} /> */}
                                                                        {End_DateFlag === 1 ?
                                                                            <DatePicker id="end_date" name="end_date" value={EndDate} onChange={(date) => { setEndDate(date); setEnd_DateFlag(0) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                            :
                                                                            <DatePicker id="end_date" name="end_date" selected={EndDate} onChange={(date) => setEndDate(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                        }
                                                                    </td>
                                                                </tr> : null
                                                        }
                                                        <tr>
                                                            <td className="caption text-center" colSpan={2} >
                                                                <input type="submit" name="command" value="Download" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </form>
                                            <br />
                                            <Link to={'/reports/individual/'+FolderId}>
                                                <input type="submit" name="command" value="Back" className="float-center" />
                                            </Link>
                                            <p></p>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </>
    )

}

export default Render_options