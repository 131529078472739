import { Link, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import Transaction_menu from './Transaction_menu';
import Footer from "../Dashboard/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "../axios";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { NumericFormat } from 'react-number-format';
import ford_finance_img from '../Images/logo/ford finance.png';
import Loader from "../Components/Loader"

const Finance_application_ford_finance = () => {
    
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [MaritalStatus, setMaritalStatus] = useState("");
    const [MaritalStatusAll, setMaritalStatusAll] = useState([]);
    const [Title, setTitle] = useState("");
    const [TitleAll, setTitleAll] = useState([]);
    const [ResidencyStatus, setResidencyStatus] = useState(0);
    const [ResidencyStatusAll, setResidencyStatusAll] = useState([]);
    const [ResidentialStatus, setResidentialStatus] = useState("");
    const [ResidentialStatusAll, setResidentialStatusAll] = useState([]);
    const [ClientDetails, setClientDetails] = useState("");
    const [ClientDetailsAll, setClientDetailsAll] = useState([]);
    const [Gender, setGender] = useState("");
    const [GenderAll, setGenderAll] = useState([]);
    const [FinantialProviderLoan1, setFinantialProviderLoan1] = useState("");
    const [FinantialProviderLoan2, setFinantialProviderLoan2] = useState("");
    const [FinantialProviderAll, setFinantialProviderAll] = useState([]);
    const [FinantialCompany, setFinantialCompany] = useState("");
    const [FinantialCompanyAll, setFinantialCompanyAll] = useState([]);
    const [TermMonth, setTermMonth] = useState("");
    const [TermMonthAll, setTermMonthAll] = useState([]); 
    const [FinanceType, setFinanceType] = useState("");
    const [FinanceTypeAll, setFinanceTypeAll] = useState([]); 
    
    const [SpouseDetails, setSpouseDetails] = useState("");
    const [SpouseDetailsAll, setSpouseDetailsAll] = useState([]);
    const [SpouseTitle, setSpouseTitle] = useState("");
    const [SpouseResidencyStatus, setSpouseResidencyStatus] = useState(0);
    const [SpouseGender, setSpouseGender] = useState("");

    //------- contact details
    const [HighestEducationAll, setHighestEducationAll] = useState([]);
    const [HighestEducation, setHighestEducation] = useState("");
    const [IndividualAll, setIndividualAll] = useState([]);
    const [ContactIndividual, setContactIndividual] = useState("");
    const [StaffIndicatorAll, setStaffIndicatorAll] = useState([]);
    const [StaffIndicator, setStaffIndicator] = useState("");
  
    const [ContactDetailsAll, setContactDetailsAll] = useState([]);
    const [RelationshipAll, setRelationshipAll] = useState([]);
    const [ContactTitleAll, setContactTitleAll] = useState([]);
    const [ContactDetailsSave, setContactDetailsSave] = useState("");
    const [PrintButtonData, setPrintButtonData] = useState("");

    const [ContactDetails, setContactDetails] = useState("");
    const [ContactRelationship, setContactRelationship] = useState("");
    const [ContactTitle, setContactTitle] = useState("");
    const [ContactFirstName, setContactFirstName] = useState("");
    const [ContactSurnameName, setContactSurnameName] = useState("");
    const [ContactMobileNo, setContactMobileNo] = useState("");
    const [ContactHomePhone, setContactHomePhone] = useState("");
    const [ContactWorkPhone, setContactWorkPhone] = useState("");
    const [ContactPhysicalAddress, setContactPhysicalAddress] = useState("");
    const [ContactEmployerName, setContactEmployerName] = useState("");
    const [ContactDepartment, setContactDepartment] = useState("");
    const [FIContactId, setFIContactId] = useState(0);

    // ----------------- EMPLOYEE DETAILS
    const [EmpIncomeCategoryAll, setEmpIncomeCategoryAll] = useState([]); // dropdown array
    const [EmpIncomeCategory, setEmpIncomeCategory] = useState("");
    const [EmpSalaryDateAll, setEmpSalaryDateAll] = useState([]); // dropdown array
    const [EmpSalaryDate, setEmpSalaryDate] = useState("");
    const [EmpIndustrySectorAll, setEmpIndustrySectorAll] = useState([]); // dropdown array
    const [EmpIndustrySector, setEmpIndustrySector] = useState("");
    const [EmpBankNameAll, setEmpBankNameAll] = useState([]); // dropdown array
    const [EmpBankName, setEmpBankName] = useState("");
    const [EmpAccountTypeAll, setEmpAccountTypeAll] = useState([]); // dropdown array
    const [EmpAccountType, setEmpAccountType] = useState("");
    const [AccountHolderAll, setAccountHolderAll] = useState([]); // dropdown array
    const [AccountHolder, setAccountHolder] = useState("");
    const [OccupationLevelAll, setOccupationLevelAll] = useState([]); // dropdown array
    const [OccupationLevel, setOccupationLevel] = useState("");
    
    const [EmpEmployer, setEmpEmployer] = useState("");
    const [EmpOccupation, setEmpOccupation] = useState("");
    const [EmpEmpoyeeHash, setEmpEmpoyeeHash] = useState("");
    const [Designation, setDesignation] = useState("");
    const [EmpContactNo, setEmpContactNo] = useState("");
    const [EmpSupervisor, setEmpSupervisor] = useState("");
    const [EmpAddress, setEmpAddress] = useState("");
    const [EmpTownCity, setEmpTownCity] = useState("");
    const [PreviousEmpYear, setPreviousEmpYear] = useState("");
    const [AcTypeSpecify, setAcTypeSpecify] = useState("");
    const [EmpBranchName, setEmpBranchName] = useState("");
    const [EmpBranchCode, setEmpBranchCode] = useState("");
    const [EmpAccountNumber, setEmpAccountNumber] = useState("");
    const [IndustrySector, setIndustrySector] = useState("");
    const [Last6MonthStmtSubmitted, setLast6MonthStmtSubmitted] = useState("");
    const [FinancialsSubmitted, setFinancialsSubmitted] = useState("");
    const [PeriodSselfEmployee, setPeriodSselfEmployee] = useState("");
    const [SelfEmpHideShow, setSelfEmpHideShow] = useState("none");
    const [ParmentHideShow, setParmentHideShow] = useState("");
    
    const [SalaryAccount, setSalaryAccount] = useState("");
    const [DateAccountOpened, setDateAccountOpened] = useState('');
    const  FinalDateAccountOpened = DateAccountOpened != '' ? moment(DateAccountOpened).format("YYYY-MM-DD") : 0;
    const [EmployeeFrom, setEmployeeFrom] = useState('');
    const  FinalEmployeeFrom = EmployeeFrom != '' ? moment(EmployeeFrom).format("YYYY-MM-DD") : 0;
    const [EmployeeTo, setEmployeeTo] = useState('');
    const  FinalEmployeeTo = EmployeeTo != '' ? moment(EmployeeTo).format("YYYY-MM-DD") : 0;
    const [ContractExpDate, setContractExpDate] = useState('');
    const  FinalContractExpDate = ContractExpDate != '' ? moment(ContractExpDate).format("YYYY-MM-DD") : 0;
    
    const [colorFocus, setcolorFocus] = useState("");
    const [colorFocus1, setcolorFocus1] = useState("");
    const [colorFocus3, setcolorFocus3] = useState("");

    const [colorFocus5, setcolorFocus5] = useState("");
    const [colorFocus6, setcolorFocus6] = useState("");
    const [colorFocus7, setcolorFocus7] = useState("");

    const [EmployeeDetailsSave, setEmployeeDetailsSave] = useState("");
    const [flag, setFlag] = useState(0);
      // console.log(focusDate);

    // STATE FOR FLAGS
    const [ClientDetailFlag, setClientDetailFlag] = useState(true)
    const [EmployeeDetailFlag, setEmployeeDetailFlag] = useState(false)
    const [FinancialDetailFlag, setFinancialDetailFlag] = useState(true)
    const [LoanDetailFlag, setLoanDetailFlag] = useState(false)
    const [SpouseDetailFlag, setSpouseDetailFlag] = useState(false)
    const [ContactDetailFlag, setContactDetailFlag] = useState(false)
   
    //------------------- manage date flag
    const [BirthdateFlag, setBirthdateFlag] = useState(0);
    const [CurrentdateFlag, setCurrentdateFlag] = useState(0);
    const [PrevdateFlag, setPrevdateFlag] = useState(0);
    const [MarrieddateFlag, setMarrieddateFlag] = useState(0);
    const [SpouseBirthdateFlag, setSpouseBirthdateFlag] = useState(0);
    const [SpouseDateEmployeeFlag, setSpouseDateEmployeeFlag] = useState(0);
    const [LoanCommencementDateFlag, setLoanCommencementDateFlag] = useState(0);
    const [LoanFirstDebitOrderDateFlag, setLoanFirstDebitOrderDateFlag] = useState(0);
    const [DateAccountOpenedFlag, setDateAccountOpenedFlag] = useState(0);
    const [EmployeeFromFlag, setEmployeeFromFlag] = useState(0);
    const [EmployeeToFlag, setEmployeeToFlag] = useState(0);
    const [ContractExpDateFlag, setContractExpDateFlag] = useState(0);
    const [RehabilitationDateFlag, setRehabilitationDateFlag] = useState(0);
    const [PurchaseDateFlag, setPurchaseDateFlag] = useState(0);
    const [LoanExpDateFlag, setLoanExpDateFlag] = useState(0);

    // DATA STATE FOR CLIENT DETAILS
    const [DateOfBirth, setDateOfBirth] = useState('');
    const  FinalDateOfBirth = DateOfBirth != '' ? moment(DateOfBirth).format("YYYY-MM-DD") : 0;
    const [Firstname, setFirstname] = useState('');
    const [Surname, setSurname] = useState('');
    const [IDNumber, setIDNumber] = useState('');
    const [Initials, setInitials] = useState('');
    const [PlaceOfBirth, setPlaceOfBirth] = useState('');
    const [HomeNumber, setHomeNumber] = useState('');
    const [MobileNumber, setMobileNumber] = useState('');
    const [WorkNumber, setWorkNumber] = useState('');
    const [FixNumber, setFixNumber] = useState('');
    const [Email, setEmail] = useState('');
    const [PhysicalAddress1, setPhysicalAddress1] = useState('');
    const [PhysicalAddress2, setPhysicalAddress2] = useState('');
    const [PostalAddress1, setPostalAddress1] = useState('');
    const [PostalAddress2, setPostalAddress2] = useState('');
    const [PhysicalAddressCity, setPhysicalAddressCity] = useState('');
    const [PostalAddressCity, setPostalAddressCity] = useState('');
    const [PhysicalAddressPostCode, setPhysicalAddressPostCode] = useState('');
    const [PostalAddressCode, setPostalAddressCode] = useState('');
    const [HomeVillage, setHomeVillage] = useState('');
    const [NearestLandmark, setNearestLandmark] = useState('');
    const [MarriedDate, setMarriedDate] = useState('');
    const FinalMarriedDate = MarriedDate != '' ? moment(MarriedDate).format("YYYY-MM-DD") : 0;
    const [OccupancyPreviousDate, setOccupancyPreviousDate] = useState('');
    const FinalOccupancyPreviousDate = OccupancyPreviousDate != '' ? moment(OccupancyPreviousDate).format("YYYY-MM-DD") : 0;
    const [OccupancyCurrentDate, setOccupancyCurrentDate] = useState('');
    const FinalOccupancyCurrentDate = OccupancyCurrentDate != '' ? moment(OccupancyCurrentDate).format("YYYY-MM-DD") : 0;
    const [Dependant, setDependant] = useState("")
    const [FiClientId, setFiClientId] = useState(0);

    const [OwnHomeColor, setOwnHomeColor] = useState("");
    const [MonthLeftColor, setMonthLeftColor] = useState("");
    const [HiddenTotalIncome, setHiddenTotalIncome] = useState("")
    const [HiddenSurplus, setHiddenSurplus] = useState("");
    const [MonthinstallReadonly, setMonthinstallReadonly] = useState("");
    const [MonthinstallReadonly1, setMonthinstallReadonly1] = useState("");
    
    const [RentalValueBorder, setRentalValueBorder] = useState("");
    const [BondMonthlyBorder, setBondMonthlyBorder] = useState("");
    const [DefectSurpulse, setDefectSurpulse] = useState("SURPLUS");
    //------------------------- FINANCE CLIENT DETAILS ---------------------------------------
    
    const [GarentorSeftyAll, setGarentorSeftyAll] = useState([])
    const [GarentorSefty, setGarentorSefty] = useState("")
    const [DeclearedInsolventAll, setDeclearedInsolventAll] = useState([])
    const [DeclearedInsolvent, setDeclearedInsolvent] = useState("")
   
    const [RehabilitationDate, setRehabilitationDate] = useState('');
    const FinalRehabilitationDate = RehabilitationDate != '' ? moment(RehabilitationDate).format("YYYY-MM-DD") : '1970-01-01';
    const [PurchaseDate, setPurchaseDate] = useState("")
    const FinalPurchaseDate = PurchaseDate != '' ? moment(PurchaseDate).format("YYYY-MM-DD") : '1970-01-01';
    
    const [GrossMonthlySalary, setGrossMonthlySalary] = useState("")
    const [Commission6MonthAvg, setCommission6MonthAvg] = useState("")
    const [Overtime6MonthAvg, setOvertime6MonthAvg] = useState("")
    const [MonthlyAllowance, setMonthlyAllowance] = useState("")
    const [MonthlyCarAllowance, setMonthlyCarAllowance] = useState("")
    const [PaySlipMAllowance, setPaySlipMAllowance] = useState("")
    const [AllOtherIncome, setAllOtherIncome] = useState("")
    const [TotalIncome, setTotalIncome] = useState("")
    const [GranttedBouns, setGranttedBouns] = useState("")
    const [ExistingLoan, setExistingLoan] = useState(0)
    const [Expenditure, setExpenditure] = useState(0)
    const [ToBeSealed1, setToBeSealed1] = useState(0)
    const [MonthlyInvestment1, setMonthlyInvestment1] = useState("")
    const [ToBeSealed2, setToBeSealed2] = useState(0)
    const [MonthlyInvestment2, setMonthlyInvestment2] = useState("")

    const [SocialSecurity, setSocialSecurity] = useState("")
    const [AssetFinance, setAssetFinance] = useState("")
    const [ShortTermInsurance, setShortTermInsurance] = useState("")
    const [FuneralPlan, setFuneralPlan] = useState("")
    const [InsurancePremiums, setInsurancePremiums] = useState("")
    const [ChildrenClothingEducation, setChildrenClothingEducation] = useState("")
    const [Domestic, setDomestic] = useState("")
    const [ClothingAccountPayment, setClothingAccountPayment] = useState("")
    const [Municipality, setMunicipality] = useState("")
    const [Groceries, setGroceries] = useState("")
    const [Levies, setLevies] = useState("")
    const [CellularTelephone, setCellularTelephone] = useState("")
    const [Dstv, setDstv] = useState("")
    const [Church, setChurch] = useState("")
    const [Gym, setGym] = useState("")
    const [TransportgVehicleMaintenanace, setTransportgVehicleMaintenanace] = useState("")
    const [UnitTrust, setUnitTrust] = useState("")
    const [InvestmentsBank, setInvestmentsBank] = useState("")
    const [CreditCards, setCreditCards] = useState("")
    const [Diners, setDiners] = useState("")
    const [SpendingMoney, setSpendingMoney] = useState("")
    const [OtherDebit, setOtherDebit] = useState("")
    const [Taxation, setTaxation] = useState("")
    const [Donations, setDonations] = useState("")
    const [OtherExpenses2, setOtherExpenses2] = useState("")
    const [HouseHoldExpenses, setHouseHoldExpenses] = useState("")
    const [AlomonyMaintenance, setAlomonyMaintenance] = useState("")
    const [OtherExpenses3, setOtherExpenses3] = useState("")
    const [PensionFundContribution, setPensionFundContribution] = useState("")
    const [WaterElectricity, setWaterElectricity] = useState("")
    const [CientOther1, setCientOther1] = useState("")
    const [CientOther2, setCientOther2] = useState("")
    const [TotalExpenditure, setTotalExpenditure] = useState("")

    const [FinanaceTotal, setFinanaceTotal] = useState("")
    const [DetailsOfGuarantee, setDetailsOfGuarantee] = useState("")
    const [ProofDocs, setFile] = useState("");

    const [AccountNumber, setAccountNumber] = useState("")
    const [previousBranch, setpreviousBranch] = useState("")
    const [Installment, setInstallment] = useState("")
    const [ToBeSealed3, setToBeSealed3] = useState(786)
    const [Settlement, setSettlement] = useState("")
    const [MonthLeft, setMonthLeft] = useState("")
    const [OwnHome, setOwnHome] = useState(0)
    const [RentalValue, setRentalValue] = useState("")
    const [LandlordName, setLandlordName] = useState("")
    const [LandlordAddress, setLandlordAddress] = useState("")
    const [MonthlyBond, setMonthlyBond] = useState("")
    const [BondHolder, setBondHolder] = useState("")
    const [PurchasePrice, setPurchasePrice] = useState("")
    const [MarketValue, setMarketValue] = useState("")
    const [PovertyName, setPovertyName] = useState("")
    const [BondAmount, setBondAmount] = useState("")
    const [StandNumber, setStandNumber] = useState("")
    const [Suburb, setSuburb] = useState("")
    const [Surplus, setSurplus] = useState("")
    const [ClientsFinanceDetSave, setClientsFinanceDetSave] = useState("")
    const [DocsPath, setDocsPath] = useState("")
    const [DocsName, setDocsName] = useState("")

    //----------------------- LOAN SPECIFIES ---------------------------------------------------
    const [PaymentMethodAll, setPaymentMethodAll] = useState([]);
    const [PaymentMethod, setPaymentMethod] = useState("");
    const [BusinessSegmentAll1, setBusinessSegmentAll1] = useState([]);
    const [BusinessSegment1, setBusinessSegment1] = useState("");
    const [PrincipleDriverAll, setPrincipleDriverAll] = useState([]);
    const [PrincipleDriver, setPrincipleDriver] = useState("");
    const [LoanValidateColor, setLoanValidateColor] = useState("");
   
    const [CommencementDate, setCommencementDate] = useState("")
    const FinalCommencementDate = CommencementDate != '' ? moment(CommencementDate).format("YYYY-MM-DD") : '1970-01-01';
    const [InterestType, setInterestType] = useState("")
    const [LoanExpDate, setLoanExpDate] = useState("")
    const FinalLoanExpDate = LoanExpDate != '' ? moment(LoanExpDate).format("YYYY-MM-DD") : '1970-01-01';
    const [FirstDebitOrder, setFirstDebitOrder] = useState("")
    const FinalFirstDebitOrder = FirstDebitOrder != '' ? moment(FirstDebitOrder).format("YYYY-MM-DD") : '1970-01-01';
    const [SaleType, setSaleType] = useState("")
    const [InterestRate, setInterestRate] = useState("")
    const [BankFee, setBankFee] = useState("")
    const [LoanEquity, setLoanEquity] = useState("")
    const [LoanAffordability, setLoanAffordability] = useState("")
    const [LoanDepositPercentage, setLoanDepositPercentage] = useState("")
    const [LoanAfforabilityInsurance, setLoanAfforabilityInsurance] = useState("")
    const [LoanAssetAmount, setLoanAssetAmount] = useState("")
    const [PreffredPaymentDate, setPreffredPaymentDate] = useState("")
    const [LoanInsuranceProduct, setLoanInsuranceProduct] = useState("")
    const [LoanNonInsuranceProduct, setLoanNonInsuranceProduct] = useState("")
    const [LoanCompersiveInsurance, setLoanCompersiveInsurance] = useState("")
    const [LoanDiscount, setLoanDiscount] = useState("")
    const [LoanCashDepositValue, setLoanCashDepositValue] = useState("")
    const [LoanNettTradeDeposit, setLoanNettTradeDeposit] = useState("")
    const [LoanTransactionFee, setLoanTransactionFee] = useState("")
    const [LoanFinanceAmount, setLoanFinanceAmount] = useState("")
    const [LoanSpecificsDetSave, setLoanSpecificsDetSave] = useState("")
    const [LoanCommencementFlag, setLoanCommencementFlag] = useState(0);
    const [LoanPrincipleName, setLoanPrincipleName] = useState("")
    const [DrivernameHideShow, setDrivernameHideShow] = useState("none")

   //------------------------- SPOUSE DETAILS -----------------------------------------------
    const [SpouseFirstName, setSpouseFirstName] = useState("")
    const [SpouseInitial, setSpouseInitial] = useState("")
    const [SpouseSurname, setSpouseSurname] = useState("")
    const [SpouseIdNumber, setSpouseIdNumber] = useState("")
    const [SpouseDOB, setSpouseDOB] = useState("")
    const FinalSpouseDOB = SpouseDOB != '' ? moment(SpouseDOB).format("YYYY-MM-DD") : 0;
    const [SpouseHomePhone, setSpouseHomePhone] = useState("")
    const [SpouseWorkPhone, setSpouseWorkPhone] = useState("")
    const [SpouseMobilePhone, setSpouseMobilePhone] = useState("")
    const [SpouseEmail, setSpouseEmail] = useState("")
    const [SpousePhysicalAdd1, setSpousePhysicalAdd1] = useState("")
    const [SpousePhysicalAdd2, setSpousePhysicalAdd2] = useState("")
    const [SpousePostalAdd1, setSpousePostalAdd1] = useState("")
    const [SpousePostalAdd2, setSpousePostalAdd2] = useState("")
    const [SpousePhysicalAddCity, setSpousePhysicalAddCity] = useState("")
    const [SpousePostalAddCity, setSpousePostalAddCity] = useState("")
    const [SpouseOccupation, setSpouseOccupation] = useState("")
    const [SpouseEmployerName, setSpouseEmployerName] = useState("")
    const [SpouseEmployerAdd, setSpouseEmployerAdd] = useState("")
    const [SpouseDate, setSpouseDate] = useState("")
    const FinalSpouseDate = SpouseDate != '' ? moment(SpouseDate).format("YYYY-MM-DD") : 0;
    const [SpouseIncome, setSpouseIncome] = useState("")
    const [SpouseId, setSpouseId] = useState(0)
    
    const [GarentorTitleAll, setGarentorTitleAll] = useState([]);
    const [SpouseName, setSpouseName] = useState("")
    const [SpouseChidren, setSpouseChidren] = useState("")
    const [SpouseOther, setSpouseOther] = useState("")
    const [GarentorSpouseTitle, setGarentorSpouseTitle] = useState("")
    const [GarentorFirstName, setGarentorFirstName] = useState("")
    const [GarentorSurname, setGarentorSurname] = useState("")
    const [GarentorMobileNo, setGarentorMobileNo] = useState("")
    const [ContactNationalityAll, setContactNationalityAll] = useState([]);
    const [ContactNationality, setContactNationality] = useState("")
    const [SpouseDetSave, setSpouseDetSave] = useState("")

    const [ValidateColor, setValidateColor] = useState("");
    const [ValidateColor1, setValidateColor1] = useState("");
    const [ValidateColor2, setValidateColor2] = useState("");
    const [ClientDetSave, setClientDetSave] = useState([]);

    const [PrintButton, setPrintButton] = useState("");
    const [HeaderFlag, setHeaderFlag] = useState(1);

    const [div_id1, setHide1] = useState("table");
    const [div_id2, setHide2] = useState("none");
    const [div_id3, setHide3] = useState("none");
    const [div_id4, setHide4] = useState("none");
    const [div_id5, setHide5] = useState("none");
    const [div_id6, setHide6] = useState("none");
    const [tabWidth, settabWidth] = useState("950");
    
    const [PropertyHideShow, setPropertyHideShow] = useState("");
    const [PropertyHideShow1, setPropertyHideShow1] = useState("none");
    const [Loan1HideShow, setLoan1HideShow] = useState("none");
    const [Loan2HideShow, setLoan2HideShow] = useState("none");
    const [OtherExistingHideShow, setOtherExistingHideShow] = useState("");
    const [ExpenditureHideShow, setExpenditureHideShow] = useState("");
    const [PrintButtonVisible, setPrintButtonVisible] = useState(false);
    
    const division_id = localStorage.getItem("division_id");
    const department_id = localStorage.getItem("department_id");
    const system_type = localStorage.getItem("system_type");
    const user_id = localStorage.getItem("UserId");
    const transaction_id = localStorage.getItem("transaction_id");
    const client_id = localStorage.getItem("client_id");
    const group_id = localStorage.getItem("group_id");
    const application_id = localStorage.getItem("application_id");
    const instance_id = localStorage.getItem("instance_id");
    const company_id = localStorage.getItem("company_id");
    const loanhouse_id = localStorage.getItem("loanhouse_id");
    const FordFinanceTabId = localStorage.getItem("FordFinanceTabId");
    
    useEffect(() => {
        document.title = 'Transactions | Seriti BW';
    }, []);

    //----------- get higest education / individual / staff indicator / garentor sefty -----------------
    useEffect(() => {   
        if(FordFinanceTabId === '97' || FordFinanceTabId === '99'){ 
            setLoader(true);  
            axios.get("finance_application_fordfinance/fetach_fordfinance_client_details_dropdown")
            .then((response) => {
            // console.log(response);
                if(response.status === 200){
                    setHighestEducationAll(response.data.data.highets_level_education_array);
                    setIndividualAll(response.data.data.individual_array);
                    setStaffIndicatorAll(response.data.data.staff_indicator_array);
                    setGarentorSeftyAll(response.data.data.garentor_sefty_array);
                    setDeclearedInsolventAll(response.data.data.garentor_sefty_array);
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false); 
            });
        }
    }, [FordFinanceTabId]);
   
    //----------- get client title -----------------
    useEffect(() => {    
        if(FordFinanceTabId === '97' || FordFinanceTabId === '101' || FordFinanceTabId === '102'){
            setLoader(true); 
            axios.get("add_edit_company/featch_titles")
            .then((response) => {
            // console.log(response.data.data.customer_type_array);
                if(response.status === 200){
                    setTitleAll(response.data.data.title_array);
                    setContactTitleAll(response.data.data.title_array);
                    setGarentorTitleAll(response.data.data.title_array);
                }
            })
            .catch(() => {
                console.log('error');
                setLoader(false); 
            });
        }
    }, [FordFinanceTabId]);

    //----------- get residency status -----------------
    useEffect(() => {     
        if(FordFinanceTabId === '97' || FordFinanceTabId === '101'){ 
            setLoader(true); 
            axios.get("add_edit_company/featch_residency_status")
            .then((response) => {
                if(response.status === 200){
                  setResidencyStatusAll(response.data.data.residency_status_array);
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false); 
            });
        }
    }, [FordFinanceTabId]);

    //----------- get occupation level -----------------
    useEffect(() => {     
        if(FordFinanceTabId === '98'){ 
            setLoader(true); 
            axios.get("finance_application/feach_occupation_level_dropdown")
            .then((response) => {
            //console.log(response);
                if(response.status === 200){
                  setOccupationLevelAll(response.data.data);
                }
            })
            .catch(() => {
                console.log('error');
                setLoader(false); 
            });
        }
    }, [FordFinanceTabId]);


    //----------- get residential_status -----------------
    useEffect(() => { 
        if(FordFinanceTabId === '97'){   
            setLoader(true);  
            axios.get("add_edit_company/featch_residential_status")
            .then((response) => {
                if(response.status === 200){
                  setResidentialStatusAll(response.data.data.residential_status);
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false); 
            });
        }
    }, [FordFinanceTabId]);

    //----------- get marital status-----------------
    useEffect(() => {   
        if(FordFinanceTabId === '97'){
            setLoader(true);  
            const payload = {
                division_id: division_id,
                transaction_id: transaction_id,
            };
            axios.post("add_edit_company/featch_marital_status",payload)
            .then((response) => {
                if(response.status === 200){
                  setMaritalStatusAll(response.data.data.marital_status_array);
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false);  
            });
        }
    }, [FordFinanceTabId]);

    //----------- get gender -----------------
    useEffect(() => {     
        if(FordFinanceTabId === '97' || FordFinanceTabId === '101'){  
            setLoader(true);  
            axios.get("add_edit_company/featch_gender")
            .then((response) => {
                if(response.status === 200){
                  setGenderAll(response.data.data.gender_array);
                }
            })
            .catch(() => {
                console.log('error');
                setLoader(false);  
            });
        }
    }, [FordFinanceTabId]);

    //----------- get higest education / individual / staff indicator -----------------
    useEffect(() => {   
        if(FordFinanceTabId === '98'){ 
            setLoader(true);
            axios.get("finance_application_fordfinance/fetach_fordfinance_employee_details_dropdown")
            .then((response) => {
                //console.log(response);
                if(response.status === 200){
                    setEmpIncomeCategoryAll(response.data.data.income_category_array);
                    setEmpSalaryDateAll(response.data.data.salary_date);
                    setEmpIndustrySectorAll(response.data.data.industry_sector);
                    setEmpBankNameAll(response.data.data.bank_name_array);
                    setEmpAccountTypeAll(response.data.data.account_type_array);
                    setLoader(false);  
                } 
            }) 
            .catch(() => {
                console.log('error');
            });
        }
    }, [FordFinanceTabId]);

    //----------- get account holder name-----------------
    useEffect(() => {   
        if(FordFinanceTabId === '98'){ 
            setLoader(true);
            const payload = {
                company_id: company_id,
            };
            axios.post("finance_application_fordfinance/fetach_fordfinance_account_holder_name_dropdown",payload)
            .then((response) => {
                //console.log(response);
                if(response.status === 200 && response.data.data != null){
                  setAccountHolderAll(response.data.data.account_holder_array);
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false);  
            });
        }
    }, [FordFinanceTabId]);

    //----------- get repayment_method_array / business_segment_array / principle_driver_array -----------------
    useEffect(() => {   
        if(FordFinanceTabId === '100'){
            setLoader(true);
            axios.get("finance_application_fordfinance/fetach_fordfinance_loan_specifics_dropdown")
            .then((response) => {
                //console.log(response);
                if(response.status === 200){
                    setPaymentMethodAll(response.data.data.repayment_method_array);
                    setBusinessSegmentAll1(response.data.data.business_segment_array);
                    setPrincipleDriverAll(response.data.data.principle_driver_array);
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false);  
            });
        }
    }, [FordFinanceTabId]);

    //----------- get client title -----------------
    useEffect(() => {   
        if(FordFinanceTabId === '101'){    
            setLoader(true);  
            axios.get("finance_application_fordfinance/fetach_fordfinance_contact_nationality_dropdown")
            .then((response) => {
            //console.log(response);
                if(response.status === 200){
                  setContactNationalityAll(response.data.data.contact_nationality);
                }
            })
            .catch(() => {
                console.log('error');
                setLoader(false);  
            });
        } 
    }, [FordFinanceTabId]);

    //----------- featch client details data-----------------

    useEffect(() => {   
        if(FordFinanceTabId === '97'){
            setLoader(true);
            const payload = {
                instance_id: instance_id,
            };
            axios.post("finance_application_fordfinance/featch_fordfinance_client_details_id",payload)
            .then((response) => {
            // console.log(response);
                if(response.status === 200){
                    setFiClientId(response.data.data);
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false);  
            });
        }
    }, [FordFinanceTabId]);


    useEffect(() => {  
        if(FiClientId){
            setLoader(true);  
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                company_id: FiClientId,
            };
            axios.post("finance_application_fordfinance/featch_fordfinance_finance_client_details",payload)
            .then((response) => {
            //console.log(response);
                if(response.status === 200){
                    const obj = response.data.data.client_details_array;
                    setFirstname(obj.firstname);
                    setClientDetails(obj.client_details);
                    setTitle(obj.title);
                    setInitials(obj.initials);
                    setSurname(obj.surname);
                    setIDNumber(obj.id_number);
                    setHomeNumber(obj.home_number);
                    setMobileNumber(obj.mobile_number);
                    setWorkNumber(obj.work_number);
                    setFixNumber(obj.fax_number);
                    setEmail(obj.email);
                    setPhysicalAddress1(obj.physical_address_lane1);
                    setPhysicalAddress2(obj.physical_address_lane2);
                    setPostalAddress1(obj.postal_address_lane1);
                    setPostalAddress2(obj.postal_address_lane2);
                    setPhysicalAddressCity(obj.physical_address_city);
                    setPostalAddressCity(obj.postal_address_city);
                    setResidentialStatus(obj.residential_status);
                    setResidencyStatus(obj.residancy_status);
                    setGender(obj.gender);
                    setMaritalStatus(obj.marial_status);
                    setDependant(obj.number_of_dependent);
                    setDateOfBirth(obj.date_of_birth);
                    setOccupancyCurrentDate(obj.occupancy_date);
                    setOccupancyPreviousDate(obj.occupancy_prev_date);
                    setMarriedDate(obj.date_married);
                    setHighestEducation(obj.highest_education);
                    setPhysicalAddressPostCode(obj.physical_address_code);
                    setPostalAddressCode(obj.postal_address_code);
                    setHomeVillage(obj.home_village);
                    setNearestLandmark(obj.neraest_landmark);
                    setContactIndividual(obj.individual);
                    setStaffIndicator(obj.staff_indicator);
                    setPlaceOfBirth(obj.place_of_birth);
                    setLoader(false);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                } 
              
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
        }
    }, [FiClientId]); 

    const OnClientWiseChange = () => {
        if(ClientDetails != 0){
            setFirstname(""); setClientDetails(""); setTitle(""); setInitials("");  setSurname("");
            setIDNumber(""); setHomeNumber(""); setMobileNumber(""); setWorkNumber("");
            setFixNumber(""); setEmail(""); setPhysicalAddress1("");  setPhysicalAddress2("");
            setPostalAddress1(""); setPostalAddress2(""); setPhysicalAddressCity(""); setPostalAddressCity("");
            setResidentialStatus(""); setResidencyStatus(""); setGender(""); setMaritalStatus("");
            setDateOfBirth(""); setOccupancyCurrentDate(""); setOccupancyPreviousDate(""); setMarriedDate("");
            setHighestEducation(""); setPhysicalAddressPostCode(""); setPostalAddressCode(""); setDependant("");
            setHomeVillage(""); setNearestLandmark(""); setContactIndividual(""); setStaffIndicator("");
            setPlaceOfBirth("");
            }else{
                setLoader(true);  
                const payload = {
                    transaction_id: transaction_id,
                    instance_id: instance_id,
                    company_id: company_id,
                };
                 // console.log(payload);
                axios.post("finance_application_fordfinance/featch_fordfinance_finance_client_details",payload)
                .then((response) => {
                    if(response.status === 200){
                        const obj = response.data.data.client_details_array;
                        setFirstname(obj.firstname);
                       // setClientDetails(obj.client_details);
                        setTitle(obj.title);
                        setInitials(obj.initials);
                        setSurname(obj.surname);
                        setIDNumber(obj.id_number);
                        setHomeNumber(obj.home_number);
                        setMobileNumber(obj.mobile_number);
                        setWorkNumber(obj.work_number);
                        setFixNumber(obj.fax_number);
                        setEmail(obj.email);
                        setPhysicalAddress1(obj.physical_address_lane1);
                        setPhysicalAddress2(obj.physical_address_lane2);
                        setPostalAddress1(obj.postal_address_lane1);
                        setPostalAddress2(obj.postal_address_lane2);
                        setPhysicalAddressCity(obj.physical_address_city);
                        setPostalAddressCity(obj.postal_address_city);
                        setResidentialStatus(obj.residential_status);
                        setResidencyStatus(obj.residancy_status);
                        setGender(obj.gender);
                        setMaritalStatus(obj.marial_status);
                        setDependant(obj.number_of_dependent);
                        setDateOfBirth(obj.date_of_birth);
                        setOccupancyCurrentDate(obj.occupancy_date);
                        setOccupancyPreviousDate(obj.occupancy_prev_date);
                        setMarriedDate(obj.date_married);
                        setHighestEducation(obj.highest_education);
                        setPhysicalAddressPostCode(obj.physical_address_code);
                        setPostalAddressCode(obj.postal_address_code);
                        setHomeVillage(obj.home_village);
                        setNearestLandmark(obj.neraest_landmark);
                        setContactIndividual(obj.individual);
                        setStaffIndicator(obj.staff_indicator);
                        setPlaceOfBirth(obj.place_of_birth);
                        setLoader(false);  
                    } 
                })
                .catch(() => {
                   console.log('error');
                   setLoader(false);  
                });
        }
    }

    //----------- Add/Edit client details data-----------------
    const handleClientDetailsValidation = () => {
        var flag = true;
        const regMatch = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(Email);
        if (ClientDetailFlag) {
            if (Firstname === '') {
                alert('First Name field is mandatory!');
                setValidateColor("red");
                setValidateColor1("");
                setValidateColor2("");
                flag = false;
            }else if (Surname === '') {
                alert('Surname field is mandatory!');
                setValidateColor1("red");
                setValidateColor("");
                setValidateColor2("");
                flag = false;
            }else if (Email === '') {
                alert('Email field is mandatory!');
                setValidateColor2("red");
                setValidateColor1("");
                setValidateColor("");
                flag = false;
            }else if(!regMatch){
                alert("Please enter a valid email address for this client");
                setValidateColor2("red");
                setValidateColor1("");
                setValidateColor("");
                flag = false;
            }
        }
        return flag;
    }

    const handleClientDetailsSubmit = (e) => {
        e.preventDefault();
        const flag = handleClientDetailsValidation();
        if (flag) {
            //setNameError("");
            setLoader(true);  
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                user_id : user_id,
                form_id: 97,      // ford finance bank
                group_id: group_id,
                company_id: company_id, 
                application_id: application_id,
                command: ClientDetSave,
                sub_1_field_930: ClientDetails, 
                sub_1_field_934: IDNumber,
                sub_1_field_931: Title,
                sub_1_field_932: Firstname,
                sub_1_field_933: Surname,
                sub_1_field_935: FinalDateOfBirth,
                sub_1_field_1729: PlaceOfBirth,
                sub_1_field_946: MaritalStatus,
                sub_1_field_1038: ResidencyStatus,
                sub_1_field_936: HomeNumber,
                sub_1_field_938: WorkNumber,
                sub_1_field_1752: HighestEducation, 
                sub_1_field_939: MobileNumber,
                sub_1_field_1527: FixNumber,
                sub_1_field_940: Email,
                sub_1_field_945: Gender,
                sub_1_field_1497: PhysicalAddress1,
                sub_1_field_1500: PostalAddress1,
                sub_1_field_1498: PhysicalAddress2,
                sub_1_field_1501: PostalAddress2,
                sub_1_field_1511: PhysicalAddressCity,
                sub_1_field_1513: PostalAddressCity,
                sub_1_field_941: PhysicalAddressPostCode,
                sub_1_field_943: PostalAddressCode,
                sub_1_field_1732: HomeVillage,
                sub_1_field_1731: NearestLandmark,
                sub_1_field_1466: FinalMarriedDate,
                sub_1_field_951: ResidentialStatus,
                sub_1_field_949: FinalOccupancyCurrentDate,
                sub_1_field_950: FinalOccupancyPreviousDate,
                sub_1_field_1769: ContactIndividual,
                sub_1_field_1770: StaffIndicator,
                sub_1_field_1535: Dependant,
            };
            //console.log(payload);
              axios
                .post("finance_application_fordfinance/add_edit_fordfinance_finance_client_details", payload)
                .then((response) => {
                    //console.log(response);
                    if(response.status === 200){
                        if(response.data.data == "Save"){
                           //window.location.reload(false); 
                            toast.success(response.data.message, {
                              position: toast.POSITION.TOP_CENTER
                            }); 
                            setLoader(false); 
                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });   
                        }else if(response.data.data == "Save and Exit"){
                            navigate('/transaction/create_transaction/' +transaction_id);
                            setLoader(false); 
                        }else if(response.data.data == "Print" || response.data.data == "View Finance App"){
                            GeneratePrintPdf();
                        }
                    }
                })
                .catch(() => {
                   console.log('error');
                   setLoader(false);  
                });
        }
    };
    
    //----------- featch employee details data-----------------
    useEffect(() => {   
        if(FordFinanceTabId === '98'){ 
            setLoader(true);  
            const payload = {
                instance_id: instance_id,
            };
            axios.post("finance_application_fordfinance/featch_fordfinance_employee_details",payload)
            .then((response) => {
            // console.log(response);
                if(response.status === 200 && response.data.data != ''){
                    //console.log(response.data.data.select_array);
                    const obj = response.data.data.select_array[0];
                    setEmpIncomeCategory(obj.income_category); setEmpEmployer(obj.employer);
                    setEmpOccupation(obj.occupation); setEmployeeFrom(obj.employee_from);
                    setEmployeeTo(obj.employee_to); setEmpEmpoyeeHash(obj.employee_hash); 
                    setDesignation(obj.designation); setEmpContactNo(obj.contact_number); 
                    setEmpSalaryDate(obj.salary_date); setEmpSupervisor(obj.supervisor);
                    setEmpAddress(obj.employer_address); setEmpTownCity(obj.town_city);
                    setContractExpDate(obj.contract_exp_date); setEmpIndustrySector(obj.industry_sector);
                    setPreviousEmpYear(obj.no_of_year_employee); setEmpAccountType(obj.account_type);
                    setAcTypeSpecify(obj.ac_type_specifics); setAccountHolder(obj.account_holder_name);
                    setSalaryAccount(obj.salary_account); setEmpBankName(obj.bank_finantial_institute);
                    setEmpBranchName(obj.branch_name); setEmpBranchCode(obj.branch_code);
                    setEmpAccountNumber(obj.account_number); setDateAccountOpened(obj.ac_opened_date);
                    setPeriodSselfEmployee(obj.period_self_employee); setFinancialsSubmitted(obj.financital_submitted);
                    setLast6MonthStmtSubmitted(obj.last_6month_bank); setIndustrySector(obj.insustry_sector);
                    setOccupationLevel(obj.occupation_level);

                    if(obj.income_category == 795){
                        setSelfEmpHideShow("")
                        setParmentHideShow("")
                    }else if(obj.income_category == 798){
                        setParmentHideShow("none")
                        setSelfEmpHideShow("none")
                    }else{
                        setSelfEmpHideShow("none")
                        setParmentHideShow("")
                    }
                    setLoader(false);  
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                } 
               
            })
            .catch(() => {
                console.log('error');
                setLoader(false);  
            });
        }
    }, [FordFinanceTabId]); 
   
    //----------- Add/Edit Employee details data-----------------
    const EmployeeDetValidation = () => {
        var flag = true;
        if (EmpIncomeCategory === '0') {
            alert("Income Category field is mandatory");
            setcolorFocus1('red');
            flag = false;
        }else if (EmpOccupation === "") {
            alert("Occupation field is mandatory");
            setcolorFocus3('red');
            flag = false;
        }
        else if (EmpSalaryDate === '0') {
            alert("Salary Date field is mandatory");
            setcolorFocus('red');
            flag = false;
        }
        return flag;
    }
    
    const handleAddEditEmployee = (e) => {
        e.preventDefault();
        const flag1 = EmployeeDetValidation();
        if (flag1) {
            setLoader(true);  
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                user_id : user_id,
                form_id: 98,      
                group_id: group_id,
                company_id: company_id, 
                application_id: application_id,
                command: EmployeeDetailsSave,
                sub_1_field_986: EmpIncomeCategory, 
                sub_1_field_977: EmpEmployer,
                sub_1_field_979: EmpOccupation, 
                sub_1_field_980: FinalEmployeeFrom, 
                sub_1_field_981: FinalEmployeeTo, 
                sub_1_field_982: EmpEmpoyeeHash, 
                sub_1_field_1755: Designation,
                sub_1_field_985: EmpContactNo, 
                sub_1_field_1069: EmpSalaryDate,
                sub_1_field_1077: EmpSupervisor,
                sub_1_field_984: EmpAddress,
                sub_1_field_1754: EmpTownCity,
                sub_1_field_1762: FinalContractExpDate,
                sub_1_field_1753: EmpIndustrySector,
                sub_1_field_1764: PreviousEmpYear,
                sub_1_field_1239: EmpAccountType,
                sub_1_field_1767: AcTypeSpecify,
                sub_1_field_1237: AccountHolder,
                sub_1_field_1782: SalaryAccount,
                sub_1_field_1238: EmpBankName,
                sub_1_field_1240: EmpBranchName,
                sub_1_field_1242: EmpBranchCode,
                sub_1_field_1241: EmpAccountNumber,
                sub_1_field_1686: FinalDateAccountOpened,
                sub_1_field_1516: PeriodSselfEmployee,
                sub_1_field_1517: FinancialsSubmitted,
                sub_1_field_1518: Last6MonthStmtSubmitted,
                sub_1_field_1753: IndustrySector,   
                sub_1_field_1871: OccupationLevel
                           
            };
            //console.log(payload);
            axios.post("finance_application_fordfinance/add_edit_fordfinance_employee_details", payload)
            .then((response) => {
               // console.log(response);
                if(response.status == 200){
                    if(response.data.data == "Save" || response.data.data == "Save New Entry"){
                       //window.location.reload(false);  
                        toast.success(response.data.message, {
                          position: toast.POSITION.TOP_CENTER
                        }); 
                        setLoader(false);
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });  
                    }else if(response.data.data == "Save and Exit"){
                        navigate("/transaction/create_transaction/" + transaction_id);
                        setLoader(false);
                    }else if(response.data.data == "Print" || response.data.data == "View Finance App"){
                        GeneratePrintPdf();
                    }
                }
            })
            .catch(() => {
               console.log('error');
               setLoader(false);  
            });
        }
    }

    //----------- featch clients finance details -----------------
    useEffect(() => { 
        if(FordFinanceTabId === '99'){  
            setLoader(true);  
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
            };
            axios.post("finance_application_fordfinance/featch_fordfinance_clients_finance_details",payload)
            .then((response) => {
            // console.log(response.data.data.clients_finance_details_array);
                if(response.status === 200){
                    const obj = response.data.data.clients_finance_details_array;
                    setGrossMonthlySalary(obj.monthly_basic_salary); setCommission6MonthAvg(obj.commission_six_month)
                    setOvertime6MonthAvg(obj.overtime); setPaySlipMAllowance(obj.net_monthly_income);
                    setAllOtherIncome(obj.all_other_income); setTotalIncome(obj.total_income);
                    setGranttedBouns(obj.grantted_bouns);

                    setExistingLoan(obj.other_exsting_loans); setExpenditure(obj.expenditure);
                    setToBeSealed1(obj.tobe_settled_loan1); setFinantialProviderLoan1(obj.finance_provider_loan1);
                    setMonthlyInvestment1(obj.monthly_installment_loan1); setToBeSealed2(obj.tobe_settled_loan2);
                    setFinantialProviderLoan2(obj.finance_provider_loan2); setMonthlyInvestment2(obj.monthly_installment_loan2);

                    if(obj.tobe_settled_loan1 != '' && obj.tobe_settled_loan1 == 786 ){
                        setMonthinstallReadonly("");
                        setLoan1HideShow("none");
                    }else if(obj.tobe_settled_loan1 != '' && obj.tobe_settled_loan1 == 785 ){
                        setMonthinstallReadonly("none");
                        setLoan1HideShow("");
                    }else{
                        setMonthinstallReadonly("");
                        setLoan1HideShow("none");
                    }

                    if(obj.tobe_settled_loan2 != '' && obj.tobe_settled_loan2 == 786 ){
                        setMonthinstallReadonly1("");
                        setLoan2HideShow("none");
                    }else if(obj.tobe_settled_loan2 != '' && obj.tobe_settled_loan2 == 785 ){
                        setMonthinstallReadonly1("none");
                        setLoan2HideShow("");
                    }else{
                        setMonthinstallReadonly1("");
                        setLoan2HideShow("none");
                    }

                    setSocialSecurity(obj.social_security); setAssetFinance(obj.asset_finance);
                    setShortTermInsurance(obj.short_term_insurance); setFuneralPlan(obj.funeral_plan);
                    setInsurancePremiums(obj.insurance_primium); setChildrenClothingEducation(obj.childran_education);
                    setDomestic(obj.domastic); setClothingAccountPayment(obj.clothing_account_payment);
                    setMunicipality(obj.municipality); setGroceries(obj.groceries);
                    setLevies(obj.livies); setCellularTelephone(obj.celluler_phone);
                    setDstv(obj.dstv); setChurch(obj.church);
                    setGym(obj.gym); setTransportgVehicleMaintenanace(obj.transport_vehicle);
                    setUnitTrust(obj.unit_trust); setInvestmentsBank(obj.invesment_bank);
                    setCreditCards(obj.credit_card); setDiners(obj.diners);
                    setSpendingMoney(obj.spending_money); setOtherDebit(obj.other_debt);
                    setTaxation(obj.taxation); setDonations(obj.donations);
                    setOtherExpenses2(obj.other_expenses2); setHouseHoldExpenses(obj.household_expenses);
                    setAlomonyMaintenance(obj.almoney_maintanance); setOtherExpenses3(obj.other_expenses3);
                    setPensionFundContribution(obj.pension_funds); setWaterElectricity(obj.water_electricity);
                    setCientOther1(obj.other1); setCientOther2(obj.other2);
                    setTotalExpenditure(obj.total_expenditure);

                    setFinantialCompany(obj.existing_previous_vehicle); setAccountNumber(obj.account_number);
                    setpreviousBranch(obj.finance_company_branch);
                
                        setInstallment(obj.instalment); 
                
                    if(obj.settlement != '' && obj.settlement != 0){
                        setSettlement(obj.settlement); 
                    }else{
                        setSettlement('0.00');
                    }
                    setToBeSealed3(obj.tobe_settled3);setMonthLeft(obj.months_left);
                    setOwnHome(obj.own_your_home); 
                    if(obj.own_your_home == 786){
                        setPropertyHideShow("");
                        setPropertyHideShow1("none");
                    }else if(obj.own_your_home == 785){
                        setPropertyHideShow1("");
                        setPropertyHideShow("none");
                    }else{
                        setPropertyHideShow("none"); 
                    } 

                    setMonthlyBond(obj.bond_monthly_installement); setBondHolder(obj.bond_holder_bank);
                    setPurchasePrice(obj.purchase_price); setPurchaseDate(obj.purchase_date);
                    setMarketValue(obj.present_market_value); setPovertyName(obj.in_whose_name);
                    setBondAmount(obj.outstanding_bond_amount); setStandNumber(obj.stand_number);
                
                    setRentalValue(obj.rental_lodging_value);
                    setLandlordName(obj.landlord_name); setLandlordAddress(obj.landlord_address);
                    setSuburb(obj.subrub); 
                    
                    setFinanaceTotal(obj.all_total); setDeclearedInsolvent(obj.have_you_been_declared_insolvent);
                    setRehabilitationDate(obj.rehabilitation_date); setGarentorSefty(obj.are_you_garentor);
                    setDetailsOfGuarantee(obj.details_of_guarentee); //setSurplus(obj.surplus);
                    
                    if(obj.expenditure == 785){
                        setExpenditureHideShow("");
                    }else{
                        setExpenditureHideShow("none");
                    }

                    if(obj.other_exsting_loans == 785){
                        setOtherExistingHideShow("");
                    }else{
                        setOtherExistingHideShow("none");
                    }
                
                    setDocsPath(obj.file_document_path);  setDocsName(obj.file_document_name);
                    setHiddenTotalIncome(obj.hidden_total_income);  //setHiddenSurplus(obj.hidden_surplus); 

                    //------------ onload total expenditure
                    var social_security1 = obj.social_security.replace(/,(?=.*\.\d+)/g, ''); 
                    var asset_finance1 = obj.asset_finance.replace(/,(?=.*\.\d+)/g, ''); 
                    var short_term_insurance1 = obj.short_term_insurance.replace(/,(?=.*\.\d+)/g, ''); 
                    var funeral_plan1 = obj.funeral_plan.replace(/,(?=.*\.\d+)/g, '');  
                    var insurance_primium1 = obj.insurance_primium.replace(/,(?=.*\.\d+)/g, ''); 
                    var childran_education1 = obj.childran_education.replace(/,(?=.*\.\d+)/g, ''); 
                    var domastic1 = obj.domastic.replace(/,(?=.*\.\d+)/g, ''); 
                    var clothing_account_payment1 = obj.clothing_account_payment.replace(/,(?=.*\.\d+)/g, ''); 
                    var municipality1 = obj.municipality.replace(/,(?=.*\.\d+)/g, '');  
                    var groceries1 = obj.groceries.replace(/,(?=.*\.\d+)/g, ''); 
                    var livies1 = obj.livies.replace(/,(?=.*\.\d+)/g, ''); 
                    var celluler_phone1 = obj.celluler_phone.replace(/,(?=.*\.\d+)/g, ''); 
                    
                    var dstv1 = obj.dstv.replace(/,(?=.*\.\d+)/g, ''); 
                    var church1 = obj.church.replace(/,(?=.*\.\d+)/g, ''); 
                    var gym1 = obj.gym.replace(/,(?=.*\.\d+)/g, ''); 
                    var transport_vehicle1 = obj.transport_vehicle.replace(/,(?=.*\.\d+)/g, ''); 
                    var unit_trust1 = obj.unit_trust.replace(/,(?=.*\.\d+)/g, ''); 
                    var invesment_bank1 = obj.invesment_bank.replace(/,(?=.*\.\d+)/g, ''); 
    
                    var credit_card1 = obj.credit_card.replace(/,(?=.*\.\d+)/g, ''); 
                    var diners1 = obj.diners.replace(/,(?=.*\.\d+)/g, ''); 
                    var spending_money1 = obj.spending_money.replace(/,(?=.*\.\d+)/g, ''); 
                    var other_debt1 = obj.other_debt.replace(/,(?=.*\.\d+)/g, ''); 
                    var taxation1 = obj.taxation.replace(/,(?=.*\.\d+)/g, ''); 
                    var donations1 = obj.donations.replace(/,(?=.*\.\d+)/g, ''); 
    
                    var other_expenses_new = obj.other_expenses2.replace(/,(?=.*\.\d+)/g, ''); 
                    var household_expenses1 = obj.household_expenses.replace(/,(?=.*\.\d+)/g, ''); 
                    var almoney_maintanance1 = obj.almoney_maintanance.replace(/,(?=.*\.\d+)/g, ''); 
                    var other_expenses_new3 = obj.other_expenses3.replace(/,(?=.*\.\d+)/g, ''); 
                    var pension_funds1 = obj.pension_funds.replace(/,(?=.*\.\d+)/g, ''); 
                    var water_electricity1 = obj.water_electricity.replace(/,(?=.*\.\d+)/g, ''); 
    
                    var other_new1 = obj.other1.replace(/,(?=.*\.\d+)/g, ''); 
                    var other_new2 = obj.other2.replace(/,(?=.*\.\d+)/g, ''); 
    
                    //console.log(other_new1+'--'+other_new2);
    
                    var onload_total_expenditure = Number(social_security1)+Number(asset_finance1)+Number(short_term_insurance1)+Number(funeral_plan1)+Number(insurance_primium1)+Number(childran_education1)+Number(domastic1)+Number(clothing_account_payment1)+Number(municipality1)+Number(groceries1)+Number(livies1)+Number(celluler_phone1)+Number(dstv1)+Number(church1)+Number(gym1)+Number(transport_vehicle1)+Number(unit_trust1)+Number(invesment_bank1)+Number(credit_card1)+Number(diners1)+Number(spending_money1)+Number(other_debt1)+Number(taxation1)+Number(donations1)+Number(other_expenses_new)+Number(household_expenses1)+Number(almoney_maintanance1)+Number(other_expenses_new3)+Number(pension_funds1)+Number(water_electricity1)+Number(other_new1)+Number(other_new2);
    
                    if(obj.total_expenditure != '0.00'){
                        let final_total_expenditure = onload_total_expenditure.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+'.00';
                        setTotalExpenditure(final_total_expenditure);
                        // console.log(final_total_expenditure);
                    }else{
                        setTotalExpenditure('0.00');
                    }
    
                    var hidden_total_income1 = obj.hidden_total_income; 
                    var monthly_installment_loan1 = obj.monthly_installment_loan1.replace(/,(?=.*\.\d+)/g, ''); 
                    var monthly_installment_loan2 = obj.monthly_installment_loan2.replace(/,(?=.*\.\d+)/g, ''); 
                    
                    if(obj.instalment != '' && obj.instalment != 0){
                        var instalment1 = obj.instalment.replace(/,(?=.*\.\d+)/g, ''); 
                    }else{
                        var instalment1 = '0.00';
                    }
                    
                    if(obj.tobe_settled3 == 786){
                        var instalment1 = obj.instalment.replace(/,(?=.*\.\d+)/g, ''); 
                    }else if(obj.tobe_settled3 == 785 || obj.tobe_settled3 == '0.00'){
                        var instalment1 = '0.00';
                    }
                    
                    var lodging_value1 = obj.rental_lodging_value.replace(/,(?=.*\.\d+)/g, ''); 
                    var bond_monthly_installement1 = obj.bond_monthly_installement.replace(/,(?=.*\.\d+)/g, ''); 
                
                    //console.log(bond_monthly_installement1);
                    var new_surplus = Number(hidden_total_income1)-Number(onload_total_expenditure)-Number(monthly_installment_loan1)-Number(monthly_installment_loan2)-Number(instalment1)-Number(lodging_value1)-Number(bond_monthly_installement1);

                    
                    let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    setSurplus(final_surplus);
                    setHiddenSurplus(new_surplus);
                    setLoader(false);  
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                } 
               
            })
            .catch(() => {
                console.log('error');
                setLoader(false);  
            });
        }
    }, [FordFinanceTabId]); 

    //----------- Add/Edit clients finance details data-----------------
    const handleClientFinanceDetailsValidation = () => {
        var flag = true;
        if (FinancialDetailFlag) {

            if (MonthLeft === '') {
                alert('Months Left field is mandatory!');
                setMonthLeftColor('red');
                flag = false;
            }else if (OwnHome === '') {
                alert('Please select Do you own your home?');
                setOwnHomeColor('1px solid red');
                flag = false;
            }else if (Surplus === "0.00") {
                alert('Surplus field is mandatory!');
                flag = false;
            }else if(OwnHome == 786){
               if(RentalValue === "0.00"){
                alert('Please enter RENTAL/LODGING VALUE amount.');
                setRentalValueBorder('1px solid red');
                flag = false;
               }
            }else if(OwnHome == 785){
                if(MonthlyBond === "0.00"){
                 alert('Please enter BOND MONTHLY INSTALMENT amount.');
                 setBondMonthlyBorder('1px solid red');
                 flag = false;
                }
            }else if (Surplus === "") {
                alert('Surplus field is mandatory!');
                flag = false;
            }else if (ProofDocs.size > 1e6) {
                alert("Please upload a file less than 30 MB");
                flag = false;
            } else if (ProofDocs.type == "application/png" || ProofDocs.type =="application/jpg" || ProofDocs.type =="application/jpeg" || ProofDocs.type =="application/txt" || ProofDocs.type == "application/pdf" ) {
                if (ProofDocs.type == "application/png" || ProofDocs.type =="application/jpg" || ProofDocs.type =="application/jpeg" || ProofDocs.type =="application/txt" || ProofDocs.type == "application/pdf" ){
                    flag = true;
                }else{
                    alert("File does not support. Accept only png, jpg, jpeg, pdf, txt ");
                    flag = false;
                }
            }else{
                alert("File does not support. Accept only png, jpg, jpeg, pdf, txt ");
                flag = false;
            }
        }
        return flag;
    }

   
    const handleClientFinanceDetailsSubmit = (e) => {
        e.preventDefault();
        const flag = handleClientFinanceDetailsValidation();
        if (flag) {
            setLoader(true);  
            var bodyFormData = new FormData();
                bodyFormData.append("transaction_id", transaction_id);
                bodyFormData.append("instance_id", instance_id);
                bodyFormData.append("user_id", user_id);
                bodyFormData.append("form_id", 99);
                bodyFormData.append("group_id", group_id);
                bodyFormData.append("application_id", application_id);
                bodyFormData.append("command", ClientsFinanceDetSave);
                bodyFormData.append("sub_1_field_1778", ProofDocs);
                bodyFormData.append("doc_on_name", "sub_1_field_1778");
                
                bodyFormData.append("sub_1_field_988", GrossMonthlySalary);
                bodyFormData.append("sub_1_field_989", Commission6MonthAvg);
                bodyFormData.append("sub_1_field_990", Overtime6MonthAvg);
                bodyFormData.append("sub_1_field_993", PaySlipMAllowance);
                bodyFormData.append("sub_1_field_996", AllOtherIncome);
                bodyFormData.append("sub_1_field_997", TotalIncome);
                bodyFormData.append("sub_1_field_1772", GranttedBouns);

                bodyFormData.append("sub_1_field_1708", ExistingLoan);
                bodyFormData.append("sub_1_field_1862", Expenditure);
                bodyFormData.append("sub_1_field_1726", ToBeSealed1);
                bodyFormData.append("sub_1_field_1709", FinantialProviderLoan1);
                bodyFormData.append("sub_1_field_1711", MonthlyInvestment1);
                bodyFormData.append("sub_1_field_1727", ToBeSealed2);
                bodyFormData.append("sub_1_field_1718", FinantialProviderLoan2);
                bodyFormData.append("sub_1_field_1717", MonthlyInvestment2);
               
                bodyFormData.append("sub_1_field_1845", SocialSecurity);
                bodyFormData.append("sub_1_field_1846", AssetFinance);
                bodyFormData.append("sub_1_field_1859", ShortTermInsurance);
                bodyFormData.append("sub_1_field_1858", FuneralPlan);
                bodyFormData.append("sub_1_field_1822", InsurancePremiums);
                bodyFormData.append("sub_1_field_1014", ChildrenClothingEducation);
                bodyFormData.append("sub_1_field_1833", Domestic);
                bodyFormData.append("sub_1_field_1009", ClothingAccountPayment);
                bodyFormData.append("sub_1_field_1857", Municipality);
                bodyFormData.append("sub_1_field_1828", Groceries);
                bodyFormData.append("sub_1_field_1856", Levies);
                bodyFormData.append("sub_1_field_1851", CellularTelephone);
                bodyFormData.append("sub_1_field_1835", Dstv);
                bodyFormData.append("sub_1_field_1847", Church);
                bodyFormData.append("sub_1_field_1848", Gym);
                bodyFormData.append("sub_1_field_1013", TransportgVehicleMaintenanace);
                bodyFormData.append("sub_1_field_1850", UnitTrust);
                bodyFormData.append("sub_1_field_1855", InvestmentsBank);
                bodyFormData.append("sub_1_field_1003", CreditCards);
                bodyFormData.append("sub_1_field_1854", Diners);
                bodyFormData.append("sub_1_field_1853", SpendingMoney);
                bodyFormData.append("sub_1_field_1771", OtherDebit);
                bodyFormData.append("sub_1_field_1838", Taxation);
                bodyFormData.append("sub_1_field_1852", Donations);
                bodyFormData.append("sub_1_field_1834", OtherExpenses2);
                bodyFormData.append("sub_1_field_1831", HouseHoldExpenses);
                bodyFormData.append("sub_1_field_1832", AlomonyMaintenance);
                bodyFormData.append("sub_1_field_1836", OtherExpenses3);
                bodyFormData.append("sub_1_field_1839", PensionFundContribution);
                bodyFormData.append("sub_1_field_1010", WaterElectricity);
                bodyFormData.append("sub_1_field_1016", CientOther1);
                bodyFormData.append("sub_1_field_1840", CientOther2);
                bodyFormData.append("sub_1_field_1842", TotalExpenditure);
               
                bodyFormData.append("sub_1_field_1475", FinantialCompany);
                bodyFormData.append("sub_1_field_1477", AccountNumber);
                bodyFormData.append("sub_1_field_1476", previousBranch);
                bodyFormData.append("sub_1_field_1478", Installment);
                bodyFormData.append("sub_1_field_1481", ToBeSealed3);
                bodyFormData.append("sub_1_field_1479", Settlement);
                bodyFormData.append("sub_1_field_1480", MonthLeft);

                bodyFormData.append("sub_1_field_1483", OwnHome);
                bodyFormData.append("sub_1_field_1493", LandlordName);
                bodyFormData.append("sub_1_field_1712", RentalValue);
                bodyFormData.append("sub_1_field_1494", LandlordAddress);
                bodyFormData.append("sub_1_field_1713", MonthlyBond);
                bodyFormData.append("sub_1_field_1488", BondHolder);
                bodyFormData.append("sub_1_field_1491", PurchasePrice);
                bodyFormData.append("sub_1_field_1489", FinalPurchaseDate);
                bodyFormData.append("sub_1_field_1492", MarketValue);
                bodyFormData.append("sub_1_field_1485", PovertyName);
                bodyFormData.append("sub_1_field_1490", BondAmount);
                bodyFormData.append("sub_1_field_1486", StandNumber);
                bodyFormData.append("sub_1_field_1487", Suburb);

                bodyFormData.append("sub_1_field_1775", FinanaceTotal);
                bodyFormData.append("sub_1_field_1776", DeclearedInsolvent);
                bodyFormData.append("sub_1_field_1777", FinalRehabilitationDate);
                bodyFormData.append("sub_1_field_1779", GarentorSefty);
                bodyFormData.append("sub_1_field_1780", DetailsOfGuarantee);
                bodyFormData.append("sub_1_field_1019", Surplus);
        
           // console.log(bodyFormData);
              axios.post("finance_application_fordfinance/add_edit_fordfinance_clients_finance", bodyFormData)
                .then((response) => {
                   // console.log(response);
                    if(response.status === 200){
                        if(response.data.data == "Save"){
                            //window.location.reload(false); 
                            toast.success(response.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            });  
                            setPrintButtonVisible(true);
                            setHeaderFlag(2)
                            setLoader(false);
                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                           // window.location.reload(false); 
                        }else if(response.data.data == "Save and Exit"){
                            navigate('/transaction/create_transaction/' +transaction_id);
                            setLoader(false);
                        }else if(response.data.data == "Print" || response.data.data == "View Finance App"){
                            GeneratePrintPdf();
                        }
                    }
                   // window.location.reload(false);   
                })
                .catch(() => {
                   console.log('error');
                   setLoader(false);
                });
        }
    };

    //----------- featch loan specifics details -----------------
    useEffect(() => {   
        if(FordFinanceTabId === '100'){
            setLoader(true);
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                system_type: system_type,
            };
            axios.post("finance_application_fordfinance/featch_fordfinance_loan_specifics_details",payload)
            .then((response) => {
            //console.log(response);
                if(response.status === 200){
                
                    const obj1 = response.data.data.loan_specifics_details_array;
                    
                    setCommencementDate(obj1.commencement_date); setInterestType(obj1.interest_type); 
                    setLoanExpDate(obj1.loan_exp_date); setSaleType(obj1.sale_type);  
                    setFirstDebitOrder(obj1.installment_date); setFinanceType(obj1.finance_type);
                    setTermMonth(obj1.term_months);  setInterestRate(obj1.prefferd_interest_rate);
                    setLoanEquity(obj1.loan_equility);

                    setLoanAffordability(obj1.affordability);  setLoanDepositPercentage(obj1.deposit_percentage);
                    setLoanAfforabilityInsurance(obj1.affordability_insurance); setBusinessSegment1(obj1.bussiness_segment);
                    // setConsumerSegment(obj1.consumers_segment); 
                    setPaymentMethod(obj1.repayment_method); setPreffredPaymentDate(obj1.prefferd_payment_date);
                    setLoanAssetAmount(obj1.asset_amount);  setLoanInsuranceProduct(obj1.insurance_product);
                    setLoanNonInsuranceProduct(obj1.non_insurance_product);  setLoanCompersiveInsurance(obj1.comprehensive_insurance); setPrincipleDriver(obj1.principle_driver);
                    setLoanDiscount(obj1.discount);  setLoanCashDepositValue(obj1.cash_deposit_value);
                    setLoanNettTradeDeposit(obj1.nett_trade_in_deposit_value);  setBankFee(obj1.bank_documentation_fee);
                    setLoanTransactionFee(obj1.transaction_fee);  setLoanFinanceAmount(obj1.financed_amount);
                    if(obj1.principle_driver == 1751){
                        setDrivernameHideShow("");
                    }else{
                        setDrivernameHideShow("none");
                    }
                    setLoanPrincipleName(obj1.principle_driver_name);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
        }
    }, [FordFinanceTabId]); 
    
    useEffect(() => { 
        if(FordFinanceTabId === '100' && (FinalCommencementDate !== '1970-01-01' || FinalFirstDebitOrder !== '1970-01-01')){  
            const payload = {
                instance_id: instance_id,
                sub_1_field_1533: FinalCommencementDate,
                sub_1_field_1510: FinalFirstDebitOrder,
            };
            axios.post("finance_application/featch_commencement_date_validate",payload)
            .then((response) => {
            //console.log(response);
                setLoanCommencementFlag(response.data.data);
                setLoader(false);  
            })
            .catch(() => {
                console.log('error');
            });
        }
    }, [FinalCommencementDate,FinalFirstDebitOrder,FordFinanceTabId]);

    //----------- Add/Edit Loan specifics details data-----------------
    const handleLoanDetailsValidation = () => {
        var flag = true;
        if ((InterestRate === "") || (parseInt(InterestRate) === 0)) {
            alert("Please ensure the amount is greater than 0.");
            setLoanValidateColor("red");
            flag = false;
        }else if ((LoanCommencementFlag === 1)) {
            alert("Commencement date cannot be less than the current date")
            flag = false;
        }
        return flag;
    }

    const handleLoanDetailsSubmit = (e) => {
        e.preventDefault();
        const flag = handleLoanDetailsValidation();
        if (flag) {
            setLoader(true);
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                user_id : user_id,
                form_id: 100,      
                group_id: group_id,
                company_id: company_id, 
                application_id: application_id,
                command: LoanSpecificsDetSave,
                sub_1_field_1533: FinalCommencementDate,
                sub_1_field_1247: InterestType,
                sub_1_field_1751: FinalLoanExpDate,
                sub_1_field_1246: SaleType,
                sub_1_field_1510: FinalFirstDebitOrder,  
                sub_1_field_1509: FinanceType,     
                sub_1_field_1031: TermMonth,
                sub_1_field_1030: InterestRate,     
                // sub_1_field_1873: PaymentMethod,
                sub_1_field_1456: LoanEquity,     
                sub_1_field_1457: LoanAffordability,
                sub_1_field_1459: LoanDepositPercentage,     
                sub_1_field_1458: LoanAfforabilityInsurance,
                sub_1_field_1816: BusinessSegment1,
                sub_1_field_1790: PreffredPaymentDate,
                sub_1_field_1039: LoanAssetAmount,
                sub_1_field_1450: LoanInsuranceProduct,
                sub_1_field_1451: LoanNonInsuranceProduct,
                sub_1_field_1452: LoanCompersiveInsurance,
                sub_1_field_1042: LoanDiscount,
                sub_1_field_1043: LoanCashDepositValue,
                sub_1_field_1449: LoanNettTradeDeposit,
                sub_1_field_1448: BankFee,
                sub_1_field_1453: LoanTransactionFee,
                sub_1_field_1029: LoanFinanceAmount,
                sub_1_field_1785: PrincipleDriver,
                sub_1_field_1786: LoanPrincipleName,
            };
            //console.log(payload);
              axios.post("finance_application_fordfinance/add_edit_fordfinance_loan_specifics_details", payload)
                .then((response) => {
                   // console.log(response);
                    if(response.status === 200){
                        if(response.data.data == "Save"){
                           // window.location.reload(false); 
                            toast.success(response.data.message, {
                               position: toast.POSITION.TOP_CENTER
                            }); 
                            setPrintButtonVisible(true);
                            setHeaderFlag(2)
                            setLoader(false);
                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }else if(response.data.data == "Save and Exit"){
                            navigate('/transaction/create_transaction/' +transaction_id);
                            setLoader(false);
                        }else if(response.data.data == "Print" || response.data.data == "View Finance App"){
                            GeneratePrintPdf();
                        }
                    }
                })
                .catch(() => {
                   console.log('error');
                   setLoader(false);
                });
        }
    };

    //----------- featch contact details -----------------
    useEffect(() => {  
        if(FordFinanceTabId === '102'){ 
            setLoader(true);
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                company_id: company_id,
            };
            axios.post("finance_application_fordfinance/featch_fordfinance_contact_details",payload)
            .then((response) => {
            //console.log(response);
                if(response.status === 200 && response.data.data.select_array != ''){
                    const obj1 = response.data.data.select_array[0];
                    setContactDetails(obj1.contact_details); setContactRelationship(obj1.relationship); 
                    setContactTitle(obj1.title); setContactFirstName(obj1.firstname);  
                    setContactSurnameName(obj1.surname); setContactMobileNo(obj1.mobile_number);
                    setContactHomePhone(obj1.home_phone);  setContactWorkPhone(obj1.work_phone);
                    setContactPhysicalAddress(obj1.physical_address); setContactEmployerName(obj1.employer_name);
                    setContactDepartment(obj1.department);
                    setLoader(false);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }  
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
        }
    }, [FordFinanceTabId]); 
    
    const OnContactWiseChange = (typeContactId) => {
         //console.log(typeContactId);
         if(typeContactId == 0){
             setContactDetails(""); setContactRelationship(""); setContactTitle(""); setContactFirstName(""); 
             setContactSurnameName(""); setContactMobileNo(""); setContactHomePhone("");
             setContactWorkPhone(""); setContactPhysicalAddress(""); setContactEmployerName(""); setContactDepartment("");
            
            }else{
                setLoader(true);
                const payload = {
                     transaction_id: transaction_id,
                     instance_id: instance_id,
                     company_id: company_id,
                     sel_contact_id: typeContactId,
                };
            
                axios.post("finance_application_fordfinance/featch_fordfinance_contact_details_onchange",payload)
                 .then((response) => {
                    //console.log(response);
                     if(response.status === 200 && response.data != ''){
                         const obj1 = response.data.data.selected_array[0];
                         setContactRelationship(obj1.relationship); 
                         setContactTitle(obj1.title); setContactFirstName(obj1.firstname);  
                         setContactSurnameName(obj1.surname); setContactMobileNo(obj1.mobile_number);
                         setContactHomePhone(obj1.home_phone);  setContactWorkPhone(obj1.work_phone);
                         setContactPhysicalAddress(obj1.physical_address); setContactEmployerName(obj1.employer_name);
                         setContactDepartment(obj1.department);
                         setLoader(false);
                     } 
                })
                .catch(() => {
                    console.log('error');
                    setLoader(false);
                });
        }
    }

    useEffect(() => {  
        if(FordFinanceTabId === '102'){  
            setLoader(true);
            const payload = {
                instance_id: instance_id,
            };
            axios.post("finance_application_fordfinance/featch_fordfinance_contact_id",payload)
            .then((response) => {
            //console.log(response);
                if(response.status === 200){
                    setFIContactId(response.data.data);
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
        }
    }, [FordFinanceTabId]);
    
    //----------- Add/Edit contact details-----------------
    const ContactDetailsValidation = () => {
        var flag = true;
        
        if(ContactRelationship == 0){
            alert("Please select contact relationship");
            setcolorFocus5('red');
            flag = false;
        }else if(ContactFirstName == ''){
            alert("Please enter first name");
            setcolorFocus6('red');
            flag = false;
        }else if(ContactSurnameName == ''){
            alert("Please enter surname");
            setcolorFocus7('red');
            flag = false;
        }
        
        return flag;
    }

    const handleAddContact = (e) => {
        e.preventDefault();
        const flag1 = ContactDetailsValidation();
        if (flag1) {
            setLoader(true);
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                user_id : user_id,
                form_id: 102,      
                group_id: group_id,
                company_id: company_id, 
                application_id: application_id,
                command: ContactDetailsSave,
                sub_1_field_967: ContactDetails,
                sub_1_field_968: ContactRelationship,
                sub_1_field_969: ContactTitle,
                sub_1_field_970: ContactFirstName,
                sub_1_field_971: ContactSurnameName,
                sub_1_field_1748: ContactMobileNo,
                sub_1_field_972: ContactHomePhone,
                sub_1_field_973: ContactWorkPhone,
                sub_1_field_976: ContactPhysicalAddress,
                sub_1_field_974: ContactEmployerName,
                sub_1_field_975: ContactDepartment
            };
           // console.log(payload);
            axios.post("finance_application_fordfinance/add_edit_fordfinance_contact_details", payload)
            .then((response) => {
               //console.log(response);
                if(response.status == 200){
                    if(response.data.data == "Save" || response.data.data == "Save New Entry"){
                      // window.location.reload(false);  
                        toast.success(response.data.message, {
                          position: toast.POSITION.TOP_CENTER
                        }); 
                    }else if(response.data.data == "Save and Exit"){
                        navigate("/transaction/create_transaction/" + transaction_id);
                    }else if(response.data.data == "Print" || response.data.data == "View Finance App"){
                        GeneratePrintPdf();
                    }
                    setLoader(false);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }
                
            })
            .catch(() => {
               console.log('error');
               setLoader(false);
            });
        }
    }

    //----------- featch spouse details --------------
    useEffect(() => {   
        if(FordFinanceTabId === '101'){
            setLoader(true); 
            const payload = {
                instance_id: instance_id,
            };
            axios.post("finance_application_fordfinance/featch_fordfinance_spouse_details_id",payload)
            .then((response) => {
            //console.log(response);
                if(response.status === 200){
                    setSpouseId(response.data.data);
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
        }
    }, [FordFinanceTabId]);

    useEffect(() => {   
        if(SpouseId){
            setLoader(true); 
            const payload = {
                instance_id: instance_id,
                company_id: company_id,
                spouse_id: SpouseId,
            };
            axios.post("finance_application_fordfinance/featch_fordfinance_spouse_details",payload)
            .then((response) => {
            //console.log(response);
                if(response.status === 200){
                    const obj = response.data.data.spouse_details_array;
                    setSpouseDetails(obj.spouse_details); setSpouseTitle(obj.title)
                    setSpouseFirstName(obj.firstname); setSpouseInitial(obj.initials);
                    setSpouseSurname(obj.surname); setSpouseIdNumber(obj.id_number);
                    setSpouseDOB(obj.date_of_birth); setSpouseGender(obj.gender);
                    setSpouseHomePhone(obj.home_phone); setSpouseWorkPhone(obj.work_phone);
                    setSpouseMobilePhone(obj.mobile_phone); setSpouseEmail(obj.email);
                    setSpousePhysicalAdd1(obj.physical_address_lane1); setSpousePostalAdd1(obj.postal_address_lane1);
                    setSpousePhysicalAdd2(obj.physical_address_lane2); setSpousePostalAdd2(obj.postal_address_lane2);
                    setSpousePhysicalAddCity(obj.physical_address_city); setSpousePostalAddCity(obj.postal_address_city);
                    setSpouseResidencyStatus(obj.residancy_status); setSpouseOccupation(obj.occupation);
                    setSpouseEmployerName(obj.employer_name); setSpouseEmployerAdd(obj.employer_address);
                    setSpouseDate(obj.date_employed); setSpouseIncome(obj.spouse_nett_income);
                    setContactNationality(obj.contact_nationality);
                    setSpouseName(obj.spouse_name);
                    setSpouseChidren(obj.children);
                    setSpouseOther(obj.other);
                    setGarentorSpouseTitle(obj.spouse_title);
                    setGarentorFirstName(obj.spouse_first_name);
                    setGarentorSurname(obj.spouse_surname);
                    setGarentorMobileNo(obj.mobile);
                    setLoader(false);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
        }
    }, [SpouseId,FordFinanceTabId]);

    useEffect(() => {  
        if(SpouseDetails){  
            setLoader(true);
            const payload = {
                instance_id: instance_id,
                company_id: company_id,
                spouse_id: SpouseDetails,
            };
            axios.post("finance_application_fordfinance/featch_fordfinance_spouse_details",payload)
            .then((response) => {
            //console.log(response);
                if(response.status === 200){
                    const obj = response.data.data.spouse_details_array;
                    // setSpouseDetails(obj.spouse_details); 
                    setSpouseTitle(obj.title)
                    setSpouseFirstName(obj.firstname); setSpouseInitial(obj.initials);
                    setSpouseSurname(obj.surname); setSpouseIdNumber(obj.id_number);
                    setSpouseDOB(obj.date_of_birth); setSpouseGender(obj.gender);
                    setSpouseHomePhone(obj.home_phone); setSpouseWorkPhone(obj.work_phone);
                    setSpouseMobilePhone(obj.mobile_phone); setSpouseEmail(obj.email);
                    setSpousePhysicalAdd1(obj.physical_address_lane1); setSpousePostalAdd1(obj.postal_address_lane1);
                    setSpousePhysicalAdd2(obj.physical_address_lane2); setSpousePostalAdd2(obj.postal_address_lane2);
                    setSpousePhysicalAddCity(obj.physical_address_city); setSpousePostalAddCity(obj.postal_address_city);
                    setSpouseResidencyStatus(obj.residancy_status); setSpouseOccupation(obj.occupation);
                    setSpouseEmployerName(obj.employer_name); setSpouseEmployerAdd(obj.employer_address);
                    setSpouseDate(obj.date_employed); setSpouseIncome(obj.spouse_nett_income);
                    setContactNationality(obj.contact_nationality);
                    setSpouseName(obj.spouse_name);
                    setSpouseChidren(obj.children);
                    setSpouseOther(obj.other);
                    setGarentorSpouseTitle(obj.spouse_title);
                    setGarentorFirstName(obj.spouse_first_name);
                    setGarentorSurname(obj.spouse_surname);
                    setGarentorMobileNo(obj.mobile);
                    setLoader(false);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                } 
               
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
        }
    }, [SpouseDetails,FordFinanceTabId]);

    //----------- Add/Edit spouse details data-----------------
    const handleSpouseDetailsValidation = () => {
        var flag = true;
        if (SpouseDetails == 0) {
            alert('Please select spouse details!');
            flag = false;
        }
        return flag;
    }

    const handleSpouseDetailsSubmit = (e) => {
        e.preventDefault();
        const flag = handleSpouseDetailsValidation();
        if (flag) {
            setLoader(true);
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                user_id : user_id,
                form_id: 101,      
                group_id: group_id,
                company_id: company_id, 
                application_id: application_id,
                command: SpouseDetSave,
                sub_1_field_952: SpouseDetails,
                sub_1_field_954: SpouseTitle, 
                sub_1_field_955: SpouseFirstName,
                sub_1_field_1075: SpouseInitial,
                sub_1_field_956: SpouseSurname,
                sub_1_field_957: SpouseIdNumber,
                sub_1_field_958: FinalSpouseDOB,
                sub_1_field_965: SpouseGender,
                sub_1_field_959: SpouseHomePhone,
                sub_1_field_960: SpouseWorkPhone,
                sub_1_field_961: SpouseMobilePhone,
                sub_1_field_962: SpouseEmail,
                sub_1_field_1503: SpousePhysicalAdd1,
                sub_1_field_1506: SpousePostalAdd1,
                sub_1_field_1504: SpousePhysicalAdd2,
                sub_1_field_1507: SpousePostalAdd2,
                sub_1_field_1514: SpousePhysicalAddCity,
                sub_1_field_1515: SpousePostalAddCity,
                sub_1_field_947 : SpouseResidencyStatus,
                sub_1_field_1468: SpouseOccupation,
                sub_1_field_1469: SpouseEmployerName,
                sub_1_field_1470: SpouseEmployerAdd,
                sub_1_field_1471: FinalSpouseDate,
                sub_1_field_994 : SpouseIncome,
                sub_1_field_1738: ContactNationality,
                sub_1_field_1573: SpouseName,
                sub_1_field_1574: SpouseChidren,
                sub_1_field_1575: SpouseOther,
                sub_1_field_1572: GarentorSpouseTitle,
                sub_1_field_1583: GarentorFirstName,
                sub_1_field_1584: GarentorSurname,
                sub_1_field_1585: GarentorMobileNo,

            };
            //console.log(payload);
              axios.post("finance_application_fordfinance/add_edit_fordfinance_spouse_details", payload)
                .then((response) => {
                    if(response.status === 200){
                        if(response.data.data == "Save"){
                           // window.location.reload(false);
                            toast.success(response.data.message, {
                              position: toast.POSITION.TOP_CENTER
                            });  
                            setLoader(false);
                        }else if(response.data.data == "Save and Exit"){
                            navigate('/transaction/create_transaction/' +transaction_id);
                            setLoader(false);
                        }else if(response.data.data == "Print" || response.data.data == "View Finance App"){
                            GeneratePrintPdf();
                        }
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }
                })
                .catch(() => {
                   console.log('error');
                   setLoader(false);
                });
        }
    };

    //--------------------------------------------------------------------------------

    //----------- get client details -----------------
    useEffect(() => { 
        if(FordFinanceTabId === '97'){
            setLoader(true);
            const payload = {
                client_id: client_id,
            };    
            axios
            .post("finance_application/fetach_client_details_dropdown",payload)
            .then((response) => {
                if(response.status === 200){
                  setClientDetailsAll(response.data.data);
                }
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
        }
    }, [FordFinanceTabId]); 
    
    //----------- get income category / finantial provider / finantial company / term months / finance type / consumer segment / business segment -----------------
    useEffect(() => { 
        if(FordFinanceTabId === '99' || FordFinanceTabId === '100'){
            setLoader(true);      
            axios.get("finance_application/featch_income_category_finantial_provider")
            .then((response) => {
                if(response.status === 200){
                    setFinantialProviderAll(response.data.data.finantial_provider);
                    setFinantialCompanyAll(response.data.data.finance_company);
                    setTermMonthAll(response.data.data.term_month);
                    setFinanceTypeAll(response.data.data.finance_type);
                    setLoader(false);
                }
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
        }
    }, [FordFinanceTabId]);

    //----------- get client details -----------------
    useEffect(() => { 
        if(FordFinanceTabId === '101' || FordFinanceTabId === '102'){
            setLoader(true);      
            const payload = {
                client_id: client_id,
            };    
            axios.post("finance_application/fetach_spouse_details_dropdown",payload)
            .then((response) => {
                if(response.status === 200){
                    //console.log(response);
                    setSpouseDetailsAll(response.data.data);
                    setContactDetailsAll(response.data.data)
                }
            }) 
            .catch(() => {
                console.log('error');
                setLoader(false);      
            });
        }
    }, [FordFinanceTabId]); 
    
    //----------- get salary date / relationship (employee details) -----------------
    useEffect(() => {     
        if(FordFinanceTabId === '102'){  
            setLoader(true);   
            axios.get("finance_application/featch_salary_date_relationship")
            .then((response) => {
                if(response.status === 200){
                //setSalaryDateAll(response.data.data.salary_date);
                   setRelationshipAll(response.data.data.relationship);
                   setLoader(false);      
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false);      
            });
        }
    }, [FordFinanceTabId]);

    //------------------- table hide /show
    const ChangeNavigation = (tab) => {
        // const CheckValidation = handleClientDetailsValidation();
        
        // if (CheckValidation) {
          //  console.log(tab)
            if(tab === "Client Details"){  // 1
                setHide1('table'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('none'); 
                setHide6('none'); settabWidth(950);
                localStorage.setItem("FordFinanceTabId", 97);
            }else if(tab === "Employer Details"){  // 2
                setHide1('none'); setHide2('table'); setHide3('none'); setHide4('none'); setHide5('none');
                setHide6('none'); settabWidth(800); //previous width - 1407
                localStorage.setItem("FordFinanceTabId", 98);
            }else if(tab === "Client's Financial Details"){   // 3
                setHide1('none'); setHide2('none'); setHide3('table'); setHide4('none'); setHide5('none');
                setHide6('none'); settabWidth(800); 
                localStorage.setItem("FordFinanceTabId", 99);
            }else if(tab === "Loan Specifics"){  // 4
                setHide1('none'); setHide2('none'); setHide3('none'); setHide4('table'); setHide5('none');
                setHide6('none'); settabWidth(800);
                localStorage.setItem("FordFinanceTabId", 100);
            }else if(tab === "Spouse Details"){  // 5
                setHide1('none'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('table');
                setHide6('none'); settabWidth(800);
                localStorage.setItem("FordFinanceTabId", 101);
            }else if(tab === "Contact Details"){  // 6
                setHide1('none'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('none');
                setHide6('table'); settabWidth(800);
                localStorage.setItem("FordFinanceTabId", 102);
            }
        // }
    }

    useEffect(() => {
        if (FordFinanceTabId == 97) {   //client details
            setHide1('table'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('none'); 
            setHide6('none'); settabWidth(950);
        } else if (FordFinanceTabId == 98) {  //employee details
            setHide1('none'); setHide2('table'); setHide3('none'); setHide4('none'); setHide5('none');
            setHide6('none'); settabWidth(800); //previous width - 1407
        }else if (FordFinanceTabId == 99) {  // client finance details
            setHide1('none'); setHide2('none'); setHide3('table'); setHide4('none'); setHide5('none');
            setHide6('none'); settabWidth(800); 
        } else if (FordFinanceTabId == 100) {  // loan specifics details
            setHide1('none'); setHide2('none'); setHide3('none'); setHide4('table'); setHide5('none');
            setHide6('none'); settabWidth(800);
        } else if (FordFinanceTabId == 101) {  // spouse details
            setHide1('none'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('table');
            setHide6('none'); settabWidth(800);
        } else if (FordFinanceTabId == 102) {  // contact details
            setHide1('none'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('none');
            setHide6('table'); settabWidth(800);
        } 

    }, [FordFinanceTabId]);

    
    const ChangeForsurpulse = () => {
        if(Math.sign(HiddenSurplus) === -1){ 
            setDefectSurpulse("DEFICIT");
        } else { 
            setDefectSurpulse("SURPLUS");
        }
    }

    const RemoveZeroVal = (e) => {
        // console.log(e);
        if(e === 1 && GrossMonthlySalary === '0.00'){ setGrossMonthlySalary("") }
        if(e === 2 && Commission6MonthAvg === '0.00'){ setCommission6MonthAvg("") }
        if(e === 3 && Overtime6MonthAvg === '0.00'){ setOvertime6MonthAvg("") }
        if(e === 4 && MonthlyAllowance === '0.00'){ setMonthlyAllowance("") }
        if(e === 5 && MonthlyCarAllowance === '0.00'){ setMonthlyCarAllowance("") }
        if(e === 6 && PaySlipMAllowance === '0.00'){ setPaySlipMAllowance("") }
        if(e === 7 && AllOtherIncome === '0.00'){ setAllOtherIncome("") }
        if(e === 8 && TotalIncome === '0.00'){ setTotalIncome("") }
        if(e === 9 && MonthlyInvestment1 === '0.00'){ setMonthlyInvestment1("") }
        if(e === 10 && MonthlyInvestment2 === '0.00'){ setMonthlyInvestment2("") }
        if(e === 11 && Installment === '0.00'){ setInstallment("") }
        if(e === 12 && Settlement === '0.00'){ setSettlement("") }
        if(e === 13 && RentalValue === '0.00'){ setRentalValue("") }
        if(e === 14 && Surplus === '0.00'){ setSurplus("") }
        if(e === 15 && GranttedBouns === '0.00'){ setGranttedBouns("") }
        
        if(e === 21 && SocialSecurity === '0.00'){ setSocialSecurity("") }
        if(e === 22 && AssetFinance === '0.00'){ setAssetFinance("") }
        if(e === 23 && ShortTermInsurance === '0.00'){ setShortTermInsurance("") }
        if(e === 24 && FuneralPlan === '0.00'){ setFuneralPlan("") }
        if(e === 25 && InsurancePremiums === '0.00'){ setInsurancePremiums("") }
        if(e === 26 && ChildrenClothingEducation === '0.00'){ setChildrenClothingEducation("") }
        if(e === 27 && Domestic === '0.00'){ setDomestic("") }
        if(e === 28 && ClothingAccountPayment === '0.00'){ setClothingAccountPayment("") }
        if(e === 29 && Municipality === '0.00'){ setMunicipality("") }
        if(e === 30 && Groceries === '0.00'){ setGroceries("") }
        if(e === 31 && Levies === '0.00'){ setLevies("") }
        if(e === 32 && CellularTelephone === '0.00'){ setCellularTelephone("") }
        if(e === 33 && Dstv === '0.00'){ setDstv("") }
        if(e === 34 && Church === '0.00'){ setChurch("") }
        if(e === 35 && Gym === '0.00'){ setGym("") }
        if(e === 36 && TransportgVehicleMaintenanace === '0.00'){ setTransportgVehicleMaintenanace("") }
        if(e === 37 && UnitTrust === '0.00'){ setUnitTrust("") }
        if(e === 38 && InvestmentsBank === '0.00'){ setInvestmentsBank("") }
        if(e === 39 && CreditCards === '0.00'){ setCreditCards("") }

        if(e === 40 && Diners === '0.00'){ setDiners("") } 
        if(e === 41 && SpendingMoney === '0.00'){ setSpendingMoney("") } 
        if(e === 42 && OtherDebit === '0.00'){ setOtherDebit("") } 
        if(e === 43 && Taxation === '0.00'){ setTaxation("") } 
        if(e === 44 && Donations === '0.00'){ setDonations("") } 
        if(e === 45 && OtherExpenses2 === '0.00'){ setOtherExpenses2("") } 
        if(e === 46 && HouseHoldExpenses === '0.00'){ setHouseHoldExpenses("") } 
        if(e === 47 && AlomonyMaintenance === '0.00'){ setAlomonyMaintenance("") } 
        if(e === 48 && OtherExpenses3 === '0.00'){ setOtherExpenses3("") } 
        if(e === 49 && PensionFundContribution === '0.00'){ setPensionFundContribution("") } 
        if(e === 50 && WaterElectricity === '0.00'){ setWaterElectricity("") } 
        if(e === 51 && CientOther1 === '0.00'){ setCientOther1("") } 
        if(e === 52 && CientOther2 === '0.00'){ setCientOther2("") } 
        if(e === 53 && TotalExpenditure === '0.00'){ setTotalExpenditure("") } 
        
        if(e === 81 && BankFee === '0.00'){ setBankFee("") }
        if(e === 82 && SpouseIncome === '0.00'){ setSpouseIncome("") } 
        if(e === 83 && MonthlyBond === '0.00'){ setMonthlyBond("") } 
        if(e === 84 && PurchasePrice === '0.00'){ setPurchasePrice("") } 
        if(e === 85 && MarketValue === '0.00'){ setMarketValue("") } 
        if(e === 86 && BondAmount === '0.00'){ setBondAmount("") } 
    }

    const ShowZero = (e) => {
        // alert('dd');
        if(e === 1 && GrossMonthlySalary === ''){ setGrossMonthlySalary("0.00") } 
        if(e === 2 && Commission6MonthAvg === ''){ setCommission6MonthAvg("0.00") }
        if(e === 3 && Overtime6MonthAvg === ''){ setOvertime6MonthAvg("0.00") }
        if(e === 4 && MonthlyAllowance === ''){ setMonthlyAllowance("0.00") }
        if(e === 5 && MonthlyCarAllowance === ''){ setMonthlyCarAllowance("0.00") }
        if(e === 6 && PaySlipMAllowance === ''){ setPaySlipMAllowance("0.00") }
        if(e === 7 && AllOtherIncome === ''){ setAllOtherIncome("0.00") }
        if(e === 8 && TotalIncome === ''){ setTotalIncome("0.00") }
        if(e === 9 && MonthlyInvestment1 === ''){ setMonthlyInvestment1("0.00") }
        if(e === 10 && MonthlyInvestment2 === ''){ setMonthlyInvestment2("0.00") }
        if(e === 11 && Installment === ''){ setInstallment("0.00") }
        if(e === 12 && Settlement === ''){ setSettlement("0.00") }
        if(e === 13 && RentalValue === ''){ setRentalValue("0.00") }
        if(e === 14 && Surplus === ''){ setSurplus("0.00") }
        if(e === 15 && GranttedBouns === ''){ setGranttedBouns("0.00") }
        
        if(e === 21 && SocialSecurity === ''){ setSocialSecurity("0.00") }
        if(e === 22 && AssetFinance === ''){ setAssetFinance("0.00") }
        if(e === 23 && ShortTermInsurance === ''){ setShortTermInsurance("0.00") }
        if(e === 24 && FuneralPlan === ''){ setFuneralPlan("0.00") }
        if(e === 25 && InsurancePremiums === ''){ setInsurancePremiums("0.00") }
        if(e === 26 && ChildrenClothingEducation === ''){ setChildrenClothingEducation("0.00") }
        if(e === 27 && Domestic === ''){ setDomestic("0.00") }
        if(e === 28 && ClothingAccountPayment === ''){ setClothingAccountPayment("0.00") }
        if(e === 29 && Municipality === ''){ setMunicipality("0.00") }
        if(e === 30 && Groceries === ''){ setGroceries("0.00") }
        if(e === 31 && Levies === ''){ setLevies("0.00") }
        if(e === 32 && CellularTelephone === ''){ setCellularTelephone("0.00") }
        if(e === 33 && Dstv === ''){ setDstv("0.00") }
        if(e === 34 && Church === ''){ setChurch("0.00") }
        if(e === 35 && Gym === ''){ setGym("0.00") }
        if(e === 36 && TransportgVehicleMaintenanace === ''){ setTransportgVehicleMaintenanace("0.00") }
        if(e === 37 && UnitTrust === ''){ setUnitTrust("0.00") }
        if(e === 38 && InvestmentsBank === ''){ setInvestmentsBank("0.00") }
        if(e === 39 && CreditCards === ''){ setCreditCards("0.00") }

        if(e === 40 && Diners === ''){ setDiners("0.00") } 
        if(e === 41 && SpendingMoney === ''){ setSpendingMoney("0.00") } 
        if(e === 42 && OtherDebit === ''){ setOtherDebit("0.00") } 
        if(e === 43 && Taxation === ''){ setTaxation("0.00") } 
        if(e === 44 && Donations === ''){ setDonations("0.00") } 
        if(e === 45 && OtherExpenses2 === ''){ setOtherExpenses2("0.00") } 
        if(e === 46 && HouseHoldExpenses === ''){ setHouseHoldExpenses("0.00") } 
        if(e === 47 && AlomonyMaintenance === ''){ setAlomonyMaintenance("0.00") } 
        if(e === 48 && OtherExpenses3 === ''){ setOtherExpenses3("0.00") } 
        if(e === 49 && PensionFundContribution === ''){ setPensionFundContribution("0.00") } 
        if(e === 50 && WaterElectricity === ''){ setWaterElectricity("0.00") } 
        if(e === 51 && CientOther1 === ''){ setCientOther1("0.00") } 
        if(e === 52 && CientOther2 === ''){ setCientOther2("0.00") } 
        if(e === 53 && TotalExpenditure === ''){ setTotalExpenditure("0.00") }  

        if(e === 81 && BankFee === ''){ setBankFee("0.00") } 
        if(e === 82 && SpouseIncome === ''){ setSpouseIncome("0.00") } 
        if(e === 83 && MonthlyBond === ''){ setMonthlyBond("0.00") } 
        if(e === 84 && PurchasePrice === ''){ setPurchasePrice("0.00") } 
        if(e === 85 && MarketValue === ''){ setMarketValue("0.00") } 
        if(e === 86 && BondAmount === ''){ setBondAmount("0.00") } 

    }

    //----------- get print button details (hide/show) -----------------
    useEffect(() => { 
        const payload = {
            transaction_id: transaction_id,
            application_id: application_id,
            instance_id: instance_id,
            group_id: group_id,
        };    
        axios.post("finance_application/print_label_details",payload)
        .then((response) => {
            if(response.status === 200){
                //console.log(response);
                setPrintButtonData(response.data.data);
            
                if(response.data.data == 'Print'){
                   setPrintButton("Print");
                }else if(response.data.data == 'View Finance App'){
                    setPrintButton("View Finance App")
                }else{
                    setPrintButton("Print"); 
                }
            }
        })
        .catch(() => {
            console.log('error');
        });
    }, [PrintButtonVisible]); 

    //---------------- generate pdf and redirect url -------------
    const GeneratePrintPdf = ()=>{
        //alert('as');
        setLoader(true);
        const payload = {
            transaction_id: transaction_id,
            application_id: application_id,
            instance_id: instance_id,
            group_id: group_id,
            loanhouse_id: loanhouse_id,
            system_type: system_type,
            user_id: user_id,
            division_id: division_id,
            department: department_id,
            command: PrintButton,
        };
        //console.log(payload);setExpenditureHideShow
        axios.post("finance_application/finance_pdf_calling", payload)
        .then((response) => {
            //console.log(response);
            if(response.status == 200){
               //window.location.reload(false);
               navigate('/transaction/prepare/application_id/'+response.data.data+'/regenerated=true');
               setLoader(false);
            } 
        })
        .catch(() => {
           console.log('error');
        }); 
    }
    
    const OtherFieldHideShow = (type)=>{
        //console.log(type);
        if(type == 786){
            setPropertyHideShow("");
            setPropertyHideShow1("none");
          
            if(isNaN(HiddenSurplus)){
                var txt_surplus = HiddenSurplus.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(HiddenSurplus == ""){
                var txt_surplus = "0.00";
            }else{
                var txt_surplus = HiddenSurplus;
            }
           //console.log(txt_surplus);
           
            if(isNaN(MonthlyBond)){
                var txt_MonthlyBond = MonthlyBond.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(MonthlyBond == ""){
                var txt_MonthlyBond = "0.00";
            }else{
                var txt_MonthlyBond = MonthlyBond;
            }
            //console.log(txt_RentalValue);
           
            var new_surplus = parseFloat(txt_surplus)+parseFloat(txt_MonthlyBond);
            let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setSurplus(final_surplus);
            setHiddenSurplus(new_surplus);
            setMonthlyBond("0.00")

        }else if(type == 785){
            setPropertyHideShow1("");
            setPropertyHideShow("none");
            
            if(isNaN(HiddenSurplus)){
                var txt_surplus = HiddenSurplus.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(HiddenSurplus == ""){
                var txt_surplus = "0.00";
            }else{
                var txt_surplus = HiddenSurplus;
            }
           //console.log(txt_surplus);
            if(isNaN(RentalValue)){
                var txt_RentalValue = RentalValue.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(RentalValue == ""){
                var txt_RentalValue = "0.00";
            }else{
                var txt_RentalValue = RentalValue;
            }
           
            var new_surplus = parseFloat(txt_surplus)+parseFloat(txt_RentalValue);
            let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setSurplus(final_surplus);
            setHiddenSurplus(new_surplus);
            setRentalValue("0.00")
        }  
    }

    const Loan1HideShowTab = (type1)=>{
        //console.log(type1);
        if(type1 == 'no'){
            setLoan1HideShow("none");
            setMonthinstallReadonly("");
        }else if(type1 == 'yes'){
            setLoan1HideShow("");
            setMonthinstallReadonly("none");
        }  
    }

    const Loan2HideShowTab = (type2)=>{
        //console.log(type1);
        if(type2 == 'no'){
            setLoan2HideShow("none");
            setMonthinstallReadonly1("");
        }else if(type2 == 'yes'){
            setLoan2HideShow("");
            setMonthinstallReadonly1("none");
        }  
    }

    const OtherExistingHideShowTab = (type3)=>{
        if(type3 == 'no'){
            setOtherExistingHideShow("none");
        }else if(type3 == 'yes'){
            setOtherExistingHideShow("");
        }  
    }
    
    const ExpenditureHideShowTab = (type4)=>{
        if(type4 == 'no'){
            setExpenditureHideShow("none");
        }else if(type4 == 'yes'){
            setExpenditureHideShow("");
        }  
    }

    const DriverWiseNameHideShow = (dType)=>{
        //console.log(dType);
         if(dType == 1751){
             setDrivernameHideShow("");
         }else{
             setDrivernameHideShow("none");
         }  
     }

    const ClearInstallemnt = (Install_val)=>{
        //setInstallment("0.00");
        if(isNaN(HiddenSurplus)){
            var txt_surplus = HiddenSurplus.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(HiddenSurplus == ""){
            var txt_surplus = "0.00";
        }else{
            var txt_surplus = HiddenSurplus;
        }
       //console.log(txt_surplus);
        if(isNaN(Installment)){
            var txt_Installment = Installment.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(Installment == ""){
            var txt_Installment = "0.00";
        }else{
            var txt_Installment = Installment;
        }

        if(Install_val == 785){
            var new_surplus = parseFloat(txt_surplus)+parseFloat(txt_Installment);
            let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setSurplus(final_surplus);
            setHiddenSurplus(new_surplus);
        }else if(Install_val == 786){
            var new_surplus = parseFloat(txt_surplus)-parseFloat(txt_Installment);
            let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setSurplus(final_surplus);
            setHiddenSurplus(new_surplus);
        }
    }

    const Calculate = ()=>{
       
        if(isNaN(PaySlipMAllowance)) {
            var nett_monthly_income = PaySlipMAllowance.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(PaySlipMAllowance == ""){
            var nett_monthly_income = "0.00";
        }else{
            var nett_monthly_income = PaySlipMAllowance;
        }

        if(isNaN(AllOtherIncome)) {
            var all_other_income = AllOtherIncome.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(AllOtherIncome == ""){
            var all_other_income = "0.00";
        }else{
            var all_other_income = AllOtherIncome;
        }
        
        let total_income = parseFloat(nett_monthly_income)+parseFloat(all_other_income);
        let final_total_income = total_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setTotalIncome(final_total_income);
        setHiddenTotalIncome(total_income);
        
        if(isNaN(TotalExpenditure)) {
            var total_expenditure_new = TotalExpenditure.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(TotalExpenditure == ""){
            var total_expenditure_new = "0.00";
        }else{
            var total_expenditure_new = TotalExpenditure;
        }

        if(isNaN(MonthlyInvestment1)){
            var monthly_investment1 = MonthlyInvestment1.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(MonthlyInvestment1 == ""){
            var monthly_investment1 = "0.00";
        }else{
            var monthly_investment1 = MonthlyInvestment1;
        }

        if(isNaN(MonthlyInvestment2)) {
            var monthly_investment2 = MonthlyInvestment2.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(MonthlyInvestment2 == ""){
            var monthly_investment2 = "0.00";
        }else{
            var monthly_investment2 = MonthlyInvestment2;
        }
        
        if(ToBeSealed3 == '786'){   // condition no 
            if(isNaN(Installment)) {
                var total_installment = Installment.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(Installment == ""){
                var total_installment = "0.00";
            }else{
                var total_installment = Installment;
            }
        }else{
            var total_installment = "0.00";  // condition yes
        }
        
        if(OwnHome == '786'){
            if(isNaN(RentalValue)) {
                var rental_value = RentalValue.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(RentalValue == ""){
                var rental_value = "0.00";
            }else{
                var rental_value = RentalValue;
            }
        }else{
            var rental_value = "0.00";
        }

        if(OwnHome == '785'){
            if(isNaN(MonthlyBond)) {
                var monthly_bond = MonthlyBond.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(MonthlyBond == ""){
                var monthly_bond = "0.00";
            }else{
                var monthly_bond = MonthlyBond;
            }
        }else{
            var monthly_bond = "0.00";
        }

        var new_surplus = parseFloat(total_income)-parseFloat(total_expenditure_new)-parseFloat(monthly_investment1)-parseFloat(monthly_investment2)-parseFloat(rental_value)-parseFloat(monthly_bond)-parseFloat(total_installment);
        let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setSurplus(final_surplus);
        setHiddenSurplus(new_surplus);
    }

    const Calculate1 = ()=>{

        if(isNaN(SocialSecurity)) {
            var social_security = SocialSecurity.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(SocialSecurity == ""){
            var social_security = "0.00";
        }else{
            var social_security = SocialSecurity;
        }

        if(isNaN(AssetFinance)) {
            var asset_finance = AssetFinance.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(AssetFinance == ""){
            var asset_finance = "0.00";
        }else{
            var asset_finance = AssetFinance;
        }

        if(isNaN(ShortTermInsurance)) {
            var short_term_insurance = ShortTermInsurance.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(ShortTermInsurance == ""){
            var short_term_insurance = "0.00";
        }else{
            var short_term_insurance = ShortTermInsurance;
        }

        if(isNaN(FuneralPlan)) {
            var funeral_plan = FuneralPlan.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(FuneralPlan == ""){
            var funeral_plan = "0.00";
        }else{
            var funeral_plan = FuneralPlan;
        }

        if(isNaN(InsurancePremiums)) {
            var insurance_premiums = InsurancePremiums.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(InsurancePremiums == ""){
            var insurance_premiums = "0.00";
        }else{
            var insurance_premiums = InsurancePremiums;
        }

        if(isNaN(ChildrenClothingEducation)) {
            var children_clothing_education = ChildrenClothingEducation.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(ChildrenClothingEducation == ""){
            var children_clothing_education = "0.00";
        }else{
            var children_clothing_education = ChildrenClothingEducation;
        }

        if(isNaN(Domestic)) {
            var txt_domestic = Domestic.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(Domestic == ""){
            var txt_domestic = "0.00";
        }else{
            var txt_domestic = Domestic;
        }

        if(isNaN(ClothingAccountPayment)) {
            var clothing_account_payment = ClothingAccountPayment.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(ClothingAccountPayment == ""){
            var clothing_account_payment = "0.00";
        }else{
            var clothing_account_payment = ClothingAccountPayment;
        }

        if(isNaN(Municipality)) {
            var txt_Municipality = Municipality.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(Municipality == ""){
            var txt_Municipality = "0.00";
        }else{
            var txt_Municipality = Municipality;
        }

        if(isNaN(Groceries)) {
            var groceries = Groceries.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(Groceries == ""){
            var groceries = "0.00";
        }else{
            var groceries = Groceries;
        }

        if(isNaN(Levies)) {
            var txt_levies = Levies.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(Levies == ""){
            var txt_levies = "0.00";
        }else{
            var txt_levies = Levies;
        }

        if(isNaN(CellularTelephone)) {
            var txt_CellularTelephone = CellularTelephone.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(CellularTelephone == ""){
            var txt_CellularTelephone = "0.00";
        }else{
            var txt_CellularTelephone = CellularTelephone;
        }

        if(isNaN(Dstv)) {
            var txt_Dstv = Dstv.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(Dstv == ""){
            var txt_Dstv = "0.00";
        }else{
            var txt_Dstv = Dstv;
        }

        if(isNaN(Church)) {
            var txt_Church = Church.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(Church == ""){
            var txt_Church = "0.00";
        }else{
            var txt_Church = Church;
        }

        if(isNaN(Gym)) {
            var txt_Gym = Gym.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(Gym == ""){
            var txt_Gym = "0.00";
        }else{
            var txt_Gym = Gym;
        }

        if(isNaN(TransportgVehicleMaintenanace)) {
            var txt_TransportgVehicleMaintenanace = TransportgVehicleMaintenanace.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(TransportgVehicleMaintenanace == ""){
            var txt_TransportgVehicleMaintenanace = "0.00";
        }else{
            var txt_TransportgVehicleMaintenanace = TransportgVehicleMaintenanace;
        }

        if(isNaN(UnitTrust)) {
            var txt_UnitTrust = UnitTrust.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(UnitTrust == ""){
            var txt_UnitTrust = "0.00";
        }else{
            var txt_UnitTrust = UnitTrust;
        }

        if(isNaN(InvestmentsBank)) {
            var txt_investments_Bank = InvestmentsBank.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(InvestmentsBank == ""){
            var txt_investments_Bank = "0.00";
        }else{
            var txt_investments_Bank = InvestmentsBank;
        }

        if(isNaN(CreditCards)) {
            var txt_CreditCards = CreditCards.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(CreditCards == ""){
            var txt_CreditCards = "0.00";
        }else{
            var txt_CreditCards = CreditCards;
        }

        if(isNaN(Diners)) {
            var txt_Diners = Diners.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(Diners == ""){
            var txt_Diners = "0.00";
        }else{
            var txt_Diners = Diners;
        }

        if(isNaN(SpendingMoney)) {
            var txt_SpendingMoney = SpendingMoney.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(SpendingMoney == ""){
            var txt_SpendingMoney = "0.00";
        }else{
            var txt_SpendingMoney = SpendingMoney;
        }

        if(isNaN(OtherDebit)) {
            var txt_OtherDebit = OtherDebit.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(OtherDebit == ""){
            var txt_OtherDebit = "0.00";
        }else{
            var txt_OtherDebit = OtherDebit;
        }

        if(isNaN(Taxation)) {
            var txt_Taxation = Taxation.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(Taxation == ""){
            var txt_Taxation = "0.00";
        }else{
            var txt_Taxation = Taxation;
        }

        if(isNaN(Donations)) {
            var txt_Donations = Donations.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(Donations == ""){
            var txt_Donations = "0.00";
        }else{
            var txt_Donations = Donations;
        }

        if(isNaN(OtherExpenses2)) {
            var txt_OtherExpenses2 = OtherExpenses2.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(OtherExpenses2 == ""){
            var txt_OtherExpenses2 = "0.00";
        }else{
            var txt_OtherExpenses2 = OtherExpenses2;
        }

        if(isNaN(HouseHoldExpenses)) {
            var txt_HouseHoldExpenses = HouseHoldExpenses.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(HouseHoldExpenses == ""){
            var txt_HouseHoldExpenses = "0.00";
        }else{
            var txt_HouseHoldExpenses = HouseHoldExpenses;
        }

        if(isNaN(AlomonyMaintenance)) {
            var txt_AlomonyMaintenance = AlomonyMaintenance.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(AlomonyMaintenance == ""){
            var txt_AlomonyMaintenance = "0.00";
        }else{
            var txt_AlomonyMaintenance = AlomonyMaintenance;
        }

        if(isNaN(OtherExpenses3)) {
            var txt_OtherExpenses3 = OtherExpenses3.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(OtherExpenses3 == ""){
            var txt_OtherExpenses3 = "0.00";
        }else{
            var txt_OtherExpenses3 = OtherExpenses3;
        }

        if(isNaN(PensionFundContribution)) {
            var txt_PensionFundContribution = PensionFundContribution.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(PensionFundContribution == ""){
            var txt_PensionFundContribution = "0.00";
        }else{
            var txt_PensionFundContribution = PensionFundContribution;
        }

        if(isNaN(WaterElectricity)) {
            var txt_WaterElectricity = WaterElectricity.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(WaterElectricity == ""){
            var txt_WaterElectricity = "0.00";
        }else{
            var txt_WaterElectricity = WaterElectricity;
        }

        if(isNaN(CientOther1)) {
            var txt_CientOther1 = CientOther1.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(CientOther1 == ""){
            var txt_CientOther1 = "0.00";
        }else{
            var txt_CientOther1 = CientOther1;
        }

        if(isNaN(CientOther2)) {
            var txt_CientOther2 = CientOther2.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(CientOther2 == ""){
            var txt_CientOther2 = "0.00";
        }else{
            var txt_CientOther2 = CientOther2;
        }

         let total_expenditure = parseFloat(social_security)+parseFloat(asset_finance)+parseFloat(short_term_insurance)+parseFloat(funeral_plan)+parseFloat(insurance_premiums)+parseFloat(children_clothing_education)+parseFloat(txt_domestic)+parseFloat(clothing_account_payment)+parseFloat(groceries)+parseFloat(txt_levies)+parseFloat(txt_CellularTelephone)+parseFloat(txt_Dstv)+parseFloat(txt_Church)+parseFloat(txt_Gym)+parseFloat(txt_TransportgVehicleMaintenanace)+parseFloat(txt_UnitTrust)+parseFloat(txt_investments_Bank)+parseFloat(txt_CreditCards)+parseFloat(txt_Diners)+parseFloat(txt_SpendingMoney)+parseFloat(txt_OtherDebit)+parseFloat(txt_Taxation)+parseFloat(txt_OtherExpenses2)+parseFloat(txt_HouseHoldExpenses)+parseFloat(txt_AlomonyMaintenance)+parseFloat(txt_OtherExpenses3)+parseFloat(txt_PensionFundContribution)+parseFloat(txt_WaterElectricity)+parseFloat(txt_CientOther1)+parseFloat(txt_CientOther2)+parseFloat(txt_Donations)+parseFloat(txt_Municipality);
     
        let final_total_expenditure = total_expenditure.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // console.log(final_total_expenditure);
        setTotalExpenditure(final_total_expenditure);
        
        //----------------
        if(isNaN(HiddenTotalIncome)) {
            var all_total_income = HiddenTotalIncome.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(HiddenTotalIncome == ""){
            var all_total_income = "0.00";
        }else{
            var all_total_income = HiddenTotalIncome;
        }

        if(isNaN(MonthlyInvestment1)){
            var monthly_investment1 = MonthlyInvestment1.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(MonthlyInvestment1 == ""){
            var monthly_investment1 = "0.00";
        }else{
            var monthly_investment1 = MonthlyInvestment1;
        }

        if(isNaN(MonthlyInvestment2)) {
            var monthly_investment2 = MonthlyInvestment2.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(MonthlyInvestment2 == ""){
            var monthly_investment2 = "0.00";
        }else{
            var monthly_investment2 = MonthlyInvestment2;
        }

        if(ToBeSealed3 == '786'){   // condition no 
            if(isNaN(Installment)) {
                var total_installment = Installment.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(Installment == ""){
                var total_installment = "0.00";
            }else{
                var total_installment = Installment;
            }
        }else{
            var total_installment = "0.00";  // condition yes
        }

        if(OwnHome == '786'){
            if(isNaN(RentalValue)) {
                var rental_value = RentalValue.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(RentalValue == ""){
                var rental_value = "0.00";
            }else{
                var rental_value = RentalValue;
            }
        }else{
            var rental_value = "0.00";
        }

        if(OwnHome == '785'){
            if(isNaN(MonthlyBond)) {
                var monthly_bond = MonthlyBond.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(MonthlyBond == ""){
                var monthly_bond = "0.00";
            }else{
                var monthly_bond = MonthlyBond;
            }
        }else{
            var monthly_bond = "0.00";
        }

        var new_surplus = parseFloat(all_total_income)-parseFloat(social_security)-parseFloat(asset_finance)-parseFloat(short_term_insurance)-parseFloat(funeral_plan)-parseFloat(insurance_premiums)-parseFloat(children_clothing_education)-parseFloat(txt_domestic)-parseFloat(clothing_account_payment)-parseFloat(groceries)-parseFloat(txt_levies)-parseFloat(txt_CellularTelephone)-parseFloat(txt_Dstv)-parseFloat(txt_Church)-parseFloat(txt_Gym)-parseFloat(txt_TransportgVehicleMaintenanace)-parseFloat(txt_UnitTrust)-parseFloat(txt_investments_Bank)-parseFloat(txt_CreditCards)-parseFloat(txt_Diners)-parseFloat(txt_SpendingMoney)-parseFloat(txt_OtherDebit)-parseFloat(txt_Taxation)-parseFloat(txt_OtherExpenses2)-parseFloat(txt_HouseHoldExpenses)-parseFloat(txt_AlomonyMaintenance)-parseFloat(txt_OtherExpenses3)-parseFloat(txt_PensionFundContribution)-parseFloat(txt_WaterElectricity)-parseFloat(txt_CientOther1)-parseFloat(txt_CientOther2)-parseFloat(txt_Donations)-parseFloat(txt_Municipality)-parseFloat(monthly_investment1)-parseFloat(monthly_investment2)-parseFloat(rental_value)-parseFloat(monthly_bond)-parseFloat(total_installment);

        let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setSurplus(final_surplus);
    }
    
    const Calculate2 = ()=>{
      // console.log(Surplus);
        if(isNaN(HiddenTotalIncome)) {
            var total_income1 = HiddenTotalIncome.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(HiddenTotalIncome == ""){
            var total_income1 = "0.00";
        }else{
            var total_income1 = HiddenTotalIncome;
        }

        // if(isNaN(TotalExpenditure)) {
        //     var total_expenditure = TotalExpenditure.replace(/,(?=.*\.\d+)/g, ''); 
        // }else if(TotalExpenditure == ""){
        //     var total_expenditure = "0.00";
        // }else{
        //     var total_expenditure = TotalExpenditure;
        // }

        if(isNaN(TotalExpenditure)) {
            // var total_expenditure_new = TotalExpenditure.replace(/,(?=.*\.\d+)/g, ''); 
             var total_expenditure_new = parseFloat(TotalExpenditure.replaceAll(",", ""))
             // console.log(pp)
        }else if(TotalExpenditure == ""){
             var total_expenditure_new = "0.00";
        }else{
             var total_expenditure_new = TotalExpenditure;
        }

        if(isNaN(MonthlyInvestment1)){
            var monthly_investment1 = MonthlyInvestment1.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(MonthlyInvestment1 == ""){
            var monthly_investment1 = "0.00";
        }else{
            var monthly_investment1 = MonthlyInvestment1;
        }

        if(isNaN(MonthlyInvestment2)) {
            var monthly_investment2 = MonthlyInvestment2.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(MonthlyInvestment2 == ""){
            var monthly_investment2 = "0.00";
        }else{
            var monthly_investment2 = MonthlyInvestment2;
        }

        if(ToBeSealed3 == '786'){   // condition no 
            if(isNaN(Installment)) {
                var total_installment = Installment.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(Installment == ""){
                var total_installment = "0.00";
            }else{
                var total_installment = Installment;
            }
        }else{
            var total_installment = "0.00";  // condition yes
        }

        if(OwnHome == '786'){
            if(isNaN(RentalValue)) {
                var rental_value = RentalValue.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(RentalValue == ""){
                var rental_value = "0.00";
            }else{
                var rental_value = RentalValue;
            }
        }else{
            var rental_value = "0.00";
        }

        if(OwnHome == '785'){
            if(isNaN(MonthlyBond)) {
                var monthly_bond = MonthlyBond.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(MonthlyBond == ""){
                var monthly_bond = "0.00";
            }else{
                var monthly_bond = MonthlyBond;
            }
        }else{
            var monthly_bond = "0.00";
        }

        var new_surplus = parseFloat(total_income1)-parseFloat(monthly_investment1)-parseFloat(monthly_investment2)-total_expenditure_new-parseFloat(rental_value)-parseFloat(monthly_bond)-parseFloat(total_installment);
        let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setSurplus(final_surplus);
        setHiddenSurplus(new_surplus); 
    }

    const ClearMonthlyInstallment = (type)=>{
        if(type === '786'){
            setMonthlyInvestment1("0.00");
            setMonthlyInvestment2("0.00");
            
            if(isNaN(HiddenSurplus)){
                var txt_surplus = HiddenSurplus.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(HiddenSurplus == ""){
                var txt_surplus = "0.00";
            }else{
                var txt_surplus = HiddenSurplus;
            }
           //console.log(txt_surplus);
            if(isNaN(MonthlyInvestment1)){
                var monthly_investment1 = MonthlyInvestment1.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(MonthlyInvestment1 == ""){
                var monthly_investment1 = "0.00";
            }else{
                var monthly_investment1 = MonthlyInvestment1;
            }
    
            if(isNaN(MonthlyInvestment2)) {
                var monthly_investment2 = MonthlyInvestment2.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(MonthlyInvestment2 == ""){
                var monthly_investment2 = "0.00";
            }else{
                var monthly_investment2 = MonthlyInvestment2;
            }
           
            var new_surplus = parseFloat(txt_surplus)-parseFloat(monthly_investment1)-parseFloat(monthly_investment2);
            let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setSurplus(final_surplus);
            setHiddenSurplus(new_surplus);
        }
    }

    const OnchangeEmpCateWiseHideSHow = (type) => {
        // console.log(type);
        if(type == 795){
            setSelfEmpHideShow("")
            setParmentHideShow("")
        }else if(type == 798){
            setParmentHideShow("none")
            setSelfEmpHideShow("none")
        }else{
            setSelfEmpHideShow("none")
            setParmentHideShow("")
        }
    }

    //let panFlag = 1;

    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px", marginLeft: "-200px" }} />
            <Transaction_menu panel_flag={HeaderFlag}/>
            {/* <form onSubmit={handleSubmit}> */}
            {loader ? <Loader /> : null}

                <table width="100%" height="800" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td width="100%">
                                <p align="center">
                                     <img height="70"  border="0" src={ford_finance_img} alt=""/>
                                </p>
                                <table width="100%" border={0} cellSpacing={5} cellPadding={5} >
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center">
                                                <table className="defaultTbl" cellSpacing={1} align="center" width={tabWidth}>
                                                    <tbody>
                                                        <tr className="caption" id="group_list">
                                                            <th colSpan={(ClientDetailFlag && 4) || (EmployeeDetailFlag && 11) || (FinancialDetailFlag && 4) || (LoanDetailFlag && 4) || (SpouseDetailFlag && 4) || (ContactDetailFlag && 11)} style={{ textAlign: 'center' }}>

                                                                <input type="button" name="command" value="Client Details" onClick={() => { ChangeNavigation("Client Details") }} />
                                                           
                                                                <input type="button" name="command" value="Employer Details" onClick={() => { ChangeNavigation("Employer Details") }} />
                                                           
                                                                <input type="button" name="command" value="Client's Financial Details" onClick={() => { ChangeNavigation("Client's Financial Details") }} />
                                                           
                                                                <input type="button" name="command" value="Loan Specifics" onClick={() => { ChangeNavigation("Loan Specifics") }} />
                                                           
                                                                <input type="button" name="command" value="Spouse Details" onClick={() => { ChangeNavigation("Spouse Details") }} />
                                
                                                                <input type="button" name="command" value="Contact Details" onClick={() => { ChangeNavigation("Contact Details") }} /></th>
                                                        </tr>
                                                    </tbody>
                                                </table>

       {/*------------------ Client Details -----------------------------------------  */}
                                           <form name="clientDetails" method="post" onSubmit={handleClientDetailsSubmit} >            
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="950" style={{ display: div_id1 }}>
                                                    <tbody>
                                                        {ClientDetailFlag ?
                                                            <>
                                                                <tr className="caption">
                                                                    <th colSpan={4} style={{ textAlign: 'center' }}>CLIENT DETAILS</th>
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                        CLIENT DETAILS
                                                                    </td>

                                                                    <td style={{ textAlign: "left", width: "25%" }}>
                                                                        <select id="test" name="sub_1_field_930" className="text-uppercase" value={ClientDetails} onChange = {(e) => { setClientDetails(e.target.value); OnClientWiseChange(e.target.value); }} >
                                                                        <option value="0">-- Select An Option --</option>
                                                                          {ClientDetailsAll != null ?
                                                                            ClientDetailsAll.map((item) => {
                                                                                return (
                                                                                <option key={item.id} value={item.id}>
                                                                                {item.name}
                                                                                </option>
                                                                                );
                                                                            })
                                                                          : null }
                                                                        </select>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        ID NUMBER
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_934" type="text" value={IDNumber} onChange={(e) => { setIDNumber(e.target.value) }} className="text-uppercase"/>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                        TITLE
                                                                    </td>
                                                                    <td style={{ textAlign: "left", width: "25%" }}>
                                                                       
                                                                        <select id="test" name="sub_1_field_931" className="text-uppercase" value={Title} onChange = {(e) => { setTitle(e.target.value) }} >
                                                                            <option value="0">-- Select An Option --</option>
                                                                            {TitleAll.length > 0 ?
                                                                               TitleAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.title}
                                                                                    </option>
                                                                                    );
                                                                              })
                                                                         : null }
                                                                        </select>
                                                                    </td>

                                                                    <td className='caption'>
                                                                       <span style={{ color: ValidateColor }}> FIRST NAME</span> <span style={{ color: "red" }}>*</span>
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_932" type="text" value={Firstname} onChange={(e) => { setFirstname(e.target.value) }} className="text-uppercase"/>
                                                                    </td>

                                                                    {/* <td className='caption'>
                                                                        INITIALS
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1048" type="text" value={Initials} onChange={(e) => { setInitials(e.target.value) }} />
                                                                    </td> */}
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                    <span style={{ color: ValidateColor1 }}> SURNAME</span> <span style={{ color: "red" }}>*</span>
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_933" type="text" value={Surname} onChange={(e) => { setSurname(e.target.value) }} className="text-uppercase"/>
                                                                    </td>
                                                                    
                                                                    <td className='caption'>
                                                                        DATE OF BIRTH
                                                                    </td>
                                                                    
                                                                    <td>
                                                                        {   BirthdateFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_935" selected={DateOfBirth} name="sub_1_field_935" onChange={(date) => { setDateOfBirth(date); setBirthdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_935" value={DateOfBirth} name="sub_1_field_935"  onChange={(date) => { setDateOfBirth(date); setBirthdateFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                        }
                                                                    </td>
   
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                       <span> PLACE OF BIRTH </span>
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_1729" type="text" value={PlaceOfBirth} onChange={(e) => { setPlaceOfBirth(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        MARITAL STATUS
                                                                    </td>

                                                                    <td>
                                                                        <select name="sub_1_field_946" className="text-uppercase" value={MaritalStatus} onChange = {(e) => { setMaritalStatus(e.target.value) }}> 
                                                                        <option value="0">-- Select An Option --</option>
                                                                           {MaritalStatusAll.length > 0 ?
                                                                             MaritalStatusAll.map((item) => {
                                                                                return (
                                                                                <option key={item.id} value={item.id}>
                                                                                {item.marital_status}
                                                                                </option>
                                                                                );
                                                                             })
                                                                            : null }
                                                                        </select>
                                                                    </td>
                                                                    
                                                                </tr>
                                                                <tr>
                                                                   
                                                                    <td className='caption'>
                                                                        RESIDENCY STATUS
                                                                    </td>
                                                                    <td>
                                                                        <select name="sub_1_field_1038" id="sub_1_field_1038" className="text-uppercase" value={ResidencyStatus} onChange = {(e) => { setResidencyStatus(e.target.value) }}>
                                                                            <option value="0">-- Select an option --</option>
                                                                            {ResidencyStatusAll.length > 0 ?
                                                                                ResidencyStatusAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.res_status}
                                                                                    </option>
                                                                                    );
                                                                                })
                                                                            : null }
                                                                        </select>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        HOME NUMBER
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_936" type="text" value={HomeNumber} onChange={(e) => { setHomeNumber(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    
                                                                    <td className='caption'>
                                                                        WORK NUMBER
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_938" type="text" value={WorkNumber} onChange={(e) => { setWorkNumber(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        HIGHEST LEVEL OF EDUCATION	
                                                                    </td>
                                                                    <td>
                                                                        <select name="sub_1_field_1752" id="sub_1_field_1752" className="text-uppercase" value={HighestEducation} onChange = {(e) => { setHighestEducation(e.target.value) }}>
                                                                            <option value="0">-- Select an option --</option>
                                                                            {HighestEducationAll.length > 0 ?
                                                                                HighestEducationAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.type}
                                                                                    </option>
                                                                                    );
                                                                                })
                                                                            : null }
                                                                        </select>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        MOBILE NUMBER
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_939" type="text" value={MobileNumber} onChange={(e) => { setMobileNumber(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        FAX NUMBER
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1527" type="text" value={FixNumber} onChange={(e) => { setFixNumber(e.target.value) }} className="text-uppercase" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                    <span style={{ color: ValidateColor2 }}> EMAIL</span> 
                                                                         <span style={{ color: "red" }}>*</span>
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_940" type="text" value={Email} onChange={(e) => { setEmail(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        GENDER
                                                                    </td>
                                                                    
                                                                    <td>
                                                                        <select name="sub_1_field_945" id="gender_select" className="text-uppercase" value={Gender} onChange = {(e) => setGender(e.target.value)} >
                                                                            <option value="0">-- Select An Option --</option>
                                                                            {GenderAll.length > 0 ?
                                                                                GenderAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.gender}
                                                                                    </option>
                                                                                    );
                                                                                })
                                                                            : null }
                                                                         </select>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        PHYSICAL ADDRESS LINE 1
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1497" type="text" value={PhysicalAddress1} onChange={(e) => { setPhysicalAddress1(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        POSTAL ADDRESS LINE 1
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_1500" type="text" value={PostalAddress1} onChange={(e) => { setPostalAddress1(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        PHYSICAL ADDRESS LINE 2
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1498" type="text" value={PhysicalAddress2} onChange={(e) => { setPhysicalAddress2(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        POSTAL ADDRESS LINE 2
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_1501" type="text" value={PostalAddress2} onChange={(e) => { setPostalAddress2(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        PHYSICAL ADDRESS CITY
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1511" type="text" value={PhysicalAddressCity} onChange={(e) => { setPhysicalAddressCity(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        POSTAL ADDRESS CITY
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_1513" type="text" value={PostalAddressCity} onChange={(e) => { setPostalAddressCity(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                </tr> 

                                                                <tr>
                                                                    <td className='caption'>
                                                                        PHYSICAL ADDRESS POSTAL CODE
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_941" type="text" value={PhysicalAddressPostCode} onChange={(e) => { setPhysicalAddressPostCode(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        POSTAL ADDRESS CODE	
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_943" type="text" value={PostalAddressCode} onChange={(e) => { setPostalAddressCode(e.target.value) }} className="text-uppercase" />
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                        HOME VILLAGE	
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_1732" type="text" value={HomeVillage} onChange={(e) => { setHomeVillage(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        NEAREST LANDMARK
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1731" type="text" value={NearestLandmark} onChange={(e) => { setNearestLandmark(e.target.value) }} className="text-uppercase" />
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                        DATE MARRIED
                                                                    </td>
                                                                    <td>
                                                                        {   MarrieddateFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_1466" selected={MarriedDate} name="sub_1_field_1466" onChange={(date) => { setMarriedDate(date); setMarrieddateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_1466" value={MarriedDate} name="sub_1_field_1466"  onChange={(date) => { setMarriedDate(date); setMarrieddateFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                        }
                                                                    </td>

                                                                    <td className='caption'>
                                                                        RESIDENTIAL STATUS
                                                                    </td>
                                                                    <td>
                                                                        <select name="sub_1_field_951" className="text-uppercase" value={ResidentialStatus} onChange = {(e) => setResidentialStatus(e.target.value)} >
                                                                           <option value="0">-- Select an option --</option>
                                                                            {ResidentialStatusAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.status}
                                                                                    </option>
                                                                                    );
                                                                            })}
                                                                        </select>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                        OCCUPANCY DATE AT CURRENT ADDRESS
                                                                    </td>

                                                                    <td>
                                                                        {   CurrentdateFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_949" selected={OccupancyCurrentDate} name="sub_1_field_949" onChange={(date) => { setOccupancyCurrentDate(date); setCurrentdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_949" value={OccupancyCurrentDate} name="sub_1_field_949"  onChange={(date) => { setOccupancyCurrentDate(date); setCurrentdateFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                        }  
                                                                    </td>
                                                                   
                                                                    <td className='caption'>
                                                                        OCCUPANCY DATE AT PREVIOUS ADDRESS
                                                                    </td>
                                                                    <td>
                                                                        {   PrevdateFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_950" selected={OccupancyPreviousDate} name="sub_1_field_950" onChange={(date) => { setOccupancyPreviousDate(date); setPrevdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_950" value={OccupancyPreviousDate} name="sub_1_field_950"  onChange={(date) => { setOccupancyPreviousDate(date); setPrevdateFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                        }  
                         
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                        INDIVIDUAL
                                                                    </td>
                             
                                                                    <td>
                                                                        <select name="sub_1_field_1769" id="sub_1_field_1769" className="text-uppercase" value={ContactIndividual} onChange = {(e) => { setContactIndividual(e.target.value) }}>
                                                                            <option value="0">-- Select an option --</option>
                                                                            {IndividualAll.length > 0 ?
                                                                                IndividualAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.type}
                                                                                    </option>
                                                                                    );
                                                                                })
                                                                            : null }
                                                                        </select>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        STAFF INDICATOR	
                                                                    </td>

                                                                    <td>
                                                                        <select name="sub_1_field_1770" id="sub_1_field_1770" className="text-uppercase" value={StaffIndicator} onChange = {(e) => { setStaffIndicator(e.target.value) }}>
                                                                            <option value="0">-- Select an option --</option>
                                                                            {StaffIndicatorAll.length > 0 ?
                                                                                StaffIndicatorAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.type}
                                                                                    </option>
                                                                                    );
                                                                                })
                                                                            : null }
                                                                        </select>
                                                                    </td>

                                                                    
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        NUMBER OF DEPENDANTS
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_1535" type="text" value={Dependant} onChange={(e) => { setDependant(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td colSpan={2}></td>
                                                                </tr>

                                                                <tr className="caption text-center" >
                                                                    <td colSpan={4} style={{ textAlign: "center" }}>
                                                                    
                                                                        { PrintButtonData != '' ?
                                                                            <input type="submit" name="command" value={PrintButton} onClick={(e) => { setPrintButton(e.target.value); setClientDetSave(e.target.value); }} />
                                                                        : null }
                                                                        &nbsp;

                                                                        <input type="submit" name="Save" value="Save" onClick={(e) => { setClientDetSave(e.target.value) }} />&nbsp;
                                                                        <input type="submit" name="Save and Exit" value="Save and Exit" onClick={(e) => { setClientDetSave(e.target.value) }}/>&nbsp;
                                                                        
                                                                        <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                            <input type="button" className="p-relative" value="Cancel" />
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            </> : null}
                                                        
                                                    </tbody>
                                                </table>
                                        </form>

 {/*------------------ Employee Details -----------------------------------------  */}
                                           <form name="emp_details" method="post" onSubmit={handleAddEditEmployee} >
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="800" style={{ display: div_id2 }}>
                                                    <tbody>
                                                                <tr className="caption">
                                                                    <th colSpan={11} style={{ textAlign: 'center' }}>EMPLOYER DETAILS </th>
                                                                </tr>
                                                                
                                                                <tr>
                                                                    <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                        <span style={{ color: colorFocus1 }}> INCOME CATEGORY</span> <span style={{ color: "red" }}>*</span>
                                                                    </td>

                                                                    <td style={{ textAlign: "left", width: "25%" }}>
                                                                        <select id="test" name="sub_1_field_986" className="text-uppercase" value={EmpIncomeCategory} onChange = {(e) => { setEmpIncomeCategory(e.target.value); OnchangeEmpCateWiseHideSHow(e.target.value); }} >
                                                                            <option value="0">-- Select An Option --</option>
                                                                            {EmpIncomeCategoryAll.length > 0 ?
                                                                                EmpIncomeCategoryAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.type}
                                                                                    </option>
                                                                                    );
                                                                                })
                                                                            : null }
                                                                        </select>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        EMPLOYER
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_977" type="text" value={EmpEmployer} onChange={(e) => { setEmpEmployer(e.target.value) }} className="text-uppercase"/>
                                                                    </td>
                                                                </tr>

                                                                    {/* self employee wise hide show */}
                                                                
                                                                <tr style={{display: SelfEmpHideShow}} >
                                                                    <td className='caption'>
                                                                        PERIOD SELF-EMPLOYED	
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1516" type="text" value={PeriodSselfEmployee} onChange={(e) => { setPeriodSselfEmployee(e.target.value) }} className="text-uppercase"/>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        FINANCIALS SUBMITTED		
                                                                    </td>
                                                                    <td> 
                                                                           <input type="radio" name="sub_1_field_1517"  value={FinancialsSubmitted == '786' ? FinancialsSubmitted : '786'} checked={FinancialsSubmitted == '786'} onChange={(e) => { setFinancialsSubmitted(e.target.value) }} />
                                                                            &nbsp;No
                                                                            <br />
                                                                            <input type="radio" name="sub_1_field_1517" value={FinancialsSubmitted == '785' ? FinancialsSubmitted : '785'} checked={FinancialsSubmitted == '785'} onChange={(e) => { setFinancialsSubmitted(e.target.value) }} />
                                                                            &nbsp;Yes
                                                                    </td>
                                                                </tr>

                                                                <tr style={{display: SelfEmpHideShow}}>
                                                                   
                                                                    <td className='caption'>
                                                                        LAST 6 MONTHS BANK STATEMENTS SUBMITTED		
                                                                    </td>
                                                                    <td> 
                                                                        <input type="radio" name="sub_1_field_1518"  value={Last6MonthStmtSubmitted == '786' ? Last6MonthStmtSubmitted : '786'} checked={Last6MonthStmtSubmitted == '786'} onChange={(e) => { setLast6MonthStmtSubmitted(e.target.value) }} />
                                                                            &nbsp;No
                                                                            <br />
                                                                        <input type="radio" name="sub_1_field_1518" value={Last6MonthStmtSubmitted == '785' ? Last6MonthStmtSubmitted : '785'} checked={Last6MonthStmtSubmitted == '785'} onChange={(e) => { setLast6MonthStmtSubmitted(e.target.value) }} />
                                                                            &nbsp;Yes
                                                                    </td>

                                                                    <td className='caption'>
                                                                        &nbsp;	
                                                                    </td>
                                                                    <td>
                                                                        &nbsp;
                                                                    </td>
                                                                </tr>
                                                                
                                                                {/* end */}
                                                                
                                                                <tr>
                                                                    <td className='caption'>
                                                                        <span style={{ color: colorFocus3 }}> OCCUPATION </span> <span style={{ color: "red" }}>*</span>
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_979" type="text" value={EmpOccupation} onChange={(e) => { setEmpOccupation(e.target.value) }} className="text-uppercase"/>
                                                                    </td>

                                                                    <td className='caption'>
                                                                       EMPLOYED FROM	
                                                                    </td>
                                                                    <td>
                                                                        {   EmployeeFromFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_980" selected={EmployeeFrom} name="sub_1_field_980" onChange={(date) => { setEmployeeFrom(date); setEmployeeFromFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off"  filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_980" value={EmployeeFrom} name="sub_1_field_980"  onChange={(date) => { setEmployeeFrom(date); setEmployeeFromFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off"  filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                        }
                                                                    </td>
                                                                </tr>

                                                                <tr style={{display: ParmentHideShow}}>
                                                                    <td className='caption'>
                                                                        EMPLOYED TO
                                                                    </td>
                                                                    <td>
                                                                        {   EmployeeToFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_981" selected={EmployeeTo} name="sub_1_field_981" onChange={(date) => { setEmployeeTo(date); setEmployeeToFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off"  filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_981" value={EmployeeTo} name="sub_1_field_981"  onChange={(date) => { setEmployeeTo(date); setEmployeeToFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off"  filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                        }
                                                                    </td>

                                                                    <td className='caption'>
                                                                       EMPLOYEE #	
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_982" type="text" value={EmpEmpoyeeHash} onChange={(e) => { setEmpEmpoyeeHash(e.target.value) }} className="text-uppercase"/>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                        DESIGNATION
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1755" type="text" value={Designation} onChange={(e) => { setDesignation(e.target.value) }} className="text-uppercase"/>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        INDUSTRY / SECTOR		
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1753" type="text" value={IndustrySector} onChange={(e) => { setIndustrySector(e.target.value) }} className="text-uppercase"/>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                        OCCUPATION LEVEL	
                                                                    </td>
                                                                    <td>
                                                                    
                                                                        <select id="test" name="sub_1_field_1871" className="text-uppercase" value={OccupationLevel} onChange = {(e) => { setOccupationLevel(e.target.value) }} >
                                                                            <option value="0">-- Select An Option --</option>
                                                                            {OccupationLevelAll.length > 0 ?
                                                                                OccupationLevelAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.name}
                                                                                    </option>
                                                                                    );
                                                                                })
                                                                            : null }
                                                                        </select>

                                                                    </td>

                                                                    <td className='caption'>
                                                                        CONTACT NUMBER	
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_985" type="text" value={EmpContactNo} onChange={(e) => { setEmpContactNo(e.target.value) }} className="text-uppercase"/>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                    <span style={{ color: colorFocus }}> SALARY DATE</span> <span style={{ color: "red" }}>*</span>
                                                                    </td>
                                                                    <td>
                                                                        <select id="test" name="sub_1_field_1069" className="text-uppercase" value={EmpSalaryDate} onChange = {(e) => { setEmpSalaryDate(e.target.value) }} >
                                                                            <option value="0">-- Select An Option --</option>
                                                                            {EmpSalaryDateAll.length > 0 ?
                                                                                EmpSalaryDateAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.date}
                                                                                    </option>
                                                                                    );
                                                                                })
                                                                            : null }
                                                                        </select>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        SUPERVISOR
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1077" type="text" value={EmpSupervisor} onChange={(e) => { setEmpSupervisor(e.target.value) }} className="text-uppercase"/>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                         EMPLOYER ADDRESS
                                                                    </td>
                                                                    <td>
                                                                        <textarea name="sub_1_field_984" type="text" value={EmpAddress} onChange={(e) => { setEmpAddress(e.target.value) }} className="text-uppercase"></textarea>
                                                                      
                                                                    </td>

                                                                    <td className='caption'>
                                                                        TOWN / CITY	
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1754" type="text" value={EmpTownCity} onChange={(e) => { setEmpTownCity(e.target.value) }} className="text-uppercase"/>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                        CONTRACT EXPIRY DATE
                                                                    </td>
                                                                    <td>
                                                                        {   ContractExpDateFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_1762" selected={ContractExpDate} name="sub_1_field_1762" onChange={(date) => { setContractExpDate(date); setContractExpDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_1762" value={ContractExpDate} name="sub_1_field_1762"  onChange={(date) => { setContractExpDate(date); setContractExpDateFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                        }
                                                                    </td>

                                                                    <td className='caption'>
                                                                       &nbsp;
                                                                    </td>
                                                                    <td>
                                                                        &nbsp;
                                                                    </td>
                                                                </tr>

                                                   {/* ------------------------------ */}
                                                                <tr className="caption">
                                                                    <th colSpan={4} style={{ textAlign: 'center' }}>PREVIOUS EMPLOYER
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td className="caption" colSpan={4} style={{ textAlign: 'center' }} >(IF LESS THAN 24 MONTHS WITH CURRENT EMPLOYER)</td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                         NO. OF YEARS EMPLOYED WITH PREVIOUS EMPLOYER
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1764" type="text" value={PreviousEmpYear} onChange={(e) => { setPreviousEmpYear(e.target.value) }} className="text-uppercase"/>
                                                                    </td>

                                                                    <td className='caption'>
                                                                       &nbsp;
                                                                    </td>
                                                                    <td>
                                                                        &nbsp;
                                                                    </td>
                                                                </tr>

                                                    {/* ------------------------------------  */}
                                                                
                                                                <tr className="caption">
                                                                    <th colSpan={4} style={{ textAlign: 'center' }}>MAIN BANKING ACCOUNT DETAILS
                                                                    </th>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                        ACCOUNT TYPE	
                                                                    </td>
                                                                    <td>
                                                                        <select id="test" name="sub_1_field_1239" className="text-uppercase" value={EmpAccountType} onChange = {(e) => { setEmpAccountType(e.target.value) }} >
                                                                            <option value="0">-- Select An Option --</option>
                                                                            {EmpAccountTypeAll.length > 0 ?
                                                                                EmpAccountTypeAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.type}
                                                                                    </option>
                                                                                    );
                                                                                })
                                                                            : null }
                                                                        </select>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        ACCOUNT TYPE (SPECIFY IF OTHER)	
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1767" type="text" value={AcTypeSpecify} onChange={(e) => { setAcTypeSpecify(e.target.value) }} className="text-uppercase"/>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                        ACCOUNT HOLDER NAME	
                                                                    </td>
                                                                    <td>
                                                                        <select id="test" name="sub_1_field_1237" className="text-uppercase" value={AccountHolder} onChange = {(e) => { setAccountHolder(e.target.value) }} >
                                                                            <option value="0">-- Select An Option --</option>
                                                                            {AccountHolderAll.length > 0 ?
                                                                                AccountHolderAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.full_name}
                                                                                    </option>
                                                                                    );
                                                                                })
                                                                            : null }
                                                                        </select>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        SALARY ACCOUNT	
                                                                    </td>
                                                                    <td>
                                                                           <input type="radio" name="sub_1_field_1782"  value={SalaryAccount == '786' ? SalaryAccount : '786'} checked={SalaryAccount == '786'} onChange={(e) => { setSalaryAccount(e.target.value) }} />
                                                                            &nbsp;No
                                                                            <br />
                                                                            <input type="radio" name="sub_1_field_1782" value={SalaryAccount == '785' ? SalaryAccount : '785'} checked={SalaryAccount == '785'} onChange={(e) => { setSalaryAccount(e.target.value) }} />
                                                                            &nbsp;Yes
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                        BANK OR FINANCIAL INSTITUTE
                                                                    </td>
                                                                    <td>
                                                                        <select id="test" name="sub_1_field_1238" className="text-uppercase" value={EmpBankName} onChange = {(e) => { setEmpBankName(e.target.value) }} >
                                                                            <option value="0">-- Select An Option --</option>
                                                                            {EmpBankNameAll.length > 0 ?
                                                                                EmpBankNameAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.bank_name}
                                                                                    </option>
                                                                                    );
                                                                                })
                                                                            : null }
                                                                        </select>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        BRANCH NAME	
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1240" type="text" value={EmpBranchName} onChange={(e) => { setEmpBranchName(e.target.value) }} className="text-uppercase"/>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                        BRANCH CODE	
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1242" type="text" value={EmpBranchCode} onChange={(e) => { setEmpBranchCode(e.target.value) }} className="text-uppercase"/>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        ACCOUNT NUMBER	
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1241" type="text" value={EmpAccountNumber} onChange={(e) => { setEmpAccountNumber(e.target.value) }} className="text-uppercase"/>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                         DATE ACCOUNT OPENED	
                                                                    </td>
                                                                    <td>
                                                                        {   DateAccountOpenedFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_1686" selected={DateAccountOpened} name="sub_1_field_1686" onChange={(date) => { setDateAccountOpened(date); setDateAccountOpenedFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off"  filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_1686" value={DateAccountOpened} name="sub_1_field_1686"  onChange={(date) => { setDateAccountOpened(date); setDateAccountOpenedFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off"  filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                        }
                                                                    </td>

                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                </tr>
                                                               
                                                                <tr className="caption" >
                                                                    <td colSpan={11} style={{ textAlign: "center" }}>
                                                                        
                                                                        { PrintButtonData != '' ?
                                                                                 <input type="submit" name="command" value={PrintButton} onClick={(e) => { setPrintButton(e.target.value);setEmployeeDetailsSave(e.target.value);}} />
                                                                        : null }
                                                                        &nbsp;

                                                                        <input type="submit" name="command" value="Save" onClick={(e) => { setEmployeeDetailsSave(e.target.value) }}/>&nbsp;
                                                                       
                                                                        <input type="submit" name="command" value="Save and Exit" onClick={(e) => { setEmployeeDetailsSave(e.target.value) }} />&nbsp;
                                                                       
                                                                        <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                        <input type="button" className="p-relative" value="Cancel" />
                                                                        </Link>
                                                                
                                                                    </td>
                                                                </tr>
                                                     </tbody>
                                                </table> 
                                            </form>   

 {/*------------------ Client Finance Details -----------------------------------------  */}

                                            <form name="clientDetails" method="post" onSubmit={handleClientFinanceDetailsSubmit} >            
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="800" style={{ display: div_id3 }}>
                                                    <tbody>  
                                                        {/* {FinancialDetailFlag ?
                                                            <>   */}
                                                                    <tr className="caption">
                                                                        <th colSpan={4} style={{ textAlign: 'center' }}>CLIENT'S FINANCIAL DETAILS </th>
                                                                    </tr>
                                                                  
                                                                    <tr>
                                                                        <th className="caption" colSpan="4" style={{ textAlign: "center" }}>Income</th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>Gross Monthly Basic Salary</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;
                                                                           
                                                                            <NumericFormat type="text" id="sub_1_field_988" name="sub_1_field_988"  value={GrossMonthlySalary} onChange={(e) => { setGrossMonthlySalary(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(1);}} onBlur={() => { ShowZero(1); }}/> 
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>Commission (Last 6 months average)</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;
                                                                           
                                                                            <NumericFormat type="text" id="sub_1_field_989" name="sub_1_field_989"  value={Commission6MonthAvg} onChange={(e) => { setCommission6MonthAvg(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(2);}} onBlur={() => { ShowZero(2); }}/> 
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>OVERTIME (LAST 6 MONTHS AVERAGE)</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;
                                                                            
                                                                            <NumericFormat type="text" id="sub_1_field_990" name="sub_1_field_990"  value={Overtime6MonthAvg} onChange={(e) => { setOvertime6MonthAvg(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(3);}} onBlur={() => { ShowZero(3); }}/> 
                                                                        </td>
                                                                 
                                                                        <td className="caption" style={{ width: "25%" }}>PAYSLIP NETT MONTHLY INCOME</td>
                                                                        <td id="1" style={{ textAlign: "right", width: "25%" }} >
                                                                            P&nbsp;
                                                                            
                                                                            <NumericFormat type="text" id="sub_1_field_993" name="sub_1_field_993"  value={PaySlipMAllowance} onChange={(e) => { setPaySlipMAllowance(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(6);}} onBlur={() => { ShowZero(6); }} onKeyUp={(e) => { Calculate(e.target.value) }}/> 
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>ALL OTHER INCOME</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;
                                                                           
                                                                            <NumericFormat type="text" id="sub_1_field_996" name="sub_1_field_996"  value={AllOtherIncome} onChange={(e) => { setAllOtherIncome(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(7);}} onBlur={() => { ShowZero(7); }} onKeyUp={(e) => { Calculate(e.target.value) }}/>
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>TOTAL INCOME</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;
                                                                            <NumericFormat type="hidden" name="hidden_total_income" value={HiddenTotalIncome} onChange={(e) => { setHiddenTotalIncome(e.target.value) }} fixedDecimalScale=".00"/>
                                                                            
                                                                            <NumericFormat type="text" id="sub_1_field_997" name="sub_1_field_997"  value={TotalIncome} onChange={(e) => { setTotalIncome(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(8);}} onBlur={() => { ShowZero(8); }} readOnly/>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>GUARANTEED BONUS / 13 CHEQUE</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;
                                                                           
                                                                            <NumericFormat type="text" id="sub_1_field_1772" name="sub_1_field_1772"  value={GranttedBouns} onChange={(e) => { setGranttedBouns(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(15);}} onBlur={() => { ShowZero(15); }} onKeyUp={(e) => { Calculate(e.target.value) }}/>
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>&nbsp;</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                           &nbsp;
                                                                        </td>
                                                                    </tr>
                                                                   
                                                                   
                                                                    <tr>
                                                                        <th className="caption" colSpan="4" style={{ textAlign: "center" }}>EXPENDITURE</th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>OTHER EXISTING LOANS</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                            <input type="radio" name="sub_1_field_1708"  value={ExistingLoan == '786' ? ExistingLoan : '786'} checked={ExistingLoan == '786'} onChange={(e) => { setExistingLoan(e.target.value) }} onClick={(e) => { OtherExistingHideShowTab("no"); ClearMonthlyInstallment(e.target.value); }} />
                                                                            &nbsp;No
                                                                            <br />
                                                                            <input type="radio" name="sub_1_field_1708" value={ExistingLoan == '785' ? ExistingLoan : '785'} checked={ExistingLoan == '785'} onChange={(e) => { setExistingLoan(e.target.value) }} onClick={(e) => { OtherExistingHideShowTab("yes") }} />
                                                                            &nbsp;Yes
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            Expenditure
                                                                        </td>

                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="radio" name="sub_1_field_1862"  value={Expenditure == '786' ? Expenditure : '786'} checked={Expenditure == '786'} onChange={(e) => { setExpenditure(e.target.value) }} onClick={(e) => { ExpenditureHideShowTab("no") }} />
                                                                            &nbsp;No
                                                                            <br />
                                                                            <input type="radio" name="sub_1_field_1862" value={Expenditure == '785' ? Expenditure : '785'} checked={Expenditure == '785'} onChange={(e) => { setExpenditure(e.target.value) }} onClick={(e) => { ExpenditureHideShowTab("yes") }} />
                                                                            &nbsp;Yes
                                                                        </td>
                                                                    </tr>
                                                                  
                                                                            <tr style={{ display: OtherExistingHideShow }}>
                                                                                <td className='caption' colSpan="4" style={{ textAlign: "center" }}>LOAN 1</td>
                                                                            </tr>

                                                                            <tr style={{ display: OtherExistingHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>TO BE SETTLED	</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                                    <input type="radio" name="sub_1_field_1726" value={ToBeSealed1 == '786' ? ToBeSealed1 : '786'} checked={ToBeSealed1 == '786'} onChange={(e) => { setToBeSealed1(e.target.value) }} onClick={(e) => { Loan1HideShowTab("no") }} /> No
                                                                                    <br />
                                                                                    <input type="radio" name="sub_1_field_1726" value={ToBeSealed1 == '785' ? ToBeSealed1 : '785'} checked={ToBeSealed1 == '785'} onChange={(e) => { setToBeSealed1(e.target.value) }} onClick={(e) => { Loan1HideShowTab("yes") }} />
                                                                                    Yes
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>

                                                                                </td>

                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                                </td>
                                                                            </tr>
                                                                            <tr className='caption' colSpan="4" style={{ display: Loan1HideShow }}>

                                                                                <td colSpan={4} style={{ color: "red", textAlign: "center", fontSize: "10px" }}>* if loan to be settled do not complete monthly installment value below</td>
                                                                            </tr>

                                                                            <tr style={{ display: OtherExistingHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>FINANCIAL PROVIDER</td>
                                                                                <td id="1" style={{ textAlign: "left", width: "25%" }}>
                                                                                <select name="sub_1_field_1709" className="text-uppercase" style={{ textTransform: "uppercase" }} value={FinantialProviderLoan1} onChange = {(e) => setFinantialProviderLoan1(e.target.value)} >
                                                                                    <option value="0">-- Select An Option --</option>
                                                                                    {FinantialProviderAll.map((item) => {
                                                                                        return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                        {item.type}
                                                                                        </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>MONTHLY INSTALMENT</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1711" name="sub_1_field_1711"  value={MonthlyInvestment1} onChange={(e) => { setMonthlyInvestment1(e.target.value) }} style={{textAlign: 'right', width: '12.4em', pointerEvents: MonthinstallReadonly}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(9);}} onBlur={() => { ShowZero(9); }} onKeyUp={(e) => { Calculate2(e.target.value) }} />
                                                                                </td>
                                                                            </tr>

                                                                            <tr style={{ display: OtherExistingHideShow }}>
                                                                                <td className='caption' colSpan="4" style={{ textAlign: "center" }}>LOAN 2</td>
                                                                            </tr>

                                                                            <tr style={{ display: OtherExistingHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>TO BE SETTLED	</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                                    <input type="radio" name="sub_1_field_1727" value={ToBeSealed2 == '786' ? ToBeSealed2 : '786'} checked={ToBeSealed2 == '786'} onChange={(e) => { setToBeSealed2(e.target.value) }} onClick={(e) => { Loan2HideShowTab("no") }} />&nbsp;No
                                                                                    <br />
                                                                                    <input type="radio" name="sub_1_field_1727" value={ToBeSealed2 == '785' ? ToBeSealed2 : '785'} checked={ToBeSealed2 == '785'} onChange={(e) => { setToBeSealed2(e.target.value) }} onClick={(e) => { Loan2HideShowTab("yes") }} />
                                                                                    &nbsp;Yes
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>

                                                                                </td>

                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                                </td>
                                                                                
                                                                            </tr>
                                                                            
                                                                            <tr className='caption' colSpan="4" style={{ display: Loan2HideShow }} >
                                                                                <td colSpan={4} style={{ color: "red", textAlign: "center", fontSize: "10px" }}>* if loan to be settled do not complete monthly installment value below</td>
                                                                            </tr>

                                                                            <tr style={{ display: OtherExistingHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>FINANCIAL PROVIDER</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                                <select name="sub_1_field_1718" className="text-uppercase" style={{ textTransform: "uppercase" }} value={FinantialProviderLoan2} onChange = {(e) => setFinantialProviderLoan2(e.target.value)} >
                                                                                    <option value="0">-- Select An Option --</option>
                                                                                    {FinantialProviderAll.map((item) => {
                                                                                        return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                        {item.type}
                                                                                        </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>MONTHLY INSTALMENT</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1717" name="sub_1_field_1717"  value={MonthlyInvestment2} onChange={(e) => { setMonthlyInvestment2(e.target.value) }} style={{textAlign: 'right', width: '12.4em', pointerEvents: MonthinstallReadonly1}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(10);}} onBlur={() => { ShowZero(10); }} onKeyUp={(e) => { Calculate2(e.target.value) }} />
                                                                                </td>
                                                                            </tr>
                                                                       
                                                                            <tr style={{ display: ExpenditureHideShow }}> 
                                                                                <td className='caption' colSpan="4" style={{ textAlign: "center" }}>EXPENDITURE</td>
                                                                            </tr>

                                                                            <tr style={{ display: ExpenditureHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>SOCIAL SECURITY</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1845" name="sub_1_field_1845"  value={SocialSecurity} onChange={(e) => { setSocialSecurity(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(21);}} onBlur={() => { ShowZero(21); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>ASSET FINANCE</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                    
                                                                                    <NumericFormat type="text" id="sub_1_field_1846" name="sub_1_field_1846"  value={AssetFinance} onChange={(e) => { setAssetFinance(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(22);}} onBlur={() => { ShowZero(22); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: ExpenditureHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>SHORT TERM INSURANCE</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                    
                                                                                    <NumericFormat type="text" id="sub_1_field_1859" name="sub_1_field_1859"  value={ShortTermInsurance} onChange={(e) => { setShortTermInsurance(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(23);}} onBlur={() => { ShowZero(23); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>FUNERAL PLAN</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                    
                                                                                    <NumericFormat type="text" id="sub_1_field_1858" name="sub_1_field_1858"  value={FuneralPlan} onChange={(e) => { setFuneralPlan(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(24);}} onBlur={() => { ShowZero(24); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: ExpenditureHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>INSURANCE / ASSURANCE PREMIUMS	</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1822" name="sub_1_field_1822"  value={InsurancePremiums} onChange={(e) => { setInsurancePremiums(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(25);}} onBlur={() => { ShowZero(25); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>CHILDREN'S CLOTHING / EDUCATION</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                    
                                                                                    <NumericFormat type="text" id="sub_1_field_1014" name="sub_1_field_1014"  value={ChildrenClothingEducation} onChange={(e) => { setChildrenClothingEducation(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(26);}} onBlur={() => { ShowZero(26); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: ExpenditureHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>DOMESTIC</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1833" name="sub_1_field_1833"  value={Domestic} onChange={(e) => { setDomestic(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(27);}} onBlur={() => { ShowZero(27); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>CLOTHING ACCOUNT PAYMENT</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                    
                                                                                    <NumericFormat type="text" id="sub_1_field_1009" name="sub_1_field_1009"  value={ClothingAccountPayment} onChange={(e) => { setClothingAccountPayment(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(28);}} onBlur={() => { ShowZero(28); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: ExpenditureHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>MUNICIPALITY</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1857" name="sub_1_field_1857" value={Municipality} onChange={(e) => { setMunicipality(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(29);}} onBlur={() => { ShowZero(29); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>GROCERIES</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                    
                                                                                    <NumericFormat type="text" id="sub_1_field_1828" name="sub_1_field_1828"  value={Groceries} onChange={(e) => { setGroceries(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(30);}} onBlur={() => { ShowZero(30); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: ExpenditureHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>LEVIES</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                    
                                                                                    <NumericFormat type="text" id="sub_1_field_1856" name="sub_1_field_1856" value={Levies} onChange={(e) => { setLevies(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(31);}} onBlur={() => { ShowZero(31); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>CELLULAR TELEPHONE</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1851" name="sub_1_field_1851" value={CellularTelephone} onChange={(e) => { setCellularTelephone(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(32);}} onBlur={() => { ShowZero(32); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: ExpenditureHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>DSTV/TV</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1835" name="sub_1_field_1835"  value={Dstv} onChange={(e) => { setDstv(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(33);}} onBlur={() => { ShowZero(33); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>CHURCH</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1847" name="sub_1_field_1847" value={Church} onChange={(e) => { setChurch(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(34);}} onBlur={() => { ShowZero(34); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: ExpenditureHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>GYM</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                    
                                                                                    <NumericFormat type="text" id="sub_1_field_1848" name="sub_1_field_1848"  value={Gym} onChange={(e) => { setGym(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(35);}} onBlur={() => { ShowZero(35); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>TRANSPORT / PETROL / VEHICLE MAINTENANCE</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1013" name="sub_1_field_1013"  value={TransportgVehicleMaintenanace} onChange={(e) => { setTransportgVehicleMaintenanace(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(36);}} onBlur={() => { ShowZero(36); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: ExpenditureHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>UNIT TRUST</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1850" name="sub_1_field_1850"  value={UnitTrust} onChange={(e) => { setUnitTrust(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(37);}} onBlur={() => { ShowZero(37); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>INVESTMENTS (BANK)</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1855" name="sub_1_field_1855" value={InvestmentsBank} onChange={(e) => { setInvestmentsBank(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(38);}} onBlur={() => { ShowZero(38); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: ExpenditureHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>CREDIT CARDS</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1003" name="sub_1_field_1003"  value={CreditCards} onChange={(e) => { setCreditCards(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(39);}} onBlur={() => { ShowZero(39); }} onKeyUp={(e) => { Calculate1(e.target.value) }}/>
                                                                                </td>
                                                                               
                                                                                <td className="caption" style={{ width: "25%" }}>DINERS</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1854" name="sub_1_field_1854"  value={Diners} onChange={(e) => { setDiners(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(40);}} onBlur={() => { ShowZero(40); }} onKeyUp={(e) => { Calculate1(e.target.value) }}/>
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: ExpenditureHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>SPENDING MONEY	</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1853" name="sub_1_field_1853"  value={SpendingMoney} onChange={(e) => { setSpendingMoney(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(41);}} onBlur={() => { ShowZero(41); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                               
                                                                                <td className="caption" style={{ width: "25%" }}>OTHER DEBT / MONTHLY COMMITMENT</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1771" name="sub_1_field_1771" value={OtherDebit} onChange={(e) => { setOtherDebit(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(42);}} onBlur={() => { ShowZero(42); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: ExpenditureHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>TAXATION</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1838" name="sub_1_field_1838" value={Taxation} onChange={(e) => { setTaxation(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(43);}} onBlur={() => { ShowZero(43); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                               
                                                                                <td className="caption" style={{ width: "25%" }}>DONATIONS</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1852" name="sub_1_field_1852"  value={Donations} onChange={(e) => { setDonations(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(44);}} onBlur={() => { ShowZero(44); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: ExpenditureHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>OTHER EXPENSES 2</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1834" name="sub_1_field_1834"  value={OtherExpenses2} onChange={(e) => { setOtherExpenses2(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(45);}} onBlur={() => { ShowZero(45); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                               
                                                                                <td className="caption" style={{ width: "25%" }}>HOUSEHOLD EXPENSES</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1831" name="sub_1_field_1831"  value={HouseHoldExpenses} onChange={(e) => { setHouseHoldExpenses(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(46);}} onBlur={() => { ShowZero(46); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: ExpenditureHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>ALIMONY / MAINTENANCE</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1832" name="sub_1_field_1832"  value={AlomonyMaintenance} onChange={(e) => { setAlomonyMaintenance(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(47);}} onBlur={() => { ShowZero(47); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                               
                                                                                <td className="caption" style={{ width: "25%" }}>OTHER EXPENSES 3</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1836" name="sub_1_field_1836"  value={OtherExpenses3} onChange={(e) => { setOtherExpenses3(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(48);}} onBlur={() => { ShowZero(48); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: ExpenditureHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>PENSION FUND CONTRIBUTION</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1839" name="sub_1_field_1839" value={PensionFundContribution} onChange={(e) => { setPensionFundContribution(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(49);}} onBlur={() => { ShowZero(49); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                               
                                                                                <td className="caption" style={{ width: "25%" }}>WATER & ELECTRICTY, WAGES</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1010" name="sub_1_field_1010"  value={WaterElectricity} onChange={(e) => { setWaterElectricity(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(50);}} onBlur={() => { ShowZero(50); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: ExpenditureHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>OTHER</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1016" name="sub_1_field_1016"  value={CientOther1} onChange={(e) => { setCientOther1(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(51);}} onBlur={() => { ShowZero(51); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                               
                                                                                <td className="caption" style={{ width: "25%" }}>OTHER</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1840" name="sub_1_field_1840"  value={CientOther2} onChange={(e) => { setCientOther2(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(52);}} onBlur={() => { ShowZero(52); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: ExpenditureHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>TOTAL EXPENDITURE</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1842" name="sub_1_field_1842"  value={TotalExpenditure} onChange={(e) => { setTotalExpenditure(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(53);}} onBlur={() => { ShowZero(53); }} onKeyUp={(e) => { Calculate1(e.target.value) }} readOnly/>
                                                                                </td>
                                                                               
                                                                                <td className="caption" style={{ width: "25%" }}>&nbsp;</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    &nbsp;
                                                                                </td>
                                                                            </tr>
                                                                    
                                                                    <tr> 
                                                                        <th className='caption' colSpan="4" style={{ textAlign: "center" }}>VEHICLE FINANCE</th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            <span id="sub_1_label_986" title="Income field is mandatory">EXISTING / PREVIOUS VEHICLE FINANCE COMPANY </span>
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                           
                                                                            <select name="sub_1_field_1475" className="text-uppercase" style={{ textTransform: "uppercase" }} value={FinantialCompany} onChange = {(e) => setFinantialCompany(e.target.value)} >
                                                                                    <option value="0">-- Select An Option --</option>
                                                                                    {FinantialCompanyAll.map((item) => {
                                                                                        return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                        {item.type}
                                                                                        </option>
                                                                                        );
                                                                                    })}
                                                                            </select>

                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            ACCOUNT NUMBER
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1477" value={AccountNumber} onChange={(e) => { setAccountNumber(e.target.value) }} />

                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            BRANCH
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1476" value={previousBranch} onChange={(e) => { setpreviousBranch(e.target.value) }} className="text-uppercase"/>

                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>INSTALMENT	</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;
                                                                            
                                                                            <NumericFormat type="text" id="sub_1_field_1478" name="sub_1_field_1478"  value={Installment} onChange={(e) => { setInstallment(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(11);}} onBlur={() => { ShowZero(11); }} onKeyUp={(e) => { Calculate2(e.target.value) }} />
                                                                        </td> 
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>TO BE SETTLED	</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                            <input type="radio" name="sub_1_field_1481"  value={ToBeSealed3 == '786' ? ToBeSealed3 : '786'} checked={ToBeSealed3 == '786'} onChange={(e) => { setToBeSealed3(e.target.value) }} onClick={(e) => { ClearInstallemnt(e.target.value) }} />
                                                                            &nbsp;No
                                                                            <br />
                                                                            <input type="radio" name="sub_1_field_1481" value={ToBeSealed3 == '785' ? ToBeSealed3 : '785'} checked={ToBeSealed3 == '785'} onChange={(e) => { setToBeSealed3(e.target.value) }} onClick={(e) => { ClearInstallemnt(e.target.value) }} />
                                                                            &nbsp;Yes
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>SETTLEMENT	</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;
                                                                           
                                                                            <NumericFormat type="text" id="sub_1_field_1479" name="sub_1_field_1479"  value={Settlement} onChange={(e) => { setSettlement(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(12);}} onBlur={() => { ShowZero(12); }}/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%",color: MonthLeftColor }}>
                                                                            MONTHS LEFT <span style={{color : "red"}}>*</span>
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1480" value={MonthLeft} onChange={(e) => { setMonthLeft(e.target.value) }} />

                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <th className="caption" colSpan="4" style={{ textAlign: "center" }}>
                                                                            PROPERTY
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%", border: OwnHomeColor }}>DO YOU OWN YOUR HOME?</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="radio" name="sub_1_field_1483" value="786" onChange={(e) => setOwnHome(786)} checked={OwnHome == '786'} onClick={(e) => { OtherFieldHideShow(e.target.value); }}/>
                                                                            &nbsp;No
                                                                            <br />
                                                                            <input type="radio" name="sub_1_field_1483" value="785" onChange={(e) => setOwnHome(785)} checked={OwnHome == '785'} onClick={(e) => { OtherFieldHideShow(e.target.value); }}/>
                                                                            &nbsp;Yes
                                                                        </td>
                                                                        <td className="caption"></td>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                    {/* {OwnHome === 0 ?
                                                                        <> */}
                                                                            <tr style={{ display: PropertyHideShow }} >
                                                                                <td className="caption" style={{ width: "25%" }}>
                                                                                    LANDLORD NAME
                                                                                </td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                                    <input type="text" name="sub_1_field_1493" value={LandlordName} onChange={(e) => { setLandlordName(e.target.value) }} className="text-uppercase"/>

                                                                                </td>

                                                                                <td className="caption" style={{ width: "25%" }}>RENTAL / LODGING VALUE	<span style={{ color: "red" }}>*</span>	</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                    
                                                                                    <NumericFormat type="text" id="sub_1_field_1712" name="sub_1_field_1712"  value={RentalValue} onChange={(e) => { setRentalValue(e.target.value) }} style={{textAlign: 'right', width: '12.4em', border: RentalValueBorder}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(13);}} onBlur={() => { ShowZero(13); }} onKeyUp={(e) => { Calculate2(e.target.value); ChangeForsurpulse(e.target.value); }} />
                                                                                </td>
                                                                               
                                                                            </tr>

                                                                            <tr style={{ display: PropertyHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>
                                                                                    LANDLORD ADDRESS
                                                                                </td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                                    <input type="text" name="sub_1_field_1494" value={LandlordAddress} onChange={(e) => { setLandlordAddress(e.target.value) }} className="text-uppercase"/>

                                                                                </td>

                                                                                <td className="caption" style={{ width: "25%" }}></td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                </td>
                                                                               
                                                                            </tr>
                                                                      
                                                                            <tr style={{ display: PropertyHideShow1 }} >
                                                                                <td className="caption" style={{ width: "25%" }}>BOND MONTHLY INSTALMENT <span style={{ color: "red" }}>*</span> </td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                    
                                                                                    <NumericFormat type="text" id="sub_1_field_1713" name="sub_1_field_1713"  value={MonthlyBond} onChange={(e) => { setMonthlyBond(e.target.value) }} style={{textAlign: 'right', width: '12.4em', border: BondMonthlyBorder}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(83);}} onBlur={() => { ShowZero(83); }} onKeyUp={(e) => { Calculate2(e.target.value) }}/>

                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>
                                                                                    BOND HOLDER (BANK)
                                                                                </td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                                    <input type="text" id="sub_1_field_1488" name="bond_holder" value={BondHolder} onChange={(e) => { setBondHolder(e.target.value) }} className="text-uppercase" />

                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: PropertyHideShow1 }} >
                                                                                <td className="caption" style={{ width: "25%" }}>PURCHASE PRICE	</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                    
                                                                                    <NumericFormat type="text" id="sub_1_field_1491" name="sub_1_field_1491"  value={PurchasePrice} onChange={(e) => { setPurchasePrice(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(84);}} onBlur={() => { ShowZero(84); }}/>
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>
                                                                                    PURCHASE DATE
                                                                                </td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
 
                                                                                {   PurchaseDateFlag == 1 ?
                                                                                    <DatePicker id="sub_1_field_1489" selected={PurchaseDate} name="sub_1_field_1489" onChange={(date) => { setPurchaseDate(date); setPurchaseDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                        return new Date() > d; }}/>
                                                                                :
                                                                                    <DatePicker id="sub_1_field_1489" value={PurchaseDate} name="sub_1_field_1489"  onChange={(date) => { setPurchaseDate(date); setPurchaseDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                        return new Date() > d; }}/>
                                                                                }  

                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: PropertyHideShow1 }} >
                                                                                <td className="caption" style={{ width: "25%" }}>PRESENT MARKET VALUE	</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                  
                                                                                    <NumericFormat type="text" id="sub_1_field_1492" name="sub_1_field_1492"  value={MarketValue} onChange={(e) => { setMarketValue(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(85);}} onBlur={() => { ShowZero(85); }}/>
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>
                                                                                    IN WHOSE NAME?
                                                                                </td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                                    <input type="text" id="sub_1_field_1485"  name="poverty_name" value={PovertyName} onChange={(e) => { setPovertyName(e.target.value) }} className="text-uppercase"/>

                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: PropertyHideShow1 }}>
                                                                                <td className="caption" style={{ width: "25%" }}>OUTSTANDING BOND AMOUNT</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1490" name="sub_1_field_1490"  value={BondAmount} onChange={(e) => { setBondAmount(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(86);}} onBlur={() => { ShowZero(86); }}/>
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>
                                                                                    STAND NUMBER
                                                                                </td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                                    <input type="text" id="sub_1_field_1486"   name="sub_1_field_1486" value={StandNumber} onChange={(e) => { setStandNumber(e.target.value) }} />

                                                                                </td>
                                                                            </tr>
                                                                           
                                                                            <tr style={{ display: PropertyHideShow1 }}>
                                                                                <td className="caption" style={{ width: "25%" }}></td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>
                                                                                    SUBURB
                                                                                </td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                                    <input type="text" id="sub_1_field_1487" name="suburb" value={Suburb} onChange={(e) => { setSuburb(e.target.value) }} className="text-uppercase"/>

                                                                                </td>
                                                                            </tr> 
                                                                    
                                                                    <tr>
                                                                        <th className="caption" colSpan="4" style={{ textAlign: "center" }}>
                                                                            EXISTING UNSECURED LOANS AND OTHER CREDIT FACILITIES
                                                                        </th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            TOTAL
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" id="sub_1_field_1775" name="sub_1_field_1775" value={FinanaceTotal} onChange={(e) => { setFinanaceTotal(e.target.value) }} />
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            HAVE YOU BEEN DECLARED INSOLVENT	
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                                <select name="sub_1_field_1776" className="text-uppercase" style={{ textTransform: "uppercase" }} value={DeclearedInsolvent} onChange = {(e) => setDeclearedInsolvent(e.target.value)} >
                                                                                    <option value="0">-- Select An Option --</option>
                                                                                    {DeclearedInsolventAll.map((item) => {
                                                                                        return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                        {item.status}
                                                                                        </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                        </td>
                                                                    </tr> 
                                                                  
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            REHABILITATION DATE	
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            {   RehabilitationDateFlag == 1 ?
                                                                                    <DatePicker id="sub_1_field_1777" selected={RehabilitationDate} name="sub_1_field_1777" onChange={(date) => { setRehabilitationDate(date); setRehabilitationDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                        return new Date() > d; }}/>
                                                                                :
                                                                                    <DatePicker id="sub_1_field_1777" value={RehabilitationDate} name="sub_1_field_1777"  onChange={(date) => { setRehabilitationDate(date); setRehabilitationDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                        return new Date() > d; }}/>
                                                                            }  
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            PROOF
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            
                                                                            {
                                                                                DocsName != '' ?
                                                                                  <a href={DocsPath} target="_blank">{DocsName}</a> 
                                                                               :
                                                                                 <input type="file" name="sub_1_field_1778" onChange={(e) => setFile(e.target.files[0])} />
                                                                            }
                                                                        </td>
                                                                    </tr> 

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            ARE YOU A GUARANTOR / SURETY FOR ANYBODY OR ANYTHING ELSE	
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                                <select name="sub_1_field_1779" className="text-uppercase" style={{ textTransform: "uppercase" }} value={GarentorSefty} onChange = {(e) => setGarentorSefty(e.target.value)} >
                                                                                    <option value="0">-- Select An Option --</option>
                                                                                   {GarentorSeftyAll.map((item) => {
                                                                                        return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                        {item.status}
                                                                                        </option>
                                                                                        );
                                                                                    })} 
                                                                                </select>
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            DETAILS OF GUARANTEE	
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <textarea name="sub_1_field_1780" className="text-uppercase" value={DetailsOfGuarantee} onChange={(e) => { setDetailsOfGuarantee(e.target.value) }}></textarea>
                                                                        </td>
                                                                    </tr> 

                                                                    <tr>
                                                                        <th className="caption" colSpan="4" style={{ textAlign: "center" }}>
                                                                            TOTAL DISPOSABLE INCOME
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>{DefectSurpulse}<span style={{ color: "red" }}> *</span>	</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;
                                                                            <NumericFormat type="hidden" name="hidden_sirplus" value={HiddenSurplus} onChange={(e) => { setHiddenSurplus(e.target.value) }} decimalScale={2} fixedDecimalScale=".00" />

                                                                            <NumericFormat type="text" id="sub_1_field_1019" name="sub_1_field_1019"  value={Surplus} onChange={(e) => { setSurplus(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(14);}} onBlur={() => { ShowZero(14); }}/>
                                                                        </td>
                                                                    </tr>

                                                                    <tr className="caption text-center" >
                                                                        <td colSpan={4} style={{ textAlign: "center" }}>
                                                                            { PrintButtonData != '' ?
                                                                                 <input type="submit" name="command" value={PrintButton} onClick={(e) => { setPrintButton(e.target.value);setClientsFinanceDetSave(e.target.value); }} />
                                                                            : null }
                                                                            &nbsp;

                                                                            <input type="submit" name="Save" value="Save" onClick={(e) => { setClientsFinanceDetSave(e.target.value) }} />&nbsp;
                                                                            <input type="submit" name="Save and Exit" value="Save and Exit" onClick={(e) => { setClientsFinanceDetSave(e.target.value) }}/>&nbsp;
                                                                            
                                                                            <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                                <input type="button" className="p-relative" value="Cancel" />
                                                                            </Link>
                                                                        </td>
                                                                    </tr>

                                                                {/* </> : null } */}
                                                     </tbody>
                                                </table>
                                            </form>    
 {/* --------- Loan Specifics -------------------------------- */}
                                            <form name="clientDetails" method="post" onSubmit={handleLoanDetailsSubmit}>    
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="800" style={{ display: div_id4 }}>
                                                    <tbody>  
                                                       
                                                                    <tr className="caption">
                                                                        <th colSpan={4} style={{ textAlign: 'center' }}>LOAN SPECIFICS
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                      
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            COMMENCEMENT DATE
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                        
                                                                            {   LoanCommencementDateFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_1533" selected={CommencementDate} name="sub_1_field_1533" onChange={(date) => { setCommencementDate(date); setLoanCommencementDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" />
                                                                            :
                                                                                <DatePicker id="sub_1_field_1533" value={CommencementDate} name="sub_1_field_1533"  onChange={(date) => { setCommencementDate(date); setLoanCommencementDateFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" />
                                                                            }
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>INTEREST TYPE</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                            <input type="radio" name="sub_1_field_1247"  value={InterestType == '1' ? InterestType : '1'} checked={InterestType == '1'} onChange={(e) => { setInterestType(e.target.value) }} />
                                                                            &nbsp; FIXED
                                                                            <br />
                                                                            <input type="radio" name="sub_1_field_1247" value={InterestType == '2' ? InterestType : '2'} checked={InterestType == '2'} onChange={(e) => { setInterestType(e.target.value) }} />
                                                                            &nbsp; LINKED
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                       
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                           LOAN EXPIRY DATE	
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            
                                                                            {   LoanExpDateFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_1751" selected={LoanExpDate} name="sub_1_field_1751" onChange={(date) => { setLoanExpDate(date); setLoanExpDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" />
                                                                            :
                                                                                <DatePicker id="sub_1_field_1751" value={LoanExpDate} name="sub_1_field_1751"  onChange={(date) => { setLoanExpDate(date); setLoanExpDateFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" />
                                                                            }
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>SALE TYPE</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                            <input type="radio" name="sub_1_field_1246" value={SaleType == '1' ? SaleType : '1'} checked={SaleType == '1'} onChange={(e) => { setSaleType(e.target.value) }} />
                                                                            &nbsp; FLEET
                                                                            <br />
                                                                            <input type="radio" name="sub_1_field_1246" value={SaleType == '2' ? SaleType : '2'} checked={SaleType == '2'} onChange={(e) => { setSaleType(e.target.value) }} />
                                                                            &nbsp; VEHICLE
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            FIRST DEBIT ORDER/ INSTALMENT DATE
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            
                                                                            {   LoanFirstDebitOrderDateFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_1510" selected={FirstDebitOrder} name="sub_1_field_1510" onChange={(date) => { setFirstDebitOrder(date); setLoanFirstDebitOrderDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" />
                                                                            :
                                                                                <DatePicker id="sub_1_field_1510" value={FirstDebitOrder} name="sub_1_field_1510"  onChange={(date) => { setFirstDebitOrder(date); setLoanFirstDebitOrderDateFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" />
                                                                            }
                                                                        </td>

                                                                        <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                            FINANCE TYPE
                                                                        </td>

                                                                        <td style={{ textAlign: "left", width: "25%" }}>
                                                                            <select name="sub_1_field_1509" className="text-uppercase" style={{ textTransform: "uppercase" }} value={FinanceType} onChange = {(e) => setFinanceType(e.target.value)} >
                                                                                <option value="0">-- Select An Option --</option>
                                                                                {FinanceTypeAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.type}
                                                                                    </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                            TERM (MONTHS)
                                                                        </td> 

                                                                        <td style={{ textAlign: "left", width: "25%" }}>
                                                                            <select name="sub_1_field_1031" className="text-uppercase" style={{ textTransform: "uppercase" }} value={TermMonth} onChange = {(e) => setTermMonth(e.target.value)} >
                                                                                <option value="0">-- Select An Option --</option>
                                                                                {TermMonthAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.month}
                                                                                    </option>
                                                                                    );
                                                                                })}
                                                                            </select>

                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            <span style={{ color: LoanValidateColor }}>PREFERRED INTEREST RATE (%)</span> <span style={{ color: "red" }}>*</span>
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1030" value={InterestRate} onChange={(e) => { setInterestRate(e.target.value) }} />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            {/* REPAYMENT METHOD	 */}
                                                                        </td>
                                                                        <td style={{ textAlign: "left", width: "25%" }}>
                                                                            {/* <select name="sub_1_field_1873"  className="text-uppercase" style={{ textTransform: "uppercase" }} value={PaymentMethod} onChange = {(e) => setPaymentMethod(e.target.value)} >
                                                                                <option value="0">-- Select An Option --</option>
                                                                                {PaymentMethodAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.method}
                                                                                    </option>
                                                                                    );
                                                                                })}
                                                                            </select> */}
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>LOAN EQUITY</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                           <input id="sub_1_field_1456" type="hidden" name="sub_1_field_1456" value={LoanEquity} onChange={(e) => { setLoanEquity(e.target.value) }} />
                                                                             {LoanEquity} %
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>DEPOSIT PERCENTAGE	</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input id="sub_1_field_1459" type="hidden" name="sub_1_field_1459" value={LoanDepositPercentage} onChange={(e) => { setLoanDepositPercentage(e.target.value) }} />
                                                                            {LoanDepositPercentage} %
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>AFFORDABILITY</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input id="sub_1_field_1457" type="hidden" name="sub_1_field_1457" value={LoanAffordability} onChange={(e) => { setLoanAffordability(e.target.value) }}  />
                                                                            {LoanAffordability} %
                                                                        </td>
                                                                       
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>AFFORDABILITY WITH INSURANCE</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                           <input id="sub_1_field_1458" type="hidden" name="sub_1_field_1458" value={LoanAfforabilityInsurance} onChange={(e) => { setLoanAfforabilityInsurance(e.target.value) }} />
                                                                            {LoanAfforabilityInsurance} %
                                                                        </td>

                                                                        <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                            BUISNESS SEGMENT
                                                                        </td>

                                                                        <td style={{ textAlign: "left", width: "25%" }}>
                                                                            <select name="sub_1_field_1816" className="text-uppercase" style={{ textTransform: "uppercase" }} value={BusinessSegment1} onChange = {(e) => setBusinessSegment1(e.target.value)} >
                                                                                <option value="0">-- Select An Option --</option>
                                                                                {BusinessSegmentAll1.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.method}
                                                                                    </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                    </tr>
                                                                   
                                                                    <tr className="caption">
                                                                        <th colSpan={4} style={{ textAlign: 'center' }}>TRANSACTION SPECIFICS
                                                                        </th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>PREFERRED PAYMENT DATE (DD)	</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" id="sub_1_field_1790" name="sub_1_field_1790" value={PreffredPaymentDate} onChange={(e) => { setPreffredPaymentDate(e.target.value) }} />
                                                                          
                                                                        </td>
                                                                        
                                                                        <td className="caption" style={{ width: "25%" }}>ASSET AMOUNT</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                          <input type="hidden" id="sub_1_field_1039" name="sub_1_field_1039" value={LoanAssetAmount} onChange={(e) => { setLoanAssetAmount(e.target.value) }} />
                                                                           P {LoanAssetAmount}
                                                                        </td>
                                                                       
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>INSURANCE PRODUCTS	</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                           <input type="hidden" id="sub_1_field_1450" name="sub_1_field_1450" value={LoanInsuranceProduct} onChange={(e) => { setLoanInsuranceProduct(e.target.value) }} />
                                                                           P {LoanInsuranceProduct}
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>NON-INSURANCE PRODUCTS	</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                          <input type="hidden" id="sub_1_field_1451" name="sub_1_field_1451" value={LoanNonInsuranceProduct} onChange={(e) => { setLoanNonInsuranceProduct(e.target.value) }} />
                                                                          P {LoanNonInsuranceProduct}
                                                                        </td>
                                                                        
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            COMPREHENSIVE INSURANCE
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                           <input type="hidden" id="sub_1_field_1452" name="sub_1_field_1452" value={LoanCompersiveInsurance} onChange={(e) => { setLoanCompersiveInsurance(e.target.value) }} />
                                                                           P {LoanCompersiveInsurance}
                                                                        </td>
                                                                        
                                                                        <td className="caption" style={{ width: "25%" }}>DISCOUNT</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            <input type="hidden" id="sub_1_field_1042" name="sub_1_field_1042" value={LoanDiscount} onChange={(e) => { setLoanDiscount(e.target.value) }}  />
                                                                            P {LoanDiscount}
                                                                        </td>
                                                                       
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>CASH/CHEQUE/EFT DEPOSIT VALUE</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                           <input type="hidden" id="sub_1_field_1043" name="sub_1_field_1043"  value={LoanCashDepositValue} onChange={(e) => { setLoanCashDepositValue(e.target.value) }} />
                                                                            P {LoanCashDepositValue}
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>NETT TRADE IN DEPOSIT VALUE</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                           <input type="hidden" id="sub_1_field_1449" name="sub_1_field_1449" value={LoanNettTradeDeposit} onChange={(e) => { setLoanNettTradeDeposit(e.target.value) }} />
                                                                            P {LoanNettTradeDeposit}
                                                                        </td>
                                                                        
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>BANK DOCUMENTATION FEE	</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }} >
                                                                            P&nbsp;
                                                                           
                                                                            <NumericFormat type="text" id="sub_1_field_1448" name="sub_1_field_1448"  value={BankFee} onChange={(e) => { setBankFee(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(81);}} onBlur={() => { ShowZero(81); }}/>
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>TRANSACTION FEE</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                           <input type="hidden" id="sub_1_field_1453" name="sub_1_field_1453" value={LoanTransactionFee} onChange={(e) => { setLoanTransactionFee(e.target.value) }} />
                                                                            P {LoanTransactionFee}
                                                                        </td>
                                                                      
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>FINANCED AMOUNT</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                           <input type="hidden" id="sub_1_field_1029" name="sub_1_field_1029" value={LoanFinanceAmount} onChange={(e) => { setLoanFinanceAmount(e.target.value) }}/>
                                                                            P {LoanFinanceAmount}
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>&nbsp;</td>
                                                                        <td>&nbsp;</td>
                                                                    </tr>

                                                                    <tr className="caption">
                                                                        <th colSpan={4} style={{ textAlign: 'center' }}>VEHICLE INSURANCE
                                                                        </th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>WILL YOU BE THE PRINCIPAL DRIVER</td>

                                                                        <td style={{ textAlign: "left", width: "25%" }}>
                                                                            <select name="sub_1_field_1785" className="text-uppercase" style={{ textTransform: "uppercase" }} value={PrincipleDriver} onChange = {(e) => { setPrincipleDriver(e.target.value); DriverWiseNameHideShow(e.target.value); }} >
                                                                                <option value="0">-- Select An Option --</option>
                                                                                {PrincipleDriverAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.status}
                                                                                    </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%", display: DrivernameHideShow }}>NAME</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%", display: DrivernameHideShow }}>
                                                                            <input type="text" id="sub_1_field_1786" name="sub_1_field_1786" className="text-uppercase" value={LoanPrincipleName} onChange={(e) => { setLoanPrincipleName(e.target.value) }}  />
                                                                        </td>

                                                                    </tr>


                                                                    <tr className="caption text-center" >
                                                                        <td colSpan={4} style={{ textAlign: "center" }}>
                                                                            { PrintButtonData != '' ?
                                                                                 <input type="submit" name="command" value={PrintButton} onClick={(e) => { setPrintButton(e.target.value);setLoanSpecificsDetSave(e.target.value); }} />
                                                                            : null }
                                                                            &nbsp;

                                                                            <input type="submit" name="Save" value="Save" onClick={(e) => { setLoanSpecificsDetSave(e.target.value) }} />&nbsp;
                                                                            <input type="submit" name="Save and Exit" value="Save and Exit" onClick={(e) => { setLoanSpecificsDetSave(e.target.value) }}/>&nbsp;
                                                                            
                                                                            <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                                <input type="button" className="p-relative" value="Cancel" />
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                            </tbody>
                                                        </table>
                                                    </form>    
        {/* ----------------- Spouse Details ----------------------------------  */}
                                                <form name="clientDetails" method="post" onSubmit={handleSpouseDetailsSubmit} > 
                                                    <table className="defaultTbl" cellSpacing={1} align="center" width="800" style={{ display: div_id5 }}>
                                                        <tbody>  
                                                        
                                                                    <tr className="caption">
                                                                        <th colSpan={4} style={{ textAlign: 'center' }}>SPOUSE DETAILS
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                            SPOUSE DETAILS
                                                                        </td>

                                                                        <td style={{ textAlign: "left", width: "25%" }}>
                                                                          
                                                                            <select id="test" name="sub_1_field_952" className="text-uppercase" value={SpouseDetails} onChange = {(e) => { setSpouseDetails(e.target.value) }} >
                                                                            <option value="0">-- Select An Option --</option>
                                                                             {SpouseDetailsAll != null ?
                                                                                SpouseDetailsAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.name}
                                                                                    </option>
                                                                                    );
                                                                                })
                                                                              : null }
                                                                            </select>
                                                                        </td>
                                                                        <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                            TITLE
                                                                        </td>

                                                                        <td style={{ textAlign: "left", width: "25%" }}>
                                                                            <select id="test" name="sub_1_field_954" className="text-uppercase" value={SpouseTitle} onChange = {(e) => { setSpouseTitle(e.target.value) }} >
                                                                                <option value="0">-- Select An Option --</option>
                                                                                {TitleAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.title}
                                                                                    </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            FIRST NAME
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_955" value={SpouseFirstName} onChange={(e) => { setSpouseFirstName(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            INITIALS
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1075" value={SpouseInitial} onChange={(e) => { setSpouseInitial(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            SURNAME
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_956" value={SpouseSurname} onChange={(e) => { setSpouseSurname(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            ID NUMBER
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_957" value={SpouseIdNumber} onChange={(e) => { setSpouseIdNumber(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            DATE OF BIRTH
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            {   SpouseBirthdateFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_958" selected={SpouseDOB} name="sub_1_field_958" onChange={(date) => { setSpouseDOB(date); setSpouseBirthdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_958" value={SpouseDOB} name="sub_1_field_958"  onChange={(date) => { setSpouseDOB(date); setSpouseBirthdateFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            }  
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            GENDER
                                                                        </td>
                                                                        <td id="1" style={{ textAlign: "left", width: "25%" }}>
                                                                            <select name="sub_1_field_965" id="gender_select"   className="text-uppercase" value={SpouseGender} onChange = {(e) => setSpouseGender(e.target.value)} >
                                                                                <option value="0">-- Select An Option --</option>
                                                                                {GenderAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.gender}
                                                                                    </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            HOME PHONE
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_959" value={SpouseHomePhone} onChange={(e) => { setSpouseHomePhone(e.target.value) }} />
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            WORK PHONE
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_960" value={SpouseWorkPhone} onChange={(e) => { setSpouseWorkPhone(e.target.value) }} />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            MOBILE PHONE
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_961" value={SpouseMobilePhone} onChange={(e) => { setSpouseMobilePhone(e.target.value) }} />
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            E-MAIL
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_962" value={SpouseEmail} onChange={(e) => { setSpouseEmail(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            PHYSICAL ADDRESS LINE 1
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1503" value={SpousePhysicalAdd1} onChange={(e) => { setSpousePhysicalAdd1(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            POSTAL ADDRESS LINE 1
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1506" value={SpousePostalAdd1} onChange={(e) => { setSpousePostalAdd1(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            PHYSICAL ADDRESS LINE 2
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1504" value={SpousePhysicalAdd2} onChange={(e) => { setSpousePhysicalAdd2(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            POSTAL ADDRESS LINE 2
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1507" value={SpousePostalAdd2} onChange={(e) => { setSpousePostalAdd2(e.target.value) }} className="text-uppercase" />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            PHYSICAL ADDRESS CITY
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1514" value={SpousePhysicalAddCity} onChange={(e) => { setSpousePhysicalAddCity(e.target.value) }} className="text-uppercase" />
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            POSTAL ADDRESS CITY
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1515" value={SpousePostalAddCity} onChange={(e) => { setSpousePostalAddCity(e.target.value) }} className="text-uppercase" />
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            CONTACT NATIONALITY	
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <select id="test" name="sub_1_field_1738" className="text-uppercase" value={ContactNationality} onChange = {(e) => { setContactNationality(e.target.value) }} >
                                                                                <option value="0">-- Select An Option --</option>
                                                                                {ContactNationalityAll != null ?
                                                                                    ContactNationalityAll.map((item) => {
                                                                                        return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                        {item.name}
                                                                                        </option>
                                                                                        );
                                                                                    })
                                                                                : null }
                                                                            </select>
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                           &nbsp;
                                                                        </td>
                                                                        <td>&nbsp;</td>
                                                                    </tr>

                                                                    <tr className="caption">
                                                                        <th colSpan={4} style={{ textAlign: 'center' }}>ADDITIONAL SPOUSE DETAILS
                                                                        </th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            RESIDENCY STATUS
                                                                        </td>
                                                                        <td id="1" style={{ textAlign: "left", width: "25%" }}>
                                                                            <select name="sub_1_field_947" id="sub_1_field_947" className="text-uppercase" value={SpouseResidencyStatus} onChange = {(e) => { setSpouseResidencyStatus(e.target.value) }}>
                                                                                <option value="0">-- Select an option --</option>
                                                                                {ResidencyStatusAll.map((item) => {
                                                                                        return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                        {item.res_status}
                                                                                        </option>
                                                                                        );
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            OCCUPATION
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1468" value={SpouseOccupation} onChange={(e) => { setSpouseOccupation(e.target.value) }} className="text-uppercase" />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            EMPLOYER NAME
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1469" value={SpouseEmployerName} onChange={(e) => { setSpouseEmployerName(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            EMPLOYER ADDRESS
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1470" value={SpouseEmployerAdd} onChange={(e) => { setSpouseEmployerAdd(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            DATE EMPLOYED
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                           
                                                                            {   SpouseDateEmployeeFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_1471" selected={SpouseDate} name="sub_1_field_1471" onChange={(date) => { setSpouseDate(date); setSpouseDateEmployeeFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_1471" value={SpouseDate} name="sub_1_field_1471"  onChange={(date) => { setSpouseDate(date); setSpouseDateEmployeeFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            }  
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            SPOUSE NETT INCOME
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;
                                                                           
                                                                            <NumericFormat type="text" id="sub_1_field_994" name="sub_1_field_994"  value={SpouseIncome} onChange={(e) => { setSpouseIncome(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(82);}} onBlur={() => { ShowZero(82); }}/>
                                                                        </td>
                                                                    </tr>

                                                                    <tr className="caption">
                                                                        <th colSpan={4} style={{ textAlign: 'center' }}>NUMBER OF DEPENDANTS</th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            SPOUSE
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1573" value={SpouseName} onChange={(e) => { setSpouseName(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            CHILDREN
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1574" value={SpouseChidren} onChange={(e) => { setSpouseChidren(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            OTHER
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1575" value={SpouseOther} onChange={(e) => { setSpouseOther(e.target.value) }} className="text-uppercase"/>
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                           &nbsp;
                                                                        </td>
                                                                        <td>&nbsp;</td>
                                                                    </tr>

                                                                    <tr className="caption">
                                                                        <th colSpan={4} style={{ textAlign: 'center' }}>GUARANTORS / SURETY DETAILS</th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }} colSpan={4}>
                                                                            (APPLICABLE TO THE PRIMARY APPLICANT. THE GUARANTOR / SURETY MUST SUBMIT SEPARATE PERSONAL AND FINANCIAL DETAILS FORMS)
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                            TITLE
                                                                        </td>

                                                                        <td style={{ textAlign: "left", width: "25%" }}>
                                                                            <select id="test" name="sub_1_field_1572" className="text-uppercase" value={GarentorSpouseTitle} onChange = {(e) => { setGarentorSpouseTitle(e.target.value) }} >
                                                                                <option value="0">-- Select An Option --</ option>
                                                                                {GarentorTitleAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.title}
                                                                                    </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            FIRST NAME	
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1583" value={GarentorFirstName} onChange={(e) => { setGarentorFirstName(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            SURNAME
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1584" value={GarentorSurname} onChange={(e) => { setGarentorSurname(e.target.value) }} className="text-uppercase"/>
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            MOBILE
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1585" value={GarentorMobileNo} onChange={(e) => { setGarentorMobileNo(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                    </tr>

                                                                    <tr className="caption text-center" >
                                                                        <td colSpan={4} style={{ textAlign: "center" }}>
                                                                           
                                                                            { PrintButtonData != '' ?
                                                                                 <input type="submit" name="command" value={PrintButton} onClick={(e) => { setPrintButton(e.target.value);setSpouseDetSave(e.target.value); }} />
                                                                            : null }
                                                                            &nbsp;

                                                                            <input type="submit" name="Save" value="Save" onClick={(e) => { setSpouseDetSave(e.target.value) }} />&nbsp;
                                                                            
                                                                            <input type="submit" name="Save and Exit" value="Save and Exit" onClick={(e) => { setSpouseDetSave(e.target.value) }}/>&nbsp;
                                                                            
                                                                            <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                                <input type="button" className="p-relative" value="Cancel" />
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                            </tbody>
                                                        </table>
                                                    </form>    
        {/* ------------- Contact Details ------------  */}
                                                <form name="contact_details" method="post" onSubmit={handleAddContact} >
                                                    <table className="defaultTbl" cellSpacing={1} align="center" width="800" style={{ display: div_id6 }}>
                                                        <tbody>  
                                                            <tr className="caption">
                                                                <th colSpan={4} style={{ textAlign: 'center' }}>CONTACT DETAILS
                                                                </th>
                                                            </tr>

                                                            <tr>
                                                                <td className="caption">
                                                                     CONTACT
                                                                </td>
                                                                <td>
                                                                    <select id="sub_1_field_967" name="sub_1_field_967" className="text-uppercase" value={ContactDetails} onChange = {(e) => { setContactDetails(e.target.value); OnContactWiseChange(e.target.value); }}>
                                                                        <option value="0">-- Select An Option --</option>
                                                                        {ContactDetailsAll != null ?
                                                                        ContactDetailsAll.map((item) => {
                                                                            return (
                                                                            <option key={item.id} value={item.id}>
                                                                            {item.name}
                                                                            </option>
                                                                            );
                                                                        })
                                                                        : null }
                                                                    </select>
                                                                </td>

                                                                <td className="caption">
                                                                    RELATIONSHIP <span style={{ color: "red" }}>*</span>
                                                                </td>
                                                                <td>
                                                                    <select id="sub_1_field_968" name="sub_1_field_968"  className="text-uppercase" value={ContactRelationship} onChange = {(e) => { setContactRelationship(e.target.value) }} style={colorFocus5 != '' ? {borderColor: colorFocus5} : null } >
                                                                        <option value="0">-- Select An Option --</option>
                                                                        {RelationshipAll.map((item) => {
                                                                            return (
                                                                            <option key={item.id} value={item.id}>
                                                                            {item.relation}
                                                                            </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td className="caption">
                                                                    TITLE
                                                                </td>
                                                                <td>
                                                                    <select id="sub_1_field_969" className="text-uppercase" name="sub_1_field_969" value={ContactTitle} onChange = {(e) => { setContactTitle(e.target.value) }} >
                                                                        <option value="0">-- Select An Option --</option>
                                                                        {ContactTitleAll.map((item) => {
                                                                                return (
                                                                                <option key={item.id} value={item.id}>
                                                                                {item.title}
                                                                                </option>
                                                                                );
                                                                        })}
                                                                    </select>
                                                                </td>

                                                                <td className="caption">
                                                                    FIRST NAME <span style={{ color: "red" }}>*</span>
                                                                </td>
                                                                <td>
                                                                    <input type="text" id="firstname" name="sub_1_field_970" className="text-uppercase" value={ContactFirstName} onChange = {(e) => { setContactFirstName(e.target.value) }} style={colorFocus6 != '' ? {borderColor: colorFocus6} : null } />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td className="caption">
                                                                   SURNAME <span style={{ color: "red" }}>*</span>
                                                                </td>
                                                                <td>
                                                                    <input type="text" id="surname" name="sub_1_field_971" className="text-uppercase" value={ContactSurnameName} onChange = {(e) => { setContactSurnameName(e.target.value) }} style={colorFocus7 != '' ? {borderColor: colorFocus7} : null } />
                                                                </td>

                                                                <td className="caption">
                                                                    MOBILE NUMBER	
                                                                </td>
                                                                <td>
                                                                    <input type="text" id="mobile_no" name="sub_1_field_1748" style={{ textTransform: "uppercase" }} value={ContactMobileNo} onChange = {(e) => { setContactMobileNo(e.target.value) }} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td className="caption">
                                                                    HOME PHONE
                                                                </td>
                                                                <td>
                                                                    <input type="text" id="home_phone" name="sub_1_field_972" style={{ textTransform: "uppercase" }} value={ContactHomePhone} onChange = {(e) => { setContactHomePhone(e.target.value) }} />
                                                                </td>

                                                                <td className="caption">
                                                                    WORK PHONE
                                                                </td>
                                                                <td>
                                                                    <input type="text" id="work_phone" name="sub_1_field_973" style={{ textTransform: "uppercase" }} value={ContactWorkPhone} onChange = {(e) => { setContactWorkPhone(e.target.value) }} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td className="caption">
                                                                    PHYSICAL ADDRESS
                                                                </td>
                                                                <td>
                                                                    <textarea rows="4" id="physical_address" name="sub_1_field_976" style={{ textTransform: "uppercase" }} value={ContactPhysicalAddress} onChange = {(e) => { setContactPhysicalAddress(e.target.value) }} ></textarea>
                                                                </td>

                                                                <td className="caption">
                                                                    EMPLOYER NAME
                                                                </td>
                                                                <td>
                                                                    <input type="text" id="employee_name" name="sub_1_field_974" style={{ textTransform: "uppercase" }} value={ContactEmployerName} onChange = {(e) => { setContactEmployerName(e.target.value) }} />
                                                                </td>

                                                            </tr>

                                                            <tr>
                                                                <td className="caption">
                                                                    DEPARTMENT
                                                                </td>
                                                                <td>
                                                                    <input type="text" id="department" name="sub_1_field_97" style={{ textTransform: "uppercase" }} value={ContactDepartment} onChange = {(e) => { setContactDepartment(e.target.value) }} />
                                                                </td>

                                                                <td colSpan={2}>&nbsp;</td>
                                                            </tr>
                                                            
                                                            <tr className="caption" >
                                                                <td colSpan={11} style={{ textAlign: "center" }}>

                                                                    { PrintButtonData != '' ?
                                                                        <input type="submit" name="command" value={PrintButton} onClick={(e) => { setPrintButton(e.target.value); setContactDetailsSave(e.target.value);  }} />
                                                                    : null }
                                                                    &nbsp;

                                                                    <input type="submit" name="command" value="Save" onClick={(e) => { setContactDetailsSave(e.target.value) }}/>&nbsp;
                                                                
                                                                    <input type="submit" name="command" value="Save and Exit" onClick={(e) => { setContactDetailsSave(e.target.value) }} />&nbsp;
                                                                
                                                                    <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                    <input type="button" className="p-relative" value="Cancel" />
                                                                    </Link>
                                                            
                                                                </td>
                                                            </tr>
                                                 
                                                    </tbody>
                                                </table>
                                              </form>  
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            {/* </form> */}
            <Footer /> 
        </>
    )
}

export default Finance_application_ford_finance