import { Link, useNavigate, useParams } from "react-router-dom"
import React, { useState, useEffect } from "react";
//import { useLocation } from "react-router";
import Transaction_menu from "./Transaction_menu";
import axios from "../axios";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../Components/Loader"
import view_img from '../Images/logo/view.png';
import refresh_img from '../Images/core/refresh.png';
import ModalDialog from './../ModalDialog'
import Search_img from '../Images/logo/search.svg';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { Modal, Button } from 'react-bootstrap'

const Transaction = () => {

    const navigate = useNavigate();
    //const location = useLocation();

    const system_type = localStorage.getItem("system_type");
    const super_user = localStorage.getItem("super_user");
    const user_id = localStorage.getItem("UserId");
    const division_id = localStorage.getItem("division_id");
    const departments_id = localStorage.getItem("department_id");
    const tp_id = localStorage.getItem("product_hidden_id");  // for using vehicle details module
    const id = localStorage.getItem("product_hidden_tp_id"); // for using vehicle details module
    const parent_id = localStorage.getItem("parent_id");
    const client_id = localStorage.getItem("client_id");
    const unincept_flag = localStorage.getItem("unincept_flag");
    const user_country_id = localStorage.getItem("user_country_id");
    const local_instance_id = localStorage.getItem("instance_id");
    const sess_access_token = localStorage.getItem("sess_access_token");
    const transID = localStorage.getItem("transaction_id");
    const model_status = localStorage.getItem("model_status");
    
   // const [StatusApiData, setStatusApiData] = useState([]);
   const [RefreshFlag, setRefreshFlag] = useState(true);

    const handleRefresh = (loanhouse_id,application_id) => {
        // alert(loanhouse_id)
        setLoader(true);
        const payload = {
            "sess_access_token": sess_access_token,
            "transID": transID,
            "loanhouse_id" : loanhouse_id,
            "application_id" : application_id

        }
        axios
            .post("refresh_create_transaction", payload)
            .then((response) => {
                //console.log(response); 
                if(response.data.status === 401){
                    alert(response.data.message);
                }else if (response.data.status === 200) {
                    alert(response.data.message);
                   // setStatusApiData(response.data.data);
                   setRefreshFlag(true)
                }
                setLoader(false);
            })
            .catch((response) => {
                //console.log('error');
                if (response.response.data.status === 400) {
                    // setLoader(false);
                    //alert(response.response.data.data);
                    toast.error(response.response.data.data, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                setLoader(false);
            });
    }

    // console.log(StatusApiData);

    //--------- vehicle transcation details --------
    const transcation = useParams();

    const [TranscationDetails, setTranscationDetails] = useState("");
    const [transcation_id, setTranscationId] = useState("");
    const [currancy, setCurrancy] = useState("");
    const [manufacturer, setManufacturer] = useState("");
    const [TranscationOtherDetails, setTranscationOtherDetails] = useState("");

    const [Usergroup, setUsergroup] = useState(0);
    const [UsergroupAll, setUsergroupAll] = useState([]);
    const [Userbranch, setUserbranch] = useState("");
    const [UserbranchAll, setUserbranchAll] = useState([]);

    const [productDetails, setproductDetails] = useState([]);
    const [regularDrivers, setregularDrivers] = useState([]);

    const [printableDocument, setPrintableDocument] = useState([]);
    const [TranscationContact, setTranscationContact] = useState([]);
    const [FinanceApplications, setFinanceApplications] = useState([]);
    const [SupportingInformation, setSupportingInformation] = useState([]);
    const [FinanceData, setFinanceData] = useState("");
    const [FinancerAll, setFinancerAll] = useState([]);
    const [TranscationContactOtherDetails, setTranscationContactOtherDetails] = useState([]);

    const [ContactCopy, setContactCopy] = useState("");
    const [ContactDelete, setContactDelete] = useState("");
    // const [HiddenValue, setHiddenValue] = useState([]);
    const [form_id, setform_id] = useState("");
    const [instance_id, setInstance_id] = useState("");
    const [trans_id, setTrans_id] = useState("");
    const [company_id, setCompany_id] = useState("");
    const [department_id, setDepartment_id] = useState("");
    const [group_id, setGroup_id] = useState("");
    const [compulsory_forms, setCompulsory_forms] = useState("");
    const [delete_instance, setDelete_instance] = useState("");
    const [add_button, setAdd_button] = useState("");
    const [transcation_schedule_button, setTranscationScheduleButton] = useState("");
    const [TranscationContactButton, setTranscationContactButton] = useState("");
    const [FinanceApplicationButton, setFinanceApplicationButton] = useState("");

    const [FinanceWorkFlowId, setFinanceWorkFlowId] = useState("");
    const [FinanceMasterInstanceId, setFinanceMasterInstanceId] = useState("");
    const [FinanceDepartmentId, setFinanceDepartmentId] = useState("");
    const [FinanceTranscationId, setFinanceTranscationId] = useState("");
    const [FinanceClientId, setFinanceClientId] = useState("");

    const [SupportingInfoiMasterInstanceID, setSupportingInfoiMasterInstanceID] = useState("");
    const [SupportingInfoDepartment_id, setSupportingInfoDepartment_id] = useState("");
    const [SupportingInfoTransaction_id, setSupportingInfoTransaction_id] = useState("");
    const [SupportingInfoiCompulsoryFormsGroupID, setSupportingInfoiCompulsoryFormsGroupID] = useState("");
    const [SupportingInfoClient_id, setSupportingInfoClient_id] = useState("");
    const [FooterData, setFooterData] = useState("");
    const [VehicleProductVisible, setVehicleProductVisible] = useState("");

    const [PrintableDocummentDetails, setPrintableDocummentDetails] = useState([]);
    const [roa_file_name, setRoaFile] = useState("");
    // const [InsuranceSchedule, setInsuranceSchedule] = useState("");
    // const [TransactionSchedule, setTransactionSchedule] = useState("");

    const [flag, setFlag] = useState(false);
    const [ReadOnly, setReadOnly] = useState(false);

    const [Print2Flag, setPrint2Flag] = useState(false);//for Transaction Schedule
    const [Print1Flag, setPrint1Flag] = useState(false);//for Insurance Proposal
    const [DriverDipFlag, setDriverDipFlag] = useState(0);
    const [HistoryFlag, setHistoryFlag] = useState("");

    const [loader, setLoader] = useState(false);

    //----------------check permission------------------
    useEffect(() => {
        const payload = {
            UserId: user_id,
            super_user: super_user,
            module_name: "Transactions"
        }
        axios
            .post("getRights", payload)
            .then((response) => {
                //console.log(response.data.data)
                // alert(response.data.message);
                if (response.data.status === 200) {
                    if (response.data.data === 0) {//None
                        alert(response.data.message);
                        navigate('/home');
                    } else if (response.data.data === 1) {//Read
                        setFlag(true); setReadOnly(true);
                    } else if (response.data.data === 2) {//Write
                        setFlag(true); setReadOnly(false);
                    } else if (response.data.data === 3) {//Modify
                        setFlag(true); setReadOnly(false);
                    } else if (response.data.data === 4) {//Admin
                        setFlag(true); setReadOnly(false);
                    }
                }
            })
            .catch(() => {
                //setLoginLoader(false);
            });
    }, [user_id, super_user, navigate]);

    useEffect(() => {
        document.title = 'Transactions | Seriti BW';
    }, []);

    //----------- get footer html -----------------
    useEffect(() => {
        const payload = {
            transaction_id: transcation.id,
            system_type: system_type,
            user_country_id: user_country_id,
            user_id: user_id,
        };
        //console.log(payload); 
        axios
            .post("dashboard_transcation/dashboard_transaction_footer", payload)
            .then((response) => {
                // console.log(response); 
                setFooterData(response.data);
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //------------------ Copy client transcation ----------------
    const CopyClientTranscation = (person_edit_id) => {
        const payload = {
            transaction_id: transcation.id,
            system_type: system_type,
            user_id: user_id,
            id: client_id,
            person_id: person_edit_id,
            cclient: 1,
        };
        // console.log(payload);
        axios
            .post("add_person/transcation_wise_client_copy", payload)
            .then((response) => {
                //console.log(response);
                if (response.data.status === 200) {
                    window.location.reload(false);
                }
            })
            .catch(() => {
                //console.log('error');
            });
    }

    //------------------ Delete client transcation ----------------
    const DeleteClientTranscation = (person_edit_id) => {
        const payload = {
            transaction_id: transcation.id,
            system_type: system_type,
            user_id: user_id,
            person_id: person_edit_id,
        };
        // console.log(payload);
        axios
            .post("add_person/transcation_wise_client_delete", payload)
            .then((response) => {
                //console.log(response);
                if (response.data.status === 200) {
                    window.location.reload(false);
                }
            })
            .catch(() => {
                //console.log('error');
            });
    }

    //------------------ Client client transcation ----------------
    const ClientClientTranscation = (person_edit_id) => {
        const payload = {
            transaction_id: transcation.id,
            system_type: system_type,
            user_id: user_id,
            person_id: person_edit_id,
        };
        // console.log(payload);
        axios
            .post("add_person/transcation_wise_client_client", payload)
            .then((response) => {
                //console.log(response);
                if (response.data.status === 200) {
                    window.location.reload(false);
                }
            })
            .catch(() => {
                //console.log('error');
            });
    }

    //--------------- support information redirection -----------
    const change_fillForm = (event, form_id) => {
        //alert(form_id);
        const hidden_data = {
            instance_id: SupportingInfoiMasterInstanceID,
            transaction_id: SupportingInfoTransaction_id,
            company_id: SupportingInfoClient_id,
            department_id: SupportingInfoDepartment_id,
            group_id: SupportingInfoiCompulsoryFormsGroupID,
            compulsory_forms: 1,
            form_id: form_id,
        };
        localStorage.setItem("HiddenData", JSON.stringify(hidden_data));
        //navigate('/transaction/fill_forms',{state:hidden_data});
        navigate('/transaction/fill_forms');
    }
    //console.log(add_button);


    useEffect(() => {
        setLoader(true);
        const payload = {
            user_id: user_id,
            system_type: system_type,
            super_user: super_user,
            transaction_id: transcation.id,
        };
        //--------------- transcation_id to stored local storage
        localStorage.setItem("transaction_id", transcation.id);
        //console.log(payload);
        axios
            .post("transcation/vehicle_transcation_details", payload)
            .then((response) => {
               // console.log(response);
                setTranscationDetails(response.data.data)
                setTranscationId(response.data.data.transcation_id)
                setUsergroup(response.data.data.division_id)
                setUserbranch(response.data.data.department_id)
                setCurrancy(response.data.data.currancy)
                setManufacturer(response.data.data.manufacturer)
                setTranscationOtherDetails(response.data.next_button_data)
                localStorage.setItem("parent_id", response.data.data.parent_id)
                localStorage.setItem("unincept_flag", response.data.data.chkflag)
                localStorage.setItem("client_id", response.data.data.client_id)
                localStorage.setItem("model_status", true);
                setLoader(false);
            })
            .catch(() => {
                // console.log('error');
                setLoader(false);
            });
    }, []);

    //--------- get product --------
    useEffect(() => {
        const payload = {
            user_id: user_id,
            system_type: system_type,
            transaction_id: transcation.id,
            currancy: currancy,
            manufacturer: manufacturer,
        };
        //console.log(payload);
        axios
            .post("transcation/vehicle_transcation_product", payload)
            .then((response) => {
                //console.log(response);
                if (response.status === 200) {
                    setproductDetails(response.data.data)
                    localStorage.setItem("product_hidden_tp_id", response.data.next_button_data.id)
                    localStorage.setItem("product_hidden_id", response.data.next_button_data.tp_id)
                    setVehicleProductVisible(response.data.next_button_data.vehicle_product_add_flag)
                }

            })
            .catch(() => {
                // console.log('error');
            });
    }, [currancy, manufacturer]);

    //--------- get regular drivers --------
    useEffect(() => {
        const payload = {
            user_id: user_id,
            system_type: system_type,
            transaction_id: transcation.id,
        };
        //console.log(payload);
        axios
            .post("transcation/vehicle_transcation_regular_drivers", payload)
            .then((response) => {
                //  console.log(response);
                if (response.status === 200) {
                    setDriverDipFlag(1);
                    //setLoader(false);
                    setregularDrivers(response.data.data)
                    //setHiddenValue(response.data.next_button_data)
                    setform_id(response.data.next_button_data.form_id)
                    setInstance_id(response.data.next_button_data.instance_id)
                    setTrans_id(response.data.next_button_data.transaction_id)
                    setCompany_id(response.data.next_button_data.company_id)
                    setDepartment_id(response.data.next_button_data.department_id)
                    setGroup_id(response.data.next_button_data.group_id)
                    setCompulsory_forms(response.data.next_button_data.compulsory_forms)
                    setDelete_instance(response.data.next_button_data.delete_instance)
                    setAdd_button(response.data.next_button_data.add_button)
                    localStorage.setItem("form_id", 46)
                }

            })
            .catch(() => {
                // console.log('error');DriverDipFlag
            });
    }, [DriverDipFlag]);

    //--------- get printable documents --------
    useEffect(() => {
        const payload = {
            user_id: user_id,
            system_type: system_type,
            transaction_id: transcation.id,
        };
        //console.log(payload);
        axios
            .post("transcation/vehicle_transcation_printable_documents", payload)
            .then((response) => {
                //console.log(response.data.data);
                if (response.status === 200) {
                    setPrintableDocument(response.data.data)
                    setTranscationScheduleButton(response.data.data.action_button)
                }

            })
            .catch(() => {
                // console.log('error');
            });
    }, []);

    //--------- get transaction contacts --------
    useEffect(() => {
        const payload = {
            user_id: user_id,
            system_type: system_type,
            transaction_id: transcation.id,
        };
        //console.log(payload);
        axios
            .post("transcation/vehicle_transcation_contacts", payload)
            .then((response) => {
                //console.log(response.data);
                if (response.status === 200) {
                    setTranscationContact(response.data.data)
                }
                setTranscationContactButton(response.data.other_data.main_button_show)
                setTranscationContactOtherDetails(response.data.other_data)
            })
            .catch(() => {
                // console.log('error');
            });
    }, []);

    //--------- get finance applications --------
    // useEffect(() => {
    //     const payload = {
    //         user_id: user_id,
    //         super_user: super_user,
    //         system_type: system_type,
    //         transaction_id: transcation.id,
    //     };
    //     //console.log(payload);
    //     axios
    //         .post("transcation/vehicle_transcation_finance_application", payload)
    //         .then((response) => {
    //             // console.log(response.data.other_data);
    //             if (response.status === 200) {
    //                 // console.log(response);
    //                 setFinanceApplications(response.data.data)
    //             }
    //             setFinancerAll(response.data.financer_data);
    //             setFinanceApplicationButton(response.data.other_data.main_button_show)
    //             setFinanceWorkFlowId(response.data.other_data.iWorkflowID)
    //             setFinanceMasterInstanceId(response.data.other_data.iMasterInstanceID)
    //             setFinanceDepartmentId(response.data.other_data.department_id)
    //             setFinanceTranscationId(response.data.other_data.transaction_id)
    //             setFinanceClientId(response.data.other_data.client_id)
    //             setHistoryFlag(response.data.other_data.history_flag)
    //             localStorage.setItem("instance_id", response.data.other_data.instance_id);
    //             localStorage.setItem("company_id", response.data.other_data.company_id);
    //             //localStorage.setItem("application_id", response.data.other_data.application_id);
    //             // localStorage.setItem("group_id", response.data.other_data.group_id);
    //             // localStorage.setItem("loanhouse_id", response.data.other_data.loanhouse_id);
    //         })
    //         .catch(() => {
    //             // console.log('error');
    //         });
    // }, []);

    useEffect(() => {
        if (RefreshFlag) {
        const payload = {
            user_id: user_id,
            super_user: super_user,
            system_type: system_type,
            transaction_id: transcation.id,
        };
        //console.log(payload);
        axios
            .post("transcation/vehicle_transcation_finance_application", payload)
            .then((response) => {
                // console.log(response.data.other_data);
                if (response.status === 200) {
                    // console.log(response);
                    setFinanceApplications(response.data.data)
                }
                setFinancerAll(response.data.financer_data);
                setFinanceApplicationButton(response.data.other_data.main_button_show)
                setFinanceWorkFlowId(response.data.other_data.iWorkflowID)
                setFinanceMasterInstanceId(response.data.other_data.iMasterInstanceID)
                setFinanceDepartmentId(response.data.other_data.department_id)
                setFinanceTranscationId(response.data.other_data.transaction_id)
                setFinanceClientId(response.data.other_data.client_id)
                setHistoryFlag(response.data.other_data.history_flag)
                localStorage.setItem("instance_id", response.data.other_data.instance_id);
                localStorage.setItem("company_id", response.data.other_data.company_id);
                setRefreshFlag(false)
            })
            .catch(() => {
                // console.log('error');
            });
        }
    }, [RefreshFlag]);

    //--------- get supporting information --------
    useEffect(() => {
        const payload = {
            user_id: user_id,
            system_type: system_type,
            transaction_id: transcation.id,
            super_user: super_user,
        };
        //console.log(payload);
        axios
            .post("transcation/vehicle_transcation_supporting_information", payload)
            .then((response) => {
                //console.log(response.data);
                if (response.status === 200) {
                    setSupportingInformation(response.data.data)
                }
                setSupportingInfoiMasterInstanceID(response.data.other_data.iMasterInstanceID)
                setSupportingInfoDepartment_id(response.data.other_data.department_id)
                setSupportingInfoTransaction_id(response.data.other_data.transaction_id)
                setSupportingInfoiCompulsoryFormsGroupID(response.data.other_data.iCompulsoryFormsGroupID)
                setSupportingInfoClient_id(response.data.other_data.client_id)

                const hidden_data = {
                    instance_id: response.data.other_data.iMasterInstanceID,
                    transaction_id: response.data.other_data.transaction_id,
                    company_id: response.data.other_data.client_id,
                    department_id: response.data.other_data.department_id,
                    group_id: response.data.other_data.iCompulsoryFormsGroupID,
                    compulsory_forms: 1,
                    form_id: 13,
                };
                localStorage.setItem("HiddenData", JSON.stringify(hidden_data));
                localStorage.removeItem("FillFormTabId", 0);
                localStorage.removeItem("WesBankTabId", 0);
                localStorage.removeItem("StandbicTabId", 0);
                localStorage.removeItem("FordFinanceTabId", 0);
                localStorage.removeItem("BankGaboroneTabId", 0);
                localStorage.removeItem("AbsaBankTabId", 0);
                
            })
            .catch(() => {
                // console.log('error');
            });
    }, []);

    //----------- get groups -----------------
    // useEffect(() => {
    //     axios
    //         .get("index/group")
    //         .then((response) => {
    //             setUsergroupAll(response.data.data);
    //         })
    //         .catch(() => {
    //             //setLoader(false);
    //         });
    // }, []);

    useEffect(() => {
        const payload = {
            user_department_access_rights: localStorage.getItem("user_department_access_rights"),
        };
        axios
            .post("user_right_accesable_division", payload)
            .then((response) => {
                //console.log(response);
                setUsergroupAll(response.data.data);
            })
            .catch(() => {
                console.log('error');
               // setLoader(false);
            });

    }, []);

    //----------- get branch -----------------
    useEffect(() => {
        axios
            .get("index/branch/" + Usergroup)
            .then((response) => {
                if (response.status === 200) {
                    setUserbranchAll(response.data.data);
                }
            })
            .catch(() => {
                //setLoader(false);
            });
    }, [Usergroup]);

    //-----------------
    const Transcation_change = (e) => {
        e.preventDefault();
        setLoader(true);
        const payload = {
            transaction_id: transcation.id,
            division_id: division_id,
            department_id: departments_id,
            user_id: user_id,
            new_division_id: Usergroup,
            new_department_id: Userbranch,
            command: "Change",
        };
        //console.log(payload);
        axios
            .post("transcation/vehicle_transcation_change", payload)
            .then((response) => {
                // console.log(response);
                if (response.data.status === 200) {
                    // window.location.reload(false);
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                setLoader(false);
                window.location.reload(false);
            })
            .catch(() => {
                //console.log('error');
                setLoader(false);
            });
    }

    const RemoveVehicle = (remove_tp_id) => {
        const payload = {
            transaction_id: transcation.id,
            tp_id: remove_tp_id,
            user_id: user_id,
        };
        //console.log(payload);
        axios
            .post("transcation/product_transcation_remove", payload)
            .then((response) => {
                // console.log(response);
                if (response.data.status === 200) {
                    window.location.reload(false);
                }
            })
            .catch(() => {
                //console.log('error');
            });
    }

    //---------------- finance application submit
    const handleFinanceAppValidation = () => {
        var flag = true;
        if (FinanceData === '') {
            alert('Please Select a Loan House.');
            flag = false;
        }
        return flag;
    }

    const handleFinanceApplicationSubmit = (e) => {
        e.preventDefault();
        const flag = handleFinanceAppValidation();
        if (flag) {
            const payload = {
                transaction_id: transcation.id,
                user_id: user_id,
                instance_id: local_instance_id,
                loan_house: FinanceData,
                command: "New Application",
            };
            // console.log(payload);
            axios
                .post("transcation/finance_application_submit", payload)
                .then((response) => {
                    // console.log(response);
                    if (response.data.status === 200) {
                        localStorage.setItem("group_id", response.data.data.group_id)
                        localStorage.setItem("loanhouse_id", response.data.data.loanhouse_id)
                        localStorage.setItem("form_id", response.data.data.form_id)
                        localStorage.setItem("application_id", response.data.data.application_id);

                        if (response.data.data.loanhouse_id == 43) {
                            localStorage.setItem("WesBankTabId", 25)
                            navigate('/transaction/finance_application');
                        } else if (response.data.data.loanhouse_id == 44) {
                            localStorage.setItem("StandbicTabId", 97)
                            navigate('/transaction/finance_application_standbic');
                        } else if (response.data.data.loanhouse_id == 51) {
                            localStorage.setItem("FordFinanceTabId", 97)
                            navigate('/transaction/finance_application_ford_finance');
                        } else if (response.data.data.loanhouse_id == 45) {
                            navigate('/transaction/finance_application_bank_garborone');
                        } else if (response.data.data.loanhouse_id == 42) {
                            localStorage.setItem("AbsaBankTabId", 91)
                            navigate('/transaction/finance_application_bank_absa');
                        } else if (response.data.data.loanhouse_id == 52 || response.data.data.loanhouse_id == 53 ) {
                            navigate('/transaction/finance_application_bostwana_building');
                        } else if (response.data.data.loanhouse_id == 48 || response.data.data.loanhouse_id == 49 || response.data.data.loanhouse_id == 47 ) {
                            navigate('/transaction/finance_application_capital_baroda_standard_bank');
                        } 
                    }
                })
                .catch(() => {
                    //console.log('error');
                });
        }
    }

    const FinanceApplicationHiddenData = (group_id, loanhouse_id, application_id) => {
        //  alert(loanhouse_id);
        if (loanhouse_id == 43) {
            if (group_id != '' || loanhouse_id != '') {     // wesbank
                localStorage.setItem("group_id", group_id);
                localStorage.setItem("loanhouse_id", loanhouse_id);
                localStorage.setItem("application_id", application_id);
                localStorage.setItem("WesBankTabId", 25)
                navigate('/transaction/finance_application');
            }
        } else if (loanhouse_id == 44) {                   // standbic
            if (group_id != '' || loanhouse_id != '') {
                localStorage.setItem("group_id", group_id);
                localStorage.setItem("loanhouse_id", loanhouse_id);
                localStorage.setItem("application_id", application_id);
                localStorage.setItem("StandbicTabId", 97)
                navigate('/transaction/finance_application_standbic');
            }
        } else if (loanhouse_id == 51) {
            if (group_id != '' || loanhouse_id != '') {  // ford finance
                localStorage.setItem("group_id", group_id);
                localStorage.setItem("loanhouse_id", loanhouse_id);
                localStorage.setItem("application_id", application_id);
                localStorage.setItem("FordFinanceTabId", 97)
                navigate('/transaction/finance_application_ford_finance');
            }
        } else if (loanhouse_id == 45) {  // bank garborone

            localStorage.setItem("group_id", group_id);
            localStorage.setItem("loanhouse_id", loanhouse_id);
            localStorage.setItem("application_id", application_id);
            navigate('/transaction/finance_application_bank_garborone');

        } else if (loanhouse_id == 42) {  // bank absa

            localStorage.setItem("group_id", group_id);
            localStorage.setItem("loanhouse_id", loanhouse_id);
            localStorage.setItem("application_id", application_id);
            localStorage.setItem("AbsaBankTabId", 91)
            navigate('/transaction/finance_application_bank_absa');
        } else if (loanhouse_id == 52 || loanhouse_id == 53) {  // bostwana building society (52) / Acess bank (53)

            localStorage.setItem("group_id", group_id);
            localStorage.setItem("loanhouse_id", loanhouse_id);
            localStorage.setItem("application_id", application_id);
            navigate('/transaction/finance_application_bostwana_building');
        } else if (loanhouse_id == 48 || loanhouse_id == 49 || loanhouse_id == 47) {  // Bank of baroda (48) / Capital bank (49) / Standard chareted bank (47)

            localStorage.setItem("group_id", group_id);
            localStorage.setItem("loanhouse_id", loanhouse_id);
            localStorage.setItem("application_id", application_id);
            navigate('/transaction/finance_application_capital_baroda_standard_bank');
        }

    }

    //---------------- featch printable documents
    useEffect(() => {
        const payload = {
            transaction_id: transcation.id,
            system_id: system_type,
            user_country_id: user_country_id,
        };
        //console.log(payload);
        axios
            .post("transcation/featch_printable_documents", payload)
            .then((response) => {
                // console.log(response.data);
                if (response.status === 200) {
                    setPrintableDocummentDetails(response.data.data)
                }
            })
            .catch(() => {
                // console.log('error');
            });
    }, []);

    const handlePrintableDocumentsSubmit = (e) => {
        e.preventDefault();

        var bodyFormData = new FormData();
        bodyFormData.append("transaction_id", transcation.id);
        bodyFormData.append("command", 'Upload');
        bodyFormData.append("roa_pdf", roa_file_name);

        axios
            .post("transcation/finance_application_submit", bodyFormData)
            .then((response) => {
                // console.log(response);
                if (response.data.status === 200) {

                }
            })
            .catch(() => {
                //console.log('error');
            });
    }

    //Transaction Proposal PDF
    useEffect(() => {
        if (Print2Flag) {
            const payload = {
                transaction_id: transcation.id,
                user_id: user_id,
                system_type: system_type,
                division_id: division_id,
                command: "Print2",
            };
            //console.log(payload);
            axios
                .post("transcation/featch_printable_documents_pdf", payload)
                .then((response) => {
                    // console.log(response);
                    if (response.data.status === 200) {
                        setPrint2Flag(false);
                      
                        var link = document.createElement('a');
                        document.body.appendChild(link);
                        link.target = "_blank";
                        link.href = response.data.data.original.data.url;
                        link.download = '';
                        link.click();
                    }
                })
                .catch(() => {
                    // console.log('error');
                });
        }
    }, [Print2Flag]);

    //Insurance Schedule PDF
    // useEffect(() => {
    //     //if (Print1Flag) {
    //       //  setLoader(true);
    //         const payload = {
    //             transaction_id: transcation.id,
    //             user_id: user_id,
    //             system_type: system_type,
    //             division_id: division_id,
    //             command: "Print1",
    //         };
    //         //console.log(payload);
    //         axios
    //             .post("transcation/featch_printable_documents_pdf", payload)
    //             .then((response) => {
    //                 // console.log(response.data.data.original.data);
    //                 if (response.data.status === 200) {
    //                      //console.log(response.data.data.original.data.url);
    //                     setInsuranceSchedule(response.data.data.original.data.url);
    //                 //     setPrint1Flag(false);
    //                 //     setLoader(false);
                     
    //                 //     var link = document.createElement("a");
    //                 //    // link.download = response.data.data.original.data;
    //                 //     link.target = "_blank";
    //                 //     link.href = response.data.data.original.data;
    //                 //     document.body.appendChild(link);
    //                 //     link.click();
    //                 //     document.body.removeChild(link)

    //                 //     toast.success(response.data.message, {
    //                 //         position: toast.POSITION.TOP_CENTER
    //                 //     });
    //                 }
    //             })
    //             .catch(() => {
    //                 // console.log('error');
    //             });
    //     //}
    // }, []);

     //Insurance Schedule PDF
     useEffect(() => {
        if (Print1Flag) {
           // setLoader(true);
            const payload = {
                transaction_id: transcation.id,
                user_id: user_id,
                system_type: system_type,
                division_id: division_id,
                command: "Print1",
            };
            //console.log(payload);
            axios
                .post("transcation/featch_printable_documents_pdf", payload)
                .then((response) => {
                    // console.log(response.data.data.original.data);
                    if (response.data.status === 200) {
                        setPrint1Flag(false);
                       
                        var link = document.createElement('a');
                        document.body.appendChild(link);
                        link.target = "_blank";
                        link.href = response.data.data.original.data.url;
                        link.download = '';
                        link.click();
                    }
                })
                .catch(() => {
                    // console.log('error');
                });
        }
    }, [Print1Flag]);


    //---------------- generate pdf and redirect url -------------
    const GeneratePrintPdf = (group_id, loanhouse_id, application_id) => {
        //alert('as');
        localStorage.setItem("group_id", group_id);
        localStorage.setItem("loanhouse_id", loanhouse_id);
        localStorage.setItem("application_id", application_id);

        const payload = {
            transaction_id: transcation.id,
            application_id: application_id,
            instance_id: instance_id,
            group_id: group_id,
            loanhouse_id: loanhouse_id,
            system_type: system_type,
            user_id: user_id,
            division_id: division_id,
            department: departments_id,
            command: "Print",
        };
        // console.log(payload);
        axios
            .post("finance_application/finance_pdf_calling", payload)
            .then((response) => {
                //console.log(response);
                if (response.status == 200) {
                    //window.location.reload(false);
                    navigate('/transaction/prepare/application_id/' + response.data.data + '/regenerated=true');
                   // navigate('/transaction/prepare');
                }
            })
            .catch(() => {
                console.log('error');
            });
    }

    const handleDriverRowDeleteRow = (ContactsId) => {
        //  e.preventDefault();
        const payload = {
            instance_id: instance_id,
            contact_id: ContactsId,
            user_id: user_id,
            command: "Delete",
        };
        //console.log(payload);
        axios
            .post("transcation/regular_driver_delete", payload)
            .then((response) => {
                // console.log(response);
                if (response.data.status === 200) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    window.location.reload(false);
                    setDriverDipFlag(1);
                }
            })
            .catch(() => {
                //console.log('error');
            });
    }

    const [Status, setStatus] = useState("");
    const [Code, setCode] = useState("");
    const [ModalFlag, setModalFlag] = useState(false);
    let panFlag = 1;

    const handleView = (status, code) => {
        //console.log(status,code);
        setStatus(status);
        setCode(code);
        setModalFlag(true);
    }
   
    //----------------- application history model pop-up
    const [HistorytransactionId, setHistorytransactionId] = useState("");
    const [HistoryLoanHouseId, setHistoryLoanHouseId] = useState("");
    const [HistoryLoanHousename, setHistoryLoanHousename] = useState("");

    // const handleApplicationHitory = (transactionId, LoanHouseId, LoanHousename) => {
    //     //console.log(status,code);
    //     setHistorytransactionId(transactionId);
    //     setHistoryLoanHouseId(LoanHouseId);
    //     setHistoryLoanHousename(LoanHousename);
    //     setHistoryModalFlag(true);
    // }
    
    const [HistoryModalFlag1, setHistoryModalFlag1] = useState(false);
    const [ApplicationHistoryData, setApplicationHistoryData] = useState([]);

    const handleApplicationHitory1 = (transactionId, LoanHouseId, LoanHousename) => {
        //console.log(status,code);
        setHistorytransactionId(transactionId);
        setHistoryLoanHouseId(LoanHouseId);
        setHistoryLoanHousename(LoanHousename);
        setHistoryModalFlag1(true);
    }

    useEffect(() => {
        const payload = {
            iTransactionId: HistorytransactionId,
            iLoanId: HistoryLoanHouseId,
        };
        //console.log(payload);
        axios
            .post("transcation/application_history", payload)
            .then((response) => {
                // console.log(response);
                if (response.data.status === 200) {
                    setApplicationHistoryData(response.data.data);
                }
            })
            .catch(() => {
                // console.log('error');
            });
    }, [HistoryModalFlag1]);

    // console.log(model_status);
    const closeModal = () => {
        setHistoryModalFlag1(false);
    }

    //----------------- compare to history
    const [CompareHistoryModalFlag1, setCompareHistoryModalFlag1] = useState(false);
    const [CompareApplicationHistoryData, setCompareApplicationHistoryData] = useState("");
    
    const [CompHistoryLoanHouseId, setCompHistoryLoanHouseId] = useState("");
    const [HistorySubmitDate, setHistorySubmitDate] = useState("");
    const [HistoryCurrentDate, setHistoryCurrentDate] = useState("");
    const [modelFlag11, setmodelFlag11] = useState(false);

    const handleCompareToHitory = (submitDate, LoanHouseId, currentDate) => {
        
        setCompareHistoryModalFlag1(true);
        setCompHistoryLoanHouseId(LoanHouseId);
        setHistorySubmitDate(submitDate);
        setHistoryCurrentDate(currentDate);
        setmodelFlag11(true);
    }

    useEffect(() => {
        setLoader(true);
        if(modelFlag11){
        const payload = {
            iPreviousTransactionDate: HistorySubmitDate,
            iCurrentTransactionDate: HistoryCurrentDate,
            loan_id: CompHistoryLoanHouseId,
            iTransactionID: transID,
        };
        //console.log(payload);
        axios
            .post("transcation/compare_application_history", payload)
            .then((response) => {
                // console.log(response);
                setCompareApplicationHistoryData(response.data);
                setLoader(false);
            })
            .catch(() => {
                // console.log('error');
                setLoader(false);
            });
        }
    }, [modelFlag11]);
    
    const closeModal1 = () => {
        setCompareHistoryModalFlag1(false);
    }

    return (
        <>

    <ToastContainer theme="colored" style={{ width: "450px", marginLeft: "-200px" }} />
    <Transaction_menu panel_flag={panFlag}/>

    {loader ? <Loader /> : null}

    {ModalFlag ? <ModalDialog status={Status} code={Code} modelStatus={model_status} /> : null}
    
    {/* {HistoryModalFlag ? <HistoryModalDialog HistorytransactionId={HistorytransactionId} HistoryLoanHouseId={HistoryLoanHouseId} HistoryLoanHousename={HistoryLoanHousename} historyModelStatus={historyModelStatus} /> : null} */}
  
    {flag ?
        <>
          {/* ------------- Application History -----------------  */}
        
          {HistoryModalFlag1 ?
            <Modal show={HistoryModalFlag1} dialogClassName="modal-90w" >
                <Modal.Header closeButton onClick={closeModal} style={{ backgroundColor: "#B86512" }}>
                    <p style={{ height: "5px", fontSize: "14px", color: "white" }}><b>{HistoryLoanHousename} HISTORY</b></p>
                </Modal.Header>
                <Modal.Body className="modal-bg-color" style={{height: "250px", overflow: "auto"}}>
                    <div>
                        <table border="0" cellPadding={0} cellSpacing={0}  id="mainContainer" className="modaltable1 w-100">
                            <tbody>
                                    <tr className="caption">
                                        <th>SUBMISSION DATE</th>
                                        <th>STATUS</th>
                                        <th>APPLICATION</th>
                                        <th>ACTION</th>
                                    </tr>
                                
                                {ApplicationHistoryData != null ?
                                    ApplicationHistoryData.map((item, index) => {
                                        return (
                                    <tr key={index}>
                                        <td>{item.submission_date}</td>
                                        <td>{item.status}</td>
                                        <td>
                                            <a href={item.application_link} target="_blank">{item.application_name}</a>
                                        </td>
                                        <td>
                                            {item.action_flag == 'no' ?
                                            <button onClick={(e) => handleCompareToHitory(item.submission_date,item.loanhouse_id,item.current_date)} className="button12 button4 modal-table-action-btn">Compare to Previous</button>
                                            : null }
                                        </td> 
                                    </tr>
                                    );
                                }) : null }
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-bg-color">
                    <Button onClick={closeModal} className="modal-submit-btn">
                        Close 
                    </Button>
                </Modal.Footer>
            </Modal>
        : null }
        
        {/* ------------------- Compare previos history -------------------- */}

        {CompareHistoryModalFlag1 ?
            <Modal show={CompareHistoryModalFlag1} dialogClassName="modal-90w">
                <Modal.Header closeButton onClick={closeModal1} style={{ backgroundColor: "#B86512" }}>
                    <p style={{ height: "5px", fontSize: "14px", color: "white" }}><b>COMPARISON</b></p>
                </Modal.Header>
                <Modal.Body className="comparison-table" style={{height: "50vh", overflow: "auto"}}>
                    <div>
                        <div dangerouslySetInnerHTML={ {__html: CompareApplicationHistoryData} } />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={closeModal1} className="modal-submit-btn">
                    Back to application History 
                    </Button>
                </Modal.Footer>
            </Modal>
        : null }

            <div>
                <table width="100%" height="1050" id="mainContainer" border="0" cellPadding={0} cellSpacing={0} className="custom-body">
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">
                                <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                                <fieldset disabled={ReadOnly}>
                                    <table width="100%" border="0" cellPadding={5} cellSpacing={5}>
                                        <tbody>
                                            <tr>
                                                <td valign="top" align="center">
                                                    <table width="1050">
                                                        <tbody>
                                                            <tr valign="top" height="100%">
                                                                <td rowSpan={3} width="33%" height="100%" className="text-left">
                                                                    {/* <form method="post"> */}
                                                                    <table className="defaultTbl" width="100%" height="100%" cellSpacing={1}>
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan={2}>Vehicle Transaction Details</th>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Transaction#</td>
                                                            <td nowrap="nowrap">
                                                                {transcation.id}
                                                                {TranscationDetails.international_trno != '' ?
                                                                    <div className="mr-20 float-right">
                                                                        ({TranscationDetails.international_trno})
                                                                    </div>
                                                                    : null}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Sales Ref#</td>
                                                            <td nowrap="nowrap">{TranscationDetails.sales_reference}</td>
                                                        </tr>

                                                        <tr>

                                                            <td className="caption">Group</td>
                                                            {TranscationOtherDetails.dropdown_show === 'yes' ?
                                                                <td nowrap="nowrap">
                                                                    <select name="division" id="division" className="text-uppercase" value={Usergroup} onChange={(e) => setUsergroup(e.target.value)} >
                                                                        <option value="0">-- Select a collection first --</option>
                                                                        {UsergroupAll.map((item) => {
                                                                            let group_name = item.name.replace("&#38;", "'");
                                                                            return (
                                                                                <option key={item.id} value={item.id}>
                                                                                    {group_name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </td>
                                                                :
                                                                <td>
                                                                    {TranscationDetails.division_name}
                                                                </td>
                                                            }
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Branch</td>
                                                            {TranscationOtherDetails.dropdown_show === 'yes' ?
                                                                <td nowrap="nowrap">
                                                                    <select name="department" id="department" className="text-uppercase w-100" value={Userbranch} onChange={(e) => setUserbranch(e.target.value)}>
                                                                        <option>--- SELECT A GROUP FIRST---</option>
                                                                        {UserbranchAll.map((item) => {
                                                                            return (
                                                                                <option key={item.id} value={item.id}>
                                                                                    {item.name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                    <br />
                                                                    <input className="mt-1" type="submit" name="command" value="Change" onClick={Transcation_change} />
                                                                </td>
                                                                :
                                                                <td>
                                                                    {TranscationDetails.department_name}
                                                                </td>
                                                            }
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">{TranscationDetails.full_name_label}</td>
                                                            <td>{TranscationDetails.name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Client Type</td>
                                                            <td nowrap="nowrap">{TranscationDetails.client_type}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Date Created</td>
                                                            <td nowrap="nowrap">{TranscationDetails.created_date}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Inception Status</td>
                                                            <td nowrap="nowrap">{TranscationDetails.inception_status}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Finance Status</td>
                                                            <td nowrap="nowrap">{TranscationDetails.finance_status}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Sales Rep</td>
                                                            <td nowrap="nowrap">{TranscationDetails.sales_rep}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">BUSINESS MANAGER/MARKETER</td>
                                                            <td nowrap="nowrap">{TranscationDetails.buss_manager}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Vehicle Code</td>
                                                            <td nowrap="nowrap">{TranscationDetails.vehicle_code}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Manufacturer</td>
                                                            <td nowrap="nowrap">{TranscationDetails.manufacturer}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Model</td>
                                                            <td nowrap="nowrap">{TranscationDetails.model}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Stock Number</td>
                                                            <td nowrap="nowrap">{TranscationDetails.stock_number}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Total Cost Price</td>
                                                            <td className="text-right" style={{ textAlign: 'right' }} id="amount">{TranscationDetails.total_cost_price}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Financed Amount</td>
                                                            <td className="text-right" style={{ textAlign: 'right' }} id="payable_amount">{TranscationDetails.final_finance_amount}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Term</td>
                                                            <td className="text-right" style={{ textAlign: 'right' }} id="finance_term" name="finance_term">{TranscationDetails.term}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Preferred Interest Rate</td>
                                                            <td className="text-right" style={{ textAlign: 'right' }} id="interest">{TranscationDetails.preferred_interest_rate}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Estimated  {TranscationDetails.period} Installment</td>
                                                            <td>
                                                                { TranscationDetails.final_estimated_installment != '' && TranscationDetails.final_estimated_installment != 0 ?

                                                                   <div className="text-right" style={{ textAlign: 'right' }} id="installment">{TranscationDetails.final_estimated_installment}</div>
                                                                : 
                                                                   <div className="text-right" style={{ textAlign: 'right' }} id="installment">P 0.00</div>
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr className="caption">
                                                            <td className="caption" colSpan={2}>
                                                                <table width="100%">
                                                                    <tbody>
                                                                        <tr>

                                                                            <td className="text-center" width="50%">
                                                                                {TranscationDetails.chkflag === 1 ?
                                                                                    <Link to={"/transaction/client_company_view/" + TranscationDetails.client_id}>
                                                                                        <input type="submit" name="command" value="View Client" />
                                                                                    </Link>
                                                                                    : null}
                                                                            </td>
                                                                            {super_user != '' || TranscationDetails.unincept_flag === 1 ?

                                                                                <td className="text-center" width="50%">
                                                                                    {TranscationDetails.chkflag === 1 ?
                                                                                        <Link to="/transaction/un_incept">
                                                                                            <input type="button" name="command" value="Unincept" />
                                                                                        </Link>
                                                                                        : null}
                                                                                </td>
                                                                                : null}

                                                                            <td className="text-center" width="50%">
                                                                                {TranscationDetails.chkflag === 0 ?
                                                                                    <Link to={"/transaction/client_company_add_edit/" + TranscationDetails.client_id}>
                                                                                        <input type="submit" name="command" value="Client Details" />
                                                                                    </Link>
                                                                                    : null}
                                                                            </td>

                                                                            <td className="text-center">
                                                                                {TranscationDetails.chkflag === 0 ?
                                                                                    <Link to={"/transaction/edit_transaction/" + transcation.id}>

                                                                                        <input type="button" name="command" value="Transaction Details" />
                                                                                    </Link>
                                                                                    : null}
                                                                            </td>

                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                                    </table>
                                                                    {/* </form> */}
                                                                </td>
                                                                {/* Supporting Information */}
                                                                <td rowSpan={2} width="23%" className="text-center">
                                                                    {/* <form method="post"> */}
                                                                    <input type="hidden" name="form_id" id="cform_id" value="" />
                                                                    <input type="hidden" name="instance_id" value={SupportingInfoiMasterInstanceID} />
                                                                    <input type="hidden" name="transaction_id" value={SupportingInfoTransaction_id} />
                                                                    <input type="hidden" name="company_id" value={SupportingInfoClient_id} />
                                                                    <input type="hidden" name="department_id" value={SupportingInfoDepartment_id} />
                                                                    <input type="hidden" name="group_id" id="cgroup_id" value={SupportingInfoiCompulsoryFormsGroupID} />
                                                                    <input type="hidden" name="compulsory_forms" value="1" />
                                                                    <table className="defaultTbl" width="100%" cellSpacing={1}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th colSpan={2}>Supporting Information</th>
                                                                            </tr>

                                                                            {SupportingInformation.length > 0 ?
                                                                                SupportingInformation.map((item, index) => {

                                                                                    return (
                                                                                        <tr key={index}>

                                                                                            <td className="text-center" width="1">
                                                                                                {item.display_style != '' ?
                                                                                                    <input type="submit" name="command" value="View" style={{ textAlign: 'center', position: 'relative' }} onClick={event => change_fillForm(event, item.id)} />
                                                                                                    : item.display_style === '' ?
                                                                                                        <input type="submit" name="command" value="View" style={{ textAlign: 'center' }} onClick={event => change_fillForm(event, item.id)} />
                                                                                                        : null}

                                                                                            </td>
                                                                                            <td>
                                                                                                {item.module_name}
                                                                                                {/* <Link to="/transaction/fill_forms">
                                            &nbsp;&nbsp;click
                                        </Link> */}
                                                                                            </td>

                                                                                        </tr>
                                                                                    );
                                                                                }) :

                                                                                <tr>
                                                                                    <td colSpan={2}><em>There are no forms to display.</em></td>
                                                                                </tr>
                                                                            }
                                                                            {/* <tr>
                            <td className="text-center" width="1">
                                <input type="submit" name="command" value="View" /></td>
                            <td>Insurance Details</td>
                        </tr> */}
                                                                            <tr className="caption">
                                                                                <td colSpan={2}>&nbsp;</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    {/* </form> */}
                                                                </td>
                                                                {/* Finance Applications */}
                                                                <td width="100%" className="text-right">
                                                                    <form method="post" name="new_application_frm" onSubmit={handleFinanceApplicationSubmit} >
                                                                        <input type="hidden" name="loan_house" id="loan_house_id" value="" />
                                                                        <input type="hidden" name="workflow_id" value={FinanceWorkFlowId} />
                                                                        <input type="hidden" name="instance_id" value={FinanceMasterInstanceId} />
                                                                        <input type="hidden" name="transaction_id" value={FinanceTranscationId} />
                                                                        <input type="hidden" name="application_id" id="application_id" value="" />
                                                                        <input type="hidden" name="form_id" id="form_id" value="" />
                                                                        <input type="hidden" name="group_id" id="group_id" value="" />
                                                                        <input type="hidden" name="company_id" value={FinanceClientId} />
                                                                        <input type="hidden" name="department_id" value={FinanceDepartmentId} />
                                                                        <input type="hidden" name="command" id="command" value="View" />

                                                                        <table className="defaultTbl p-relative" width="100%" cellSpacing={1}>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th colSpan={4} style={{ textAlign: 'center' }}>Finance Applications</th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="caption"></td>
                                                                                    <td className="caption">Submission Date</td>
                                                                                    <td className="caption">Institution</td>
                                                                                    <td className="caption text-center">Status</td>
                                                                                </tr>
                                                                                {/* {console.log(FinanceApplications)} */}
                                                                                {FinanceApplications != null ?
                                                                                    FinanceApplications.map((item, index) => {
                                                                                        return (
                                                                                            <tr key={index}>

                                                                                                <td>
                                                                                                    {/* <input type="button" value="Edit" name="command" onClick={() => FinanceApplicationHiddenData(item.group_id,item.loanhouse_id,item.application_id)} /> */}

                                                                                                    {item.edit_button === 'Edit' ?
                                                                                                        <input type="button" value={item.edit_button} name="command" onClick={() => FinanceApplicationHiddenData(item.group_id, item.loanhouse_id, item.application_id)} />
                                                                                                        : null}


                                                                                {item.view_button === 'View' || unincept_flag == 1 ?
                                                                                    //    <Link to="/transaction/prepare/application_id/regenerated=true">
                                                                                    <input type="button" value="View" name="command" onClick={() => GeneratePrintPdf(item.group_id, item.loanhouse_id, item.application_id)} />
                                                                                    //    </Link>
                                                                                    : null}

                                                                                                </td>

                                                                                                <td>
                                                                                                    
                                                                                                    <span>{item.submission_date}</span><br />
                                                                                                    { HistoryFlag === 'yes' ?
                                                                                                       <span style={{backgroundColor: '#ede4d4', borderRadius: '0.30em', padding: "3px", cursor:"pointer"}} onClick={(e) => handleApplicationHitory1(transID, item.loanhouse_id, item.instuction)}>History</span> 
                                                                                                    : null }

                                                                                                    { HistoryFlag === 'yes' ?
                                                                                                        <span style={{cursor:"pointer", margin: "0px 0px 0px 10px", color:'orange'}} onClick={(e) => handleApplicationHitory1(transID, item.loanhouse_id, item.instuction)}><img height="15"  border="0" src={Search_img} alt=""/></span>   
                                                                                                    : null }                                     
                                                                                                </td>
                                                                                                <td>{item.instuction}
                                                                                                {((item.international_trno > 0 ) && (item.loanhouse_id === 44 || item.loanhouse_id === 51)) ? <img height={30} width={30} src={refresh_img} alt="" onClick={(e) => handleRefresh(item.loanhouse_id,item.application_id)} /> : "" }
                                                                                                </td>
                                                                                                <td>{item.status}
                                                                                                    {    
                                                                                                        (item.sFinanceCodes !== '' && (item.international_trno > 0 ) && (item.loanhouse_id === 44 || item.loanhouse_id === 51)) ?
                                                                                                            <>
                                                                                                            <img height={40} width={40} src={view_img} alt="" onClick={(e) => handleView(item.resStatus,item.sFinanceCodes)} />
                                                                                                            <table border="0px;">
                                                    <tbody>
                                                        <tr><td>{item.sFinanceCodes}</td></tr>
                                                        <tr><td><b>LOAN AMOUNT : </b>{item.sLoanAmount}</td></tr>
                                                        <tr><td><b>LOAN TERM : </b>{item.sLoanTerm}</td></tr>
                                                        <tr><td><b>INTEREST RATE : </b>{item.sLoanInterest}</td></tr>
                                                        <tr><td><b>DEPOSIT AMOUNT : </b>{item.sLoanDeposit}</td></tr>
                                                    </tbody>
                                                                                                            </table>
                                                                                                            </>
                                                                                                            : null
                                                                                                    }
                                                                                                </td>

                                                                                            </tr>
                                                                                        );
                                                                                    }) :

                                                                                    <tr>
                                                                                        <td colSpan={4}><em>THERE ARE NO APPLICATIONS TO LIST YET.</em></td>
                                                                                    </tr>
                                                                                }
                                                                                
                                                                                { unincept_flag != 1 ?
                                                                                <tr className="caption">
                                                                                    <td colSpan={4}>
                                                                                        <select name="loan_house_select" id="loan_house" className="text-uppercase" value={FinanceData} onChange={(e) => setFinanceData(e.target.value)} style={{ width: '180px' }}>
                                                                                            <option value="">Select a Financier</option>

                                                                                            {FinancerAll != null ?
                                                                                                FinancerAll.map((item) => {
                                                                                                    return (
                                                                                                        <option key={item.id} value={item.id}>
                                                                                                            {item.name}
                                                                                                        </option>
                                                                                                    );
                                                                                                })
                                                                                                : null}
                                                                                        </select>
                                                                                        &nbsp;

                                                                                        <input type="submit" name="command" value="New Application" />
                                                                                    </td>
                                                                                </tr>
                                                                                :  FinanceApplications === null ?
                                                                                <tr>
                                                                                    <td colSpan={4}><em>THERE ARE NO APPLICATIONS TO LIST YET.</em></td>
                                                                                </tr> 
                                                                                : null    
                                                                               }

                                                                            </tbody>
                                                                        </table>
                                                                    </form>
                                                                </td>
                                                            </tr>
                                                            {/* Insurance Applications (display none)*/}
                                                            <tr>
                                                                <td width="33%" className="text-right">
                                                                    <form method="post" action="fill_forms.php" id="new_insurance_frm">
                                                                        <input type="hidden" name="insurer" id="insurer_name_id" value="" />
                                                                        <input type="hidden" name="workflow_id" value="1" />
                                                                        <input type="hidden" name="instance_id" value="12961" />
                                                                        <input type="hidden" name="transaction_id" value="12071" />
                                                                        <input type="hidden" name="insurance_application_id" id="insurance_application_id" value="" />
                                                                        <input type="hidden" name="form_id" id="insurance_form_id" value="" />
                                                                        <input type="hidden" name="group_id" id="insurance_group_id" value="" />
                                                                        <input type="hidden" name="company_id" value="12243" />
                                                                        <input type="hidden" name="department_id" value="80" />
                                                                        <input type="hidden" name="command" id="command_insurer" value="View" />

                                                                        <table className="defaultTbl d-none" width="100%" cellSpacing={1}>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th colSpan={4}>Insurance Applications</th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="caption"></td>
                                                                                    <td className="caption">Submission Date</td>
                                                                                    <td className="caption">Insurer</td>
                                                                                    <td className="caption text-center">Status</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan={4}><em>There are no applications to list yet.</em></td>
                                                                                </tr>
                                                                                <tr className="caption">
                                                                                    <td colSpan={4}>
                                                                                        <select name="insurer_select" id="insurer" className="text-uppercase">
                                                                                            <option value="0">Select an Insurer</option>
                                                                                        </select>
                                                                                        &nbsp;<input name="command" value="Apply for Insurance" type="button" />
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </form>
                                                                </td>
                                                            </tr>
                                                            
                                                            {/* Printable Documents */}
                                                            <tr>
                                                                <td colSpan={2}>
                                                <form method="post" onSubmit={handlePrintableDocumentsSubmit} >
                                                    <table width="100%" className="defaultTbl" cellSpacing={1}>
                                                        <tbody>
                                                            <tr>
                                                                <th colSpan={4} className="text-center">Printable Documents</th>
                                                            </tr>

                                                            {PrintableDocummentDetails.first_row == 'yes' ?
                                                                <tr>
                                                                    <td>
                                                                        
                                                                        {/* <a target="_blank" href={TransactionSchedule}  > 
                                                                            <input type="button" name="command" value="View" />
                                                                        </a> */}
                                                                        <input type="button" name="command" value="View" onClick={(e) => setPrint2Flag(true)} />
                                                                    </td>
                                                                    <td style={{ textAlign: 'left' }}>
                                                                        Transaction Schedule   
                                                                    </td>  
                                                                    <td colSpan={2}>&nbsp;</td>
                                                                </tr>
                                                                :
                                                                <tr>
                                                                    <td colSpan={4}></td>
                                                                </tr>
                                                            }
                                                            {/* ------------------------------------------------ */}
                                                            {PrintableDocummentDetails.second_row == 'yes' ?
                                                                <tr>
                                                                    <td>
                                                                        
                                                                        {/* <a target="_blank" href={InsuranceSchedule}  > 
                                                                            <input type="button" name="command" value="View" />
                                                                        </a> */}
                                                                        
                                                                        <input type="button" name="command" value="View" onClick={(e) => setPrint1Flag(true)} />
                                                                    </td>
                                                                    <td style={{ textAlign: 'left' }}>
                                                                        Insurance Schedule
                                                                    </td>

                                                                    <td colSpan={2}>&nbsp;</td>
                                                                </tr>
                                                                : null}
                                                            {/* --------------------------------------------------- */}
                                                            {PrintableDocummentDetails.hollard_flag == 'yes' ?
                                                                <tr>
                                                                    <td>
                                                                        <Link target="_blank" to={{ pathname: PrintableDocummentDetails.motar_insurance_policy }} download >
                                                                            <input type="button" name="command" value="View" />
                                                                        </Link>
                                                                    </td>
                                                                    <td style={{ textAlign: 'left' }}>
                                                                        Hollard Motor Insurance Policy
                                                                    </td>
                                                                    <td colSpan={2}>&nbsp;</td>
                                                                </tr>
                                                                : null}

                                                        </tbody>
                                                    </table>
                                                </form>
                                                                    
                                                                    {/* Transaction Contacts */}
                                                                    <form method="post">
                                                                        <input type="hidden" name="module_segment" value="contacts" />
                                                                        <input type="hidden" name="contact_id" id="contact_id" value="" />
                                                                        <table className="defaultTbl" width="100%" cellSpacing={1}>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th colSpan={6} className="text-center">Transaction Contacts</th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="caption"></td>
                                                                                    <td className="caption">Relationship</td>
                                                                                    <td nowrap="" className="caption">First Name</td>
                                                                                    <td className="caption">Surname</td>
                                                                                    <td className="caption">ID #</td>
                                                                                    <td className="caption"></td>
                                                                                </tr>

                                                                                {TranscationContact.length > 0 ?
                                                                                    TranscationContact.map((item, index) => {
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                { unincept_flag != 1 ?
                                                                                                <td style={{ textAlign: 'center' }}>

                                                                                                    <Link to={"/transaction/add_person/" + client_id + "/person_id/" + item.id}>
                                                                                                        <input type="button" value={item.edit_button} name="command" />
                                                                                                    </Link>
                                                                                                    &nbsp;

                                                                                                    {unincept_flag == 0 ?
                                                                                                        <Link to={`#`} onClick={() => { if (window.confirm('Are you sure you want to copy this contact? ')) { CopyClientTranscation(item.id) } }}>
                                                                                                            <input type="button" name="command" value={item.copy_button} />
                                                                                                        </Link>
                                                                                                        : null} &nbsp;

                                                                                                    {TranscationContactOtherDetails.client_type_id == 'yes' ?
                                                                                                        <Link to={`#`} onClick={() => { if (window.confirm("Are you sure that you want to make this contact the client? This contact's details will be swapped with the client's throughout the system.")) { ClientClientTranscation(item.id) } }}>
                                                                                                            <input type="button" value={item.client_button} name="command" />
                                                                                                        </Link>
                                                                                                        : null}
                                                                                                </td>
                                                                                                : <td style={{ textAlign: 'center' }}>
                                                                                                    <Link to={"/transaction/add_person/" + client_id + "/person_id/" + item.id}>
                                                                                                        <input type="button" value={item.edit_button} name="command" />
                                                                                                    </Link>

                                                                                                </td> }

                                                                                                <td>{item.relationship}</td>
                                                                                                <td>{item.firstname}</td>
                                                                                                <td>{item.surname}</td>
                                                                                                <td>{item.id_number}</td>
                                                                                                <td>

                                                                                                    {item.delete_button === 'Delete' ?
                                                                                                        <Link to={`#`} onClick={() => { if (window.confirm("Are you sure you want to delete this contact? This will also remove this contact's information in any application form that it was used.")) { DeleteClientTranscation(item.id) } }}>
                                                                                                            <input type="button" value="Delete" name="command" onChange={(e) => { setContactDelete(e.target.value) }} />
                                                                                                        </Link>
                                                                                                        : null}

                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    }) :

                                                                                    <tr>
                                                                                        <td colSpan={6}><em>There are no contacts for this client.</em></td>
                                                                                    </tr>
                                                                                }

                                                                                <tr className="caption">
                                                                                    <td colSpan={6} className="text-center">
                                                                                        <Link to={"/transaction/add_person"}>
                                                                                            {TranscationContactButton === 'Add Contact' ?
                                                                                                <input type="submit" value="Add Contact" />
                                                                                                : null}
                                                                                        </Link>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className="caption">
                                                                                    <td colSpan={6}>&nbsp;</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </form>
                                                                    {/* Regular Drivers */}
                                                                    <form method="post" >
                                                                        <input type="hidden" name="form_id" id="cform_id" value={form_id} onChange={(e) => { setform_id(e.target.value) }} />
                                                                        <input type="hidden" name="instance_id" value={instance_id} onChange={(e) => { setInstance_id(e.target.value) }} />
                                                                        <input type="hidden" name="transaction_id" value={trans_id} onChange={(e) => { setTrans_id(e.target.value) }} />
                                                                        <input type="hidden" name="company_id" value={company_id} onChange={(e) => { setCompany_id(e.target.value) }} />
                                                                        <input type="hidden" name="department_id" value={department_id} onChange={(e) => { setDepartment_id(e.target.value) }} />
                                                                        <input type="hidden" name="group_id" id="cgroup_id" value={group_id} onChange={(e) => { setGroup_id(e.target.value) }} />
                                                                        <input type="hidden" name="compulsory_forms" value={compulsory_forms} onChange={(e) => { setCompulsory_forms(e.target.value) }} />
                                                                        <input type="hidden" name="delete_instance" id="delete_instance" value={delete_instance} onChange={(e) => { setDelete_instance(e.target.value) }} />
                                                                        <input type="hidden" name="from_page" value="transaction.php" />
                                                                        <table className="defaultTbl" width="100%" cellSpacing={1}>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th colSpan={6} className="text-center">Regular Drivers</th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="caption"></td>
                                                                                    <td nowrap="" className="caption">First Name</td>
                                                                                    <td className="caption">Surname</td>
                                                                                    <td className="caption">ID #</td>
                                                                                    <td className="caption"></td>
                                                                                </tr>

                                                                                {regularDrivers.length > 0 ?
                                                                                    regularDrivers.map((item, index) => {
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                { unincept_flag != 1 ?
                                                                                                    <td style={{ textAlign: 'center' }}>
                                                                                                        {item.first_regular_driver == 1 ?
                                                                                                            <Link to="/transaction/client_company_add_edit/0">
                                                                                                                <input type="button" value={item.action_button} name="command" />
                                                                                                            </Link>
                                                                                                            :
                                                                                                            <input type="button" value={item.action_button} name="command" onClick={event => change_fillForm(event, 73)} />
                                                                                                        }
                                                                                                    </td>
                                                                                                : 
                                                                                                    <td style={{ textAlign: 'center' }}>
                                                                                                        {item.first_regular_driver == 1 ?
                                                                                                            <Link to={"/transaction/client_company_view/"+client_id}>
                                                                                                                <input type="button" value="View" name="command" />
                                                                                                            </Link>
                                                                                                            :
                                                                                                            <input type="button" value={item.action_button} name="command" onClick={event => change_fillForm(event, 73)} />
                                                                                                        }
                                                                                                    </td>
                                                                                                }

                                                                                                <td>{item.firstname}</td>
                                                                                                <td>{item.surname}</td>
                                                                                                <td>{item.contact_number}</td>
                                                                                                <td style={{ textAlign: 'center' }}>

                                                                                                    {item.delete_button === 'Delete' ?
                                                                                                        <input type="button" value="Delete" name="command" onClick={() => { if (window.confirm("Are you sure you want to delete this contact? This will also remove this contact's information in any application form that it was used.")) { handleDriverRowDeleteRow(item.id); } }} />
                                                                                                        : null}

                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    }) :

                                                                                    <tr>
                                                                                        <td colSpan={6}><em>There are no drivers for this client.</em></td>
                                                                                    </tr>
                                                                                }

                                                                                <tr className="caption">
                                                                                    <td colSpan={6} className="text-center">
                                                                                        {add_button === 'Add Driver' ?
                                                                                            <input type="button" value="Add Driver" onClick={event => change_fillForm(event, 73)} />
                                                                                            : null}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </form>

                                                                </td>
                                                            </tr>
                                                            {/* Products */}
                                                            <tr>
                                                                <td width="100%" colSpan={3}>
                                                                    {/* <form method="post" action="../products/add_to_transaction.php?module=Transactions"> */}
                                                                    <input type="hidden" value="" name="id" id="id_0" />
                                                                    <input type="hidden" value="" name="tp_id" id="p_id_0" />
                                                                    <input type="hidden" value="" name="products_product_type" id="pp_id" />
                                                                    <table className="defaultTbl" width="100%" cellSpacing={1}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th colSpan={7} style={{ textAlign: 'center' }}>Products</th>
                                                                            </tr>
                                                                            <tr className="caption">

                                                                                <td width="1"></td>
                                                                                <td>Product</td>
                                                                                <td>Option</td>
                                                                                <td>Category</td>
                                                                                <td>Documentation</td>
                                                                                <td className="text-right">Amount</td>
                                                                                <td width="1">&nbsp;</td>
                                                                            </tr>

                                                                            {productDetails.length > 0 ?
                                                                                productDetails.map((item, index) => {
                                                                                    return (
                                                                                        <tr key={index}>

                                                                                            {
                                                                                                item.category === 'VEHICLE' && unincept_flag != 1 ?

                                                                                                    <td>
                                                                                                        {item.action_column === 'no' ?
                                                                                                            null
                                                                                                            :
                                                                                                            <Link to={"/products/add_to_transaction/command=Edit/module=Transactions/" + tp_id + "/" + id}>
                                                                                                                <input type="submit" value={item.action_button} name="command" />
                                                                                                            </Link>
                                                                                                        }
                                                                                                    </td>
                                                                                                    : item.category === 'GENERIC ACCESSORY' && unincept_flag != 1 ?
                                                                                                    <td>
                                                                                                        <Link to={"/products/add_accessories_to_transaction/module=Transactions/" + parent_id}>
                                                                                                            <input type="submit" value={item.action_button} name="command" />
                                                                                                        </Link>
                                                                                                    </td>
                                                                                                    : item.iCategory === 1 && unincept_flag != 1 ?
                                                                                                    <td>
                                                                                                        <Link to={"/transaction/insurance_products/module=Transactions/category=1/" + parent_id}>
                                                                                                            <input type="submit" value={item.action_button} name="command" />
                                                                                                        </Link>
                                                                                                    </td>
                                                                                                    : item.iCategory === 2 && unincept_flag != 1 ?
                                                                                                    <td>
                                                                                                        <Link to={"/transaction/products/module=Transactions/category=2/" + parent_id}>
                                                                                                            <input type="submit" value={item.action_button} name="command" />
                                                                                                        </Link>
                                                                                                    </td>
                                                                                                    : unincept_flag != 1 ?
                                                                                                    <td>
                                                                                                        <input type="submit" value={item.action_button} name="command" />
                                                                                                    </td>
                                                                                                    : 
                                                                                                    <td>&nbsp;</td>

                                                                                            }

                                                                                            <td>{item.product_name}</td>
                                                                                            <td>{item.option}</td>
                                                                                            <td>{item.category}</td>
                                                                                            <td>
                                                                                                <a href={item.wording_path} target="_blank"> {item.wording}</a>

                                                                                            </td>
                                                                                            <td style={{ whiteSpace: "nowrap" }} className="text-right">{item.product_amount}</td>
                                                                                            <td>

                                                                                                {item.remove_dis_button === 'Remove' ?
                                                                                                    <Link to={`#`} onClick={() => { if (window.confirm('Are you sure you want to remove ' + item.option + ' and all selected products from the transaction ?')) { RemoveVehicle(item.tp_id) } }}>
                                                                                                        <input type="submit" value="Remove" name="command" />
                                                                                                    </Link>

                                                                                                    : item.remove_dis_button === 'Discount' ?
                                                                                                        <Link to="/transaction/comp_insurance_discount">
                                                                                                            <input type="submit" value="Discount" name="command" />
                                                                                                        </Link>
                                                                                                        : null}

                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                }) :

                                                                                <tr>
                                                                                    <td colSpan={7}><em>There are no vehicles in this transaction yet.</em></td>
                                                                                </tr>
                                                                            }

                                                                            <tr className="caption">
                                                                                { unincept_flag != 1 ?
                                                                                <td colSpan={7} className="text-center">
                                                                                    {
                                                                                        VehicleProductVisible == 1 ?
                                                                                            <Link to={"/products/search/product_type=vehicles/command=search/module=Transactions/products_product_type/0"}>
                                                                                                <input type="button" value="Vehicles" />
                                                                                            </Link>
                                                                                            :
                                                                                            <Link to={"/products/add_to_transaction/command=Edit/module=Transactions/" + id + "/" + tp_id}>
                                                                                                <input type="button" value="Vehicles" />
                                                                                            </Link>
                                                                                    }&nbsp;

                                                                                    {parent_id != 0 ?
                                                                                        <Link to={"/products/add_accessories_to_transaction/module=Transactions/" + parent_id}>
                                                                                            <input type="button" value="Accessories" />
                                                                                        </Link>
                                                                                        : null} &nbsp;

                                                                                    {parent_id != 0 ?
                                                                                        <Link to={"/transaction/insurance_products/module=Transactions/category=1/" + parent_id}>
                                                                                            <input type="button" value="Insurance Products" />
                                                                                        </Link>
                                                                                        : null} &nbsp;

                                                                                    {parent_id != 0 ?
                                                                                        <Link to={"/transaction/products/module=Transactions/category=2/" + parent_id}>
                                                                                            <input type="button" value="VAP Products" />
                                                                                        </Link>
                                                                                        : null}

                                                                                </td>
                                                                                : 
                                                                                    <td colSpan={7} className="text-center">&nbsp;</td>
                                                                                }
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    {/* </form> */}
                                                                </td>
                                                            </tr>


                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </fieldset>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table width="100%" height="14" cellPadding={0} cellSpacing={0} style={{ position: 'fixed', bottom: '0px', left: '0px' }}>
                    <tbody>
                        <tr>
                            <td width="100%" height="14" className="footer-img">
                                <div dangerouslySetInnerHTML={{ __html: FooterData }} />
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </>
        : null}

        </>
    );

}

export default Transaction
