import React, { useState } from "react";
import VechileAccessories_menu from "./VechileAccessories_menu"
import axios from "../../axios";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../Components/Loader"

function Import_mm_codes() {
    document.title = "Products | Seriti - Vehicle & Accessories";
    const { template_id } = useParams();
    const [file, setFile] = useState('');
    const [email, setEmail] = useState('');
    const [loader, setLoader] = useState(false);

    //---------------------handle validation------------------------//
    const handleValidation = () => {
        var flag = true;
        // alert((file.size))
        if (file.type !== "text/csv") {
            alert('Please import a csv file.');
            flag = false;
        }
        if (file.size > (20e+7)) {
            alert('Please upload a file smaller than 20 MB.');
            flag = false;
        }
        // const fileSize = file.size / (20**1024); // in MiB
        // alert(fileSize)
        // if (fileSize < 20) {
        //     alert('File size exceeds 2 MiB');
        // } 

        // var _size = file.size;
        //     var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
        //     i=0;
        //     while(_size>900){
        //         _size/=1024;
        //         i++;
        //     }
        //     var exactSize = (Math.round(_size*100)/100)+' '+fSExt[i];
        //         console.log('FILE SIZE = ',exactSize);
        //     alert(exactSize);
        // flag = false;

        return flag;
    };

    //------------------------submit file--------------------------//
    const handleImport = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        // console.log(flag)
        if (flag) {
            setLoader(true);
            var bodyFormData = new FormData();
            bodyFormData.append("import_file", file);
            bodyFormData.append("template_id", template_id);
            bodyFormData.append("notify_email", email);

            axios
                .post("/import_mm_codes", bodyFormData)
                .then((response) => {
                    setLoader(false);
                    if (response.data.status === 200) {
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                })
                .catch((response) => {
                    setLoader(false);
                    if (response.response.data.status === 400)
                    toast.error(response.response.data.data, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    };


    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <VechileAccessories_menu />
            {loader ? <Loader /> : null}
            <form method="post" onSubmit={handleImport} encType="multipart/form-data" >
                <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                    <tbody>
                        <tr valign="top" className="bg-white">
                            <td></td>
                            <td width="100%" className="bg-white">
                                <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                                <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center">

                                                <table className="defaultTbl" width="500" cellSpacing="1">
                                                    <tbody>
                                                        <tr><th colSpan={2} className="text-center">M&M Codes Import</th></tr>
                                                        <tr className="caption"><td width="90%">Template Attributes</td><td style={{ textAling: "left" }}>Column</td></tr>

                                                        <tr>
                                                            <td>Import File<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="file" name="import_file" onChange={(e) => setFile(e.target.files[0])} required /></td>
                                                        </tr>

                                                        <tr>
                                                            <td>Send a notification of import to</td>
                                                            <td><input type="email" name="notify_email" nouppercase="1" value={email} onChange={(e) => setEmail(e.target.value)} /></td>
                                                        </tr>
                                                        <tr className="caption">
                                                            <td colSpan="2" style={{ textAling: "center" }} className="text-center">
                                                                <input type="submit" name="command" value="Import" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <p></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    )
}

export default Import_mm_codes