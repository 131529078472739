import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link, useNavigate, useLocation } from 'react-router-dom';

function Affiliates_add() {
    const navigate = useNavigate();
    document.title = "Administration | Seriti - Group Admin";
    const location = useLocation()
    const { affiliate_id } = location.state
    // console.log(affiliate_id);
    const [collectionName, setCollectionName] = useState('');

    const [tableHead, setTableHead] = useState('ADD COLLECTION');

    //---------------------------fetch data of edit case-----------------------------//
    useEffect(() => {
        if (affiliate_id !== '') {
            const payload = {
                affiliate_id: affiliate_id
            };
            axios
                .post("/admin/group_admin/affiliate_edit_get_data", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data.data[0]['name'])
                        setCollectionName(response.data.data[0]['name']);
                        setTableHead('Edit COLLECTION')
                    }
                })
                .catch((response) => {
                    alert(response.response.data.message);
                });
        }
    }, [affiliate_id]);

    //-------------------------handle validation------------------------------------//
    const handleValidation = () => {
        var flag = true;
        if (collectionName === '') {
            alert('Please enter colletion name!');
            flag = false;
        }
        return flag;
    };

    const handleEdit = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        if (flag) {
            const payload = {
                collectionName: collectionName,
                affiliate: affiliate_id
            };
            axios
                .post("/admin/affiliates/add_update", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        navigate('/admin/group_admin/affiliates')
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    alert(response.response.data.message);
                });
        }
    };
    return (
        <>
            <Admin_menu />
            <form method="post" action="" onSubmit={handleEdit} encType="multipart/form-data" >
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">

                                <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center"><p></p>
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="400">
                                                    <tbody>

                                                        <tr><th colSpan={2} style={{ textAlign: 'center' }}>{tableHead} </th></tr>
                                                        <tr><td className="caption">Name<span style={{ color : "red"}}> *</span></td>
                                                            <td>
                                                                <input validate="text" type="text" className="text-uppercase" name="name" value={collectionName} onChange={(e) => { setCollectionName(e.target.value) }} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" colSpan={2} style={{ textAlign: 'center' }}>
                                                                <input type="submit" name="command" value="Save" />
                                                                <Link to={"/admin/group_admin/affiliates"} className="btn btn-result-open">
                                                                    <input type="submit" name="command" value="Back" /></Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr><tr>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );
}

export default Affiliates_add