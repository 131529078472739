import React, { useEffect, useState } from 'react'
import Transaction_menu from './Transaction_menu';
import axios from "../axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../Components/Loader"
import { NumericFormat } from 'react-number-format';


const Transaction_document = () => {
    const TransactionId = localStorage.getItem("transaction_id") ? localStorage.getItem("transaction_id") : ""
    const UserId = localStorage.getItem("UserId") ? localStorage.getItem("UserId") : ""
    const SystemType = localStorage.getItem("system_type") ? localStorage.getItem("system_type") : "";
    const DivisionId = localStorage.getItem("division_id") ? localStorage.getItem("division_id") : "";

    const [ServiceCall, setServiceCall] = useState(true);
    const [Currency, setCurrency] = useState("")

    const [Sighted, setSighted] = useState("")
    const [SalePerson, setSalePerson] = useState("")
    const [BuisnessMarketer, setBuisnessMarketer] = useState("")
    const [InvoiceDate, setInvoiceDate] = useState("")
    const [PaidDate, setPaidDate] = useState("")
    const [DocNumber, setDocNumber] = useState("")
    const [DeliveryDate, setDeliveryDate] = useState("")
    const [StockNumber, setStockNumber] = useState("")
    const [CashTransaction, setCashTransaction] = useState("")
    const [FinanceCompany, setFinanceCompany] = useState("")
    const [FinanceCompanyArr, setFinanceCompanyArr] = useState([])
    const [FinanceSceme, setFinanceSceme] = useState("")
    const [FinanceStatus, setFinanceStatus] = useState("")
    const [FinanceStatusArr, setFinanceStatusArr] = useState([])
    const [SaleStatus, setSaleStatus] = useState("")
    const [SaleStatusArr, setSaleStatusArr] = useState([])
    const [InterestRate, setInterestRate] = useState("")
    const [InterestRateType, setInterestRateType] = useState("")
    const [OtherFee, setOtherFee] = useState("0.00")
    const [OtherCost, setOtherCost] = useState("0.00")
    const [DicAmount, setDicAmount] = useState("0.00")

    //DATA STATE FOR VEHICLE DETAILS
    const [VehicleCode, setVehicleCode] = useState("")
    const [VehicleMake, setVehicleMake] = useState("")
    const [Model, setModel] = useState("")
    const [MakeDetailsArr, setMakeDetailsArr] = useState([])
    const [RetailValue, setRetailValue] = useState("")
    const [Discount, setDiscount] = useState("")
    const [SellingPrice, setSellingPrice] = useState("")
    const [Cost, setCost] = useState("")
    const [Profit, setProfit] = useState("")

    // PRODUCT SUMMARY STATES
    const [ProductSummary, setProductSummary] = useState([])
    const [InvioceDateFlag, setInvioceDateFlag] = useState(1)
    const [InviocePaidDateFlag, setInviocePaidDateFlag] = useState(1)
    const [InvioceDeliveryDateFlag, setInvioceDeliveryDateFlag] = useState(1)
    const [VehicleAccessoriesArr, setVehicleAccessoriesArr] = useState([])
   
    const [loader, setLoader] = useState(false);
    const [HeaderFlag, setHeaderFlag] = useState(1);
    //console.log(VehicleAccessoriesArr);
    useEffect(() => {
        document.title = 'Transactions | Seriti BW';
    }, []);

    useEffect(() => {
        if (ServiceCall && TransactionId !== "") {
            setLoader(true);
            axios
                .get("transaction/get_doc/" + TransactionId)
                .then((response) => {
                  //console.log(response.data.data);
                    setVehicleAccessoriesArr(response.data.data.vehicle_accessories)
                    setCurrency(response.data.data.currency)
                    setSighted(response.data.data.sighted)
                    setSalePerson(response.data.data.sales_person)
                    setBuisnessMarketer(response.data.data.buisness_marketer)
                    // console.log(response.data.data);
                    if (response.data.data.invoice_date !== "") {
                        setInvoiceDate(response.data.data.invoice_date)
                        setInvioceDateFlag(1)
                    }
                    if (response.data.data.invoice_paid_date !== "") {
                        setPaidDate(response.data.data.invoice_paid_date)
                        setInviocePaidDateFlag(1)
                    }
                    if (response.data.data.vehicle_delivery_date !== "") {
                        setDeliveryDate(response.data.data.vehicle_delivery_date)
                        setInvioceDeliveryDateFlag(1)
                    }
                    setDocNumber(response.data.data.doc_invoice_num)
                    setStockNumber(response.data.data.stock_number)
                    setCashTransaction(response.data.data.cash)
                    setFinanceCompany(response.data.data.finance_comapany)
                    setFinanceCompanyArr(response.data.data.finance_comapany_arr)
                    setFinanceSceme(response.data.data.finance_scheme)
                    setFinanceStatus(response.data.data.finance_status)
                    setFinanceStatusArr(response.data.data.finance_status_arr)
                    setSaleStatus(response.data.data.sales_status)
                    setSaleStatusArr(response.data.data.sales_status_arr);
                    setInterestRate(response.data.data.interest_rate)
                    setInterestRateType(response.data.data.interest_rate_type)
                    // setOtherFee((response.data.data.other_fee !== null) ? response.data.data.other_fee : "0.00")
                    setOtherFee((response.data.data.other_fee !== null) ? parseFloat(response.data.data.other_fee).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "0.00")
                    // setOtherCost((response.data.data.other_cost !== null) ? response.data.data.other_cost : "0.00")
                    setOtherCost((response.data.data.other_cost !== null) ? parseFloat(response.data.data.other_cost).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "0.00")
                    // setDicAmount((response.data.data.dic_amount !== null) ? response.data.data.dic_amount : "0.00")
                    setDicAmount((response.data.data.dic_amount !== null) ? parseFloat(response.data.data.dic_amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "0.00")

                    setVehicleCode(response.data.data.vehicle_code)
                    setVehicleMake(response.data.data.vehicle_make)
                    setModel(response.data.data.vehicle_model)
                    setMakeDetailsArr(response.data.data.make_details)
                    setRetailValue(response.data.data.retail_price)
                    setSellingPrice(response.data.data.selling_price)
                    setDiscount(response.data.data.discount)
                    setCost(response.data.data.cost_price)
                    setProfit(response.data.data.vehicle_profit)
                    setProductSummary(response.data.data.product_summary)
                    setInvioceDateFlag(1)
                    setServiceCall(false);
                    setLoader(false);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch(() => {
                    console.log('error');
                    setLoader(false);
                });
        }
    }, [ServiceCall, TransactionId])

    const handleValidation = () => {
        var flag = true;
        return flag;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const flag = handleValidation();

        if (flag) {
            if (TransactionId !== "" && UserId !== "") {
                const payload = {
                    "transaction_id": TransactionId,
                    "user_id": UserId,
                    "sales_status": SaleStatus,
                    "finance_status": FinanceStatus,
                    "finance_company": FinanceCompany,
                    "invoice_date": InvoiceDate !== '' ? moment(InvoiceDate).format("YYYY-MM-DD") : 0,
                    "invoice_pay_date": PaidDate !== '' ? moment(PaidDate).format("YYYY-MM-DD") : 0,
                    "vehicle_delivery_date": DeliveryDate !== '' ? moment(DeliveryDate).format("YYYY-MM-DD") : 0,
                    "other_fee": OtherFee,
                    "other_cost": OtherCost,
                    "dic_amount": DicAmount
                }
                axios
                    .post("transaction/save_doc_data", payload)
                    .then((response) => {
                      //  console.log(response);
                        if (response.data.status === 200) {
                            alert("Please ensure that you have uploaded the FINANCE APPLICATION, DELIVERY RECIEPT and all other relevant documents in the Document Section under the SUPPORTING INFORMATION SECTION.");
                            toast.success(response.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            setServiceCall(true);
                            setHeaderFlag(2)
                        }
                    })
                    .catch((response) => {
                        if (response.response.data.status === 400)
                        toast.error(response.response.data.data, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    });
            }
        }
    }

    const HandleCostingSchedule = () => {
        if (SystemType !== "" && TransactionId !== "" && UserId !== "" && DivisionId !== "") {
            setLoader(true);
            const payload = {
                "system_type": SystemType,
                "transaction_id": TransactionId,
                "user_id": UserId,
                "division_id": DivisionId
            }

            axios
                .post("costing_schedule_pdf", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        setLoader(false);
                        alert("Please ensure that you have uploaded the FINANCE APPLICATION, DELIVERY RECIEPT and all other relevant documents in the Document Section under the SUPPORTING INFORMATION SECTION.");
                        var link = document.createElement('a');
                            document.body.appendChild(link);
                            link.target = "_blank";
                            link.href = response.data.data;
                            link.download = '';
                            link.click();
                            toast.success(response.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                    }
                    // setServiceCall(true);
                })
                .catch((response) => {
                    if (response.response.data.status === 400)
                    toast.error(response.response.data.data, {
                        position: toast.POSITION.TOP_CENTER
                    });

                });
        }
    }

    const RemoveZeroVal = (e) => {
        // console.log(e);
        if(e === 1 && OtherFee === '0.00'){ setOtherFee("") }
        if(e === 2 && OtherCost === '0.00'){ setOtherCost("") }
        if(e === 3 && DicAmount === '0.00'){ setDicAmount("") }
    }

    const ShowZero = (e) => {
        // alert('dd');
        if(e === 1 && OtherFee === ''){ setOtherFee("0.00") } 
        if(e === 2 && OtherCost === ''){ setOtherCost("0.00") }
        if(e === 3 && DicAmount === ''){ setDicAmount("0.00") }
    }

   // let panFlag = 1;

    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Transaction_menu panel_flag={HeaderFlag}/>
            {loader ? <Loader /> : null}
            <form onSubmit={handleSubmit}>
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td width="100%">
                                <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center"><p></p>
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="800">
                                                    <tbody>

                                                        <tr className="caption">
                                                            <th colSpan={5} style={{ textAlign: 'center' }}>DOC DATA
                                                            </th>
                                                        </tr>

                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                IS DEAL SIGHTED
                                                            </td>

                                                            <td style={{ textAlign: "left" }} colSpan={3}>
                                                                {Sighted}
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                SALES PERSON	                                                            </td>

                                                            <td style={{ textAlign: "left" }} colSpan={3} >
                                                                {SalePerson}
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                BUSINESS MANAGER/MARKETER	                                                            </td>

                                                            <td style={{ textAlign: "left" }} colSpan={3}>
                                                                {BuisnessMarketer}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                DOC INVOICE DATE	                                                            </td>

                                                            <td style={{ textAlign: "left", width: "25%" }} colSpan={3}>
                                                                {InvioceDateFlag === 1 ?
                                                                    <DatePicker id="invoice_date" name="invoice_date" value={InvoiceDate} onChange={(date) => { setInvoiceDate(date); setInvioceDateFlag(0) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                    :
                                                                    <DatePicker id="invoice_date" name="invoice_date" selected={InvoiceDate} onChange={(date) => setInvoiceDate(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                }

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                DOC INVOICE PAID DATE
                                                            </td>

                                                            <td style={{ textAlign: "left", width: "25%" }} colSpan={3}>

                                                                {InviocePaidDateFlag === 1 ?
                                                                    <DatePicker id="paid_date" name="paid_date" value={PaidDate} onChange={(date) => { setPaidDate(date); setInviocePaidDateFlag(0) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                    :
                                                                    <DatePicker id="paid_date" name="paid_date" selected={PaidDate} onChange={(date) => setPaidDate(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                VEHICLE DELIVERY DATE                                                            </td>

                                                            <td style={{ textAlign: "left", width: "25%" }} colSpan={3}>

                                                                {InvioceDeliveryDateFlag === 1 ?
                                                                    <DatePicker id="delivery_date" name="delivery_date" value={DeliveryDate} onChange={(date) => { setDeliveryDate(date); setInvioceDeliveryDateFlag(0) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                    :
                                                                    <DatePicker id="delivery_date" name="delivery_date" selected={DeliveryDate} onChange={(date) => setDeliveryDate(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                }
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                DOC INVOICE NUMBER	                                                            </td>

                                                            <td style={{ textAlign: "left" }} colSpan={3}>
                                                                {DocNumber !== null ? DocNumber : ""}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                STOCK NUMBER	                                                            </td>

                                                            <td style={{ textAlign: "left" }} colSpan={3}>
                                                                {StockNumber !== null ? StockNumber : ""}
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                CASH TRANSACTION
                                                            </td>

                                                            <td style={{ textAlign: "left" }} colSpan={3}>
                                                                {CashTransaction}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                FINANCE COMPANY
                                                            </td>

                                                            <td style={{ textAlign: "left" }} colSpan={3}>
                                                                <select name="finance_company" className="text-uppercase" value={FinanceCompany} onChange={(e) => { setFinanceCompany(e.target.value) }} style={{ width : "200px"}}>
                                                                    <option value="">Select An Option</option>
                                                                    {FinanceCompanyArr.map((item) => {
                                                                        return (
                                                                            <option value={item.value} key={item.value}>
                                                                                {item.text}
                                                                            </option>);
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                FINANCE DEAL SCHEME
                                                            </td>

                                                            <td style={{ textAlign: "left" }} colSpan={3}>
                                                                {FinanceSceme}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                TRANSACTION FINANCE STATUS	                                                            </td>

                                                            <td style={{ textAlign: "left" }} colSpan={3}>
                                                                <select name="finance_status" className="text-uppercase" value={FinanceStatus !== null ? FinanceStatus : ""} onChange={(e) => { setFinanceStatus(e.target.value) }} >
                                                                    <option value="">Select An Option</option>
                                                                    {FinanceStatusArr.map((item, i) => {
                                                                        return (
                                                                            <option value={item.id} key={i}>
                                                                                {item.name}
                                                                            </option>);
                                                                    })}
                                                                </select>
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                TRANSACTION SALES STATUS
                                                            </td>

                                                            <td style={{ textAlign: "left" }} colSpan={3}>
                                                                <select name="sale_status" className="text-uppercase" value={SaleStatus !== null ? SaleStatus : ""} onChange={(e) => { setSaleStatus(e.target.value) }} style={{ width : "200px"}}>

                                                                    {SaleStatusArr.map((item, i) => {
                                                                        return (
                                                                            <option value={i} key={i}>
                                                                                {item}
                                                                            </option>);
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                INTEREST RATE
                                                            </td>

                                                            <td style={{ textAlign: "left" }} colSpan={3}>
                                                                {InterestRate}%
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                INTEREST RATE TYPE	                                                            </td>

                                                            <td style={{ textAlign: "left" }} colSpan={3}>
                                                                {InterestRateType}                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                OTHER FEE	                                                            </td>

                                                            <td style={{ textAlign: "right" }} colSpan={3}>
                                                                {Currency}&nbsp;
                                                                {/* <input className="text_currency" size="15" style={{ textAlign: "right", textTransform: "uppercase" }} type="text" name="dic_amount" value={OtherFee} onChange={(e) => { setOtherFee(e.target.value) }}  onClick={(e) => handleAmount(OtherFee, setOtherFee)} onBlur={(e) => handleBlurAmount(OtherFee, setOtherFee)} /> */}

                                                                <NumericFormat type="text" name="dic_amount" style={{textAlign: 'right', width: '12.4em'}}  value={OtherFee} onChange={(e) => { setOtherFee(e.target.value) }}  onClick={() => { RemoveZeroVal(1);}} onBlur={() => { ShowZero(1); }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" /> 

                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                 OTHER COST	                                                      
                                                                 
                                                             </td>

                                                            <td style={{ textAlign: "right" }} colSpan={3}>
                                                                {Currency}&nbsp;
                                                              
                                                                <NumericFormat type="text" name="other_cost" style={{textAlign: 'right', width: '12.4em'}}  value={OtherCost} onChange={(e) => { setOtherCost(e.target.value) }}  onClick={() => { RemoveZeroVal(2);}} onBlur={() => { ShowZero(2); }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" /> 
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                DIC AMOUNT
                                                            </td>

                                                            <td style={{ textAlign: "right" }} colSpan={3}>
                                                                {Currency}&nbsp;
                                                               
                                                                <NumericFormat type="text" style={{textAlign: 'right', width: '12.4em'}} name="dic_amount" value={DicAmount} onChange={(e) => { setDicAmount(e.target.value) }}  onClick={() => { RemoveZeroVal(3);}} onBlur={() => { ShowZero(3); }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" /> 
                                                            </td>

                                                        </tr>

                                                        <tr className="caption">
                                                            <th colSpan={5} style={{ textAlign: 'center' }}>VEHICLE DETAILS
                                                            </th>
                                                        </tr>

                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                VEHICLE CODE	                                                          </td>

                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={3}>
                                                                {VehicleCode}
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                VEHICLE MAKE	                                                          </td>

                                                            <td style={{ textAlign: "left" }} colSpan={3}>
                                                                {VehicleMake}
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                VEHICLE MODEL
                                                            </td>

                                                            <td style={{ textAlign: "left" }} colSpan={3}>
                                                                {Model}
                                                            </td>

                                                        </tr>
                                                        
                                                            {MakeDetailsArr != null ?
                                                                MakeDetailsArr.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                        {item.caption}	                                                     </td>

                                                                    <td style={{ textAlign: "left" }} colSpan={3}>
                                                                        {item.string_value}

                                                                    </td>

                                                                </tr>
                                                            )
                                                        }) : null}

                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                RETAIL PRICE </td>

                                                            <td style={{ textAlign: "right" }} colSpan={3}>
                                                                {Currency}&nbsp;{RetailValue}
                                                            </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                DISCOUNT                                                            </td>

                                                            <td style={{ textAlign: "right" }} colSpan={3}>
                                                                {Currency}&nbsp;{Discount}
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                SELLING PRICE	                                                            </td>

                                                            <td style={{ textAlign: "right" }} colSpan={3}>
                                                                {Currency}&nbsp;{SellingPrice}
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                VEHICLE COST	                                                            </td>

                                                            <td style={{ textAlign: "right" }} colSpan={3}>
                                                                {Currency}&nbsp;{Cost}
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                VEHICLE PROFIT	                                                            </td>

                                                            <td style={{ textAlign: "right" }} colSpan={3}>
                                                                {Currency}&nbsp;{Profit}
                                                            </td>
                                                        </tr>

                                                        
                                                        { VehicleAccessoriesArr != '' ? 
                                                            <tr>
                                                                <td className='caption' style={{ textAlign: "center" }} colSpan={5}>
                                                                ACCESSORIES</td>
                                                            </tr>
                                                        : null }    
                                                           
                                                        { VehicleAccessoriesArr != '' ?
                                                            VehicleAccessoriesArr.map((item, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td className='caption' style={{ textAlign: "left" }} colSpan={2}>
                                                                         {item.name}</td>
                                                                        <td style={{ textAlign: "right" }} colSpan={3}>
                                                                         {item.amount}</td>
                                                                    </tr>
                                                                )
                                                                })
                                                        : null}

                                                        
                                                        
                                                        { ProductSummary != '' && ProductSummary != null ?
                                                        <tr className="caption">
                                                            <th colSpan={5} style={{ textAlign: 'center' }}>PRODUCTS (VAPS) SUMMARY
                                                            </th>
                                                        </tr>
                                                        : null }

                                                        { ProductSummary != '' && ProductSummary != null ?
                                                        <tr>
                                                            <td className="caption">Product</td>
                                                            <td className="caption">Option</td>
                                                            <td className="caption" style={{ textAlign: "right" }}>Premium</td>
                                                            <td className="caption" style={{ textAlign: "right" }}>Payover</td>
                                                            <td className="caption" style={{ textAlign: "right" }}>Commission</td>
                                                        </tr>
                                                        : null }

                                                        {ProductSummary.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td className="caption m-1">{item.product}</td>
                                                                    <td className="caption m-1">{item.option}</td>
                                                                    <td style={{ textAlign: "right" }}>{Currency}&nbsp;{item.premium}</td>
                                                                    <td style={{ textAlign: "right" }}>{Currency}&nbsp;{item.payover}</td>
                                                                    <td style={{ textAlign: "right" }}>{Currency}&nbsp;{item.comission}</td>
                                                                </tr>
                                                            )
                                                        })}


                                                        <tr className="caption text-center p-1" >
                                                            <td colSpan={5} style={{ textAlign: "center" }}>
                                                                <input type="submit" name="Save" value="Save" />&nbsp;
                                                                <input type="button" name="Costing Schedule" value="Costing Schedule" onClick={(e) => { HandleCostingSchedule() }} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    )
}

export default Transaction_document