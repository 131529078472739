import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { useNavigate, useLocation,Link } from 'react-router-dom';

function Company_view_btn() {
    document.title = "Administration | Seriti - Add Company";
    const navigate = useNavigate();
    const { companyId } = useParams();

    const location = useLocation()
    const { flag } = location.state;
    const { msg } = location.state; //console.log(msg)

    return (
        <>
            <Admin_menu />
            <form method="post" action="">
                <table width="100%" height="1024" id="mainContainer" cellSpacing="0" cellPadding="0" className="custom-body">
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">
                                <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                                <table width="100%" cellSpacing="5" cellPadding="5">
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center">
                                                <p></p>

                                                <table cellSpacing="1" className="defaultTbl" width="600">
                                                    <tr className="text-center">{msg}</tr>
                                                    <tr className="text-center">
                                                    <Link to={'/admin/add_company/edit/' + companyId}>
                                                        <input type="button" name="view_company"
                                                        value="View Company"/>
                                                    </Link>
                                                    </tr>
                                                </table>

                                                <p></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );
}

export default Company_view_btn