import React from "react";
import seriti_loader from '../Images/logo/Seriti Loader.gif';

const Loader = (props) => {

  return (
    <div
      style={{
        position: "fixed",
        top: -60,
        left: 0,
        height: (props.height !== undefined ? props.height : 'calc(100% + 60px)'),
        width: "100%",
        backgroundColor: "rgb(0, 0, 0,0.5)",
        zIndex: "99999",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >

      <div >
        <img height={100} width={100} src={seriti_loader} alt="" />
      </div>
    </div>
  );
};

export default Loader;
