
import Report_menu from './Report_menu';
import React, { useState, useEffect } from "react";
import axios from "../axios";
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import Loader from "../Components/Loader";

const Report_folder = () => {
    const UserId = localStorage.getItem("UserId");

    const [searchData, setSearchData] = useState([]);
    const [Command, setCommand] = useState('');
    const [Name, setName] = useState('');
    const [FolderId, setFolderId] = useState('');
    const [sButton, setsButton] = useState('Add');
    const [reload, setReload] = useState(true);

    const navigate = useNavigate();
    const super_user = localStorage.getItem("super_user");
    const [flag, setFlag] = useState(false);
    const [ReadOnly, setReadOnly] = useState(false);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        document.title = 'Reports | Seriti BW';
    }, []);
    

    useEffect(() => {
        const payload = {
            UserId: UserId,
            super_user: super_user,
            module_name: "Reports"
        }
        axios
            .post("getRights", payload)
            .then((response) => {
                //console.log(response.data.data)
                if (response.data.status === 200) {
                    // alert(response.data.message);
                    if (response.data.data === 0) {//None
                        alert(response.data.message);
                        navigate('/home');
                    } else if (response.data.data === 1) {//Read
                        setFlag(true); setReadOnly(true);
                    } else if (response.data.data === 2) {//Write
                        setFlag(true); setReadOnly(false);
                    } else if (response.data.data === 3) {//Modify
                        setFlag(true); setReadOnly(false);
                    } else if (response.data.data === 4) {//Admin
                        setFlag(true); setReadOnly(false);
                    }
                }
            })
            .catch(() => {
                //setLoginLoader(false);
            });
    }, [UserId, super_user, navigate]);

    //----------------------------dropdown of type----------------------------------// 
    useEffect(() => {
        setLoader(true);
        //if (reload && flag) {
            const payload = {
                user_id: UserId
            };
            axios
                .post("report/folders", payload)
                .then((response) => {
                    // console.log(response.data.data);
                    setSearchData(response.data.data)
                   // setReload(false);
                   setLoader(false);
                })
                .catch((response) => {
                    //alert(response.data);
                    setLoader(false);
                });
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
       // }
    }, []); // [UserId, reload, flag]

    //----------------------------handle validation---------------------------------//
    const handleValidation = () => {
        var flag = true;

        if (Name === '') {
            alert('Please fill name fields!');
            flag = false;
        }
        return flag;
    };

    //------------------------------add/edit/remove---------------------------------//
    const handleSubmit = (e) => {
        // setGroup(group)
        e.preventDefault();
        const flag = (Command === 'Add' || Command === 'Update') ? handleValidation() : true;
        if (flag) {
            const payload = {
                command: Command,
                name: Name,//Add

                folder_id: FolderId
            };
            axios
                .post("report/folders/add_edit", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data.message)
                        if (Command !== 'Edit') {
                            toast.success(response.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                        }
                        // alert(response.data.message)
                        setReload(true);
                        setName('');
                        if (Command === 'Edit') {
                            setName(response.data.data.sNameValue);
                            setsButton('Save')
                        }
                        setCommand('')
                    }
                })
                .catch((response) => {
                    if (response.response.data.status === 400) {
                        toast.error(response.response.data.data, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                });
        }
    };

    return (
        <>
            {flag ?
                <>
                    <ToastContainer theme="colored" style={{ width: "450px" }} />
                    <Report_menu />
                    {loader ? <Loader /> : null}
                    <form method="post" action="" onSubmit={handleSubmit}>
                    <fieldset disabled={ReadOnly}>
                        <div >
                            <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                                <tbody>
                                    <tr valign="top">
                                        <td></td>
                                        <td width="100%">

                                            <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                                <tbody>
                                                    <tr>
                                                        <td valign="top" align="center"><p></p>
                                                            <table className="defaultTbl" cellSpacing={1} align="center" width="500">
                                                                <tbody>
                                                                    <tr className="caption">
                                                                        <th colSpan={2} style={{ textAlign: 'center' }}>Report Folders</th>
                                                                    </tr>
                                                                    {
                                                                        searchData.length ? (
                                                                            searchData.map((item, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>
                                                                                            <Link to={"/reports/individual/" + item.id}>
                                                                                                <input name="individual" type="button" value="Individual" onClick={(e) => { setCommand('Individual'); setFolderId(item.id) }} />
                                                                                            </Link> &nbsp;
                                                                                            {/* disabled={((item.id == 1)) ? true : false} */}
                                                                                            <Link to={"/reports/render_options/" + item.id + "/" + 0}>
                                                                                                <input name="consolidated" type="button" value="Consolidated" />
                                                                                            </Link>
                                                                                            {/* <input name="command" type="submit" value="Edit" onClick={(e) => { setCommand('Edit'); setFolderId(item.id) }} disabled /> */}
                                                                                        </td>

                                                                                        <td>{item.name}</td>

                                                                                        {/* <td>
                                                                                            <input type="submit" name="command" value="Remove" onClick={(e) => { setCommand('Remove'); setFolderId(item.id) }} disabled />
                                                                                        </td> */}
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                        ) : <tr><td colSpan={2}>"There is nothing to display."</td></tr>
                                                                    }

                                                                    {/* <tr className="caption">
                                                                        <td></td>
                                                                        <td>
                                                                            <input name="name" value={Name} onChange={(e) => setName(e.target.value)} /><span style={{ color: "red" }}> *</span>
                                                                        </td>
                                                                        <td>
                                                                            <input type="submit" name="command" value={sButton} onClick={(e) => setCommand(sButton)} />
                                                                        </td>
                                                                    </tr> */}
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </fieldset>
                    </form>
                </>
                : null}
        </>
    )
}

export default Report_folder