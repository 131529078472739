import Admin_menu from "../Admin_menu"
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"
import axios from "../../axios";
import { useParams } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Option_linking() {
    document.title = "Administration | Seriti - Product Admin";
    const location = useLocation()
    const { loc } = location.state
    const { type } = location.state
    const { parentId } = location.state
    const { listParent } = useParams();
    const { productId } = useParams();
    const [allGroup, setAllGroup] = useState([]);
    const [group, setGroup] = useState('');
    const [start_date, setstart_date] = useState('');
    const [allData, setAllData] = useState([])
    const [productName, setproductName] = useState('')
    const [flag, setflag] = useState(true)
    const [command, setCommand] = useState('')

    //for notes
    const [GroupTitle, setGroupTitle] = useState('')
    const [isShown, setIsShown] = useState(false)
    const [linkId, setlinkId] = useState('')
    const [notes, setNotes] = useState(null)

    //for tab branches
    const [branch, setBranch] = useState('');
    const [allBranch, setAllBranch] = useState([]);

    //---------------------------dropdown of group-------------------------------// 
    useEffect(() => {
        axios
            .get("index/group")
            .then((response) => {
                // console.log(response)
                setAllGroup(response.data.data);
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, []);

    //---------------------dropdown of branch depends on group-------------------// 
    useEffect(() => {
        if (group !== '') {
            axios
                .get("index/branch/" + group)
                .then((response) => {
                    // console.log(response)
                    setAllBranch(response.data.data);
                })
                .catch((response) => {
                    //alert(response.data);
                });
        }
    }, [group]);

    //----------------------------getting data-----------------------------------// 
    useEffect(() => {
        if (flag) {
            const payload = {
                type: type
            };
            axios
                .post("open_linking/groups/" + productId, payload)
                .then((response) => {
                    //console.log(response.data.data)
                    setAllData(response.data.data.rLinks);
                    setproductName(response.data.data.product[0].product)
                    //setNotes
                    setflag(false)
                })
                .catch((response) => {
                    //alert(response.data);
                });
        }
    }, [flag, productId, type]);
    //console.log(command)

    //-----------------------set state on notes tab-----------------------------//
    const handleNotes = (id, name, notes) => {
        setGroupTitle(name); setIsShown(true); setlinkId(id); setNotes(null); setNotes(notes);setGroup(id)
    }

    const handleValidation = () => {
        var flag = true;
        if (group === '' || group === '0') {
            alert('Please select a group.');
            flag = false;
        }
        return flag;
    };
    //console.log(notes)
    //----------------------------Add group-------------------------------------//
    const handleAdd = (e) => {
        // setGroup(group)
        e.preventDefault();
        const flag = handleValidation();

        if (flag) {
            const payload = {
                command: command,
                division: group,
                department: branch,
                start_date: start_date !== '' ? moment(start_date).format("YYYY-MM-DD") : 0,
                product_id: productId,
                linkId: linkId,
                notes: notes
            };
            // console.log(payload)
            axios
                .post("open_linking/add", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        setflag(true);
                        setCommand('')
                        // alert(response.data.message)
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    // console.log("response : "+response.data);
                    if (response.response.data.status === 400)
                    toast.error(response.response.data.data, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    };
    // console.log(productName)
    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Admin_menu />
            <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                <tbody>
                    <tr valign="top" className="bg-white">
                        <td></td>
                        <td width="100%" className="bg-white">
                            <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                            <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                            <form method="post" action="" onSubmit={handleAdd}>
                                                <input type="hidden" name="command" value="Search" />
                                                <table className="defaultTbl" width="500" cellSpacing="1">
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan="2" className="text-center">Add Link to Product :{productName}</th>
                                                        </tr>

                                                        <tr id="div_row">
                                                            <td className="caption">Group<span style={{ color: "red" }}> *</span></td>
                                                            <td id="div_container">
                                                                <select name="division" id="division" className="text-uppercase" value={group} style={{ width: '382px' }} onChange={(e) => setGroup(e.target.value)}>
                                                                    <option value="0">-- Select a group --</option>
                                                                    {allGroup.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id} >
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        {loc &&
                                                            <tr id="dept_row">
                                                                <td className="caption">Branch</td>
                                                                <td id="dept_container">
                                                                    <select name="department" id="department" className="text-uppercase" value={branch} onChange={(e) =>
                                                                        setBranch(e.target.value)} style={{ width: '382px' }}>
                                                                        {/* onChange = {(e) => setBranch(e.target.value)} */}
                                                                        <option value="0">-- Select a group First --</option>
                                                                        {allBranch.map((item) => {
                                                                            return (
                                                                                <option key={item.id} value={item.id} >
                                                                                    {item.name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </td>
                                                            </tr>}

                                                        <tr>
                                                            <td className="caption">Start date</td>
                                                            <td><DatePicker id="start_date" name="start_date" selected={start_date} onChange={(date) => setstart_date(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" /></td>
                                                        </tr>

                                                        <tr>
                                                            <td colSpan="2" className="caption text-center" >
                                                                <input type="submit" name="command" value="Add" onClick={(e) => setCommand('Add')} />

                                                                <Link to="/admin/financial_products" state={{ type: parentId, listParent: listParent }}>
                                                                    <input type="button" name="command" value="Back" />
                                                                </Link>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <br />

                                                <table className="defaultTbl" width="800">
                                                    <tbody>

                                                        <tr>
                                                            <th colSpan={6} className="text-center">Link To Product:{productName}</th>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption"></td>
                                                            <td className="caption"></td>
                                                            <td className="caption">Group</td>
                                                            <td className="caption">Status</td>
                                                            <td className="caption">Start Date</td>
                                                            <td className="caption">End Date</td>
                                                        </tr>
                                                        {
                                                            allData && allData.length ? (
                                                                allData.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <Link to={"/option_linking/edit/" + productId + "/" + item.id} className="btn btn-result-open" state={{ listParent: listParent, loc: loc, type: type }}>
                                                                                    <input type="submit" name="edit" value="Edit" />
                                                                                </Link>
                                                                            </td>
                                                                            <td>
                                                                                <input type="button" name="notes" value="Notes" onClick={(e) => { handleNotes(item.id, item.name, item.notes) }} />
                                                                            </td>
                                                                            <td> {item.name} </td>
                                                                            <td> {item.is_active} </td>
                                                                            <td> {item.start_date} </td>
                                                                            <td> {item.end_date} </td>

                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : <tr><td colSpan={6}>"This product is not linked to any Group or Branch yet."</td></tr>
                                                        }
                                                        <tr >
                                                            <td className="caption text-center" colSpan={6}>
                                                            </td>
                                                        </tr>
                                                        {isShown &&
                                                            <tr><td colSpan="6" style={{ textAlign: "center" }} className="caption" > {GroupTitle} Group Product Notes </td></tr>
                                                        }
                                                        {isShown &&
                                                            <tr>
                                                                <td style={{ textAlign: "center" }} >
                                                                    <input type="submit" name="command" value="Save" onClick={(e) => setCommand('Save')} />
                                                                </td>
                                                                <td colSpan="5">
                                                                    <textarea name="notes" rows="2" cols="80" value={notes !== null ? notes : ""} onChange={(e) => setNotes(e.target.value)} className="text-uppercase" ></textarea>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </form>
                                            <p></p>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </td>
                        <td></td>

                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default Option_linking