import { Link, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import Transaction_menu from "../Transaction/Transaction_menu";
import { NumericFormat } from 'react-number-format';
import Footer from "../Dashboard/Footer";
import axios from "../axios";
import Loader from "../Components/Loader";

const Insurance_products = () => {
  // const url_data = useParams();
  const navigate = useNavigate();
  const transaction_id = localStorage.getItem("transaction_id");
  const parent_id = localStorage.getItem("parent_id");
  const user_id = localStorage.getItem("UserId");
  const system_type = localStorage.getItem("system_type");

  const [NonProductDetails, setNonProductDetails] = useState([]);
  //const [EditProductDetails, setEditProductDetails] = useState([]);
  const [HasPayoverAmount, setHasPayoverAmount] = useState(0);
  const [PayoverAmount, setPayoverAmount] = useState("");
  const [ProductsSave, setProductsSave] = useState("");

  const [NotOfferedDeclineAccept, setNotOfferedDeclineAccept] = useState([]);
  const [CostGroup, setCostGroup] = useState([]);
  const [RetailPrice, setRetailPrice] = useState([]);
  const [Cash, setCash] = useState([]);
  const [FinalCash, setFinalCash] = useState([]);

  const [AcceptProductDetails, setAcceptProductDetails] = useState([]);
  const [DeclainProductDetails, setDeclainProductDetails] = useState([]);
  const [AcceptanceProductDetails, setAcceptanceProductDetails] = useState([]);
  const [CostGroupProductDetails, setCostGroupProductDetails] = useState([]);
  const [RetailPriceProductDetails, setRetailPriceProductDetails] = useState([]);
  const [CashProductDetails, setCashProductDetails] = useState([]);

  const [Flag1, setFlag1] = useState(0);
  const [Flag2, setFlag2] = useState(0);
  const [Flag3, setFlag3] = useState(0);
  const [Flag4, setFlag4] = useState(0);

  const [CommencementDate, setCommencementDate] = useState("");
  const FinalCommencementDate = CommencementDate != '' ? moment(CommencementDate).format("YYYY-MM-DD") : 0;
  const [currentdateFlag, setcurrentdateFlag] = useState(0);
  const [RowHideShow, setRowHideShow] = useState("none");
  const [RowHideShow1, setRowHideShow1] = useState("none");
  const [CommencementDateHideShowFlag, setCommencementDateHideShowFlag] = useState("none");

  const [FinalArrayOfAccepted, setFinalArrayOfAccepted] = useState([]);

  const [loader, setLoader] = useState(false);

  const UserId = localStorage.getItem("UserId");
  const super_user = localStorage.getItem("super_user");
  const [flag, setFlag] = useState(false);
  const [ReadOnly, setReadOnly] = useState(false);
  const [HeaderFlag, setHeaderFlag] = useState(1);

  //----------------check permission------------------
  useEffect(() => {
    const payload = {
      UserId: UserId,
      super_user: super_user,
      module_name: "Products"
    }
    axios
      .post("getRights", payload)
      .then((response) => {

        if (response.data.status === 200) {
          if (response.data.data === 0) {//None
            alert(response.data.message); setFlag(false);
            navigate('/transaction/create_transaction/'+ transaction_id); 
          } else if (response.data.data === 1) {//Read
            setFlag(true); setReadOnly(true);
          } else if (response.data.data === 2) {//Write
            setFlag(true); setReadOnly(false);
          } else if (response.data.data === 3) {//Modify
            setFlag(true); setReadOnly(false);
          } else if (response.data.data === 4) {//Admin
            setFlag(true); setReadOnly(false);
          }
        }
      })
      .catch(() => {
        //setLoginLoader(false);
      });
  }, [UserId, super_user, navigate]);

  useEffect(() => {
    document.title = 'Transactions | Seriti BW';
  }, []);

  //------------------- get data -----------------
  useEffect(() => {
    setLoader(true);
    const payload = {
      transaction_id: transaction_id,
      user_id: user_id,
      parent_id: parent_id,
      category: 1,
      system_type: system_type,
      id: 0,
    };
    axios
      // .post("transaction_product/featch_non_insurance_product_details", payload)
      .post("getProductDetails", payload)
      .then((response) => {
         // console.log(response.data.data.FinalArrayOfAccepted);
        setNonProductDetails(response.data.data.final_result);
        setAcceptProductDetails(response.data.data.accept_product_result);
        setCostGroupProductDetails(response.data.data.cost_group_product_result);
        setRetailPriceProductDetails(response.data.data.retail_price_product_result);
        setCashProductDetails(response.data.data.cash_product_result);
        setDeclainProductDetails(response.data.data.accept_product_result);
        setAcceptanceProductDetails(response.data.data.offred_product_result);
        setFinalArrayOfAccepted(response.data.data.FinalArrayOfAccepted);//ACCEPT

        let result = response.data.data.final_result;
        for (var i = 0; i < result.length; i++) {
          if (result[i]['accept_check_box_radio_status'] == 'checked' && result[i]['accept_check_box_radio_value'] == 10182) { // comme date
            setCommencementDateHideShowFlag("");
            setRowHideShow("");
            if(result[i]['commencement_date'] != '' && result[i]['commencement_date'] != 0){
              setCommencementDate(result[i]['commencement_date']);
            }
          }

          if (result[i]['accept_check_box_radio_status'] == 'checked' && result[i]['accept_check_box_radio_value'] == 10181) {
            setRowHideShow("");
          }

          if (result[i]['accept_check_box_radio_status'] == 'checked' && result[i]['accept_check_box_radio_value'] == 10321) {
            setRowHideShow1("");
          }

          if (result[i]['accept_check_box_radio_status'] == 'checked' && result[i]['accept_check_box_radio_value'] == 10322) {
            setRowHideShow("");
          }

          if (result[i]['accept_check_box_radio_status'] == 'checked' && result[i]['accept_check_box_radio_value'] == 10294) {
            setRowHideShow1("");
          }

          if (result[i]['accept_check_box_radio_status'] == 'checked' && result[i]['accept_check_box_radio_value'] == 10350) {
            setRowHideShow1("");
          }

          if (result[i]['accept_check_box_radio_status'] == 'checked' && result[i]['accept_check_box_radio_value'] == 10351) {
            setRowHideShow1("");
          }
        }

        let cost_array = response.data.data.cost_group_product_result;
        let CostGroupArray = [...cost_array];
        setCostGroup(CostGroupArray);

        let price_array = response.data.data.retail_price_product_result;
        let RetailPriceArray = [...price_array];
        setRetailPrice(RetailPriceArray);
        
        let cash_array = response.data.data.cash_product_result;
        let CashArray = [...cash_array];
        setFinalCash(CashArray);
        setLoader(false);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      })
      .catch(() => {
        //setLoader(false);
        setLoader(false);
      });
  }, []);

 


  // console.log(FinalArrayOfAccepted);
  const addPayoverValue = (index, event, msg) => {
    const fPayover = prompt(msg);
    if (fPayover != null) {
      setHasPayoverAmount(1);
      setPayoverAmount(fPayover);
    }
  }

  const checkMinMax = (event, min, max) => {
    const price_amt = event.target.value;
    if (min >= price_amt) {
      alert('Price entered is below minimum amount of ' + min);
    }
    if ((max <= price_amt) && (max > 0)) {
      alert('Price entered is above maximum amount of ' + max);
    }

  }

  //---------------------- creating array ---------------------
  const handleNotOfferedAndDeclined = (index, event, un_index) => {
    let data = [...FinalArrayOfAccepted];
    data[un_index][event.target.name] = event.target.value;
    setFinalArrayOfAccepted(data);
    if (Flag2 === 0) {
      setCostGroup(CostGroupProductDetails)
    }

    if (Flag3 === 0) {
      setRetailPrice(RetailPriceProductDetails);
    }

    if (Flag4 === 0) {
      setFinalCash(CashProductDetails);
    }

    setFlag1(1);
  }

  const handleAccepted = (index, event, accept_name, IsWordingDisplay, WordingDisplayName) => {
    if (IsWordingDisplay !== "" && WordingDisplayName === "Contract") {
      alert("Contract must be printed")
    }
    let NotOfferedDeclineAccept2 = [...FinalArrayOfAccepted];
    for (let i = 0; i < FinalArrayOfAccepted.length; i++) {
      const valueOfKey = Object.keys(NotOfferedDeclineAccept2[i]);
      if (valueOfKey[0] === accept_name) {
        NotOfferedDeclineAccept2[i][valueOfKey[0]] = event.target.value;
      }
    }
    // console.log(NotOfferedDeclineAccept2);

    setFinalArrayOfAccepted(NotOfferedDeclineAccept2);

    if (Flag2 === 0) {
      setCostGroup(CostGroupProductDetails)
    }

    if (Flag3 === 0) {
      setRetailPrice(RetailPriceProductDetails);
    }

    if (Flag4 === 0) {
      setFinalCash(CashProductDetails);
    }
    setFlag1(1);
  }

  const handleCostGroupChange = (index, event) => {
    let CostGroupArray = [...CostGroupProductDetails];
    const cost_group_radio_name = event.target.name;
    CostGroupArray[index][cost_group_radio_name] = event.target.value;
    setCostGroup(CostGroupArray);

    if (Flag1 === 0) {
      setNotOfferedDeclineAccept(AcceptProductDetails);
    }

    if (Flag4 === 0) {
      setFinalCash(CashProductDetails);
    }

    if (Flag3 === 0) {
      setRetailPrice(RetailPriceProductDetails);
    }

    setFlag1(1);
  }

  const handleRetailPriceChange = (index, event) => {
    let RetailPriceArray = [...RetailPriceProductDetails];
    const cost_retail_price_name = event.target.name;
    RetailPriceArray[index][cost_retail_price_name] = event.target.value;
   // console.log(cost_retail_price_name);
    setRetailPrice(RetailPriceArray);

    // if (Flag1 === 0) {
    //   handleNotOfferedAndDeclined(index, event);
    // }

    if (Flag4 === 0) {
      setFinalCash(CashProductDetails);
    }

    if (Flag2 === 0) {
      setCostGroup(CostGroupProductDetails)
    }

    setFlag1(1);
  }

  const handleCashChange = (index, event) => {

    let CashArray = [...CashProductDetails];
    CashArray[index][event.target.name] = !CashArray[index][event.target.name];
    // console.log(CashArray);
    setFinalCash(CashArray);

    if (Flag1 === 0) {
      setNotOfferedDeclineAccept(AcceptProductDetails);
    }

    if (Flag2 === 0) {
      setCostGroup(CostGroupProductDetails)
    }

    if (Flag3 === 0) {
      setRetailPrice(RetailPriceProductDetails);
    }

    setFlag1(1);
  }
  
  const PdataOnchage = () => {
      setRetailPrice(RetailPriceProductDetails);
      setFinalArrayOfAccepted(FinalArrayOfAccepted);
      setCostGroup(CostGroupProductDetails)
      setFinalCash(CashProductDetails);
  }

  //---------------- add/edit product data ------------------------
  const handleAddEditProducts = (e) => {
    e.preventDefault();
    setLoader(true);
    const payload = {
      transaction_id: transaction_id,
      user_id: user_id,
      parent_id: parent_id,
      category: 1,
      system_type: system_type,
      command: ProductsSave,
      new_accept_data: FinalArrayOfAccepted,
      price_data: RetailPrice,
      cost_group_data: CostGroup,
      cash_data: FinalCash,
      has_payover_amount: HasPayoverAmount,
      payover_amount: PayoverAmount,
      commencement_date: FinalCommencementDate,
    };
   //console.log(payload); 
    axios
      // .post("transaction_product/add_edit_non_insurance_product_details", payload)
      .post("/addEditProducts", payload)
      .then((response) => {
        //  console.log(response); 
        if (response.data.data.command === "Save") {
          // window.location.reload(false);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER
          });
          setHeaderFlag(2)
        } else if (response.data.data.command === "Save and Continue") {
          navigate('/transaction/roa');
        }
        setLoader(false);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      })
      .catch((response) => {
        setLoader(false);
        console.log('error');
      });
  };

  //---------------------
  const ExcessHideShow = (id, val) => {
    //console.log(val);
    if (id == 'accept75' && val == 1) {
      const RowHideShow = "none";
      setRowHideShow(RowHideShow);
      setCommencementDateHideShowFlag("none");
    }

    if (id == 'accept100' && val == 1) {
      const RowHideShow1 = "none";
      setRowHideShow1(RowHideShow1);
      setRowHideShow("none");
    }

    if (id == 'accept197' && val == 1) {
      const RowHideShow1 = "none";
      setRowHideShow1(RowHideShow1);
      setRowHideShow("none");
    }

  }

  const ExcessHideShow1 = (id, val) => {
    if (id == 'accept75' && val == 2) {
      const RowHideShow = "none";
      setRowHideShow(RowHideShow);
      setCommencementDateHideShowFlag("none");
    }

    if (id == 'accept100' && val == 2) {
      const RowHideShow1 = "none";
      setRowHideShow1(RowHideShow1);
      setRowHideShow("none");
    }

    if (id == 'accept197' && val == 2) {
      const RowHideShow1 = "none";
      setRowHideShow1(RowHideShow1);
      setRowHideShow("none");
    }
  }

  const ExcessHideShow2 = (id, val) => {
    if (id == 'accept75' && val == 10181) {
      const RowHideShow = "";
      setRowHideShow(RowHideShow);
      setCommencementDateHideShowFlag("none");
    }

    if (id == 'accept75' && val == 10182) {
      const RowHideShow = "";
      setRowHideShow(RowHideShow);
      setCommencementDateHideShowFlag("");
    }

    if (id == 'accept100' && val == 10294) {
      const RowHideShow1 = "";
      setRowHideShow1(RowHideShow1);
    }

    if (id == 'accept197' && val == 10321) {
      const RowHideShow1 = "";
      setRowHideShow1(RowHideShow1);
      setRowHideShow("none");
    }

    if (id == 'accept197' && val == 10322) {
      const RowHideShow = "";
      setRowHideShow(RowHideShow);
      setRowHideShow1("none");
    }

    if (id == 'accept75' && val == 10350) {
      const RowHideShow = "";
      setRowHideShow(RowHideShow);
      setCommencementDateHideShowFlag("none");
    }

    if (id == 'accept75' && val == 10351) {
      const RowHideShow = "";
      setRowHideShow(RowHideShow);
      setCommencementDateHideShowFlag("");
    }


  }

  const RemoveZeroValcomm = (index, event) => {
    // let data1 = [...NonProductDetails];
    // data1[index]["retail_price"] = (event.target.value) ? event.target.value : 0;
    // let data = [...NonProductDetails];
    // if(data1[40].retail_price_value === '0.00'){
    //  // alert('s');
    //   data1[index]["retail_price_value"] = '';
    //   //console.log(data1);
    //   setNonProductDetails(data);
    // }
  }

  const ShowZeroValcomm = (index, event) => {
    // let data1 = [...NonProductDetails];
    //  //console.log(data1);
    //  data1[index]["retail_price_value"] = (event.target.value) ? event.target.value : '0.00';
    // // setNonProductDetails(data1);
    // if(data1[40].retail_price_value === '0.00'){
    //   data1[index]["retail_price_value"] = '0.00';
    //   setNonProductDetails(data1);
    // }
  }

  const [NewArray, setNewArray] = useState([]);
  //------------------handle button click of category-----------------------------------//
  const handleChildProducts = (index, display_id) => {
    let DispArr = [...NewArray];
    let DispIdArr = [];

    //EXTENDED WARRANTY
    if (display_id === 169 || display_id === 170 || display_id === 171 || display_id === 172 || display_id === 173 || display_id === 174 || display_id === 186 || display_id === 187 || display_id === 188 || display_id === 189) DispIdArr = [169, 170, 171, 172, 173, 174, 186, 187, 188, 189];

    //PRE-OWNED WARRANTY
    if (display_id === 178 || display_id === 179 || display_id === 180 || display_id === 181 || display_id === 206) DispIdArr = [178, 179, 180, 181, 206];

    //WARRANTY
    if (display_id === 160 || display_id === 162) DispIdArr = [160, 162];

    //COMPREHENSIVE INSURANCE
    if (display_id === 75 || display_id === 100 || display_id === 195) DispIdArr = [75, 100, 195];

    //COMPREHENSIVE INSURANCE ANNUAL RENEWAL
    if (display_id === 197) DispIdArr = [197];

    //SHORTFALL
    if (display_id === 115 || display_id === 196 || display_id === 193) DispIdArr = [115, 196, 193];

    //DEBT PROTECTION
    if (display_id === 133 || display_id === 190 || display_id === 198 || display_id === 112 || display_id === 192 || display_id === 194 || display_id === 199) DispIdArr = [133, 190, 198, 112, 192, 194, 199];

    //EXCESS BUYBACK
    if (display_id === 182 || display_id === 194) DispIdArr = [182, 194];

    for (let i = 0; i < DispIdArr.length; i++) {
      (DispIdArr[i] in NewArray && NewArray[DispIdArr[i]] !== undefined && NewArray[DispIdArr[i]] !== "") ? (DispArr[DispIdArr[i]] = "") : (DispArr[DispIdArr[i]] = DispIdArr[i]);
    }
    (display_id in NewArray && NewArray[display_id] !== "") ? (NonProductDetails[index]["sign"] = "+") : (NonProductDetails[index]["sign"] = "-");

    setNewArray(DispArr);
  }

  //let panFlag = 1;

  return (
    <>
      <ToastContainer theme="colored" style={{ width: "450px", marginLeft: "-200px" }} />
      <Transaction_menu panel_flag={HeaderFlag}/>
      {loader ? <Loader /> : null}
      {flag ? <>
        <div>
          <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing={0} cellPadding={0} className="custom-body">
            <tbody>
              <tr valign="top">
                <td></td>
                <td width="100%">
                  <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                  {parent_id != 0 ?
                    <fieldset disabled={ReadOnly}>
                      <form name="non_product" method="post" onSubmit={handleAddEditProducts} >
                        <table width="100%" border="0" cellSpacing={5} cellPadding={5}>
                          <tbody>
                            <tr>
                              <td valign="top" align="center">
                                <table cellSpacing={1} className="defaultTbl no_caption_color" width="1000">
                                  <tbody>
                                    <tr>
                                      <th colSpan={9} style={{ textAlign: 'center' }}>INSURANCE PRODUCTS</th>
                                    </tr>
                                    <tr>
                                      <td className="caption" width={150}>Item</td>
                                      <td className="caption" width={175} style={{ textAlign: 'center' }}>Not Offered</td>
                                      <td className="caption">Declined</td>
                                      <td className="caption">Accepted</td>
                                      <td className="caption" width={150}>Cost Group <br />&nbsp;&nbsp;1&nbsp;&nbsp;&nbsp;2&nbsp;&nbsp;&nbsp;3&nbsp;&nbsp;</td>
                                      <td className="caption">Cash</td>
                                      <td className="caption">WORDING</td>
                                      <td className="caption" width={90}>Retail price</td>
                                      <td className="caption" width={175}>Installment</td>
                                    </tr>
                                    {/* {//console.log(NonProductDetails)} */}
                                    {
                                      NonProductDetails.length > 0 ?
                                        NonProductDetails.map((item, index) => {
                                          //var test = index+100; 

                                          return [

                                            <tr key={item.even} style={{ display: (item.display_category == true) ? "" : "none" }} >

                                              {/* ITEM NAME */}
                                              <td className="caption" style={{ display: (item.not_offered_radio_delined_name == 'accept182') ? RowHideShow : (item.not_offered_radio_delined_name == 'accept199') ? RowHideShow1 : "" }}>
                                                {(item.display_button_value !== "") ?
                                                  <input type="button" name={item.display_button_id} id={item.display_button_id} value={item.display_button_value + " " + item.sign} style={{ display: item.display_button_style }} onClick={(e) => handleChildProducts(index, item.display_button_id)} />
                                                  : null}
                                              </td>

                                              {/* -------------------NOT OFFERED---------------------------------------- */}

                                              <td className="caption" style={{ display: (item.not_offered_radio_delined_name == 'accept182') ? RowHideShow : (item.not_offered_radio_delined_name == 'accept199') ? RowHideShow1 : "", textAlign: 'center' }}>
                                                <input type="radio" value="1" name={item.not_offered_radio_delined_name1} id={item.not_offered_radio_delined_name1} defaultChecked={(item.offered_checked_status === 'checked') ? true : false} onChange={(e) => { setNotOfferedDeclineAccept(e.target.value); handleNotOfferedAndDeclined(index, e, item.unique_index); ExcessHideShow(item.not_offered_radio_delined_name, 1) }} />
                                              </td>

                                              {/* ------------------------DECLINED----------------------------------- */}
                                              <td className="caption" style={{ display: (item.not_offered_radio_delined_name == 'accept182') ? RowHideShow : (item.not_offered_radio_delined_name == 'accept199') ? RowHideShow1 : "", textAlign: 'center' }}>
                                                <input type="radio" value="2" name={item.not_offered_radio_delined_name1} id={item.not_offered_radio_delined_name1} defaultChecked={(item.delined_checked_status === 'checked') ? true : false} onChange={(e) => { setNotOfferedDeclineAccept(e.target.value); handleNotOfferedAndDeclined(index, e, item.unique_index); ExcessHideShow1(item.not_offered_radio_delined_name, 2) }} />
                                              </td>

                                              {/* --------------------PRODUCT OPTIONS NAME---------------------------- */}
                                              <td className="caption" style={{ display: (item.not_offered_radio_delined_name == 'accept182') ? RowHideShow : (item.not_offered_radio_delined_name == 'accept199') ? RowHideShow1 : "", textAlign: 'left' }} colSpan={6}>
                                                {item.display_accepted}
                                              </td>
                                            </tr>,
                                            //  second tr -------------------------------------------
                                            // style={{ display : (item.repeat_option_id == 'yes') ? "" : "none" }}

                                            (NewArray.length > 0 ?
                                              (item.display_button_id in NewArray) ?
                                                (NewArray[item.display_button_id] !== undefined && NewArray[item.display_button_id] !== "") ?
                                                  <>
                                                    <tr key={item.odd}  >

                                                      {
                                                        item.td_display_name != 'EXCESS BUYBACK' ?

                                                          <td colSpan={3}>
                                                            {item.td_display_name}
                                                            {
                                                              item.commencement_date != '' ?
                                                                <a style={{ display: CommencementDateHideShowFlag }}><br />Commencement Date :
                                                                  {currentdateFlag == 1 ?
                                                                    <DatePicker id="commencement_date" wrapperClassName="datePicker" selected={CommencementDate} name="commencement_date" onChange={(date) => { setCommencementDate(date); setcurrentdateFlag(1); PdataOnchage(); }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                    :
                                                                    <DatePicker id="commencement_date" wrapperClassName="datePicker" value={item.commencement_date} name="commencement_date" onChange={(date) => { setCommencementDate(date); setcurrentdateFlag(1); PdataOnchage(); }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                  }
                                                                </a>
                                                                : null
                                                            }
                                                          </td>
                                                          :

                                                          // <td style={{ display : (item.accept_check_box_radio_value == 10182) ? "": RowHideShow   }}>
                                                          <td style={{ display: (item.not_offered_radio_delined_name == 'accept182') ? RowHideShow : (item.not_offered_radio_delined_name == 'accept199') ? RowHideShow1 : "" }} colSpan={3}>
                                                            {
                                                              item.td_display_name == 'EXCESS BUYBACK' ?
                                                                <a>EXCESS BUYBACK </a>
                                                                : null}
                                                          </td>

                                                      }
                                                      {/* blanck td  */}

                                                   
                                                      {/* {//accepted radio button} */}

                                                      <td style={{ display: (item.not_offered_radio_delined_name == 'accept182') ? RowHideShow : (item.not_offered_radio_delined_name == 'accept199') ? RowHideShow1 : "", textAlign: 'center' }}>
                                                        <input type="radio" value={item.accept_check_box_radio_value} name={item.not_offered_radio_delined_name} id={item.not_offered_radio_delined_name} defaultChecked={(item.accept_check_box_radio_status === 'checked') ? true : false} onChange={(e) => { setNotOfferedDeclineAccept(e.target.value); handleAccepted(index, e, item.not_offered_radio_delined_name, item.wording_display, item.wording_show_name); ExcessHideShow2(item.not_offered_radio_delined_name, item.accept_check_box_radio_value) }} />
                                                      </td>

                                                      {/* ------------------------------------------------ */}
                                                      <td style={{ display: (item.not_offered_radio_delined_name == 'accept182') ? RowHideShow : (item.not_offered_radio_delined_name == 'accept199') ? RowHideShow1 : "", textAlign: 'center' }}>
                                                        <input type="radio" value="1" name={item.cost_group_radio_name1} defaultChecked={(item.cost_group === 1) ? true : null} onChange={(e) => { setCostGroup(e.target.value); handleCostGroupChange(index, e) }} />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        <input type="radio" value="2" name={item.cost_group_radio_name1} defaultChecked={(item.cost_group === 2) ? true : null} onChange={(e) => { setCostGroup(e.target.value); handleCostGroupChange(index, e) }} />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        <input type="radio" value="3" name={item.cost_group_radio_name1} defaultChecked={(item.cost_group === 3) ? true : null} onChange={(e) => { setCostGroup(e.target.value); handleCostGroupChange(index, e) }} />
                                                      </td>


                                                      <td style={{ display: (item.not_offered_radio_delined_name == 'accept182') ? RowHideShow : (item.not_offered_radio_delined_name == 'accept199') ? RowHideShow1 : "", textAlign: 'center' }}>

                                                        {/* <td style={{ display : (item.td_display_name != 'EXCESS BUYBACK') ? "": RowHideShow, textAlign: 'center'}}> */}
                                                        <input type="checkbox" value="1" name={item.cash_checkbox_name} id={item.cash_checkbox_name} defaultChecked={(item.cash_status === 'checked') ? true : false} onChange={(e) => { item.cash_status === 'checked' ? setCash(Cash) : setCash(Cash); handleCashChange(index, e) }} />
                                                      </td>

                                                      <td style={{ display: (item.not_offered_radio_delined_name == 'accept182') ? RowHideShow : (item.not_offered_radio_delined_name == 'accept199') ? RowHideShow1 : "", textAlign: 'center' }}>
                                                        {
                                                          item.wording_display !== '' ?
                                                            <a href={item.wording_path} target="_blank" rel="noreferrer">
                                                              <span style={{ color: 'blue' }}>{item.wording_show_name}</span>
                                                            </a>
                                                            : null}
                                                      </td>


                                                      <td style={{ display: (item.not_offered_radio_delined_name == 'accept182') ? RowHideShow : (item.not_offered_radio_delined_name == 'accept199') ? RowHideShow1 : "", textAlign: 'center' }}>
                                                        {item.retail_price_textbox === 'text' ?

                                                          <NumericFormat type="text" name={item.retail_price_name} id={item.retail_price_name} value={item.retail_price_value} onChange={(e) => { setRetailPrice(e.target.value); handleRetailPriceChange(index, e) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={(e) => { RemoveZeroValcomm(index, e) }} onBlur={(e) => { ShowZeroValcomm(index, e); checkMinMax(e, item.price_min, item.price_max) }} />

                                                          :
                                                          item.retail_price_show
                                                        }

                                                        {
                                                          item.retail_price_textbox === '' ?
                                                            <input type="hidden" name={item.retail_price_name} id={item.retail_price_name} defaultValue={item.retail_price_value} />
                                                            : null}
                                                      </td>

                                                      <td style={{ display: (item.not_offered_radio_delined_name == 'accept182') ? RowHideShow : (item.not_offered_radio_delined_name == 'accept199') ? RowHideShow1 : "", textAlign: 'center' }}>
                                                        {
                                                          item.payover_button_id != '' && item.td_display_name != 'EXCESS BUYBACK' ?
                                                            <input type="button" name={item.payover_button_id} id={item.payover_button_id} value="Payover" onClick={(evt) => { addPayoverValue(index, evt, item.show_alert_msg); }} />
                                                            :
                                                            item.payover_amount_value
                                                        }

                                                        {
                                                          item.payover_button_id !== '' && item.td_display_name != 'EXCESS BUYBACK' ?
                                                            item.payover_amount_value
                                                            : null
                                                        }

                                                        {
                                                          item.payover_button_id !== '' ?
                                                            <input type="hidden" name={item.has_payover_amount_name} id={item.has_payover_amount_id} value={HasPayoverAmount} onChange={(e) => { setHasPayoverAmount(e.target.value) }} />
                                                            :
                                                            null
                                                        }

                                                        {
                                                          item.payover_button_id !== '' ?
                                                            <input type="hidden" name={item.payover_amount_name} id={item.payover_amount_id} value={PayoverAmount} onChange={(e) => { setPayoverAmount(e.target.value) }} />
                                                            :
                                                            null
                                                        }
                                                      </td>

                                                    </tr>
                                                  </>
                                                  : null
                                                : null
                                              : null)
                                          ];
                                        }) :

                                        <tr>
                                          <td colSpan={9} className="text-center"><em>No record found</em></td>
                                        </tr>
                                    }

                                    <tr>
                                      <td className="caption" colSpan={9} style={{ textAlign: 'center' }}>
                                        <input type="submit" name="command" value="Save" onClick={(e) => { setProductsSave(e.target.value) }} />&nbsp;
                                        <input type="submit" name="command" value="Save and Continue" onClick={(e) => { setProductsSave(e.target.value) }} />&nbsp;
                                        <Link to={"/transaction/create_transaction/" + transaction_id}>
                                          <input type="button" className="p-relative" value="Cancel" />
                                        </Link>
                                      </td>
                                    </tr>

                                  </tbody>
                                </table>
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </form>
                    </fieldset>
                    :
                    <fieldset disabled={ReadOnly}>
                      <table width="100%" border="0" cellSpacing={5} cellPadding={5}>
                        <tbody>
                          <tr>
                            <td valign="top" align="center">
                              <table cellSpacing={1} className="defaultTbl no_caption_color" width="500">
                                <tbody>
                                  <tr>
                                    <th style={{ textAlign: 'center' }}>AVAILABLE VEHICLES</th>
                                  </tr>
                                  <tr>
                                    <td style={{ textAlign: 'center' }}>
                                      <select className="text-uppercase">
                                        <option value="0">-- Please add a vehicle to the transaction first --</option>
                                      </select>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="caption">IN ORDER TO ADD OEM ACCESSORIES, PLEASE SELECT A VEHICLE.</td>
                                  </tr>

                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                  }
                </td>
              </tr>
            </tbody>
          </table>
          <Footer />
        </div>
      </> : null}
    </>
  );
}

export default Insurance_products;