import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  pdf,
} from "@react-pdf/renderer";
import Html from 'react-pdf-html';
import header_logo from '../Images/pdf_logo/h1.png';
import moment from "moment";
import axios from "../axios";
import { saveAs } from 'file-saver';

const yourDate = new Date()
const CurrentDate = moment(yourDate).format("YYYY-MM-DD") 
//console.log(NewDate);

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
  },
  section: {
    margin: 10,
    padding: 10,
    fileName: "test.pdf",
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  footerPageNumber: {
    fontSize: 8,
    paddingLeft:1,
    paddingTop: 5,
    paddingBottom: 10,
    margin: 0,
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 0,
    left: 0,
    right: 0,
    paddingHorizontal: 16,
    color: "grey",
    borderTop: 1.3,
    marginHorizontal: 8,
  },
});

// Create Document Component
const Roa_pdf = () => {

const transaction_id = localStorage.getItem("transaction_id");
const system_type    = localStorage.getItem("system_type");
const user_id       = localStorage.getItem("UserId");

const [AllPdfDetails, setAllPdfDetails] = useState("");
const [OtherDetailsPdf, setOtherDetailsPdf] = useState("");
const [HeaderData, setHeaderData] = useState("");
const [FooterData, setFooterData] = useState("");


//console.log(ProductAcceptList); 
const [numPages, setNumPages] = useState(0);
const [pageNumber, setPageNumber] = useState(1);

function onDocumentLoadSuccess({ numPages }) {
  setNumPages(numPages);
}


//----------- get other details pdf html -----------------
// useEffect(() => {
//   const payload = {
//       transaction_id: transaction_id,
//       system_type: system_type,
//   };
//   //console.log(payload); 
//   axios
//     .post("record_of_advice/featch_pdf_other_details", payload)
//     .then((response) => {
//      // console.log(response); 
//         setOtherDetailsPdf(response.data); 
//     })
//     .catch(() => {
//       console.log('error'); 
//     });
// }, []);

//----------- get data -----------------
useEffect(() => {
  const payload = {
      transaction_id: transaction_id,
      system_type: system_type,
  };
  //console.log(payload); 
  axios
    .post("record_of_advice/featch_roa_pdf_details", payload)
    .then((response) => {
     // console.log(response); 
        setAllPdfDetails(response.data); 
    })
    .catch(() => {
      console.log('error'); 
    });
}, []);

//----------- get header -----------------
useEffect(() => {
  const payload = {
    transaction_id: transaction_id,
    user_id: user_id,
  };
  axios
    .post("record_of_advice/featch_pdf_header", payload)
    .then((response) => {
      //console.log(response.data.data); 
      setHeaderData(response.data.data.header_array[0]); 
      setFooterData(response.data.data.footer_array); 
    })
    .catch(() => {
      console.log('error'); 
    });
}, []);


//-------------------------------------- main html start--------------------------------

const header = `<html><body>

<div>
  
  <div style="width: 100%;">
    <div style="width: 50%; position: relative;">
      <img src=`+header_logo+` alt="" style="width: 100px;">
    </div>

    <div style="width: 50%; position: relative;left: 110;margin-top: -60px;">
      <h6 style="font-size: 10px; font-weight: bold;"> RECORD OF ADVICE <br> TRANSACTION #: `+HeaderData.sales_reference+` <br>GROUP: `+HeaderData.div_name+`</h6>
    </div>
    <div style="width: 100%; height:35px; position: relative;left: 480;margin-top: -50px;">
    <h6 style="margin: 0px; padding:0px">DATE :  `+CurrentDate+`</h6>
    </div>
  </div>
</div>
<hr style="margin: 0px; padding:0px">
</body></html>`;



const footer = `<html><body> 

</body></html>`;

const html = `<html><body>

     

     

</body></html>`;



  return (
   
    <PDFViewer style={styles.viewer} >
      <Document file="REACtPDF.pdf" onLoadSuccess={onDocumentLoadSuccess}>
        <Page size="A4" style={styles.page}>
        <View fixed >
           <Html>{header}</Html>
           {/* <Html pageNumber={pageNumber} /> */}
          </View>
          <Html style={{ fontSize: 10, fontFamily: 'Helvetica', }}>{html}</Html>
          
          <View fixed>
            <Html>{footer}</Html> 
          </View>

          <View style={styles.footer} render={({ pageNumber, totalPages }) => (
            <Text style={styles.footerPageNumber}> 
              {FooterData} &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;Page {pageNumber}/{totalPages}
            </Text>
            
          )}
      fixed
    />

        </Page>
      </Document>
    </PDFViewer>
    
  );
}
export default Roa_pdf;