import VechileAccessories_menu from "./VechileAccessories_menu"
import React, { useState, useEffect } from "react";
import { Link,useNavigate } from "react-router-dom"
import axios from "../../axios";
import { useLocation } from "react-router";
import Pagination from "../../Components/Pagination";

function Search_products() {
    document.title = "Products | Seriti - Vehicle & Accessories";
    const location = useLocation();
    const navigate = useNavigate();
    const [SearchText, setSearchText] = useState('');
    const [Category, setCategory] = useState('');
    const [AllCategory, setAllCategory] = useState([]);
    const [AllGroup, setAllGroup] = useState([]);
    const [Group, setGroup] = useState('');
    const [Branch, setBranch] = useState('');
    const [AllBranch, setAllBranch] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [TotalRecords, setTotalRecords] = useState(0);
    //const [isShown, setIsShown] = useState(false);
    
    var url = new URL(window.location.href);
    const page = url.searchParams.get('PageNo') || 1
    const limit = url.searchParams.get('Limit') || 10
    const [ServiceCall, setServiceCall] = useState(true);
    const [flag, setFlag] = useState(0);
    
     //console.log(search_text);
    useEffect(() => {
        setServiceCall(true);
    }, [limit, page, SearchText, Group, Branch, Category,flag]);

    //-----------------------------search data with pagination-------------------------// 
    useEffect(() => {
        if (ServiceCall && page) {
            // alert('sdsd');
            const payload = {
                division: Group,
                department: Branch,
                search_text: SearchText,
                category: Category,
                page: page,
                limit: limit,
            };
            axios
                .post("/product/search", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                      // console.log(response.data)
                       // setIsShown(true);
                        setTotalRecords(response.data.total_record)

                        if (location.state) {
                            setSearchData(location.state.data);
                            setTotalRecords(location.state.total_record)
                          }else{
                            setSearchData(response.data.data);
                          }
                    }
                })
                .catch((response) => {
                });
        }
    }, [ServiceCall, page, limit, SearchText, Group, Branch, Category, flag,location])
    
    //-----------------------------search data----------------------------------------// 
    const handleSubmit = (e) => {
        // setGroup(group)
        e.preventDefault();

        const payload = {
            division: Group,
            department: Branch,
            search_text: SearchText,
            category: Category,
            page: page,
            limit: limit,
        };
        axios
            .post("/product/search", payload)
            .then((response) => {
                // if (response.data.status === 200) {
                //     // console.log(response.data.data)
                //     setIsShown(true);
                //     setSearchData(response.data.data.rResults);
                //     setTotalRecords(response.data.data.TotalRecords)
                // }
                if (response.data.status === 200) {
                    // setProductDetails(response.data.data)
                    setTotalRecords(response.data.total_record)
                    navigate('/products/search/new_product',{state:response.data});
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
            });

    }; 
    
    useEffect(() => {
        if (location.state) {
            setSearchData(location.state.data);
            setTotalRecords(location.state.total_record);
            navigate("/products/search/new_product", {
            state: null,
          });
        }
      }, [location, navigate]);

    
    //---------------------------------group dropdown---------------------------------//
    useEffect(() => {
        //console.log(collection);
        axios
            .get("index/group")
            .then((response) => {
                // console.log(response)
                setAllGroup(response.data.data);
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, []);

    //------------------dropdown of branch depends on group---------------------------// 
    useEffect(() => {
        if (Group !== '') {
            axios
                .get("index/branch/" + Group)
                .then((response) => {
                    // console.log(response)
                    setAllBranch(response.data.data);
                })
                .catch((response) => {
                    //alert(response.data);
                });
        }
    }, [Group]);

    //----------------------------dropdown of product----------------------------------// 
    useEffect(() => {
        axios
            .get("/product/dropdown")
            .then((response) => {
                // console.log(response.data.data)
                setAllCategory(response.data.data);
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, []);

    return (
        <>
            <VechileAccessories_menu />
            <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                <tbody>
                    <tr valign="top" className="bg-white">
                        <td></td>
                        <td width="100%" className="bg-white">
                            <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                            <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                            <form method="post" action="" onSubmit={handleSubmit} >
                                                <table className="defaultTbl" width="500" cellSpacing="1">
                                                    <tbody>
                                                        <tr><th colSpan={2} className="text-center">Search for a Product</th></tr>
                                                        <tr><td className="caption">GROUP</td><td>
                                                            <select name="division" id="division" className="text-uppercase" value={Group} onChange={(e) => setGroup(e.target.value)} style={{ width: '382px' }}>
                                                                <option value="0">-- Select a group --</option>
                                                                {AllGroup.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id} >
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </td>
                                                        </tr>
                                                        
                                                        <tr><td className="caption">Branch</td><td>
                                                            <select name="department" id="department" className="text-uppercase" value={Branch} onChange={(e) =>
                                                                setBranch(e.target.value)} style={{ width: '382px' }}>
                                                                {/* onChange = {(e) => setBranch(e.target.value)} */}
                                                                <option value="0">-- Select a group First --</option>
                                                                {AllBranch.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id} >
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </td></tr>
                                                        {/* //$sCategorySelects */}
                                                        <tr><td className="caption">Product Type</td><td>
                                                            <select name="category" id="category" className="text-uppercase" value={Category} onChange={(e) =>
                                                                setCategory(e.target.value)} style={{ width: '382px' }}>
                                                                {/* onChange = {(e) => setBranch(e.target.value)} */}
                                                                <option value="0">-- Select option --</option>
                                                                {AllCategory.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id} >
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </td></tr>

                                                        {/*  */}
                                                        <tr>
                                                            <td className="caption">Search Criteria</td>
                                                            <td>
                                                                <input type="text" name="search_text" className="text-uppercase" value={SearchText} onChange={(e) => setSearchText(e.target.value)} />
                                                            </td>
                                                        </tr>
                                                        <tr className="caption">
                                                            <td colSpan={2} style={{ textAlign: "center" }}>
                                                                <input type="submit" name="command" value="Search" />
                                                            </td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </form>
                                            <br />
                                            {/* {isShown && ( */}
                                                <form method="post">
                                                    <table className="defaultTbl" width="800" >
                                                        <tbody>
                                                            <tr><th colSpan="4" style={{ textAlign: "center" }}>Products</th></tr>
                                                            <tr className="caption"><td>&nbsp;</td><td>&nbsp;</td><td width="90%">Product</td><td>&nbsp;</td></tr>
                                                            {
                                                                searchData.length ? (
                                                                    searchData.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <Link to={"/product/modify_product/" + item.id}>
                                                                                        <input type="button" value="Admin" />
                                                                                    </Link>
                                                                                </td>
                                                                                <td>
                                                                                    {(item.has_sub_products || item.has_sub_products !== 0) && (
                                                                                        <Link to={"/product/sub_products/" + item.id}>
                                                                                            <input type="button" value="Sub-products" />
                                                                                        </Link>
                                                                                    )}
                                                                                </td>
                                                                                <td>{item.name}</td>
                                                                                <td>
                                                                                    {(item.is_stock || item.is_stock !== 0) && (
                                                                                        // <Link to={"/product/stock_admin"}>
                                                                                            <input type="button" value="Stock" />
                                                                                        // </Link>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                ) : <tr><td colSpan={4}>"Your search returned no results."</td></tr>
                                                            }
                                                            <tr><td colSpan={4} width="90%" style={{textAlign:"center"}}>
                                                                {
                                                                    TotalRecords > 0 ?
                                                                        <Pagination
                                                                            records={searchData.length}
                                                                            link={"products/search/new_product"}
                                                                            SelectedPage={page}
                                                                            //search={''}
                                                                           search={"&search_text=" + SearchText  + "&division=" + Group + "&department=" + Branch + "&category=" + Category}
                                                                            NoOfRecords={TotalRecords}
                                                                        /> : null
                                                                }
                                                            </td></tr>

                                                        </tbody>
                                                    </table>
                                                </form>

                                            {/* )} */}

                                        </td>
                                        <td>

                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </td>
                        <td></td>

                    </tr>
                </tbody>
            </table>
        </>
    )

}

export default Search_products