import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu";
import Loader from "../../Components/Loader"
import axios from "../../axios";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import Pagination from "../../Components/Pagination";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

function Audit() {
    document.title = "Administration | Seriti - Audit Trail Report";
    const UserId = localStorage.getItem("UserId");
    const [Transaction, setTransaction] = useState('');
    const [ClientName, setClientName] = useState('');
    const [Page, setPage] = useState('');
    const [FromDate, setFromDate] = useState('');
    const [ToDate, setToDate] = useState('');
    const [searchData, setSearchData] = useState({});
    const [isShown1, setIsShown1] = useState(false);
    const [isShown2, setIsShown2] = useState(false);

    const [FromDateFlag, setFromDateFlag] = useState(0)
    const [ToDateFlag, setToDateFlag] = useState(0)

    const location = useLocation();
    const navigate = useNavigate();

    var url = new URL(window.location.href);
    const page = url.searchParams.get('PageNo') || 1
    const limit = url.searchParams.get('Limit') || 10
    const [ServiceCall, setServiceCall] = useState(true);
    const [MainViewFlag, setMainViewFlag] = useState(false);
    const [TotalRecords, setTotalRecords] = useState(0);
    const [loader, setLoader] = useState(false);


    //------------------------------ set pagination ---------------------------------//
    //console.log(search_text);
    useEffect(() => {
        setServiceCall(true);
    }, [limit, page, Transaction, ClientName, Page, FromDate, ToDate]);

    const handleValidation = () => {
        var flag = true;
        if (Transaction === '' && FromDate === '' && ToDate === '') {
            alert('Please enter atleast a \n - Transaction # \n or Date From and Date To');
            flag = false;
        }
        return flag;
    }

    //---------------------------------search data with pagination-------------------------------// 
    useEffect(() => {

        if (ServiceCall && page && MainViewFlag) {
            const flag = handleValidation();
            if (flag) {
                // alert("ServiceCall useEffect")
                const payload = {
                    transaction: Transaction,
                    client: ClientName,
                    page: Page,
                    date_from: FromDate !== '' ? moment(FromDate).format("YYYY-MM-DD") : 0,
                    date_to: ToDate !== '' ? moment(ToDate).format("YYYY-MM-DD") : 0,
                    // command : 
                    pagination_limit: limit,
                    pagination_offset: page
                };

                axios
                    .post("admin/audit", payload)
                    .then((response) => {
                        if (response.data.status === 200) {
                            // console.log(response.data)
                            setTotalRecords(response.data.total_record)

                            if (location.state) {
                                setSearchData(location.state.data);
                                setTotalRecords(location.state.total_record)
                            } else {
                                setSearchData(response.data.data);
                            }
                        }
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    })
                    .catch((response) => {
                        setLoader(false)
                    });
            }
        }
    }, [ServiceCall, limit, page, location])

    // console.log(FromDate,ToDate);
    //-----------------------------------searching users------------------------------------------//
    const handleSubmit = (e) => {
        setMainViewFlag(true);

        e.preventDefault();
        const flag = handleValidation();
        if (flag) {
            setLoader(true);
            const payload = {
                transaction: Transaction,
                client: ClientName,
                page: Page,
                date_from: FromDate !== '' ? moment(FromDate).format("YYYY-MM-DD") : 0,
                date_to: ToDate !== '' ? moment(ToDate).format("YYYY-MM-DD") : 0,
                // command : 
                pagination_limit: limit,
                pagination_offset: page
            };

            axios
                .post("admin/audit", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data.data);
                        setLoader(false);
                        if (FromDate !== "") { setFromDate(moment(FromDate).format("YYYY-MM-DD")); setFromDateFlag(1) }
                        if (ToDate !== "") { setToDate(moment(ToDate).format("YYYY-MM-DD")); setToDateFlag(1) }
                        if (Transaction === "") {
                            setIsShown1(false);
                            setIsShown2(true);
                            // setSearchData(response.data.data);
                            setTotalRecords(response.data.total_record)
                            //console.log(location.state);
                            if (location.state) {
                                setSearchData((location.state.data));
                                setTotalRecords(location.state.total_record)
                            } else {
                                setSearchData(response.data.data);
                            }
                        } else {
                            setIsShown1(true);
                            setIsShown2(false);
                            setSearchData(response.data.data);
                        }
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }
                })
                .catch((response) => {
                    setLoader(false);
                    //   alert(response.data);
                });
        }
    };

    useEffect(() => {
        if (location.state) {
            setSearchData(location.state.data);
            setTotalRecords(location.state.total_record);
            navigate("admin/audit", {
                state: null,
            });
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [location.state, navigate]);
    
    //---------------------------print pdf of particular trasaction id-----------------------------//
    const handlePrint = (id) => {
        setLoader(true)
        const payload = {
            transaction: id,
            client: ClientName,
            page: Page,
            date_from: FromDate !== '' ? moment(FromDate).format("YYYY-MM-DD") : 0,
            date_to: ToDate !== '' ? moment(ToDate).format("YYYY-MM-DD") : 0,
            user_id: UserId
        };
        axios
            .post("admin/audit_pdf", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data.message);
                    setLoader(false)
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch((response) => {
                setLoader(false)
                if (response.response.data.status === 400) {
                    toast.error(response.response.data.data, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            });
    };

    //---------------------------print pdf of in loop trasaction id-------------------------------//
    const handlePrint2 = (id) => {
        // console.log(id);
        setTransaction(id);
        handlePrint(id);
    }

    //------------------------------view of in loop transaction id---------------------------------//
    const handleView = (id) => {
        setTransaction(id)
        setLoader(true)
        const payload = {
            transaction: id,
            client: ClientName,
            page: Page,
            date_from: FromDate !== '' ? moment(FromDate).format("YYYY-MM-DD") : 0,
            date_to: ToDate !== '' ? moment(ToDate).format("YYYY-MM-DD") : 0,
        };
        axios
            .post("admin/audit", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    setLoader(false)
                    setIsShown1(true);
                    setIsShown2(false);
                    setSearchData(response.data.data);
                }
            })
            .catch(() => {
                setLoader(false)
                console.log('error');
            });
    }

    //------------------------------back in list of transactions list-----------------------------//
    const handleBack = () => {
        setTransaction('')
        const payload = {
            client: ClientName,
            page: Page,
            date_from: FromDate,
            date_to: ToDate
        };
        axios
            .post("admin/audit", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    setIsShown1(false);
                    setIsShown2(true);
                    setSearchData(response.data.data);
                }
            })
            .catch((response) => {
                console.log('error');
            });
    }
    
    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Admin_menu />
            {loader ? <Loader /> : null}
            <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                <tbody>
                    <tr valign="top" className="bg-white">
                        <td></td>
                        <td width="100%" className="bg-white">
                            <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                            <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                            <form method="post" action="" >
                                                <table className="defaultTbl" width="500" cellSpacing="1">
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan="2" className="text-center">TRANSACTION AUDIT TRAIL</th>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">TRANSACTION #</td>
                                                            <td>
                                                                <input type="text" name="transaction" id="transaction" autoComplete="off" className="sectext text-uppercase" value={Transaction} onChange={(e) => { setTransaction(e.target.value) }} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">CLIENT NAME</td>
                                                            <td><input type="text" name="clint_name" value={ClientName} className="text-uppercase" onChange={(e) => { setClientName(e.target.value) }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">PAGE</td>
                                                            <td>
                                                                <select name="page" id="page" className="text-uppercase" value={Page} onChange={(e) => setPage(e.target.value)}>
                                                                    <option value="">--SELECT PAGE--</option>
                                                                    <option value="client ">client details</option>
                                                                    <option value="transaction detail">transaction detail</option>
                                                                    <option value="transaction product">vehicle details</option>
                                                                    <option value="non-insurance product">value added products</option>
                                                                    <option value="insurance">insurance product</option>
                                                                    <option value="transaction contact">transaction contacts</option>
                                                                    <option value="View Transaction">view transaction</option>
                                                                    <option value="data saved for form">bank application</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">TRANSACTION DATE FROM</td>
                                                            <td>
                                                                {FromDateFlag === 1 ?
                                                                    <DatePicker id="start_date" name="start_date" value={FromDate} onChange={(date) => { setFromDate(date); setFromDateFlag(0) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                    :
                                                                    <DatePicker id="start_date" name="start_date" selected={FromDate} onChange={(date) => setFromDate(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr id="div_row">
                                                            <td className="caption">TRANSACTION DATE TO</td>
                                                            <td>
                                                                {ToDateFlag === 1 ?
                                                                    <DatePicker id="start_date" name="start_date" value={ToDate} onChange={(date) => { setToDate(date); setToDateFlag(0) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                    :
                                                                    <DatePicker id="start_date" name="start_date" selected={ToDate} onChange={(date) => setToDate(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2" className="caption text-center" ><input type="button" name="command" value="View" onClick={(e) => handleSubmit(e)} /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </form>
                                            <br />
                                            {isShown1 && (
                                                <>
                                                    <table className="defaultTbl" width="600">
                                                        <tbody>
                                                            <tr>
                                                                <th colSpan="4" className="text-center">Inception/Un-Inception Audit Trail</th>
                                                            </tr>
                                                            <tr>
                                                                <td className="caption">Date</td>
                                                                <td className="caption">Action</td>
                                                                <td className="caption">User</td>
                                                                <td className="caption">Reason</td>
                                                            </tr>
                                                            {
                                                                searchData.transaction && searchData.transaction.length ? (
                                                                    searchData.transaction.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{item.date}</td>
                                                                                <td>{item.action} </td>
                                                                                <td>{item.firstname + " " + item.surname}</td>
                                                                                <td>{item.reason}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                ) : <tr><td colSpan={4}>"No results found"</td></tr>
                                                            }

                                                        </tbody>
                                                    </table>

                                                    <table className="defaultTbl" width="900">
                                                        <tbody>
                                                            <tr><th colSpan="9" className="text-center">Audit Trail for Transaction #: {Transaction}  {searchData.name}</th>
                                                            </tr>
                                                            <tr className="caption">
                                                                <td>Group Name</td>
                                                                <td>Branch Name</td>
                                                                <td style={{ width: "200" }}>Job Date</td>
                                                                <td>Action</td>
                                                                <td>User</td>
                                                                <td style={{ width: "300" }}>Field</td>
                                                                <td>Previous Value</td>
                                                                <td>Modified Date</td>
                                                                <td>New Value</td>
                                                            </tr>

                                                            {
                                                                searchData.result && searchData.result.length ? (
                                                                    searchData.result.map((item, index) => {

                                                                        var length = searchData.result[index].rAuditDetailQuery.length;

                                                                        return (
                                                                            <>
                                                                                {
                                                                                    searchData.result[index].rAuditDetailQuery.map((tempitem, tempindex) => {
                                                                                        return (
                                                                                            <tr key={tempindex}>
                                                                                                {parseInt(tempindex) === 0 ? <>
                                                                                                    <td rowSpan={length} style={{
                                                                                                        verticalAlign: "top",
                                                                                                        textAlign: "left"
                                                                                                    }} >{searchData.rTransQuery[0].group}</td>
                                                                                                    <td rowSpan={length} style={{
                                                                                                        verticalAlign: "top",
                                                                                                        textAlign: "left"
                                                                                                    }} >{searchData.rTransQuery[0].department}</td>
                                                                                                    <td nowrap="nowrap" rowSpan={length} style={{
                                                                                                        width: "200",
                                                                                                        verticalAlign: "top",
                                                                                                        textAlign: "left"
                                                                                                    }} >{searchData.rTransQuery[0].created}</td>
                                                                                                    <td rowSpan={length} style={{
                                                                                                        verticalAlign: "top",
                                                                                                        textAlign: "left"
                                                                                                    }} >{item.note}</td>
                                                                                                    <td rowSpan={length} style={{
                                                                                                        verticalAlign: "top",
                                                                                                        textAlign: "left"
                                                                                                    }} >{item.sUserName}</td></> : null}
                                                                                                <td style={{ width: "300" }}>{tempitem.field}</td>
                                                                                                <td>{tempitem.old_value}</td>
                                                                                                <td nowrap="nowrap">{item.sDate}</td>
                                                                                                <td>{tempitem.new_value}</td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </>
                                                                        );
                                                                    })
                                                                ) : <tr><td colSpan={9}>"No results found"</td></tr>
                                                            }

                                                            <tr>
                                                                <td colSpan={9} className="caption text-center" >
                                                                    <input type="button" name="command" value="Back" onClick={(e) => handleBack()} />
                                                                    <input type="button" name="command" value="Print" onClick={(e) => handlePrint(Transaction)} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </>
                                            )}
                                            {isShown2 && (
                                                <form method="post">
                                                    <table className="defaultTbl" width="800">
                                                        <tbody>
                                                            <tr>
                                                                <th colSpan="10" className="text-center">TRANSACTIONS {FromDate} TO {ToDate}</th>
                                                            </tr>
                                                            <tr>
                                                                <td className="caption"></td>
                                                                <td className="caption">TRANSACTION #</td>
                                                                <td className="caption">CLIENT</td>
                                                            </tr>
                                                            {
                                                                searchData && searchData.length ? (
                                                                    searchData.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>

                                                                                    <input type="button" name="command" value="Print" onClick={(e) => { handlePrint2(item.id) }} />

                                                                                    <input type="button" name="copy" value="View" onClick={(e) => { handleView(item.id) }} />

                                                                                </td>
                                                                                <td style={{ textAlign: "center" }}> {item.id} </td>
                                                                                <td> {item.client} </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                ) : <tr><td colSpan={3}>"No results found"</td></tr>
                                                            }

                                                            <tr><td colSpan={3} width="90%" style={{ textAlign: "center" }}>
                                                                {
                                                                    TotalRecords > 0 ?
                                                                        <Pagination
                                                                            records={searchData.length}
                                                                            link={"admin/audit"}
                                                                            SelectedPage={page}
                                                                            //search={''}
                                                                            search={"&transaction=" + Transaction + "&client=" + ClientName + "&page=" + Page + "&date_from=" + FromDate + "&date_to=" + ToDate}
                                                                            NoOfRecords={TotalRecords}
                                                                        /> : null
                                                                }
                                                            </td></tr>
                                                        </tbody>
                                                    </table>
                                                </form>
                                            )}
                                            <p></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td></td>

                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default Audit