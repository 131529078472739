import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { token } from "../features/TokenSlice";
import axios from "../axios";
import Logo from '../Images/logo/Seriti BW - system logo.png';
import Home_img from '../Images/core/home_1.gif';
import Logout_img from '../Images/core/logout_1.gif';
import Loader from "../Components/Loader"

const Template = (props) => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [HederTableData, setHederTableData] = useState("");

    const transaction_id   = localStorage.getItem("transaction_id");
    const system_type      = localStorage.getItem("system_type");
    const user_country_id  = localStorage.getItem("user_country_id");
    const loanhouse_id  = localStorage.getItem("loanhouse_id");
    var panFlag = props.panel_flag !== '' ? props.panel_flag : 0;
    const [loader, setLoader] = useState(false);
        
//    const [HeaderFlag, setHeaderFlag] = useState(panFlag);

    // function refreshPage() {
    //     navigate('/home');
    //     window.location.reload(false);
    // }

    //----------- get header html -----------------
    useEffect(() => {
    // console.log(panFlag, "in call");
        setLoader(true);
        const payload = {
            transaction_id: transaction_id,
            system_type: system_type,
            user_country_id: user_country_id,
            loanhouse_id: loanhouse_id ? loanhouse_id : 0,
        };
        //console.log(payload); 
        axios
        .post("dashboard_transcation/dashboard_transcation_calculations", payload)
        .then((response) => {
        // console.log(response); 
            setHederTableData(response.data);
            setLoader(false); 
        })
        .catch(() => {
            console.log('error'); 
            setLoader(false); 
        });
    },[transaction_id, panFlag]);
    
    const logout = (e) => {
        e.preventDefault();
    
        const payload = {
            UserId: localStorage.getItem("UserId"),
            TokenId: localStorage.getItem("TokenId"),
        };
        //console.log(payload);
            axios
                .post("singout", payload)
                .then((response) => {
                   // console.warn(response);
                    if(response.data.status === 200){
                        //localStorage.clear();
                        localStorage.removeItem("Token");
                        localStorage.removeItem("TokenId");
                        localStorage.removeItem("UserId");
                        localStorage.removeItem("super_user");
                        localStorage.removeItem("Username");
                        localStorage.removeItem("system_type");
                        localStorage.removeItem("user_country_id");
                        localStorage.removeItem("division_id");
                        localStorage.removeItem("department_id");
                        localStorage.removeItem("section_id");
                        localStorage.removeItem("Access");
                        localStorage.removeItem("Modules");
                        localStorage.removeItem("transaction_id");
                        localStorage.clear();
                        dispatch(token(""));
                        navigate('/login'); 
                    }
                })
                .catch(() => {
                    //setLoginLoader(false);
                });
    }

    return (
        <>
             {loader ? <Loader /> : null}
            <table width="100%" border="0" cellPadding={0} cellSpacing={0} style={{ left: 0, top: 0, position: 'fixed', zIndex: 1,backgroundColor: 'white' }}>
                <tbody>
                    <tr valign="top">
                        <td width="60%" style={{ backgroundColor: '#ffffff' }} height="92" align='left'>
                            <img style={{ padding: '10px' }} src={Logo} />
                            &nbsp;
                        </td>
                        <td style={{ backgroundColor: '#ffffff' }} height="92">&nbsp;
                            {  transaction_id != 0 && (panFlag == 1 || panFlag == 2) ?
                                <div dangerouslySetInnerHTML={ {__html: HederTableData} } />
                            : null }
                          
                            {/* <div className="dashboard_summary1" id={panFlag}>
                            </div> */}
                        </td>

                        <td>
                            <table border="0" cellPadding={0} cellSpacing={0} align="right" style={{ marginTop: '12px' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ float: 'right', width: '100%' }} >
                                         <Link to="/home"> 
                                            <img border="0" src={Home_img} />
                                         </Link>
                                        </td>
                                        <td>&nbsp;</td>

                                        <td style={{ float: 'right', width: '100%' }} >
                                            <a href="" className="men" onClick={logout}>
                                              <img border="0" src={Logout_img} />
                                            </a>
                                        </td>
                                       
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table width="100%" cellPadding={0} cellSpacing={0} height="13" style={{ left: 0, top: 90, position: 'fixed', zIndex: 1 }}>
                <tbody>
                    <tr><td width="100%" className='line_bg'></td>
                    </tr>
                </tbody>
            </table>

            <table>
                <tbody>
                   <tr><td>&nbsp;</td></tr>
                </tbody>
            </table>
            
          
        </>
    );
}

export default Template;