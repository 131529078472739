import { Link, useNavigate } from "react-router-dom"
//import React, { useState, useEffect } from "react";
import edit_profile from '../Images/ribbon/jason1_edit_profile.png';
import jason1_edittransaction from '../Images/ribbon/jason1_edittransaction.png';
import jason1_vehicledetail from '../Images/ribbon/jason1_vehicledetail.png';
import jason1_accessories from '../Images/ribbon/jason1_accessories.png'
import jason1_vaps from '../Images/ribbon/jason1_vaps.png';
import jason1_insurance from '../Images/ribbon/jason1_insurance.png';
import roa_new from '../Images/ribbon/roa_new.png';
import jason1_co_contatcs from '../Images/ribbon/jason1_co_contatcs.png';
import jason1_supp_info from '../Images/ribbon/jason1_supp_info.png';
import jason1_viewtransaction1 from '../Images/ribbon/jason1_viewtransaction1.jpg';
import jason1_incept from '../Images/ribbon/jason1_incept.png';
import jason1_doc from '../Images/ribbon/jason1_doc.png';
import Template from "../Components/Template";
import Insurance_products from "./Insurance_products";
import axios from "../axios";

const Transaction_menu = (props) => {
    
    const navigate = useNavigate();
    const parent_id = localStorage.getItem("parent_id");
    const new_id     = localStorage.getItem("product_hidden_id");  // for using vehicle details module
    const tp_id        = localStorage.getItem("product_hidden_tp_id"); // for using vehicle details module
    const transaction_id = localStorage.getItem("transaction_id");
    const unincept_flag = localStorage.getItem("unincept_flag"); // if transcation module due to transcation status unincept (button) visible for checking flag
    const client_id = localStorage.getItem("client_id");
    let panFlag = props.panel_flag;

    const UserId = localStorage.getItem("UserId");
    const super_user = localStorage.getItem("super_user");

    const incept_contact = (e) => {
       alert('You cannot add a transaction contact to an incepted deal');
    }

    const CheckProductPermission = (event, id) => {   
        // console.log('ss');
         const payload = {
             UserId: UserId,
             super_user: super_user,
             module_name: "Products"
         }
         axios
         .post("getRights", payload)
         .then((response) => {
             if (response.data.status === 200) {
                 //console.log(response.data.data)
                 if (parseInt(response.data.data) === 0) {//None
                     alert(response.data.message);
                     navigate('/transaction/create_transaction/'+ transaction_id); 
                 } else if (parseInt(response.data.data) === 1) {//Read
                    alert(response.data.message);
                    if(id == 1){
                        navigate('/products/add_accessories_to_transaction/module=Transactions/'+ parent_id);
                    }else if(id == 2){
                         navigate('/transaction/products/module=Transactions/category=2/'+ parent_id);
                    }else if(id == 3){
                         navigate('/transaction/insurance_products/module=Transactions/category=1/'+ parent_id);
                    }
                 } else if (parseInt(response.data.data) === 4 || parseInt(response.data.data) === 2 || parseInt(response.data.data) === 3) {//Admin
                    // setSearchingFlag(response.data.data);
                    if(id == 1){
                       navigate('/products/add_accessories_to_transaction/module=Transactions/'+ parent_id);
                    }else if(id == 2){
                        navigate('/transaction/products/module=Transactions/category=2/'+ parent_id);
                    }else if(id == 3){
                        navigate('/transaction/insurance_products/module=Transactions/category=1/'+ parent_id);
                    }
                 }
                 // return true;
             }
         })
         .catch(() => {
             //setLoginLoader(false);
         });
     }
    
    return (
        <>
            <Template panel_flag={panFlag}/>
            <div>
                <table width="100%" border="0" cellPadding={0} cellSpacing={0} className="custom-icon-menu bg-white">
                    <tbody>
                        <tr>
                            {
                                unincept_flag == 1 ?
                                <td className="text-center">
                                    <Link to={"/transaction/client_company_view/" + client_id} className="custom_menu_item">
                                        <img height="63" className="titleHover_CLIENT_DETAILS" border="0" src={edit_profile} alt=""/>
                                        <span className="span_tooltip">CLIENT DETAILS</span>
                                    </Link>
                                </td>
                            :
                                <td className="text-center">
                                    <Link to="/transaction/client_company_add_edit/0" className="custom_menu_item">
                                        <img height="63" className="titleHover_CLIENT_DETAILS" border="0" src={edit_profile} alt="" />
                                        <span className="span_tooltip">CLIENT DETAILS</span>
                                    </Link>
                                </td>
                            }

                            <td className="text-center">
                                <Link to={"/transaction/edit_transaction/" + transaction_id} className="custom_menu_item">
                                    <img height="63" className="titleHover_TRANSACTION_DETAILS" border="0" src={jason1_edittransaction} alt=""/>
                                    <span className="span_tooltip">TRANSACTION DETAILS</span>
                                </Link>
                            </td>
                            
                           {
                            new_id == 0 ?
                                <td className="text-center">
                                    <Link to={"/products/search/product_type=vehicles/command=search/module=Transactions/products_product_type/0"} className="custom_menu_item">
                                        <img height="63" className="titleHover_VEHICLE_DETAILS" border="0" src={jason1_vehicledetail} alt=""/>
                                        <span className="span_tooltip">VEHICLE DETAILS</span>
                                    </Link>
                                </td>
                            :
                                <td className="text-center">
                                  
                                    <Link to={"/products/add_to_transaction/command=Edit/module=Transactions/" + new_id + "/" + tp_id} className="custom_menu_item">
                                        <img height="63" className="titleHover_VEHICLE_DETAILS" border="0" src={jason1_vehicledetail} alt=""/>
                                        <span className="span_tooltip">VEHICLE DETAILS</span>
                                    </Link>
                                </td>
                            }
                            
                            { super_user == 0 ?
                                <td className="text-center">
                                    <Link onClick={event => CheckProductPermission(event, 1)} className="custom_menu_item">
                                        <img height="63" className="titleHover_ACCESSORIES" border="0" src={jason1_accessories} alt=""/>
                                        <span className="span_tooltip">ACCESSORIES</span>
                                    </Link>
                                </td>
                                :
                                <td className="text-center">
                                    <Link to={"/products/add_accessories_to_transaction/module=Transactions/" + parent_id} className="custom_menu_item">
                                        <img height="63" className="titleHover_ACCESSORIES" border="0" src={jason1_accessories} alt=""/>
                                        <span className="span_tooltip">ACCESSORIES</span>
                                    </Link>
                                </td>
                            }

                            { super_user == 0 ?  // acess rights
                                <td className="text-center">
                                    { unincept_flag == 1 ? 
                                        <Link to={"/transaction/view_non_insurance_product/category=2/" + parent_id} className="custom_menu_item">
                                        <img height="63" className="titleHover_VALUE_ADDED_PRODUCTS" border="0" src={jason1_vaps} alt=""/>
                                        <span className="span_tooltip">VALUE ADDED PRODUCTS</span>
                                    </Link>
                                    : 
                                    // non insurane produts
                                        <Link onClick={event => CheckProductPermission(event, 2)} className="custom_menu_item">
                                            <img height="63" className="titleHover_VALUE_ADDED_PRODUCTS" border="0" src={jason1_vaps} alt=""/>
                                            <span className="span_tooltip">VALUE ADDED PRODUCTS</span>
                                    </Link>
                                    }
                                </td>
                                :
                                <td className="text-center">
                                    { unincept_flag == 1 ? 
                                        <Link to={"/transaction/view_non_insurance_product/category=2/" + parent_id} className="custom_menu_item">
                                        <img height="63" className="titleHover_VALUE_ADDED_PRODUCTS" border="0" src={jason1_vaps} alt=""/>
                                        <span className="span_tooltip">VALUE ADDED PRODUCTS</span>
                                    </Link>
                                    : 
                                    // non insurane produts
                                        <Link to={"/transaction/products/module=Transactions/category=2/" + parent_id} className="custom_menu_item">
                                            <img height="63" className="titleHover_VALUE_ADDED_PRODUCTS" border="0" src={jason1_vaps} alt=""/>
                                            <span className="span_tooltip">VALUE ADDED PRODUCTS</span>
                                    </Link>
                                    }
                                </td>
                            }
                            
                            { super_user == 0 ?  // acess rights
                                <td className="text-center">
                                    { unincept_flag == 1 ? 
                                        <Link to={"/transaction/view_insurance_products/category=1/" + parent_id} className="custom_menu_item">
                                            <img height="63" className="titleHover_INSURANCE" border="0" src={jason1_insurance} alt=""/>
                                            <span className="span_tooltip">INSURANCE</span>
                                        </Link>
                                    : 
                                    // Insurance_products
                                        <Link onClick={event => CheckProductPermission(event, 3)} className="custom_menu_item">
                                            <img height="63" className="titleHover_INSURANCE" border="0" src={jason1_insurance} alt=""/>
                                            <span className="span_tooltip">INSURANCE</span>
                                        </Link>
                                    }
                                </td>
                            :
                                <td className="text-center">
                                    { unincept_flag == 1 ? 
                                        <Link to={"/transaction/view_insurance_products/category=1/" + parent_id} className="custom_menu_item">
                                            <img height="63" className="titleHover_INSURANCE" border="0" src={jason1_insurance} alt=""/>
                                            <span className="span_tooltip">INSURANCE</span>
                                        </Link>
                                    : 
                                    // Insurance_products
                                        <Link to={"/transaction/insurance_products/module=Transactions/category=1/" + parent_id} className="custom_menu_item">
                                            <img height="63" className="titleHover_INSURANCE" border="0" src={jason1_insurance} alt=""/>
                                            <span className="span_tooltip">INSURANCE</span>
                                        </Link>
                                    }
                                </td>
                            }

                            <td className="text-center">
                                <Link to="/transaction/roa" className="custom_menu_item">
                                    <img height="63" className="titleHover_ROA" border="0" src={roa_new} alt=""/>
                                    <span className="span_tooltip">ROA</span>
                                </Link>
                            </td>
                            
                            { unincept_flag == 1 ? 
                                <td className="text-center">
                                    <Link to={"/transaction/create_transaction/"+transaction_id} className="custom_menu_item" onClick={(e) => { incept_contact() }} >  
                                        <img height="63" className="titleHover_TRANSACTION_CONTACTS" border="0" src={jason1_co_contatcs} alt="" />
                                        <span className="span_tooltip">TRANSACTION CONTACTS</span>
                                    </Link>
                                </td>
                            :
                                <td className="text-center">
                                    <Link to="/transaction/add_person" className="custom_menu_item">
                                        <img height="63" className="titleHover_TRANSACTION_CONTACTS" border="0" src={jason1_co_contatcs} alt=""/>
                                        <span className="span_tooltip">TRANSACTION CONTACTS</span>
                                    </Link>
                                </td>
                            }

                            <td className="text-center">
                                <Link to="/transaction/fill_forms" className="custom_menu_item">
                                    <img height="63" className="titleHover_SUPPORTING_INFO" border="0" src={jason1_supp_info} alt=""/>
                                    <span className="span_tooltip">SUPPORTING INFO</span>
                                </Link>
                            </td>

                            <td className="text-center">
                                <Link to={"/transaction/create_transaction/" + transaction_id} className="custom_menu_item">
                                    <img height="63" className="titleHover_VIEW_TRANSACTION" border="0" src={jason1_viewtransaction1} alt=""/>
                                    <span className="span_tooltip">VIEW TRANSACTION</span>
                                </Link>
                            </td>

                            <td className="text-center">
                                <Link to="/transaction/inception" className="custom_menu_item">
                                    <img height="63" className="titleHover_INCEPTION" border="0" src={jason1_incept} alt=""/>
                                    <span className="span_tooltip">INCEPTION</span>
                                </Link>
                            </td>

                            <td className="text-center">
                                <Link to="/transaction/doc" className="custom_menu_item">
                                    <img height="63" className="titleHover_DOC" border="0" src={jason1_doc} alt=""/>
                                    <span className="span_tooltip">DOC</span>
                                </Link>
                            </td> 
                        </tr>
                    </tbody>
                </table>

              
                <table width="100%" height="13" border="0" cellPadding={0} cellSpacing={0} className="custom-icon-menu-shadow ">
                    <tbody>
                        <tr>
                            <td width="100%" className="custom-shadow-img"></td>
                        </tr>
                    </tbody>
                </table>
           
            </div>
        </>
    );

}

export default Transaction_menu