import { Link, useNavigate, useParams } from "react-router-dom"
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import Transaction_menu from "../Transaction/Transaction_menu";
import Footer from "../Dashboard/Footer";
import axios from "../axios";
import Pagination from "../Components/Pagination";
import Loader from "../Components/Loader";

const Search = () => {
  const url_data = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const transaction_id = localStorage.getItem("transaction_id");
  const [loader, setLoader] = useState(false);
   // const parent_id      = localStorage.getItem("parent_id");
   // const user_id        = localStorage.getItem("UserId");
  const [VehicleAll, setVehicleAll] = useState([]);
  const [Vehicles, setVehicles] = useState("");
  //console.log(Vehicles);
  const [ModelYearAll, setModelYearAll] = useState([]);
  const [ModelYear, setModelYear] = useState("");
  const [HideDescountinuedVehicles, setHideDescountinuedVehicles] = useState(true);
  
  const [SearchText, setSearchText] = useState("");
  const [SearchCode, setSearchCode] = useState("");
  const [command, setCommand] = useState("Search");
  
  const [ProductDetails, setProductDetails] = useState([]);
  const [TotalRecord, setTotalRecord] = useState("");
  
  var url = new URL(window.location.href);
  const page = url.searchParams.get('PageNo') || 1
  const limit = url.searchParams.get('Limit') || 15
  const [ServiceCall, setServiceCall] = useState(false);
  const [flag, setFlag] = useState(0);
  
 

  //console.log(TotalRecord);
  // useEffect(() => {
  //   setServiceCall(true);
  // }, [limit, page, SearchText, SearchCode, ModelYear, HideDescountinuedVehicles, Vehicles, flag]);

    useEffect(() => {   
      if (ServiceCall && page) {
      // alert('sdsd');
      //setLoader(true);
       const payload = {
  
          command: command,
          division_id: localStorage.getItem("division_id"),
          department_id: localStorage.getItem("department_id"),
          search_text: SearchText,
          search_code: SearchCode,
          manufacture_date : ModelYear,
          show: 1,
          product_type: "vehicles",
          hide_disc: HideDescountinuedVehicles,
          products_vehicles: Vehicles,
          page: page,
          limit: limit,
     };
    //console.log(payload);
     axios
      .post("product_search/search_product_details", payload)
      .then((response) => {
         //console.log(response.data);
          setTotalRecord(response.data.total_record);
          if (location.state) {
            setProductDetails(location.state.data);
            setTotalRecord(location.state.total_record);
          }else{
            setProductDetails(response.data.data)
          }
          //setLoader(false);
          setServiceCall(true);
      })
      .catch(() => {
         console.log('error');
         //setLoader(false);
      });
        
      }
      
    }, [ServiceCall, page, limit, flag])
  
    //console.log(flag); [ServiceCall, page, limit, SearchText, SearchCode, ModelYear, HideDescountinuedVehicles, Vehicles, flag])

    
  // const ResetAll = () => {
  //   setSearchCode("");
  //   setSearchText("");
  //   setModelYear("");
  //   setVehicles("");
  // }

  const handleSubmit = (e) => {
    e.preventDefault();
     //alert('sdsd');
     const payload = {

        command: command,
        division_id: localStorage.getItem("division_id"),
        department_id: localStorage.getItem("department_id"),
        search_text: SearchText,
        search_code: SearchCode,
        manufacture_date : ModelYear,
        show: 1,
        product_type: "vehicles",
        hide_disc: HideDescountinuedVehicles,
        products_vehicles: Vehicles,
        page: page,
        limit: limit,
   };
  //console.log(payload);
   axios
    .post("product_search/search_product_details", payload)
    .then((response) => {
       //console.log(response.data);
        if (response.data.status === 200) {
          // setProductDetails(response.data.data)
          setTotalRecord(response.data.total_record);
          navigate('/products/search/product_type=vehicles/command=search/module=Transactions/products_product_type/0',{state:response.data});
        }
        setServiceCall(true);
    })
    .catch(() => {
       console.log('error');
    }); 
  }

  useEffect(() => {
    if (location.state) {
      setProductDetails(location.state.data);
      setTotalRecord(location.state.total_record);
        navigate("/products/search/product_type=vehicles/command=search/module=Transactions/products_product_type/0", {
        state: null,
      });
    }
  }, [location, navigate]);
  

// [ServiceCall, page, limit, SearchText, SearchCode, ModelYear, HideDescountinuedVehicles, Vehicles]

   //----------- get finance company dropdown -----------------
   useEffect(() => {
    const payload = {
       transaction_id: transaction_id,
       products_product_type: 1037,
       product_type: "vehicles",
    };
    axios
      .post("product_search/featch_vehicles_and_model_year", payload)
      .then((response) => {
        if(response.data.status === 200){
         //console.log(response);
          setVehicleAll(response.data.data.vehicles_array);
          setModelYearAll(response.data.data.model_year_array);
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      })
      .catch(() => {
        console.log('error'); 
      });
 }, []);

    return (
      <>
        <Transaction_menu />
        {loader ? <Loader /> : null}
        <div>
          <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing={0} cellPadding={0} className="custom-body">
            <tbody>
              <tr valign="top">
               <td></td>
               <td width="100%">
                <div id="parent_wrapper" className="custom-parent_wrapper"></div> 
                <form name="form1" method="post" onSubmit={handleSubmit}>
                  <table width="100%" border="0" cellSpacing={1} cellPadding={1}>
                    <tbody>
                      <tr>
                         <td valign="top" align="center">
                            <table cellSpacing={1} cellPadding={1} className="defaultTbl no_caption_color" width="350">
                              <tbody>
                                  <tr>
                                    <th colSpan={2} style={{textAlign: 'center'}}>Search for a Product</th>
                                  </tr>
                                  <tr>
                                    <td className="caption">VEHICLES</td>
                                    <td>
                                         <select id="products_vehicles" name="products_vehicles" className="text-uppercase" value={Vehicles} onChange = {(e) => setVehicles(e.target.value)}>
                                            <option value="">-- Select Option --</option>
                                            {VehicleAll.map((item) => {
                                                return (
                                                <option key={item.id} value={item.id}>
                                                   {item.name}
                                                </option>
                                                );
                                           })}
                                         </select>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="caption">HIDE DISCONTINUED VEHICLES</td>
                                    <td>
                                        <input type="checkbox" name="hide_disc" className="text-uppercase" value={HideDescountinuedVehicles} onChange={(e) => { setHideDescountinuedVehicles(!HideDescountinuedVehicles) }} checked={HideDescountinuedVehicles}/>
                                    </td>  
                                  </tr>
                                  <tr>
                                    <td className="caption">MODEL YEAR</td>
                                   
                                    <td>
                                        <select id="manufacture_date" name="manufacture_date" className="text-uppercase" value={ModelYear} onChange = {(e) => setModelYear(e.target.value)}>
                                            <option value="">-- ALL --</option>
                                            {ModelYearAll.map((item) => {
                                                return (
                                                <option key={item.id} value={item.id}>
                                                   {item.year}
                                                </option>
                                                );
                                           })}
                                        </select>
                                    </td>
                                    
                                  </tr>
                                  <tr>
                                    <td className="caption">SEARCH CRITERIA</td>
                                    <td>
                                        <input type="text" name="search_text" className="text-uppercase" value={SearchText} onChange={(e) => { setSearchText(e.target.value) }} /> 
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="caption">VEHICLE CODE</td>
                                    <td>
                                        <input type="text" name="search_code" className="text-uppercase" value={SearchCode} onChange={(e) => { setSearchCode(e.target.value) }}/>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="caption" colSpan={2} style={{textAlign: 'center'}}>
                                        <input type="submit" name="command" value={command} onChange={(e) => { setCommand(e.target.value) }} />&nbsp;
                                        {/* <input type="submit" name="command" value="Reset" onClick={ResetAll} onChange={(e) => { setCommand("Search") }}/> */}
                                    </td>
                                  </tr>
                                
                              </tbody>
                            </table>
                          </td>
                      </tr>

                    </tbody>
                  </table>
              </form>
                  <table width="100%" border="0" cellSpacing={1} cellPadding={1}>
                    <tbody>
                      <tr>
                          <td valign="top" align="center">
                          <table cellSpacing={1} cellPadding={1} className="defaultTbl no_caption_color" width="800">
                              <tbody>
                                    <tr>
                                      <th colSpan={7} style={{textAlign: 'center'}}>VEHICLES</th>
                                    </tr>
                                    <tr>
                                        <td className="caption"></td>
                                        <td className="caption">CODE</td>
                                        <td className="caption">MAKE</td>
                                        <td className="caption">MODEL/FAMILY</td>
                                        <td className="caption">DESCRIPTION</td>
                                        <td className="caption">DATE INTRODUCED</td>
                                        <td className="caption">DATE DISCONTINUED</td>
                                    </tr>

                                             {ProductDetails.length > 0 ?
                                                  ProductDetails.map((item, index) => {
                                                    
                                                  return (
                                                    <tr key={index}>
                                                       
                                                        <td>
                                                          {/* <Link to={"/products/add_to_transaction/command=Edit/module=Transactions/" + item.id + "/" + item.category_id}> */}
                                                          <Link to={"/products/add_to_transaction/command=Edit/module=Transactions/" + item.id + "/0"}>
                                                             <input type="submit" name="select" value="Select"/>
                                                          </Link>
                                                        </td>
                                                        <td>{item.code}</td>
                                                        <td>{item.make}</td>
                                                        <td>{item.model_family}</td>
                                                        <td>{item.description}</td>
                                                        <td>{item.date_introduce}</td>
                                                        <td>{item.date_discontunue}</td>
                                                    </tr>
                                                  );
                                                }) :
                                                
                                                <tr>
                                                     <td colSpan={7} className="text-center"><em>YOUR SEARCH RETURNED NO RESULTS.</em></td>
                                                 </tr>
                                                }

                                    <tr>
                                      <td className="caption" colSpan={7} style={{textAlign: 'center'}}>
                                       {          
                                            TotalRecord > 0 ?
                                              <Pagination
                                                  records={ProductDetails.length}
                                                  link={"products/search/product_type=vehicles/command=search/module=Transactions/products_product_type/0"}
                                                  SelectedPage={page}
                                                  //search={''}
                                                  search={"&search_text=" + SearchText  + "&search_code=" + SearchCode + "&manufacture_date=" + ModelYear + "&hide_disc=" + HideDescountinuedVehicles + "&products_vehicles=" + Vehicles}
                                                  NoOfRecords={TotalRecord}
                                              /> : null
                                          }
                                      </td>
                                    </tr>

                              </tbody>
                          </table>
                          </td>
                      </tr>
                    </tbody>
                  </table>

                </td> 
              </tr>
            </tbody>
          </table>
          <Footer />
        </div>
      </>
    );
}

export default Search;