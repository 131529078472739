import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link } from 'react-router-dom';
import { useParams } from "react-router";

function Branch_receipts() {
    document.title = "Administration | Seriti - Group Admin";
    return (
        <>
            <Admin_menu />
            <form method="post" action="" encType="multipart/form-data" >
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">
                                <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center"><p></p>
                                                <table className="defaultTbl" width="500">
                                                    <tbody>
                                                        <tr>
                                                            <th colspan="2" className="caption text-center">Add Receipt</th>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">BUSINESS MANAGER/MARKETER</td>
                                                            <td><select name="business_manager" id="business_manager">
                                                                <option value="0">-- SELECT MANAGER --</option>$sBusinessManagersOptions
                                                            </select></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Product Category</td>
                                                            <td><select name="product_id" id="product_id">
                                                                <option value="0">-- SELECT CATEGORY --</option>$sProductCategoryOptions
                                                            </select></td>
                                                        </tr>
                                                        <tr id="sub_category_row">
                                                            <td className="caption" id="sub_category_name">$sProductName</td>
                                                            <td><select name="sub_product_id" id="sub_product">
                                                                <option value="0">-- SELECT CATEGORY --</option>$sSubCategoryProductsOptions
                                                            </select></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Amount (Excl VAT)</td>
                                                            <td>$sCurrency<input type="text" name="rollover_amount" id="rollover_amount" value="$fRolloverAmount" className="currency_amount"/></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">VAT Amount</td>
                                                            <td>$sCurrency<input type="hidden" name="rollover_vat" id="rollover_vat" value="$fRolloverVat"/><input type="text" id="rollover_vat_1" disabled="disabled" value="$fRolloverVat" className="currency_amount"/></td>
                                                            </tr>
                                                                <tr>
                                                                    <td className="caption">Total Amount (Incl VAT)</td>
                                                                    <td>$sCurrency<input type="text" name="rollover_total" value="$fRolloverTotal" id="rollover_total" className="currency_amount"/></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="caption">Cost Group</td>
                                                                    <td><input type="radio" name="cost_group" $sOneCheck value="1"/>1&nbsp;<input type="radio" name="cost_group" $sTwoCheck value="2"/>2&nbsp;<input type="radio" name="cost_group" $sThreeCheck value="3"/>3</td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td className="caption">Note</td>
                                                                            <td><textarea name="rollover_notes" cols="40" rows="4">$sRolloverNotes</textarea></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="caption">Receipt Date</td>
                                                                            <td><input type="text" className="date" name="receipt_date" value="$sReceiptDate" id="receipt_date"/></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="caption" colspan="2"><input type="submit" name="command" value="Cancel"/><input type="submit" name="command" value="$sSaveUpdateBtn"/></td>
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr><tr>
                                                                    <td align="center">

                                                                        <table className="defaultTbl">
                                                                            <tbody>
                                                                                <tr><th colSpan="4">Branch Demographics</th></tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </form>
                                </>
                                );
}

                                export default Branch_receipts