import Admin_menu from "../Admin_menu"
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"
import axios from "../../axios";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Financial_products() {
    document.title = "Administration | Seriti - Product Admin";
    const location = useLocation()
    const { type } = location.state //productId
    const { listParent } = location.state //category
    // console.log(type,listParent)
    const [allData, setAllData] = useState([]);
    const { parentId } = location.state
    const [command, setCommand] = useState('')
    const [id, setId] = useState('')
    const [flag, setFlag] = useState(true)

    //----------------------------dropdown of category----------------------------------// 
    useEffect(() => {
        // if (flag) {
        if (listParent !== '' && flag) {
            axios
                .get("financial_products/" + listParent)
                .then((response) => {
                    // console.log(response.data.data)
                    setAllData(response.data.data);
                    setFlag(false);

                })
                .catch((response) => {
                    //alert(response.data);
                });
        }
        // }
    }, [flag, listParent]);

    //----------------------------getting list-------------------------------------//
    const handleSubmit = (e) => {
        e.preventDefault();

        const payload = {
            command: command,
            id: id
        };
        axios
            .post("financial_products_update", payload)
            .then((response) => {
                //console.log(response.data.data)
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                setFlag(true)
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                if (response.response.data.status === 400)
                toast.error(response.response.data.data, {
                    position: toast.POSITION.TOP_CENTER
                });
            });
    };

    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Admin_menu />
            <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                <tbody>
                    <tr valign="top" className="bg-white">
                        <td></td>
                        <td width="100%" className="bg-white">
                            <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                            <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                            <form method="post" action="" onSubmit={handleSubmit}>
                                                <table className="defaultTbl" width="1000" cellSpacing="1">
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan={10} className="text-center">Financial Products</th>
                                                        </tr>
                                                        <tr className="caption"><td></td><td>Groups</td><td>Branches</td><td>Options</td><td>Mappings</td><td>Type</td><td>Category</td><td>Product</td><td></td></tr>
                                                        {
                                                            allData && allData.length ? (
                                                                allData.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <Link to={"/admin/edit_product"} state={{ command: 'Update', type: type, category: listParent, item_id: item.id }}>
                                                                                    <input type="submit" name="command" value="Edit" />
                                                                                </Link>
                                                                                <input type="submit" name="command" value="Copy" onClick={(e) => { setCommand('Copy'); setId(item.id) }} />
                                                                            </td>
                                                                            <td>
                                                                                <Link to={"/admin/option_linking/" + listParent + "/" + item.id} state={{ loc: false, type: "group", parentId: parentId }} >
                                                                                    <input type="submit" name="command" value={item.groups} />
                                                                                </Link>
                                                                            </td>
                                                                            <td>
                                                                                <Link to={"/admin/option_linking/" + listParent + "/" + item.id} state={{ loc: true, type: "branch", parentId: parentId }}>
                                                                                    <input type="submit" name="command" value={item.branches} />
                                                                                </Link>
                                                                            </td>
                                                                            <td>
                                                                                <Link to={"/admin/product_options/" + listParent + "/" + item.id} state={{ loc: true, type: "options", parentId: parentId }}>
                                                                                    <input type="submit" name="command" value={item.options} />
                                                                                </Link>
                                                                            </td>
                                                                            <td>
                                                                                <input type="submit" name="command" value={item.mappings} />
                                                                            </td>
                                                                            <td>{item.type}</td>
                                                                            <td>{item.category}</td>
                                                                            <td>{item.product}</td>
                                                                            <td>
                                                                                {(item.active) ?
                                                                                    <input type="submit" name="command" value="Disable" onClick={(e) => { setCommand('Disable'); setId(item.id) }} /> : <input type="submit" name="command" value="Activate" onClick={(e) => { setCommand('Activate'); setId(item.id) }} />}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : <tr><td colSpan={10}></td></tr>
                                                        }
                                                        <tr className="caption" >
                                                            <td colSpan={10} className="text-center">
                                                                <Link to={"/admin/add_product"} state={{ command: 'Add', type: type, category: listParent, item_id: 0 }}>
                                                                    <input type="submit" name="command" value="Add Product" />
                                                                </Link>

                                                                <Link to={"/admin/products"}>
                                                                    <input type="button" name="command" value="Back" />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </form>
                                            <br />

                                            <p></p>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </td>
                        <td></td>

                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default Financial_products