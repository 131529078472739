import VechileAccessories_menu from "./VechileAccessories_menu"
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"
import { useLocation } from "react-router";
import axios from "../../axios";
import Pagination from "../../Components/Pagination";

function Accessories() {
    document.title = "Products | Seriti - Vehicle & Accessories";
    const [search_text, setSearchText] = useState('');
    const [category, setCategory] = useState('');
    const [allCategory, setAllCategory] = useState([]);
    const [allGroup, setAllGroup] = useState([]);
    const [group, setGroup] = useState('');
    const [branch, setBranch] = useState('');
    const [allBranch, setAllBranch] = useState([]);
    const [searchData, setSearchData] = useState([]);
    // const [isShown, setIsShown] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const [TotalRecords, setTotalRecords] = useState(0);
    var url = new URL(window.location.href);
    const page = url.searchParams.get('PageNo') || 1
    const limit = url.searchParams.get('Limit') || 10
    const [ServiceCall, setServiceCall] = useState(true);
    const [flag, setFlag] = useState(0);

    useEffect(() => {
        setServiceCall(true);
    }, [limit, page, search_text, group, branch, category, flag]);

    //----------------------------dropdown of group----------------------------------// 
    useEffect(() => {
        //console.log(collection);
        axios
            .get("index/group")
            .then((response) => {
                // console.log(response)
                setAllGroup(response.data.data);
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, []);

    //------------------dropdown of branch depends on group---------------------------// 
    useEffect(() => {
        if (group !== '') {
            axios
                .get("index/branch/" + group)
                .then((response) => {
                    // console.log(response)
                    setAllBranch(response.data.data);
                })
                .catch((response) => {
                    //alert(response.data);
                });
        }
    }, [group]);

    //----------------------------dropdown of product----------------------------------// 
    useEffect(() => {
        axios
            .get("/product/dropdown")
            .then((response) => {
                // console.log(response.data.data)
                setAllCategory(response.data.data);
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, []);

    //-----------------------------search data with pagination-------------------------// 
    useEffect(() => {
        if (ServiceCall && page) {
            // alert('sdsd');
            const payload = {
                division: group,
                department: branch,
                search_text: search_text,
                category: category,
                limit: limit,
                page: page,
            };
            axios
                .post("/product/accessories", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        setTotalRecords(response.data.total_record)
                        if (location.state) {
                            setSearchData(location.state.data);
                            setTotalRecords(location.state.total_record)
                        } else {
                            setSearchData(response.data.data);
                        }
                    }
                })
                .catch((response) => {
                });
        }
    }, [ServiceCall, page, limit, search_text, group, branch, category, flag, location])
    
    //-----------------------------search data----------------------------------------// 
    const handleSubmit = (e) => {
        // setGroup(group)
        e.preventDefault();

        const payload = {
            division: group,
            department: branch,
            search_text: search_text,
            category: category,
            limit: limit,
            page: page,
        };
        axios
            .post("/product/accessories", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    if (response.data.status === 200) {
                        setTotalRecords(response.data.total_record)
                        navigate('/products/accessories', { state: response.data });
                    }
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
            });

    };

    useEffect(() => {
        if (location.state) {
            setSearchData(location.state.data);
            setTotalRecords(location.state.total_record);
            navigate("/products/accessories", {
                state: null,
            });
        }
    }, [location, navigate]);

    //   console.log(TotalRecords);
    return (
        <>
            <VechileAccessories_menu />

            <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                <tbody>
                    <tr valign="top" className="bg-white">
                        <td></td>
                        <td width="100%" className="bg-white">
                            <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                            <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                            <form method="post" action="" onSubmit={handleSubmit} >
                                                <table className="defaultTbl" width="500" cellSpacing="1">
                                                    <tbody>
                                                        <tr><th colSpan={2} className="text-center">Search for an Accessory</th></tr>

                                                        <tr><td className="caption">GROUP</td><td>
                                                            <select name="division" id="division" className="text-uppercase" value={group} onChange={(e) => setGroup(e.target.value)} style={{ width: '382px' }}>
                                                                <option value="0">-- Select a group --</option>
                                                                {allGroup.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id} >
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </td>
                                                        </tr>

                                                        <tr><td className="caption">Branch</td><td>
                                                            <select name="department" id="department" className="text-uppercase" value={branch} onChange={(e) =>
                                                                setBranch(e.target.value)} style={{ width: '382px' }}>
                                                                {/* onChange = {(e) => setBranch(e.target.value)} */}
                                                                <option value="0">-- Select a group First --</option>
                                                                {allBranch.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id} >
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </td></tr>

                                                        <tr><td className="caption">Product Type</td><td>
                                                            <select name="category" id="category" className="text-uppercase" value={category} onChange={(e) =>
                                                                setCategory(e.target.value)} style={{ width: '382px' }}>
                                                                {/* onChange = {(e) => setBranch(e.target.value)} */}
                                                                <option value="0">-- Select option --</option>
                                                                {allCategory.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id} >
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </td></tr>

                                                        <tr>
                                                            <td className="caption">Search Criteria</td>
                                                            <td>
                                                                <input type="text" name="search_text" className="text-uppercase" value={search_text} onChange={(e) => setSearchText(e.target.value)} />
                                                            </td>
                                                        </tr>

                                                        <tr className="caption">
                                                            <td colSpan={2} style={{ textAlign: "center" }}>
                                                                <input type="submit" name="command" value="Search" />
                                                            </td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </form>

                                            <br />
                                            {/* {isShown && ( */}
                                            <form method="post">
                                                <table className="defaultTbl" width="800" >
                                                    <tbody>
                                                        <tr><th colSpan="4">Accessories</th></tr>
                                                        <tr className="caption"><td>&nbsp;</td><td>&nbsp;</td><td width="90%">Product</td><td>&nbsp;</td></tr>
                                                        {
                                                            searchData && searchData.length && TotalRecords > 0 ? (
                                                                searchData.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <Link to={"/products/accessory_admin/" + item.id + "/" + item.template_id}>
                                                                                    <input type="button" value="Admin" />
                                                                                </Link>
                                                                            </td>
                                                                            <td>

                                                                            </td>
                                                                            <td>{item.name}</td>
                                                                            <td>
                                                                                <Link to={"/products/add_edit_accessories/" + item.id}>
                                                                                    <input type="button" value="Edit" />
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : <tr><td colSpan={4}>"Your search returned no results."</td></tr>
                                                        }
                                                        <tr><td colSpan={4} width="90%" className="caption" style={{ textAlign: "center" }}>
                                                            <Link to={"/products/add_edit_accessories/0"}>
                                                                <input type="button" value="Add Accessories" />
                                                            </Link>
                                                        </td></tr>


                                                        <tr><td colSpan={4} width="90%" style={{ textAlign: "center" }}>
                                                            {
                                                                TotalRecords > 0 ?
                                                                    <Pagination
                                                                        records={searchData.length}
                                                                        link={"/products/accessories"}
                                                                        SelectedPage={page}
                                                                        //search={''}
                                                                        search={"&search_text=" + search_text + "&division=" + group + "&department=" + branch + "&category=" + category}
                                                                        NoOfRecords={TotalRecords}
                                                                    /> : null
                                                            }
                                                        </td></tr>

                                                    </tbody>
                                                </table>
                                            </form>

                                            {/* )} */}

                                        </td>
                                        <td>

                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </td>
                        <td></td>

                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default Accessories