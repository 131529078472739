import { Link, useNavigate, useParams } from "react-router-dom"
import React, { useState, useEffect } from "react";
import Template from "../Components/Template";
import axios from "../axios";
//import { confirmAlert } from 'react-confirm-alert';

const Create_new = () => {

    const navigate = useNavigate();
    const [Newbranch, setNewbranch] = useState(80);
    const [NewbranchAll, setNewbranchAll] = useState([]);
    const [BranchName, setBranchName] = useState("");
    const [command, setCommand] = useState("Yes");
    const [clientId, setclientId] = useState("");
    const [old_transaction_id, setoldTransactionId] = useState("");
    const [keep_vehicle, setkeepVehicle] = useState("1");
    const [Transcationmsg, setTranscationmsg] = useState("");

    const user_id = localStorage.getItem("UserId")
    const super_user = localStorage.getItem("super_user")
    const department_id = localStorage.getItem("department_id")
    const division_id = localStorage.getItem("division_id")
    const system_type = localStorage.getItem("system_type")
    // const [routeParams, setkeepVehicle] = useState("1");
    const url_data = useParams();

    //console.log(url_data.client_id)   
    //----------- get branch -----------------
    useEffect(() => {
        axios
            .get("/search/show_branch/" + user_id + '/' + super_user)
            .then((response) => {
                // console.log(response.data);
                setNewbranchAll(response.data.data);
            })
            .catch(() => {
                //setLoader(false);
            });
    }, []);

    //----------------- show branch name ----------------
    useEffect(() => {
        axios
            .get("/search/show_branch_name/" + user_id + '/' + super_user + '/' + Newbranch)
            .then((response) => {
                //console.log(response.data);
                setBranchName(response.data);
            })
            .catch(() => {
                //setLoader(false);
            });
    }, [Newbranch]);

    //--------------------------- create new transcation --------------------
    const createTranscation = () => {
        //alert('sas');
        const payload = {
            user_id: user_id,
            command: command,
            client_id: url_data.client_id,
            old_transaction_id: url_data.old_transaction_id,
            keep_vehicle: keep_vehicle,
            department_id: department_id,
            req_department_id: Newbranch,
            division_id: division_id,
            system_type: system_type,
            copy_client_id: url_data.client_id,
            copy_old_transaction_id: url_data.old_transaction_id,
        };
       // console.log(payload);
        axios
           .post("search/add_new_transcation", payload)
            .then((response) => {
                //console.log(response);
                localStorage.setItem("division_id", response.data.data.division_id);
                localStorage.setItem("department_id", response.data.data.department_id);
                navigate('/transaction/create_transaction/' +response.data.data.transaction_no);
            })
            .catch(() => {
                console.log('error');
            });
    }

    //------------ show transcation body message --------------
    useEffect(() => {
        const payload = {
            old_transaction_id: old_transaction_id,
            copy_client_id: url_data.client_id,
            copy_old_transaction_id: url_data.old_transaction_id,
        };
        //console.log(payload);
        axios
            .post("search/show_transcation_message", payload)
            .then((response) => {
                //console.log(response.data);
                setTranscationmsg(response.data)
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    return (
        <>
            <Template />
            <div>

                <table width="100%" height="13" border="0" cellPadding={0} cellSpacing={0} className="custom-icon-menu-shadow ">
                    <tbody>
                        <tr>
                            <td width="100%" className="custom-shadow-img"></td>
                        </tr>
                    </tbody>
                </table>

                <table width="100%" height="1024" id="mainContainer" border="0" cellPadding={0} cellSpacing={0} className="custom-body bg-white">
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">
                                <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                                <table width="100%" border="0" cellPadding={5} cellSpacing={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center">
                                                <form method="post" name="new_transcation" >
                                                    <input type="hidden" name="client_id" value={clientId} onChange={(e) => { setclientId(e.target.value) }} />
                                                    <input type="hidden" name="command" value={command} onChange={(e) => { setCommand(e.target.value) }} />
                                                    <input type="hidden" name="old_transaction_id" value={old_transaction_id} onChange={(e) => { setoldTransactionId(e.target.value) }} />
                                                    <input type="hidden" name="keep_vehicle" value={keep_vehicle} onChange={(e) => { setkeepVehicle(e.target.value) }} />
                                                    <table width="750" className="defaultTbl" align="center" cellSpacing={1}>
                                                        <tbody>
                                                            <tr>
                                                                <th colSpan={2} style={{ textAlign: 'center' }}>Create New Transaction</th>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }} colSpan={2}>
                                                                    PLEASE SPECIFY THE BRANCH TO CREATE THE TRANSACTION IN: &nbsp;<select name="department_id" id="department_id" value={Newbranch} onChange={(e) => setNewbranch(e.target.value)} style={{ width: '288px' }}>
                                                                        {NewbranchAll.map((item) => {
                                                                            return (
                                                                                <option key={item.id} value={item.id}>
                                                                                    {item.name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'center' }} colSpan={2}>

                                                                    {Transcationmsg.check_flag == 'create_user' ? (
                                                                        <div>
                                                                            YOU ARE ABOUT TO CREATE A NEW TRANSACTION WHICH WILL ALSO CREATE A NEW CLIENT ON THE SYSTEM.
                                                                            <br /><br />
                                                                            DO YOU WANT TO CONTINUE?
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            YOU ARE ABOUT TO CREATE A NEW TRANSACTION UNDER THE EXISTING CLIENT <strong>
                                                                                {Transcationmsg.cname}</strong> WITH ID NUMBER <strong>
                                                                                {Transcationmsg.cregister_number}</strong>
                                                                            <br /><br />
                                                                            DO YOU WANT TO CONTINUE?
                                                                        </div>

                                                                    )}
                                                                </td>
                                                            </tr>

                                                            <tr className="caption">
                                                                <td style={{ textAlign: 'center' }}>
                                                                    <Link to={`#`} onClick={() => { if (window.confirm('ARE YOU SURE YOU WISH TO CREATE A NEW TRANSACTION IN ' + BranchName + '?')) { createTranscation() } }}>
                                                                        <input type="button" name="command" value="Yes" /></Link>
                                                                </td>
                                                                <td style={{ textAlign: 'center' }}>
                                                                    <Link to="/home">
                                                                        <input type="button" name="command" value="No" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </form>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>


            </div>
        </>
    );

}

export default Create_new