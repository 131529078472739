import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link } from 'react-router-dom';
import { useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Branch_defaults() {
    document.title = "Administration | Seriti - Group Admin";
    const { division } = useParams();
    const { department } = useParams();
    const [branch_details, setsBranchDetails] = useState([])
    const [branch_settings, setBranchSettings] = useState([])
    const [financier, setFinancier] = useState([])
    const [products, setsProducts] = useState([])
    const [sDepartment, setsDepartment] = useState([])
    const [sDivision, setsDivision] = useState([])
    const [users_in_branch, setsUsersInBranch] = useState([])
    const [users_with_access, setsUsersWithAccess] = useState([])
    const [branchName, setBranchName] = useState('')
    const [sDivisionName, setsDivisionName] = useState('')
    const [isShown, setIsShown] = useState(false);
    const [Flag, setFlag] = useState(true);
    const [user, setUser] = useState([])
    const [newFinancier, setNewFinancier] = useState([])

    const handleChange = (index, event) => {
        // console.log(index)
        let data = [...financier];
        data[index][event.target.name] = event.target.value;
        setNewFinancier(data);
    };
    // console.log(newFinancier);
    //-----------------------------view branch-------------------------------//
    useEffect(() => {
        const payload = {
            division: division,
            department: department
        };
        if (Flag === true) {
            axios
                .post("/admin/affiliates/view_branch", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data.data)
                        setsBranchDetails(response.data.data.branch_details)

                        var obj = response.data.data.branch_settings
                        setBranchSettings(response.data.data.branch_settings);
                        setFinancier(response.data.data.financier)
                        setsProducts(response.data.data.products)
                        setsDepartment(response.data.data.sDepartment);
                        setsDivision(response.data.data.sDivision)
                        setsUsersInBranch(response.data.data.users_in_branch);
                        setsUsersWithAccess(response.data.data.users_with_access)
                        setBranchName(response.data.data.branchName)
                        setsDivisionName(response.data.data.sDivisionName)
                        if ("sScheme" in obj) setIsShown(true)

                        setFlag(false)
                        // console.log("sScheme" in obj);
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    alert(response.response.data.data);
                });
        }
    }, [Flag, division, department]);
    // console.log(financier);

    useEffect(() => {
        const payload = {
            user: user
        };
        if (user.length > 0) {
            axios
                .post("/admin/group_admin/users_branch_update", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data.data)
                        setFlag(true)
                        setUser([])
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    alert(response.response.data.data);
                });
        }
    }, [user]);

    //------------------------------handle edit-----------------------------------//
    const handleEdit = (e) => {
        e.preventDefault();
        const payload = {
            newFinancier: newFinancier
        };
        axios
            .post("/admin/group_admin/financiers_update", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data.data)
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setFlag(true)
                    setUser([])
                    setNewFinancier('')
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                if (response.response.data.status === 400)
                    toast.error(response.response.data.data, {
                        position: toast.POSITION.TOP_CENTER
                    });
            });
    };

    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Admin_menu />
            <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body">
                <tbody>
                    <tr valign="top">
                        <td></td>
                        <td width="100%">
                            <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                            <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                            <table className="defaultTbl" width="600" cellSpacing="1">
                                                <tbody>
                                                    <tr>
                                                        <th colSpan="4" className="text-center">{sDepartment} Settings for {branchName}</th>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">Display Logo</td>
                                                        <td colSpan="3"><img height="92" src={branch_settings.logo} width="130" alt="" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">Name</td>
                                                        <td>{branchName}</td>
                                                        <td className="caption">Code</td>
                                                        <td>{branch_settings.code}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">Group</td>
                                                        <td>{sDivisionName}</td>
                                                        <td className="caption">Use Electronic Signature</td>
                                                        <td>{branch_settings.significant}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">Default Finance Term</td>
                                                        <td>{branch_settings.finance_term}</td>
                                                        <td className="caption">Default Prime Adjustment</td>
                                                        <td>{branch_settings.prime_adjustment}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">Start Date</td>
                                                        <td>{branch_settings.start_date}</td>
                                                        <td className="caption">End Date</td>
                                                        <td>{branch_settings.end_date}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">IGF Number</td>
                                                        <td>{branch_settings.igf_number}</td>
                                                        <td className="caption">{branch_settings.sPrincipalName}</td>
                                                        <td>{branch_settings.dealer_principle}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">Financial Manager</td>
                                                        <td>{branch_settings.financial_manager}</td>
                                                        <td className="caption">Approved User</td>
                                                        <td>{branch_settings.approved_user}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">BUSINESS MANAGER/MARKETER</td>
                                                        <td>{branch_settings.marketer}</td>
                                                        <td className="caption">Dispute Facilitator</td>
                                                        <td>{branch_settings.dispute_facilitator}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">FSP Representative</td>
                                                        <td>{branch_settings.fsp_rep_name}</td>
                                                        <td className="caption">&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">FSP Complaints Officer</td>
                                                        <td>{branch_settings.fsp_complaints}</td>
                                                        <td className="caption">FSP Compliance Officer</td>
                                                        <td>{branch_settings.fsp_compliance}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">Professional Indemnity</td>
                                                        <td>{branch_settings.indemnity}</td>
                                                        <td className="caption">Fidelity Gurantee</td>
                                                        <td>{branch_settings.fidelity}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">Default Vehicle Coding System</td>
                                                        <td>{branch_settings.vehicle_coding_system}</td>
                                                        <td className="caption">&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    {
                                                        isShown && (
                                                            <tr>
                                                                <td className="caption">Scheme</td>
                                                                <td>{branch_settings.sScheme}</td>
                                                                <td className="caption">Web Service File</td>
                                                                <td>{branch_settings.web_service_file}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    <tr className="caption">
                                                        <td colSpan="4" className="text-center">
                                                            <Link to={'/admin/group_admin/edit_branch/' + division + '/' + department}>
                                                                <input type="button" value="Edit Settings" />
                                                            </Link>

                                                            <Link to={'/admin/group_admin/apl_index/' + department} state={{ division: division }}>
                                                                <input type="button" value="APLs" />
                                                            </Link>
                                                            <Link to={'/admin/group_admin/branch_receipts/' + department}>
                                                                <input type="button" value="Receipts" />
                                                            </Link>
                                                            <Link to={'/admin/group_admin/group_defaults/' + division}>
                                                                <input type="button" value="Back" /></Link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br /><br />

                                            <table className="defaultTbl" width="600" cellSpacing="1">
                                                <tbody>
                                                    <tr>
                                                        <th colSpan="4" className="text-center">{sDepartment} Details for {branchName}</th>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">Company Name</td>
                                                        <td>{branch_details.name}</td>
                                                        <td className="caption">Company Abbreviation</td>
                                                        <td>{branch_details.abbreviation}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">Merchant Name</td>
                                                        <td>{branch_details.merchant_name}</td>
                                                        <td className="caption">Merchant Code</td>
                                                        <td>{branch_details.merchant_code}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">Registration Number</td>
                                                        <td>{branch_details.company_registration_number}</td>
                                                        <td className="caption">VAT Number</td>
                                                        <td>{branch_details.vat_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">Physical Address</td>
                                                        <td>{branch_details.physical_address}</td>
                                                        <td className="caption">Postal Address</td>
                                                        <td>{branch_details.postal_address}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">Office Number</td>
                                                        <td>{branch_details.office_number}</td>
                                                        <td className="caption">Fax Number</td>
                                                        <td>{branch_details.fax_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">Web Address</td>
                                                        <td>{branch_details.website_address}</td>
                                                        <td className="caption">Email Address</td>
                                                        <td>{branch_details.email}</td>
                                                    </tr>
                                                    <tr className="caption">
                                                        <td colSpan="4" className="text-center">Banking Details</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">Bank</td>
                                                        <td>{branch_details.bank}</td>
                                                        <td className="caption">Branch Code</td>
                                                        <td>{branch_details.branch_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">Account Holder</td>
                                                        <td>{branch_details.account_name}</td>
                                                        <td className="caption">Account Number</td>
                                                        <td>{branch_details.account_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">Account Type</td>
                                                        <td>{branch_details.account_type}</td>
                                                        <td className="caption">&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    <tr className="caption">

                                                        <td colSpan="4" className="text-center">
                                                            <Link to={'/admin/group_admin/branch_details/' + division + '/' + department} >
                                                                <input type="button" value="Edit Details" /></Link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br /><br />

                                            <table className="defaultTbl" width="600" cellSpacing="1">
                                                <tbody>
                                                    <tr>
                                                        <th colSpan="4" className="text-center">Users in the {sDepartment}: {branchName}</th>
                                                    </tr>
                                                    <tr className="caption">
                                                        <td width="1">&nbsp;</td>
                                                        <td>Name</td>
                                                        <td>Email</td>
                                                        <td width="1">&nbsp;</td>
                                                    </tr>
                                                    {

                                                        users_in_branch && users_in_branch.length ? (
                                                            users_in_branch.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <Link to={'/admin/profile/' + item.id}>
                                                                                <input type="button" value="View" />
                                                                            </Link>
                                                                        </td>
                                                                        <td>{item.fullname}</td>
                                                                        <td>{item.email}</td>
                                                                        <td><input type="button" value={item.sButtonDisEnableText} onClick={(e) => { setUser([item.id, item.sButtonDisEnableText]) }} /></td>
                                                                    </tr>
                                                                );
                                                            })
                                                        ) : <tr><td colSpan={4}><em>"No Users found for this branch."</em></td></tr>
                                                    }
                                                    <tr className="caption">
                                                        <td colSpan="4">&nbsp;</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />

                                            <table className="defaultTbl" width="600" cellSpacing="1">
                                                <tbody>
                                                    <tr>
                                                        <th colSpan="4" className="text-center">Users with Access to {sDepartment}: {branchName}</th>
                                                    </tr>
                                                    <tr className="caption">
                                                        <td width="1">&nbsp;</td>
                                                        <td>Name</td>
                                                        <td>Email</td>
                                                        <td width="1">&nbsp;</td>
                                                    </tr>
                                                    {
                                                        users_with_access && users_with_access.length ? (
                                                            users_with_access.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <Link to={'/admin/profile/' + item.id}>
                                                                                <input type="button" value="View" />
                                                                            </Link>
                                                                        </td>
                                                                        <td>{item.fullname}</td>
                                                                        <td>{item.email}</td>
                                                                        <td><input type="button" value={item.sButtonDisEnableText} onClick={(e) => { setUser([item.id, item.sButtonDisEnableText]) }} /></td>
                                                                    </tr>
                                                                );
                                                            })
                                                        ) : <tr><td colSpan={4}><em>"No Users found for this branch."</em></td></tr>
                                                    }

                                                    <tr className="caption">
                                                        <td colSpan="4">&nbsp;</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />

                                            <table className="defaultTbl" width="700" cellSpacing="1">
                                                <tbody>
                                                    <tr>
                                                        <th colSpan="5" className="text-center">Products linked to {sDepartment}: {branchName}</th>
                                                    </tr>
                                                    <tr className="caption">
                                                        <td width="1">&nbsp;</td>
                                                        <td>Product</td>
                                                        <td className="text-center">Start Date</td>
                                                        <td className="text-center">End Date</td>
                                                        <td>Notes</td>
                                                    </tr>
                                                    {
                                                        products && products.length ? (
                                                            products.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td width="1">&nbsp;</td>
                                                                        <td>{item.product}</td>
                                                                        <td className="text-center">{item.start_date}</td>
                                                                        <td className="text-center">{item.end_date}</td>
                                                                        <td>{item.notes}</td>
                                                                    </tr>
                                                                );
                                                            })
                                                        ) : <tr><td colSpan={5}><em>"No Products linked to this branch."</em></td></tr>
                                                    }

                                                    <tr className="caption">
                                                        <td colSpan="5">&nbsp;</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <form method="post" action="" onSubmit={handleEdit} encType="multipart/form-data">
                                                <table className="defaultTbl" cellSpacing="1">
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan="4" className="text-center">Available Financiers</th>
                                                        </tr>
                                                        <tr className="caption">
                                                            <td>&nbsp;</td>
                                                            <td>Financier</td>
                                                            <td>Priority</td>
                                                            <td>Web Service File</td>
                                                        </tr>
                                                        {
                                                            financier && financier.length ? (
                                                                financier.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td width="1">&nbsp;</td>
                                                                            <td>{item.name}</td>

                                                                            <td className="text-center"><input type="text" name="priority" value={item.priority} size="2" className="text-uppercase" onChange={event => handleChange(index, event)} /></td>

                                                                            <td className="text-center"><input type="text" nouppercase="1" name="web_service_file" value={item.web_service_file} className="text-none" onChange={event => handleChange(index, event)} /></td>
                                                                        </tr >
                                                                    );
                                                                })
                                                            ) : <tr><td colSpan={4}><em>"There are no financiers avalaible to this branch."</em></td></tr>
                                                        }

                                                        <tr className="caption">
                                                            <td colSpan="4" className="text-center"><input type="submit" value="Update Priorities" name="command" onClick={handleEdit} /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </form>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </>
    );

}

export default Branch_defaults