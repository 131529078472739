import VechileAccessories_menu from "./VechileAccessories_menu"
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom"
import axios from "../../axios";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Add_edit_accessories() {
    document.title = "Products | Seriti - Vehicle & Accessories";
    const { product_id } = useParams();
    // console.log(product_id);
    const [heading, setHeading] = useState('Add a New Accessory')
    const [btn, setBtn] = useState('Add')
    const [command, setCommand] = useState('Add')
    // (product_id === 0) ? setHeading("Add a New Accessory") : setHeading("Edit : "+productName)
    const [productName, setProductName] = useState('')
    const [category, setCategory] = useState('');
    const [allCategory, setAllCategory] = useState([]);
    const [code, setCode] = useState('');
    const [costPrice, setCostPrice] = useState('');
    const [selling_price, setSellingPrice] = useState('')
    const [template, setTemplate] = useState('');
    const [allTemplate, setAllTemplate] = useState([]);
    const [Tangible, setTangible] = useState(false);

    const [allGroup, setAllGroup] = useState([]);
    const [group, setGroup] = useState('');
    const [branch, setBranch] = useState('');
    const [allBranch, setAllBranch] = useState([]);

    const [isShown, setIsShown] = useState(false);

    const [vehicles, setVehicles] = useState('');
    const [allVehicles, setAllVehicles] = useState([]);
    // console.log(category);

    const handleChange = (vahicle) => {
        // alert(e);
        if (vahicle === '1037') setIsShown(true)
        else setIsShown(false)
    }
    //----------------------------fetch accessories data----------------------------------// 
    useEffect(() => {
        axios
            .get("/product/get/0")
            .then((response) => {
                //console.log(response.data.data)
                var obj = response.data.data
                setAllTemplate(obj)
                    (product_id === 0) ? setHeading("Add a New Accessory") : setHeading()
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, [product_id]);


    //----------------------------fetch accessories data----------------------------------// 
    useEffect(() => {
        if (product_id > 0) {
            axios
                .get("/product/get/accessories/" + product_id)
                .then((response) => {
                    // console.log(response.data.data[0])
                    var obj = response.data.data[0]
                    setProductName(obj.name)
                    setCategory(obj.category_id)
                    setCostPrice(obj.cost_price);
                    setSellingPrice(obj.selling_price)
                    setTemplate(obj.template_id)
                    setGroup(obj.division_id)
                    setBranch(obj.department_id)
                    setBtn("Save")
                    setCommand("Save")
                    setHeading("Edit : ")
                    setCode(obj.code)
                    setTangible(obj.sActiveChecked)
                    setHeading("Edit : " + obj.name)

                })
                .catch((response) => {
                    //alert(response.data);
                });
        }
    }, [product_id]);

    //----------------------------dropdown of product----------------------------------// 
    useEffect(() => {
        //console.log(collection);
        axios
            .get("/product/dropdown")
            .then((response) => {
                // console.log(response.data.data)
                setAllCategory(response.data.data);
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, []);

    //----------------------------dropdown of group----------------------------------// 
    useEffect(() => {
        //console.log(collection);
        axios
            .get("index/group")
            .then((response) => {
                // console.log(response)
                setAllGroup(response.data.data);
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, []);

    //------------------dropdown of branch depends on group---------------------------// 
    useEffect(() => {
        if (group !== '') {
            axios
                .get("index/branch/" + group)
                .then((response) => {
                    // console.log(response)
                    setAllBranch(response.data.data);
                })
                .catch((response) => {
                    //alert(response.data);
                });
        }
    }, [group]);
    //---------------------handle validation------------------------//
    const handleValidation = () => {
        var flag = true;
        var regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
        // const regexp = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/
        if (productName === '') {
            alert('Please enter name at least one character.');
            flag = false;
        } else if (costPrice === '' || selling_price === '') {
            alert('Please enter a valid amount.');
            flag = false;
        } else if (!regexp.test(costPrice)) {
            alert('Please enter a valid amount.');
            flag = false;
        } else if (!regexp.test(selling_price)) {
            alert('Please enter a valid amount.');
            flag = false;
        }
        return flag;
    };

    //---------------------------------edit data--------------------------------------//
    // console.log(template)
    const handleSubmit = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        if (flag) {
            const payload = {
                // user_id : UserId,
                command: command,
                product_id: product_id,
                category: category,//product type
                name: productName,
                cost_price: costPrice,
                selling_price: selling_price,
                template: template,
                code: code,
                division: group,
                department: branch,
                tangible: Tangible ? 1 : 0,
            };
            axios
                .post("product/add_edit_accessories", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data.data);
                        //alert(response.data.message)
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    if (response.response.data.status === 400)
                    toast.error(response.response.data.data, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    };


    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <VechileAccessories_menu />

            <form method="post" action="" onSubmit={handleSubmit} >
                <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                    <tbody>
                        <tr valign="top" className="bg-white">
                            <td></td>
                            <td width="100%" className="bg-white">
                                <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                                <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center">

                                                <table className="defaultTbl" width="500" cellSpacing="1">
                                                    <tbody>
                                                        <tr><th colSpan={2} className="text-center">{heading}</th></tr>
                                                        <tr>
                                                            <td width="30%" className="caption">Name<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="text" name="name" className="text-uppercase" value={productName} size="60" validate="text" onChange={(e) => setProductName(e.target.value)} /></td>
                                                        </tr>

                                                        <tr><td className="caption">Product Type</td><td>
                                                            <select name="category" id="category" className="text-uppercase" value={category} onChange={(e) => { setCategory(e.target.value); handleChange(e.target.value) }} style={{ width: '382px' }} >
                                                                {/* onChange = {(e) => setBranch(e.target.value)} */}
                                                                <option value="0">-- Select option --</option>
                                                                {allCategory.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id} >
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </td></tr>
                                                        {
                                                            isShown && (
                                                                <tr><td className="caption">VEHICLES</td><td>
                                                                    <select name="vehicles" id="vehicles" className="text-uppercase" value={vehicles} onChange={(e) => { setVehicles(e.target.value); handleChange(e.target.value) }} style={{ width: '382px' }} >
                                                                        {/* onChange = {(e) => setBranch(e.target.value)} */}
                                                                        <option value="0">-- Select option --</option>
                                                                        {/* {allCategory.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id} >
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })} */}
                                                                    </select>
                                                                </td></tr>
                                                            )}



                                                        <tr><td className="caption">GROUP</td><td>
                                                            <select name="division" id="division" className="text-uppercase" value={group} onChange={(e) => setGroup(e.target.value)} style={{ width: '382px' }}>
                                                                <option value="0">-- Select a collection first --</option>
                                                                {allGroup.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id} >
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </td>
                                                        </tr>

                                                        <tr><td className="caption">Branch</td><td>
                                                            <select name="department" id="department" className="text-uppercase" value={branch} onChange={(e) =>
                                                                setBranch(e.target.value)} style={{ width: '382px' }}>
                                                                {/* onChange = {(e) => setBranch(e.target.value)} */}
                                                                <option value="0">-- Select a group First --</option>
                                                                {allBranch.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id} >
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </td></tr>

                                                        <tr><td className="caption">Code</td>
                                                            <td><input type="text" name="code" className="text-uppercase" value={code} validate="currency" onChange={(e) => setCode(e.target.value)} /></td>
                                                        </tr>

                                                        <tr><td className="caption">Cost Price<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="text" name="cost_price" className="text-uppercase" value={costPrice} validate="currency" onChange={(e) => setCostPrice(e.target.value)} /></td>
                                                        </tr>

                                                        <tr><td className="caption">Selling Price<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="text" name="selling_price" className="text-uppercase" value={selling_price} validate="currency" onChange={(e) => setSellingPrice(e.target.value)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Template</td>
                                                            <td>
                                                                <select name="template" id="template" className="text-uppercase" value={template} onChange={(e) =>
                                                                    setTemplate(e.target.value)} style={{ width: '382px' }}>
                                                                    {/* onChange = {(e) => setBranch(e.target.value)} */}
                                                                    <option value="0">-- Select option --</option>
                                                                    {allTemplate.map((item) => {
                                                                        return (
                                                                            <option key={item.value} value={item.value} >
                                                                                {item.text}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Tangible</td>
                                                            <td>
                                                                <input type="checkbox" value={Tangible} name="active" checked={Tangible} onChange={(e) => { setTangible(!Tangible) }} />
                                                                (CAN WE PHYSICALLY RUN OUT OF STOCK?)
                                                            </td>
                                                        </tr>

                                                        <tr className="caption">
                                                            <td colSpan="2" className="text-center">
                                                                <input type="submit" name="command" value={btn} />
                                                                <Link to={"/products/accessories"} >
                                                                    <input type="button" name="cancel" value="Cancel" />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <p></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    )
}

export default Add_edit_accessories