
import Admin_menu from "../Admin_menu"
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"
import axios from "../../axios";
import { useParams } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

function Option_linking_edit() {
    document.title = "Administration | Seriti - Product Admin";
    const navigate = useNavigate();
    const { linkId } = useParams();
    const { productId } = useParams();

    const location = useLocation()
    const { listParent } = location.state
    const { loc } = location.state
    const { type } = location.state
    // console.log(listParent,loc,type);
    
    const [allGroup, setAllGroup] = useState([]);
    const [group, setGroup] = useState('');

    const [productName, setproductName] = useState('')

    const [start_date, setstart_date] = useState('')
    const [end_date, setend_date] = useState('')

    const [startFlag, setStartFlag] = useState(1)
    const [endFlag, setEndFlag] = useState(1)

    //for tab branches
    const [branch, setBranch] = useState('');
    const [allBranch, setAllBranch] = useState([]);

    //-------------------------dropdown of group--------------------------------// 
    useEffect(() => {
        axios
            .get("index/group")
            .then((response) => {
                // console.log(response)
                setAllGroup(response.data.data);
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, []);

    //------------------dropdown of branch depends on group-----------------// 
    useEffect(() => {
        if (group !== '') {
            axios
                .get("index/branch/" + group)
                .then((response) => {
                    // console.log(response)
                    setAllBranch(response.data.data);
                })
                .catch((response) => {
                    //alert(response.data);
                });
        }
    }, [group]);

    //-----------------------fetch data of linkId--------------------------------// 
    useEffect(() => {
        if (productId !== '' && linkId !== '') {
            axios
                .get("fetch_edit/" + productId + "/" + linkId)
                .then((response) => {
                    // console.log(response.data.data.product)
                    var obj = response.data.data.aLink[0];
                    setproductName(response.data.data.product)
                    setGroup(obj.group_id);
                    setstart_date(obj.start_date);
                    setend_date(obj.end_date)
                    setBranch(obj.branch_id)
                    if (obj.start_date === '') setStartFlag(0);
                    if (obj.end_date === '') setEndFlag(0);
                })
                .catch((response) => {
                    //alert(response.data);
                });
        }
    }, [productId, linkId]);

     //-----------------------------compare start date and end date-------------------//
    const compareDates = (d1, d2) => {
        var flag = true;
        let date1 = new Date(d1).getTime();
        let date2 = new Date(d2).getTime();
        if (date1 > date2) {
            //console.log(`${start_date} is greater than ${end_date}`);
            alert('Start date is greater than End date. Please enter Valid Dates');
            setend_date('');//setstart_date('')
            flag = false;
        } 
        return flag;
    }
    //----------------------------edit group-------------------------------------//
    const handleEdit = (e) => {
        e.preventDefault();
        const flag = compareDates(start_date,end_date);
        //console.log(flag)
        if (flag) {
            const payload = {
                command: "Update",
                division: group,
                department: branch,
                start_date: start_date !== '' ? moment(start_date).format("YYYY-MM-DD") : 0,
                end_date: end_date !== '' ? moment(end_date).format("YYYY-MM-DD") : 0,
                product_id: productId,
                linkId: linkId,
                listParent: listParent,
                type: type
            };
            // console.log(payload)
            axios
                .post("open_linking/add", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        if (response.data.data.type === 'group')
                            navigate('/admin/option_linking/' + listParent + '/' + productId, { state: { listParent: listParent, loc: false, type: 'group' } })
                        else
                            navigate('/admin/option_linking/' + listParent + '/' + productId, { state: { listParent: listParent, loc: true, type: 'branch' } })
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    // console.log("response : "+response.data);
                    if (response.response.data.status === 400) {
                        alert(response.response.data.data);
                    }
                });
        }
    };

    return (
        <>
            <Admin_menu />
            <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                <tbody>
                    <tr valign="top" className="bg-white">
                        <td></td>
                        <td width="100%" className="bg-white">
                            <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                            <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                            <form method="post" action="" onSubmit={handleEdit}>
                                                <input type="hidden" name="command" value="Search" />
                                                <table className="defaultTbl" width="500" cellSpacing="1">
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan="2">Add Link to Product :{productName}</th>
                                                        </tr>

                                                        <tr id="div_row">
                                                            <td className="caption">Group</td>
                                                            <td id="div_container">
                                                                <select name="division" id="division" className="text-uppercase" value={group} style={{ width: '382px' }} onChange={(e) => setGroup(e.target.value)}>
                                                                    <option value="0">-- Select a collection first --</option>
                                                                    {allGroup.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id} >
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        {loc &&
                                                            <tr id="dept_row">
                                                                <td className="caption">Branch</td>
                                                                <td id="dept_container">
                                                                    <select name="department" id="department" className="text-uppercase" value={branch} onChange={(e) =>
                                                                        setBranch(e.target.value)} style={{ width: '382px' }}>
                                                                        {/* onChange = {(e) => setBranch(e.target.value)} */}
                                                                        <option value="0">-- Select a group First --</option>
                                                                        {allBranch.map((item) => {
                                                                            return (
                                                                                <option key={item.id} value={item.id} >
                                                                                    {item.name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </td>
                                                            </tr>}

                                                        <tr>
                                                            <td className="caption">Start date</td>
                                                            <td>
                                                                {startFlag === 1 ?
                                                                    <DatePicker id="start_date" name="start_date" value={start_date} onChange={(date) => { setstart_date(date); setStartFlag(0) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                    :
                                                                    <DatePicker id="start_date" name="start_date" selected={start_date} onChange={(date) => setstart_date(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                }
                                                            </td>

                                                        </tr>

                                                        <tr>
                                                            <td className="caption">End date</td>
                                                            <td>

                                                                {endFlag === 1 ?
                                                                    <DatePicker id="end_date" name="end_date" value={end_date} onChange={(date) => { setend_date(date); setEndFlag(0) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />

                                                                    :

                                                                    <DatePicker id="end_date" name="end_date" selected={end_date} onChange={(date) => setend_date(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                }

                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colSpan="2" className="caption text-center" >
                                                                <input type="submit" name="command" value="Add"  />
                                                                <Link to={"/admin/option_linking/" + listParent + "/" + productId} state={{ loc: loc, type: type }}>
                                                                    <input type="submit" name="command" value="Back" />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </form>
                                            <br />

                                            <p></p>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </td>
                        <td></td>

                    </tr>
                </tbody>
            </table>
        </>
    );



}

export default Option_linking_edit