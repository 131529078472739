import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link } from 'react-router-dom';
import { useParams } from "react-router";
import Loader from "../../Components/Loader"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Assign_loanhouse() {
    document.title = "Administration | Seriti - Finance Institution";
    const { loanhouse_id } = useParams();

    const [name, setName] = useState('');
    const [allGroup, setAllGroup] = useState([]);
    const [group, setGroup] = useState('');
    const [branch, setBranch] = useState('');
    const [allBranch, setAllBranch] = useState([]);
    const [allAssignedGroup, setAllassignedGroup] = useState([]);
    const [remove, setRemove] = useState('');
    const [removeFlag, setRemoveFlag] = useState(false);
    const [assign, setAssign] = useState(false);
    const [fetch, setFetch] = useState(true);
    const [flag, setFlag] = useState(true);

    const [loader, setLoader] = useState(false);
    //------------------------------group dropdown------------------------------------//
    useEffect(() => {
        if(flag){
            axios
            .get("index/group")
            .then((response) => {
                setAllGroup(response.data.data);
                setFlag(false)
            })
            .catch((response) => {
                alert(response.response.data.message);
                setBranch('All')
            });
        }
    }, [flag]);

    useEffect(() => {
        if (group !== '') {
            axios
                .get("assign/branch/"+ loanhouse_id + '/' + group)
                .then((response) => {
                    setAllBranch(response.data.data);
                    
                })
                .catch((response) => {
                    alert(response.response.data.message);
                });
        }
    }, [group,loanhouse_id]);

    //-----------------------------view loanhouse details-------------------------------//
    useEffect(() => {
        const payload = {
            loanhouse_id: loanhouse_id
        };
        axios
            .post("/admin/view_loanhouse", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    setName(response.data.data[0].name)
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                alert(response.response.data.message);
            });
    }, [loanhouse_id]);

    //---------------------------------assigned groups-----------------------------------//
    useEffect(() => {
        if (fetch === true) {
            axios
                .post("loanhouses/assigned_groups/" + loanhouse_id)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data);
                        setFetch(false)
                        setAllassignedGroup(response.data.data[0])
                    }
                })
                .catch((response) => {
                    alert(response.response.data.message);
                });
        }
    }, [fetch,loanhouse_id]);

    //---------------------actual assign (save) group or remove group---------------------//
    const handleEdit = (e) => {
        e.preventDefault();
        setLoader(true);
        const payload = {
            loanhouse_id: loanhouse_id,
            bridge_id: remove,
            group: group,
            branch: branch,
            assign: assign
        };
        
        if (removeFlag === true) {
            axios
            .post("loanhouses/handle_assigned_groups", payload)
            .then((response) => {
                setLoader(false);
                if (response.data.status === 200) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setFetch(true)
                    setAssign(false)
                    setRemoveFlag(false)
                    setFlag(true);
                    setGroup('');
                    setBranch('')
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                setLoader(false);
                // console.log(response.response)
                if (response.response.data.status === 400) {
                    toast.error(response.response.data.data, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            });
        }
        
    };

    return (
        <>
        <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Admin_menu />
            {loader ? <Loader /> : null}
            <form method="post" action="" onSubmit={handleEdit} encType="multipart/form-data" >
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">

                                <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center"><p></p>
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="500">
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan="2" className="text-center">ASSIGN A GROUP TO {name}</th>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">GROUP<span style={{color:"red"}}> *</span></td>
                                                            <td>
                                                                <select name="group" id="group" value={group} onChange={(e) => {setGroup(e.target.value); setBranch('All')}} className="text-uppercase">
                                                                    <option value="">-- SELECT A GROUP --</option>
                                                                    {allGroup.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id} >
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">BRANCH</td>
                                                            <td>
                                                                <select name="branch" id="branch" value={branch} onChange={(e) => setBranch(e.target.value)} className="text-uppercase">
                                                                    <option value="All">-- Select a GROUP First -</option>
                                                                    {allBranch.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id} >
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colSpan={2} style={{ textAlign: 'center' }}>
                                                                <input name="assign" type="submit" value="Assign" onClick={(e) => { setAssign(true);setRemoveFlag(true) }} />
                                                                <Link to={"/admin/loanhouses_list"} className="btn btn-result-open">
                                                                    <input type="button" value="Cancel" /></Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr><tr>
                                            <td>
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="500">
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan={3} className="text-center">ASSIGNED GROUPS</th>
                                                        </tr>
                                                        <tr className="caption">
                                                            <td>GROUP</td>
                                                            <td>BRANCH</td>
                                                            <td></td>
                                                        </tr>
                                                        {
                                                            allAssignedGroup && allAssignedGroup.length ? (
                                                                allAssignedGroup.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td> {item.group_name} </td>
                                                                            <td> {item.branch_name} </td>
                                                                            <td>
                                                                                <input type="submit" name="command" value="Remove" onClick={(e) => {
                                                                                    if (window.confirm('Do you really want to delete this record?')){
                                                                                        setRemoveFlag(true);
                                                                                        setRemove(item.id);
                                                                                    }
                                                                                }
                                                                                } />

                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : <tr><td colSpan={3}>"THIS LOANHOUSE HAS NOT BEEN ASSIGNED TO ANY COLLECTION OR GROUP YET."</td></tr>
                                                        }
                                                        {/* <input type="submit" name="command" value="Remove" onClick={(e) => { setRemove(item.id) }} /> */}
                                                        <tr>
                                                            <td colSpan={3} className="caption" style={{ textAlign: 'center' }}>
                                                                <Link to={"/admin/loanhouses_list"} className="btn btn-result-open">
                                                                    <input type="button" name="refer" value="Back" />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );
}

export default Assign_loanhouse