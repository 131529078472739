import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link, useLocation } from 'react-router-dom';
import { useParams } from "react-router"
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function List_categories() {
    document.title = "Administration | Seriti - Product Admin";
    const { option_id } = useParams()

    const location = useLocation()
    const { parentId } = location.state
    const [allCategory, setAllCategory] = useState([]);

    const [cat_type, setCatType] = useState('');

    const [sHeading, setsHeading] = useState('');

    const [order, setOrder] = useState(1);
    const [option, setOption] = useState('');
    const [command, setCommand] = useState('');

    const [optionId, setOptionId] = useState('');
    const [reload, setReload] = useState(true);
    const [isAddShown, setIsAddShown] = useState(true);
    const [isUpdateShown, setIsUpdateShown] = useState(false);
    //----------------------------getting category----------------------------------// 
    useEffect(() => {
        if (reload) {
            const payload = {
                option_id: option_id
            };
            axios
                .post("list/categories", payload)
                .then((response) => {
                    // console.log(response.data.data.aGroup)
                    setsHeading(response.data.data.sHeading);
                    setAllCategory(response.data.data.rTypes);
                    setReload(false);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    //alert(response.data);
                });
        }
    }, [option_id, reload]);
    // console.log(parentId);
    //---------------------handle validation------------------------//
    const handleValidation = () => {
        var flag = true;
        if (option === '') {
            alert('Please fill option fields!');
            flag = false;
        }
        return flag;
    };

    //on edit tab set data fro update command
    const handleEdit = (id, opt, ord, catType) => {
        // alert(id);
        setOrder(ord);
        setOption(opt);
        setOptionId(id);
        setCatType(catType)
        setIsAddShown(false);
        setIsUpdateShown(true)
    }

    //----------------------------add-------------------------------------//
    const handleSubmit = (e) => {
        // alert('here')
        e.preventDefault();

        // const flag = handleValidation();
        const flag = (command === 'Add' || command === 'Update') ? handleValidation() : true;
        // const flag = true;
        if (flag) {
            const payload = {
                command: command,
                id: option_id,
                order: order,
                option: option,
                option_id: optionId,//for update
                parent_id: parentId,
                cat_type: cat_type,
                type: "subCategory"
            };
            axios
                .post("category_admin_update", payload)
                .then((response) => {
                    // console.log(response.data.data)
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setIsAddShown(true)
                    setIsUpdateShown(false)
                    setReload(true);
                    setOrder(1);
                    setOption('');
                    setOptionId('');
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    // console.log(response.response.data.data)
                    if (response.response.data.status === 400)
                    toast.error(response.response.data.data, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    };
    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Admin_menu />
            <form method="post" action="" encType="multipart/form-data" onSubmit={handleSubmit}>
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">

                                <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center"><p></p>
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="600">
                                                    <tbody>

                                                        <tr><th colSpan="6" style={{ textAlign: 'center' }}>{sHeading} Catagory Administration</th></tr>
                                                        <tr className="caption">
                                                            <td></td>
                                                            <td>Order</td>
                                                            <td>Type Name</td>
                                                            {(parentId > 0) ? <td>Product Type</td> : <td>Categories</td>}
                                                            <td></td>
                                                        </tr>

                                                        {
                                                            allCategory && allCategory.length ? (
                                                                allCategory.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <input name="edit_role" type="button" value="Edit" onClick={(e) => handleEdit(item.id, item.option, item.sorder, item.cat_type)} />
                                                                            </td>
                                                                            <td>{item.sorder}</td>
                                                                            <td>{item.option}</td>
                                                                            {(item.cat_type_name !== '') ? <td>{item.cat_type_name}</td> : null}

                                                                            <td>
                                                                                {(item.active) ?
                                                                                    <input type="submit" name="command" value="Disable" onClick={(e) => { setCommand('Disable'); setOptionId(item.id) }} /> : <input type="submit" name="command" value="Enable" onClick={(e) => { setCommand('Enable'); setOptionId(item.id) }} />}
                                                                            </td>

                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : <tr><td colSpan={6}>No records</td></tr>
                                                        }
                                                        {/* add */}
                                                        <tr className="caption">
                                                            <td></td>
                                                            <td>
                                                                <input type="text" name="order" size="2" value={order} onChange={(e) => setOrder(e.target.value)} />
                                                            </td>
                                                            <td>
                                                                <input type="text" name="option" size="35" value={option} onChange={(e) => setOption(e.target.value)} className="text-uppercase" />
                                                            </td>
                                                            <td><select name="cat_type" value={cat_type} onChange={(e) => setCatType(e.target.value)} className="text-uppercase">
                                                                <option value="0">Not Defined</option>
                                                                <option value="1">Generic Insurance</option>
                                                                <option value="2">Generic VAP</option>
                                                                <option value="3">Vehicle Insurance</option>
                                                                <option value="4">Vehicle VAP</option>
                                                                <option value="5">Aftersales Insurance</option>
                                                                <option value="6">Aftersales VAP</option>
                                                                <option value="7">Retail</option>
                                                            </select>
                                                            </td>
                                                            <td colSpan={2}>
                                                                {isAddShown && <input type="submit" name="command" value="Add" onClick={(e) => setCommand('Add')} />}

                                                                {isUpdateShown && <input type="submit" name="command" value="Update" onClick={(e) => setCommand('Update')} />}
                                                            </td>
                                                        </tr>

                                                        <tr className="caption">
                                                            <td colSpan={5} style={{ textAlign: 'center' }}>
                                                                <Link to={"/admin/categories/" + parentId} >
                                                                    <input type="button" name="back" value="Back" />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                        {/* add */}
                                                    </tbody>
                                                </table>

                                            </td>
                                        </tr><tr>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );

}

export default List_categories