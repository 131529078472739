import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link } from 'react-router-dom';

function Loanhouses_list() {
    document.title = "Administration | Seriti - Finance Institution";
    // const navigate = useNavigate();
    const [allCountries, setAllcountries] = useState([]);
    const [country, setCountry] = useState('');
    const [searchData, setSearchData] = useState([]);
    // const [active, setActive] = useState('');
    const [flag, setFlag] = useState('');
    const [fetch, setFetch] = useState(true);
   
    //----------------------------country dropdown-----------------------------------//
    useEffect(() =>{
        // alert("here");
        axios
                .get("country_list")
                .then((response) => {
                    // console.log(response.data.data[0])
                    setAllcountries(response.data.data[0]);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch(() => {
                    //setLoginLoader(false);
                });
    }, []);
    
    //-----------------------------searched loanhouses list--------------------------//
    // useEffect(() =>{
    //     axios
    //             .get("loanhouses_list/"+country)
    //             .then((response) => {
    //                 // console.log(response.data.data)
    //                 setSearchData(response.data.data);
    //             })
    //             .catch(() => {
    //                 //setLoginLoader(false);
    //             });   
    // }, [country]);

    //---------------------searched loanhouses list as per country-------------------//
    useEffect(() =>{
        // console.log(fetch);
        if (fetch === true || (country !== '' && fetch === false) || country === '') {
            axios
                .get("loanhouses_list/"+849)//849 ID for BOSTWANA country
                .then((response) => {
                    // console.log(response.data.data)
                    setFetch(false)
                    setSearchData(response.data.data);
                })
                .catch(() => {
                    //setLoginLoader(false);
                });
        }
    }, [country,fetch]);
    
    //----------------------add and update loanhouse--------------------------------//
    const handleEdit = (e) => {
        e.preventDefault();
        const payload = {
            loanhouse_id: flag,
            command : '1'
        };
        // console.log(payload)
        axios
            .post("/admin/add_update_loanhouse", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    setFetch(true)
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                console.log('error');
                // alert(response.response.data.message);
            });
    };

    return (
        <>
            <Admin_menu />
            <form method="post" action="" onSubmit={handleEdit} encType="multipart/form-data" >
            <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                <tbody>
                    <tr valign="top">
                        <td></td>
                        <td width="100%">
                            
                            <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center"><p></p>
                                            {/* <table className="defaultTbl" cellSpacing={1} align="center">
                                                <tbody>
                                                    <tr>
                                                        <th colSpan="2" className="text-center">Filter by Country</th>
                                                    </tr>
                                                    <tr>
                                                        <td className="caption">Country</td>
                                                        <td>
                                                            <select name="countries_country" id="countries_country" value={country} onChange = {(e) => setCountry(e.target.value)} className="text-uppercase">
                                                                <option value="">-- Select Option --</option>
                                                                {allCountries.map((item) => {
                                                                    return (
                                                                    <option key={item.id}  value={item.id} >
                                                                        {item.name}
                                                                    </option>
                                                                    );
                                                                })}    
                                                            </select>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table> */}
                                        </td>
                                    </tr><tr>
                                        <td>
                                            <table className="defaultTbl" cellSpacing={1} align="center">
                                                <tbody>
                                                    <tr>
                                                        <th colSpan="7" className="text-center">List of Loanhouses</th>
                                                    </tr>
                                                    <tr className="caption">
                                                        <td></td>
                                                        <td></td>
                                                        <td>Name</td>
                                                        <td>Country</td>
                                                        <td>Logo</td>
                                                        <td>Email</td>
                                                        <td></td>
                                                    </tr>
                                                    {
                                                        searchData && searchData.length? (
                                                        searchData.map((item,index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <Link to={"/admin/loanhouses/" + item.id} className="btn btn-result-open">
                                                                        <input type="button" value="View"/>
                                                                    </Link>
                                                                </td>
                                                                <td> 
                                                                    <Link to={"/admin/assign_loanhouse/" + item.id} className="btn btn-result-open">
                                                                        <input type="button" value="Assign to a Group"/> 
                                                                    </Link>
                                                                </td>
                                                                <td> {item.name} </td>
                                                                <td> {item.country_name} </td>
                                                                <td> <img src={item.logo} width="150" alt=""/></td>
                                                                <td> {item.email} </td>
                                                                <td> {(item.active === 1) ? 
                                                                    <input type="submit" name="command" value="Deactivate"  onClick={(e) => { setFlag(item.id) }} id="command"/>
                                                                
                                                                : 
                                                                    <input type="submit" name="command" value="Activate"  onClick={(e) => { setFlag(item.id) }} id="command"/>
                                                                }
                                                                </td>
                                                        </tr> 
                                                        );
                                                        })
                                                        ):<tr><td colSpan={7}></td>
                                                    </tr>
                                                        }
                                                    <tr>
                                                        <td colSpan="7" className="caption" style={{ textAlign: 'center' }}>
                                                        <Link to={"/admin/loanhouses_edit/0"} className="btn btn-result-open">   
                                                            <input type="button" name="refer" value="Add New"  />
                                                        </Link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            </form>
        </>
    );


}

export default Loanhouses_list