
import VechileAccessories_menu from "./VechileAccessories_menu"
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"
import axios from "../../axios";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

function Attributes_capture() {
    document.title = "Products | Seriti - Vehicle & Accessories";
    const location = useLocation()
    const { productId } = location.state
    const { template_id } = location.state
    const [productName, setProductName] = useState('');

    const [make, setMake] = useState('');
    const [cylinders, setCylinders] = useState('');
    const [cubic_capacity, setCubicCapacity] = useState('');
    const [kilowatts, setKilowatts] = useState('');
    const [body_type, setBodyType] = useState('');
    const [of_doors, setOfDoors] = useState('');
    const [dateDiscountined, setDateDiscountined] = useState('');
    const [dateIntroduced, setDateIntroduced] = useState('');
    const [dateAttribute, setDateAttribute] = useState('');
    // const [make, setMake] = useState('');
    //----------------------------data----------------------------------// 
    useEffect(() => {
        const payload =
        {
            product_id: productId,
            template_id: template_id
        };
        axios
            .post("/product/attributes", payload)
            .then((response) => {
                // console.log(response.data.data.rResults)
                setProductName(response.data.data.sProductName);
                var obj = response.data.data.rResults;
                setMake(obj.Make)
                setCylinders(obj.Cylinders)
                setCubicCapacity(obj.Cubic_Capacity)
                setKilowatts(obj.Kilowatts)
                setBodyType(obj.Body_Type)
                // setOfDoors(obj.#_of_Doors)
                // setDateDiscountined(obj.Date_Discontinued)
                // setDateIntroduced(obj.Date_Introduced)
                // setDateAttribute(obj.Another_Attribute)
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, [productId, template_id]);

    // console.log(template_id);
    //-----------------------------search data----------------------------------------// 
    // console.log(category)
    const handleSubmit = (e) => {
        // setGroup(group)
        e.preventDefault();

        // const payload = {
        //     division: group,
        //     department: branch,
        //     search_text: search_text,
        //     category: category
        // };
        // axios
        //     .post("/product/search", payload)
        //     .then((response) => {
        //         if (response.data.status === 200) {
        //             // console.log(response.data.data)
        //             setIsShown(true);
        //             setSearchData(response.data.data);
        //         }
        //     })
        //     .catch((response) => {
        //     });

    };
    return (
        <>
            <VechileAccessories_menu />
            <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                <tbody>
                    <tr valign="top" className="bg-white">
                        <td></td>
                        <td width="100%" className="bg-white">
                            <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                            <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                            <form method="post" action="" onSubmit={handleSubmit} >
                                                <table className="defaultTbl" width="500" cellSpacing="1">
                                                    <tbody>
                                                        <tr><th colSpan={2} className="text-center">Capture Attributes: {productName}</th></tr>
                                                        <tr><td className="caption">Make</td>
                                                            <td>
                                                                <input type="text" name="make" value={make} onChange={(e) => setMake(e.target.value)} />
                                                            </td>
                                                        </tr>
                                                        <tr><td className="caption">Cylinders</td>
                                                            <td>
                                                                <input type="text" name="cylinders" value={cylinders} onChange={(e) => setCylinders(e.target.value)} />
                                                            </td>
                                                        </tr>

                                                        <tr><td className="caption">Cubic Capacity</td>
                                                            <td>
                                                                <input type="text" name="cubic_capacity" value={cubic_capacity} onChange={(e) => setCubicCapacity(e.target.value)} />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Kilowatts</td>
                                                            <td>
                                                                <input type="text" name="kilowatts" value={kilowatts} onChange={(e) => setKilowatts(e.target.value)} />
                                                            </td>
                                                        </tr>

                                                        <tr><td className="caption">Body Type</td>
                                                            <td>
                                                                <input type="text" name="body_type" value={body_type} onChange={(e) => setBodyType(e.target.value)} />
                                                            </td>
                                                        </tr>

                                                        <tr><td className="caption"># of Doors</td>
                                                            <td>
                                                                <input type="text" name="of_doors" value={of_doors} onChange={(e) => setOfDoors(e.target.value)} />
                                                            </td>
                                                        </tr>

                                                        <tr><td className="caption">Date Discontinued</td>
                                                            <td>
                                                                <DatePicker selected={dateDiscountined} name="date_of_birth" onChange={(date) => setDateDiscountined(date)} dateFormat="MM/yyyy" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Date Introduced</td>
                                                            <td>
                                                                <DatePicker selected={dateIntroduced} name="date_of_birth" onChange={(date) => setDateIntroduced(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Another Attribute</td>
                                                            <td>
                                                            <DatePicker selected={dateAttribute} name="date_of_birth" onChange={(date) => setDateAttribute(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                            </td>
                                                        </tr>
                                                        <tr className="caption">
                                                            <td colSpan="2" style={{ textAlign: "center" }}>
                                                                <input type="submit" name="command" value="Search" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </form>
                                            <p></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default Attributes_capture