import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

function Group_defaults_add() {
    document.title = "Administration | Seriti - Group Admin";
    const navigate = useNavigate();
    const UserId = localStorage.getItem("UserId")
    const { affiliate } = useParams()

    const [logo1, setLogo1] = useState('')
    const [logo2, setLogo2] = useState('')

    const [code, setCode] = useState('')
    const [country_id, setcountry_id] = useState('')
    const [allCountry, setAllCountry] = useState([]);

    const [currency, setcurrency] = useState('')
    const [allcurrency, setAllCurrency] = useState([])

    const [end_date, setend_date] = useState('')

    const [finance_term, setfinance_term] = useState('')
    const [allfinance_term, setallfinance_term] = useState([])

    const [fsp_complaints, setfsp_complaints] = useState('')
    const [allfsp_complaints, setAllfsp_complaints] = useState([])

    const [fsp_compliance, setfsp_compliance] = useState('')
    const [allfsp_compliance, setAllfsp_compliance] = useState([])

    const [fsp_rep, setfsp_rep] = useState('')
    const [allfsp_rep, setAllfsp_rep] = useState([])

    const [name, setname] = useState('')

    const [payover_date, setpayover_date] = useState('')

    const [payover_job_name, setpayover_job_name] = useState('')

    const [prime_adjustment, setprime_adjustment] = useState('')

    const [service_type, setservice_type] = useState('')
    const [allservice_type, setallservice_type] = useState([])

    const [share_branches, setshare_branches] = useState(false)

    const [signature_date, setsignature_date] = useState('')

    const [start_date, setstart_date] = useState('')

    const [system_type, setsystem_type] = useState('')

    const [transaction_fee, settransaction_fee] = useState('')
    const [allTransaction, setallTransaction] = useState([])

    const [vehicle_coding_system, setvehicle_coding_system] = useState('')

    //----------------set default logo of seriti------------------------//
    useEffect(() => {
        axios
            .get("/set_logo")
            .then((response) => {
                if (response.data.status === 200) {
                    setLogo1(response.data.data)
                }
            })
            .catch((response) => {
                // alert(response.response.data.message);
            });
    }, []);

    //---------------------country list---------------------------------//
    useEffect(() => {
        axios
            .get("country_list")
            .then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data.data[0]);
                    setAllCountry(response.data.data[0])
                }
            })
            .catch((response) => {
                // alert(response.response.data.message);
            });
    }, []);

    //--------------------others dropdown of page------------------------//
    useEffect(() => {
        axios
            .get("/admin/group_admin/get_dropdown")
            .then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data.data.currency);
                    setAllCurrency(response.data.data.currency)
                    setallfinance_term(response.data.data.finance_term)
                    setallservice_type(response.data.data.service_type)
                    setAllfsp_rep(response.data.data.fsp_name_dropdown)
                    setallTransaction(response.data.data.aTransactionType)
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                // alert(response.response.data.message);
            });
    }, []);

    //---------------fsp_complaints&fsp_compliance dropdown--------------//
    useEffect(() => {
        const payload = {
            fsp_rep: fsp_rep
        };
        if (fsp_rep > 0) {
            // alert(fsp_rep)
            axios
                .post("/admin/group_admin/get_dropdown_fsp", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data.data);
                        setAllfsp_complaints(response.data.data.sFSPComplaintsSelect);
                        setAllfsp_compliance(response.data.data.sFSPComplianceSelect);
                    }
                })
                .catch((response) => {
                    // alert(response.response.data.message);
                });
        }
    }, [fsp_rep]);
    // console.log(start_date);
    //--------------------------handle validation-----------------------//
    const handleValidation = () => {
        var flag = true;
        if (code === '' || name === '' || system_type === '') {
            alert('Please fill in all the fields!');
            flag = false;
        }else if(prime_adjustment !== ''){
            if(!(/^[0-9\b]+$/.test(prime_adjustment))){
                alert('Please enter a valid value for prime adjustment');
                flag = false;
            }
        }else if(transaction_fee !== ''){
            if(!(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/.test(transaction_fee))){
                alert('Please enter a valid value for transaction fee');
                flag = false;
            }
        }
        if (logo2) {
            if (!logo2.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                alert('select valid image.');
                flag = false;
            }
        }
        if (end_date !== '') {
            let date1;
            if (start_date === '')      //this is for add condition
                date1 = new Date().getTime(); //getting today date
            else                       //this is for edit conditin
                date1 = new Date(start_date).getTime();

            let date2 = new Date(end_date).getTime();
            if (date1 > date2) {
                alert('Start date is greater than End date. Please enter Valid End date');
                setend_date('');
                flag = false;
            }
        }
        return flag;
    };

    //------------------------submit data-------------------------------//
    const handleSubmit = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        if (flag) {
            var bodyFormData = new FormData();

            bodyFormData.append("user_id", UserId);
            bodyFormData.append("affiliate", affiliate);
            bodyFormData.append("logo", logo2);
            bodyFormData.append("code", code);
            bodyFormData.append("name", name);
            bodyFormData.append("country", country_id);
            bodyFormData.append("currency", currency);
            bodyFormData.append("prime_adjustment", prime_adjustment);
            bodyFormData.append("finance_term", finance_term);
            bodyFormData.append("service_type", service_type);//Service Type
            bodyFormData.append("fsp_rep", fsp_rep);
            bodyFormData.append("fsp_complaints", fsp_complaints);
            bodyFormData.append("fsp_compliance", fsp_compliance);
            bodyFormData.append("system_type", system_type);//Type of Transactions 
            bodyFormData.append("vehicle_coding_system", vehicle_coding_system);
            bodyFormData.append("share_branches", share_branches ? 1 : 0);
            bodyFormData.append("signature_date", signature_date !== '' ? moment(signature_date).format("YYYY-MM-DD") : 0);
            bodyFormData.append("payover_date", payover_date !== '' ? moment(payover_date).format("YYYY-MM-DD") : 0);
            bodyFormData.append("payover_job_name", payover_job_name);
            bodyFormData.append("start_date", start_date !== '' ? moment(start_date).format("YYYY-MM-DD") : 0);
            bodyFormData.append("end_date", end_date !== '' ? moment(end_date).format("YYYY-MM-DD") : 0);
            bodyFormData.append("transaction_fee", transaction_fee);
            
            // bodyFormData.append("seriti_fee", seriti_fee);
            // bodyFormData.append("service_fee", service_fee);
            // bodyFormData.append("consulting_fee", consulting_fee);
            // bodyFormData.append("compliance_fee", compliance_fee);
            // bodyFormData.append("formgroup_id", formgroup_id);
            // console.log(bodyFormData);
            axios
                .post("/admin/group_admin/add_group_deafults", bodyFormData)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data.data)
                        navigate('/admin/group_admin/group_defaults/' + response.data.data.division);
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    if(response.response.data.status === 400)
                        alert(response.response.data.data);
                });
        }
    };

    return (
        <>
            <Admin_menu />

            <form method="post" onSubmit={handleSubmit} encType="multipart/form-data" validation="1">
                <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body">
                    <tbody>
                        <tr valign="top">
                            <td width="100%">
                                <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                                <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center">
                                                <table className="defaultTbl" width="550" cellSpacing="1">
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan="2" className="text-center">Add Group</th>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Display Logo</td>
                                                            <td><img height="92" src={logo1} width="350" alt=""/><br /><input type="file" name="logo" onChange={(e) => setLogo2(e.target.files[0])} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Group code<span style={{ color: "red" }}> *</span></td>
                                                            <td><input validate="text" type="text" name="code" value={code} onChange={(e) => setCode(e.target.value)} className="text-uppercase" style={{ width : "350px"}} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Group name<span style={{ color: "red" }}> *</span></td>
                                                            <td><input validate="text" type="text" name="name" value={name} onChange={(e) => setname(e.target.value)} className="text-uppercase" style={{ width : "350px"}}/></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Country</td>
                                                            <td>
                                                                <select name="country" className="text-uppercase" value={country_id} onChange={(e) => { setcountry_id(e.target.value) }} style={{ width : "350px"}} >
                                                                    <option value="">-- Select Option --</option>
                                                                    {allCountry.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Currency</td>
                                                            <td>
                                                                <select name="currency" className="text-uppercase" value={currency} onChange={(e) => { setcurrency(e.target.value) }} style={{ width : "350px"}}>
                                                                    <option value="">- - Select currency - -</option>
                                                                    {allcurrency.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.key}>
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Default prime adjustment</td>
                                                            <td><input type="text" name="prime_adjustment" value={prime_adjustment} className="text-uppercase" onChange={(e) => { setprime_adjustment(e.target.value) }} style={{ width : "350px"}}/></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Default financial term</td>
                                                            <td>
                                                                <select name="finance_term" className="text-uppercase" value={finance_term} onChange={(e) => { setfinance_term(e.target.value) }} style={{ width : "350px"}}>
                                                                    <option value="">- - Select finance_term - -</option>
                                                                    {allfinance_term.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.value}>
                                                                                {item.value}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Service type</td>
                                                            <td>
                                                                <select name="service_type" className="text-uppercase" value={service_type} onChange={(e) => { setservice_type(e.target.value) }} style={{ width : "350px"}}>
                                                                    <option value="0">- - Select service_type - -</option>
                                                                    {allservice_type.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.value}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">FSP Represantative</td>
                                                            <td>
                                                                <select name="fsp_rep" className="text-uppercase" value={fsp_rep} onChange={(e) => setfsp_rep(e.target.value)} style={{ width : "350px"}}>
                                                                    <option value="0">-- Please select an FSP Representative --</option>
                                                                    {allfsp_rep.map((item) => {
                                                                        return (
                                                                            <option key={item.value} value={item.value}>
                                                                                {item.text}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">FSP Complaints Officer</td>
                                                            <td>
                                                                <select name="fsp_complaints" className="text-uppercase" value={fsp_complaints} onChange={(e) => setfsp_complaints(e.target.value)} style={{ width : "350px"}}>
                                                                    <option value="0">-- Please select an FSP Complaints Officer --</option>
                                                                    {allfsp_complaints.map((item) => {
                                                                        return (
                                                                            <option key={item.value} value={item.value}>
                                                                                {item.text}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">FSP Compliance Officer</td>
                                                            <td>
                                                                <select name="fsp_compliance" className="text-uppercase" value={fsp_compliance} onChange={(e) => setfsp_compliance(e.target.value)} style={{ width : "350px"}}>
                                                                    <option value="0">-- Please select an FSP Compliance Officer --</option>
                                                                    {allfsp_compliance.map((item) => {
                                                                        return (
                                                                            <option key={item.value} value={item.value}>
                                                                                {item.text}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Type of Transactions<span style={{ color: "red" }}> *</span></td>
                                                            <td>
                                                                <select validate="text" name="system_type" className="text-uppercase" value={system_type} onChange={(e) => setsystem_type(e.target.value)} style={{ width : "350px"}}>
                                                                    <option value="">--SELECT OPTION--</option>
                                                                    {allTransaction.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.key}>{item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr id="vehicle_coding_system">
                                                            <td className="caption">Default Vehicle Coding System</td>
                                                            <td>
                                                                <input type="radio" name="vehicle_coding_system" value="1" onChange={(e) => setvehicle_coding_system(e.target.value)} />
                                                                M&amp;M
                                                                <input type="radio" name="vehicle_coding_system" value="2" onChange={(e) => setvehicle_coding_system(e.target.value)} />
                                                                Glass
                                                                <input type="radio" name="vehicle_coding_system" value="3" onChange={(e) => setvehicle_coding_system(e.target.value)} /> Redbook
                                                            </td>

                                                            
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Share Contacts between Branches</td>
                                                            <td><input type="checkbox" name="share_branches" value={share_branches} onChange={(e) => setshare_branches(!share_branches)} /></td>
                                                        </tr>
                                                       
                                                        <tr>
                                                            <td className="caption">SLA Signature date</td>
                                                            <td><DatePicker id="signature_date" name="signature_date" selected={signature_date} onChange={(date) => setsignature_date(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Payover date</td>
                                                            <td><DatePicker id="payover_date" name="payover_date" selected={payover_date} onChange={(date) => setpayover_date(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Payover job name</td>
                                                            <td><input type="text" name="payover_job_name" value={payover_job_name} className="text-uppercase" onChange={(e) => setpayover_job_name(e.target.value)} /></td>
                                                        </tr>
                                                        
                                                        <tr>
                                                            <td className="caption">Start date</td>
                                                            <td><DatePicker id="start_date" name="start_date" selected={start_date} onChange={(date) => setstart_date(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">End date</td>
                                                            <td><DatePicker id="end_date" name="end_date" selected={end_date} onChange={(date) => setend_date(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Transaction Fee</td>
                                                            <td><input type="text" name="transaction_fee" value={transaction_fee} id="transaction_fee" className="text-uppercase" onChange={(e) => settransaction_fee(e.target.value)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption" colSpan={2}>
                                                                <Link to={'/admin/group_admin/group_fsp/' + affiliate}>
                                                                    <input type="submit" name="command" value="Back" className="float-left" />
                                                                </Link>
                                                                <input type="submit" name="command" value="Save" className="float-right" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    )

}

export default Group_defaults_add