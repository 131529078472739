import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "../axios";
import DatePicker from "react-datepicker";
import $ from 'jquery';

function Regular_drivers_table_rows({rowsData, handleDriversChange, instanceNumber, RegdValidate, colorFocus1}) {

    // const [AccountHolderAll, setAccountHolderAll] = useState([]);
    // const [BankNameAll, setBankNameAll] = useState([]);
    // const [AccountTypeAll, setAccountTypeAll] = useState([]);
    const [chk, setchk] = useState(1);
    const [DriverDetailsAll, setDriverDetailsAll] = useState([]);
    const [GenderAll, setGenderAll] = useState([]);
    const [CfgAll, setCfgAll] = useState([]);
    const [DriverLicenseDateAll, setDriverLicenseDateAll] = useState([]); 
    const [DriverWiseDetials, setDriverWiseDetials] = useState([]);
    const [ContactDetId, setContactDetId] = useState(0);
    
    const [DriverDetailsId, setDriverDetailsId] = useState(0);
    const [driver_firstname, setdriver_firstname] = useState('');
    const [driver_surname, setdriver_surname] = useState('');
    const [physical_address, setphysical_address] = useState('');
    const [date_of_birth, setdate_of_birth] = useState('');
    const [id_number, setid_number] = useState('');
    const [gender, setgender] = useState(0);
    const [drivers_lic_issue_date, setdrivers_lic_issue_date] = useState(0);
    const [claim_free_group, setclaim_free_group] = useState(0);
    const [CalAge, setCalAge] = useState(0);
    const [Age, setAge] = useState(0);
    const [AgeFalg, setAgeFalg] = useState(0);
    const [DriverFlag, setDriverFlag] = useState(0);
    const [ClaimPreviosIn12Month, setClaimPreviosIn12Month] = useState('');
    
    const company_id = localStorage.getItem("company_id");
    const instance_id = localStorage.getItem("instance_id");
   // console.log(ContactDetId);

   const [Cdate, setDate] = useState(new Date().toLocaleDateString('fr-FR'));
   const [expirydateFlag, setexpirydateFlag] = useState(0);
   const [FinalInstanceNumber, setFinalInstanceNumber] = useState(0);
  // console.log(Cdate);

    //----------- get driver details -----------------
    useEffect(() => { 
        const payload = {
            company_id: company_id,
            instance_id: instance_id,
        };

        axios
          .post("fill_forms/featch_driver_details_new",payload)
          .then((response) => {
            //console.log(response);
             setDriverDetailsAll(response.data.data.main_array);
          })
          .catch(() => {
             console.log('error');
          });
    }, []);

    //----------- get gender / cfg -----------------
    useEffect(() => { 
        axios
          .get("fill_forms/featch_gender_claim_free_group")
          .then((response) => {
            //console.log(response.data.gender_array);
              setGenderAll(response.data.gender_array);
              setCfgAll(response.data.cfg_array);
          })
          .catch(() => {
             console.log('error');  
          });
    }, []);

    //----------- get DRIVERS LICENSE ISSUE DATE -----------------
    useEffect(() => {
        axios
            .get("fill_forms/regular_driver_year")
            .then((response) => {
                //console.log(response.data.data.drivers_issue_year_array);
                if (response.data != '') {
                    setDriverLicenseDateAll(response.data.data.drivers_issue_year_array);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    // console.log(physical_address)
     //----------- get driver onchanges wise details -----------------
     useEffect(() => { 

        if(ContactDetId == 0){
            setDriverDetailsId(0); setdriver_firstname(""); setAgeFalg(0);
            setdriver_surname(""); setphysical_address(''); setdate_of_birth("");
            setdate_of_birth(""); setid_number(""); setAge(0); setClaimPreviosIn12Month('');
            setgender(0); setdrivers_lic_issue_date(0); setclaim_free_group(""); setDriverFlag(0);
        }else{

        const payload = {
            company_id: ContactDetId,
            instance_id: instance_id,
            final_instance_number: FinalInstanceNumber,
        };
        //console.log(payload);
        axios
          .post("fill_forms/featch_regular_drivers_wise_details",payload)
          .then((response) => {
            // console.log(response);
              setDriverWiseDetials(response.data.data);
              setDriverDetailsId(response.data.data.id);
              setdriver_firstname(response.data.data.driver_firstname);
              setdriver_surname(response.data.data.driver_surname);
              setphysical_address(response.data.data.physical_address);
              setAgeFalg(1);
              setdate_of_birth(response.data.data.date_of_birth);
              setid_number(response.data.data.id_number);
              setAge(response.data.data.age);
              if(response.data.data.gender != 0){
                 setgender(response.data.data.gender);
              }else{
                setgender(0);
              }
              
              setdrivers_lic_issue_date(response.data.data.drivers_lic_issue_date);
              setclaim_free_group(response.data.data.claim_free_group);
              setClaimPreviosIn12Month(response.data.data.claim_previos_in_12_month);
          })
          .catch(() => {
             console.log('error');
          });
        }
    }, [ContactDetId,FinalInstanceNumber]);

    const handleDriverWiseShowDetails = (index,evnt,final_instance_number)=>{  
        
        let condetid = evnt.target.value;
        setContactDetId(condetid);
        setFinalInstanceNumber(final_instance_number);
        //console.log(final_instance_number);
        if(condetid == ''){
            setgender('0');
            setclaim_free_group('0');
            setphysical_address('');
            setdrivers_lic_issue_date('0')
        }
    }

    const calculate_age = (index,date) => {
         
        // const { value } = evnt.target;
        var mdate =  moment(date).format('YYYY/MM/DD');
         //console.log(mdate);
        var today = new Date();
        var birthDate = new Date(mdate);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        //console.log(age);
        // return age;
        setAgeFalg(0);
        let temp_d = '-1';
        if(age == temp_d){
            setCalAge(0);
        }else{
            setCalAge(age);
        }
        
    }

    const handleRelation = ()=>{
        setDriverFlag(1);
    }

    return(
        
        rowsData.map((data, index)=>{
            // console.log(data);
            // console.log(index);
            const {drivers_details, driver_firstname1, driver_surname1, physical_address1, date_of_birth1, age1, id_number1, gender1, drivers_lic_issue_date1, claim_previos1, claim_group1}= data;

            const final_instance_number = instanceNumber+index+1;
            const temp_drivers_details = `sub_${final_instance_number}_field_1436`;
            const temp_driver_firstname = `sub_${final_instance_number}_field_1426`;
            const temp_driver_surname = `sub_${final_instance_number}_field_1440`;
            const temp_physical_address = `sub_${final_instance_number}_field_1427`;
            const temp_date_of_birth = `sub_${final_instance_number}_field_1444`;
            const temp_age = `sub_${final_instance_number}_field_1446`;
            const temp_id_number = `sub_${final_instance_number}_field_1442`;
            const temp_gender = `sub_${final_instance_number}_field_1445`;
            const temp_drivers_lic_issue_date = `sub_${final_instance_number}_field_1443`;
            const temp_claim_previos = `sub_${final_instance_number}_field_1447`;
            const temp_claim_group = `sub_${final_instance_number}_field_1399`;

            return(
                <tr key={index}>
                <td>
                    <select className="text-uppercase" id="driver_details" name={temp_drivers_details} value={drivers_details} onChange={(evnt) => { handleDriversChange(index, evnt); handleDriverWiseShowDetails(index, evnt,final_instance_number) }} style={temp_drivers_details == RegdValidate ? {borderColor: colorFocus1, width: '180px'} : null }>
                        <option value="">Select an option</option>
                        {DriverDetailsAll != null ?
                            DriverDetailsAll.map((item) => {
                                 return (
                                 <option key={item.id} value={item.id}>
                                 {item.dname}
                                 </option>
                                 );
                           })
                        : null }

                    </select>
                </td>
                <td nowrap="nowrap" className="text-center">
                    <input type="text" id="firstname" name={temp_driver_firstname} defaultValue={driver_firstname != '' ? driver_firstname : driver_firstname1} className="text-uppercase" onChange={(evnt)=>(handleDriversChange(index, evnt))} />
                </td>
                <td nowrap="nowrap" className="text-center">
                    <input type="text" id="surname" name={temp_driver_surname} defaultValue={driver_surname != '' ? driver_surname : driver_surname1} className="text-uppercase" onChange={(evnt)=>(handleDriversChange(index, evnt))}/>
                </td>
                <td nowrap="nowrap" className="text-center">
                    <textarea rows={4} id="physicaladdress" name={temp_physical_address} defaultValue={physical_address != '' ? physical_address : physical_address1} className="text-uppercase" onChange={(evnt)=>(handleDriversChange(index, evnt, temp_physical_address, 10, final_instance_number))}></textarea>
                </td>

                <td nowrap="nowrap" className="text-center">
                    {/* <input type="date" id="date_of_birth" name={temp_date_of_birth} defaultValue={date_of_birth != '' ? date_of_birth : date_of_birth1}  className="text-uppercase" onChange={(evnt) => { handleDriversChange(index, evnt); calculate_age(index, evnt);  }}/> */}
                    {
                        expirydateFlag == 1 ?
                            <DatePicker id="date_of_birth" selected={date_of_birth} name={temp_date_of_birth} onChange={(date) => { setdate_of_birth(date); setexpirydateFlag(1); calculate_age(index, date); handleDriversChange(index, date, temp_date_of_birth, 5); }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                        :
                            <DatePicker id="date_of_birth" value={date_of_birth} name={temp_date_of_birth} onChange={(date) => { setdate_of_birth(date); setexpirydateFlag(1); calculate_age(index, date); handleDriversChange(index, date, temp_date_of_birth, 5); }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                    }
                </td>
                <td nowrap="nowrap" className="text-center">
                    { AgeFalg == 1 ?
                       <input type="text" id="age" name={temp_age} value={Age != '' ? Age : age1} className="text-uppercase" onChange={(evnt)=>(handleDriversChange(index, evnt))}/> 
                    :
                       <input type="text" id="age" name={temp_age} value={CalAge} className="text-uppercase" onChange={(evnt)=>(handleDriversChange(index, evnt))}/> 
                    }
                   
                </td>
                <td nowrap="nowrap" className="text-center">
                    <input type="text" id="id_number" name={temp_id_number} defaultValue={id_number != '' ? id_number : id_number1}className="text-uppercase" onChange={(evnt)=>(handleDriversChange(index, evnt))}/>
                </td>
                
                <td nowrap="nowrap" className="text-center">
                {  gender != '' && DriverFlag === 0 ?
                    <select className="text-uppercase" id="gender" name={temp_gender} value={gender != '' ? gender : gender1} onChange={(evnt)=> { handleDriversChange(index, evnt); handleRelation(); }}>
                        <option value="0">Select an option</option>
                        {GenderAll.map((data) => {
                            return (
                            <option key={data.id} value={data.id}>
                                {data.gender}
                            </option>
                            );
                        })}
                    </select>
                :
                    <select className="text-uppercase" id="gender" name={temp_gender} defaultValue={gender != '' ? gender : gender1} onChange={(evnt)=>(handleDriversChange(index, evnt))}>
                        <option value="0">Select an option</option>
                        {GenderAll.map((data) => {
                            return (
                            <option key={data.id} value={data.id}>
                                {data.gender}
                            </option>
                            );
                        })}
                    </select>
                }
                </td>
                <td nowrap="nowrap" className="text-center">
                    {  drivers_lic_issue_date != '' && DriverFlag === 0 ?   
                        <select className="text-uppercase" id="drivers_lic_issue_date" name={temp_drivers_lic_issue_date} value={drivers_lic_issue_date != '' ? drivers_lic_issue_date : drivers_lic_issue_date1} onChange={(evnt)=>{ handleDriversChange(index, evnt); handleRelation(); }}>
                            <option value="0">Select an option</option>
                            {DriverLicenseDateAll.map((data) => {
                                return (
                                <option key={data.id} value={data.id}>
                                    {data.year}
                                </option>
                                );
                            })}
                        </select>
                    :
                        <select className="text-uppercase" id="drivers_lic_issue_date" name={temp_drivers_lic_issue_date} defaultValue={drivers_lic_issue_date != '' ? drivers_lic_issue_date : drivers_lic_issue_date1} onChange={(evnt)=>(handleDriversChange(index, evnt))}>
                            <option value="0">Select an option</option>
                            {DriverLicenseDateAll.map((data) => {
                                return (
                                <option key={data.id} value={data.id}>
                                    {data.year}
                                </option>
                                );
                            })}
                        </select>
                    }
                </td>     
                <td nowrap="nowrap" className="text-center">
                    <input type="text" id="claim_previous_12_months" name={temp_claim_previos} defaultValue={ClaimPreviosIn12Month != '' ? ClaimPreviosIn12Month : claim_previos1} className="text-uppercase" onChange={(evnt)=>(handleDriversChange(index, evnt))}/>
                </td>
                <td nowrap="nowrap" className="text-center">
                    {  claim_free_group != '' && DriverFlag === 0 ? 
                        <select className="text-uppercase" id="sub_1_field_1399" name={temp_claim_group} value={claim_free_group != '' ? claim_free_group : claim_group1}  onChange={(evnt)=> { handleDriversChange(index, evnt); handleRelation(); }}>
                            <option value="0">Select an option</option>
                            {CfgAll.map((data) => {
                                return (
                                <option key={data.id} value={data.id}>
                                    {data.cfg}
                                </option>
                                );
                            })}
                        </select>
                    :
                        <select className="text-uppercase" id="sub_1_field_1399" name={temp_claim_group} defaultValue={claim_free_group != '' ? claim_free_group : claim_group1}  onChange={(evnt)=> { handleDriversChange(index, evnt); handleRelation(); }}>
                            <option value="0">Select an option</option>
                            {CfgAll.map((data) => {
                                return (
                                <option key={data.id} value={data.id}>
                                    {data.cfg}
                                </option>
                                );
                            })}
                        </select>
                    }
                </td>
                
                <td nowrap="nowrap" className="text-center">
                    &nbsp;
                </td>
            </tr>
            )
        })
   
    )
    
}
export default Regular_drivers_table_rows;