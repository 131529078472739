import React, { useState, useEffect } from "react";
import axios from "../axios";
import delete_row_img from '../Images/icons/x.png';

function Bostwana_access_employee_details_table_rows({rowsData, handleEmployeeChange, instanceNumber, colorFocus, EmpValidateSalary,setSalaryDateFlag,PositionValidate,colorFocus5}) {

    const [SalaryDateAll, setSalaryDateAll] = useState([]);
    const [chkCurr, setchkCurr] = useState(true);
    const [DateRowHideShow, setDateRowHideShow] = useState("");
    
    //----------- get salary date / relationship (employee details) -----------------
    useEffect(() => {     
        axios
        .get("finance_application/featch_salary_date_relationship")
        .then((response) => {
            if(response.status === 200){
               setSalaryDateAll(response.data.data.salary_date);
            } 
        })
        .catch(() => {
            console.log('error');
        });
    }, []);
   
     //-------------- hide /show div
    const SalaryDateHideShow = (e) => {
        const loc_div_id = e.currentTarget.value;
        //console.log(loc_div_id);
        if(loc_div_id === 'false'){
            setDateRowHideShow("");
        }else if(loc_div_id === 'true'){
            setDateRowHideShow("none");
        }  
    }

    return(
        
        rowsData.map((data, index)=>{
            // console.log(data);
            // console.log(index);
            const {employer,position,employed_from,employed_to,employee_hash,employer_address,contact_number,supervisor,salary_date}= data;
            
            const final_instance_number = instanceNumber+index+1;                                
            const temp_employer = `sub_${final_instance_number}_field_977`;
            const temp_current = `sub_${final_instance_number}_field_978`;
            const temp_position = `sub_${final_instance_number}_field_979`;
            const temp_employed_from = `sub_${final_instance_number}_field_980`;
            const temp_employed_to = `sub_${final_instance_number}_field_981`;
            const temp_employee_hash = `sub_${final_instance_number}_field_982`;
            const temp_employer_address = `sub_${final_instance_number}_field_984`;
            const temp_contact_number = `sub_${final_instance_number}_field_985`;
            const temp_salary_date = `sub_${final_instance_number}_field_1069`;
            const temp_supervisor = `sub_${final_instance_number}_field_1077`;
            
            return(
                <tr key={index}>
                <td style={{ textAlign: "center" }}>
                    <input type="text" id="employer" name={temp_employer} value={employer} onChange={(evnt) => { handleEmployeeChange(index, evnt); }} style={{ textTransform: "uppercase" }}/>
                </td>

                <td style={{ textAlign: "center" }}>
                    <input type="checkbox" id="current" name={temp_current} value={chkCurr} onChange={(evnt) => { handleEmployeeChange(index, evnt);setchkCurr(!chkCurr) }} style={{ textTransform: "uppercase" }} onClick={(evnt) => { SalaryDateHideShow(evnt)}} /> 
                </td>

                <td style={{ textAlign: "center" }}>
                    <input type="text" id="position" name={temp_position} value={position} onChange={(evnt) => { handleEmployeeChange(index, evnt); }} className="text-uppercase" style={temp_position == PositionValidate ? {borderColor: colorFocus5} : null }/>
                </td>    

                <td style={{ textAlign: "center" }}>
                    <input type="date" id="from" name={temp_employed_from} value={employed_from} onChange={(evnt) => { handleEmployeeChange(index, evnt); }} style={{ textTransform: "uppercase" }}/>
                </td>

                <td style={{ textAlign: "center" }}>
                    <input type="date" id="to" name={temp_employed_to} value={employed_to} onChange={(evnt) => { handleEmployeeChange(index, evnt); }} style={{textTransform: "uppercase", display: DateRowHideShow}} />
                </td>

                <td style={{ textAlign: "center" }}>
                    <input type="text" id="employee_hash" name={temp_employee_hash} value={employee_hash} onChange={(evnt) => { handleEmployeeChange(index, evnt); }} style={{ textTransform: "uppercase" }}/>
                </td>

                <td style={{ textAlign: "center" }}>
                    <textarea rows="4" id="address" name={temp_employer_address} style={{ textTransform: "uppercase" }} value={employer_address} onChange={(evnt) => { handleEmployeeChange(index, evnt); }}></textarea>
                </td>

                <td style={{ textAlign: "center" }}>
                    <input type="text" id="contact" name={temp_contact_number} value={contact_number} onChange={(evnt) => { handleEmployeeChange(index, evnt); }} style={{ textTransform: "uppercase" }} />
                </td>

                <td style={{ textAlign: "center" }}>
                    <select id="salary_date" name={temp_salary_date} className="text-uppercase" value={salary_date} onChange={(evnt) => { handleEmployeeChange(index, evnt); setSalaryDateFlag(0); }} style={temp_salary_date == EmpValidateSalary ? {borderColor: colorFocus} : null } >
                        <option value="0">-- Select an option --</option>
                        {SalaryDateAll.map((item) => {
                            return (
                            <option key={item.id} value={item.id}>
                            {item.date}
                            </option>
                            );
                        })}
                    </select>
                </td>

                <td style={{ textAlign: "center" }}>
                    <input type="text" id="supervisor" name={temp_supervisor} value={supervisor} onChange={(evnt) => { handleEmployeeChange(index, evnt); }} style={{ textTransform: "uppercase" }} />
                </td>

                <td nowrap="nowrap" className="text-center">
                     <input type="image" name="delete_file" src={delete_row_img} width="16" onClick={() => { if (window.confirm('Are you sure you want to delete this row?')) { } }}/>
                </td>
            </tr>
            )
        })
   
    )
    
}
export default Bostwana_access_employee_details_table_rows;