import React from 'react'
import { Link } from 'react-router-dom';
import Template from '../Components/Template';
import IconReport from '../Images/Report/icon_reports.png';
import IconReportData from '../Images/Report/icon_report_data.png';
import IconFilterTypes from '../Images/Report/icon_filter_types.png';

const ReportMenu = () => {
    return (
        <>
            <Template />
            <div>
                <table width="100%" border="0" cellPadding={0} cellSpacing={0} className="custom-icon-menu bg-white">
                    <tbody>
                        <tr>
                            <td></td>
                            <td width="100%">
                                <table width="100%" border="0" cellPadding={0} cellSpacing={0} >
                                    <tbody>
                                        <tr>
                                            <td className="text-center" height="63"><h4> Reports</h4></td>
                                            {/* <td className="text-center">
                                                <Link to="/reports/folders" >
                                                    <img height="63" className="titleHover_ADD_USER" border="0" src={IconReport} alt='' />
                                                </Link>
                                            </td>

                                            <td className="text-center">
                                                <Link to="" >
                                                    <img height="63" className="titleHover_SEARCH_USER" border="0" src={IconReportData} alt='' />
                                                </Link>
                                            </td>

                                            <td className="text-center">
                                                <Link to="" >
                                                    <img height="63" className="titleHover_FINANCE_INSTITUTION" border="0" src={IconFilterTypes} alt='' />
                                                </Link>
                                            </td> */}
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table width="100%" height="13" border="0" cellPadding={0} cellSpacing={0} className="custom-icon-menu-shadow ">
                    <tbody>
                        <tr>
                            <td width="100%" className="custom-shadow-img"></td>
                        </tr>
                    </tbody>
                </table>


            </div>

        </>
    );
}

export default ReportMenu