import Admin_menu from "../Admin_menu"
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"
import axios from "../../axios";
import { useParams } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../Components/Loader"

function Product_options() {
   document.title = "Administration | Seriti - Product Admin";
   const division_id = localStorage.getItem("division_id");
   const user_id = localStorage.getItem("UserId");
   const super_user = localStorage.getItem("super_user");

   const location = useLocation()
   // const { loc } = location.state
   const { type } = location.state
   const { parentId } = location.state
   const { listParent } = useParams();
   const { productId } = useParams();
   // console.log(type);
   const [allData, setAllData] = useState([])
   const [productName, setproductName] = useState('')

   const [AllDetails, setAllDetails] = useState([])

   const [heading, setHeading] = useState('Add')

   const [sortingOrder, setSortingOrder] = useState('')
   const [sCode, setsCode] = useState('')
   const [sOption, setsOption] = useState('');
   const [sDescription, setsDescription] = useState('');
   const [sVatIndicator, setsVatIndicator] = useState(false);
   const [start_date, setstart_date] = useState('');
   const [end_date, setend_date] = useState('');
   const [paymentType, setPaymentType] = useState('');
   // console.log(paymentType);
   const [iMinVehicleAge, setiMinVehicleAge] = useState('');
   const [iMaxVehicleAge, setiMaxVehicleAge] = useState('');
   const [iMinOdometer, setiMinOdometer] = useState('');
   const [iMaxOdometer, setiMaxOdometer] = useState('');
   const [sManWarranty, setsManWarranty] = useState(false);
   const [sManMaintenance, setsManMaintenance] = useState('');
   const [iMaxAge, setiMaxAge] = useState('');
   const [iMinAge, setiMinAge] = useState('');
   const [sAgeType, setsAgeType] = useState('');
   const [sDepositInd, setsDepositInd] = useState('');
   const [sStartatInception, setsStartatInception] = useState('');
   const [sFinanceTermInd, setFinanceTermInd] = useState(false);

   const [sFinanceTerm, setsFinanceTerm] = useState('')
   //console.log(sFinanceTerm);
   const [sTermFilter, setsTermFilter] = useState('')
   const [sSecondInsured, setsSecondInsured] = useState('')
   const [fMinCover, setfMinCover] = useState(0)
   const [iFreeCover, setiFreeCover] = useState(0)
   const [fMaxCover, setfMaxCover] = useState(0)
   const [fResidualFactor, setfResidualFactor] = useState(0)
   const [sQualifier, setsQualifier] = useState(0)
   const [iSellingLower, setiSellingLower] = useState(0)
   const [iSellingUpper, setiSellingUpper] = useState(0)
   const [sNewUsed, setsNewUsed] = useState(1)
   const [sApprovedUsed, setsApprovedUsed] = useState('')
   const [sPurpose, setsPurpose] = useState(1)
   const [sServiceHistory, setsServiceHistory] = useState('')
   const [bPriceEditable, setbPriceEditable] = useState('')
   const [pc_check, setPC_check] = useState('') //radio Premium Price (Insurance)
   const [fProductPrice, setfProductPrice] = useState('') //if pc_check ==1
   const [fProductPrice2, setfProductPrice2] = useState('') //if pc_check ==1

   const [fWarrentyRate, setfWarrentyRate] = useState('') //if pc_check ==2

   const [fWarrentyPercentage, setfWarrentyPercentage] = useState('') //if pc_check ==4

   const [fDebtPercentage, setfDebtPercentage] = useState('') //if pc_check ==5
   const [fPriceMin, setfPriceMin] = useState('')
   const [fPriceMax, setfPriceMax] = useState('')
   const [sAddVat, setsAddVat] = useState(false)
   const [fPayoverAmount, setfPayoverAmount] = useState(0)
   const [sComIncludedInPayoverChecked, setsComIncludedInPayoverChecked] = useState(false)
   const [fCommAmount, setfCommAmount] = useState(0)
   const [iCommPerc, setiCommPerc] = useState(0)
   const [fCollectionFee, setfCollectionFee] = useState(0)
   const [fPolicyFee, setfPolicyFee] = useState(0)
   const [sInspectionSupplier, setsInspectionSupplier] = useState(false)
   const [fInspectionFee, setfInspectionFee] = useState(0)
   const [fDocFee, setfDocFee] = useState(0)
   const [sWordingFile, setsWording] = useState('')
   const [sBrochureFile, setsBrochure] = useState('')
   const [sTermsConditionsFile, setsTermsConditions] = useState('')
   const [sAdditionalMonths, setsAdditionalMonths] = useState('')
   const [iAdditionalKm, setiAdditionalKm] = useState(0)
   const [sExpiryMonths, setsExpiryMonths] = useState('')
   const [iExpiryKm, setiExpiryKm] = useState(0)
   const [bMonthly, setbMonthly] = useState(false)
   const [fMonthlyAmount, setfMonthlyAmount] = useState('')
   const [bFitment, setbFitment] = useState(false)
   const [sProductPackage, setsProductPackage] = useState(false)

   const [sCurrency, setsCurrency] = useState('')
   //console.log(sPPI_check);
   const [sBottomButton, setsBottomButton] = useState('Save')

   //--------------------------hide show flags----------------------------//
   const [max_term, setMaxTerm] = useState(true);
   const [term, setTermFlag] = useState(true)
   const [premPriceFlag, setPremPriceFlag] = useState(false)
   const [rateFlag, setRateFlag] = useState(false)
   const [regentFlag, setRegentFlag] = useState(false)
   const [perFlag, setPerFlag] = useState(false)
   const [pDebtFlag, setPDebtFlag] = useState(false)

   const [flag, setFlag] = useState(true) //page reload flag

   const [optionId, setOptionId] = useState('');//for edit case set id
   const [editFlag, setEditFlag] = useState(false)
   const [regent_rate, setRegentRate] = useState('')

   const [startFlag, setStartFlag] = useState(1)
   const [endFlag, setEndFlag] = useState(1)

   const [btnValue, setBtnValue] = useState('')
   const [copySortOrder, setCopySortOrder] = useState('')

   const [loader, setLoader] = useState(false);
   //-------------------------getting data--------------------------------// 
   useEffect(() => {
      if (flag) {
         const payload = {
            type: type,
            division_id: division_id
         };
         axios
            .post("options/" + productId, payload)
            .then((response) => {
               // console.log(response.data.data.options)
               setAllData(response.data.data.options);
               setproductName(response.data.data.product[0].product)
               setSortingOrder(response.data.data.sortingOrder);
               setCopySortOrder(response.data.data.sortingOrder);
               setsCurrency(response.data.data.sCurrency)
               setFlag(false)
            })
            .catch((response) => {
               console.log(response)
               //alert(response.data);
            });
      }
   }, [productId, type, division_id, flag]);

   const toggleMaxTerm = () => {
      // console.log("Before : " + term);
      (sFinanceTermInd === false) ? setMaxTerm(false) : setMaxTerm(true);
      setFinanceTermInd(!sFinanceTermInd)
   }

   const handleValidation = () => {
      var validate = true;
      var regexp = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/
      if (sortingOrder === '' || sCode === '' || sOption === '' || start_date === '' || fPayoverAmount === '') {
         alert('Please fill in all the fields!');
         validate = false;
      }
      if (fPayoverAmount !== '') {
         // alert('in')
         if (!regexp.test(fPayoverAmount)) {
            alert('Please enter a valid amount.');
            setfPayoverAmount('');
            validate = false;
         }
      }
      if (sWordingFile) {
         if (!sWordingFile.name.match(/\.(pdf)$/)) {
            alert('select valid wording file.');
            validate = false;
         }
      }
      if (sBrochureFile) {
         if (!sBrochureFile.name.match(/\.(pdf)$/)) {
            alert('select valid brochure file.');
            validate = false;
         }
      }
      if (sTermsConditionsFile) {
         if (!sTermsConditionsFile.name.match(/\.(pdf)$/)) {
            alert('select valid terms and conditions file.');
            validate = false;
         }
      }
      if (end_date !== '') {
         let date1;
         if (start_date === '')      //this is for add condition
            date1 = new Date().getTime(); //getting today date
         else                       //this is for edit conditin
            date1 = new Date(start_date).getTime();

         let date2 = new Date(end_date).getTime();
         if (date1 > date2) {
            alert('Start date is greater than End date. Please enter Valid End date');
            setend_date('');
            validate = false;
         }
      }
      return validate;
   };

   // console.log(btnValue,sBottomButton);
   //--------------------------------getting list-------------------------------------//
   const handleSubmit = (e) => {
      e.preventDefault();
      const validate = handleValidation();
      if (validate) {
         setLoader(true);
         var bodyFormData = new FormData();

         bodyFormData.append("user_id", user_id)
         bodyFormData.append("command", sBottomButton)  //Save/Update/Copy
         bodyFormData.append("product_id", productId)
         bodyFormData.append("parent_id", parentId)
         bodyFormData.append("selection", listParent)

         //session variables
         bodyFormData.append("user_id", user_id)
         bodyFormData.append("super_user", super_user)
         bodyFormData.append("division_id", division_id)

         bodyFormData.append("sort_order", sortingOrder)//1
         bodyFormData.append("code", sCode)             //2
         bodyFormData.append("option", sOption)         //3
         bodyFormData.append("description", sDescription)//4
         bodyFormData.append("vat_indicator", sVatIndicator ? 1 : 0)//5
         bodyFormData.append("start_date", start_date !== '' ? moment(start_date).format("YYYY-MM-DD") : 0)//6
         bodyFormData.append("end_date", end_date !== '' ? moment(end_date).format("YYYY-MM-DD") : 0)//7
         bodyFormData.append("payment_type", paymentType)//8

         //Option Requirements
         bodyFormData.append("min_vehicle_age", iMinVehicleAge)//9
         bodyFormData.append("max_vehicle_age", iMaxVehicleAge)//10
         bodyFormData.append("min_odometer", iMinOdometer)//11
         bodyFormData.append("max_odometer", iMaxOdometer)//12
         bodyFormData.append("manufacturer_warranty", sManWarranty ? 1 : 0)//13
         bodyFormData.append("manufacturer_maintenance", sManMaintenance ? 1 : 0)//14
         bodyFormData.append("max_age", iMaxAge)//15
         bodyFormData.append("min_age", iMinAge)//16
         bodyFormData.append("age_type", sAgeType)//17
         bodyFormData.append("deposit_ind", sDepositInd ? 1 : 0)//18
         bodyFormData.append("startat_inception", sStartatInception ? 1 : 0)//19
         bodyFormData.append("finance_term_ind", sFinanceTermInd ? 1 : 0)//20
         bodyFormData.append("finance_term", sFinanceTerm)//21
         bodyFormData.append("term_filter", sTermFilter ? 1 : 0)//22
         bodyFormData.append("second_insured", sSecondInsured ? 1 : 0)//23
         bodyFormData.append("min_cover", fMinCover)//24
         bodyFormData.append("free_cover", iFreeCover)//25
         bodyFormData.append("max_cover", fMaxCover)//26
         bodyFormData.append("residual_factor", fResidualFactor)//27
         bodyFormData.append("qualifier", sQualifier)//28
         bodyFormData.append("selling_lower", iSellingLower)//29
         bodyFormData.append("selling_upper", iSellingUpper)//30
         bodyFormData.append("new_used", sNewUsed)//31
         bodyFormData.append("approved_used", sApprovedUsed)//32
         bodyFormData.append("purpose", sPurpose)//33
         bodyFormData.append("service_history", sServiceHistory)//34

         //Option Price (All include VAT)
         bodyFormData.append("price_editable", bPriceEditable ? 1 : 0)//35
         bodyFormData.append("pc", 5)//36
         bodyFormData.append("price", fProductPrice ? fProductPrice : 0) //37
         bodyFormData.append("price2", fProductPrice2 ? fProductPrice2 : 0)// 38
         bodyFormData.append("warrenty_rate", fWarrentyRate ? fWarrentyRate : 0)//39
         bodyFormData.append("warrenty_percentage", fWarrentyPercentage ? fWarrentyPercentage : 0)//40
         bodyFormData.append("debt_percentage", fDebtPercentage ? fDebtPercentage : 0)//41
         bodyFormData.append("price_min", fPriceMin)//42
         bodyFormData.append("price_max", fPriceMax)//43
         bodyFormData.append("add_vat", sAddVat ? 1 : 0)//44
         bodyFormData.append("payover_amount", fPayoverAmount)//45
         bodyFormData.append("com_included_in_payover", sComIncludedInPayoverChecked ? 1 : 0)//46
         bodyFormData.append("comm_amount", fCommAmount)//47
         bodyFormData.append("comm_perc", iCommPerc)//48
         bodyFormData.append("collection_fee", fCollectionFee)//49
         bodyFormData.append("policy_fee", fPolicyFee)//50
         bodyFormData.append("inspection_supplier", sInspectionSupplier ? 1 : 0)//51
         bodyFormData.append("inspection_fee", fInspectionFee)//52
         bodyFormData.append("doc_fee", fDocFee)//53

         //Option Setup
         bodyFormData.append("wording_file", sWordingFile)//54
         bodyFormData.append("brochure_file", sBrochureFile)//55
         bodyFormData.append("terms_conditions_file", sTermsConditionsFile)//56
         bodyFormData.append("additional_months", sAdditionalMonths)//57
         bodyFormData.append("additional_km", iAdditionalKm)//58
         bodyFormData.append("expiry_months", sExpiryMonths)//59
         bodyFormData.append("expiry_km", iExpiryKm)//60
         bodyFormData.append("monthly", bMonthly ? 1 : 0)//61
         bodyFormData.append("monthly_amount", fMonthlyAmount)//62
         bodyFormData.append("fitment_centre", bFitment ? 1 : 0)//63
         bodyFormData.append("package_product", sProductPackage ? 1 : 0)//64

         // bodyFormData.append("newCommand", newCommand ? 1 : 0)//64
         bodyFormData.append("option_id", optionId)//65
         
         //console.log(bodyFormData);
         axios
            .post("add/product/options", bodyFormData)
            .then((response) => {
               // console.log(response)
               setLoader(false);
               setFlag(true)
               toast.success(response.data.message, {
                  position: toast.POSITION.TOP_CENTER
               });

               //---set all fields blank after add or update--//
               setHeading('Add')
               setSortingOrder(sortingOrder + 1);
               setsCode('')
               setsOption('')
               setsDescription('')
               setsVatIndicator(false)
               setstart_date('')
               setend_date('')
               setPaymentType('') //1
               setiMinVehicleAge('')
               setiMaxVehicleAge('')
               setiMinOdometer('')
               setiMaxOdometer('')
               setsManWarranty(false)
               setsManMaintenance('')
               setiMaxAge('')
               setiMinAge('')
               setsAgeType('')
               setsDepositInd('')
               setsStartatInception('')
               setFinanceTermInd(false)
               setsFinanceTerm('')
               setsTermFilter('')
               setsSecondInsured('')
               setfMinCover(0)
               setiFreeCover(0)
               setfMaxCover(0)
               setfResidualFactor(0)
               setsQualifier(0)
               setiSellingLower(0)
               setiSellingUpper(0)
               setsNewUsed(1)
               setsApprovedUsed('')
               setsPurpose(1)
               setsServiceHistory('')
               setbPriceEditable('')
               setPC_check('')
               setfProductPrice('')
               setfProductPrice2('')
               setfWarrentyRate('')
               setfWarrentyPercentage('')
               setfDebtPercentage('')
               setfPriceMin('')
               setfPriceMax('')
               setsAddVat(false)
               setfPayoverAmount(0)
               setsComIncludedInPayoverChecked(false)
               setfCommAmount(0)
               setiCommPerc(0)
               setfCollectionFee(0)
               setfPolicyFee(0)
               setsInspectionSupplier(false)
               setfInspectionFee(0)
               setfDocFee(0)
               setsWording('')
               setsBrochure('')
               setsTermsConditions('')
               setsAdditionalMonths('')
               setiAdditionalKm(0)
               setsExpiryMonths('')
               setiExpiryKm(0)
               setbMonthly(false)
               setfMonthlyAmount('')
               setbFitment(false)
               setsProductPackage(false)
               setsCurrency('')
               setsBottomButton('Save')
               setMaxTerm(true)
               setTermFlag(true)
               setPremPriceFlag(false)
               setRateFlag(false)
               setRegentFlag(false)
               setPerFlag(false)
               setPDebtFlag(false)
               setFlag(true)
               setOptionId('')
               setEditFlag(false)
               setRegentRate('')
               setStartFlag(1)
               setEndFlag(1)
               setBtnValue('')
               setCopySortOrder('')

               window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
               setLoader(false);
               if (response.response.data.status === 400);
               toast.error(response.response.data.data, {
                  position: toast.POSITION.TOP_CENTER
               });
            });
      }
   };

   //-------------------------------getting data for edit---------------------------//
   useEffect(() => {
      if (optionId !== '') {
         axios
            .get("get/product/edit/" + optionId)
            .then((response) => {
              //console.log(response)
               var obj = response.data.data.aProduct[0];
               //console.log(obj);
               setAllDetails(obj);
               setSortingOrder(copySortOrder)//for copy tab

               if (btnValue !== 'Copy') {
                  //alert('not in copy')
                  setSortingOrder(obj.sort_order);
                  setsCode(obj.code);
                  setsOption(obj.option)
               }
               setsDescription(obj.description);
               setsVatIndicator(obj.vat_indicator ? true : false);
               setstart_date(obj.start_date);
               setend_date(obj.end_date)
               if (obj.start_date === '') setStartFlag(0);
               if (obj.end_date === '') setEndFlag(0);
               setPaymentType(obj.payment_type);

               setiMinVehicleAge(obj.min_vehicle_age)
               setiMaxVehicleAge(obj.max_vehicle_age);
               setiMinOdometer(obj.min_odometer);
               setiMaxOdometer(obj.max_odometer)
               setsManWarranty(obj.manufacturer_warranty ? true : false);
               setsManMaintenance(obj.manufacturer_maintenance ? true : false);
               setiMaxAge(obj.max_age)
               setiMinAge(obj.min_age)
               setsAgeType(obj.age_type)
               setsDepositInd(obj.deposit_ind ? true : false)
               setsStartatInception(obj.startat_inception ? true : false)
               setFinanceTermInd(obj.finance_term_ind ? true : false);
               setsFinanceTerm(obj.finance_term);
               setsTermFilter(obj.term_filter ? true : false)
               setsSecondInsured(obj.second_insured ? true : false);
               setfMinCover(obj.min_cover);
               setiFreeCover(obj.free_cover)
               setfMaxCover(obj.max_cover);
               setfResidualFactor(obj.residual_factor);
               setsQualifier(obj.qualifier)
               setiSellingLower(obj.selling_lower)
               setiSellingUpper(obj.selling_upper)
               setsNewUsed(obj.new_used)
               setsApprovedUsed(obj.approved_used);
               setsPurpose(obj.purpose);
               setsServiceHistory(obj.service_history);
               setbPriceEditable(obj.price_editable ? true : false)

               setfProductPrice(obj.retail_price);
               setfProductPrice2(obj.retail_price2);

               setfWarrentyRate(obj.warrenty_rate)
               setfWarrentyPercentage(obj.warrenty_percentage)
               setfDebtPercentage(obj.debt_percentage)
               setfPriceMin(obj.price_min);
               setfPriceMax(obj.price_max);
               setsAddVat(obj.add_vat ? true : false);

               setfPayoverAmount(obj.payover_amount)
               setsComIncludedInPayoverChecked(obj.com_included_in_payover ? true : false)
               setfCommAmount(obj.comm_amount);
               setiCommPerc(obj.comm_perc)
               setfCollectionFee(obj.collection_fee)

               setfPolicyFee(obj.policy_fee)
               setsInspectionSupplier(obj.inspection_supplier ? true : false)
               setfInspectionFee(obj.inspection_fee);
               setfDocFee(obj.doc_fee)

               setsAdditionalMonths(obj.additional_months)
               setiAdditionalKm(obj.additional_km)
               setsExpiryMonths(obj.expiry_months)
               setiExpiryKm(obj.expiry_km)
               setbMonthly(obj.monthly ? true : false)
               setfMonthlyAmount(obj.monthly_price)
               setbFitment(obj.fitment_centre ? true : false)
               setsProductPackage(obj.package_product ? true : false)

               setRegentRate(obj.regent_rate)

               setEditFlag(true);

               obj.debt_percentage !== '' && obj.debt_percentage != null ? setPDebtFlag(true) : setPDebtFlag(false)
               obj.warrenty_percentage !== '' && obj.warrenty_percentage !== null ? setPerFlag(true) : setPerFlag(false)

               if((obj.retail_price !== "0" && obj.retail_price !== "" && obj.retail_price !== null) || (obj.retail_price2 !== "0" && obj.retail_price2 !== "" && obj.retail_price2 !== null)){
                  setPremPriceFlag(true);
               }else{
                  setPremPriceFlag(false);
               }

               obj.regent_rate !== 0 && obj.regent_rate !== '' && obj.regent_rate !== null ? setPerFlag(true) : setPerFlag(false)
               obj.warrenty_rate !== "0" && obj.warrenty_rate !== '' && obj.warrenty_rate !== null ? setRateFlag(true) : setRateFlag(false)
               
            })
            .catch((response) => {
               //console.log(response)
               //alert(response.data);
            });
      }
   }, [optionId, btnValue, copySortOrder]);
   // console.log(regent_rate);

   useEffect(() => {
      // if (editFlag) {
      //    (fProductPrice !== "0" || fProductPrice2 !== "0") ? setPC_check(1) : ((fWarrentyRate !== "0") ? setPC_check(2) : ((regent_rate !== 0) ? setPC_check(3) : (fWarrentyPercentage !== "0" ? setPC_check(4) : (fDebtPercentage !== "0" ? setPC_check(5) : setPC_check('')))));

      //    if (fProductPrice !== "0" || fProductPrice2 !== "0") setPremPriceFlag(true);
      //    else if (fWarrentyRate !== "0") setRateFlag(true);
      //    else if (fWarrentyPercentage != "" && fWarrentyPercentage != null) setPerFlag(false)
      //    else if (fDebtPercentage !== "0") setPDebtFlag(true)
      //    setEditFlag(false);
      // }

      if((fProductPrice !== "0" && fProductPrice !== "" && fProductPrice !== null) || (fProductPrice2 !== "0" && fProductPrice2 !== "" && fProductPrice2 !== null)){
         setPC_check(1);
      }else if(fWarrentyRate !== "0" && fWarrentyRate !== null && fWarrentyRate !== ""){
         setPC_check(2);
      }else if(regent_rate !== 0 && regent_rate !== null && regent_rate !== ""){
         setPC_check(3);
      }else if(fWarrentyPercentage !== "0" && fWarrentyPercentage !== null && fWarrentyPercentage !== ""){
         setPC_check(4);
      }else if(fDebtPercentage !== "0" && fDebtPercentage !== null && fDebtPercentage !== ""){
         setPC_check(5);
      }else{
         setPC_check('')
      }

   }, [editFlag, fProductPrice, fProductPrice2, fWarrentyRate, regent_rate, fWarrentyPercentage, fDebtPercentage]);

   //--------------------------Enable or Disable options---------------------------//
   const handleEnable = (cmd, id) => {
      if (cmd === 'Enable' || cmd === 'Disable') {
         // alert(cmd)
         var bodyFormData = new FormData();
         bodyFormData.append("newCommand", cmd)//64
         bodyFormData.append("option_id", id)//65
         axios
            .post("product/options/enable", bodyFormData)
            .then((response) => {
               //console.log(response.data.data)
               toast.success(response.data.message, {
                  position: toast.POSITION.TOP_CENTER
               });
               setFlag(true)
            })
            .catch((response) => {
               if (response.response.data.status === 400);
               toast.error(response.response.data.data, {
                  position: toast.POSITION.TOP_CENTER
               });
            });
      }
   }

   return (
      <>
         <ToastContainer theme="colored" style={{ width: "450px" }} />
         <Admin_menu />
         {loader ? <Loader /> : null}
         <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
            <tbody>
               <tr valign="top" className="bg-white">
                  <td></td>
                  <td width="100%" className="bg-white">
                     <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                     <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                        <tbody>
                           <tr>
                              <td valign="top" align="center">
                                 <form method="post" action="" onSubmit={handleSubmit}>
                                    <input type="hidden" name="command" value="Search" />
                                    <table className="defaultTbl" width="1000" cellSpacing="1">
                                       <tbody>
                                          <tr>
                                             <th colSpan={11} className="text-center">Financial Options for Product:{productName}</th>
                                          </tr>
                                          <tr>
                                             <td className="caption"></td>
                                             {/* <td className="caption"></td> */}
                                             <td className="caption"></td>
                                             <td className="caption">ID</td>
                                             <td className="caption" width={250}>Option Name</td>
                                             <td className="caption">Start Date</td>
                                             <td className="caption">End Date</td>
                                             <td className="caption" width={100}>Premium Price</td>
                                             <td className="caption" width={100}>Retail Price</td>
                                             <td className="caption" width={100}>Payover Amount</td>
                                             <td className="caption"></td>
                                          </tr>
                                          {
                                             allData && allData.length ? (
                                                allData.map((item, index) => {
                                                   return (
                                                      <tr key={index}>
                                                         <td>
                                                            <input type="button" name="edit" value="Edit" onClick={(e) => { setsBottomButton('Update'); setSortingOrder(index + 1); setHeading('Edit'); setOptionId(item.id); setBtnValue(e.target.value) }} />
                                                         </td>
                                                         {/* <td>
                                                            <input type="button" name="test" value="Test" />
                                                         </td> */}
                                                         <td style={{ textAlign: "center" }}>
                                                            <input type="button" name="copy" value="Copy" onClick={(e) => { setsBottomButton('Save'); setHeading('Add'); setOptionId(item.id); setBtnValue(e.target.value) }} />
                                                         </td>
                                                         <td> {item.id} </td>
                                                         <td> {item.option} </td>
                                                         <td nowrap="true"> {item.start_date} </td>
                                                         <td nowrap="true"> {item.end_date} </td>

                                                         {/* Premium Price */}
                                                         <td> {item.p1} </td>

                                                         {/* Retail Price */}
                                                         <td> {item.p2} </td>

                                                         {/* Payover Amount	 */}
                                                         <td> {item.p3} </td>

                                                         <td><input type="button" name={item.active} value={item.active} onClick={(e) => handleEnable(item.active, item.id)} /></td>
                                                      </tr>
                                                   );
                                                })
                                             ) : <tr><td colSpan={10}>"This product is not linked to any Group or Branch yet."</td></tr>
                                          }
                                          <tr>
                                             <td colSpan={3} className="caption text-center" ><input type="button" name="command" value="Product Fields" /></td>
                                             <td colSpan={8} className="caption text-center" >
                                                <Link to="/admin/financial_products" state={{ type: parentId, listParent: listParent }}>
                                                   <input type="submit" name="command" value="Back To Product List" />
                                                </Link>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                    {/* </form> */}
                                    <br />

                                    {/* <form method="post" > */}
                                    <table className="defaultTbl" width="800">
                                       <tbody>
                                          <tr>
                                             <th colSpan="10" className="text-center">{heading} Product Option: {productName}</th>
                                          </tr>

                                          <tr>
                                             <td className="caption" width="250">Sorting Order<span style={{ color: "red" }}> *</span></td>
                                             <td width="600"><input type="text" name="sort_order" value={sortingOrder} size="3" onChange={(e) => setSortingOrder(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Option Code<span style={{ color: "red" }}> *</span></td>
                                             <td><input type="text" className="text-uppercase" name="code" value={sCode} size="10" onChange={(e) => setsCode(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Option Name<span style={{ color: "red" }}> *</span></td>
                                             <td><input type="text" className="text-uppercase" name="option" value={sOption} size="60" onChange={(e) => setsOption(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Display Name</td>
                                             <td><input type="text" className="text-uppercase" name="description" value={sDescription} size="50" onChange={(e) => setsDescription(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">VAT indicator</td>
                                             <td><input type="checkbox" name="vat_indicator" value={sVatIndicator} onChange={(e) => { setsVatIndicator(!sVatIndicator) }} checked={sVatIndicator} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Start date<span style={{ color: "red" }}> *</span></td>
                                             <td>
                                                {startFlag === 1 ?
                                                   <DatePicker id="start_date" name="start_date" value={start_date} onChange={(date) => { setstart_date(date); setStartFlag(0) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                   :
                                                   <DatePicker id="start_date" name="start_date" selected={start_date} onChange={(date) => setstart_date(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                }
                                             </td>
                                          </tr>

                                          <tr>
                                             <td className="caption">End date</td>
                                             <td>
                                                {endFlag === 1 ?
                                                   <DatePicker id="end_date" name="end_date" value={end_date} onChange={(date) => { setend_date(date); setEndFlag(0) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />

                                                   :

                                                   <DatePicker id="end_date" name="end_date" selected={end_date} onChange={(date) => setend_date(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                }
                                             </td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Payment type</td>
                                             <td>
                                                <input type="radio" name="payment_type" value="1" onClick={(e) => setTermFlag(false)} onChange={(e) => { setPaymentType(e.target.value) }} checked={paymentType == 1} />&nbsp;Single&nbsp;

                                                <input type="radio" name="payment_type" value="2" onClick={(e) => setTermFlag(true)} onChange={(e) => { setPaymentType(e.target.value) }} checked={paymentType == 2} />&nbsp;Monthly&nbsp;

                                                <input type="radio" name="payment_type" value="3" onClick={(e) => setTermFlag(false)} onChange={(e) => { setPaymentType(e.target.value) }} checked={paymentType == 3} />&nbsp;Annual&nbsp;
                                             </td>
                                          </tr>

                                          <tr>
                                             <td colSpan={2} className="caption"><div align="center">Option Requirements</div></td>
                                          </tr>

                                          <tr >
                                             <td className="caption">Minimum Vehicle Age in months</td>
                                             <td><input type="text" name="min_vehicle_age" value={iMinVehicleAge} size="3" onChange={(e) => setiMinVehicleAge(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Maximum Vehicle Age in months</td>
                                             <td><input type="text" name="max_vehicle_age" value={iMaxVehicleAge} size="3" onChange={(e) => setiMaxVehicleAge(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Minimum Odometer Reading</td>
                                             <td><input type="text" name="min_odometer" value={iMinOdometer} onChange={(e) => setiMinOdometer(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Maximum Odometer Reading</td>
                                             <td><input type="text" name="max_odometer" value={iMaxOdometer} onChange={(e) => setiMaxOdometer(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Active Manufacturer Warranty</td>
                                             <td><input type="checkbox" name="manufacturer_warranty" value={sManWarranty} onChange={(e) => { setsManWarranty(!sManWarranty) }} checked={sManWarranty} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Active Manufacturer Maintenance</td>
                                             <td><input type="checkbox" name="manufacturer_maintenance" value={sManMaintenance} onChange={(e) => { setsManMaintenance(!sManMaintenance) }} checked={sManMaintenance} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Maximum Person Age</td>
                                             <td><input type="text" name="max_age" size="3" value={iMaxAge} onChange={(e) => setiMaxAge(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Minimum Person Age</td>
                                             <td><input type="text" name="min_age" size="3" value={iMinAge} onChange={(e) => setiMinAge(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">PDP Age Type</td>
                                             <td>
                                                <input type="radio" name="age_type" value="1" onChange={(e) => { setsAgeType(e.target.value) }} checked={sAgeType == 1} /> Entry Age
                                                <input type="radio" name="age_type" value="2" onChange={(e) => { setsAgeType(e.target.value) }} checked={sAgeType == 2} /> Cover Age
                                             </td> 
                                          </tr>

                                          <tr>
                                             <td className="caption">Deposit Indicator</td>
                                             <td><input type="checkbox" name="deposit_ind" value={sDepositInd} onChange={(e) => { setsDepositInd(!sDepositInd) }} checked={sDepositInd} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Start at Inception</td>
                                             <td><input type="checkbox" name="startat_inception" value={sStartatInception} onChange={(e) => { setsStartatInception(!sStartatInception) }} checked={sStartatInception} /></td>
                                          </tr>

                                          {term && (
                                             <tr>
                                                <td className="caption">Finance Term Indicator</td>
                                                <td><input type="checkbox" name="finance_term_ind" onChange={(e) => toggleMaxTerm()} value={sFinanceTermInd} checked={sFinanceTermInd} /></td>
                                             </tr>
                                          )}

                                          {max_term && (
                                             <tr>
                                                <td className="caption">Term of Cover / Max Term</td>
                                                <td>  
                                                   <input type="radio" name="finance_term" value="0" onChange={(e) => { setsFinanceTerm(e.target.value) }} checked={sFinanceTerm == 0} /> N/A&nbsp;
                                                   <input type="radio" name="finance_term" value="96" onChange={(e) => { setsFinanceTerm(e.target.value) }} checked={sFinanceTerm == 96} /> 96&nbsp;
                                                   <input type="radio" name="finance_term" value="84" onChange={(e) => { setsFinanceTerm(e.target.value) }} checked={sFinanceTerm == 84} /> 84&nbsp;
                                                   <input type="radio" name="finance_term" value="72" onChange={(e) => { setsFinanceTerm(e.target.value) }} checked={sFinanceTerm == 72} /> 72&nbsp;
                                                   <input type="radio" name="finance_term" value="60" onChange={(e) => { setsFinanceTerm(e.target.value) }} checked={sFinanceTerm == 60} /> 60&nbsp;
                                                   <input type="radio" name="finance_term" value="54" onChange={(e) => { setsFinanceTerm(e.target.value) }} checked={sFinanceTerm == 54} /> 54&nbsp;
                                                   <input type="radio" name="finance_term" value="48" onChange={(e) => { setsFinanceTerm(e.target.value) }} checked={sFinanceTerm == 48} /> 48&nbsp;
                                                   <input type="radio" name="finance_term" value="42" onChange={(e) => { setsFinanceTerm(e.target.value) }} checked={sFinanceTerm == 42} /> 42&nbsp;
                                                   <input type="radio" name="finance_term" value="36" onChange={(e) => { setsFinanceTerm(e.target.value) }} checked={sFinanceTerm == 36} /> 36&nbsp;
                                                   <input type="radio" name="finance_term" value="30" onChange={(e) => { setsFinanceTerm(e.target.value) }} checked={sFinanceTerm == 30} /> 30&nbsp;
                                                   <input type="radio" name="finance_term" value="24" onChange={(e) => { setsFinanceTerm(e.target.value) }} checked={sFinanceTerm == 24} /> 24&nbsp;
                                                   <input type="radio" name="finance_term" value="18" onChange={(e) => { setsFinanceTerm(e.target.value) }} checked={sFinanceTerm == 18} /> 18&nbsp;
                                                   <input type="radio" name="finance_term" value="12" onChange={(e) => { setsFinanceTerm(e.target.value) }} checked={sFinanceTerm == 12} /> 12&nbsp;
                                                   <input type="radio" name="finance_term" value="6" onChange={(e) => { setsFinanceTerm(e.target.value) }} checked={sFinanceTerm == 6} /> 6
                                                </td>
                                             </tr>
                                          )}

                                          <tr>
                                             <td className="caption">FILTER ON TERM</td>
                                             <td><input type="checkbox" name="term_filter" value={sTermFilter} onChange={(e) => { setsTermFilter(!sTermFilter) }} checked={sTermFilter} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Second Insured</td>
                                             <td><input type="checkbox" name="second_insured" value={sSecondInsured} onChange={(e) => { setsSecondInsured(!sSecondInsured) }} checked={sSecondInsured} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Minimum Cover Amount</td>
                                             <td><input type="text" name="min_cover" value={fMinCover} onChange={(e) => setfMinCover(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Maximum Free Cover Limit</td>
                                             <td><input type="text" name="free_cover" value={iFreeCover} onChange={(e) => setiFreeCover(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Maximum Cover Amount</td>
                                             <td><input type="text" name="max_cover" value={fMaxCover} onChange={(e) => setfMaxCover(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Residual Factor</td>
                                             <td><input type="text" name="residual_factor" value={fResidualFactor} onChange={(e) => setfResidualFactor(e.target.value)} />%</td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Maximum Cover Qualifier</td>
                                             <td>
                                                <input type="radio" name="qualifier" value="0" onChange={(e) => { setsQualifier(e.target.value) }} checked={sQualifier == 0}  />&nbsp;N/A&nbsp;
                                                <input type="radio" name="qualifier" value="1" onChange={(e) => { setsQualifier(e.target.value) }} checked={sQualifier == 1} />&nbsp;Ineligible&nbsp;
                                                <input type="radio" name="qualifier" value="2" onChange={(e) => { setsQualifier(e.target.value) }} checked={sQualifier == 2} />&nbsp;Restricted&nbsp;
                                             </td>  
                                          </tr>

                                          <tr>
                                             <td className="caption">Selling Price Range (Upper/Lower)</td>
                                             <td>
                                                <input type="text" name="selling_lower" value={iSellingLower} onChange={(e) => setiSellingLower(e.target.value)} />&nbsp;
                                                to
                                                &nbsp;<input type="text" name="selling_upper" value={iSellingUpper} onChange={(e) => setiSellingUpper(e.target.value)} />
                                             </td>
                                          </tr>

                                          <tr>
                                             <td className="caption">New/Used</td>
                                             <td>
                                                <input type="radio" name="new_used" value="1" onChange={(e) => { setsNewUsed(e.target.value) }} checked={sNewUsed == 1} />&nbsp;NEW&nbsp;
                                                <input type="radio" name="new_used" value="2" onChange={(e) => { setsNewUsed(e.target.value) }} checked={sNewUsed == 2} />&nbsp;USED&nbsp;
                                                <input type="radio" name="new_used" value="3" onChange={(e) => { setsNewUsed(e.target.value) }} checked={sNewUsed == 3} />&nbsp;BOTH&nbsp;
                                             </td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Manufacturer Approved Used Vehicle</td>
                                             <td>
                                                <input type="radio" name="approved_used" value="1" onChange={(e) => { setsApprovedUsed(e.target.value) }} checked={sApprovedUsed == 1} />&nbsp;YES&nbsp;
                                                <input type="radio" name="approved_used" value="2" onChange={(e) => { setsApprovedUsed(e.target.value) }} checked={sApprovedUsed == 2} />&nbsp;NO&nbsp;
                                                <input type="radio" name="approved_used" value="3" onChange={(e) => { setsApprovedUsed(e.target.value) }} checked={sApprovedUsed == 3} />&nbsp;BOTH&nbsp;
                                             </td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Vehicle Purpose</td>
                                             <td>
                                                <input type="radio" name="purpose" value="1" onChange={(e) => { setsPurpose(e.target.value) }} checked={sPurpose == 1} />&nbsp;PRIVATE&nbsp;
                                                <input type="radio" name="purpose" value="2" onChange={(e) => { setsPurpose(e.target.value) }} checked={sPurpose == 2} />&nbsp;BUSINESS&nbsp;
                                                <input type="radio" name="purpose" value="3" onChange={(e) => { setsPurpose(e.target.value) }} checked={sPurpose == 3} />&nbsp;TAXI&nbsp;
                                                <input type="radio" name="purpose" value="6" onChange={(e) => { setsPurpose(e.target.value) }} checked={sPurpose == 6} />&nbsp;HCV&nbsp;
                                                <input type="radio" name="purpose" value="4" onChange={(e) => { setsPurpose(e.target.value) }} checked={sPurpose == 4} />&nbsp;NOT TAXI&nbsp;
                                                <input type="radio" name="purpose" value="5" onChange={(e) => { setsPurpose(e.target.value) }} checked={sPurpose == 5} />&nbsp;ALL&nbsp;
                                             </td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Full Service History</td>
                                             <td>
                                                <input type="radio" name="service_history" value="1" onChange={(e) => { setsServiceHistory(e.target.value) }} checked={sServiceHistory == 1} />&nbsp;YES&nbsp;
                                                <input type="radio" name="service_history" value="2" onChange={(e) => { setsServiceHistory(e.target.value) }} checked={sServiceHistory == 2} />&nbsp;NO&nbsp;
                                                <input type="radio" name="service_history" value="3" onChange={(e) => { setsServiceHistory(e.target.value) }} checked={sServiceHistory == 3} />&nbsp;BOTH&nbsp;
                                             </td>
                                          </tr>

                                          <tr>
                                             <td colSpan={2} className="caption"><div align="center">Option Price (All include VAT)</div></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Price Editable</td>
                                             <td><input type="checkbox" name="price_editable" value={bPriceEditable} onChange={(e) => { setbPriceEditable(!bPriceEditable) }} checked={bPriceEditable} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Premium Price (Insurance)</td>
                                             <td>
                                                <input type="radio" name="pc" value="1" onClick={(e) => { setPremPriceFlag(true); setRateFlag(false); setRegentFlag(false); setPerFlag(false); setPDebtFlag(false); setfWarrentyRate(''); setfWarrentyPercentage(''); setfDebtPercentage('') }} onChange={(e) => { setPC_check(e.target.value) }} checked={pc_check == 1} />&nbsp;
                                                {premPriceFlag && (
                                                   <input type="text" name="price" value={fProductPrice} id="ppi" className="sPPI" onChange={(e) => setfProductPrice(e.target.value)} />
                                                )}  

                                             </td>
                                          </tr>

                                          {premPriceFlag && (
                                             <tr id="ppin" className="sPPIN" >
                                                <td className="caption">Premium Price (Non-insurance)</td>
                                                <td>
                                                   &nbsp; &nbsp; &nbsp;<input type="text" name="price2" value={fProductPrice2} onChange={(e) => setfProductPrice2(e.target.value)} />
                                                </td>
                                             </tr>
                                          )}

                                          <tr>
                                             <td className="caption">Rate (per {sCurrency} 1000)</td>
                                             <td>
                                                <input type="radio" name="pc" value="2" onClick={(e) => { setRateFlag(true); setPremPriceFlag(false); setRegentFlag(false); setPerFlag(false); setPDebtFlag(false); setfProductPrice(''); setfProductPrice2(''); setfWarrentyPercentage(''); setfDebtPercentage(''); }} onChange={(e) => { setPC_check(e.target.value) }} checked={pc_check == 2} />&nbsp;
                                                {rateFlag && (
                                                   <input type="text" name="warrenty_rate" value={fWarrentyRate} id="rate" className="sRate" onChange={(e) => setfWarrentyRate(e.target.value)} />
                                                )}
                                             </td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Rate (Regent)</td>
                                             <td>
                                                <input type="radio" name="pc" value="3" onClick={(e) => { setRegentFlag(true); setPremPriceFlag(false); setRateFlag(false); setPerFlag(false); setPDebtFlag(false); setfProductPrice(''); setfProductPrice2(''); setfWarrentyRate(''); setfWarrentyPercentage(''); setfDebtPercentage(''); }} onChange={(e) => { setPC_check(e.target.value) }} checked={pc_check == 3} />
                                             </td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Price: Percentage of Asset</td>
                                             <td>
                                                <input type="radio" name="pc" value="4" onClick={(e) => { setPerFlag(true); setPremPriceFlag(false); setRateFlag(false); setRegentFlag(false); setPDebtFlag(false); setfProductPrice(''); setfProductPrice2(''); setfWarrentyRate(''); setfDebtPercentage('') }} onChange={(e) => { setPC_check(e.target.value) }} checked={pc_check == 4} />&nbsp;
                                                {perFlag && (
                                                   <input type="text" name="warrenty_percentage" value={fWarrentyPercentage} size="4" id="perc" className="sPerc" onChange={(e) => setfWarrentyPercentage(e.target.value)} />
                                                )}
                                             </td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Price: Percentage of Debt</td>
                                             <td>
                                                <input type="radio" name="pc" value="5" onClick={(e) => { setPDebtFlag(true); setPremPriceFlag(false); setRateFlag(false); setRegentFlag(false); setPerFlag(false); setfProductPrice(''); setfProductPrice2(''); setfWarrentyRate(''); setfWarrentyPercentage(''); }} onChange={(e) => { setPC_check(e.target.value) }} checked={pc_check == 5} />&nbsp;
                                                {pDebtFlag && (
                                                   <input type="text" name="debt_percentage" value={fDebtPercentage} size="4" id="percD" className="sPercD" onChange={(e) => setfDebtPercentage(e.target.value)} />)} %

                                             </td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Price: Min</td>
                                             <td>
                                                <input type="text" name="price_min" value={fPriceMin} onChange={(e) => setfPriceMin(e.target.value)} />
                                             </td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Price: Max</td>
                                             <td>
                                                <input type="text" name="price_max" value={fPriceMax} onChange={(e) => setfPriceMax(e.target.value)} />
                                             </td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Add Vat to Price</td>
                                             <td>
                                                <input type="checkbox" name="add_vat" value={sAddVat} onChange={(e) => { setsAddVat(!sAddVat) }} checked={sAddVat} />
                                             </td>
                                          </tr>

                                          <tr>
                                             <td colSpan={2} className="caption"><div align="center">Option Payments (All Include VAT)</div></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Payover Amount<span style={{ color: "red" }}> *</span></td>
                                             <td><input type="text" name="payover_amount" value={fPayoverAmount} onChange={(e) => setfPayoverAmount(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Commission Included in Payover</td>
                                             <td><input type="checkbox" name="com_included_in_payover" value={sComIncludedInPayoverChecked} onChange={(e) => { setsComIncludedInPayoverChecked(!sComIncludedInPayoverChecked) }} checked={sComIncludedInPayoverChecked} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Commission Amount</td>
                                             <td><input type="text" name="comm_amount" value={fCommAmount} onChange={(e) => setfCommAmount(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Commission Percentage</td>
                                             <td><input type="text" size="3" name="comm_perc" value={iCommPerc} onChange={(e) => setiCommPerc(e.target.value)} />%</td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Collection Fee</td>
                                             <td><input type="text" name="collection_fee" value={fCollectionFee} onChange={(e) => setfCollectionFee(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Policy Fee</td>
                                             <td><input type="text" name="policy_fee" value={fPolicyFee} onChange={(e) => setfPolicyFee(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Inspection paid to supplier</td>
                                             <td><input type="checkbox" size="3" name="inspection_supplier" value={sInspectionSupplier} onChange={(e) => { setsInspectionSupplier(!sInspectionSupplier) }} checked={sInspectionSupplier} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Inspection Fee</td>
                                             <td><input type="text" name="inspection_fee" value={fInspectionFee} onChange={(e) => setfInspectionFee(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Dealer Document Fee</td>
                                             <td><input type="text" name="doc_fee" value={fDocFee} onChange={(e) => setfDocFee(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td colSpan={2} className="caption"><div align="center">Option Setup</div></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Wording</td>
                                             {/* <img src={sWording} width="130" height="80"/> */}
                                             <td><input type="file" name="wording_file" onChange={(e) => setsWording(e.target.files[0])} />
                                              
                                                <a href={AllDetails.wording_url} target="_blank">{AllDetails.wording !== '' ? AllDetails.wording : ''}</a>
                                             </td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Brochure</td>
                                             <td><input type="file" name="brochure_file" onChange={(e) => setsBrochure(e.target.files[0])} />
                           
                                                 <a href={AllDetails.brochure_url} target="_blank">{AllDetails.brochure !== '' ? AllDetails.brochure : ''}</a>
                                              </td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Terms and Conditions</td>
                                             <td><input type="file" name="terms_conditions_file" onChange={(e) => setsTermsConditions(e.target.files[0])} /> 
                                             
                                               <a href={AllDetails.terms_conditions_url} target="_blank">{AllDetails.terms_conditions !== '' ? AllDetails.terms_conditions : ''}</a>
                                             </td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Additional Months</td>
                                             <td>
                                                <input type="radio" name="additional_months" value="0" onChange={(e) => { setsAdditionalMonths(e.target.value) }} checked={sAdditionalMonths == 0} />&nbsp;N/A&nbsp;
                                                <input type="radio" name="additional_months" value="84" onChange={(e) => { setsAdditionalMonths(e.target.value) }} checked={sAdditionalMonths == 84} />&nbsp;84&nbsp;
                                                <input type="radio" name="additional_months" value="72" onChange={(e) => { setsAdditionalMonths(e.target.value) }} checked={sAdditionalMonths == 72} />&nbsp;72&nbsp;
                                                <input type="radio" name="additional_months" value="60" onChange={(e) => { setsAdditionalMonths(e.target.value) }} checked={sAdditionalMonths == 60} />&nbsp;60&nbsp;
                                                <input type="radio" name="additional_months" value="54" onChange={(e) => { setsAdditionalMonths(e.target.value) }} checked={sAdditionalMonths == 54} />&nbsp;54&nbsp;
                                                <input type="radio" name="additional_months" value="48" onChange={(e) => { setsAdditionalMonths(e.target.value) }} checked={sAdditionalMonths == 48} />&nbsp;48&nbsp;
                                                <input type="radio" name="additional_months" value="42" onChange={(e) => { setsAdditionalMonths(e.target.value) }} checked={sAdditionalMonths == 42} />&nbsp;42&nbsp;
                                                <input type="radio" name="additional_months" value="36" onChange={(e) => { setsAdditionalMonths(e.target.value) }} checked={sAdditionalMonths == 36} />&nbsp;36&nbsp;
                                                <input type="radio" name="additional_months" value="30" onChange={(e) => { setsAdditionalMonths(e.target.value) }} checked={sAdditionalMonths == 30} />&nbsp;30&nbsp;
                                                <input type="radio" name="additional_months" value="24" onChange={(e) => { setsAdditionalMonths(e.target.value) }} checked={sAdditionalMonths == 24} />&nbsp;24&nbsp;
                                                <input type="radio" name="additional_months" value="18" onChange={(e) => { setsAdditionalMonths(e.target.value) }} checked={sAdditionalMonths == 18} />&nbsp;18&nbsp;
                                                <input type="radio" name="additional_months" value="12" onChange={(e) => { setsAdditionalMonths(e.target.value) }} checked={sAdditionalMonths == 12} />&nbsp;12&nbsp;
                                                <input type="radio" name="additional_months" value="6" onChange={(e) => { setsAdditionalMonths(e.target.value) }} checked={sAdditionalMonths == 6} />&nbsp;6
                                             </td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Additional Kilometres</td>
                                             <td><input type="text" name="additional_km" value={iAdditionalKm} onChange={(e) => setiAdditionalKm(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Expiry Months</td>
                                             <td>
                                                <input type="radio" name="expiry_months" value="0" onChange={(e) => { setsExpiryMonths(e.target.value) }} checked={sExpiryMonths == 0} />&nbsp;N/A&nbsp;
                                                <input type="radio" name="expiry_months" value="120" onChange={(e) => { setsExpiryMonths(e.target.value) }} checked={sExpiryMonths == 120} />&nbsp;120&nbsp;
                                                <input type="radio" name="expiry_months" value="84" onChange={(e) => { setsExpiryMonths(e.target.value) }} checked={sExpiryMonths == 84} />&nbsp;84&nbsp;
                                                <input type="radio" name="expiry_months" value="72" onChange={(e) => { setsExpiryMonths(e.target.value) }} checked={sExpiryMonths == 72} />&nbsp;72&nbsp;
                                                <input type="radio" name="expiry_months" value="60" onChange={(e) => { setsExpiryMonths(e.target.value) }} checked={sExpiryMonths == 60} />&nbsp;60&nbsp;
                                                <input type="radio" name="expiry_months" value="54" onChange={(e) => { setsExpiryMonths(e.target.value) }} checked={sExpiryMonths == 54} />&nbsp;54&nbsp;
                                                <input type="radio" name="expiry_months" value="48" onChange={(e) => { setsExpiryMonths(e.target.value) }} checked={sExpiryMonths == 48} />&nbsp;48&nbsp;
                                                <input type="radio" name="expiry_months" value="42" onChange={(e) => { setsExpiryMonths(e.target.value) }} checked={sExpiryMonths == 42} />&nbsp;42&nbsp;
                                                <input type="radio" name="expiry_months" value="36" onChange={(e) => { setsExpiryMonths(e.target.value) }} checked={sExpiryMonths == 36}/>&nbsp;36&nbsp;
                                                <input type="radio" name="expiry_months" value="30" onChange={(e) => { setsExpiryMonths(e.target.value) }} checked={sExpiryMonths == 30} />&nbsp;30&nbsp;
                                                <input type="radio" name="expiry_months" value="24" onChange={(e) => { setsExpiryMonths(e.target.value) }} checked={sExpiryMonths == 24} />&nbsp;24&nbsp;
                                                <input type="radio" name="expiry_months" value="18" onChange={(e) => { setsExpiryMonths(e.target.value) }} checked={sExpiryMonths == 18} />&nbsp;18&nbsp;
                                                <input type="radio" name="expiry_months" value="12" onChange={(e) => { setsExpiryMonths(e.target.value) }} checked={sExpiryMonths == 12} />&nbsp;12&nbsp;
                                                <input type="radio" name="expiry_months" value="6" onChange={(e) => { setsExpiryMonths(e.target.value) }} checked={sExpiryMonths == 6} />&nbsp;6
                                             </td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Expiry Kilometres</td>
                                             <td><input type="text" name="expiry_km" value={iExpiryKm} onChange={(e) => setiExpiryKm(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Monthly Subscription</td>
                                             <td>
                                                <input type="checkbox" name="monthly" value={bMonthly} onChange={(e) => { setbMonthly(!bMonthly) }} checked={bMonthly} />&nbsp;
                                                <input type="input" name="monthly_amount" value={fMonthlyAmount} onChange={(e) => setfMonthlyAmount(e.target.value)} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Fitment Centre</td>
                                             <td><input type="checkbox" name="fitment_centre" value={bFitment} onChange={(e) => { setbFitment(!bFitment) }} checked={bFitment} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption">Package Product</td>
                                             <td><input type="checkbox" name="package_product" value={sProductPackage} onChange={(e) => { setsProductPackage(!sProductPackage) }} checked={sProductPackage} /></td>
                                          </tr>

                                          <tr>
                                             <td className="caption" colSpan="2">
                                                <div align="center">
                                                   <input type="submit" value={sBottomButton} />
                                                </div>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </form>

                                 <p></p>
                              </td>
                           </tr>

                        </tbody>
                     </table>
                  </td>
                  <td></td>

               </tr >
            </tbody >
         </table >
      </>
   );
}

export default Product_options