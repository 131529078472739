import VechileAccessories_menu from "./VechileAccessories_menu"
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"
import axios from "../../axios";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Modify_product() {
    document.title = "Products | Seriti - Vehicle & Accessories";
    const UserId = localStorage.getItem("UserId");
    const { productId } = useParams();
    const [productName, setProductName] = useState('')
    const [category, setCategory] = useState('');
    const [allCategory, setAllCategory] = useState([]);
    const [costPrice, setCostPrice] = useState('');
    const [selling_price, setSellingPrice] = useState('')
    const [ProductDescription, setProductDescription] = useState('')
    const [template, setTemplate] = useState('');
    const [allTemplate, setAllTemplate] = useState([]);
    const [Enabled, setEnabled] = useState(false);
    const [DeleteBtn, setDeleteBtn] = useState(false);
    // console.log(Enabled)
    //----------------------------fetch product data----------------------------------// 
    useEffect(() => {
        axios
            .get("/product/get/" + productId)
            .then((response) => {
                // console.log(response.data.data[0])
                var obj = response.data.data[0]
                setProductName(obj.name)
                setCategory(obj.category_id)
                setCostPrice(obj.cost_price);
                setSellingPrice(obj.selling_price)
                setProductDescription(obj.description)
                setTemplate(obj.template_id)
                setAllTemplate(obj.sTemplateSelect)
                setEnabled(obj.sActiveChecked)
                // if (obj.sDeleteButton) {
                setDeleteBtn(obj.sDeleteButton)
                // }
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, [productId]);

    //----------------------------dropdown of product----------------------------------// 
    useEffect(() => {
        //console.log(collection);
        axios
            .get("/product/dropdown")
            .then((response) => {
                // console.log(response.data.data)
                setAllCategory(response.data.data);
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, []);

    //---------------------handle validation------------------------//
    const handleValidation = () => {
        var flag = true;
        // var regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
        const regexp = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/
        if (category === '0' || category === '') {
            alert('Please select Product Type.');
            flag = false;
        }
        if (productName === '') {
            alert('Please enter at least one character.');
            flag = false;
        } else if (costPrice === '' || selling_price === '') {
            alert('Please enter a valid amount.');
            flag = false;
        } else if (!regexp.test(costPrice)) {
            alert('Please enter a valid amount.');
            flag = false;
        } else if (!regexp.test(selling_price)) {
            alert('Please enter a valid amount.');
            flag = false;
        }
        return flag;
    };

    //---------------------------------edit data--------------------------------------//
    // console.log(template)
    const handleSubmit = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        if (flag) {
            const payload = {
                user_id: UserId,
                productId: productId,
                category: category,//product type
                name: productName,
                cost_price: costPrice,
                selling_price: selling_price,
                template: template,
                product_description: ProductDescription,
                active: Enabled ? 1 : 0,
            };
            axios
                .post("product/edit", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data.message);
                        // alert(response.data.message)
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        })
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    if (response.response.data.status === 400)
                    toast.error(response.response.data.data, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    };

    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <VechileAccessories_menu />
            <form method="post" action="" onSubmit={handleSubmit} >
                <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                    <tbody>
                        <tr valign="top" className="bg-white">
                            <td></td>
                            <td width="100%" className="bg-white">
                                <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                                <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center">

                                                <table className="defaultTbl" width="500" cellSpacing="1">
                                                    <tbody>
                                                        <tr><th colSpan={2} className="text-center">Modify Product: {productName}</th></tr>
                                                        <tr><td className="caption">Product Type<span style={{ color: "red" }}> *</span></td><td>
                                                            <select name="category" id="category" className="text-uppercase" value={category} onChange={(e) =>
                                                                setCategory(e.target.value)} style={{ width: '382px' }}>
                                                                {/* onChange = {(e) => setBranch(e.target.value)} */}
                                                                <option value="0">-- Select option --</option>
                                                                {allCategory.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id} >
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </td></tr>

                                                        <tr>
                                                            <td width="30%" className="caption">Name<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="text" name="name" value={productName} size="60" validate="text" className="text-uppercase" onChange={(e) => setProductName(e.target.value)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Cost Price<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="text" name="cost_price" value={costPrice} validate="currency" onChange={(e) => setCostPrice(e.target.value)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Selling Price<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="text" name="selling_price" value={selling_price} validate="currency" onChange={(e) => setSellingPrice(e.target.value)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Product Description</td>
                                                            <td><textarea name="product_description" className="text-uppercase" cols="50" rows="4" value={ProductDescription} onChange={(e) => setProductDescription(e.target.value)}></textarea>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Template</td>
                                                            <td>
                                                                <select name="template" id="template" className="text-uppercase" value={template} onChange={(e) =>
                                                                    setTemplate(e.target.value)} style={{ width: '382px' }}>
                                                                    {/* onChange = {(e) => setBranch(e.target.value)} */}
                                                                    <option value="0">-- Select option --</option>
                                                                    {allTemplate.map((item) => {
                                                                        return (
                                                                            <option key={item.value} value={item.value} >
                                                                                {item.text}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Enabled</td>
                                                            <td>
                                                                <input type="checkbox" value={Enabled} name="active" checked={Enabled} onChange={(e) => { setEnabled(!Enabled) }} />
                                                            </td>
                                                        </tr>

                                                        <tr className="caption">
                                                            <td colSpan="2" className="text-center">
                                                                <Link to={"/product/attributes_capture"} state={{ productId: productId, template_id: template }}>
                                                                    <input type="button" name="command" value="Attributes" /></Link>&nbsp;</td>
                                                        </tr>

                                                        <tr className="caption">
                                                            <td colSpan="2" className="text-center">
                                                                <input type="submit" name="command" value="Save" />

                                                                <Link to={"/products/search/new_product"} >
                                                                    <input type="button" name="cancel" value="Cancel" />
                                                                </Link>

                                                                {DeleteBtn &&
                                                                    <input type="submit" name="command" value="Delete" onClick="if (!confirm(\'Are you sure you want to delete '.$aProduct['name'].'?\')) return false;" />
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <p></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    )
}

export default Modify_product