import { Link, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from "react";
import delete_row_img from '../Images/icons/x.png';
import { NumericFormat } from 'react-number-format';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "../axios";
import Transaction_menu from "../Transaction/Transaction_menu";
import Footer from "../Dashboard/Footer";
import Documents_table_rows from "../Transaction/Documents_table_rows";
import Bank_account_table_rows from "../Transaction/Bank_account_table_rows";
import Regular_drivers_table_rows from "../Transaction/Regular_drivers_table_rows";
import bank_of_baroda_img from '../Images/logo/Bank of Baroda2.jpg';
import capital_bank_img from '../Images/logo/Capital Bank.png';
import standard_chareted_bank_img from '../Images/logo/StandardChartered logo.png';

import Loader from "../Components/Loader"

const Finance_application_capital_baroda_standard_bank = () => {

    const navigate = useNavigate();

    const [loader, setLoader] = useState(false);
    const [RowHideShow, setRowHideShow] = useState("none");
    const [AddNewButton, setAddNewButton] = useState("table-row");
    const [RowHideShow1, setRowHideShow1] = useState("none");
    const [AddNewButton1, setAddNewButton1] = useState("table-row");
    const [RowHideShow2, setRowHideShow2] = useState("none");
    const [AddNewButton2, setAddNewButton2] = useState("table-row");

    const [UserNotesDetails, setUserNotesDetails] = useState([]);
    const [SystemNotesDetails, setSystemNotesDetails] = useState([]);
    const division_id = localStorage.getItem("division_id");
    const department_id = localStorage.getItem("department_id");
    const super_user = localStorage.getItem("super_user");
    const system_type = localStorage.getItem("system_type");
    const user_id = localStorage.getItem("UserId");
    const company_id = localStorage.getItem("company_id");
    const form_id = localStorage.getItem("form_id");
    const group_id = localStorage.getItem("group_id");
    const transaction_id = localStorage.getItem("transaction_id");
    const instance_id = localStorage.getItem("instance_id");
    const FillFormTabId = localStorage.getItem("FillFormTabId");
    const unincept_flag = localStorage.getItem("unincept_flag");
    const loanhouse_id = localStorage.getItem("loanhouse_id");
    const application_id = localStorage.getItem("application_id") ? localStorage.getItem("application_id") : 0;

    const [note_save, setNoteSave] = useState("");
    const [UserNote, setUserNote] = useState("");
    const [SystemNote, setSystemNote] = useState("");
    const [CheckButtonRight, setCheckButtonRight] = useState("");

    const HiddenData = localStorage.getItem("HiddenData") ? JSON.parse(localStorage.getItem("HiddenData")) : [];
    //------------- document
    const [DocumentDetails, setDocumentDetails] = useState([]);
    const [DocumentTypeAll, setDocumentTypeAll] = useState([]);
    const [DocumentEditArray, setDocumentEditArray] = useState([]);
    const [DocumentKycCheckboxArray, setDocumentKycCheckboxArray] = useState([]);

    const [rowsAddDocumentData, setRowsAddDocumentData] = useState([]);
    const [docsControlInstanceNumber, setdocsControlInstanceNumber] = useState(0);
    const [flag, setFlag] = useState(0);
    const [validateflag, setvalidateflag] = useState(0);
    const [colorFocus, setcolorFocus] = useState("");
    const [docsvalidateDescName, setdocsvalidateDescName] = useState("");
    const [DocumentDatazero, setDocumentDatazero] = useState(0);
    const [DocumentCommand, setDocumentCommand] = useState("");
    const [DocumentPdf, setFile] = useState("");
    const [FileAddFlag, setFileAddFlag] = useState(0);
    const [FileEditName, setFileEditName] = useState("");
    const [handleDeleteDocsFlag, sethandleDeleteDocsFlag] = useState(0);
    const [TypeDiscription, setTypeDiscription] = useState("");
    const [TypeDocument, setTypeDocument] = useState("");
    const [FileDocument, setFileDocument] = useState("");

    // console.log(FileEditName);
    //---------- bank account
    const [BankAccounttDetails, setBankAccounttDetails] = useState([]);
    const [BankAccounttEditArray, setBankAccounttEditArray] = useState([]);
    const [BankAccounttCheckboxArray, setBankAccounttCheckboxArray] = useState([]);

    const [AccountHolderAll, setAccountHolderAll] = useState([]);
    const [BankNameAll, setBankNameAll] = useState([]);
    const [AccountTypeAll, setAccountTypeAll] = useState([]);
    const [BankDatazero, setBankDatazero] = useState(0);
    const [rowsBankData, setRowsBankData] = useState([]);
    const [BankControlInstanceNumber, setBankControlInstanceNumber] = useState(0);
    const [BankCommand, setBankCommand] = useState("");
    const [handleDeleteFlag, sethandleDeleteFlag] = useState(0);
    const [BankDipFlag, setBankDipFlag] = useState(0);
    const [BankchkDipFlag, setBankchkDipFlag] = useState(0);
    const [chkPrimaryAc, setchkPrimaryAc] = useState(false);

    //-------------- regular drivers
    const [RegularDriverSelectDetails, setRegularDriverSelectDetails] = useState([]);
    const [RegularDriverAddDetails, setRegularDriverAddDetails] = useState([]);
    const [RegularDriverEditDetails, setRegularDriverEditDetails] = useState([]);
    const [rowsAddDriversData, setRowsAddDriversData] = useState([]);
    const [DriverDetailsAll, setDriverDetailsAll] = useState([]);
    const [GenderAll, setGenderAll] = useState([]);
    const [CfgAll, setCfgAll] = useState([]);
    const [RegularDriverzero, setRegularDriverzero] = useState(0);
    const [DriverCommand, setDriverCommand] = useState("");
    const [handledrDeleteFlag, sethandledrDeleteFlag] = useState(0);
    const [DriverDipFlag, setDriverDipFlag] = useState(0);
    const [DriverAddFlag, setDriverAddFlag] = useState(false);
    const [DriverLicenseDateAll, setDriverLicenseDateAll] = useState([]); 
    
    const [driversControlInstanceNumber, setdriversControlInstanceNumber] = useState(0);
    const [RegdValidate, setRegdValidate] = useState(""); 
    const [colorFocus1, setcolorFocus1] = useState(""); 
    const [rowsAddContactData2, setrowsAddContactData2] = useState([]);
    
    //console.log(RegularDriverSelectDetails);
    //---------------- insurance details
    const [InsuranceDetails, setInsuranceDetails] = useState([]);
    const [insuranceImUserRadio, setinsuranceImUserRadio] = useState("");
    const [insuranceMakeModel, setinsuranceMakeModel] = useState("");
    const [insuranceSerialNo, setinsuranceSerialNo] = useState("");
    const [insuranceSumInsured, setinsuranceSumInsured] = useState("");
    const [insuranceCommand, setinsuranceCommand] = useState("");
    const [AddEntryDiplayFlag, setAddEntryDiplayFlag] = useState("");
    
    
    useEffect(() => {
        document.title = 'Transactions | Seriti BW';
    }, []);

    //---------------- generate pdf and redirect url -------------
    const GeneratePrintPdf = ()=>{
        //alert('as');
        setLoader(true);
        const payload = {
            transaction_id: transaction_id,
            application_id: application_id,
            instance_id: instance_id,
            group_id: group_id,
            loanhouse_id: loanhouse_id,
            system_type: system_type,
            user_id: user_id,
            division_id: division_id,
            department: department_id,
            command: "Print",
        };
        axios
        .post("finance_application/finance_pdf_calling", payload)
        .then((response) => {
            //console.log(response);
            if(response.status == 200){
               //window.location.reload(false);
               navigate('/transaction/prepare/application_id/'+response.data.data+'/regenerated=true');
               setLoader(false);
            } 
        })
        .catch(() => {
            setLoader(false);
           console.log('error');
        }); 
    }
    
     //----------- get driver details -----------------
     useEffect(() => { 
        const payload = {
            company_id: company_id,
            instance_id: HiddenData.instance_id,
        };

        axios
          .post("fill_forms/featch_driver_details_new",payload)
          .then((response) => {
            //console.log(response.data.data.other_data);
            if(response.data.data.other_data === 1){
                setAddEntryDiplayFlag(1);
            }else{
                setAddEntryDiplayFlag(0);
            }
             
          })
          .catch(() => {
             console.log('error');
          });
    }, []);

    //----------- get type id -----------------
    useEffect(() => {
        axios
            .get("fill_forms/featch_document_type")
            .then((response) => {
                //console.log(response.data.type_array);
                if (response.data.type_array != '') {
                    setDocumentTypeAll(response.data.type_array);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get account holder name -----------------
    useEffect(() => {
        const payload = {
            company_id: company_id,
        };
        // console.log(payload);
        axios
            .post("fill_forms/featch_bank_account_holder", payload)
            .then((response) => {
                // console.log(response);
                if (response.data) {
                    setAccountHolderAll(response.data.account_holder_array);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);


    //----------- get bank name -----------------
    useEffect(() => {
        axios
            .get("fill_forms/featch_bank_name")
            .then((response) => {
                //console.log(response.data.type_array);
                if (response.data.bank_name_array != '') {
                    setBankNameAll(response.data.bank_name_array);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get DRIVERS LICENSE ISSUE DATE -----------------
    useEffect(() => {
        axios
            .get("fill_forms/regular_driver_year")
            .then((response) => {
                //console.log(response.data.data.drivers_issue_year_array);
                if (response.data != '') {
                    setDriverLicenseDateAll(response.data.data.drivers_issue_year_array);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get account type -----------------
    useEffect(() => {
        axios
            .get("fill_forms/featch_account_type")
            .then((response) => {
                //console.log(response.data.type_array);
                if (response.data.account_type_array != '') {
                    setAccountTypeAll(response.data.account_type_array);
                }

            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get driver details -----------------
    useEffect(() => {
        const payload = {
            company_id: company_id,
        };

        axios
            .post("fill_forms/featch_driver_details", payload)
            .then((response) => {
                //console.log(response);
                setDriverDetailsAll(response.data.data);
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get gender / cfg -----------------
    useEffect(() => {
        axios
            .get("fill_forms/featch_gender_claim_free_group")
            .then((response) => {
                //console.log(response.data.gender_array);
                setGenderAll(response.data.gender_array);
                setCfgAll(response.data.cfg_array);
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------------- featch insurance details --------------------
    // console.log(InsuranceDetails.immobilister);
    useEffect(() => {
        setLoader(true);
        const payload = {
            transaction_id: HiddenData.transaction_id,
            instance_id: HiddenData.instance_id,
            navigate_form_id: 71,
        };
        // console.log(payload);
        axios
            .post("fill_forms/insurance_details_drivers", payload)
            .then((response) => {
                //console.log(response.data);
                if (response.status === 200) {
                    setInsuranceDetails(response.data.data[0])
                    setinsuranceImUserRadio(response.data.data[0].immobilister)
                    setinsuranceMakeModel(response.data.data[0].make_model)
                    setinsuranceSerialNo(response.data.data[0].serial_no)
                    if (response.data.data[0].sum_insured) {
                        setinsuranceSumInsured(response.data.data[0].sum_insured)
                    } else {
                        setinsuranceSumInsured('0.00');
                    }
                }
                setLoader(false);
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
    }, []);


    //----------------- featch bank account data --------------------
    useEffect(() => {
        setLoader(true);
        const payload = {
            transaction_id: HiddenData.transaction_id,
            instance_id: HiddenData.instance_id,
            navigate_form_id: 46,
        };
        //console.log(payload);
        axios
            .post("fill_forms/featch_bank_account_details", payload)
            .then((response) => {
                // console.log(response.data.data.checkbox_edit_array);
                if (response.status === 200) {
                    setBankAccounttDetails(response.data.data.select_array)
                    setBankAccounttEditArray(response.data.data.edit_array)
                    setBankAccounttCheckboxArray(response.data.data.checkbox_edit_array)
                }
                setLoader(false);
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
    }, [BankDipFlag]);

    //----------------- featch document data --------------------
    useEffect(() => {
        setLoader(true);
        const payload = {
            transaction_id: HiddenData.transaction_id,
            instance_id: HiddenData.instance_id,
            navigate_form_id: 24,
        };
        //console.log(payload);
        axios
            .post("fill_forms/featch_documents", payload)
            .then((response) => {
               // console.log(response);
                if (response.status === 200) {
                    setDocumentDetails(response.data.data.select_array)
                    setDocumentEditArray(response.data.data.edit_array)
                    //setDocumentCheckboxArray(response.data.data.kyc_checkbox_edit_array)
                    setDocumentKycCheckboxArray(response.data.data.kyc_checkbox_edit_array)
                   // setDocumentSelectCheckboxArray(response.data.data.select_checkbox_edit_array)
                }
                setLoader(false);
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
    }, []);

    //----------------- featch regular driver details --------------------
    useEffect(() => {
            setLoader(true);
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                form_id: 73,
                company_id: company_id,
            };
            //console.log(payload);
            axios
                .post("fill_forms/featch_regular_drivers", payload)
                .then((response) => {
                //console.log(response);
                    if (response.status === 200) {
                        setRegularDriverSelectDetails(response.data.data.select_array);
                        setRegularDriverEditDetails(response.data.data.edit_array);
                        setDriverAddFlag(false);
                    }
                    setLoader(false);
                })
                .catch(() => {
                    console.log('error');
                    setLoader(false);
                });
    }, [DriverDipFlag,DriverAddFlag]);


    //----------------- featch note data --------------------
    useEffect(() => {
        setLoader(true);
        const payload = {
            user_id: user_id,
            super_user: super_user,
            transaction_id: HiddenData.transaction_id,
            instance_id: HiddenData.instance_id,
            user_notes: 165,
            system_notes: 166,
        };
        // console.log(payload);
        axios
            .post("fill_forms/featch_notes", payload)
            .then((response) => {
                // console.log(response.data);
                if (response.status === 200) {
                    setUserNotesDetails(response.data.user_note_data)
                    setSystemNotesDetails(response.data.system_note_data)
                    setCheckButtonRight(response.data.other_data.bAllowWrite)
                }
                setLoader(false);
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
    }, []);

    const addNoteValidation = () => {
        var flag = true;
        if (UserNote === "" && SystemNote === "") {
            alert("Please Enter User/System Note");
            flag = false;
        }
        return flag;
    };

    //------------ Add Notes ------------
    const handleAddNotes = (e) => {
        e.preventDefault();
        const flag = addNoteValidation();
        if (flag) {
            setLoader(true);
            const payload = {
                user_id: user_id,
                transaction_id: HiddenData.transaction_id,
                instance_id: HiddenData.instance_id,
                form_id: 13,
                command: note_save,
                application_id: application_id,
                group_id: 16,
                sub_1_field_165: UserNote,
                sub_1_field_166: SystemNote,
            };
            //console.log(payload);
            axios
                .post("fill_forms/add_notes", payload)
                .then((response) => {
                    // console.log(response);
                    if (response.status === 200) {
                        if (response.data.other_data.command === "Save and Exit" || response.data.other_data.command != '') {
                            navigate('/transaction/create_transaction/' + response.data.other_data.transaction_id);
                        } else {
                            toast.success(response.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                        }
                    }
                    setLoader(false);
                    window.location.reload(false);
                })
                .catch(() => {
                    console.log('error');
                    setLoader(false);
                });
        }
    }

    //------------ Add/Edit bank Account Details------------
    const handleAddBankDocument = (e) => {
        e.preventDefault();
        
        if (handleDeleteFlag == 0) {
            setLoader(true);
            const payload = {
                user_id: user_id,
                transaction_id: HiddenData.transaction_id,
                instance_id: HiddenData.instance_id,
                form_id: 46,
                command: BankCommand,
                application_id: application_id,
                group_id: 16,
                add_bank_data: rowsBankData,
                edit_bank_data: BankAccounttEditArray,
                checkbox_data: BankAccounttCheckboxArray,
            };
          //  console.log(payload);
            axios
                .post("fill_forms/add_bank_account_details", payload)
                .then((response) => {
                  //console.log(response);
                    if (response.status === 200) {
                        if (response.data.other_data.command === "Save and Exit") {
                            navigate('/transaction/create_transaction/' + response.data.other_data.transaction_id);
                        } else {
                            // window.location.reload(false);
                            if (response.data.other_data.command != '') {
                                toast.success(response.data.message, {
                                    position: toast.POSITION.TOP_CENTER
                                });
                                //window.location.reload(false);
                            }
                        }
                    }
                    window.location.reload(false);
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }
    }

    //------------ Add/Edit Regular Driver Details------------

    const DriverDetailsValidation = () => {
        // console.log(rowsAddContactData2);
        var flag = true;
        if(rowsAddContactData2 != ''){      // add array validation
            if(rowsAddContactData2[0]['driver_details'] == 0){
                 alert("Please select driver details");
                 setcolorFocus1('red');
                 flag = false;
            }
        }
        return flag;
     }

     
    const handleAddRegularDriver = (e) => {
        e.preventDefault();

        if (handledrDeleteFlag == 0) {
            const flag1 = DriverDetailsValidation();
            if (flag1) {
            setLoader(true);
            const payload = {
                user_id: user_id,
                transaction_id: transaction_id,
                instance_id: instance_id,
                form_id: 73,
                command: DriverCommand,
                application_id: application_id,
                group_id: 16,
                company_id: company_id,
                add_driver_data: RegularDriverAddDetails,
                edit_driver_data: RegularDriverEditDetails,
            };
         // console.log(payload);
            axios
                .post("fill_forms/add_regular_driver_details", payload)
                .then((response) => {
                  // console.log(response);
                    if (response.status === 200) {
                        if (response.data.other_data.command === "Save and Exit") {
                            navigate('/transaction/create_transaction/' + transaction_id);
                        } else {
                            toast.success(response.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            setDriverAddFlag(true);
                           //navigate('/transaction/fill_forms')
                            window.location.reload(false);
                        }   
                    }
                    setLoader(false);
                })
                .catch(() => {
                    console.log('error');
                    setLoader(false);
                });
        }
      }
    }

    //------------ Add Document ------------
    const handleValidation = () => {
        var flag1 = true;

        if (validateflag == 1 && TypeDocument == 0) {
            alert("Document type is mandatory");
            //setcolorFocus('red')
            flag1 = false;
        } else if (validateflag == 1 && TypeDiscription == "") {
            alert("Description field is mandatory");
            setcolorFocus('red')
            flag1 = false;
        } else if (validateflag == 1 && FileDocument == "") {
            alert("File is required");
            // setcolorFocus('red')
            flag1 = false;
        }
        return flag1;
    };

    //console.log(handleDeleteDocsFlag);
    const handleAddDocuments = (e) => {
       // console.log('hgfh');
        e.preventDefault();
        if (handleDeleteDocsFlag == 0) {
          //  console.log('ss');
            const flag1 = handleValidation();
            if (flag1) {
               // setLoader(true);
                const payload = {
                    user_id: user_id,
                    transaction_id: HiddenData.transaction_id,
                    instance_id: HiddenData.instance_id,
                    form_id: 24,
                    command: DocumentCommand,
                    application_id: application_id,
                    group_id: 16,
                    edit_docs_data: DocumentEditArray,
                    kyc_checkbox_docs_data: DocumentKycCheckboxArray,
                    //select_checkbox_docs_data: DocumentSelectCheckboxArray,
                    add_docs_array: rowsAddDocumentData,
                };

                //console.log(payload);
                axios
                    .post("fill_forms/add_document_details", payload)
                    .then((response) => {
                        //console.log(response);
                        if (response.status === 200) {
                            if (response.data.other_data.command === "Save and Exit") {
                                navigate('/transaction/create_transaction/' + response.data.other_data.transaction_id);
                            } else {
                                if (response.data.other_data.command) {
                                    toast.success(response.data.message, {
                                        position: toast.POSITION.TOP_CENTER
                                    });
                                    window.location.reload(false);
                                }

                            }
                        }
                        //setLoader(false);
                    })
                    .catch(() => {
                        console.log('error');
                    });
        }

        var bodyFormData = new FormData();
        bodyFormData.append("user_id", user_id);
        bodyFormData.append("transaction_id", HiddenData.transaction_id);
        bodyFormData.append("instance_id", HiddenData.instance_id);
        bodyFormData.append("command", DocumentCommand);
        bodyFormData.append("form_id", 24);
        bodyFormData.append("application_id", application_id);
        bodyFormData.append("group_id", 16);
        bodyFormData.append("DocumentFileName", FileEditName);
        bodyFormData.append(FileEditName, DocumentPdf);
        bodyFormData.append("control_instance", docsControlInstanceNumber);

        
        axios
            .post("fill_forms/add_new_document_details", bodyFormData)
            .then((response) => {
                // console.log(response);
                setLoader(false);
            })
            .catch(() => {
                console.log('error');
            });
        }
    }

    //------------ Add Insurance ------------
    const handleAddInsurance = (e) => {
        e.preventDefault();
        setLoader(true);
        const payload = {
            user_id: user_id,
            transaction_id: HiddenData.transaction_id,
            instance_id: HiddenData.instance_id,
            form_id: 71,
            group_id: 16,
            command: insuranceCommand,
            application_id: application_id,
            sub_1_field_1430: insuranceMakeModel,
            sub_1_field_1431: insuranceSumInsured,
            sub_1_field_1432: insuranceSerialNo,
            sub_1_field_1434: insuranceImUserRadio,
        };
         //console.log(payload);

        axios
            .post("fill_forms/add_insurance_details", payload)
            .then((response) => {
                // console.log(response.data);
                if (response.status === 200) {
                    if (response.data.other_data.command === "Save and Exit") {
                        navigate('/transaction/create_transaction/' + response.data.other_data.transaction_id);
                    } else {
                        // window.location.reload(false);
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                }
                setLoader(false);
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
    }


    const RemoveZeroVal = (e) => {
        if (e === 1 && insuranceSumInsured === '0.00') { setinsuranceSumInsured("") }
    }
    const ShowZero = (e) => {
        if (e === 1 && insuranceSumInsured === '') { setinsuranceSumInsured("0.00") }
    }

    //-------- regular document (button hide/show)
    const documentShowRow = (e) => {
        const loc_div_id = e.currentTarget.id;
        if (loc_div_id == 'add_new_entry') {
            const RowHideShow = "table-row";
            const AddNewButton = "none";
            setRowHideShow(RowHideShow);
            setAddNewButton(AddNewButton)
        } else if (loc_div_id == 'remove_new_entry') {
            const RowHideShow = "none";
            const AddNewButton = "table-row";
            setRowHideShow(RowHideShow);
            setAddNewButton(AddNewButton)
        }
    }

    //-------- bank account (button hide/show)
    const bankShowRow = (e) => {
        const loc_div_id1 = e.currentTarget.id;
        if (loc_div_id1 == 'add_new_entry') {
            const RowHideShow1 = "table-row";
            const AddNewButton1 = "none";
            setRowHideShow1(RowHideShow1);
            setAddNewButton1(AddNewButton1)
        } else if (loc_div_id1 == 'remove_new_entry') {
            const RowHideShow1 = "none";
            const AddNewButton1 = "table-row";
            setRowHideShow1(RowHideShow1);
            setAddNewButton1(AddNewButton1)
        }
    }

    //-------- regular driver (button hide/show)
    const driversShowRow = (e) => {
        const loc_div_id2 = e.currentTarget.id;
        if (loc_div_id2 == 'add_new_entry') {
            const RowHideShow2 = "table-row";
            const AddNewButton2 = "none";
            setRowHideShow2(RowHideShow2);
            setAddNewButton2(AddNewButton2)
        } else if (loc_div_id2 == 'remove_new_entry') {
            const RowHideShow2 = "none";
            const AddNewButton2 = "table-row";
            setRowHideShow2(RowHideShow2);
            setAddNewButton2(AddNewButton2)
        }
    }

    //-------------- main tab hide / show
    const [div_id1, setHide1] = useState("table");
    const [div_id2, setHide2] = useState("none");
    const [div_id3, setHide3] = useState("none");
    const [div_id4, setHide4] = useState("none");
    const [div_id5, setHide5] = useState("none");

    const main_form_id = HiddenData.form_id;
    //localStorage.setItem("FillFormTabId", main_form_id);
    useEffect(() => {

        if (main_form_id == 13) {   //notes
            setHide1("table");
            settabWidth(800);
        } else if (main_form_id == 24) {  //documents
            setHide2("table")
            setHide1("none");
            settabWidth(800);
        } else if (main_form_id == 71) {  // insurance_details
            setHide4("table")
            setHide1("none");
            settabWidth(800);
        } else if (main_form_id == 73) {  // regular driver details
            setHide5("table")
            setHide1("none");
            settabWidth(1668);
        } else if (main_form_id == 46 || form_id == 46 && main_form_id != 71) { //bank_account
            setHide3("table")
            setHide1("none");
            settabWidth(1095);

        }

    }, [main_form_id]);

    const [tabWidth, settabWidth] = useState(800);

    const mainDivHideShow = event => {
        const main_div_id = event.currentTarget.id;

        if (main_div_id == 'notes') {
            setHide1('table'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('none');
            settabWidth(800);
            localStorage.setItem("FillFormTabId", 13);
        } else if (main_div_id == 'documents') {
            setHide1('none'); setHide2('table'); setHide3('none'); setHide4('none'); setHide5('none');
            settabWidth(800);
            localStorage.setItem("FillFormTabId", 24);
        } else if (main_div_id == 'bank_account') {
            setHide1('none'); setHide2('none'); setHide3('table'); setHide4('none'); setHide5('none');
            settabWidth(1102);
            localStorage.setItem("FillFormTabId", 46);
        } else if (main_div_id == 'insurance_details') {
            setHide1('none'); setHide2('none'); setHide3('none'); setHide4('table'); setHide5('none');
            settabWidth(800);
            localStorage.setItem("FillFormTabId", 71);
        } else if (main_div_id == 'regular_drivers') {
            setHide1('none'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('table');
            settabWidth(1668);
            localStorage.setItem("FillFormTabId", 73);
        } else {

        }
    }

    useEffect(() => {
        if (FillFormTabId == 13) {   //notes
            setHide1('table'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('none');
            settabWidth(800);
        } else if (FillFormTabId == 24) {  //documents
            setHide1('none'); setHide2('table'); setHide3('none'); setHide4('none'); setHide5('none');
            settabWidth(800);
        }else if (FillFormTabId == 46) {  // bank details
            setHide1('none'); setHide2('none'); setHide3('table'); setHide4('none'); setHide5('none');
            settabWidth(1102);
        } else if (FillFormTabId == 71) {  // insurance_details
            setHide1('none'); setHide2('none'); setHide3('none'); setHide4('table'); setHide5('none');
            settabWidth(800);
        } else if (FillFormTabId == 73) {  // regular driver details
            setHide1('none'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('table');
            settabWidth(1668);
        } 

    }, [FillFormTabId,main_form_id]);


    //--------------- Add / Delete - Document Row -------------
    const addDocsRowTable = () => {
      
        const rowsDocsInput = {}
        setRowsAddDocumentData([...rowsAddDocumentData, rowsDocsInput]);
        setvalidateflag(1)

        if (DocumentDetails) {
            const EditBankCount = DocumentDetails.length;  // control instance count
            setdocsControlInstanceNumber(EditBankCount);
            const main_number = EditBankCount + 1;
            const validate_description = `sub_${main_number}_field_233`;
            setdocsvalidateDescName(validate_description);

        } else {
            setdocsControlInstanceNumber(0);
            const validate_description = `sub_1_field_233`;
            setdocsvalidateDescName(validate_description);
        }
    }

    const handleDocumentChange = (index, evnt) => {  // add array
        //console.log(final_instance_number);
        const { name, value } = evnt.target;
        const rowsDocsInput = [...rowsAddDocumentData];
        rowsDocsInput[index][name] = value;
        //  console.log(rowsDocsInput);
        setRowsAddDocumentData(rowsDocsInput);
        setDocumentDatazero(1);
    }

    const handleDocumentChange1 = (index, evnt) => {  //edit array

        let DocsDetArray = [...DocumentEditArray];
        DocsDetArray[index][evnt.target.name] = evnt.target.value;
        // console.log(DocsDetArray); 
        setDocumentEditArray(DocsDetArray);
        setDocumentDatazero(1);

    }
    //-----------------------   //checkbox array

    const handleDocumentChkChange = (index, evnt) => {

        let DocsDetArray = [...DocumentKycCheckboxArray];
        DocsDetArray[index][evnt.target.name] = !DocsDetArray[index][evnt.target.name];
        //console.log(DocsDetArray);
        setDocumentKycCheckboxArray(DocsDetArray);
        setDocumentDatazero(1);
    }

    const removeDocumentRow = (index) => {
        const rows = [...rowsAddDocumentData];
        rows.splice(index, 1);
        setRowsAddDocumentData(rows);
    }

    //-------------- delete row from document
    const handleDocumentRowDeleteRow = (instance_number) => {
       // console.log(instance_number);
        setLoader(true);
        const payload = {
            instance_id: HiddenData.instance_id,
            user_id: user_id,
            form_id: 24,
            group_id: 16,
            final_instance_number: instance_number,
        };
       // console.log(payload);
        axios
            .post("fill_forms/delete_row_document_details", payload)
            .then((response) => {
                //console.log(response);
                if (response.status == 200) {
                    // window.location.reload(false);
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                   setLoader(false);  
                }
                window.location.reload(false);
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
    }

    //--------------- Add / Delete - Bank Account Row --------------
    const addBankRowTable = () => {
        const rowsBankInput = {}
        setRowsBankData([...rowsBankData, rowsBankInput]);

        if (BankAccounttDetails) {
            const EditBankCount = BankAccounttDetails.length;  // control instance count
            setBankControlInstanceNumber(EditBankCount);
        } else {
            setBankControlInstanceNumber(0);
        }
    }

    const removeBankRow = (index) => {
        const rows = [...rowsBankData];
        rows.splice(index, 1);
        setRowsBankData(rows);
    }

    const handleBankChange = (index, evnt, flag, temp_bank_primary_account) => {   //---------- add bank details 
        //console.log(evnt);
        const { name, value } = evnt.target;
        const rowsBankInput = [...rowsBankData];
        rowsBankInput[index][name] = value;
        if(flag == 1){
             rowsBankInput[index][temp_bank_primary_account] = evnt.target.value  === 'false' ? 1 : 0;
        }
        //console.log(rowsBankInput);
        setRowsBankData(rowsBankInput);
    //------------------------------------------
        if(flag == 1){
           // console.log('ss');
            var BankEditDetArray2 = [...BankAccounttDetails];
            for(var i=0; i<BankAccounttDetails.length; i++){
                BankEditDetArray2[i]['primary_account'] = false;
            }
            //console.log(BankEditDetArray2);
          setBankAccounttDetails(BankEditDetArray2);

          let BankEditDetArray1 = [...BankAccounttCheckboxArray];
          for(var i=0; i<BankAccounttCheckboxArray.length; i++){
              if(i !== index){
              BankEditDetArray1[i]['primary_chk'] = false;
              }
          }
          setBankAccounttCheckboxArray(BankEditDetArray1);
        }
       // console.log(BankEditDetArray2);
     
    }
    //console.log(rowsBankData);
    const handleBankChange1 = (index, evnt) => {  //---------- edit bank details 

        let BankEditDetArray = [...BankAccounttEditArray];
        BankEditDetArray[index][evnt.target.name] = evnt.target.value;
        setBankAccounttEditArray(BankEditDetArray);
        // console.log(BankEditDetArray); 
        setBankDatazero(1)
    }

    const handleBankChkChange = (index, evnt, final_instance_number) => {   // edit checkbox bank details  
        //console.log( final_instance_number)

        let BankEditDetArray1 = [...BankAccounttCheckboxArray];
        var BankEditDetArray2 = [...BankAccounttDetails];
        var chk_name = 'sub_'+final_instance_number+'_field_1244';
        BankEditDetArray2[index]["primary_account"] = evnt.target.value  === "false" ? true : false;
        BankEditDetArray1[index]['primary_chk'] = evnt.target.value  === "false" ? true : false;
        for(var i=0; i<BankAccounttDetails.length; i++){
           if(i !== index){
            BankEditDetArray2[i]['primary_account'] = false;
           }
        }

        setBankAccounttDetails(BankEditDetArray2);
       
        for(var i=0; i<BankAccounttCheckboxArray.length; i++){
            if(i !== index){
             BankEditDetArray1[i]['primary_chk'] = false;
            }
        }
        // console.log(BankEditDetArray1);
        setBankAccounttCheckboxArray(BankEditDetArray1);

       const rowsBankInput = [...rowsBankData];
       for(var i=0; i<rowsBankData.length; i++){
            if(i !== index){
                rowsBankInput[i][chk_name] = false;
            }
        }
        setRowsBankData(rowsBankInput);
        setchkPrimaryAc(false);

        setBankDatazero(1)
    }

    //-------------- delete row 
    const handleBankRowDeleteRow = (instance_number) => {
        setLoader(true);
        const payload = {
            instance_id: HiddenData.instance_id,
            user_id: user_id,
            form_id: 46,
            group_id: 16,
            final_instance_number: instance_number,
        };
        //console.log(payload);
        axios
            .post("finance_application/delete_row_employee_contact_details", payload)
            .then((response) => {
                //console.log(response);
                if (response.status == 200) {
                    //window.location.reload(false);
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setBankDipFlag(1);
                }
                setLoader(false);
                window.location.reload(false);
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
    }
    //--------------- Add / Delete - Regular Drivers Row --------------
    //console.log(rowsAddDriversData);
    const addDriversRowTable = () => {
        const rowsDriversInput = {}
        setRowsAddDriversData([...rowsAddDriversData, rowsDriversInput])

        if (RegularDriverSelectDetails) {
            const EditDriverCount = RegularDriverSelectDetails.length;  // control instance count
            setdriversControlInstanceNumber(EditDriverCount);
           
            const main_number = EditDriverCount+1;
            const validate_driver = `sub_${main_number}_field_1436`;
            setRegdValidate(validate_driver);
        } else {
            setdriversControlInstanceNumber(0);
            setRegdValidate("sub_1_field_1436");
        }

        const rowsContactInput2 = {driver_details: 0}
        setrowsAddContactData2([...rowsAddContactData2, rowsContactInput2])
    }

    const handleDriversChange = (index, evnt) => {  //---------- add driver details 
        //console.log(evnt);
        const { name, value } = evnt.target;
        const rowsDriversInput = [...rowsAddDriversData];
        //console.log(rowsDriversInput);
        rowsDriversInput[index][name] = value;
        setRegularDriverAddDetails(rowsDriversInput);

        let ContactDetArray11 = [...rowsAddContactData2];
        ContactDetArray11[index][evnt.target.id] = evnt.target.value;
        setrowsAddContactData2(ContactDetArray11);
    }

    const handleDriversChange1 = (index, evnt) => {  //---------- edit driver details 

        let DriverEditDetArray = [...RegularDriverEditDetails];
        DriverEditDetArray[index][evnt.target.name] = evnt.target.value;
        //console.log(DriverEditDetArray);
        setRegularDriverEditDetails(DriverEditDetArray);
        setRegularDriverzero(1)
    }

    
    const handleEditDriversAgeCalculate = (index, evnt) => {
        let DriverEditDetArray = [...RegularDriverEditDetails];
        DriverEditDetArray[index][evnt.target.name] = evnt.target.value;
        //console.log(DriverEditDetArray);
       //-----------------------------
        var today = new Date();
        var birthDate = new Date(evnt.target.value);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        let regularDriverSelectDetails1 = [...RegularDriverSelectDetails]
        regularDriverSelectDetails1[index]["age"] = age;
        setRegularDriverSelectDetails(regularDriverSelectDetails1) 
    }

    const removeDriversRow = (index) => {
        const rows = [...rowsAddDriversData];
        rows.splice(index, 1);
        setRowsAddDriversData(rows);
        setrowsAddContactData2([]);
        setRegularDriverAddDetails([]);
    }

    //-------------- delete row 
    const handleDriverRowDeleteRow = (instance_number,ContactsId) => {
        setLoader(true);
        const payload = {
            instance_id: HiddenData.instance_id,
            contact_id: ContactsId,
            final_instance_number: instance_number, 
            user_id: user_id,
            company_id: company_id,
            form_id: 73,
            group_id: 16,
            command: "Delete",
        };

        //console.log(payload);
        axios
            //.post("finance_application/delete_row_employee_contact_details", payload)
            .post("fill_forms/delete_row_regular_driver_delete", payload)
            .then((response) => {
                //console.log(response);
                if (response.status == 200) {
                   // window.location.reload(false);
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setDriverDipFlag(1);
                }
                setLoader(false);
                window.location.reload(false);
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
    }
    
    let panFlag = 1;

    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px", marginLeft: "-200px" }} />
            <Transaction_menu panel_flag={panFlag}/>
            {loader ? <Loader /> : null}
            <div>
                <table width="100%" height="1024" id="mainContainer" cellSpacing={0} cellPadding={0} className="custom-body">
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">
                                <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                                <p align="center">
                                    { loanhouse_id == 48 ?
                                        <img height="65"  border="0" src={bank_of_baroda_img} alt=""/>
                                    : loanhouse_id == 49 ?
                                        <img height="120"  border="0" src={capital_bank_img} alt=""/>
                                    : loanhouse_id == 47 ?
                                        <img height="150"  border="0" src={standard_chareted_bank_img} alt=""/>
                                    : null }

                                </p>
                                <table width="100%" cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center">
                                                <table className="defaultTbl" cellSpacing={1} width={tabWidth} >
                                                    <tbody>
                                                        <tr>
                                                            <th id="group_list" className="text-center" colSpan={8}>
                                                                <input type="submit" name="command" value="Notes" id="notes" onClick={mainDivHideShow} />
                                                                <input className="p-relative" type="submit" name="command" value="Documents" id="documents" onClick={mainDivHideShow} />
                                                                <input type="submit" name="command" value="Bank Accounts" id="bank_account" onClick={mainDivHideShow} />
                                                                <input type="submit" name="command" value="Insurance Details" id="insurance_details" onClick={mainDivHideShow} />
                                                                <input type="submit" name="command" value="Regular Drivers" id="regular_drivers" onClick={mainDivHideShow} />
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                {/* Note */}
                                                <form name="notesForm" id="notesForm" method="post" onSubmit={handleAddNotes} >

                                                    <table className="defaultTbl" cellSpacing={1} width="800" style={{ display: div_id1 }}>

                                                        <tbody>
                                                            <tr>
                                                                <th id="form_name" colSpan={4} style={{ textAlign: 'center' }}>Notes</th>
                                                            </tr>
                                                            <tr>
                                                                <td className="caption" style={{ width: '25%' }}>&nbsp;</td>
                                                                <td className="text-left" style={{ width: '25%', verticalAlign: 'baseline' }}>
                                                                    <strong>User Notes (visible to client)</strong>
                                                                    <br />
                                                                    <div className="notes-textarea-div">
                                                                        <textarea rows="4" className="notes-textarea" id="sub_1_field_165" name="sub_1_field_165" value={UserNote} onChange={(e) => { setUserNote(e.target.value) }}></textarea>
                                                                        <br />
                                                                        {UserNotesDetails != null ?
                                                                            UserNotesDetails.map((item, index) => {
                                                                                return (
                                                                                    <span key={index}>
                                                                                        <strong>{item.DateTime} :</strong>
                                                                                        <br />
                                                                                        {item.NoteName}
                                                                                        <hr style={{ margin: '0.3rem 0' }} />
                                                                                    </span>
                                                                                );
                                                                            }) : null
                                                                        }
                                                                    </div>
                                                                </td>

                                                                <td className="caption" style={{ width: '25%' }}>&nbsp;</td>

                                                                <td className="text-left" style={{ width: '25%', verticalAlign: 'baseline' }}>
                                                                    <strong>system notes (only visible to system users)</strong>
                                                                    <br />
                                                                    <div className="notes-textarea-div" valign="top">
                                                                        <textarea rows="4" className="notes-textarea" id="sub_1_field_166" name="sub_1_field_166" value={SystemNote} onChange={(e) => { setSystemNote(e.target.value) }}></textarea>
                                                                        <br />
                                                                        {SystemNotesDetails != null ?
                                                                            SystemNotesDetails.map((item, index) => {
                                                                                return (
                                                                                    <span key={index}>
                                                                                        <strong>{item.DateTime} :</strong>
                                                                                        <br />
                                                                                        {item.NoteName}
                                                                                        <hr style={{ margin: '0.3rem 0' }} />
                                                                                    </span>
                                                                                );
                                                                            }) : null
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr className="caption">
                                                                <td colSpan={4} className="text-center">
                                                                    &nbsp;
                                                                    {/* {CheckButtonRight ? */}
                                                                    <input type="button" name="command" value="Print" onClick={GeneratePrintPdf}/>
                                                                    &nbsp;
                                                                    <input type="submit" name="command" value="Save" onClick={(e) => { setNoteSave(e.target.value) }} />
                                                                    {/* : null } */}
                                                                    &nbsp;
                                                                    {/* { CheckButtonRight ? */}
                                                                    <input type="submit" name="command" value="Save and Exit" onClick={(e) => { setNoteSave(e.target.value) }} />
                                                                    {/* : null} */}
                                                                    &nbsp;

                                                                    {
                                                                        HiddenData.transaction_id != '' ?
                                                                            <Link to={"/transaction/create_transaction/" + HiddenData.transaction_id}>
                                                                                <input type="button" className="p-relative" value="Cancel" />
                                                                            </Link>
                                                                            :
                                                                            <Link to={"/transaction/create_transaction/0"}>
                                                                                <input type="button" className="p-relative" value="Cancel" />
                                                                            </Link>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>

                                                    </table>
                                                </form>
                                                {/* DOCUMENTS */}
                                                <form name="DocumentForm" method="post" onSubmit={handleAddDocuments} encType="multipart/form-data">
                                                    <table className="defaultTbl" cellSpacing={1} width="800" style={{ display: div_id2 }}>
                                                        <tbody>
                                                            <tr>
                                                                <th id="form_name" colSpan={6} style={{ textAlign: 'center' }}>Documents</th>
                                                            </tr>
                                                            <tr className="caption">
                                                                <td>Type</td>
                                                                <td>
                                                                    <span id="sub_1_label_233" title="{fieldname} field is mandatory">Description
                                                                        <span className="text-danger">*</span></span>
                                                                </td>
                                                                <td>KYC</td>
                                                                <td>File</td>
                                                                {/* <td>SELECT</td> */}
                                                                <td>&nbsp;</td>
                                                            </tr>

                                                            {DocumentDetails ?
                                                                DocumentDetails.map((item, index) => {
                                                                    //  style={{ display: RowHideShow }}
                                                                    const { document_type, description, kyc1, sel_check } = item;

                                                                    const final_instance_number1 = index + 1;
                                                                    const temp_document_type = `sub_${final_instance_number1}_field_232`;
                                                                    const temp_description = `sub_${final_instance_number1}_field_233`;
                                                                    const temp_check_val = `sub_${final_instance_number1}_field_1870`;
                                                                   
                                                                    const temp_up_file = `sub_${final_instance_number1}_field_234`;

                                                                    return (
                                                                        <tr className="new_entry_line" key={index}>

                                                                            <td nowrap="nowrap" className="text-left">
                                                                                <select name={temp_document_type} id={temp_document_type} className="text-uppercase" value={DocumentDatazero === 0 ? item.type_id : document_type} onChange={(evnt) => (handleDocumentChange1(index, evnt, item.control_instance_number))}  >


                                                                                    <option value="">Select an option</option>
                                                                                    {DocumentTypeAll.map((data) => {
                                                                                        return (
                                                                                            <option key={data.id} value={data.id}>
                                                                                                {data.type_name}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                            </td>

                                                                            <td nowrap="nowrap" className="text-left">
                                                                                <input type="text" name={temp_description} id={temp_description} style={{ width: '100%' }} className="text-uppercase" defaultValue={DocumentDatazero === 0 ? item.description : description} onChange={(evnt) => (handleDocumentChange1(index, evnt, item.control_instance_number))} />
                                                                            </td>
                                                                            <td nowrap="nowrap" className="text-center">
                                                                                <input type="checkbox" name={temp_check_val} id={temp_check_val} defaultValue={DocumentDatazero === 0 ? item.kyc : kyc1} defaultChecked={(item.kyc == true) ? true : false} onChange={(evnt) => { handleDocumentChkChange(index, evnt); setFlag(1); }} />
                                                                                {/* checked={checked} */}
                                                                            </td>
                                                                            <td nowrap="nowrap" className="text-center">
                                                                                <br />
                                                                                {/* {item.file != '' ? */}

                                                                                    <a href={item.document_path} target='_blank'>
                                                                                        {item.file}
                                                                                    </a>
                                                                                    {/* :
                                                                                    <input type="file" name={temp_up_file} id={temp_up_file} onChange={(evnt) => { handleDocumentChange1(index, evnt); setFile(evnt.target.files[0]); }} /> 
                                                                                }*/}

                                                                            </td>
                                                                            {/* <td nowrap="nowrap" className="text-center">
                                                                                <input type="checkbox" name={temp_sel_check} id={temp_sel_check} defaultValue={DocumentDatazero === 0 ? item.select : sel_check} defaultChecked={(item.select == true) ? true : false} onChange={(evnt) => { handleDocumentChkChangenew(index, evnt); setFlag(1) }} />
                                                                                <input type="hidden" id="sub_1_field_1872" name="sub_1_field_1872" />
                                                                            </td> */}

                                                                            <td>
                                                                                { unincept_flag != 1 ?
                                                                                <input type="image" name="delete_file" src={delete_row_img} width="16" onClick={(e) => { if (window.confirm('Are you sure you want to delete this row?')) { handleDocumentRowDeleteRow(item.control_instance_number);sethandleDeleteDocsFlag(1); } }} />
                                                                                : null }
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }) : null}

                                                            <Documents_table_rows rowsData={rowsAddDocumentData} handleDocumentChange={handleDocumentChange} instanceNumber1={docsControlInstanceNumber} colorFocus={colorFocus} docsvalidateDescName={docsvalidateDescName} setFile={setFile} setFileAddFlag={setFileAddFlag} setFileEditName={setFileEditName} setvalidateflag={setvalidateflag} TypeDiscription={TypeDiscription} setTypeDiscription={setTypeDiscription} TypeDocument={TypeDocument} setTypeDocument={setTypeDocument} FileDocument={FileDocument} setFileDocument={setFileDocument} />
                                                            {/* style={{ display: AddNewButton }} */}
                                                            <tr style={{ display: AddNewButton }}>
                                                                <td className="text-center" colSpan={6}>
                                                                    <input type="hidden" name="row_count" />
                                                                    <input type="button" id="add_new_entry" value="Add an Entry" onClick={function (e) { addDocsRowTable(); documentShowRow(e); }} />
                                                                </td>
                                                            </tr>
                                                            {/* style={{ display: RowHideShow }} */}
                                                            <tr id="add_entry_row" style={{ display: RowHideShow }} >
                                                                <td className="text-center" colSpan={6}>
                                                                    <input type="hidden" name="row_count" />
                                                                    <input className="new_entry_line" type="submit" value="Save New Entry" name="command" onClick={(e) => { setDocumentCommand(e.target.value) }} />
                                                                    <input className="new_entry_line" id="remove_new_entry" type="button" value="Cancel" onClick={function (e) { removeDocumentRow(); documentShowRow(e) }} />
                                                                </td>
                                                            </tr>

                                                            <tr className="caption">
                                                                <td colSpan={6} className="text-center">
                                                                    <input type="button" name="command" value="Print" onClick={GeneratePrintPdf}/>
                                                                    &nbsp;
                                                                    <input type="submit" name="command" value="Save" onClick={(e) => { setDocumentCommand(e.target.value) }} />
                                                                    &nbsp;
                                                                    <input type="submit" name="command" value="Save and Exit" onClick={(e) => { setDocumentCommand(e.target.value) }} />
                                                                    &nbsp;
                                                                    {/* <input type="submit" name="command" value="Send Documents" /> */}

                                                                    <Link to={"/transaction/create_transaction/" + HiddenData.transaction_id}>
                                                                        <input type="button" className="p-relative" value="Cancel" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </form>
                                                {/*  Bank Account */}
                                                <form name="notesForm" id="notesForm" method="post" onSubmit={handleAddBankDocument} >
                                                    <table className="defaultTbl" cellSpacing={1} width="1095" style={{ display: div_id3 }}>
                                                        <tbody>
                                                            <tr>
                                                                <th id="form_name" colSpan={8} style={{ textAlign: 'center' }}>Bank Accounts</th>
                                                            </tr>
                                                            <tr className="caption">
                                                                <td>Account Holder Name</td>
                                                                <td>Bank Name</td>
                                                                <td>Account Type</td>
                                                                <td>Branch Name</td>
                                                                <td>Account Number</td>
                                                                <td>Branch Code</td>
                                                                <td>Primary Account</td>
                                                                <td>&nbsp;</td>
                                                            </tr>

                                                            {

                                                                BankAccounttDetails ?
                                                                    BankAccounttDetails.map((item, index) => {
                                                                        // {console.log(BankAccounttDetails)}
                                                                        const { holder_name, bw_bank_name, account_type, branch_name, account_no, branch_code } = item;

                                                                        const final_instance_number = index + 1;
                                                                        const temp_ac_holder_name = `sub_${final_instance_number}_field_1237`;
                                                                        const temp_bank_name = `sub_${final_instance_number}_field_1238`;
                                                                        const temp_account_type = `sub_${final_instance_number}_field_1239`;
                                                                        const temp_branch_name = `sub_${final_instance_number}_field_1240`;
                                                                        const temp_account_no = `sub_${final_instance_number}_field_1241`;
                                                                        const temp_branch_code = `sub_${final_instance_number}_field_1242`;
                                                                        const temp_primary_account = `sub_${final_instance_number}_field_1244`;

                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <select name={temp_ac_holder_name} id="sub_1_field_1237" style={{ width: '200px' }} className="text-uppercase" value={BankDatazero === 0 ? item.ac_holder_name : holder_name} onChange={(evnt) => (handleBankChange1(index, evnt, item.control_instance_number))}>
                                                                                        <option value="">Select an option</option>
                                                                                        { AccountHolderAll != null ?
                                                                                        AccountHolderAll.map((data) => {
                                                                                            return (
                                                                                                <option key={data.id} value={data.id}>
                                                                                                    {data.holder_name}
                                                                                                </option>
                                                                                            );
                                                                                        })
                                                                                        : null}
                                                                                    </select>
                                                                                </td>
                                                                                <td nowrap="nowrap" className="text-center">
                                                                                    <select name={temp_bank_name} id="sub_1_field_1238" className="text-uppercase" value={BankDatazero === 0 ? item.bank_name : bw_bank_name} onChange={(evnt) => (handleBankChange1(index, evnt, item.control_instance_number))}>
                                                                                        <option value="">Select an option</option>
                                                                                        {BankNameAll.map((data) => {
                                                                                            return (
                                                                                                <option key={data.id} value={data.id}>
                                                                                                    {data.bank_name}
                                                                                                </option>
                                                                                            );
                                                                                        })}
                                                                                    </select>
                                                                                </td>

                                                                                <td nowrap="nowrap" className="text-center">
                                                                                    <select name={temp_account_type} id="sub_1_field_1239" className="text-uppercase" value={BankDatazero === 0 ? item.ac_type : account_type} onChange={(evnt) => (handleBankChange1(index, evnt, item.control_instance_number))} >
                                                                                        <option value="">Select an option</option>
                                                                                        {AccountTypeAll.map((data) => {
                                                                                            return (
                                                                                                <option key={data.id} value={data.id}>
                                                                                                    {data.type}
                                                                                                </option>
                                                                                            );
                                                                                        })}
                                                                                    </select>
                                                                                </td>
                                                                                <td nowrap="nowrap" className="text-center">
                                                                                    <input type="text" name={temp_branch_name} id="sub_1_field_1240" className="text-uppercase" defaultValue={BankDatazero === 0 ? item.branch_name : branch_name} onChange={(evnt) => (handleBankChange1(index, evnt, item.control_instance_number))} />
                                                                                </td>

                                                                                <td nowrap="nowrap" className="text-center">
                                                                                    <input type="text" name={temp_account_no} id="sub_1_field_1241" className="text-uppercase" defaultValue={BankDatazero === 0 ? item.ac_number : account_no} onChange={(evnt) => (handleBankChange1(index, evnt, item.control_instance_number))} />
                                                                                </td>

                                                                                <td nowrap="nowrap" className="text-center">
                                                                                    <input type="text" name={temp_branch_code} id="sub_1_field_1242" className="text-uppercase" defaultValue={BankDatazero === 0 ? item.branch_code : branch_code} onChange={(evnt) => { (handleBankChange1(index, evnt, item.control_instance_number)) }} />
                                                                                </td>
                                                                                <td nowrap="nowrap" className="text-center">

                                                                                    <input type="checkbox" name={temp_primary_account} value={item.primary_account} checked={item.primary_account} onChange={(evnt) => { handleBankChkChange(index, evnt,final_instance_number); }} />

                                                                                </td>
                                                                                <td>
                                                                                   { unincept_flag != 1 ?
                                                                                    <input type="image" name="delete_file" src={delete_row_img} width="16" onClick={() => { if (window.confirm('Are you sure you want to delete this row?')) { handleBankRowDeleteRow(item.control_instance_number); sethandleDeleteFlag(1); } }} />
                                                                                    : null }
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }) : null}

                                                            <Bank_account_table_rows rowsData={rowsBankData} handleBankChange={handleBankChange} instanceNumber={BankControlInstanceNumber} setBankchkDipFlag={setBankchkDipFlag} chkPrimaryAc={chkPrimaryAc} setchkPrimaryAc={setchkPrimaryAc}/>
                                                            {/* <TableRows rows={rows} /> */}
                                                            <tr style={{ display: AddNewButton1 }}>
                                                                <td className="text-center" colSpan={8}>
                                                                    <input type="hidden" value="1" name="row_count" />
                                                                    <input type="button" id="add_new_entry" value="Add an Entry" onClick={function (e) { addBankRowTable(); bankShowRow(e) }} />

                                                                </td>
                                                            </tr>
                                                            <tr style={{ display: RowHideShow1 }}>
                                                                <td className="text-center" colSpan={8}>
                                                                    <input type="hidden" value="1" name="row_count" />
                                                                    <input className="new_entry_line" type="submit" value="Save New Entry" name="command" onClick={(e) => { setBankCommand(e.target.value) }} />
                                                                    <input className="new_entry_line" id="remove_new_entry" type="button" value="Cancel" onClick={function (e) { removeBankRow(); bankShowRow(e) }} />

                                                                </td>
                                                            </tr>
                                                            <tr className="caption">
                                                                <td colSpan={8} className="text-center">
                                                                    <input type="button" name="command" value="Print" onClick={GeneratePrintPdf}/>
                                                                    &nbsp;
                                                                    <input type="submit" name="command" value="Save" onClick={(e) => { setBankCommand(e.target.value) }} />
                                                                    &nbsp;
                                                                    <input type="submit" name="command" value="Save and Exit" onClick={(e) => { setBankCommand(e.target.value) }} />
                                                                    &nbsp;
                                                                    <Link to={"/transaction/create_transaction/" + HiddenData.transaction_id}>
                                                                        <input type="button" className="p-relative" value="Cancel" />
                                                                    </Link>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </form>
                                                {/*  INSURANCE DETAILS */}
                                                <form name="notesForm" id="notesForm" method="post" onSubmit={handleAddInsurance} >
                                                    <table className="defaultTbl" cellSpacing={1} width="800" style={{ display: div_id4 }}>
                                                        <tbody>
                                                            <tr>
                                                                <th id="form_name" colSpan={4} style={{ textAlign: 'center' }}>Insurance Details</th>
                                                            </tr>
                                                            <tr>
                                                                <th className="caption" colSpan={4} style={{ textAlign: 'center' }}>Audio Equipment</th>
                                                            </tr>
                                                            <tr>
                                                                <td className="caption" style={{ width: '25%' }}>Make &amp; Model</td>
                                                                <td id="1" nowrap="nowrap" className="text-left" style={{ width: '25%' }}>
                                                                    <input id="sub_1_field_1430" type="text" name="sub_1_field_1430" className="text-uppercase" defaultValue={insuranceMakeModel} onChange={(e) => { setinsuranceMakeModel(e.target.value) }} />
                                                                </td> 
                                                                <td className="caption" style={{ width: '25%' }}>Sum Insured</td>
                                                                <td id="1" nowrap="nowrap" className="text-right" style={{ width: '25%' }}>P&nbsp;
                                                                    
                                                                    <NumericFormat type="text" id="sub_1_field_1431" name="sub_1_field_1431" value={insuranceSumInsured} onChange={(e) => { setinsuranceSumInsured(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(1); }} onBlur={() => { ShowZero(1); }} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="caption" style={{ width: '25%' }}>Serial No.</td>
                                                                <td id="1" nowrap="nowrap" className="text-left" style={{ width: '25%' }}>
                                                                    <input id="sub_1_field_1432" type="text" name="sub_1_field_1432" className="text-uppercase" defaultValue={insuranceSerialNo} onChange={(e) => { setinsuranceSerialNo(e.target.value) }} />
                                                                </td> 
                                                                <td className="caption">&nbsp;</td>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="caption" colSpan={4} style={{ textAlign: 'center' }}>Security</th>
                                                            </tr>

                                                            <tr>
                                                                <td className="caption" style={{ width: '25%' }}>Immobiliser</td>
                                                                <td id="1" nowrap="nowrap" className="text-left" style={{ width: '25%' }}>
                                                                    <input id="sub_1_field_1434" type="radio" name="sub_1_field_1434" defaultValue={InsuranceDetails.immobilister == 1660 ? InsuranceDetails.immobilister : '1660'} checked={insuranceImUserRadio == 1660} onChange={(e) => { setinsuranceImUserRadio(e.target.value) }} />&nbsp;&nbsp;AFTERMARKET<br />
                                                                    <input id="sub_1_field_1434" type="radio" name="sub_1_field_1434" defaultValue={InsuranceDetails.immobilister == 1659 ? InsuranceDetails.immobilister : '1659'} checked={insuranceImUserRadio == 1659} onChange={(e) => { setinsuranceImUserRadio(e.target.value) }} />&nbsp;&nbsp;FACTORY FITTED<br />
                                                                    <input id="sub_1_field_1434" type="radio" name="sub_1_field_1434" defaultValue={InsuranceDetails.immobilister == 1694 ? InsuranceDetails.immobilister : '1694'} checked={insuranceImUserRadio == 1694} onChange={(e) => { setinsuranceImUserRadio(e.target.value) }} />&nbsp;&nbsp;NONE<br /></td>
                                                                <td colSpan={2}>&nbsp;</td>

                                                            </tr>
                                                            <tr className="caption">
                                                                <td colSpan={4} className="text-center">
                                                                    <input type="button" name="command" value="Print" onClick={GeneratePrintPdf}/>
                                                                    &nbsp;
                                                                    <input type="submit" name="command" value="Save" onClick={(e) => { setinsuranceCommand(e.target.value) }} />
                                                                    &nbsp;
                                                                    <input type="submit" name="command" value="Save and Exit" onClick={(e) => { setinsuranceCommand(e.target.value) }} />
                                                                    &nbsp;
                                                                    <Link to={"/transaction/create_transaction/" + HiddenData.transaction_id}>
                                                                        <input type="button" className="p-relative" value="Cancel" />
                                                                    </Link>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </form>
                                                {/*  REGULAR DRIVERS */}

                                                <form name="DriverForm" id="DriverForm" method="post" onSubmit={handleAddRegularDriver} >
                                                    <table className="defaultTbl" cellSpacing={1} width="1668" style={{ display: div_id5 }}>
                                                        <tbody>
                                                            <tr>
                                                                <th id="form_name" colSpan={12} style={{ textAlign: 'center' }}>Regular Drivers</th>
                                                            </tr>
                                                            <tr className="caption">
                                                                <td>Driver Details <span style={{ color: "red" }}>*</span></td>
                                                                <td>Driver Firstname</td>
                                                                <td>Driver Surname</td>
                                                                <td>PHYSICAL ADDRESS</td>
                                                                <td>Date of Birth</td>
                                                                <td>Age</td>
                                                                <td>ID Number</td>
                                                                <td>Gender</td>
                                                                <td>Drivers License Issue Date</td>
                                                                <td>Claims in the previous 12 months</td>
                                                                <td>Claim Free Group (CFG)</td>
                                                                <td>&nbsp;</td>
                                                            </tr>

                                                            {RegularDriverSelectDetails ?
                                                                RegularDriverSelectDetails.map((item, index) => {
                                                                    const { driver_details, first_name, last_name, physical_address, date_of_birth, age1, id_number, gender1, driver_licence_issue_date, claim_previous_12_month, cfg1 } = item;

                                                                    const final_instance_number = index + 1;
                                                                    const temp_driver_details = `sub_${final_instance_number}_field_1436`;
                                                                    const temp_first_name = `sub_${final_instance_number}_field_1426`;
                                                                    const temp_last_name = `sub_${final_instance_number}_field_1440`;
                                                                    const temp_physical_address = `sub_${final_instance_number}_field_1427`;
                                                                    const temp_date_of_birth = `sub_${final_instance_number}_field_1444`;
                                                                    const temp_age = `sub_${final_instance_number}_field_1446`;
                                                                    const temp_id_number = `sub_${final_instance_number}_field_1442`;
                                                                    const temp_gender = `sub_${final_instance_number}_field_1445`;
                                                                    const temp_driver_licence_issue_date = `sub_${final_instance_number}_field_1443`;
                                                                    const temp_claim_previous_12_month = `sub_${final_instance_number}_field_1447`;
                                                                    const temp_cfg = `sub_${final_instance_number}_field_1399`;

                                                                    return (
                                                                        <tr key={index}>

                                                                            <td nowrap="nowrap" className="text-center">
                                                                                <select id="driver_details" name={temp_driver_details} style={{ width: '180px' }} className="text-uppercase" value={RegularDriverzero === 0 ? item.drivers_details : driver_details} onChange={(evnt) => (handleDriversChange1(index, evnt))} disabled>
                                                                                    {/* <option value="">Select an option</option> */}
                                                                                    {DriverDetailsAll.map((data) => {
                                                                                        return (
                                                                                            <option key={data.id} value={data.id}>
                                                                                                {data.dname}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                            </td>

                                                                            <td nowrap="nowrap" className="text-center">
                                                                                <input type="hidden" id="sub_1_field_1528" name="sub_1_field_1528" />
                                                                                <input type="hidden" id="sub_1_field_1520" name="sub_1_field_1520" />
                                                                                <input type="hidden" id="sub_1_field_1529" name="sub_1_field_1529" />
                                                                                <input type="hidden" id="sub_1_field_1530" name="sub_1_field_1530" />
                                                                                <input type="hidden" id="sub_1_field_1531" name="sub_1_field_1531" />
                                                                                <input type="hidden" id="sub_1_field_1532" name="sub_1_field_1532" />

                                                                                {/* -------------- */}
                                                                                <input id="sub_1_field_1426" type="text" name={temp_first_name} className="text-uppercase" defaultValue={RegularDriverzero === 0 ? item.driver_firstname : first_name} onChange={(evnt) => (handleDriversChange1(index, evnt))} />
                                                                            </td>

                                                                            <td nowrap="nowrap" className="text-center">
                                                                                <input id="sub_1_field_1440" type="text" name={temp_last_name} className="text-uppercase" defaultValue={RegularDriverzero === 0 ? item.driver_surname : last_name} onChange={(evnt) => (handleDriversChange1(index, evnt))} />
                                                                            </td>

                                                                            <td nowrap="nowrap" className="text-center">
                                                                                <textarea rows={4} id="sub_1_field_1427" name={temp_physical_address} className="text-uppercase" defaultValue={RegularDriverzero === 0 ? item.physical_address : physical_address} onChange={(evnt) => (handleDriversChange1(index, evnt))} ></textarea>
                                                                            </td>

                                                                            <td nowrap="nowrap" className="text-center">
                                                                                <input type="date" name={temp_date_of_birth} className="birthdate hasDatepicker text-uppercase" style={{ width: '75' }} id="birth_date" defaultValue={RegularDriverzero === 0 ? item.date_of_birth : date_of_birth} onChange={(evnt) => { handleDriversChange1(index, evnt); handleEditDriversAgeCalculate(index, evnt); }} />
                                                                            </td>

                                                                            <td nowrap="nowrap" className="text-center">
                                                                                {/* <input id="sub_1_field_1446" type="text" name={temp_age} className="text-uppercase" defaultValue={RegularDriverzero === 0 ? item.age : age1} onChange={(evnt) => (handleDriversChange1(index, evnt))} /> */}
                                                                                <input id="sub_1_field_1446" type="text" name={temp_age} className="text-uppercase" value={item.age} onChange={(evnt) => (handleDriversChange1(index, evnt))} />
                                                                            </td>

                                                                            <td nowrap="nowrap" className="text-center">
                                                                                <input id="sub_1_field_1442" type="text" name={temp_id_number} className="text-uppercase" defaultValue={RegularDriverzero === 0 ? item.id_number : id_number} onChange={(evnt) => (handleDriversChange1(index, evnt))} />
                                                                            </td>

                                                                            <td nowrap="nowrap" className="text-center">

                                                                                <select id="sub_1_field_1445" name={temp_gender} className="text-uppercase" value={RegularDriverzero === 0 ? item.gender : gender1} onChange={(evnt) => (handleDriversChange1(index, evnt))}  >
                                                                                    <option value="">Select an option</option>
                                                                                    {GenderAll.map((data) => {
                                                                                        return (
                                                                                            <option key={data.id} value={data.id}>
                                                                                                {data.gender}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>

                                                                            </td>

                                                                            <td nowrap="nowrap" className="text-center">
                                                                               
                                                                                <select id="sub_1_field_1445" name={temp_driver_licence_issue_date} className="text-uppercase" value={RegularDriverzero === 0 ? item.drivers_lic_issue_date : driver_licence_issue_date} onChange={(evnt) => (handleDriversChange1(index, evnt))}  >
                                                                                    <option value="0">Select an option</option>
                                                                                    {DriverLicenseDateAll.map((data) => {
                                                                                        return (
                                                                                            <option key={data.id} value={data.id}>
                                                                                                {data.year}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                            </td>

                                                                            <td nowrap="nowrap" className="text-center">
                                                                                <input id="sub_1_field_1447" type="text" name={temp_claim_previous_12_month} className="text-uppercase" defaultValue={RegularDriverzero === 0 ? item.claim_previos : claim_previous_12_month} onChange={(evnt) => (handleDriversChange1(index, evnt))} />
                                                                            </td>

                                                                            <td nowrap="nowrap" className="text-center">

                                                                                <select id="sub_1_field_1399" name={temp_cfg} className="text-uppercase" value={RegularDriverzero === 0 ? item.cfg : cfg1} onChange={(evnt) => (handleDriversChange1(index, evnt))} >
                                                                                    <option value="">Select an option</option>
                                                                                    {CfgAll.map((data) => {
                                                                                        return (
                                                                                            <option key={data.id} value={data.id}>
                                                                                                {data.cfg}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>

                                                                            </td>

                                                                            <td>
                                                                               { unincept_flag != 1 ?
                                                                                <input type="image" name="delete_file" src={delete_row_img} width="16" onClick={() => { if (window.confirm('Are you sure you want to delete this row?')) { handleDriverRowDeleteRow(item.control_instance_number, item.drivers_details); sethandledrDeleteFlag(1); } }} />
                                                                                : null}
                                                                            </td>

                                                                        </tr>
                                                                    );
                                                                }) : null}

                                                            <Regular_drivers_table_rows rowsData={rowsAddDriversData} handleDriversChange={handleDriversChange} instanceNumber={driversControlInstanceNumber} RegdValidate={RegdValidate} colorFocus1={colorFocus1} />

                                                            <tr id="add_entry_row" ></tr>
                                                            { AddEntryDiplayFlag == 0 ?
                                                            <tr style={{ display: AddNewButton2 }}>
                                                                <td className="text-center" colSpan={12}>
                                                                    <input type="hidden" name="row_count" />
                                                                    <input type="button" id="add_new_entry" value="Add an Entry" onClick={function (e) { addDriversRowTable(); driversShowRow(e) }} />

                                                                </td>
                                                            </tr>
                                                            : null }

                                                            <tr style={{ display: RowHideShow2 }}>
                                                                <td className="text-center" colSpan={12}>
                                                                    <input type="hidden" name="row_count" />
                                                                    <input className="new_entry_line" type="submit" value="Save New Entry" name="command" onClick={(e) => { setDriverCommand(e.target.value) }} />
                                                                    <input className="new_entry_line" id="remove_new_entry" type="button" value="Cancel" onClick={function (e) { removeDriversRow(); driversShowRow(e) }} />
                                                                </td>
                                                            </tr>
                                                            
                                                            <tr className="caption">
                                                                <td colSpan={12} className="text-center">
                                                                    <input type="button" name="command" value="Print" onClick={GeneratePrintPdf}/>
                                                                    &nbsp;
                                                                    <input type="submit" name="command" value="Save" onClick={(e) => { setDriverCommand(e.target.value) }} />
                                                                    &nbsp;
                                                                    <input type="submit" name="command" value="Save and Exit" onClick={(e) => { setDriverCommand(e.target.value) }} />
                                                                    &nbsp;
                                                                    <Link to={"/transaction/create_transaction/" + HiddenData.transaction_id}>
                                                                        <input type="button" className="p-relative" value="Cancel" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </form>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Footer />
            </div>
        </>
    );

}

export default Finance_application_capital_baroda_standard_bank
