import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link } from "react-router-dom"
import Loader from "../../Components/Loader"

function Search_company() {
    document.title = "Administration | Seriti - Add Company";
    const [CompanyName, setCompanyName] = useState("");
    const [searchData, setSearchData] = useState([]);
    const [Reload, setReload] = useState(true);

    const [loader, setLoader] = useState(false);
    
    useEffect(() => {
        if(Reload){
        const payload = {
            company_name: '',
        };
        axios
            .post("admin/search_company", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data.data);
                    setSearchData(response.data.data);
                    setReload(false)
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                // console.log("response : "+response.data);
                //alert(response.data);
            });
        }
    }, [Reload]);

    //---------------------handle validation------------------------//
    const handleValidation = () => {
        var flag = true;
        if (CompanyName === '') {
            alert('Please enter Comapny Name');
            flag = false;
        }
        return flag;
    };

    //----------------------------searching company-------------------------------------//
    const handleSubmit = (e) => {
        // setGroup(group)
        e.preventDefault();
        const flag = handleValidation();
        // console.log(flag)
        if (flag) {
            setLoader(true);

            const payload = {
                company_name: CompanyName,
            };
            axios
                .post("admin/search_company", payload)
                .then((response) => {
                    setLoader(false);
                    if (response.data.status === 200) {
                        // console.log(response.data.data);
                        setSearchData(response.data.data);
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    setLoader(false);
                    console.log('error');
                });
        }
    };
    // console.log(searchData);
    return (
        <>
            <Admin_menu />
            {loader ? <Loader /> : null}
            <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                <tbody>
                    <tr valign="top" className="bg-white">
                        <td></td>
                        <td width="100%" className="bg-white">
                            <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                            <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                            <form method="post" action="" onSubmit={handleSubmit} >
                                                <table className="defaultTbl" width="500" cellSpacing="1">
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan="2" className="text-center">List Company / SUPPLIERS</th>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Company Name<span style={{ color: "red" }}> *</span></td>
                                                            <td>
                                                                <input type="text" name="CompanyName" autoComplete="off" className="sectext text-uppercase" value={CompanyName} onChange={(e) => { setCompanyName(e.target.value) }} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2" className="caption text-center" >
                                                                <input type="submit" name="command" value="Search" />
                                                                <Link to={"/admin/company_add_edit"} className="btn btn-result-open">
                                                                    <input type="button" name="command" value="Add New Company" />
                                                                </Link>
                                                                <input type="reset" name="command" value="Clear" onClick={ (e)=>{setReload(true);setCompanyName("")} }/>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </form>
                                            <br />

                                            <form method="post">
                                                <table className="defaultTbl" width="800">
                                                    <tbody>

                                                        <tr>
                                                            <th colSpan="10" className="text-center">Results of Company / SUPPLIERS Search</th>
                                                        </tr>
                                                        <tr>

                                                            <td className="caption">Company Name</td>
                                                            <td className="caption">Mobile Number</td>
                                                            <td className="caption">Email</td>
                                                            <td className="caption" width="50"></td>
                                                        </tr>
                                                        {
                                                            searchData && searchData.length ? (
                                                                searchData.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td> {item.name} </td>
                                                                            <td> {(item.cell_number === "0" ? null : item.cell_number)} </td>
                                                                            <td> {item.email} </td>

                                                                            <td>
                                                                                <Link to={"/admin/add_company/edit/" + item.id} className="btn btn-result-open">
                                                                                    <input type="submit" name="command" value="Edit" />
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : <tr><td colSpan={4}>"NO COMPANY WERE FOUND MATCHING YOUR SEARCH CRITERIA"</td></tr>
                                                        }

                                                    </tbody>
                                                </table>
                                            </form>

                                            <p></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td></td>

                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default Search_company