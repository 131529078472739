import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link } from 'react-router-dom';
import { useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Branch_access() {
    document.title = "Administration | Seriti - Add User";
    const { user_id } = useParams()
    const super_user = localStorage.getItem("super_user");
    const UserId = localStorage.getItem("UserId");
    const Modules = localStorage.getItem("Modules");
    const Modules_info = localStorage.getItem("Modules_info");
    const Levels = localStorage.getItem("Levels");
    const Access = localStorage.getItem("Access");
    const [branchDetails, setBranchDetails] = useState([]);
    const [results, setResults] = useState([]);

    const [group, setGroup] = useState('');
    const [allGroup, setAllGroup] = useState([]);

    const [branch, setBranch] = useState('');
    const [allBranch, setAllBranch] = useState([]);

    const [button, setButton] = useState('');
    const [access_id, setAccessId] = useState('');

    const [fetch, setFetch] = useState(true);

    const [remove, setRemove] = useState(false);

    //--------------------------------group dropdown------------------------------------//
    useEffect(() => {
        // alert("here");
        axios
            .get("index/group")
            .then((response) => {
                // console.log(response.data.data)
                setAllGroup(response.data.data);
                setBranch('ALL')
            })
            .catch((response) => {
                //alert(response.response.data.message);
            });
    }, []);

    //----------------------branch dropdown depends on group----------------------------//
    useEffect(() => {
        if (group != '') {
            axios
                .get("index/branch/" + group)
                .then((response) => {
                    setAllBranch(response.data.data);
                })
                .catch((response) => {
                    //alert(response.response.data.message);
                });
        }
    }, [group]);

    //-------------------initial get branches of user----------------------------------//
    useEffect(() => {
        const payload = {
            user_id: user_id
        }
        if (fetch === true) {
            axios
                .post("admin/branch_access", payload)
                .then((response) => {
                    // console.log(response.data.data);
                    setFetch(false)
                    setBranchDetails(response.data.data);
                    setResults(response.data.data.rResults)
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch(() => {
                    //setLoginLoader(false);
                });
        }
    }, [fetch, user_id]);

    const handleEdit = (e) => {
        e.preventDefault();
        // console.log(button)
        // console.log(access_id)
        // console.log(remove)
        const payload = {
            button: button,
            access_id: access_id,
            user_id: user_id,
            super_user: super_user,
            UserId: UserId,
            Modules: Modules,
            Modules_info: Modules_info,
            Levels: Levels,
            Access: Access,
            division: group,
            department: branch
        };
        if (remove === true) {
            axios
                .post("/admin/update_branch_access", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data.message);            
                        setFetch(true)
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    if (response.response.data.status === 400)
                    toast.error(response.response.data.data, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    };

    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Admin_menu />
            <form method="post" action="" encType="multipart/form-data" onSubmit={handleEdit}>
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="700">
                                <table width="700" border={0} cellSpacing={2} cellPadding={5} className="defaultTbl" align="center">
                                    <tbody>
                                        <tr>
                                            <th colSpan={6} style={{ textAlign: 'center' }}>BRANCH ACCESS</th>
                                        </tr>
                                        <tr className="caption">
                                            <td colSpan={3}></td>
                                            <td>{branchDetails.sDivTitle}</td>
                                            <td>{branchDetails.sDepTitle}</td>
                                            <td>ACCESS</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>DEFAULT</td>
                                            <td>{branchDetails.sUserDivision}</td>
                                            <td>{branchDetails.sUserDepartment}</td>
                                            <td></td>
                                        </tr>
                                        {
                                            results && results.length ? (
                                                results.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td colSpan={3} style={{ textAlign: 'right' }}>{index + 1}.</td>
                                                            <td style={{ textAlign: 'left' }}> {item.group} </td>
                                                            <td style={{ textAlign: 'left' }}> {item.branch} </td>
                                                            <td> <input type="submit" name="command" value="Remove" onClick={(e) => {
                                                                if (window.confirm('Do you really want to delete this record?')) {
                                                                    setRemove(true);
                                                                    setButton(e.target.value);
                                                                    setAccessId(item.access_id);
                                                                }
                                                            }
                                                            } /></td>
                                                        </tr>
                                                    );
                                                })
                                            ) : <tr><td colSpan={6}></td></tr>
                                        }
                                        <tr>
                                            <td colSpan={3}><input type="submit" name="command" value="Add" onClick={(e) => { setButton(e.target.value); setRemove(true) }} /></td>
                                            <td>
                                                <select name="group" id="group" value={group} onChange={(e) => { setGroup(e.target.value); setBranch('ALL') }} className="text-uppercase">
                                                    <option value="ALL">-- SELECT A GROUP --</option>
                                                    {allGroup.map((item) => {
                                                        return (
                                                            <option key={item.id} value={item.id} >
                                                                {item.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </td>
                                            <td>
                                                <select name="branch" id="branch" value={branch} onChange={(e) => setBranch(e.target.value)} className="text-uppercase">
                                                    <option value="ALL">-- Select a GROUP First -</option>
                                                    {allBranch.map((item) => {
                                                        return (
                                                            <option key={item.id} value={item.id} >
                                                                {item.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </td>

                                            <td style={{ textAlign: 'center' }}>
                                                <Link to={"/admin/user_rights/" + user_id} className="btn btn-result-open">
                                                    <input type="button" value="Back" />
                                                </Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody >
                </table >
            </form >
        </>
    );
}

export default Branch_access
