import Admin_menu from "../Admin_menu"
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"
import axios from "../../axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../Components/Loader"

function Add_product() {
    document.title = "Administration | Seriti - Product Admin";
    const navigate = useNavigate();

    const location = useLocation()
    const { type } = location.state //productId
    const { category } = location.state //listParent
    const { command } = location.state //Add/Edit
    const { item_id } = location.state //for Edit case only,for add case its set with 0
   
    const UserId = localStorage.getItem("UserId");
    const division_id = localStorage.getItem("division_id");
    const system_type = localStorage.getItem("system_type");

    const [parentId, setParentId] = useState('');//type id(category id)
    const [allParentId, setAllParentId] = useState([]);//sub-type id(sub-category id)

    const [listParent, setListParent] = useState('');
    const [allListParent, setAllListParent] = useState([]);

    const [product, setProduct] = useState('')

    const [description, setDescription] = useState('')//Display Name

    const [payovers, setPayovers] = useState(false)
    const [vat, setVat] = useState(false)
    const [collection_agent, setAgent] = useState(false);
    const [cash, setCash] = useState(false);
    const [additional, setAdditional] = useState(false);

    const [start_date, setstart_date] = useState('')
    const [end_date, setend_date] = useState('')
    const [adminId, setAdminId] = useState('')
    const [allAdminId, setAllAdminId] = useState([])

    const [ownerId, setOwnerId] = useState('')
    const [allOwnerId, setAllOwnerId] = useState([])

    const [claims, setClaims] = useState('')
    const [allClaims, setAllClaims] = useState([])

    const [writer, setWriter] = useState('')
    const [allWriter, setAllWriter] = useState([])

    const [web_code, setWebCode] = useState('')
    const [regNo, setRegNo] = useState('')

    const [comm_amount, setComAmt] = useState(0)
    const [comm_perc, setComPer] = useState(0)
    const [collection_fee, setCollectionFee] = useState(0)
    const [collection_perc, setCollectionPer] = useState(0)
    const [policy_fee, setPolicyFee] = useState(0)
    const [policy_perc, setPolicyPer] = useState(0)
    const [inspection_fee, setInspectionFee] = useState(0)
    const [inspection_perc, setInspectionPer] = useState(0)
    const [doc_fee, setDocFee] = useState(0)
    const [doc_perc, setDocPer] = useState(0)
    const [notes, setNotes] = useState('')

    const [payment_type, setPaymentType] = useState('');
    const [cost_group, setCostGroup] = useState('');
    const [display, setDisplay] = useState('');

    const [loader, setLoader] = useState(false);
    
    //----------------------------dropdown of type----------------------------------// 
    useEffect(() => {
        const payload = {
            division_id: division_id,
            system_type: system_type
        };
        axios
            .post("category_options", payload)
            .then((response) => {
                //   console.log(response.data.data)
                setAllParentId(response.data.data.rTypes);
                setParentId(type)
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, [division_id, system_type, type]);

    //---------------------------dropdown of category---------------------------------//
    useEffect(() => {
        if (parentId > 0) {
            axios
                .get("get_list/" + parentId)
                .then((response) => {
                    // console.log(response.data.data)
                    setAllListParent(response.data.data);
                    setListParent(category)
                })
                .catch((response) => {
                    //alert(response.data);
                    console.log("error")
                });
        }else{
            setAllListParent([]);
        }
    }, [parentId, category]);

    //--------------------------all dropdown------------------------------------------//
    useEffect(() => {
        axios
            .get("dropdown_add_product")
            .then((response) => {
                // console.log(response.data.data.rAdmins)
                setAllAdminId(response.data.data.rAdmins);
                setAllOwnerId(response.data.data.rSuppliers);
                setAllClaims(response.data.data.sClaimsCompanies);
                setAllWriter(response.data.data.sUnderwriterCompanies);
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, []);
    
    //--------------------------------handle validation-------------------------------//
    const handleValidation = () => {
        var flag = true;
        const regexp = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/
        if (product === '') {
            alert('A Product name must be entered.');
            flag = false;
        }
        if (parentId === '0' || listParent === '0') {
            alert('Please select Type and category.');
            flag = false;
        }
        if (adminId === '0') {
            alert('Please select Administrator.');
            flag = false;
        }
        if (ownerId === '0') {
            alert('Please select Owner.');
            flag = false;
        }
        if (end_date !== '') {
            let date1;
            if (start_date === '')      //this is for add condition
                date1 = new Date().getTime(); //getting today date
            else                       //this is for edit conditin
                date1 = new Date(start_date).getTime();

            let date2 = new Date(end_date).getTime();
            if (date1 > date2) {
                alert('Start date is greater than End date. Please enter Valid End date');
                setend_date(''); 
                flag = false;
            }
        }
        if (comm_amount !== 0 || comm_perc !== 0 || collection_fee !== 0 || collection_perc !== 0 || policy_fee !== 0 || policy_perc !== 0 || inspection_fee !== 0 || inspection_perc !== 0 || doc_fee !== 0 || doc_perc !== 0) {
            if (!regexp.test(comm_amount) || !regexp.test(comm_perc) || !regexp.test(collection_fee) || !regexp.test(collection_perc) || !regexp.test(policy_fee) || !regexp.test(policy_perc) || !regexp.test(inspection_fee) || !regexp.test(inspection_perc) || !regexp.test(doc_fee) || !regexp.test(doc_perc)) {
                alert('Please enter a valid amount.');
                flag = false;
            }
        }
        return flag;
    };
    
    //------------------------------add data------------------------------------------//
    const handleAdd = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        if (flag) {
            setLoader(true);
            const payload = {
                user_id: UserId,
                system_type: system_type,
                command: command,
                id: item_id, //dor edit case send id of product
                parent_id: parentId,//type_id
                selection: listParent,//category
                product: product,
                description: description,//Display Name
                payovers: payovers,
                vat: vat,
                end_date: end_date !== '' ? moment(end_date).format("YYYY-MM-DD") : 0,
                administration_id: adminId,
                owner_id: ownerId,
                claims_id: claims,
                underwriter_id: writer,
                payment_type: payment_type,
                display: display,
                collection_agent: collection_agent ? 1 : 0,
                cash: cash,
                additional: additional ? 1 : 0,
                cost_group: cost_group,
                web_code: web_code,
                registration_number: regNo,
                //Costing defaults (All Incl VAT)
                comm_amount: comm_amount,
                comm_perc: comm_perc,
                collection_fee: collection_fee,
                collection_perc: collection_perc,
                policy_fee: policy_fee,
                policy_perc: policy_perc,
                inspection_fee: inspection_fee,
                inspection_perc: inspection_perc,
                doc_fee: doc_fee,
                doc_perc: doc_perc,
                notes: notes,
            };
            axios
                .post("admin/product/add", payload)
                .then((response) => {
                    setLoader(false);
                    if (response.data.status === 200) {
                        navigate('/admin/financial_products', { state: { type: parentId, listParent: listParent } });
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    setLoader(false);
                    if (response.response.data.status === 400)
                        toast.error(response.response.data.data, {
                        position: toast.POSITION.TOP_CENTER
                        }); 
                    else  console.log("error"); 
                });
        }
    }

    
    return (
        <>
        <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Admin_menu />
            {loader ? <Loader /> : null}
            <table width="100%" height="1024" id="mainContainer" border="0" cellPadding={0} cellSpacing={0} className="custom-body bg-white">
                <tbody>
                    <tr valign="top">
                        <td></td>
                        <td width="100%">
                            <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                            <table width="100%" border="0" cellPadding={5} cellSpacing={5}>
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                           
                                            <form method="post" name="new_user" id="new_user" onSubmit={handleAdd}>
                                                <table width="500" className="defaultTbl tablesorter" align="center" cellSpacing={1}>
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan={2} style={{ textAlign: 'center' }}>Add Financial Product</th>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption" width="100">Type<span style={{ color: "red" }}> *</span></td>
                                                            <td>
                                                                <select name="parentId" value={parentId} className="text-uppercase" onChange={(e) => setParentId(e.target.value)}>
                                                                    <option value="0"> -- Select -- </option>
                                                                    {allParentId.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id} >
                                                                                {item.option}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption" width="100">Category<span style={{ color: "red" }}> *</span></td>
                                                            <td>
                                                                <select name="listParent" id="listParent" className="text-uppercase" value={listParent} onChange={(e) => setListParent(e.target.value)}>
                                                                    <option value="0"> -- Select -- </option>
                                                                    {allListParent.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id} >
                                                                                {item.option}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption" width="100">Product<span style={{ color: "red" }}> *</span></td>
                                                            <td><td><input type="text" name="product" value={product} onChange={(e) => setProduct(e.target.value)} size={50} className="text-uppercase" /></td></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption" width="100">Display Name</td>
                                                            <td><input type="text" name="description" value={description} size={50} onChange={(e) => setDescription(e.target.value)} className="text-uppercase" /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption" nowrap="nowrap" width="100">Editable Payovers</td>
                                                            <td><input type="checkbox" name="payovers" value={payovers} checked={payovers} onChange={(e) => setPayovers(!payovers)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">VAT Inclusive</td>
                                                            <td><input type="checkbox" name="vat" value={vat} checked={vat} onChange={(e) => setVat(!vat)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Created Date</td>
                                                            <td>{start_date}</td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">End Date</td>
                                                            <td>
                                                                {/* {endFlag === 1 ? */}
                                                                    <DatePicker id="end_date" name="end_date" selected={end_date} onChange={(date) => setend_date(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                    {/* :
                                                                    <DatePicker id="end_date" name="end_date" value={end_date} onChange={(date) => setend_date(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                } */}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Administrator<span style={{ color: "red" }}> *</span></td>
                                                            <td><select name="administrator_id" className="text-uppercase" style={{ width: '239px' }} value={adminId} onChange={(e) => setAdminId(e.target.value)}>
                                                                <option value="0">--select--</option>
                                                                {allAdminId.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id} >
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Owner<span style={{ color: "red" }}> *</span></td>
                                                            <td>
                                                                <select name="owner_id" className="text-uppercase" value={ownerId} onChange={(e) => setOwnerId(e.target.value)} style={{ width: '239px' }}>
                                                                    <option value="0">--select--</option>
                                                                    {allOwnerId.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id} >
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Claims</td>
                                                            <td>
                                                                <select name="claims" className="text-uppercase" value={claims} onChange={(e) => setClaims(e.target.value)} style={{ width: '239px' }}>
                                                                    <option value="0">--select--</option>
                                                                    {allClaims.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id} >
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Underwriter</td>
                                                            <td>
                                                                <select name="underwriter" className="text-uppercase" value={writer} onChange={(e) => setWriter(e.target.value)} style={{ width: '239px' }}>
                                                                    <option value="0">--select--</option>
                                                                    {allWriter.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id} >
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Payment type</td>
                                                            <td>
                                                                <input type="radio" name="payment_type" value="1" onChange={(e) => { setPaymentType(e.target.value) }} defaultChecked={(payment_type === 1) ? true : null} />Single
                                                                <input type="radio" name="payment_type" value="2" onChange={(e) => { setPaymentType(e.target.value) }} defaultChecked={(payment_type === 2) ? true : null} />Monthly
                                                                <input type="radio" name="payment_type" value="3" onChange={(e) => { setPaymentType(e.target.value) }} defaultChecked={(payment_type === 3) ? true : null} />Annual
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Display Type</td>
                                                            <td>
                                                                <select name="display" className="text-uppercase" value={display} onChange={(e) => setDisplay(e.target.value)} style={{ width: '239px' }}>
                                                                    <option value="1">Single Selection</option>
                                                                    <option value="2">Multiple Selection</option>
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Collection Agent choice</td>
                                                            <td><input className="collection_agent" type="checkbox" value={collection_agent} checked={collection_agent} onChange={(e) => setAgent(!collection_agent)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Allow Cash</td>
                                                            <td><input name="cash" type="checkbox" value={cash} checked={cash} onChange={(e) => setCash(!cash)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Maintenace plans</td>
                                                            <td><input type="checkbox" name="additional" value={additional} checked={additional} onChange={(e) => setAdditional(!additional)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Default cost group</td>
                                                            <td>
                                                                <input type="radio" name="cost_group" value='1' onChange={(e) => { setCostGroup(e.target.value) }} defaultChecked={(cost_group === 1) ? true : null} /> 1
                                                                <input type="radio" name="cost_group" value='2' onChange={(e) => { setCostGroup(e.target.value) }} defaultChecked={(cost_group === 2) ? true : null} /> 2
                                                                <input type="radio" name="cost_group" value='3' onChange={(e) => { setCostGroup(e.target.value) }} defaultChecked={(cost_group === 3) ? true : null} /> 3
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Web service code</td>
                                                            <td><input type="text" name="web_code" className='caption text-uppercase' value={web_code} onChange={(e) => setWebCode(e.target.value)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Registration Number</td>
                                                            <td><input type="text" className='caption text-uppercase' name="registration_number" value={regNo} onChange={(e) => setRegNo(e.target.value)} /></td>
                                                        </tr>

                                                        <tr><td colSpan={2} className='caption text-center'>Costing defaults (All Incl VAT)</td></tr>

                                                        <tr>
                                                            <td className="caption">Commission Amount</td>
                                                            <td><input type="text" name="comm_amount" value={comm_amount} onChange={(e) => setComAmt(e.target.value)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Commission Percentage</td>
                                                            <td><input type="text" name="comm_perc" size="3" value={comm_perc} onChange={(e) => setComPer(e.target.value)} />%</td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Collection Fee</td>
                                                            <td><input type="text" name="collection_fee" value={collection_fee} onChange={(e) => setCollectionFee(e.target.value)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Collection Percentage</td>
                                                            <td><input type="text" name="collection_perc" size="3" value={collection_perc} onChange={(e) => setCollectionPer(e.target.value)} />%</td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Policy Fee</td>
                                                            <td><input type="text" name="policy_fee" value={policy_fee} onChange={(e) => setPolicyFee(e.target.value)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Policy Percentage</td>
                                                            <td><input type="text" name="collection_perc" size="3" value={policy_perc} onChange={(e) => setPolicyPer(e.target.value)} />%</td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Inspection Fee</td>
                                                            <td><input type="text" name="inspection_fee" value={inspection_fee} onChange={(e) => setInspectionFee(e.target.value)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Inspection Percentage</td>
                                                            <td><input type="text" name="inspection_perc" size="3" value={inspection_perc} onChange={(e) => setInspectionPer(e.target.value)} />%</td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Dealer Document Fee</td>
                                                            <td><input type="text" name="doc_fee" value={doc_fee} onChange={(e) => setDocFee(e.target.value)} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Dealer Document Percentage</td>
                                                            <td><input type="text" name="doc_perc" size="3" value={doc_perc} onChange={(e) => setDocPer(e.target.value)} />%</td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">Notes</td>
                                                            <td><input type="text" name="notes" className='caption text-uppercase' value={notes} onChange={(e) => setNotes(e.target.value)} /></td>
                                                        </tr>

                                                        <tr className="caption">
                                                            <td colSpan="2" className="text-center">
                                                                <input type="submit" value='Save' name="submit" />

                                                                <Link to="/admin/financial_products" state={{ type: type,listParent : category }}>
                                                                    <input type="button" name="command" value="Back" />
                                                                </Link>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </form>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody></table>
        </>
    );
}

export default Add_product