import React, { useState, useEffect } from "react";
import axios from "../axios";

function Documents_table_rows({rowsData, handleDocumentChange, instanceNumber1, colorFocus, docsvalidateDescName, setFile, setFileAddFlag, setFileEditName, setvalidateflag, TypeDiscription, setTypeDiscription, TypeDocument, setTypeDocument, FileDocument, setFileDocument}) {

    const [DocumentTypeAll, setDocumentTypeAll] = useState([]);
    const [Kyc, setKyc] = useState(1);
    // const [chkSelect, setchkSelect] = useState(1);
  
    //----------- get type id -----------------
    useEffect(() => {     
        axios
          .get("fill_forms/featch_document_type")
          .then((response) => {
            //console.log(response.data.type_array);
            setDocumentTypeAll(response.data.type_array);
          })
          .catch(() => {
             console.log('error');
          });
    }, []);

    return(
        
        rowsData.map((data, index)=>{
            const {document_type, description1, checked1, up_file, sel_check}= data;
            const final_instance_number = instanceNumber1+index+1;
            const temp_document_type = `sub_${final_instance_number}_field_232`;
            const temp_description = `sub_${final_instance_number}_field_233`;
            const temp_check_val = `sub_${final_instance_number}_field_1870`;
            const temp_up_file = `sub_${final_instance_number}_field_234`;
            // const temp_sel_check = `sub_${final_instance_number}_field_1871`;
            
            // if(checked == true){
            //     const checked = 1;
            // }
            return(
                <tr key={index}>
                <td>
                    {/* <input type="hidden" name="control_instance_number" value={index} onChange={(evnt)=>(handleChange(index, evnt))}/> */}
                    <select name={temp_document_type} id={temp_document_type} className="text-uppercase" value={TypeDocument} onChange={(evnt)=> { handleDocumentChange(index, evnt); setTypeDocument(evnt.target.value); }}>  
                        <option value="">Select an option</option>
                        {DocumentTypeAll.map((data) => {
                            return (
                            <option key={data.id} value={data.id}>
                                {data.type_name}
                            </option>
                            );
                        })}
                    </select>
                </td>
                <td nowrap="nowrap" className="text-center">
                     <input type="text"  style={temp_description == docsvalidateDescName ? {borderColor: colorFocus, width: '100%'} : {width: '100%'}} name={temp_description} id="sub_1_field_233" className="text-uppercase" value={TypeDiscription} onChange={(evnt)=>{ handleDocumentChange(index, evnt); setTypeDiscription(evnt.target.value); setvalidateflag(1); }} />
                </td>
                
                <td nowrap="nowrap" className="text-center">
                    <input type="checkbox" name={temp_check_val} id={temp_check_val} value={Kyc} onChange={(evnt)=> { handleDocumentChange(index, evnt);setKyc(!Kyc) }} />
                </td>
               
                <td nowrap="nowrap" className="text-center">
                    <input type="file" name={temp_up_file} id={temp_up_file} value={FileDocument} onChange={(evnt)=> {  setFile(evnt.target.files[0]); setFileAddFlag(1); setFileEditName(temp_up_file); setFileDocument(evnt.target.value); }} data-schema-key={temp_up_file} accept=".pdf"/>
                </td>
                {/* <td nowrap="nowrap" className="text-center">
                    <input type="checkbox" name={temp_sel_check} id={temp_sel_check} value={chkSelect} onChange={(evnt)=> { handleDocumentChange(index, evnt);setchkSelect(!chkSelect) }} />
                    <input type="hidden" id="sub_1_field_1872" name="sub_1_field_1872" />
                </td> */}
                <td nowrap="nowrap" className="text-center">
                    &nbsp;
                </td>
            </tr>
            )
        })
   
    )
    
}
export default Documents_table_rows;