import { Link } from "react-router-dom"
import React, { useState, useEffect, useRef } from "react";
//import { useLocation } from "react-router";
import Transaction_menu from "../Transaction/Transaction_menu";
import axios from "../axios";


const Comp_insurance_discount = () => {
  
  const IncreaseRef = useRef();
  const IncreaseReasonRef = useRef();
  const DiscountAmtRef = useRef();
  const DiscountReasonRef = useRef();
  
  const system_type = localStorage.getItem("system_type");
  const user_id = localStorage.getItem("UserId");
  const transaction_id = localStorage.getItem("transaction_id");
  const user_country_id  = localStorage.getItem("user_country_id");
  
  const [SystemPrice, setSystemPrice] = useState(""); 
  const [Increase, setIncrease] = useState(""); 
  const [IncreasedPrice, setIncreasedPrice] = useState(""); 
  const [IncreaseReason, setIncreaseReason] = useState(""); 
 
  const [DisSystemPrice, setDisSystemPrice] = useState(""); 
  const [DiscountAmt, setDiscountAmt] = useState(""); 
  const [DiscountedPrice, setDiscountedPrice] = useState(""); 
  const [DiscountReason, setDiscountReason] = useState(""); 
  
  const [IncreaseSave, setIncreaseSave] = useState("Increase");
  const [DiscountSave, setDiscountSave] = useState("Discount");

  const [FooterData, setFooterData] = useState(""); 
  const [TrasactionProductDiscountDetails, setTrasactionProductDiscountDetails] = useState([]); 
  
  useEffect(() => {
    document.title = 'Transactions | Seriti BW';
  }, []);

  //console.log(TrasactionProductDiscountDetails);
  const handleAllowNumberFormate = (price,flag) => {
    if(flag == 1){
      const value = price.replace(/\D/g, "");
      setSystemPrice(value);
    }

    if(flag == 2){
      const value = price.replace(/\D/g, "");
      setIncrease(value);
    }

    if(flag == 3){
      const value = price.replace(/\D/g, "");
      setIncreasedPrice(value);
    }

    if(flag == 4){
      const value = price.replace(/\D/g, "");
      setDisSystemPrice(value);
    }

    if(flag == 5){
      const value = price.replace(/\D/g, "");
      setDiscountAmt(value);
    }

    if(flag == 6){
      const value = price.replace(/\D/g, "");
      setDiscountedPrice(value);
    }
  };

  useEffect(() => {
    const payload = {
        transaction_id: transaction_id,
    };
    //console.log(payload); 
    axios
    .post("transcation/featch_product_transcation_discount_details", payload)
    .then((response) => {
      //console.log(response); 
      setTrasactionProductDiscountDetails(response.data.data);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    })
    .catch(() => {
        console.log('error'); 
    });
  }, []); 

  useEffect(() => {
    const payload = {
        transaction_id: transaction_id,
    };
    //console.log(payload); 
    axios
    .post("transcation/product_transcation_discount_featch_amount", payload)
    .then((response) => {
      setSystemPrice(response.data); 
      setDisSystemPrice(response.data); 
    })
    .catch(() => {
        console.log('error'); 
    });
  }, []); 

useEffect(() => {
  const payload = {
      transaction_id: transaction_id,
      system_type: system_type,
      user_country_id: user_country_id,
      user_id: user_id,
  };
  //console.log(payload); 
  axios
  .post("dashboard_transcation/dashboard_transaction_footer", payload)
  .then((response) => {
  // console.log(response); 
      setFooterData(response.data); 
  })
  .catch(() => {
      console.log('error'); 
  });
}, []); 

const handleIncreaseValidation = () => {
  var flag = true;
  if (Increase == "") {
         alert("Please enter a increase amount")
         IncreaseRef.current.focus();
         flag = false;
  }
  else if (IncreaseReason.length < 10) {
    alert("Please provide a detailed reason to increase the insurance. Min 10 characters.")
    IncreaseReasonRef.current.focus();
    flag = false;
  }
  return flag;
};

const handleIncreaseSubmit = (e) => {
  e.preventDefault();
  const flag = handleIncreaseValidation();
  if (flag) {
      const payload = {
          transaction_id: transaction_id,
          user_id: user_id,
          command: IncreaseSave,
          system_price: SystemPrice,
          increase: Increase,
          increased_price: IncreasedPrice,
          increase_reason: IncreaseReason,
      };
      //console.log(payload);
        axios
          .post("transcation/product_transcation_discount_add_edit", payload)
          .then((response) => {
              //console.log(response); 
              if(response.status == 200){
                window.location.reload(false);  
              }
          })
          .catch(() => {
             console.log('error');
          });
  }
};

const handleDiscountValidation = () => {
  var flag = true;
  if (DiscountAmt == "") {
         alert("Please enter a discount amount")
         DiscountAmtRef.current.focus();
         flag = false;
  }
  else if (DiscountReason.length < 10) {
    alert("Please provide a detailed reason to discount the insurance. Min 10 characters.")
    DiscountReasonRef.current.focus();
    flag = false;
  }
  return flag;
};

const handleDiscountSubmit = (e) => {
  e.preventDefault();
  const flag = handleDiscountValidation();
  if (flag) {
      const payload = {
          transaction_id: transaction_id,
          user_id: user_id,
          command: DiscountSave,
          dis_system_price: DisSystemPrice,
          discount: DiscountAmt,
          discounted_price: DiscountedPrice,
          discount_reason: DiscountReason,
      };
      //console.log(payload);
        axios
          .post("transcation/product_transcation_discount_add_edit", payload)
          .then((response) => {
              if(response.status == 200){
                window.location.reload(false);  
              }
          })
          .catch(() => {
             console.log('error');
          });
  }
};

const ResetAll = () => {

  const payload = {
    transaction_id: transaction_id,
    user_id: user_id,
    command: "Reset All"
   };
   //console.log(payload);
   axios
   .post("transcation/product_transcation_discount_add_edit", payload)
   .then((response) => {
       if(response.status == 200){
         window.location.reload(false);  
       }
   })
   .catch(() => {
      console.log('error');
   });
  
  // setIncrease("");
  // setIncreasedPrice("");
  // setIncreaseReason("");
  // setDiscountAmt("");
  // setDiscountedPrice("");
  // setDiscountReason(""); 


}

const IncreaseCalculation = () => {
  if(Increase != '' && SystemPrice != ''){
    let newIncrease = parseInt(Increase, 0);
    let newSystemPrice = parseInt(SystemPrice, 0);
    setIncreasedPrice(newIncrease + newSystemPrice);
  }else{
    setIncreasedPrice(SystemPrice);
  }
}

const DiscountCalculation = () => {
  if(DiscountAmt != '' && DisSystemPrice != ''){
    let newDisSystemPrice = parseInt(DisSystemPrice, 0);
    let newDiscountAmt = parseInt(DiscountAmt, 0);
    setDiscountedPrice(newDisSystemPrice - newDiscountAmt);
  }
  else{
    setDiscountedPrice(DisSystemPrice);
  }
}

let panFlag = 1;

return (
      <>
       <Transaction_menu panel_flag={panFlag}/>
       <div>
           <table width="100%" height="1024" id="mainContainer" border="0" cellPadding={0} cellSpacing={0} className="custom-body">
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">
                                <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                                <table width="100%" border="0" cellPadding={5} cellSpacing={5}>
                                  <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                          <table width="950">
                                            <tbody>
                                              <tr valign="top" height="100%">
                                               <td rowSpan={3} width="50%" height="100%" className="text-left">
                                                <form name="command" method="post" onSubmit={handleIncreaseSubmit} >
                                                  <table className="defaultTbl" width="100%" height="100%" cellSpacing={1}>
                                                    <tbody>
                                                      <tr>
                                                        <th colSpan={2} className="text-center">Increase Comprehensive Insurance</th>
                                                      </tr>
                                                      <tr>
                                                        <td>System Price</td>
                                                        <td>
                                                            <input type="text" name="price" id="system_price" value={SystemPrice} onChange = {(e) => handleAllowNumberFormate(e.target.value,1)} readOnly/>
                                                        </td> 
                                                      </tr>

                                                      <tr>
                                                        <td>Increase Amount</td>
                                                        <td>
                                                            <input type="text" name="increase" id="increase" value={Increase} onChange = {(e) => handleAllowNumberFormate(e.target.value,2)} onBlur={IncreaseCalculation} ref={IncreaseRef} />
                                                        </td>
                                                      </tr>
                                                     
                                                      <tr>
                                                        <td>Increased Price</td>
                                                        <td>
                                                            <input type="text" name="increased_price" id="increased_price" value={IncreasedPrice} onChange = {(e) => handleAllowNumberFormate(e.target.value,3)} readOnly/>
                                                        </td>
                                                      </tr>

                                                      <tr>
                                                        <td>Reason</td>
                                                        <td>
                                                           <textarea name="increase_reason" id="increase_reason" cols={55} rows={3} className="text-uppercase" value={IncreaseReason} onChange = {(e) => setIncreaseReason(e.target.value)} ref={IncreaseReasonRef} />
                                                        </td>
                                                      </tr>

                                                      <tr className="caption">
                                                         <td colSpan={2} className="text-center">
                                                            <input type="submit" value={IncreaseSave} name="command" onClick={(e) => { setIncreaseSave(e.target.value) }}/>&nbsp;
                                                            <input type="button" value="Reset All" name="command" onClick={(e) => { if (window.confirm('Are you sure you want to reset all discount & increase?')) { ResetAll() } }}/>
                                                         </td>
                                                      </tr>
                                                      
                                                    </tbody>
                                                  </table>
                                                  </form>
                                                </td>

                                                <td rowSpan={3} width="50%" height="100%" className="text-left">
                                                <form name="command" method="post" onSubmit={handleDiscountSubmit} >
                                                  <table className="defaultTbl" width="100%" height="100%" cellSpacing={1}>
                                                    <tbody>
                                                      <tr>
                                                        <th colSpan={2} className="text-center">Discount Comprehensive Insurance</th>
                                                      </tr>
                                                      <tr>
                                                        <td>System Price</td>
                                                        <td>
                                                            <input type="text" name="price" id="dis_system_price" value={DisSystemPrice} onChange = {(e) => handleAllowNumberFormate(e.target.value,4)} readOnly/>
                                                        </td>
                                                      </tr>
                                                     
                                                      <tr>
                                                        <td>Discount Amount</td>
                                                        <td>
                                                            <input type="text" name="discount" id="discount" value={DiscountAmt} onChange = {(e) => handleAllowNumberFormate(e.target.value,5) } onBlur={DiscountCalculation} ref={DiscountAmtRef} />
                                                        </td>
                                                      </tr>

                                                      <tr>
                                                        <td>Discounted Price</td>
                                                        <td>
                                                            <input type="text" name="discounted_price" id="discounted_price" value={DiscountedPrice} onChange = {(e) => handleAllowNumberFormate(e.target.value,6)} readOnly/>
                                                        </td>
                                                      </tr>

                                                      <tr>
                                                        <td>Reason</td>
                                                        <td>
                                                           <textarea name="discount_reason" id="discount_reason" cols={55} rows={3} className="text-uppercase" value={DiscountReason} onChange = {(e) => setDiscountReason(e.target.value)} ref={DiscountReasonRef}/>
                                                        </td>
                                                      </tr>

                                                      <tr className="caption">
                                                         <td colSpan={2} className="text-center">
                                                            <input type="submit" value={DiscountSave} name="command" onClick={(e) => { setDiscountSave(e.target.value) }}/>&nbsp;
                                                            <input type="submit" value="Reset All" name="command" onClick={() => { if (window.confirm('Are you sure you want to reset all discount & increase?')) { ResetAll() } }}/>
                                                         </td>
                                                      </tr>

                                                    </tbody>
                                                  </table>
                                                  </form>
                                                </td>

                                              </tr>
                                            </tbody>
                                          </table>
                                       
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <table width="800" align="center">
                                            <tbody>
                                              <tr valign="top" height="100%" className="text-center">
                                                <td width="50%" height="100%" className="text-center">
                                                {/* <form method="post"> */}
                                                  <table className="defaultTbl" width="100%" height="100%" cellSpacing={1} style={{textAlign: 'center', float: 'center'}} >
                                                    <tbody>
                                                      <tr>
                                                        <th colSpan={4} className="text-center">Insurance Audit Trail</th>
                                                      </tr>
                                                      <tr className="caption">
                                                        <td>Date</td>
                                                        <td>Action</td>
                                                        <td>User</td>
                                                        <td>Reason</td>
                                                      </tr>

                                                      {TrasactionProductDiscountDetails.length > 0 ?
                                                          TrasactionProductDiscountDetails.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td style={{textAlign: 'left'}}>{item.date}</td>
                                                                    <td style={{textAlign: 'left'}}>{item.action}</td>
                                                                    <td style={{textAlign: 'left'}}>{item.user}</td>
                                                                    <td style={{textAlign: 'left'}}>{item.reason}</td>
                                                                </tr>
                                                            );
                                                          }) :
                                                
                                                           <tr>
                                                               <td colSpan={4}>No Record Found</td>
                                                           </tr>
                                                        }

                                                      <tr>
                                                        <td colSpan={4} className="text-center">
                                                          <Link to={"/transaction/create_transaction/" + transaction_id}> 
                                                             <input type="button" value="Back" />
                                                          </Link>
                                                        </td>
                                                      </tr>

                                                      </tbody>
                                                    </table>
                                                  </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                  </tbody>
                                </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    
                    <table width="100%" height="14" cellPadding={0} cellSpacing={0} style={{position: 'fixed', bottom: '0px', left: '0px'}}>
                    <tbody>
                        <tr>
                            <td width="100%" height="14" className="footer-img">
                                <div dangerouslySetInnerHTML={ {__html:FooterData} } />
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
      </>
    );
}

export default Comp_insurance_discount;