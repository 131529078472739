import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import TokenReducer from "./features/TokenSlice";
import TimeZoneReducer from './features/TimeZoneSlice';

const store = configureStore({
  reducer: {
    token: TokenReducer,
    //TimeZone:TimeZoneReducer
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>

  // <React.StrictMode>
  <Provider store={store}>
    {/* local change */}
    {/* <BrowserRouter>  */}
    {/* live server change */} 
    <BrowserRouter basename="/"> 
       <App />
    </BrowserRouter>
  </Provider>
// {/* </React.StrictMode> */}

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
