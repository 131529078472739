import React, { useState, useEffect, useRef } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../Components/Loader"

function Loanhouses() {
    document.title = "Administration | Seriti - Finance Institution";
    const navigate = useNavigate();
    const { loanhouse_id } = useParams();

    const emailRef = useRef();
    const emailRef2 = useRef();
    const user_id = localStorage.getItem("UserId")

    const [tableHead, settableHead] = useState('ADD NEW LOANHOUSE');

    const [Code, setCode] = useState('');
    const [name, setName] = useState('');

    const [country_id, setCountryId] = useState('');
    const [allCountry, setAllCountry] = useState([]);
    const [PrivateForm, setPrivateForm] = useState('');
    const [allPrivateForm, setAllPrivateForm] = useState([]);
    const [BusinessForm, setBusinessForm] = useState('');
    const [allBusinessForm, setAllBusinessForm] = useState([]);
    const [logo, setLogo] = useState('');
    const [logo2, setLogo2] = useState('');
    const [email1, setEmail1] = useState('');
    const [email2, setEmail2] = useState('');
    const [email3, setEmail3] = useState('');
    const [VehicleCodingSystem, setVechileCodingSystem] = useState('');

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        axios
            .get("/loanhouses_edit/forms_groups")
            .then((response) => {
                if (response.data.status === 200) {
                    // setNameError(response.data.message);
                    // console.log(response.data.data[0]);
                    setAllPrivateForm(response.data.data[0])
                    setAllBusinessForm(response.data.data[0])
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                alert(response.response.data.message);
            });
    }, []);

    const handleValidation = () => {
        var flag = true;
        if (Code === '' || name === '' || country_id === '' || PrivateForm === '' || BusinessForm === '' || email1 === '' || email2 === '') {
            alert('Please fill in all the fields!');
            flag = false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email1)) {
            alert('Please enter a valid email address');
            emailRef.current.focus();
            flag = false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email2)) {
            alert('Please enter a valid email2 address');
            emailRef2.current.focus();
            flag = false;
        } else if (email1 === email2) {
            alert('Email address 1 and Email address 2 should not be same');
            flag = false;
        } else if (email1 === email3) {
            alert('Email address 1 and Email address 3 should not be same');
            flag = false;
        } else if (email3 === email2) {
            alert('Email address 2 and Email address 3 should not be same');
            flag = false;
        } else if (email3 !== '') {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email3)) {
                alert('Please enter a valid email3 address');
                flag = false;
            }
        } else if (email3 === '') {
            flag = true;
        }
        if (logo2) {
            if (!logo2.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                alert('select valid image.');
                flag = false;
            }
        }
        return flag;
    };
    //console.log(logo);
    const handleEdit = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        if (flag) {
            setLoader(true);
            var bodyFormData = new FormData();
            bodyFormData.append("user_id", user_id);
            bodyFormData.append("logo_path", logo2);
            bodyFormData.append("loanhouse_id", loanhouse_id);
            bodyFormData.append("code", Code);
            bodyFormData.append("name", name);
            bodyFormData.append("countries_country", country_id);
            bodyFormData.append("formgroup_id", PrivateForm);
            bodyFormData.append("business_formgroup_id", BusinessForm);
            bodyFormData.append("email", email1);
            bodyFormData.append("email2", email2);
            bodyFormData.append("email3", email3);
            bodyFormData.append("vehicle_coding_system", VehicleCodingSystem);
            
            axios
                .post("/admin/add_update_loanhouse", bodyFormData)
                .then((response) => {
                    setLoader(false);
                    if (response.data.status === 200) {
                        //console.log(response.data.data) === loanhouse_id
                        navigate('/admin/loanhouses/' + response.data.data);
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    setLoader(false);
                    if (response.response.data.status === 400)
                    toast.error(response.response.data.data, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    };

    //----------------------------country dropdown---------------------------------------//
    useEffect(() => {
        axios
            .get("country_list")
            .then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data.data[0]);
                    setAllCountry(response.data.data[0])
                }
            })
            .catch((response) => {
                alert(response.response.data.message);
            });
    }, []);

    //-------------------------fetch loanhouse data for edit-----------------------------//
    useEffect(() => {
        if (loanhouse_id > 0) {
            const payload = {
                loanhouse_id: loanhouse_id
            };

            axios
                .post("/admin/view_loanhouse", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data.data[0]);
                        settableHead('EDIT ' + response.data.data[0].name)

                        setCode(response.data.data[0].code)
                        setName(response.data.data[0].name)
                        setCountryId(response.data.data[0].country_id)
                        setPrivateForm(response.data.data[0].formgroup_id)
                        setBusinessForm(response.data.data[0].business_formgroup_id)
                        setLogo(response.data.data[0].logo)
                        setEmail1(response.data.data[0].email)
                        setEmail2(response.data.data[0].email2)
                        setEmail3(response.data.data[0].email3)
                        setVechileCodingSystem(response.data.data[0].vehicle_coding_system_no)
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    //alert(response.response.data.message);
                });
        }
    }, [loanhouse_id]);
    // console.log(allCountry)

    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Admin_menu />
            {loader ? <Loader /> : null}
            <form method="post" action="" onSubmit={handleEdit} encType="multipart/form-data" >
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">
                                <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center"><p></p>
                                                <table className="defaultTbl" cellSpacing={1} align="center">
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan="2" className="text-center">{tableHead}</th>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Company Code<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="text" name="code" className="text-uppercase" value={Code} onChange={(e) => { setCode(e.target.value) }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Name<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="text" name="name" value={name} onChange={(e) => { setName(e.target.value) }} className="text-uppercase" /></td>
                                                        </tr>
                                                        <tr id="country">
                                                            <td className="caption">Country<span style={{ color: "red" }}> *</span></td>
                                                            <td>
                                                                <select name="country" value={country_id} onChange={(e) => { setCountryId(e.target.value) }} className="text-uppercase">
                                                                    <option value="">--Select Country--</option>
                                                                    {allCountry.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id} defaultValue={(item.id === country_id) ? 'Selected' : ''} >
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Private Form<span style={{ color: "red" }}> *</span></td>
                                                            <td>
                                                                <select name="formgroup_id" value={PrivateForm} onChange={(e) => { setPrivateForm(e.target.value) }} >
                                                                    <option value=""></option>
                                                                    {allPrivateForm.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id} defaultValue={(item.id === PrivateForm) ? 'Selected' : ''} >
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Business Form<span style={{ color: "red" }}> *</span></td>
                                                            <td><select name="business_formgroup_id" value={BusinessForm} onChange={(e) => { setBusinessForm(e.target.value) }} >
                                                                <option value=""></option>
                                                                {allBusinessForm.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id} defaultValue={(item.id === BusinessForm) ? 'Selected' : ''} >
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Logo</td>
                                                            <td>
                                                                {/* <input type="file" name="import_file" onChange={(e) => setFile(e.target.files[0])} required /> */}

                                                                <input type="file" name="logo_path" onChange={(e) => setLogo2(e.target.files[0])} />
                                                                <br /><em>If no file is selected, logo will remain unchanged.</em>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Email Address<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="text" name="email" value={email1} onChange={(e) => { setEmail1(e.target.value) }} ref={emailRef} className="text-uppercase" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Email Address 2<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="text" name="email2" value={email2} onChange={(e) => { setEmail2(e.target.value) }} ref={emailRef2} className="text-uppercase" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Email Address 3</td>
                                                            <td><input type="text" name="email3" value={email3} onChange={(e) => { setEmail3(e.target.value) }} className="text-uppercase" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Vehicle Coding System</td>
                                                            <td style={{ display: "flex" }}>
                                                                <div style={{ display: "flex", marginRight: "5px" }}>
                                                                    <input type="radio" name="vehicle_coding_system" value="0" onChange={(e) => { setVechileCodingSystem(e.target.value) }} defaultChecked={(VehicleCodingSystem === 0) ? true : null} /> <p style={{ margin: 0, marginLeft: "2px" }}>Not Applicable</p>
                                                                </div>

                                                                <div style={{ display: "flex", marginRight: "5px" }}>
                                                                    <input type="radio" name="vehicle_coding_system" value="1" onChange={(e) => { setVechileCodingSystem(e.target.value) }} defaultChecked={(VehicleCodingSystem === 1) ? true : null} />
                                                                    <p style={{ margin: 0, marginLeft: "2px" }}>M&amp;M</p>
                                                                </div>

                                                                <div style={{ display: "flex", marginRight: "5px" }}>
                                                                    <input type="radio" name="vehicle_coding_system" value="2" onChange={(e) => { setVechileCodingSystem(e.target.value) }} defaultChecked={(VehicleCodingSystem === 2) ? true : null} />
                                                                    <p style={{ margin: 0, marginLeft: "2px" }}>Glass</p>
                                                                </div>

                                                                <div style={{ display: "flex", marginRight: "5px" }}>
                                                                    <input type="radio" name="vehicle_coding_system" value="3" onChange={(e) => { setVechileCodingSystem(e.target.value) }} defaultChecked={(VehicleCodingSystem === 3) ? true : null} />
                                                                    <p style={{ margin: 0, marginLeft: "2px" }}>Redbook</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="caption">
                                                            <td colSpan="2" style={{ textAlign: 'center' }}>
                                                                <Link to={"/admin/loanhouses_list"} className="btn btn-result-open">
                                                                    <input type="button" name="refer" value="Cancel" />
                                                                </Link>
                                                                &nbsp; &nbsp; &nbsp; &nbsp;
                                                                <input type="submit" name="command" value="Save" />
                                                            </td>
                                                        </tr>
                                                    </tbody></table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );
}

export default Loanhouses