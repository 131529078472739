import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link } from 'react-router-dom';

function Affiliates() {
    document.title = "Administration | Seriti - Group Admin";
    const [affiliates, setAffiliates] = useState([]);
    const [allAffiliates, setAllAffiliates] = useState([]);
    const [affiliate, setAffiliate] = useState('');
    const [command, setCommand] = useState('');
    const [fetch, setFetch] = useState(true);

    //----------------------------------get initial data------------------------------------//
    useEffect(() => {
        if (fetch === true) {
            axios
                .get("/admin/group_admin/affiliates")
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data.data)
                        setFetch(false);
                        setAffiliates(response.data.data);
                        setAllAffiliates(response.data.data.rAffiliate)
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    alert(response.response.data.message);
                });
        }
    }, [fetch]);

    //---------------------------enable, disable and delete---------------------------------//
    const handleEdit = (e) => {
        e.preventDefault();
        // console.log(command)
        // console.log(affiliate)
        const payload = {
            command: command,
            affiliate: affiliate
        };
        axios
            .post("admin/group_admin/enable_disable_delete", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data)
                    setFetch(true);
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                alert(response.response.data.message);
            });

    };

    return (
        <>
            <Admin_menu />

            <form method="post" action="" onSubmit={handleEdit} encType="multipart/form-data" >
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">

                                <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center"><p></p>
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="600">
                                                    <tbody>
                                                        <tr className="caption" >
                                                            <th colSpan={5} style={{ textAlign: 'center' }}>{affiliates.sAffiliateName}s</th>
                                                        </tr>
                                                        <tr className="caption">
                                                            <td style={{ width: 40 }}></td>
                                                            <td style={{ width: 40 }}></td>
                                                            <td>Name</td>
                                                            <td style={{ width: 40 }}></td>
                                                            <td style={{ width: 40 }}></td>
                                                        </tr>
                                                        {
                                                            allAffiliates && allAffiliates.length ? (
                                                                allAffiliates.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <Link to={"/admin/group_admin/group_fsp/" + item.id} className="btn btn-result-open">
                                                                                    <input type='submit' name='command' value={affiliates.sDivisionName} />
                                                                                </Link>
                                                                            </td>
                                                                            <td>
                                                                                <Link to={"/admin/group_admin/affiliates_add"} className="btn btn-result-open" state={{ affiliate_id: item.id }}>
                                                                                    <input type='submit' name='edit' value='Edit' />
                                                                                </Link>
                                                                            </td>

                                                                            <td>{item.name}</td>
                                                                            <td>
                                                                                <input type="submit" name="command" value={item.sButtonDisEnableText} onClick={(e) => { { setCommand(item.sButtonDisEnableText); setAffiliate(item.id) } }} />
                                                                            </td>
                                                                            <td><input type="submit" name="command" value='Delete' style={{ display: item.sDelButton }} onClick={() => {
                                                                                if (window.confirm('Are you sure you want to delete this collection? Click OK to continue.')) {
                                                                                    { { setCommand('Delete'); setAffiliate(item.id) } }
                                                                                }
                                                                            }
                                                                            } />
                                                                            </td>

                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : <tr><td></td></tr>
                                                        }
                                                        <tr>
                                                            <td colSpan={5} style={{ textAlign: 'center' }} className="caption">
                                                                <Link to={"/admin/group_admin/affiliates_add"} className="btn btn-result-open" state={{ affiliate_id: '' }}>
                                                                    <input name="add_collection" type="submit" value="Add Collection" />
                                                                </Link>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr><tr>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );
}

export default Affiliates