import React, { useState, useEffect } from "react";
import VechileAccessories_menu from "./VechileAccessories_menu"
import { Link } from "react-router-dom"
import axios from "../../axios";

function Import() {
    document.title = "Products | Seriti - Vehicle & Accessories";
    const [productTemplate, setProductTemplate] = useState([]);

    //--------------getting product templates-------------------------//
    useEffect(() => {
        axios
            .get("/product_templates")
            .then((response) => {
                // console.log(response.data.data)
                setProductTemplate(response.data.data);
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, []);
    // console.log(productTemplate)

    return (
        <>
            <VechileAccessories_menu />
            <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                <tbody>
                    <tr valign="top" className="bg-white">
                        <td></td>
                        <td width="100%" className="bg-white">
                            <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                            <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">

                                            <table className="defaultTbl" width="500" cellSpacing="1">
                                                <tbody>
                                                    <tr><th colSpan={2} className="text-center">Import Product Data</th></tr>
                                                    <tr className="caption"><td width="90%">Templates</td><td style={{ textAling: "center" }}>&nbsp;</td></tr>
                                                    {
                                                        productTemplate && productTemplate.length ? (
                                                            productTemplate.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{item.name}</td>
                                                                        <td style={{ textAling: "center" }}>
                                                                        <Link to={"/import_mm_codes/"+item.id}>
                                                                            <input type="submit" value="Import Data" />
                                                                        </Link>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        ) : <tr><td colSpan={2}>"There are no templates yet. To import data, first create a template."</td></tr>
                                                    }
                                                    <tr className="caption">
                                                        <td colSpan={2} style={{ textAling: "center" }} className="text-center">
                                                        <input type="button" value="Import Accessories" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <p></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default Import