import { useNavigate } from "react-router-dom"
import React, { useState, useEffect, useRef } from "react";
import Transaction_menu from "../Transaction/Transaction_menu";
import Footer from "../Dashboard/Footer";
import axios from "../axios";

const Un_incept_transaction = () => {
  
  const UninceptRef = useRef();
  const navigate = useNavigate();
  const [UninceptReason, setUninceptReason] = useState("");
  const [UnInceptTranscationDetailsAll, setUnInceptTranscationDetailsAll] = useState([]);
  const transaction_id = localStorage.getItem("transaction_id");
  const user_id = localStorage.getItem("UserId");

  const handleValidation = () => {
    var flag = true;

      if (UninceptReason == "") {
          alert("Please provide a detailed reason to unincept");
          UninceptRef.current.focus();
          flag = false;
      }

    return flag;
  };

const handleSubmit = (e) => {
    e.preventDefault();
    const flag = handleValidation();
    if (flag) {
        const payload = {
            transaction_id: transaction_id,
            user_id: user_id,
            command: "Unincept",
            unincept_reason: UninceptReason,
        };
        //console.log(payload);
          axios
            .post("transcation/un_incept_trasaction", payload)
            .then((response) => {
                //console.log(response); 
                if(response.status == 200){
                    navigate('/transaction/create_transaction/'+transaction_id);
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch(() => {
               console.log('error');
            });
      }
};

useEffect(() => {  
  const payload = {
    transaction_id: transaction_id,
  };

  axios
  .post("transcation/un_incept_trasaction_history",payload)
  .then((response) => {
    //console.log(response); 
      if(response.status === 200){
         setUnInceptTranscationDetailsAll(response.data.data);
      }
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  })
  .catch(() => {
      console.log('error');
  });
}, []);

let panFlag = 1;

  return (
    <>
      <Transaction_menu panel_flag={panFlag}/>
      <div>
        <table width="100%" height="1024" id="mainContainer" border="0" cellPadding={0} cellSpacing={0} className="custom-body">
          <tbody>
            <tr valign="top">
              <td></td>
              <td width="100%">
                <div id="parent_wrapper" className="custom-parent_wrapper">
                </div>
                <table width="100%" border="0" cellPadding={5} cellSpacing={5}>
                  <tbody>
                    <tr>
                      <td valign="top" align="center">
                        <form name="command" method="post" onSubmit={handleSubmit} >
                          <table cellSpacing={1} className="defaultTbl no_caption_color" width="600">
                            <tbody>
                              <tr>
                                <th colSpan={2} className="text-center">Unincept</th>
                              </tr>
                              <tr>
                                <td>Reason</td>
                                <td>
                                    <textarea name="unincept_reason" id="unincept_reason" cols={55} rows={3} value={UninceptReason} onChange = {(e) => setUninceptReason(e.target.value)} className="text-uppercase" ref={UninceptRef}/>
                                </td>
                              </tr>
                              <tr>
                                  <td className="caption" colSpan={2} style={{textAlign: 'center'}} >
                                      <input type="submit" name="command" value="Unincept" />
                                  </td>
                              </tr>
                            </tbody>
                          </table>

                          <br />

                          <table cellSpacing={1} className="defaultTbl no_caption_color" width="600">
                            <tbody>
                              <tr>
                                <th colSpan={4} className="text-center">Transaction Audit Trail</th>
                              </tr>
                              <tr>
                                <td className="caption">Date</td>
                                <td className="caption">Action</td>
                                <td className="caption">User</td>
                                <td className="caption">Reason</td>
                              </tr>
                             
                                { UnInceptTranscationDetailsAll ?
                                    UnInceptTranscationDetailsAll.map((item, index) => {
                                      return (
                                      <tr key={index}>  
                                        <td>{item.date}</td>
                                        <td>{item.action}</td>
                                        <td>{item.user_name}</td>
                                        <td>{item.reason}</td>
                                      </tr>
                                    );
                                }) : null }

                              <tr>
                                <td colSpan={4}>&nbsp;</td>
                              </tr>

                            </tbody>
                          </table>

                        </form>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <Footer />
      </div>
    </>
  );
}

export default Un_incept_transaction;