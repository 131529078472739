import { Link, useNavigate, useParams } from "react-router-dom"
import React, { useState, useEffect, useRef } from "react";
//import { useLocation } from "react-router";
import Transaction_menu from "../Transaction/Transaction_menu";
import Footer from "../Dashboard/Footer";
import DatePicker from "react-datepicker";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import moment from "moment";
import axios from "../axios";
//import { SSRProvider } from "react-bootstrap";
import { NumericFormat } from 'react-number-format';
import Loader from "../Components/Loader"

const Add_to_transaction = () => {
   const url_data = useParams();
   //const TradeCashref = useRef();

   const sellingpriceRef = useRef();

   const navigate = useNavigate();
   //console.log(url_data); // product edit id
   useEffect(() => {
      document.title = 'Transactions | Seriti BW';
   }, []);

   localStorage.setItem("product_hidden_id", url_data.id)
   localStorage.setItem("product_hidden_tp_id", url_data.tp_id)

   if (url_data.tp_id == 0) {
      localStorage.setItem("edit_to_transcation", "prod_edit")
   } else {
      localStorage.setItem("edit_to_transcation", "Edit")
   }

   const [VehicleDetails, setVehicleDetails] = useState([]);

   const transaction_id = localStorage.getItem("transaction_id");
   const id = localStorage.getItem("product_hidden_id");
   const tp_id = localStorage.getItem("product_hidden_tp_id");
   const division_id = localStorage.getItem("division_id");
   const system_type = localStorage.getItem("system_type");
   const user_id = localStorage.getItem("UserId");
   const parent_id = localStorage.getItem("parent_id");
   const editCommand = localStorage.getItem("edit_to_transcation");
   const unincept_flag = localStorage.getItem("unincept_flag");

   //console.log(id);
   const [CostGroup, setCostGroup] = useState("");
   const [VehicleCondition, setVehicleCondition] = useState("");
   const [VehicleUse, setVehicleUse] = useState("");
   const [Immobiliser, setImmobiliser] = useState("");
   const [ManPlanType, setManPlanType] = useState("");
   const [Flagss, setFlagss] = useState(0);

   const [VehicleRetailValue, setVehicleRetailValue] = useState("");
   const [VehicleTradeValue, setVehicleTradeValue] = useState("");
   const [SellingPrice, setSellingPrice] = useState("");
   const [Discount, setDiscount] = useState("");
   const [VehicleCostPrice, setVehicleCostPrice] = useState("");
   const [Deposit, setDeposit] = useState("");
   const [AssetBookValue, setAssetBookValue] = useState("");
   const [RateMargin, setRateMargin] = useState("");
   const [ResidualPercentage, setResidualPercentage] = useState("");
   const [ChassisNumber, setChassisNumber] = useState("");
   const [EngineNumber, setEngineNumber] = useState("");
   const [RegistrationNumber, setRegistrationNumber] = useState("");
   const [OdometerReading, setOdometerReading] = useState("");
   const [Colour, setColour] = useState("");
   const [TradeIn, setTradeIn] = useState(false);
   const [ManufacturerWarranty, setManufacturerWarranty] = useState(false);
   const [ExistingWarranty, setExistingWarranty] = useState(false);
   const [FirstRegistrationDate, setFirstRegistrationDate] = useState("");
   const FinalFirstRegistrationDate = FirstRegistrationDate != '' ? moment(FirstRegistrationDate).format("YYYY-MM-DD") : 0;
   const [dateFlag, setdateFlag] = useState(0);
   const [TradeInCashTransaction, setTradeInCashTransaction] = useState("");
   const [TradeInCurrentInstallment, setTradeInCurrentInstallment] = useState("");
   const [TradeInCarFinanceAccountNumber, setTradeInCarFinanceAccountNumber] = useState("");
   const [TradeInValue, setTradeInValue] = useState("");
   const [TradeInSettlementValue, setTradeInSettlementValue] = useState("");
   const [TradeInCashPayback, setTradeInCashPayback] = useState("");
   const [ManWarrantyExpiryKm, setManWarrantyExpiryKm] = useState("");
   const [ManWarrantyExpiryMonths, setManWarrantyExpiryMonths] = useState("");
   const [ExistingWarrantyExpiryKm, setExistingWarrantyExpiryKm] = useState("");
   const [ExistingWarrantyExpiryDate, setExistingWarrantyExpiryDate] = useState("");

   const [ManPlanExpiryKm, setManPlanExpiryKm] = useState("");
   const [ManPlanExpiryMonths, setManPlanExpiryMonths] = useState("");

   const FinalExistingWarrantyExpiryDate = ExistingWarrantyExpiryDate != '' ? moment(ExistingWarrantyExpiryDate).format("YYYY-MM-DD") : 0;
   const [expiredateFlag, setexpiredateFlag] = useState(0);
   const [planexpiredateFlag, setplanexpiredateFlag] = useState(0);
   const [ExistingManPlanType, setExistingManPlanType] = useState("");
   const [ExistingPlanTypeExpiryDate, setExistingPlanTypeExpiryDate] = useState("");
   const FinalExistingPlanTypeExpiryDate = ExistingPlanTypeExpiryDate != '' ? moment(ExistingPlanTypeExpiryDate).format("YYYY-MM-DD") : 0;
   const [ExistingPlanTypeExpiryKm, setExistingPlanTypeExpiryKm] = useState("");
   const [OldRetailAmount, setOldRetailAmount] = useState("");

   const [NettTradeInDepositValue, setNettTradeInDepositValue] = useState("");
   const [ResidualAmount, setResidualAmount] = useState("");

   const [FinanceCompanyAll, setFinanceCompanyAll] = useState([]);
   const [TradeInFinanceCompany, setTradeInFinanceCompany] = useState("");
   const [ManufacturerApproved, setManufacturerApproved] = useState(false);
   const [FullServiceHistory, setFullServiceHistory] = useState(false);

   const [TradeInRowHideShow, setTradeInRowHideShow] = useState("");
   const [TradeInCashRowHideShow, setTradeInCashRowHideShow] = useState("");

   const [ExistingWarrantyRowHideShow, setExistingWarrantyRowHideShow] = useState("");
   const [ManufacturerWarrantyRowHideShow, setManufacturerWarrantyRowHideShow] = useState("");
   const [VehicleConditionRowHideShow, setVehicleConditionRowHideShow] = useState("");
   const [ExistingPlanRowHideShow, setExistingPlanRowHideShow] = useState("");
   const [ManufacturerPlanRowHideShow, setManufacturerPlanRowHideShow] = useState("none");

   const [OrignalClientNumber, setOrignalClientNumber] = useState("");
   const [iTransactionStatus, setiTransactionStatus] = useState("");
   const [sIsVehicleEdited, setsIsVehicleEdited] = useState("");

   const [HandleSave, setHandleSave] = useState("");
   const [loader, setLoader] = useState(false);
   const [HeaderFlag, setHeaderFlag] = useState(1);
   
   // select another vehicle
   const addnewVehicle = () => {

      const payload = {
         transaction_id: transaction_id,
         tp_id: tp_id,
         user_id: user_id,
      };
     //console.log(payload);
      axios
         .post("transcation/product_transcation_remove", payload)
         .then((response) => {
             // console.log(response);
             if (response.data.status === 200) {
                // window.location.reload(false);
                navigate('/products/search/product_type=vehicles/command=search/module=Transactions/products_product_type/0');
             }
         })
         .catch(() => {
             //console.log('error');
         });
   }

   const handleValidation = () => {
      //console.log(BorrowingGuaranteed);
      var flag = true;
     
      if (isNaN(SellingPrice)) {
         var txt_SellingPrice = SellingPrice.replace(/,(?=.*\.\d+)/g, '');
      } else if (SellingPrice == "") {
         var txt_SellingPrice = "0.00";
      } else {
         var txt_SellingPrice = SellingPrice;
      }

      if (isNaN(Discount)) {
         var txt_Discount = Discount.replace(/,(?=.*\.\d+)/g, '');
      } else if (Discount == "") {
         var txt_Discount = "0.00";
      } else {
         var txt_Discount = Discount;
      }

      if (SellingPrice === "0.00") {
         alert('Please enter selling price');
         sellingpriceRef.current.focus();
         flag = false;
      } else if (FirstRegistrationDate === "") {
         alert('Please select first registration date.');
         flag = false; 
      } else if (parseFloat(txt_SellingPrice) <= parseFloat(txt_Discount)) {
         alert('discount cannot be equal or greater than the selling price');
         flag = false;
      }

      //if existing man plan ticked, chec for expiry months or kilomters.
      if (ExistingWarranty === true) {
         if (ExistingWarrantyExpiryDate === "" && ExistingWarrantyExpiryKm === "") {
            alert('Please enter at least an existing warranty expiry date or an existing warranty expiry milage.');
            return false;
         }
      }

      //if existing man plan ticked, chec for expiry months or kilomters.
      if (ManufacturerWarranty === true) {
         if (ManWarrantyExpiryMonths === "" && ManWarrantyExpiryKm === "") {
            alert('Please enter at least a manufacturer warranty expiry date or a manufacturer warranty expiry milage.');
            return false;
         }
      }

      //validate odo, if used, > 0
      if (VehicleCondition > 1 && OdometerReading === 0) {
         alert("For a used or demo vehicle, please enter an odometer reading, greater than 0.");
         return false;
      }

      return flag;
   };

   const handleSubmit = (e) => {
      //alert('ss');
      e.preventDefault();
      const flag = handleValidation();
      if (flag) {
         setLoader(true);
         const payload = {
            transaction_id: transaction_id,
            system_type: system_type,
            user_id: user_id,
            tp_id: tp_id,
            id: id,
            command: HandleSave,
            amount: SellingPrice,
            vin_number: ChassisNumber,
            chassis_number: ChassisNumber,
            hpi_reference: '',
            registration_number: RegistrationNumber,
            first_reg_date: FinalFirstRegistrationDate,
            odometer_reading: OdometerReading,
            engine_number: EngineNumber,
            man_warranty_expiry_km: ManWarrantyExpiryKm,
            man_warranty_expiry_months: ManWarrantyExpiryMonths,
            existing_warranty: ExistingWarranty,
            existing_warranty_expiry_date: FinalExistingWarrantyExpiryDate,
            existing_warranty_expiry_km: ExistingWarrantyExpiryKm,
            man_plan_expiry_km: ManPlanExpiryKm,
            man_plan_expiry_months: ManPlanExpiryMonths,
            existing_plan_expiry_km: ExistingPlanTypeExpiryKm,
            existing_plan_expiry_date: FinalExistingPlanTypeExpiryDate,
            taxi_con_certificate: 0,
            gear_lock: 0,
            immobiliser: Immobiliser,
            tracking_device: 0,
            cost_group: CostGroup,
            vehicle_use: VehicleUse,
            vehicle_condition: VehicleCondition,
            full_service_history: FullServiceHistory,
            manufacturer_approved: ManufacturerApproved,
            cost_price: VehicleCostPrice,
            retail_value: VehicleRetailValue,
            trade_value: VehicleTradeValue,
            colour: Colour,
            man_plan_type: ManPlanType,
            existing_man_plan_type: ExistingManPlanType,
            old_amount: SellingPrice,
            trade_in: TradeIn,
            man_warranty: ManufacturerWarranty,
            trade_in_cash: TradeInCashTransaction,
            trade_in_current_installment: TradeInCurrentInstallment,
            trade_in_finance_company: TradeInFinanceCompany,
            trade_in_car_account: TradeInCarFinanceAccountNumber,
            trade_in_value: TradeInValue,
            trade_in_settlement_value: TradeInSettlementValue,
            trade_in_cash_payback: TradeInCashPayback,
            asset_book_value: AssetBookValue,
            cash_cheque_eft_deposit: Deposit,
            nett_trade_in_deposit_value: NettTradeInDepositValue,
            rate_margin: RateMargin,
            discount: Discount,
            residual_balloon_percentage: ResidualPercentage,
            residual_balloon_value: ResidualAmount,
            old_retail_amount : OldRetailAmount,
         };

         //console.log(payload);
         axios
            .post("add_to_transaction/add_edit_vehicle_details", payload)
            .then((response) => {
               setLoader(false);
              // console.log(response);
               if (response.status === 200) {
                  if (response.data.data.command === "Save") {
                     navigate('/products/add_to_transaction/command=Edit/module=Transactions/' + id + "/" + response.data.data.tp_id);
                     //window.location.reload(false);
                     // <Transaction_menu panel_flag={1}/>
                     setHeaderFlag(2);
                     toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                     });
                     localStorage.setItem("parent_id", response.data.data.parent_id)
                     
                  } else if (response.data.data.command === "Save and Continue") {
                     localStorage.setItem("parent_id", response.data.data.parent_id)
                     navigate('/products/add_accessories_to_transaction/module=Transactions/' + parent_id);
                  }
               }
               window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch(() => {
               setLoader(false);
               console.log('error');
            });
      }
   };

   useEffect(() => {     
      axios
      .get("finance_application/featch_income_category_finantial_provider")
      .then((response) => {
          if(response.status === 200){
              //console.log(response.data.data);
              setFinanceCompanyAll(response.data.data.finantial_provider);
          }
      })
      .catch(() => {
          console.log('error');
      });
  }, []);

   //console.log(FirstRegistrationDate);
   useEffect(() => {
      setLoader(true);
      const payload = {
         transaction_id: transaction_id,
         tp_id: tp_id,
         id: id,
         command: editCommand,
         accessory: false,
      }
      //console.log(payload);
      axios
         .post("add_to_transaction/featch_vehicle_details", payload)
         .then((response) => {
          //console.log(response.data.data);
            if (response.status) {
               setOrignalClientNumber(response.data.data.orignal_client_number);
               setiTransactionStatus(response.data.data.iTransactionStatus);
               setsIsVehicleEdited(response.data.data.sIsVehicleEdited);

               setVehicleDetails(response.data.data)
               setCostGroup(response.data.data.cost_group)
               setVehicleCondition(response.data.data.vehicle_condition)
               setVehicleUse(response.data.data.vehicle_use)
               setImmobiliser(response.data.data.immobiliser)
               setManPlanType(response.data.data.manufacturer_plan_type)
               setExistingManPlanType(response.data.data.existing_plan_type)
               setVehicleRetailValue(response.data.data.vehicle_retail_value)
               setVehicleTradeValue(response.data.data.vehicle_trade_value)
               setSellingPrice(response.data.data.selling_price)
               setDiscount(response.data.data.discount)
               setVehicleCostPrice(response.data.data.vehicle_cost_price)
               setDeposit(response.data.data.deposit)
               setAssetBookValue(response.data.data.asset_book_value)
               setRateMargin(response.data.data.rate_margin)
               setResidualPercentage(response.data.data.residual_percentage)
               setChassisNumber(response.data.data.chassis_number)
               setEngineNumber(response.data.data.engine_number)
               setRegistrationNumber(response.data.data.registration_number)
               setOdometerReading(response.data.data.odometer_reading)
               setColour(response.data.data.colour)
               setTradeIn(response.data.data.trade_in)
               setManufacturerWarranty(response.data.data.manufacturer_warranty)
               setExistingWarranty(response.data.data.existing_warranty)
               setFirstRegistrationDate(response.data.data.first_registration_date)
               setTradeInCashTransaction(response.data.data.trade_in_cash_transaction)
               setTradeInCurrentInstallment(response.data.data.trade_in_current_installment)
               setTradeInCarFinanceAccountNumber(response.data.data.trade_in_car_account)
               setTradeInValue(response.data.data.trade_in_value)
               setTradeInSettlementValue(response.data.data.trade_in_settlement_value)
               setTradeInCashPayback(response.data.data.trade_in_cash_payback)
               setManWarrantyExpiryKm(response.data.data.manufacturer_warranty_km)
               setManWarrantyExpiryMonths(response.data.data.manufacturer_warranty_months)
               setExistingWarrantyExpiryKm(response.data.data.existing_warranty_expiry_km)
               setExistingWarrantyExpiryDate(response.data.data.existing_warranty_expiry_date)
               setTradeInFinanceCompany(response.data.data.trade_in_finance_company)
               setManufacturerApproved(response.data.data.manufacturer_approved)
               setFullServiceHistory(response.data.data.full_service_history)
               setNettTradeInDepositValue(response.data.data.nett_trade_in_deposit_value)
               setResidualAmount(response.data.data.residual_value)
               setManPlanExpiryKm(response.data.data.manufacturer_plan_km)
               setManPlanExpiryMonths(response.data.data.manufacturer_plan_months)
               //----------
               setExistingPlanTypeExpiryDate(response.data.data.existing_plan_expiry_date)
               setExistingPlanTypeExpiryKm(response.data.data.existing_plan_expiry_km)
               setOldRetailAmount(response.data.data.old_retail_amount)

               if (response.data.data.trade_in === true) {
                  const TradeInRowHideShow = "table-row";
                  setTradeInRowHideShow(TradeInRowHideShow);
               } else if (response.data.data.trade_in === false) {
                  const TradeInRowHideShow = "none";
                  setTradeInRowHideShow(TradeInRowHideShow);
               }

               if (response.data.data.existing_warranty === true) {
                  const ExistingWarrantyRowHideShow = "table-row";
                  setExistingWarrantyRowHideShow(ExistingWarrantyRowHideShow);
               } else if (response.data.data.existing_warranty === false) {
                  const ExistingWarrantyRowHideShow = "none";
                  setExistingWarrantyRowHideShow(ExistingWarrantyRowHideShow);
               }

               if (response.data.data.existing_plan_type === 1 || response.data.data.existing_plan_type === 2) {
                  const ExistingPlanRowHideShow = "table-row";
                  setExistingPlanRowHideShow(ExistingPlanRowHideShow);
               } else {
                  const ExistingPlanRowHideShow = "none";
                  setExistingPlanRowHideShow(ExistingPlanRowHideShow);
               }

               if (response.data.data.manufacturer_warranty === true) {
                  const ManufacturerWarrantyRowHideShow = "table-row";
                  setManufacturerWarrantyRowHideShow(ManufacturerWarrantyRowHideShow);
               } else if (response.data.data.manufacturer_warranty === false) {
                  const ManufacturerWarrantyRowHideShow = "none";
                  setManufacturerWarrantyRowHideShow(ManufacturerWarrantyRowHideShow);
               }

               if (response.data.data.vehicle_condition == 2 || response.data.data.vehicle_condition == 3) {
                  const VehicleConditionRowHideShow = "table-row";
                  setVehicleConditionRowHideShow(VehicleConditionRowHideShow);
               } else if (response.data.data.vehicle_condition == 1) {
                  const VehicleConditionRowHideShow = "none";
                  setVehicleConditionRowHideShow(VehicleConditionRowHideShow);
               }

               if (response.data.data.manufacturer_plan_type == 1 || response.data.data.manufacturer_plan_type == 2) {
                  setManufacturerPlanRowHideShow("table-row");
               } else if (response.data.data.vehicle_condition == 0) {
                  setManufacturerPlanRowHideShow("none")
               }

               if (response.data.data.trade_in_cash_transaction === false) {
                  setFlagss(2);
                  setTradeInCashRowHideShow("table-row");
               } else if (response.data.data.trade_in_cash_transaction === true) {
                  setFlagss(3);
                  setTradeInCashRowHideShow("none");
               }

               if(response.data.data.trade_in === true){
                  setTradeInRowHideShow("table-row");
                  setFlagss(1);
               }else if(response.data.data.trade_in === false){
                  setTradeInRowHideShow("none");
                  setFlagss(1);
               }           
            }
            setLoader(false);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
         })
         .catch(() => {
            console.log('error');
            setLoader(false);
         });
   }, []);


   //-------------- hide /show div
   const TradeInWiseShowRow = (e) => {
      const loc_div_id = e.currentTarget.value;
      //console.log(loc_div_id);
      if (loc_div_id === 'false') {
         const TradeInRowHideShow = "table-row";
         setTradeInRowHideShow(TradeInRowHideShow);
         setFlagss(1);
      } else if (loc_div_id === 'true') {
         setFlagss(1);
         const TradeInRowHideShow = "none";
         setTradeInRowHideShow(TradeInRowHideShow);
      }
   }

   const TradeInCashWiseShowRow = (e) => {
      const loc_div_id1 = e.currentTarget.value;
      // console.log(loc_div_id1);
      if (loc_div_id1 === 'false') {
         //const TradeInCashRowHideShow = "table-row";
         setFlagss(2);
         setTradeInCashRowHideShow("table-row");
      } else if (loc_div_id1 === 'true') {
         // const TradeInCashRowHideShow = "none";
         setFlagss(3);
         setTradeInCashRowHideShow("none");

      }
   }



   const ExistingWarrantyWiseShowRow = (e) => {
      const loc_div_id = e.currentTarget.value;
      //console.log(loc_div_id);
      if (loc_div_id === 'false') {
         const ExistingWarrantyRowHideShow = "table-row";
         setExistingWarrantyRowHideShow(ExistingWarrantyRowHideShow);
      } else if (loc_div_id === 'true') {
         const ExistingWarrantyRowHideShow = "none";
         setExistingWarrantyRowHideShow(ExistingWarrantyRowHideShow);
      }
   }

   const ManufacturerWarrantyWiseShowRow = (e) => {
      const loc_div_id = e.currentTarget.value;
      if (loc_div_id == 'false') {
         const ManufacturerWarrantyRowHideShow = "table-row";
         setManufacturerWarrantyRowHideShow(ManufacturerWarrantyRowHideShow);
      } else if (loc_div_id == 'true') {
         const ManufacturerWarrantyRowHideShow = "none";
         setManufacturerWarrantyRowHideShow(ManufacturerWarrantyRowHideShow);
      }
   }

   const VehicleConditionWiseShowRow = (e) => {
      // alert('sd');
      const loc_div_id = e.currentTarget.value;
      //console.log(loc_div_id);
      if (loc_div_id == 2 || loc_div_id == 3) {
         const VehicleConditionRowHideShow = "table-row";
         setVehicleConditionRowHideShow(VehicleConditionRowHideShow);
      } else if (loc_div_id == 1) {
         const VehicleConditionRowHideShow = "none";
         setVehicleConditionRowHideShow(VehicleConditionRowHideShow);
      }
   }

   const ExistingPlanWiseShowRow = (e) => {
      const loc_div_id = e.currentTarget.value;
      //console.log(loc_div_id);
      if (loc_div_id == 1 || loc_div_id == 2) {
         const ExistingPlanRowHideShow = "table-row";
         setExistingPlanRowHideShow(ExistingPlanRowHideShow);
      } else if (loc_div_id == 0) {
         const ExistingPlanRowHideShow = "none";
         setExistingPlanRowHideShow(ExistingPlanRowHideShow);
      }
   }

   const ManufacturerPlanTypeWiseShowRow = (e) => {
      const loc_div_id = e.currentTarget.value;
      //console.log(loc_div_id);
      if (loc_div_id == 1 || loc_div_id == 2) {
         const ManufacturerPlanRowHideShow = "table-row";
         setManufacturerPlanRowHideShow(ManufacturerPlanRowHideShow);
      } else if (loc_div_id == 0) {
         const ManufacturerPlanRowHideShow = "none";
         setManufacturerPlanRowHideShow(ManufacturerPlanRowHideShow);
      }
   }

   const checkNettTradeInValue = (e) => {
      //alert('SHORTFALL AMOUNT CANNOT BE FINANCED!');
      if (NettTradeInDepositValue < 0) {
         alert('SHORTFALL AMOUNT CANNOT BE FINANCED!');
         // $('#nett_trade_in_deposit_value').focus();
         setNettTradeInDepositValue("0.00");
         return false;
      }
   }

   const ShowZero5 = (e) => {
      // alert('dd');
      if (e === 1 && TradeInValue === '') { setTradeInValue("0.00") }
      if (e === 2 && TradeInSettlementValue === '') { setTradeInSettlementValue("0.00") }
      if (e === 3 && TradeInCashPayback === '') { setTradeInCashPayback("0.00") }
      if (e === 11 && ExistingWarrantyExpiryKm === '') { setExistingWarrantyExpiryKm(0) }
      if (e === 12 && ManWarrantyExpiryKm === '') { setManWarrantyExpiryKm(0) }
      if (e === 13 && ManPlanExpiryKm === '') { setManPlanExpiryKm(0) }
      if (e === 14 && ExistingPlanTypeExpiryKm === '') { setExistingPlanTypeExpiryKm(0) }
      if (e === 16 && ResidualPercentage === '') { setResidualPercentage("0") }

   }


   const RemoveZeroVal1 = (e) => {
      // alert(ManWarrantyExpiryKm);
      if (e === 1 && TradeInValue === '0.00') { setTradeInValue("") }
      if (e === 2 && TradeInSettlementValue === '0.00') { setTradeInSettlementValue("") }
      if (e === 3 && TradeInCashPayback === '0.00') { setTradeInCashPayback("") }
      if (e === 11 && ExistingWarrantyExpiryKm === 0) { setExistingWarrantyExpiryKm("") }
      if (e === 12 && ManWarrantyExpiryKm === 0) { setManWarrantyExpiryKm("") }
      if (e === 13 && ManPlanExpiryKm === 0) { setManPlanExpiryKm("") }
      if (e === 14 && ExistingPlanTypeExpiryKm === 0) { setExistingPlanTypeExpiryKm("") }
      if (e === 16 && ResidualPercentage === '0.00') { setResidualPercentage("") }
   }


   const RemoveZeroValcomm = (e) => {

      if (e === 1 && Discount === '0.00') { setDiscount("") }
      if (e === 2 && AssetBookValue === '0.00') { setAssetBookValue("") }
      if (e === 3 && VehicleRetailValue === '0.00') { setVehicleRetailValue("") }
      if (e === 4 && VehicleTradeValue === '0.00') { setVehicleTradeValue("") }
      if (e === 5 && SellingPrice === '0.00') { setSellingPrice("") }
      if (e === 6 && VehicleCostPrice === '0.00') { setVehicleCostPrice("") }
      if (e === 7 && Deposit === '0.00') { setDeposit("") }
      if (e === 10 && OdometerReading === '0') { setOdometerReading("") }
      if (e === 15 && TradeInCurrentInstallment === '0.00') { setTradeInCurrentInstallment("") }

   }

   const CashPaybackCalulation = (e) => {

      if (isNaN(TradeInValue)) {
         var txt_trade = TradeInValue.replace(/,(?=.*\.\d+)/g, '');
      } else if (TradeInValue == "") {
         var txt_trade = "0.00";
      } else {
         var txt_trade = TradeInValue;
      }

      if (isNaN(TradeInSettlementValue)) {
         var txt_TradeInSettlementValue = TradeInSettlementValue.replace(/,(?=.*\.\d+)/g, '');
      } else if (TradeInSettlementValue == "") {
         var txt_TradeInSettlementValue = "0.00";
      } else {
         var txt_TradeInSettlementValue = TradeInSettlementValue;
      }

      if (isNaN(TradeInCashPayback)) {
         var txt_TradeInCashPayback = TradeInCashPayback.replace(/,(?=.*\.\d+)/g, '');
      } else if (TradeInCashPayback == "") {
         var txt_TradeInCashPayback = "0.00";
      } else {
         var txt_TradeInCashPayback = TradeInCashPayback;
      }
      // setTradeInValue(txt_trade)
      // console.log(TradeInCashPayback);

      const total = parseFloat(txt_trade) - parseFloat(txt_TradeInSettlementValue) - parseFloat(txt_TradeInCashPayback);
      setNettTradeInDepositValue(total.toFixed(2));
      //console.log(total); 
   }

   const ShowZero1 = (e) => {
      //console.log(VehicleRetailValue);
      //alert(VehicleRetailValue); setVehicleRetailValue("0.00")
      if (e === 1 && VehicleRetailValue === '') { setVehicleRetailValue("0.00") }
      if (e === 2 && VehicleTradeValue === '') { setVehicleTradeValue("0.00") }
      if (e === 3 && SellingPrice === '') { setSellingPrice("0.00") }
      if (e === 4 && Discount === '') { setDiscount("0.00") }
      if (e === 5 && VehicleCostPrice === '') { setVehicleCostPrice("0.00") }
      if (e === 6 && Deposit === '') { setDeposit("0.00") }
      if (e === 7 && AssetBookValue === '') { setAssetBookValue("0.00") }
      if (e === 10 && OdometerReading === '') { setOdometerReading("0") }
      if (e === 15 && TradeInCurrentInstallment === '') { setTradeInCurrentInstallment("0.00") }
   }

   const ResidualValueCalulation = (e) => {

      if (isNaN(SellingPrice)) {
         var txt_SellingPrice = SellingPrice.replace(/,(?=.*\.\d+)/g, '');
      } else if (SellingPrice == "") {
         var txt_SellingPrice = "0.00";
      } else {
         var txt_SellingPrice = SellingPrice;
      }

      if (isNaN(Discount)) {
         var txt_Discount = Discount.replace(/,(?=.*\.\d+)/g, '');
      } else if (Discount == "") {
         var txt_Discount = "0.00";
      } else {
         var txt_Discount = Discount;
      }

      const Residualtotal1 = (txt_SellingPrice - txt_Discount) * (ResidualPercentage / 100);
      // let SellingPrice_new = Residualtotal1.toLocaleString('en');
      //let final_selling_amt = (Number(Residualtotal1)).toFixed(2);
      let final_selling_amt = Residualtotal1.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      setResidualAmount(final_selling_amt);
   }
   
   return (
      <>
         <ToastContainer theme="colored" style={{ width: "450px", marginLeft: "-200px" }} />
         <Transaction_menu panel_flag={HeaderFlag}/>
         {loader ? <Loader /> : null}
         <div>
            <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing={0} cellPadding={0} className="custom-body">
               <tbody>
                  <tr valign="top">
                     <td></td>
                     <td width="100%">
                        <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                        <form name="command_form" method="post" onSubmit={handleSubmit} >
                           <table width="100%" border="0" cellSpacing={5} cellPadding={5}>
                              <tbody>
                                 <tr>
                                    <td valign="top" align="center">
                                       <table cellSpacing={1} className="defaultTbl no_caption_color" width="600">
                                          <tbody>
                                             <tr>
                                                <th colSpan={8} style={{ textAlign: 'center' }}>Vehicle Details</th>
                                             </tr>
                                             <tr>
                                                <td className="caption">Make</td>
                                                <td>{VehicleDetails.make}</td>
                                             </tr>
                                             <tr>
                                                <td className="caption">Model/Family</td>
                                                <td>{VehicleDetails.model_family}</td>
                                             </tr>
                                             <tr>
                                                <td className="caption">Description</td>
                                                <td>{VehicleDetails.description}</td>
                                             </tr>
                                             <tr>
                                                <td className="caption">Vehicle Code</td>
                                                <td>{VehicleDetails.vehicle_code}</td>
                                             </tr>

                                             <tr>
                                                <td className="caption">CYLINDERS</td>
                                                <td>{VehicleDetails.cylinders}</td>
                                             </tr>
                                             <tr>
                                                <td className="caption">CUBIC CAPACITY</td>
                                                <td>{VehicleDetails.cubic_capacity}</td>
                                             </tr>
                                             <tr>
                                                <td className="caption">BODY TYPE</td>
                                                <td>{VehicleDetails.body_type}</td>
                                             </tr>
                                             <tr>
                                                <td className="caption"># OF DOORS</td>
                                                <td>{VehicleDetails.of_doors}</td>
                                             </tr>
                                             <tr>
                                                <td className="caption">DATE DISCONTINUED</td>
                                                <td>{VehicleDetails.date_discontinued}</td>
                                             </tr>
                                             <tr>
                                                <td className="caption">DATE INTRODUCED</td>
                                                <td>{VehicleDetails.date_introduced}</td>
                                             </tr>

                                             {url_data.tp_id !== 0 && unincept_flag != 1 ?  // edit condition
                                                <tr>
                                                   <td className="caption">Cost Group</td>
                                                   <td>
                                                      <input type="radio" name="cost_group" value={CostGroup === 1 ? CostGroup : '1'} checked={CostGroup == 1} onChange={(e) => { setCostGroup(e.target.value) }} />&nbsp; 1 &nbsp;&nbsp;
                                                      <input type="radio" name="cost_group" value={CostGroup === 2 ? CostGroup : '2'} checked={CostGroup == 2} onChange={(e) => { setCostGroup(e.target.value) }} />&nbsp; 2
                                                      &nbsp;&nbsp;
                                                      <input type="radio" name="cost_group" value={CostGroup === 3 ? CostGroup : '3'} checked={CostGroup == 3} onChange={(e) => { setCostGroup(e.target.value) }} />&nbsp; 3
                                                   </td>
                                                </tr>
                                                : null}

                                             {unincept_flag == 1 ?
                                                <tr>
                                                   <td className="caption">Cost Group</td>
                                                   <td>
                                                      <input type="radio" name="cost_group" value="1" checked={VehicleDetails.cost_group === 1} onChange={(e) => { setCostGroup(e.target.value) }} disabled={true} />&nbsp; 1 &nbsp;&nbsp;
                                                      <input type="radio" name="cost_group" value="2" checked={VehicleDetails.cost_group === 2} onChange={(e) => { setCostGroup(e.target.value) }} disabled={true} />&nbsp; 2
                                                      &nbsp;&nbsp;
                                                      <input type="radio" name="cost_group" value="3" checked={VehicleDetails.cost_group === 3} onChange={(e) => { setCostGroup(e.target.value) }} disabled={true} />&nbsp; 3
                                                   </td>
                                                </tr>
                                                : null}

                                             {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                <tr>
                                                   <td className="caption">Vehicle Condition</td>
                                                   <td>
                                                      <input type="radio" name="vehicle_condition" value={VehicleCondition === 1 ? VehicleCondition : '1'} checked={VehicleCondition == 1} onChange={(e) => { setVehicleCondition(e.target.value) }} onClick={VehicleConditionWiseShowRow} />&nbsp; New &nbsp;&nbsp;
                                                      <input type="radio" name="vehicle_condition" value={VehicleCondition === 2 ? VehicleCondition : '2'} checked={VehicleCondition == 2} onChange={(e) => { setVehicleCondition(e.target.value) }} onClick={VehicleConditionWiseShowRow} />&nbsp; Used
                                                      &nbsp;&nbsp;
                                                      <input type="radio" name="vehicle_condition" value={VehicleCondition === 3 ? VehicleCondition : '3'} checked={VehicleCondition == 3} onChange={(e) => { setVehicleCondition(e.target.value) }} onClick={VehicleConditionWiseShowRow} />&nbsp; Demo
                                                   </td>
                                                </tr>
                                                : null}

                                             {unincept_flag == 1 ?
                                                <tr>
                                                   <td className="caption">Vehicle Condition</td>
                                                   <td>
                                                      <input type="radio" name="vehicle_condition" value="1" checked={VehicleDetails.vehicle_condition === 1} onChange={(e) => { setVehicleCondition(e.target.value) }} disabled={true} />&nbsp; New &nbsp;&nbsp;
                                                      <input type="radio" name="vehicle_condition" value="2" checked={VehicleDetails.vehicle_condition === 2} onChange={(e) => { setVehicleCondition(e.target.value) }} disabled={true} />&nbsp; Used
                                                      &nbsp;&nbsp;
                                                      <input type="radio" name="vehicle_condition" value="3" checked={VehicleDetails.vehicle_condition === 3} onChange={(e) => { setVehicleCondition(e.target.value) }} disabled={true} />&nbsp; Demo
                                                   </td>
                                                </tr>
                                                : null}

                                             {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                <tr>
                                                   <td className="caption">VEHICLE USE</td>
                                                   <td>
                                                      <input type="radio" name="vehicle_use" value={VehicleUse === 1 ? VehicleUse : '1'} checked={VehicleUse == 1} onChange={(e) => { setVehicleUse(e.target.value) }} />&nbsp; Private &nbsp;&nbsp;
                                                      <input type="radio" name="vehicle_use" value={VehicleUse === 2 ? VehicleUse : '2'} checked={VehicleUse == 2} onChange={(e) => { setVehicleUse(e.target.value) }} />&nbsp; Business
                                                      &nbsp;&nbsp;
                                                      <input type="radio" name="vehicle_use" value={VehicleUse === 3 ? VehicleUse : '3'} checked={VehicleUse == 3} onChange={(e) => { setVehicleUse(e.target.value) }} />&nbsp; Taxi  &nbsp;&nbsp;
                                                      <input type="radio" name="vehicle_use" value={VehicleUse === 6 ? VehicleUse : '6'} checked={VehicleUse == 6} onChange={(e) => { setVehicleUse(e.target.value) }} />&nbsp; HCV
                                                   </td>
                                                </tr>
                                                : null}

                                             {unincept_flag == 1 ?
                                                <tr>
                                                   <td className="caption">VEHICLE USE</td>
                                                   <td>
                                                      <input type="radio" name="vehicle_use" value="1" checked={VehicleDetails.vehicle_use === 1} onChange={(e) => { setVehicleUse(e.target.value) }} disabled={true} />&nbsp; Private &nbsp;&nbsp;
                                                      <input type="radio" name="vehicle_use" value="2" checked={VehicleDetails.vehicle_use === 2} onChange={(e) => { setVehicleUse(e.target.value) }} disabled={true} />&nbsp; Business
                                                      &nbsp;&nbsp;
                                                      <input type="radio" name="vehicle_use" value="3" checked={VehicleDetails.vehicle_use === 3} onChange={(e) => { setVehicleUse(e.target.value) }} disabled={true} />&nbsp; Taxi  &nbsp;&nbsp;
                                                      <input type="radio" name="vehicle_use" value="6" checked={VehicleDetails.vehicle_use === 6} onChange={(e) => { setVehicleUse(e.target.value) }} disabled={true} />&nbsp; HCV
                                                   </td>
                                                </tr>
                                                : null}

                                             {/* vehicle condition wise hide/show */}
                                             {unincept_flag == 1 ?
                                                <tr style={{ display: VehicleConditionRowHideShow }}>
                                                   <td className="caption">Manufacturer Approved Used Vehicle</td>
                                                   {ManufacturerApproved == true ?
                                                     <td>YES</td>
                                                   :
                                                      <td>NO</td> 
                                                   }
                                                </tr>
                                             :
                                                <tr style={{ display: VehicleConditionRowHideShow }}>
                                                   <td className="caption">Manufacturer Approved Used Vehicle</td>
                                                   <td>
                                                      <input type="checkbox" id="manufacturer_approved" name="manufacturer_approved" value={ManufacturerApproved} onChange={(e) => { setManufacturerApproved(!ManufacturerApproved) }} checked={ManufacturerApproved} />
                                                   </td>
                                                </tr>
                                             }

                                             {unincept_flag == 1 ?
                                                <tr style={{ display: VehicleConditionRowHideShow }}>
                                                   <td className="caption">Full Service History</td>
                                                   {FullServiceHistory == true ?
                                                     <td>YES</td>
                                                   :
                                                      <td>NO</td> 
                                                   }
                                                </tr>
                                             :
                                                <tr style={{ display: VehicleConditionRowHideShow }}>
                                                   <td className="caption">Full Service History</td>
                                                   <td>
                                                      <input type="checkbox" id="full_service_history" name="full_service_history" value={FullServiceHistory} onChange={(e) => { setFullServiceHistory(!FullServiceHistory) }} checked={FullServiceHistory} />
                                                   </td>
                                                 </tr>
                                             }
                                            
                                             {/* end */}
                                             <tr>
                                                <td className="caption">Vehicle Retail Value (M&M)</td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'right' }}>

                                                      {VehicleDetails.current_symbol} <NumericFormat type="text" id="retail_value" name="retail_value" value={VehicleRetailValue} onChange={(e) => { setVehicleRetailValue(e.target.value) }} style={{ textAlign: 'right' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} onClick={() => { RemoveZeroValcomm(3); }} onBlur={() => { ShowZero1(1); }} />
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'right' }}>
                                                      {VehicleDetails.current_symbol + " " + VehicleDetails.vehicle_retail_value}
                                                   </td>
                                                   : null}
                                             </tr>
                                             <tr>
                                                <td className="caption">Vehicle Trade Value (M&M)</td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'right' }}>

                                                      {VehicleDetails.current_symbol} <NumericFormat type="text" id="trade_value" name="trade_value" value={VehicleTradeValue} onChange={(e) => { setVehicleTradeValue(e.target.value) }} style={{ textAlign: 'right' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} onClick={() => { RemoveZeroValcomm(4); }} onBlur={() => { ShowZero1(2); }} />
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'right' }}>
                                                      {VehicleDetails.current_symbol + " " + VehicleDetails.vehicle_trade_value}
                                                   </td>
                                                   : null}
                                             </tr>
                                             <tr>
                                                <td className="caption">Selling Price <span style={{ color: 'red' }}>*</span></td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'right' }}>

                                                      {VehicleDetails.current_symbol} <NumericFormat type="text" id="amount" name="amount" value={SellingPrice} onChange={(e) => { setSellingPrice(e.target.value) }} style={{ textAlign: 'right' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} onClick={() => { RemoveZeroValcomm(5); }} onBlur={() => { ResidualValueCalulation(SellingPrice); ShowZero1(3); }} getInputRef={sellingpriceRef} />
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'right' }}>
                                                      {VehicleDetails.current_symbol + " " + VehicleDetails.selling_price}
                                                   </td>
                                                   : null}
                                             </tr>
                                             <tr>
                                                <td className="caption">Discount</td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'right' }}>

                                                      {VehicleDetails.current_symbol} <NumericFormat type="text" id="discount" name="discount" value={Discount} onChange={(e) => { setDiscount(e.target.value) }} style={{ textAlign: 'right' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} onClick={() => { RemoveZeroValcomm(1); }} onBlur={() => { ResidualValueCalulation(SellingPrice); ShowZero1(4); }} />
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'right' }}>
                                                      {VehicleDetails.current_symbol + " " + VehicleDetails.discount}
                                                   </td>
                                                   : null}
                                             </tr>
                                             <tr>
                                                <td className="caption">Vehicle Cost Price</td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'right' }}>

                                                      {VehicleDetails.current_symbol} <NumericFormat type="text" id="cost_price" name="cost_price" value={VehicleCostPrice} onChange={(e) => { setVehicleCostPrice(e.target.value) }} style={{ textAlign: 'right' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} onClick={() => { RemoveZeroValcomm(6); }} onBlur={() => { ShowZero1(5); }} />
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'right' }}>
                                                      {VehicleDetails.current_symbol + " " + VehicleDetails.vehicle_cost_price}
                                                   </td>
                                                   : null}
                                             </tr>
                                             <tr>
                                                <td className="caption">Deposit</td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'right' }}>

                                                      {VehicleDetails.current_symbol} <NumericFormat type="text" id="cash_cheque_eft_deposit" name="cash_cheque_eft_deposit" value={Deposit} onChange={(e) => { setDeposit(e.target.value) }} style={{ textAlign: 'right' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} onClick={() => { RemoveZeroValcomm(7); }} onBlur={() => { ShowZero1(6); }} />
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'right' }}>
                                                      {VehicleDetails.current_symbol + " " + VehicleDetails.deposit}
                                                   </td>
                                                   : null}
                                             </tr>
                                             <tr>
                                                <td className="caption">Asset Book Value</td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'right' }}>

                                                      {VehicleDetails.current_symbol} <NumericFormat type="text" id="asset_book_value" name="asset_book_value" value={AssetBookValue} onChange={(e) => { setAssetBookValue(e.target.value) }} style={{ textAlign: 'right' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} onClick={() => { RemoveZeroValcomm(2); }} onBlur={() => { ShowZero1(7); }} />
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'right' }}>
                                                      {VehicleDetails.current_symbol + " " + VehicleDetails.asset_book_value}
                                                   </td>
                                                   : null}
                                             </tr>
                                             <tr>
                                                <td className="caption">Rate Margin</td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      <input type="text" id="rate_margin" name="rate_margin" value={RateMargin} onChange={(e) => { setRateMargin(e.target.value) }} style={{ textAlign: 'left' }} size="3" /> %
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      % {VehicleDetails.rate_margin}
                                                   </td>
                                                   : null}
                                             </tr>
                                             <tr>
                                                <td className="caption">Residual Percentage</td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      <input type="text" id="residual_balloon_percentage" name="residual_balloon_percentage" value={ResidualPercentage} onChange={(e) => { setResidualPercentage(e.target.value) }} style={{ textAlign: 'left' }} size="3" onBlur={() => { ShowZero5(16); ResidualValueCalulation(ResidualPercentage) }} onClick={() => { RemoveZeroVal1(16); }} /> %
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      % {VehicleDetails.residual_percentage}
                                                   </td>
                                                   : null}
                                             </tr>

                                             <tr>
                                                <td className="caption">Residual Value</td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'right' }}>
                                                      {VehicleDetails.current_symbol} {ResidualAmount}
                                                      <input type="hidden" name="residual_balloon_value" id="residual_balloon_value" value={ResidualAmount} onChange={(e) => { setResidualAmount(e.target.value) }} />
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'right' }}>
                                                      {VehicleDetails.current_symbol + " " + VehicleDetails.residual_value}
                                                   </td>
                                                   : null}
                                             </tr>

                                             <tr>
                                                <td className="caption">Chassis Number</td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      <input type="text" id="chassis_number" name="chassis_number" className="text-uppercase" value={ChassisNumber} onChange={(e) => { setChassisNumber(e.target.value) }} style={{ textAlign: 'left' }} />
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      {VehicleDetails.chassis_number}
                                                   </td>
                                                   : null}
                                             </tr>
                                             <tr>
                                                <td className="caption">Engine Number</td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      <input type="text" id="engine_number" name="engine_number" className="text-uppercase" value={EngineNumber} onChange={(e) => { setEngineNumber(e.target.value) }} style={{ textAlign: 'left' }} />
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      {VehicleDetails.engine_number}
                                                   </td>
                                                   : null}
                                             </tr>
                                             <tr>
                                                <td className="caption">Registration Number</td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      <input type="text" id="registration_number" name="registration_number" className="text-uppercase" value={RegistrationNumber} onChange={(e) => { setRegistrationNumber(e.target.value) }} style={{ textAlign: 'left' }} />
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      {VehicleDetails.registration_number}
                                                   </td>
                                                   : null}
                                             </tr>

                                             {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                <tr>
                                                   <td className="caption">First Registration Date (Year Model) <span style={{ color: 'red' }}>*</span></td>
                                                   {dateFlag === 1 ?
                                                      <td style={{ textAlign: 'left' }}>
                                                         <DatePicker id="birthdate" selected={FirstRegistrationDate === null ? new Date() : FirstRegistrationDate} name="first_reg_date" onChange={(date) => { setFirstRegistrationDate(date); setdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                      </td>
                                                      :
                                                      <td style={{ textAlign: 'left' }}>
                                                         <DatePicker id="birthdate" value={FirstRegistrationDate === null ? new Date() : FirstRegistrationDate} name="first_reg_date" onChange={(date) => { setFirstRegistrationDate(date); setdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                      </td>
                                                   }
                                                </tr>
                                                : null}

                                             {unincept_flag == 1 ?
                                                <tr>
                                                   <td className="caption">First Registration Date (Year Model) <span style={{ color: 'red' }}>*</span></td>
                                                   <td style={{ textAlign: 'left' }}>
                                                      {VehicleDetails.first_registration_date}
                                                   </td>
                                                </tr>
                                                : null}

                                             <tr>
                                                <td className="caption">Odometer Reading</td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      {/* <input type="text" id="odometer_reading" name="odometer_reading" className="text-uppercase" value={OdometerReading} onChange={(e) => { setOdometerReading(e.target.value) }} style={{textAlign: 'left'}} onClick={() => { RemoveZeroValcomm(10);}} onBlur={() => { ShowZero1(10); }}/> KM  */}
                                                      <NumericFormat type="text" id="odometer_reading" name="odometer_reading" value={OdometerReading} onChange={(e) => { setOdometerReading(e.target.value) }} thousandSeparator={true} onClick={() => { RemoveZeroValcomm(10); }} onBlur={() => { ShowZero1(10); }} /> KM
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      KM{VehicleDetails.odometer_reading}
                                                   </td>
                                                   : null}
                                             </tr>
                                             <tr>
                                                <td className="caption">Colour</td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      <input type="text" id="colour" name="colour" className="text-uppercase" value={Colour} onChange={(e) => { setColour(e.target.value) }} style={{ textAlign: 'left' }} />
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      {VehicleDetails.colour}
                                                   </td>
                                                   : null}
                                             </tr>
                                             {/* <tr>
                                     <td className="caption">HPI Reference</td>
                                     <td>2323</td>
                                  </tr>
                                  <tr>
                                     <td className="caption">Taxi Conversion Certificate</td>
                                     <td>2323</td>
                                  </tr> */}

                                             {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                <tr>
                                                   <td className="caption">Immobiliser</td>
                                                   <td>
                                                      <input type="radio" name="immobiliser" value={Immobiliser === 0 ? Immobiliser : '0'} checked={Immobiliser == 0} onChange={(e) => { setImmobiliser(e.target.value) }} />&nbsp; None &nbsp;&nbsp;
                                                      <input type="radio" name="immobiliser" value={Immobiliser === 1 ? Immobiliser : '1'} checked={Immobiliser == 1} onChange={(e) => { setImmobiliser(e.target.value) }} />&nbsp; Factory Fitted
                                                      &nbsp;&nbsp;
                                                      <input type="radio" name="immobiliser" value={Immobiliser === 2 ? Immobiliser : '2'} checked={Immobiliser == 2} onChange={(e) => { setImmobiliser(e.target.value) }} />&nbsp; Aftermarket
                                                   </td>
                                                </tr>
                                                : null}

                                             {unincept_flag == 1 ?
                                                <tr>
                                                   <td className="caption">Immobiliser</td>
                                                   <td>
                                                      <input type="radio" name="immobiliser" value="0" checked={VehicleDetails.immobiliser === 0} onChange={(e) => { setImmobiliser(e.target.value) }} disabled={true} />&nbsp; None &nbsp;&nbsp;
                                                      <input type="radio" name="immobiliser" value="1" checked={VehicleDetails.immobiliser === 1} onChange={(e) => { setImmobiliser(e.target.value) }} disabled={true} />&nbsp; Factory Fitted
                                                      &nbsp;&nbsp;
                                                      <input type="radio" name="immobiliser" value="2" checked={VehicleDetails.immobiliser === 2} onChange={(e) => { setImmobiliser(e.target.value) }} disabled={true} />&nbsp; Aftermarket
                                                   </td>
                                                </tr>
                                                : null}

                                             <tr>
                                                <td className="caption">Trade In</td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      <input type="checkbox" id="trade_in" name="trade_in" value={TradeIn} onChange={(e) => { setTradeIn(!TradeIn) }} checked={TradeIn} onClick={TradeInWiseShowRow} />
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      {VehicleDetails.trade_in === false ? 'No' : 'YES'}
                                                   </td>
                                                   : null}
                                             </tr>
                                             {/* Trade In click */}
                                             <tr style={{ display: TradeInRowHideShow }}>
                                                <td className="caption">Trade In Cash Transaction</td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      <input type="checkbox" id="trade_in_cash" name="trade_in_cash" value={TradeInCashTransaction} onChange={(e) => { setTradeInCashTransaction(!TradeInCashTransaction) }} checked={TradeInCashTransaction} onClick={TradeInCashWiseShowRow} />
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      {VehicleDetails.trade_in_cash_transaction === false ? 'No' : 'YES'}
                                                   </td>
                                                   : null}
                                             </tr>

                                             <tr style={{ display: Flagss == 1 ? TradeInRowHideShow : Flagss == 2 ? "none" : Flagss == 3 ? "table-row" : "none" }}>
                                                <td className="caption">Trade In Current Installment</td>
                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'left' }}>P {TradeInCurrentInstallment}</td>
                                                :
                                                   <td style={{ textAlign: 'left' }}>
                                                      P &nbsp;<NumericFormat type="text" id="trade_in_current_installment" name="trade_in_current_installment" value={TradeInCurrentInstallment} onChange={(e) => { setTradeInCurrentInstallment(e.target.value) }} style={{ textAlign: 'right' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} onClick={() => { RemoveZeroValcomm(15); }} onBlur={() => { ShowZero1(15); }} />
                                                   </td>
                                                }
                                             </tr>
                                             <tr style={{ display: Flagss == 1 ? TradeInRowHideShow : Flagss == 2 ? "none" : Flagss == 3 ? "table-row" : "none" }}>
                                                <td className="caption">Trade In Car Finance Company</td>

                                                {unincept_flag == 1 ?
                                                   <td>
                                                       {VehicleDetails.finance_company_name}
                                                   </td>
                                                : 
                                                   <td>
                                                      <select name="trade_in_finance_company" value={TradeInFinanceCompany} onChange={(e) => setTradeInFinanceCompany(e.target.value)} className="text-uppercase">
                                                         <option value="">-- Select a Finance Institution --</option>
                                                         {FinanceCompanyAll.map((item) => {
                                                            return (
                                                               <option key={item.id} value={item.id}>
                                                                  {item.type}
                                                               </option>
                                                            );
                                                         })}
                                                      </select>
                                                   </td>
                                                }
                                             </tr>
                                             <tr style={{ display: Flagss == 1 ? TradeInRowHideShow : Flagss == 2 ? "none" : Flagss == 3 ? "table-row" : "none" }}>
                                                <td className="caption">Trade In Car Finance Account Number</td>
                                                {unincept_flag == 1 ?
                                                  <td style={{ textAlign: 'left' }}>{TradeInCarFinanceAccountNumber}</td>
                                                :
                                                   <td style={{ textAlign: 'left' }}>
                                                      <input type="text" name="trade_in_car_account" id="trade_in_car_account" value={TradeInCarFinanceAccountNumber} className="text-uppercase" onChange={(e) => { setTradeInCarFinanceAccountNumber(e.target.value) }} />
                                                   </td>
                                                }
                                             </tr>

                                             <tr style={{ display: TradeInRowHideShow }}>
                                                <td className="caption">Trade In Value</td>
                                                {unincept_flag == 1 ?
                                                <td style={{ textAlign: 'right' }}>
                                                   P&nbsp; {TradeInValue}
                                                </td>
                                                :
                                                <td style={{ textAlign: 'right' }}>
                                                   P&nbsp;
                                                   <NumericFormat type="text" id="trade_in_value" name="trade_in_value" value={TradeInValue} onChange={(e) => { setTradeInValue(e.target.value) }} style={{ textAlign: 'right' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} onClick={() => { RemoveZeroVal1(1); }} onBlur={() => { ShowZero5(1); CashPaybackCalulation(TradeInValue) }} />
                                                </td>
                                                }
                                             </tr>

                                             <tr style={{ display: Flagss == 1 ? TradeInRowHideShow : Flagss == 2 ? "none" : Flagss == 3 ? "table-row" : "none" }}>
                                                <td className="caption">Trade In Settlement Value</td>
                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'right' }}>
                                                      P&nbsp; {TradeInSettlementValue}
                                                   </td>
                                                :
                                                   <td style={{ textAlign: 'right' }}>
                                                      P&nbsp;
                                                      <NumericFormat type="text" id="trade_in_settlement_value" name="trade_in_settlement_value" value={TradeInSettlementValue} onChange={(e) => { setTradeInSettlementValue(e.target.value) }} style={{ textAlign: 'right' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} onClick={() => { RemoveZeroVal1(2); }} onBlur={() => { ShowZero5(2); CashPaybackCalulation(TradeInSettlementValue) }} />
                                                   </td>
                                                }
                                             </tr>

                                             <tr style={{ display: TradeInRowHideShow }}>
                                                <td className="caption">Trade In Cash Payback</td>
                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'right' }}>
                                                      P&nbsp; {TradeInCashPayback}
                                                   </td>
                                                :
                                                   <td style={{ textAlign: 'right' }}>
                                                      P&nbsp;
                                                      <NumericFormat type="text" id="trade_in_settlement_value" name="trade_in_settlement_value" value={TradeInCashPayback} onChange={(e) => { setTradeInCashPayback(e.target.value) }} style={{ textAlign: 'right' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} onClick={() => { checkNettTradeInValue(); RemoveZeroVal1(3); }} onBlur={() => { ShowZero5(3); CashPaybackCalulation(TradeInCashPayback) }} />
                                                   </td>
                                                }
                                             </tr>

                                             {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                <tr style={{ display: TradeInRowHideShow }}>
                                                   <td className="caption">Nett Trade In Deposit Value</td>
                                                   <td style={{ textAlign: 'right' }}>
                                                      <input type="hidden" id="nett_trade_in_deposit_value" name="nett_trade_in_deposit_value" value={NettTradeInDepositValue} onChange={(e) => { setNettTradeInDepositValue(e.target.value) }} />
                                                      P {NettTradeInDepositValue}
                                                   </td>
                                                </tr>
                                                : null}

                                             {unincept_flag == 1 ?
                                                <tr style={{ display: TradeInRowHideShow }}>
                                                   <td className="caption">Nett Trade In Deposit Value</td>
                                                   <td style={{ textAlign: 'right' }}>
                                                      P {VehicleDetails.nett_trade_in_deposit_value}
                                                   </td>
                                                </tr>
                                                : null}
                                             {/* end */}

                                             <tr>
                                                <td className="caption">Manufacturer Warranty</td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      <input type="checkbox" id="man_warranty" name="man_warranty" value={ManufacturerWarranty} onChange={(e) => { setManufacturerWarranty(!ManufacturerWarranty) }} checked={ManufacturerWarranty} onClick={ManufacturerWarrantyWiseShowRow} />
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      {VehicleDetails.manufacturer_warranty === false ? 'No' : 'YES'}
                                                   </td>
                                                   : null}
                                             </tr>
                                             {/* Manufacturer Warranty on click */}
                                             <tr style={{ display: ManufacturerWarrantyRowHideShow }}>
                                                <td className="caption">Expiry Months</td>
                                                {unincept_flag == 1 ?
                                                <td style={{ textAlign: 'left' }}>
                                                   <input type="radio" value="72" id="man_warranty_expiry_months" name="man_warranty_expiry_months" checked={ManWarrantyExpiryMonths == 72} onChange={(e) => { setManWarrantyExpiryMonths(e.target.value) }} disabled/>&nbsp;&nbsp;72&nbsp;&nbsp;

                                                   <input type="radio" value="60" id="man_warranty_expiry_months" name="man_warranty_expiry_months" checked={ManWarrantyExpiryMonths == 60} onChange={(e) => { setManWarrantyExpiryMonths(e.target.value) }} disabled/>&nbsp;&nbsp;60&nbsp;&nbsp;

                                                   <input type="radio" value="48" id="man_warranty_expiry_months" name="man_warranty_expiry_months" checked={ManWarrantyExpiryMonths == 48} onChange={(e) => { setManWarrantyExpiryMonths(e.target.value) }} disabled/>&nbsp;&nbsp;48&nbsp;&nbsp;

                                                   <input type="radio" value="36" id="man_warranty_expiry_months" name="man_warranty_expiry_months" checked={ManWarrantyExpiryMonths == 36} onChange={(e) => { setManWarrantyExpiryMonths(e.target.value) }} disabled/>&nbsp;&nbsp;36&nbsp;&nbsp;

                                                   <input type="radio" value="24" id="man_warranty_expiry_months" name="man_warranty_expiry_months" checked={ManWarrantyExpiryMonths == 24} onChange={(e) => { setManWarrantyExpiryMonths(e.target.value) }} disabled/>&nbsp;&nbsp;24&nbsp;&nbsp;

                                                   <input type="radio" value="12" id="man_warranty_expiry_months" name="man_warranty_expiry_months" checked={ManWarrantyExpiryMonths == 12} onChange={(e) => { setManWarrantyExpiryMonths(e.target.value) }} disabled/>&nbsp;&nbsp;12&nbsp;&nbsp;

                                                   <input type="radio" value="6" id="man_warranty_expiry_months" name="man_warranty_expiry_months" checked={ManWarrantyExpiryMonths == 6} onChange={(e) => { setManWarrantyExpiryMonths(e.target.value) }} disabled/>&nbsp;&nbsp;6&nbsp;&nbsp;

                                                   <input type="radio" value="3" id="man_warranty_expiry_months" name="man_warranty_expiry_months" checked={ManWarrantyExpiryMonths == 3} onChange={(e) => { setManWarrantyExpiryMonths(e.target.value) }} disabled/>&nbsp;&nbsp;3&nbsp;&nbsp;
                                                </td>
                                               :
                                               <td style={{ textAlign: 'left' }}>
                                                   <input type="radio" value="72" id="man_warranty_expiry_months" name="man_warranty_expiry_months" checked={ManWarrantyExpiryMonths == 72} onChange={(e) => { setManWarrantyExpiryMonths(e.target.value) }} />&nbsp;&nbsp;72&nbsp;&nbsp;

                                                   <input type="radio" value="60" id="man_warranty_expiry_months" name="man_warranty_expiry_months" checked={ManWarrantyExpiryMonths == 60} onChange={(e) => { setManWarrantyExpiryMonths(e.target.value) }} />&nbsp;&nbsp;60&nbsp;&nbsp;

                                                   <input type="radio" value="48" id="man_warranty_expiry_months" name="man_warranty_expiry_months" checked={ManWarrantyExpiryMonths == 48} onChange={(e) => { setManWarrantyExpiryMonths(e.target.value) }} />&nbsp;&nbsp;48&nbsp;&nbsp;

                                                   <input type="radio" value="36" id="man_warranty_expiry_months" name="man_warranty_expiry_months" checked={ManWarrantyExpiryMonths == 36} onChange={(e) => { setManWarrantyExpiryMonths(e.target.value) }} />&nbsp;&nbsp;36&nbsp;&nbsp;

                                                   <input type="radio" value="24" id="man_warranty_expiry_months" name="man_warranty_expiry_months" checked={ManWarrantyExpiryMonths == 24} onChange={(e) => { setManWarrantyExpiryMonths(e.target.value) }} />&nbsp;&nbsp;24&nbsp;&nbsp;

                                                   <input type="radio" value="12" id="man_warranty_expiry_months" name="man_warranty_expiry_months" checked={ManWarrantyExpiryMonths == 12} onChange={(e) => { setManWarrantyExpiryMonths(e.target.value) }} />&nbsp;&nbsp;12&nbsp;&nbsp;

                                                   <input type="radio" value="6" id="man_warranty_expiry_months" name="man_warranty_expiry_months" checked={ManWarrantyExpiryMonths == 6} onChange={(e) => { setManWarrantyExpiryMonths(e.target.value) }} />&nbsp;&nbsp;6&nbsp;&nbsp;

                                                   <input type="radio" value="3" id="man_warranty_expiry_months" name="man_warranty_expiry_months" checked={ManWarrantyExpiryMonths == 3} onChange={(e) => { setManWarrantyExpiryMonths(e.target.value) }} />&nbsp;&nbsp;3&nbsp;&nbsp;
                                                </td>
                                                }

                                             </tr>
                                             <tr style={{ display: ManufacturerWarrantyRowHideShow }}>
                                                <td className="caption">Expiry Kilometres</td>
                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      {ManWarrantyExpiryKm} KM
                                                   </td>
                                                :
                                                   <td style={{ textAlign: 'left' }}>
                                                      <NumericFormat type="text" id="man_warranty_expiry_km" name="man_warranty_expiry_km" value={ManWarrantyExpiryKm} onChange={(e) => { setManWarrantyExpiryKm(e.target.value) }} thousandSeparator={true} onClick={() => { RemoveZeroVal1(12); }} onBlur={() => { ShowZero5(12); }} />&nbsp;km
                                                   </td>
                                                }
                                             </tr>
                                             {/* end */}

                                             <tr>
                                                <td className="caption">Existing Warranty</td>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      <input type="checkbox" id="existing_warranty" name="existing_warranty" value={ExistingWarranty} onChange={(e) => { setExistingWarranty(!ExistingWarranty) }} checked={ExistingWarranty} onClick={ExistingWarrantyWiseShowRow} />
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      {VehicleDetails.existing_warranty === false ? 'No' : 'YES'}
                                                   </td>
                                                   : null}
                                             </tr>
                                             {/* Existing Warranty on click */}
                                             {unincept_flag == 1 ?
                                             <tr style={{ display: ExistingWarrantyRowHideShow }}>
                                                <td className="caption">Expiry Date</td>
                                                <td style={{ textAlign: 'left' }}> {ExistingWarrantyExpiryDate} </td>
                                             </tr>
                                             :
                                                <tr style={{ display: ExistingWarrantyRowHideShow }}>
                                                   <td className="caption">Expiry Date</td>

                                                   {expiredateFlag === 1 ?
                                                      <td style={{ textAlign: 'left' }}>
                                                         <DatePicker id="existing_warranty_expiry_date" selected={ExistingWarrantyExpiryDate === null ? new Date() : ExistingWarrantyExpiryDate} name="existing_warranty_expiry_date" onChange={(date) => { setExistingWarrantyExpiryDate(date); setexpiredateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                      </td>
                                                      :
                                                      <td style={{ textAlign: 'left' }}>
                                                         <DatePicker id="existing_warranty_expiry_date" value={ExistingWarrantyExpiryDate === null ? new Date() : ExistingWarrantyExpiryDate} name="existing_warranty_expiry_date" onChange={(date) => { setExistingWarrantyExpiryDate(date); setexpiredateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                      </td>
                                                   }
                                                </tr>
                                             }

                                             <tr style={{ display: ExistingWarrantyRowHideShow }}>
                                                <td className="caption">Expiry Kilometres</td>
                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'left' }}>{ExistingWarrantyExpiryKm} KM</td>
                                                :
                                                   <td style={{ textAlign: 'left' }}>
                                                   <NumericFormat type="text" id="existing_warranty_expiry_km" name="existing_warranty_expiry_km" value={ExistingWarrantyExpiryKm} onChange={(e) => { setExistingWarrantyExpiryKm(e.target.value) }} thousandSeparator={true} onClick={() => { RemoveZeroVal1(11); }} onBlur={() => { ShowZero5(11); }} />&nbsp;km
                                                   </td>
                                                }
                                             </tr>
                                             {/* end */}

                                             {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                <tr>
                                                   <td className="caption">Manufacturer Plan Type</td>
                                                   <td>
                                                      <input type="radio" name="man_plan_type" value={ManPlanType === 0 ? ManPlanType : '0'} checked={ManPlanType == 0} onChange={(e) => { setManPlanType(e.target.value) }} onClick={ManufacturerPlanTypeWiseShowRow} />&nbsp; None &nbsp;&nbsp;
                                                      <input type="radio" name="man_plan_type" value={ManPlanType === 1 ? ManPlanType : '1'} checked={ManPlanType == 1} onChange={(e) => { setManPlanType(e.target.value) }} onClick={ManufacturerPlanTypeWiseShowRow} />&nbsp; Service
                                                      &nbsp;&nbsp;
                                                      <input type="radio" name="man_plan_type" value={ManPlanType === 2 ? ManPlanType : '2'} checked={ManPlanType == 2} onChange={(e) => { setManPlanType(e.target.value) }} onClick={ManufacturerPlanTypeWiseShowRow} />&nbsp; Maintenance
                                                   </td>
                                                </tr>
                                                : null}

                                             {unincept_flag == 1 ?
                                                <tr>
                                                   <td className="caption">Manufacturer Plan Type</td>
                                                   <td>
                                                      <input type="radio" name="man_plan_type" value="0" checked={VehicleDetails.manufacturer_plan_type === 0} onChange={(e) => { setManPlanType(e.target.value) }} disabled={true} />&nbsp; None &nbsp;&nbsp;
                                                      <input type="radio" name="man_plan_type" value="1" checked={VehicleDetails.manufacturer_plan_type === 1} onChange={(e) => { setManPlanType(e.target.value) }} disabled={true} />&nbsp; Service
                                                      &nbsp;&nbsp;
                                                      <input type="radio" name="man_plan_type" value="2" checked={VehicleDetails.manufacturer_plan_type === 2} onChange={(e) => { setManPlanType(e.target.value) }} disabled={true} />&nbsp; Maintenance
                                                   </td>
                                                </tr>
                                                : null}

                                             {/* //--------------------------------------- */}
                                             <tr style={{ display: ManufacturerPlanRowHideShow }}>
                                                <td className="caption">Expiry Months</td>
                                                {unincept_flag == 1 ?
                                                <td style={{ textAlign: 'left' }}>
                                                   <input type="radio" value="72" id="man_plan_expiry_months" name="man_plan_expiry_months" checked={ManPlanExpiryMonths == 72} onChange={(e) => { setManPlanExpiryMonths(e.target.value) }} disabled/>&nbsp;&nbsp;72&nbsp;&nbsp;

                                                   <input type="radio" value="60" id="man_plan_expiry_months" name="man_plan_expiry_months" checked={ManPlanExpiryMonths == 60} onChange={(e) => { setManPlanExpiryMonths(e.target.value) }} disabled/>&nbsp;&nbsp;60&nbsp;&nbsp;

                                                   <input type="radio" value="48" id="man_plan_expiry_months" name="man_plan_expiry_months" checked={ManPlanExpiryMonths == 48} onChange={(e) => { setManPlanExpiryMonths(e.target.value) }} disabled/>&nbsp;&nbsp;48&nbsp;&nbsp;

                                                   <input type="radio" value="36" id="man_plan_expiry_months" name="man_plan_expiry_months" checked={ManPlanExpiryMonths == 36} onChange={(e) => { setManPlanExpiryMonths(e.target.value) }} disabled/>&nbsp;&nbsp;36&nbsp;&nbsp;

                                                   <input type="radio" value="24" id="man_plan_expiry_months" name="man_plan_expiry_months" checked={ManPlanExpiryMonths == 24} onChange={(e) => { setManPlanExpiryMonths(e.target.value) }} disabled/>&nbsp;&nbsp;24&nbsp;&nbsp;

                                                   <input type="radio" value="12" id="man_plan_expiry_months" name="man_plan_expiry_months" checked={ManPlanExpiryMonths == 12} onChange={(e) => { setManPlanExpiryMonths(e.target.value) }} disabled/>&nbsp;&nbsp;12&nbsp;&nbsp;

                                                   <input type="radio" value="6" id="man_plan_expiry_months" name="man_plan_expiry_months" checked={ManPlanExpiryMonths == 6} onChange={(e) => { setManPlanExpiryMonths(e.target.value) }} disabled/>&nbsp;&nbsp;6&nbsp;&nbsp;

                                                   <input type="radio" value="3" id="man_plan_expiry_months" name="man_plan_expiry_months" checked={ManPlanExpiryMonths == 3} onChange={(e) => { setManPlanExpiryMonths(e.target.value) }} disabled/>&nbsp;&nbsp;3&nbsp;&nbsp;
                                                </td>
                                                :
                                                <td style={{ textAlign: 'left' }}>
                                                   <input type="radio" value="72" id="man_plan_expiry_months" name="man_plan_expiry_months" checked={ManPlanExpiryMonths == 72} onChange={(e) => { setManPlanExpiryMonths(e.target.value) }} />&nbsp;&nbsp;72&nbsp;&nbsp;

                                                   <input type="radio" value="60" id="man_plan_expiry_months" name="man_plan_expiry_months" checked={ManPlanExpiryMonths == 60} onChange={(e) => { setManPlanExpiryMonths(e.target.value) }} />&nbsp;&nbsp;60&nbsp;&nbsp;

                                                   <input type="radio" value="48" id="man_plan_expiry_months" name="man_plan_expiry_months" checked={ManPlanExpiryMonths == 48} onChange={(e) => { setManPlanExpiryMonths(e.target.value) }} />&nbsp;&nbsp;48&nbsp;&nbsp;

                                                   <input type="radio" value="36" id="man_plan_expiry_months" name="man_plan_expiry_months" checked={ManPlanExpiryMonths == 36} onChange={(e) => { setManPlanExpiryMonths(e.target.value) }} />&nbsp;&nbsp;36&nbsp;&nbsp;

                                                   <input type="radio" value="24" id="man_plan_expiry_months" name="man_plan_expiry_months" checked={ManPlanExpiryMonths == 24} onChange={(e) => { setManPlanExpiryMonths(e.target.value) }} />&nbsp;&nbsp;24&nbsp;&nbsp;

                                                   <input type="radio" value="12" id="man_plan_expiry_months" name="man_plan_expiry_months" checked={ManPlanExpiryMonths == 12} onChange={(e) => { setManPlanExpiryMonths(e.target.value) }} />&nbsp;&nbsp;12&nbsp;&nbsp;

                                                   <input type="radio" value="6" id="man_plan_expiry_months" name="man_plan_expiry_months" checked={ManPlanExpiryMonths == 6} onChange={(e) => { setManPlanExpiryMonths(e.target.value) }} />&nbsp;&nbsp;6&nbsp;&nbsp;

                                                   <input type="radio" value="3" id="man_plan_expiry_months" name="man_plan_expiry_months" checked={ManPlanExpiryMonths == 3} onChange={(e) => { setManPlanExpiryMonths(e.target.value) }} />&nbsp;&nbsp;3&nbsp;&nbsp;
                                                </td>
                                                }
                                             </tr>
                                             <tr style={{ display: ManufacturerPlanRowHideShow }}>
                                                <td className="caption">Expiry Kilometres</td>
                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      {ManPlanExpiryKm} KM
                                                   </td>
                                                :
                                                   <td style={{ textAlign: 'left' }}>
                                                      <NumericFormat type="text" id="man_plan_expiry_km" name="man_plan_expiry_km" value={ManPlanExpiryKm} onChange={(e) => { setManPlanExpiryKm(e.target.value) }} thousandSeparator={true} onClick={() => { RemoveZeroVal1(13); }} onBlur={() => { ShowZero5(13); }} />&nbsp;km
                                                   </td>
                                                }

                                             </tr>
                                             {/* //--------------------------------------- */}

                                             {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                <tr>
                                                   <td className="caption">EXISTING PLAN TYPE</td>
                                                   <td>
                                                      <input type="radio" name="existing_man_plan_type" value={ExistingManPlanType === 0 ? ExistingManPlanType : '0'} checked={ExistingManPlanType == 0} onChange={(e) => { setExistingManPlanType(e.target.value) }} onClick={ExistingPlanWiseShowRow} />&nbsp; None &nbsp;&nbsp;
                                                      <input type="radio" name="existing_man_plan_type" value={ExistingManPlanType == 1 ? ExistingManPlanType : '1'} checked={ExistingManPlanType == 1} onChange={(e) => { setExistingManPlanType(e.target.value) }} onClick={ExistingPlanWiseShowRow} />&nbsp; Service
                                                      &nbsp;&nbsp;
                                                      <input type="radio" name="existing_man_plan_type" value={ExistingManPlanType == 2 ? ExistingManPlanType : '2'} checked={ExistingManPlanType == 2} onChange={(e) => { setExistingManPlanType(e.target.value) }} onClick={ExistingPlanWiseShowRow} />&nbsp; Maintenance
                                                   </td>
                                                </tr>
                                                : null}

                                             {unincept_flag == 1 ?
                                                <tr>
                                                   <td className="caption">EXISTING PLAN TYPE</td>
                                                   <td>
                                                      <input type="radio" name="existing_man_plan_type" value="0" checked={VehicleDetails.existing_plan_type === 0} onChange={(e) => { setExistingManPlanType(e.target.value) }} disabled={true} />&nbsp; None &nbsp;&nbsp;
                                                      <input type="radio" name="existing_man_plan_type" value="1" checked={VehicleDetails.existing_plan_type === 1} onChange={(e) => { setExistingManPlanType(e.target.value) }} disabled={true} />&nbsp; Service
                                                      &nbsp;&nbsp;
                                                      <input type="radio" name="existing_man_plan_type" value="2" checked={VehicleDetails.existing_plan_type === 2} onChange={(e) => { setExistingManPlanType(e.target.value) }} disabled={true} />&nbsp; Maintenance
                                                   </td>
                                                </tr>
                                                : null}

                                             {unincept_flag == 1 ?
                                               <tr style={{ display: ExistingPlanRowHideShow }}>
                                                   <td className="caption">Expiry Date</td>
                                                   <td style={{ textAlign: 'left' }}>{ExistingPlanTypeExpiryDate}</td>
                                               </tr>
                                             :
                                             <tr style={{ display: ExistingPlanRowHideShow }}>
                                                <td className="caption">Expiry Date</td>

                                                {planexpiredateFlag === 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                      <DatePicker id="existing_plan_expiry_date" selected={ExistingPlanTypeExpiryDate === null ? new Date() : ExistingPlanTypeExpiryDate} name="existing_plan_expiry_date" onChange={(date) => { setExistingPlanTypeExpiryDate(date); setplanexpiredateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" />
                                                   </td>
                                                   :
                                                   <td style={{ textAlign: 'left' }}>
                                                      <DatePicker id="existing_plan_expiry_date" value={ExistingPlanTypeExpiryDate === null ? new Date() : ExistingPlanTypeExpiryDate} name="existing_plan_expiry_date" onChange={(date) => { setExistingPlanTypeExpiryDate(date); setplanexpiredateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" />
                                                   </td>
                                                }
                                             </tr>
                                             }

                                             <tr style={{ display: ExistingPlanRowHideShow }}>
                                                <td className="caption">Expiry Kilometres</td>
                                                {unincept_flag == 1 ?
                                                   <td style={{ textAlign: 'left' }}>
                                                     {ExistingPlanTypeExpiryKm} KM
                                                   </td>
                                                : 
                                                   <td style={{ textAlign: 'left' }}>
                                                       <NumericFormat type="text" id="existing_plan_expiry_km" name="existing_plan_expiry_km" value={ExistingPlanTypeExpiryKm} onChange={(e) => { setExistingPlanTypeExpiryKm(e.target.value) }} thousandSeparator={true} onClick={() => { RemoveZeroVal1(14); }} onBlur={() => { ShowZero5(14); }} />&nbsp;km
                                                   </td>
                                                }
                                             </tr>
                                             <tr>
                                                {url_data.tp_id !== 0 && unincept_flag != 1 ?
                                                   <td colSpan={2} className="caption" style={{ textAlign: 'center' }}>
                                                      <input type="submit" name="command" value="Save" onClick={(e) => { setHandleSave(e.target.value) }} />&nbsp;
                                                      <input type="submit" name="command" value="Save and Continue" onClick={(e) => { setHandleSave(e.target.value) }} />&nbsp;

                                                      {
                                                            OrignalClientNumber > 0 && iTransactionStatus != 3 && sIsVehicleEdited == 0 ? 

                                                               <Link to={`#`} onClick={() => { if (window.confirm('All Vehicle Information and selected products will be removed. Please select OK to continue or CANCEL to discard changes ?')) { addnewVehicle() } }}>
                                                                  <input type="submit" name="command" className="hide_button_after_inception" value="Select Another Vehicle" />&nbsp;
                                                               </Link>

                                                      : null} 

                                                      <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                         <input type="button" className="p-relative" value="Cancel" />
                                                      </Link>
                                                   </td>
                                                   : null}

                                                {unincept_flag == 1 ?
                                                   <td colSpan={2} className="caption" style={{ textAlign: 'center' }}>
                                                      <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                         <input type="button" value="View Transaction" name="view" />
                                                      </Link>
                                                   </td>
                                                   : null}
                                             </tr>

                                          </tbody>
                                       </table>
                                    </td>
                                 </tr>

                              </tbody>
                           </table>
                        </form>
                     </td>
                  </tr>
               </tbody>
            </table>
            <Footer />
         </div>
      </>
   );
}

export default Add_to_transaction;