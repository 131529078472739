import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

function Branch_settings_edit() {
    document.title = "Administration | Seriti - Group Admin";
    const navigate = useNavigate();
    const UserId = localStorage.getItem("UserId")
    const system_type = localStorage.getItem("system_type")

    const { affiliate } = useParams()
    const { division } = useParams()
    const { department } = useParams()

    const [tableHeading, setTableHeading] = useState('')
    const [currencySymbol, setCurrencySymbol] = useState('')
    const [logo1, setLogo1] = useState('')
    const [logo2, setLogo2] = useState('')
    const [name, setname] = useState('')
    const [code, setCode] = useState('')
    const [prime_adjustment, setprime_adjustment] = useState('')
    const [electronic_sign, setElectronicSign] = useState(0)

    const [finance_term, setfinance_term] = useState('')
    const [allfinance_term, setallfinance_term] = useState([])

    const [start_date, setstart_date] = useState('')
    const [end_date, setend_date] = useState('')
    const [igf_number, setIgf_number] = useState('')

    const [dealer_principle, setDealer_principle] = useState('')
    const [allDealer_principle, setallDealer_principle] = useState([])

    const [finance_manager, setFinance_manager] = useState('')
    const [allFinance_manager, setallFinance_manager] = useState([])

    const [approved_user, setApproved_user] = useState('')
    const [allApproved_user, setallApproved_user] = useState([])

    const [business_manager, setBusiness_manager] = useState('')
    const [allBusiness_manager, setallBusiness_manager] = useState([])

    const [fsp_rep, setfsp_rep] = useState('')
    const [allfsp_rep, setAllfsp_rep] = useState([])

    const [fsp_complaints, setfsp_complaints] = useState('')
    const [allfsp_complaints, setAllfsp_complaints] = useState([])

    const [fsp_compliance, setfsp_compliance] = useState('')
    const [allfsp_compliance, setAllfsp_compliance] = useState([])

    const [indemnity, setIndemnity] = useState()
    const [fidelity, setFidelity] = useState()
    const [vehicle_coding_system, setvehicle_coding_system] = useState('')
    const [seriti_fee, setSeriti_fee] = useState('')

    const [dispute_facilitator, setDispute_facilitator] = useState('')

    const [broker, setBroker] = useState('')
    const [allBroker, setAllBroker] = useState([])
    const [broker_check, setBroker_check] = useState('')
    const [isShown, setIsShown] = useState(false);
    
    const [StartDateFlag, setStartDateFlag] = useState(0)
    const [EndDateFlag, setEndDateFlag] = useState(0)


    useEffect(() => {
        const payload = {
            division_id: division,
            department_id: department,
            system_type: system_type
        };
        axios
            .post("/admin/group_admin/branch_settings_edit", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    var obj = response.data.data.edit_branch
                    // console.log(obj)
                    setTableHeading(obj.tableHeading)
                    setLogo1(obj.logo);
                    setprime_adjustment(obj.prime_adjustment)
                    setfsp_rep(obj.fsp_rep);
                    setfinance_term(obj.finance_term);
                    setstart_date(obj.start_date);
                    if (obj.start_date !== "") setStartDateFlag(1)
                    setend_date(obj.end_date);
                    if (obj.end_date !== "") setEndDateFlag(1)
                    setSeriti_fee(obj.seriti_fee);
                    setvehicle_coding_system(obj.vehicle_coding_system);
                    setname(obj.name);
                    setCode(obj.code);
                    if (obj.indemnity === 1) setIndemnity(true)
                    if (obj.fidelity === 1) setFidelity(true)
                    setDispute_facilitator(obj.dispute_facilitator)
                    setDealer_principle(obj.dealer_principle)
                    setFinance_manager(obj.financial_manager)
                    setApproved_user(obj.approved_user)
                    setBusiness_manager(obj.marketer)
                    setIgf_number(obj.igf_number)
                    setallApproved_user(obj.sApprovedUser)
                    setallFinance_manager(obj.sFinancialManager)
                    setallDealer_principle(obj.sDealerPrinciple)
                    setallBusiness_manager(obj.sMarketer)
                    setCurrencySymbol(obj.currencySymbol)
                    setElectronicSign(obj.electronic_sign)
                    setAllBroker(obj.aBrokerSelect)
                    setBroker_check(obj.checked)
                    if ("checked" in obj && obj.checked !== '') setIsShown(true)
                }
            })
            .catch((response) => {
                alert(response.response.data.message);
            });
    }, [division,department,system_type]);

    useEffect(() => {
        axios
            .get("/admin/group_admin/get_dropdown")
            .then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data.data);
                    setallfinance_term(response.data.data.finance_term)
                    setAllfsp_rep(response.data.data.fsp_name_dropdown)
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                alert(response.response.data.message);
            });
    }, []);

    useEffect(() => {
        const payload = {
            fsp_rep: fsp_rep
        };
        if (fsp_rep != 0) {
            // alert(fsp_rep)
            axios
                .post("/admin/group_admin/get_dropdown_fsp", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data.data);
                        setAllfsp_complaints(response.data.data.sFSPComplaintsSelect);
                        setAllfsp_compliance(response.data.data.sFSPComplianceSelect);
                    }
                })
                .catch((response) => {
                    alert(response.response.data.message);
                });
        }
    }, [fsp_rep]);

    const handleIndemnity = (e) => {
        setIndemnity(!indemnity)
    };
    
    const handleFidelity = (e) => {
        setFidelity(!fidelity)
    };

    const handleValidation = () => {
        var flag = true;
        if (code === '' || name === '') {
            alert('Please fill in all the fields!');
            flag = false;
        } else if (seriti_fee !== 0) {
            if (!(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/.test(seriti_fee))) {
                alert('Please enter a valid value for transaction fee');
                flag = false;
            }
        } else if (prime_adjustment !== '') {
            if (!(/^[0-9\b]+$/.test(prime_adjustment))) {
                alert('Please enter a valid value for prime adjustment');
                flag = false;
            }
        } 
        if (end_date !== '') {
            let date1;
            if (start_date === '')      //this is for add condition
                date1 = new Date().getTime(); //getting today date
            else                       //this is for edit conditin
                date1 = new Date(start_date).getTime();

            let date2 = new Date(end_date).getTime();
            if (date1 > date2) {
                alert('Start date is greater than End date. Please enter Valid End date');
                setend_date('');
                flag = false;
            }
        }
        if (logo2) {
            if (!logo2.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                alert('select valid image.');
                flag = false;
            }
        }
        return flag;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        if (flag) {
            var bodyFormData = new FormData();

            bodyFormData.append("user_id", UserId);
            bodyFormData.append("affiliate_id", affiliate);
            bodyFormData.append("department_id", department);
            bodyFormData.append("division_id", division);
            bodyFormData.append("system_type", system_type);

            bodyFormData.append("logo", logo2);
            bodyFormData.append("code", code);
            bodyFormData.append("name", name);
            bodyFormData.append("prime_adjustment", prime_adjustment);
            bodyFormData.append("finance_term", finance_term);
            bodyFormData.append("significant", electronic_sign);
            bodyFormData.append("start_date", start_date !== '' ? moment(start_date).format("YYYY-MM-DD") : 0);
            bodyFormData.append("end_date",  end_date !== '' ? moment(end_date).format("YYYY-MM-DD") : 0);
            bodyFormData.append("igf_number", igf_number);
            bodyFormData.append("dealer_principle", dealer_principle);
            bodyFormData.append("financial_manager", finance_manager);
            bodyFormData.append("approver_user", approved_user);
            bodyFormData.append("marketer", business_manager);
            bodyFormData.append("dispute_facilitator", dispute_facilitator);
            bodyFormData.append("fsp_rep", fsp_rep);
            bodyFormData.append("fsp_complaints", fsp_complaints);
            bodyFormData.append("fsp_compliance", fsp_compliance);
            bodyFormData.append("indemnity", indemnity);
            bodyFormData.append("fidelity", fidelity);
            bodyFormData.append("vehicle_coding_system", vehicle_coding_system);
            bodyFormData.append("broker_id", broker);
            bodyFormData.append("fee", seriti_fee);

            axios
                .post("/branch/add", bodyFormData)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data.data)
                        navigate('/admin/group_admin/branch_defaults/' + division + '/' + department);
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    if(response.response.data.status === 400)
                        alert(response.response.data.data);
                });
        }
    };

    return (
        <>
            <Admin_menu />

            <form method="post" onSubmit={handleSubmit} encType="multipart/form-data" validation="1">
                <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body">
                    <tbody>
                        <tr valign="top">
                            <td width="100%">
                                <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                                <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center">
                                                <table className="defaultTbl" width="550" cellSpacing="1">
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan="2" className="text-center">{tableHeading}</th>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Display Logo</td>
                                                            <td><img height="92" src={logo1} width="290" alt="no_image"/><br /><input type="file" name="logo" onChange={(e) => setLogo2(e.target.files[0])} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">name<span style={{ color: "red" }}> *</span></td>
                                                            <td><input validate="text" type="text" name="name" value={name} onChange={(e) => setname(e.target.value)} className="text-uppercase" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">code<span style={{ color: "red" }}> *</span></td>
                                                            <td><input validate="text" type="text" name="code" value={code} onChange={(e) => setCode(e.target.value)} className="text-uppercase" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Default prime adjustment</td>
                                                            <td><input type="text" name="prime_adjustment" value={prime_adjustment} className="text-uppercase" onChange={(e) => { setprime_adjustment(e.target.value) }} />%</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Default financial term</td>
                                                            <td>
                                                                <select name="finance_term" className="text-uppercase" value={finance_term} onChange={(e) => { setfinance_term(e.target.value) }}>
                                                                    <option value="">- - Select finance_term - -</option>
                                                                    {allfinance_term.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.value} >
                                                                                {item.value}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">USE ELECTONIC SIGNATURE</td>
                                                            <td><input type="radio" name="electronic_sign" value="0" onChange={(e) => setElectronicSign(e.target.value)} defaultChecked={ (electronic_sign === 0) ? true : null} />
                                                                No
                                                                <input type="radio" name="electronic_sign" value="1" onChange={(e) => setElectronicSign(e.target.value)} defaultChecked={(electronic_sign === 1) ? true : null} />
                                                                Yes</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Start date</td>
                                                            <td>
                                                                {StartDateFlag === 1 ?
                                                                    <DatePicker id="start_date" name="start_date" value={start_date} onChange={(date) => { setstart_date(date); setStartDateFlag(0) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                    :
                                                                    <DatePicker id="start_date" name="start_date" selected={start_date} onChange={(date) => setstart_date(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">End date</td>
                                                            <td>
                                                                {EndDateFlag === 1 ?
                                                                    <DatePicker id="end_date" name="end_date" value={end_date} onChange={(date) => {setend_date(date);setEndDateFlag(0)}} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                    :
                                                                    <DatePicker id="end_date" name="end_date" selected={end_date} onChange={(date) => setend_date(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                }
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">IGF NUMBER</td>
                                                            <td><input type="text" name="igf_number" className="date hasDatepicker text-uppercase" value={igf_number} id="igf_number" onChange={(e) => setIgf_number(e.target.value)} /></td>
                                                        </tr>

                                                        {/*  */}
                                                        <tr>
                                                            <td className="caption">BRANCH MANAGER	</td>
                                                            <td>
                                                                <select name="dealer_principle" value={dealer_principle} onChange={(e) => { setDealer_principle(e.target.value) }} className="text-uppercase">
                                                                    <option value="">-- Select Option --</option>
                                                                    {allDealer_principle.map((item) => {
                                                                        return (
                                                                            <option key={item.value} value={item.value}>
                                                                                {item.text}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">FINANCIAL MANAGER</td>
                                                            <td>
                                                                <select name="finance_manager" value={finance_manager} onChange={(e) => { setFinance_manager(e.target.value) }} className="text-uppercase">
                                                                    <option value="">-- Select Option --</option>
                                                                    {allFinance_manager.map((item) => {
                                                                        return (
                                                                            <option key={item.value} value={item.value}>
                                                                                {item.text}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">APPROVED USER</td>
                                                            <td>
                                                                <select name="approved_user" value={approved_user} onChange={(e) => { setApproved_user(e.target.value) }} className="text-uppercase">
                                                                    <option value="">-- Select Option --</option>
                                                                    {allApproved_user.map((item) => {
                                                                        return (
                                                                            <option key={item.value} value={item.value}>
                                                                                {item.text}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">BUSINESS MANAGER/MARKETER</td>
                                                            <td>
                                                                <select name="business_manager" value={business_manager} onChange={(e) => { setBusiness_manager(e.target.value) }} className="text-uppercase">
                                                                    <option value="">-- Select Option --</option>
                                                                    {allBusiness_manager.map((item) => {
                                                                        return (
                                                                            <option key={item.value} value={item.value}>
                                                                                {item.text}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">DISPUTE FACILITATOR</td>
                                                            <td><input type="hidden" name="dispute_facilitator" value={dispute_facilitator} id="dispute_facilitator" onChange={(e) => setDispute_facilitator(e.target.value)} /><em>Not Currently Available</em></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">FSP Represantative</td>
                                                            <td>
                                                                <select name="fsp_rep" className="text-uppercase" value={fsp_rep} onChange={(e) => setfsp_rep(e.target.value)}>
                                                                    <option value="0">-- Please select an FSP Representative --</option>
                                                                    {allfsp_rep.map((item) => {
                                                                        return (
                                                                            <option key={item.value} value={item.value}>
                                                                                {item.text}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">FSP Complaints Officer</td>
                                                            <td>
                                                                <select name="fsp_complaints" className="text-uppercase" value={fsp_complaints} onChange={(e) => setfsp_complaints(e.target.value)}>
                                                                    <option value="0">-- Please select an FSP Complaints Officer --</option>
                                                                    {allfsp_complaints.map((item) => {
                                                                        return (
                                                                            <option key={item.value} value={item.value}>
                                                                                {item.text}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">FSP Compliance Officer</td>
                                                            <td>
                                                                <select name="fsp_compliance" className="text-uppercase" value={fsp_compliance} onChange={(e) => setfsp_compliance(e.target.value)}>
                                                                    <option value="0">-- Please select an FSP Compliance Officer --</option>
                                                                    {allfsp_compliance.map((item) => {
                                                                        return (
                                                                            <option key={item.value} value={item.value}>
                                                                                {item.text}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption">PROFESSIONAL INDEMNITY?	</td>
                                                            <td><input type="checkbox" name="indemnity" value={indemnity} onChange={handleIndemnity} checked={indemnity} /></td>

                                                        </tr>

                                                        <tr>
                                                            <td className="caption">FIDELITY GURANTEE?</td>
                                                            <td><input type="checkbox" name="fidelity" value={fidelity} onChange={handleFidelity} checked={fidelity} /></td>
                                                        </tr>
                                                        <tr id="vehicle_coding_system">
                                                            <td className="caption">Default Vehicle Coding System</td>
                                                            <td>
                                                                <input type="radio" name="vehicle_coding_system" value="1" checked={(1 === vehicle_coding_system) ? true : null} onChange={(e) => setvehicle_coding_system(e.target.value)} />
                                                                M&amp;M
                                                                <input type="radio" name="vehicle_coding_system" value="2" checked={(2 === vehicle_coding_system) ? true : null} onChange={(e) => setvehicle_coding_system(e.target.value)} />
                                                                Glass
                                                                <input type="radio" name="vehicle_coding_system" value="3" checked={(3 === vehicle_coding_system) ? true : null} onChange={(e) => setvehicle_coding_system(e.target.value)} /> Redbook
                                                            </td>
                                                        </tr>

                                                        {
                                                            isShown && (
                                                                <tr>
                                                                    <td className="caption">Scheme</td>
                                                                    <td>
                                                                        <input type="radio" name="broker_id" value="1" checked={(1 === broker_check) ? true : null} onChange={(e) => setBroker(e.target.value)} />Independent
                                                                        <input type="radio" name="broker_id" value="2" checked={(2 === broker_check) ? true : null} onChange={(e) => setBroker(e.target.value)} />Wesbank/Dealer
                                                                        <input type="radio" name="broker_id" value="3" checked={(3 === broker_check) ? true : null} onChange={(e) => setBroker(e.target.value)} />Wesbank Only
                                                                        <input type="radio" name="broker_id" value="4" checked={(4 === broker_check) ? true : null} onChange={(e) => setBroker(e.target.value)} />Barclays Only
                                                                        <input type="radio" name="broker_id" value="5" checked={(5 === broker_check) ? true : null} onChange={(e) => setBroker(e.target.value)} />Stanbic Only
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }


                                                        <tr><td className="caption">Seriti Fee</td>
                                                            <td style={{ textAlign: "right" }}>{currencySymbol} <input type="text" name="fee" value={seriti_fee} className="currency_amount" onChange={(e) => setSeriti_fee(e.target.value)} /></td></tr>

                                                        <tr>
                                                            <td className="caption" colSpan={2}>
                                                                <Link to={'/admin/group_admin/branch_defaults/' + division + '/' + department}>
                                                                    <input type="button" name="command" value="Back" className="float-left" />
                                                                </Link>
                                                                <input type="submit" name="command" value="Save" className="float-right" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );
}

export default Branch_settings_edit