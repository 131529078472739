import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link } from 'react-router-dom';

function Setup_prime_interest() {
    document.title = "Administration | Seriti - Setup";
    const [coutries, setCountries] = useState([]);
    const [command, setCommand] = useState('');
    const [id, setId] = useState('');
    const [flag, setflag] = useState(true);

    useEffect(() => {
        if (flag) {
            axios
                .get("/admin/setup")
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data.data)
                        setCountries(response.data.data.rResults);
                        setflag(false)
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    // alert(response.response.data.message);
                });
        }
    }, [flag]);

    // console.log(id)
    //--------------------------set value of form in array----------------------------------//
    const handleEdit = (e) => {
        e.preventDefault();
        // console.log(button);
        const payload = {
            command: command,
            country_id: id
        };
        axios
            .post("/admin/setup/update", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data);
                    setflag(true)
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {

            });
    };

    return (
        <>
            <Admin_menu />
            <form method="post" action="" encType="multipart/form-data" onSubmit={handleEdit}>
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">

                                <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center"><p></p>
                                                <table className="defaultTbl" cellSpacing={1} align="center">
                                                    <tbody>
                                                        <tr><th colSpan={6} className="text-center">Country Defaults Already Set Up</th></tr>
                                                        <tr className="caption">
                                                            <td width="1">&nbsp;</td>
                                                            <td width="20%">Country</td>
                                                            <td style={{ textAlign: "right", width: "130px" }}>VAT</td>
                                                            <td style={{ textAlign: "right", width: "130px" }}>Prime Interest Rate</td>
                                                            <td style={{ textAlign: "center" }}>Code</td>
                                                            {/* <td style={{ textAlign: "right" }}>&nbsp;</td> */}
                                                        </tr>
                                                        {
                                                            coutries && coutries.length ? (
                                                                coutries.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td width="1">
                                                                                <Link to={"/admin/edit_setup/" + item.id} className="btn btn-result-open">
                                                                                    <input type="submit" value="Edit Country Defaults" name="command" />
                                                                                </Link>
                                                                            </td>
                                                                            <td width="20%">{item.country_name}</td>
                                                                            <td style={{ textAlign: "right", width: "130px" }}>{item.vat}</td>
                                                                            <td style={{ textAlign: "right", width: "130px" }}>{item.interest_rate}</td>
                                                                            <td style={{ textAlign: "center" }}>{item.country_code}</td>
                                                                            {/* <td style={{ textAlign: "right" }}><input type="submit" name="command" value={item.sDisableText + " Country Defaults"} onClick={(e) => { { setCommand(e.target.value); setId(item.id) } }} /></td> */}
                                                                        </tr>
                                                                    )
                                                                })
                                                            ) : <tr><td colSpan={6}>No records</td></tr>
                                                        }
                                                        <tr>
                                                            <td colSpan="7" className="caption" style={{ textAlign: 'center' }}>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr><tr>
                                            <td>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    )
}

export default Setup_prime_interest

