import { useNavigate } from "react-router-dom"
import React, { useState, useEffect } from "react";
//import { useLocation } from "react-router";
import Transaction_menu from "../Transaction/Transaction_menu";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import Footer from "../Dashboard/Footer";
import axios from "../axios";
import Loader from "../Components/Loader";

const Record_of_advice = () => {
   // const url_data = useParams();
   const navigate = useNavigate();
   const transaction_id = localStorage.getItem("transaction_id");
   const system_type = localStorage.getItem("system_type");
   const user_id = localStorage.getItem("UserId");
   const instance_id = localStorage.getItem("instance_id");
   
   const [ClientProduct, setClientProduct] = useState(0);
   const [ClientProductAll, setClientProductAll] = useState([]);

   const [ConsentsDetails, setConsentsDetails] = useState([]);
   const [ProductAcceptList, setProductAcceptList] = useState([]);

   const [ShareCompanyDetails, setShareCompanyDetails] = useState("");
   const [ShareAmongstPartners, setShareAmongstPartners] = useState("");
   const [ReceiveMarketingInfo, setReceiveMarketingInfo] = useState("");

   const [FinStatus, setFinStatus] = useState("");
   const [ProductExperience, setProductExperience] = useState("");
   const [ClientObjective, setClientObjective] = useState("");
   const [ProductFeedback, setProductFeedback] = useState("");

   const [ConsentsSave, setConsentsSave] = useState("");
   const [RowCount, setRowCount] = useState("");

   const [ClientFinancialResult, setClientFinancialResult] = useState([]);
   const [ProductExperienceResult, setProductExperienceResult] = useState([]);
   const [ClientObjectivesResult, setClientObjectivesResult] = useState([]);
   const [ClientProductResult, setClientProductResult] = useState([]);

   const [AllPdfDetails, setAllPdfDetails] = useState("");

   const [SaveFlag, setSaveFlag] = useState(true);
   const [pdfUpdateFlag, setpdfUpdateFlag] = useState(true);

   const [loader, setLoader] = useState(false);

   //---------------- add/edit Consents data ------------------------
   const handleAddEditConsents = (e) => {
      e.preventDefault();
      setLoader(true);
      const payload = {
         transaction_id: transaction_id,
         user_id: user_id,
         instance_id: instance_id,
         command: ConsentsSave,
         fin_status: FinStatus,
         product_experience: ProductExperience,
         client_objective: ClientObjective,
         product_feedback: ProductFeedback,
         feedback_product_id: ClientProduct,
         share_company_details: ShareCompanyDetails,
         share_amongst_partners: ShareAmongstPartners,
         receive_marketing_info: ReceiveMarketingInfo,
      };
      //console.log(payload); 
      axios
         .post("record_of_advice/add_edit_consents", payload)
         .then((response) => {
            setLoader(false);
           //  console.log(response); 
            if (response.data.data.command === "Save") {
               setSaveFlag(true);
               setFinStatus("");
               setProductExperience("");
               setClientObjective("");
               setProductFeedback("");
               toast.success(response.data.message, {
                  position: toast.POSITION.TOP_CENTER
               });
               
            } else if (response.data.data.command === "Save and Continue") {
                navigate('/transaction/add_person');
            }
            setpdfUpdateFlag(true);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
         })
         .catch((response) => {
            setLoader(false);
            console.log('error');
         });
   };

   useEffect(() => {
      document.title = 'Transactions | Seriti BW';
   }, []);

   //----------- get client product dropdown -----------------
   useEffect(() => {
      
         const payload = {
            transaction_id: transaction_id,
            system_type: system_type,
         };
         //console.log(payload); 
         axios
            .post("record_of_advice/featch_client_product_dropdown", payload)
            .then((response) => {
               //console.log(response); 
               if (response.data.status === 200) {
                  setClientProductAll(response.data.data.dropdown_list);
                  setProductAcceptList(response.data.data.product_accept_result);
                  setRowCount(response.data.data.row_count);
                 
               }
            })
            .catch(() => {
               console.log('error');
            });
     
   }, []);

   useEffect(() => {
      const payload = {
         transaction_id: transaction_id,
         system_type: system_type,
         user_id: user_id,
      };
      //console.log(payload); 
      axios
         .post("record_of_advice/featch_roa_pdf_details", payload)
         .then((response) => {
            //console.log(response); 
            setAllPdfDetails(response.data.data);
            setpdfUpdateFlag(false);
         })
         .catch(() => {
            console.log('error');
         });
   }, [pdfUpdateFlag]);

   //----------- get Consents radio -----------------
   useEffect(() => {
      const payload = {
         transaction_id: transaction_id,
      };
      //console.log(payload); 
      axios
         .post("record_of_advice/featch_consents_redio_button", payload)
         .then((response) => {
            //console.log(response); 
            if (response.data.status === 200) {
               setConsentsDetails(response.data.data);

               if (response.data.data.share_company_offered === 'checked') {
                  setShareCompanyDetails('share_company_details_yes');
               } else if (response.data.data.share_company_declain === 'checked') {
                  setShareCompanyDetails('share_company_details_no');
               } else {
                  setShareCompanyDetails('share_company_details_no');
               }

               if (response.data.data.share_amongst_offered === 'checked') {
                  setShareAmongstPartners('share_amongst_partners_yes');
               } else if (response.data.data.share_amongst_declain === 'checked') {
                  setShareAmongstPartners('share_amongst_partners_no');
               } else {
                  setShareAmongstPartners('share_amongst_partners_no');
               }

               if (response.data.data.receive_marketing_offered === 'checked') {
                  setReceiveMarketingInfo('receive_marketing_info_yes');
               } else if (response.data.data.receive_marketing_declain === 'checked') {
                  setReceiveMarketingInfo('receive_marketing_info_no');
               } else {
                  setReceiveMarketingInfo('receive_marketing_info_no');
               }
            }
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
         })
         .catch(() => {
            console.log('error');
         });
   }, []);

   //----------- featch Consents list data -----------------
   useEffect(() => {
      if (SaveFlag) {
      setLoader(true);
      const payload = {
         transaction_id: transaction_id,
      };
      //console.log(payload); 
      axios
         .post("record_of_advice/featch_all_consents", payload)
         .then((response) => {
            //console.log(response); 
            if (response.data.status === 200) {
               setClientFinancialResult(response.data.data.client_financial_result);
               setProductExperienceResult(response.data.data.product_experience_result);
               setClientObjectivesResult(response.data.data.client_objectives_result);
               setClientProductResult(response.data.data.client_product_result)
               setSaveFlag(false);
               setLoader(false);
            }
            
         })
         .catch(() => {
            console.log('error');
            setLoader(false);
         });
      }
   }, [SaveFlag,transaction_id]);

   let panFlag = 1;

   return (
      <>
         <ToastContainer theme="colored" style={{ width: "450px", marginLeft: "-200px" }} />
         <Transaction_menu panel_flag={panFlag}/>
         {loader ? <Loader /> : null}
         <div>
            <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing={0} cellPadding={0} className="custom-body">
               <tbody>
                  <tr valign="top">
                     <td></td>
                     <td width="100%">
                        <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                        <form name="non_product" method="post" onSubmit={handleAddEditConsents} >
                           <table width="100%" border="0" cellSpacing={5} cellPadding={5}>
                              <tbody>
                                 <tr>
                                    <td valign="top" align="center">
                                       <table cellSpacing={1} className="defaultTbl no_caption_color" width="800">
                                          <tbody>
                                             <tr>
                                                <th colSpan={4} style={{ textAlign: 'center' }}>Record of advice</th>
                                             </tr>
                                             <tr>
                                                <td className="caption" >Client's Financial Situation</td>
                                                <td width="98%" colSpan={3}>
                                                   <textarea name="fin_status" className="text-uppercase" style={{ width: '98%', height: '55px' }} value={FinStatus} onChange={(e) => { setFinStatus(e.target.value) }}></textarea>
                                                </td>
                                             </tr>

                                             {ClientFinancialResult != null ?
                                                ClientFinancialResult.map((item, index) => {

                                                   return (
                                                      <tr key={index}>
                                                         <td className="caption" ></td>
                                                         <td style={{ textAlign: 'left' }} colSpan={3}>Added by<strong>{item.client_financial_display_string}</strong>{item.next_name}</td>
                                                      </tr>
                                                   );
                                                }) : null}


                                             <tr>
                                                <td className="caption" >Client's Product Experience</td>
                                                <td width="98%" colSpan={3}>
                                                   <textarea name="product_experience" className="text-uppercase" style={{ width: '98%', height: '55px' }} value={ProductExperience} onChange={(e) => { setProductExperience(e.target.value) }}></textarea>
                                                </td>
                                             </tr>

                                             {ProductExperienceResult != null ?
                                                ProductExperienceResult.map((item, index) => {

                                                   return (
                                                      <tr key={index}>
                                                         <td className="caption" ></td>
                                                         <td style={{ textAlign: 'left' }} colSpan={3}>Added by<strong>{item.client_product_display_string}</strong>{item.next_name}</td>
                                                      </tr>
                                                   );
                                                }) : null}

                                             <tr>
                                                <td className="caption" >Client's Objectives</td>
                                                <td width="98%" colSpan={3}>
                                                   <textarea name="client_objective" className="text-uppercase" style={{ width: '98%', height: '55px' }} value={ClientObjective} onChange={(e) => { setClientObjective(e.target.value) }}></textarea>
                                                </td>
                                             </tr>

                                             {ClientObjectivesResult!= null ?
                                                ClientObjectivesResult.map((item, index) => {

                                                   return (
                                                      <tr key={index}>
                                                         <td className="caption" ></td>
                                                         <td style={{ textAlign: 'left' }} colSpan={3}>Added by<strong>{item.client_client_objectives_display_string}</strong>{item.next_name}</td>
                                                      </tr>
                                                   );
                                                }) : null}

                                             <tr>
                                                <td className="caption" valign="top">Client's Product Feedback</td>
                                                <td width="98%" colSpan={3}>
                                                   <select style={{ width: '98%' }} name="feedback_product_id" value={ClientProduct} onChange={(e) => setClientProduct(e.target.value)} className="text-uppercase">
                                                      <option value='0'>-- Select an Item --</option>
                                                      {ClientProductAll.map((item) => {
                                                         return (
                                                            <option key={item.id} value={item.id}>
                                                               {item.name}
                                                            </option>
                                                         );
                                                      })}
                                                   </select>
                                                   <br /><br />
                                                   <textarea name="product_feedback" className="text-uppercase" style={{ width: '98%' }} rows={8} value={ProductFeedback} onChange={(e) => { setProductFeedback(e.target.value) }}></textarea>
                                                </td>
                                             </tr>

                                             {ClientProductResult != null ?
                                                ClientProductResult.map((item, index) => {
                                                   
                                                   return (
                                                      <tr key={index}>
                                                         <td className="caption" ></td>
                                                         <td style={{ textAlign: 'left' }} colSpan={3}>Added by<strong>{item.client_product_feedback_display_string}</strong>
                                                         <br/>
                                                         <span>{item.type}: {item.category}: {item.next_name}</span>
                                                         </td>
                                                      </tr>
                                                   );
                                             }) : null}

                                             <tr>
                                                <td className="caption" rowSpan={RowCount}>Product Acceptance</td>
                                             </tr>

                                             <tr>
                                                <td className="caption" style={{ textAlign: 'left' }} >&nbsp;</td>
                                                <td className="caption" style={{ textAlign: 'center' }}>Offered</td>
                                                <td className="caption" style={{ textAlign: 'center' }}>Accepted / Declined</td>
                                             </tr>

                                             {ProductAcceptList.length > 0 ?
                                                ProductAcceptList.map((item, index) => {

                                                   return (
                                                      <tr key={index}>
                                                         {/* <td className="caption" ></td> */}
                                                         <td style={{ textAlign: 'left' }} >{item.pname}</td>
                                                         <td style={{ textAlign: 'center' }} >{item.offered}</td>
                                                         <td style={{ textAlign: 'center' }}>{item.accept_declain}</td>
                                                      </tr>

                                                   );
                                                }) : null}

                                             <tr>
                                                <td className="caption" rowSpan={4}>Consents</td>
                                                <td>&nbsp;</td>
                                                <td style={{ textAlign: 'center' }}>Yes</td>
                                                <td style={{ textAlign: 'center' }}>No</td>
                                             </tr>

                                             {
                                                ConsentsDetails.share_company_flag === 'yes' ?
                                                   <tr>
                                                      <td>CONSENT TO SHARE DETAILS WITHIN COMPANY</td>
                                                      <td style={{ textAlign: 'center' }}>
                                                         <input type="radio" name="share_company_details" value={ShareCompanyDetails === 'share_company_details_yes' ? ShareCompanyDetails : 'share_company_details_yes'} checked={ShareCompanyDetails == 'share_company_details_yes'} onChange={(e) => { setShareCompanyDetails(e.target.value) }} />
                                                      </td>
                                                      <td style={{ textAlign: 'center' }}>
                                                         <input type="radio" name="share_company_details" value={ShareCompanyDetails === 'share_company_details_no' ? ShareCompanyDetails : 'share_company_details_no'} checked={ShareCompanyDetails == 'share_company_details_no'} onChange={(e) => { setShareCompanyDetails(e.target.value) }} />
                                                      </td>
                                                   </tr>
                                                   :
                                                   <tr>
                                                      <td>CONSENT TO SHARE DETAILS WITHIN COMPANY</td>
                                                      <td style={{ textAlign: 'center' }}>
                                                         <input type="radio" name="share_company_details" value={ShareCompanyDetails === 'share_company_details_yes' ? ShareCompanyDetails : 'share_company_details_yes'} checked={ShareCompanyDetails == 'share_company_details_yes'} onChange={(e) => { setShareCompanyDetails(e.target.value) }} />
                                                      </td>
                                                      <td style={{ textAlign: 'center' }}>
                                                         <input type="radio" name="share_company_details" value={ShareCompanyDetails === 'share_company_details_no' ? ShareCompanyDetails : 'share_company_details_no'} checked={ShareCompanyDetails == 'share_company_details_no'} onChange={(e) => { setShareCompanyDetails(e.target.value) }} />
                                                      </td>
                                                   </tr>
                                             }

                                             {
                                                ConsentsDetails.share_amongst_flag === 'yes' ?
                                                   <tr>
                                                      <td>CONSENT TO SHARE DETAILS AMONGST PARTNERS</td>
                                                      <td style={{ textAlign: 'center' }}>
                                                         <input type="radio" name="share_amongst_partners" value={ShareAmongstPartners === 'share_amongst_partners_yes' ? ShareAmongstPartners : 'share_amongst_partners_yes'} checked={ShareAmongstPartners == 'share_amongst_partners_yes'} onChange={(e) => { setShareAmongstPartners(e.target.value) }} />
                                                      </td>
                                                      <td style={{ textAlign: 'center' }}>
                                                         <input type="radio" name="share_amongst_partners" value={ShareAmongstPartners === 'share_amongst_partners_no' ? ShareAmongstPartners : 'share_amongst_partners_no'} checked={ShareAmongstPartners == 'share_amongst_partners_no'} onChange={(e) => { setShareAmongstPartners(e.target.value) }} />
                                                      </td>
                                                   </tr>
                                                   :
                                                   <tr>
                                                      <td>CONSENT TO SHARE DETAILS AMONGST PARTNERS</td>
                                                      <td style={{ textAlign: 'center' }}>
                                                         <input type="radio" name="share_amongst_partners" value={ShareAmongstPartners === 'share_amongst_partners_yes' ? ShareAmongstPartners : 'share_amongst_partners_yes'} checked={ShareAmongstPartners == 'share_amongst_partners_yes'} onChange={(e) => { setShareAmongstPartners(e.target.value) }} />
                                                      </td>
                                                      <td style={{ textAlign: 'center' }}>
                                                         <input type="radio" name="share_amongst_partners" value={ShareAmongstPartners === 'share_amongst_partners_no' ? ShareAmongstPartners : 'share_amongst_partners_no'} checked={ShareAmongstPartners == 'share_amongst_partners_no'} onChange={(e) => { setShareAmongstPartners(e.target.value) }} />
                                                      </td>
                                                   </tr>
                                             }

                                             {
                                                ConsentsDetails.share_amongst_flag === 'yes' ?
                                                   <tr>
                                                      <td>CONSENT TO RECEIVE MARKET INFORMATION</td>
                                                      <td style={{ textAlign: 'center' }}>
                                                         <input type="radio" name="receive_marketing_info" value={ReceiveMarketingInfo === 'receive_marketing_info_yes' ? ReceiveMarketingInfo : 'receive_marketing_info_yes'} checked={ReceiveMarketingInfo == 'receive_marketing_info_yes'} onChange={(e) => { setReceiveMarketingInfo(e.target.value) }} />
                                                      </td>
                                                      <td style={{ textAlign: 'center' }}>
                                                         <input type="radio" name="receive_marketing_info" value={ReceiveMarketingInfo === 'receive_marketing_info_no' ? ReceiveMarketingInfo : 'receive_marketing_info_no'} checked={ReceiveMarketingInfo == 'receive_marketing_info_no'} onChange={(e) => { setReceiveMarketingInfo(e.target.value) }} />
                                                      </td>
                                                   </tr>
                                                   :
                                                   <tr>
                                                      <td>CONSENT TO RECEIVE MARKET INFORMATION</td>
                                                      <td style={{ textAlign: 'center' }}>
                                                         <input type="radio" name="receive_marketing_info" value={ReceiveMarketingInfo === 'receive_marketing_info_yes' ? ReceiveMarketingInfo : 'receive_marketing_info_yes'} checked={ReceiveMarketingInfo == 'receive_marketing_info_yes'} onChange={(e) => { setReceiveMarketingInfo(e.target.value) }} />
                                                      </td>
                                                      <td style={{ textAlign: 'center' }}>
                                                         <input type="radio" name="receive_marketing_info" value={ReceiveMarketingInfo === 'receive_marketing_info_no' ? ReceiveMarketingInfo : 'receive_marketing_info_no'} checked={ReceiveMarketingInfo == 'receive_marketing_info_no'} onChange={(e) => { setReceiveMarketingInfo(e.target.value) }} />
                                                      </td>
                                                   </tr>
                                             }

                                             <tr>
                                                <td className="caption" colSpan={4} style={{ textAlign: 'center' }}>
                                                   <input type="submit" value="Save" name="command" onClick={(e) => { setConsentsSave(e.target.value) }} />&nbsp;&nbsp;
                                                   <input type="submit" value="Save and Continue" name="command" onClick={(e) => { setConsentsSave(e.target.value) }} />&nbsp;&nbsp;

                                                   <a href={AllPdfDetails} target="_blank">
                                                      <input type="button" value="View PDF" />
                                                   </a>
                                                </td>
                                             </tr>


                                          </tbody>
                                       </table>
                                    </td>
                                 </tr>

                              </tbody>
                           </table>
                        </form>
                     </td>
                  </tr>
               </tbody>
            </table>
            <Footer />
         </div>
      </>
   );
}

export default Record_of_advice;