import { Link, useParams } from "react-router-dom"
import React, { useState, useEffect, useRef } from "react";
import Transaction_menu from "../Transaction/Transaction_menu";
import Footer from "../Dashboard/Footer";
import axios from "../axios";
import Loader from "../Components/Loader"

const Client_company_view = () => {
   const url_data = useParams();
   const division_id    = localStorage.getItem("division_id");
   const transaction_id = localStorage.getItem("transaction_id");
   const [ViewClientCompanyDetailsAll, setViewClientCompanyDetailsAll] = useState([]);
   const [RowHideShow, setRowHideShow] = useState("");
   const [RowPrivateHideShow, setRowPrivateHideShow] = useState("");
   
   const [loader, setLoader] = useState(false);
   
   useEffect(() => {
      document.title = 'Transactions | Seriti BW';
   }, []);

   //------------------- featch company details ------------- 
   useEffect(() => {   
      setLoader(true);
      const payload = {
         transaction_id: transaction_id,
         client_id: url_data.id,
         division_id: division_id,
      };

      axios
        .post("view_company/featch_view_company_details",payload)
        .then((response) => {
         //console.log(response.data.data.view_company_details);
          if(response.status === 200){
            setViewClientCompanyDetailsAll(response.data.data.view_company_details);
            
            if(response.data.data.view_company_details.customer_type === "PRIVATE"){
               const RowHideShow = "none";
               const RowPrivateHideShow = "table-row";
               setRowHideShow(RowHideShow);
               setRowPrivateHideShow(RowPrivateHideShow);
            }else if(response.data.data.view_company_details.customer_type === "COMMERCIAL"){
               const RowHideShow = "table-row";
               const RowPrivateHideShow = "none";
               setRowHideShow(RowHideShow);
               setRowPrivateHideShow(RowPrivateHideShow);
            }
            setLoader(false);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          } 
        })
        .catch(() => {
           console.log('error');
           setLoader(false);
        });
   }, []);

   let panFlag = 1;
   
    return (
      <>
        <Transaction_menu panel_flag={panFlag}/>
        {loader ? <Loader /> : null}
        <div>
          <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing={0} cellPadding={0} className="custom-body">
            <tbody>
              <tr valign="top">
               <td></td>
               <td width="100%">
                <div id="parent_wrapper" className="custom-parent_wrapper"></div> 
                <form name="command" method="post" >
                  <table width="100%" border="0" cellSpacing={5} cellPadding={5}>
                    <tbody>
                      <tr>
                         <td valign="top" align="center">
                           <input type="hidden" name="id" value="12223" />
                           <input type="hidden" name="command" value="Update" id="command" />
                           <input type="hidden" id="external_id" name="external_id" value="0" />
                           <input type="hidden" name="department" value="0" />
                           <div id="soap_result"></div>
                            <table cellSpacing={1} className="defaultTbl no_caption_color" width="650">
                              <tbody>
                                 <tr>
                                    <th colSpan={2} style={{textAlign: 'center'}}>Client Details</th>
                                 </tr>
                                 { ViewClientCompanyDetailsAll.orignal_client_number != null && ViewClientCompanyDetailsAll.orignal_client_number != 0 ? (
                                 <tr id="client_type">
                                    <td className="caption" width="240">Original Client Number</td>
                                    <td>
                                       <a href="transaction.php?id=12050">{ViewClientCompanyDetailsAll.orignal_client_number}</a>
                                    </td>
                                 </tr>
                                 ) : null }
                                 <tr id="client_type">
                                    <td className="caption" width="240">Customer Type</td>
                                    <td>{ViewClientCompanyDetailsAll.customer_type}</td>
                                 </tr>
                                 <tr id="guarantor_application" style={{ display: RowPrivateHideShow }}>
                                    <td className="caption">Is this a guarantor application?</td>
                                    <td>{ViewClientCompanyDetailsAll.guarantor_application}</td>
                                 </tr>
                                 <tr style={{ display: RowHideShow }}>
                                    <td className="caption" id="title">Company Type</td>
                                    <td id="title_contents">
                                    {ViewClientCompanyDetailsAll.company_type}
                                    </td>
                                 </tr>
                                 <tr style={{ display: RowHideShow }}>
                                    <td className="caption" id="company_name">Company Name</td>
                                    <td>
                                      {ViewClientCompanyDetailsAll.company_name}
                                    </td>
                                 </tr>
                                 <tr style={{ display: RowHideShow }}>
                                    <td className="caption" id="abbreviation">Trading As</td>
                                    <td>
                                      {ViewClientCompanyDetailsAll.abbreviation_trading_as}
                                    </td>
                                 </tr>
                                 <tr style={{ display: RowPrivateHideShow }}>
                                    <td className="caption" id="title">Title</td>
                                    <td id="title_contents">
                                       {ViewClientCompanyDetailsAll.company_representative_title}
                                    </td>
                                 </tr>
                                 <tr style={{ display: RowPrivateHideShow }}>
                                    <td className="caption" id="company_name">First Name</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.first_name}
                                    </td>
                                 </tr>
                                 <tr style={{ display: RowPrivateHideShow }}>
                                    <td className="caption" id="abbreviation">Last Name</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.abbreviation_trading_as}
                                    </td>
                                 </tr>
                                 <tr className="compReprTitle" style={{ display: RowHideShow }}>
                                    <td className="caption" id="compReprTitle">Company Representative Title</td>
                                    <td id="title_contents">
                                      {ViewClientCompanyDetailsAll.company_representative_title}
                                    </td>
                                 </tr>
                                 <tr id="initials" style={{ display: RowPrivateHideShow }}>
                                    <td className="caption">Initials</td>
                                    <td>{ViewClientCompanyDetailsAll.initials_input}</td>
                                 </tr>
                                 <tr style={{ display: RowPrivateHideShow }}>
                                    <td className="caption" id="preferred_name">Preferred Name</td>
                                    <td>{ViewClientCompanyDetailsAll.preferred_name}</td>
                                 </tr>
                                 <tr className="compReprname" style={{ display: RowHideShow }}>
                                    <td className="caption" id="compReprname">Company Representative Name</td>
                                    <td>
                                    {ViewClientCompanyDetailsAll.preferred_name}
                                    </td>
                                 </tr>
                                 <tr className="compReprSurname" style={{ display: RowHideShow }}>
                                    <td className="caption" id="compReprSurname">Company Representative Surname</td>
                                    <td>
                                      {ViewClientCompanyDetailsAll.company_representative_surname}
                                    </td>
                                 </tr>
                                 <tr className="comp_repr_nationality" style={{ display: RowHideShow }}>
                                    <td className="caption">Nationality </td>
                                    <td>
                                      {ViewClientCompanyDetailsAll.nationality}
                                    </td>
                                 </tr>
                                 <tr className="country_of_incorporation" style={{ display: RowHideShow }}>
                                    <td className="caption">Country of Incorporation </td>
                                    <td>
                                      {ViewClientCompanyDetailsAll.country_of_incorporation != 'null' ? ViewClientCompanyDetailsAll.country_of_incorporation : null}
                                    </td>
                                 </tr>
                                 <tr id="comercial_id_number_row" style={{ display: RowHideShow }}>
                                    <td className="caption">ID Number</td>
                                    <td>
                                      {ViewClientCompanyDetailsAll.id_number}
                                    </td>
                                 </tr>

                                 <tr>
                                    <td className="caption">Occupation</td>
                                    <td>{ViewClientCompanyDetailsAll.occupation}</td>
                                 </tr>
                                 <tr id="regular_driver">
                                    <td className="caption">Regular Driver</td>
                                    <td>{ViewClientCompanyDetailsAll.regular_driver}</td>
                                 </tr>
                                 <tr id="gender">
                                    <td className="caption">Gender</td>
                                    <td>
                                      {ViewClientCompanyDetailsAll.gender}
                                    </td>
                                 </tr>
                                 <tr id="date_of_birth">
                                    <td className="caption">Date of Birth</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.date_of_birth}
                                    </td>
                                 </tr>
                                 <tr>
                                    <td className="caption">Driver's License Issue Date</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.drivers_issue_date}
                                    </td>
                                 </tr>
                                 <tr>
                                    <td className="caption">Claim Free Group</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.claim_free_group}
                                    </td>
                                 </tr>
                                
                                 <tr>
                                    <td className="caption">Applicant has made a claim in the last 12  months</td>
                                    <td>
                                      {ViewClientCompanyDetailsAll.claim_has_made}
                                    </td>
                                 </tr>

                                 { ViewClientCompanyDetailsAll.claim_has_made === 'YES' ?
                                 <tr>
                                    <td className="caption">NUMBER OF CLAIMS</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.number_of_claims}
                                    </td>
                                 </tr>
                                 : null }

                                 <tr id="id_type" style={{ display: RowPrivateHideShow }}>
                                    <td className="caption">ID Type</td> 
                                    <td>
                                       {ViewClientCompanyDetailsAll.type_of_id} 
                                    </td>
                                 </tr>
                                 {/* ID Number */}
                                 <tr id="id_number" >
                                    <td className="caption" id="registration">
                                       {ViewClientCompanyDetailsAll.display_name} 
                                    </td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.company_registration_number}
                                    </td>
                                 </tr> 
                                 <tr id="passport_number" style={{ display: RowHideShow }}>
                                    <td className="caption" id="vat_number">VAT NUMBER</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.vat_number}
                                    </td>
                                 </tr>
                                 <tr className="commercial_only" style={{ display: RowHideShow }}>
                                    <td className="caption">Number of years in business</td>
                                    <td>{ViewClientCompanyDetailsAll.years_in_business}</td>
                                 </tr>
                                 <tr className="commercial_only" style={{ display: RowHideShow }}>
                                    <td className="caption">Nature of business</td>
                                    <td>{ViewClientCompanyDetailsAll.nature_of_business}</td>
                                 </tr>
                                 <tr className="commercial_only" style={{ display: RowHideShow }}>
                                    <td className="caption">Land lord<br />(if not owner of property)</td>
                                    <td>{ViewClientCompanyDetailsAll.land_lord_name}</td>
                                 </tr>
                                 <tr className="commercial_only" style={{ display: RowHideShow }}>
                                    <td className="caption">Holding company</td>
                                    <td>{ViewClientCompanyDetailsAll.holding_company}</td>
                                 </tr>
                                 <tr className="commercial_only" style={{ display: RowHideShow }}>
                                    <td className="caption">Associated company/ies</td>
                                    <td>{ViewClientCompanyDetailsAll.associated_companies}</td>
                                 </tr> 
                                 <tr className="commercial_only" style={{ display: RowHideShow }}>
                                    <td className="caption">Subsidiary company</td>
                                    <td>{ViewClientCompanyDetailsAll.subsidiary_company}</td>
                                 </tr>
                                 
                                 {/* { system_type === 1 ? ( */}
                                 {/* <tr style={{ display: RowHideShow }}>
                                    <td className="caption">Income Tax Number</td>
                                    <td>{ViewClientCompanyDetailsAll.income_tax_number}</td>
                                 </tr> */}
                                 {/* ): null } */}

                                 <tr>
                                    <td className="caption">ID Expiry Date</td>
                                    <td>{ViewClientCompanyDetailsAll.id_expiry_date}</td>
                                 </tr>
                                  <tr className="not_commercial" style={{ display: RowPrivateHideShow }}>
                                    <td className="caption">Residency Status</td>
                                    <td> 
                                       {ViewClientCompanyDetailsAll.residency_status}
                                    </td> 
                                 </tr>
                                 
                                 { ViewClientCompanyDetailsAll.residency_status === 'EXPAT RESIDENT PERMIT' ?
                                 <tr className="stay_duration" style={{ display: RowPrivateHideShow }}>
                                    <td className="caption">Duration Of Stay</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.duration_of_stay}
                                    </td>
                                 </tr>
                                 : null }

                                 {/* <tr className="prev_res_country">
                                    <td className="caption">If duration of stay &gt;2 years give previous country of residence</td>
                                    <td>
                                      <input type="text" id="prev_res_country" name="prev_res_country" size="30" className="text-uppercase" value={PrevResCountry} onChange = {(e) => setPrevResCountry(e.target.value)} /></td>
                                 </tr> */}
                                 <tr id="marital_status" style={{ display: RowPrivateHideShow }}>
                                    <td className="caption">Marital Status</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.marital_status}
                                    </td>
                                 </tr>
                                 
                                 { ViewClientCompanyDetailsAll.marital_status === 'MARRIED (COP)' || ViewClientCompanyDetailsAll.marital_status === 'MARRIED (OCOP)' ?
                                 <tr id="how_married" style={{ display: RowPrivateHideShow }}>
                                    <td className="caption">How Married</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.how_married}
                                    </td>
                                 </tr>
                                 : null }

                                 { ViewClientCompanyDetailsAll.marital_status === 'MARRIED (COP)' || ViewClientCompanyDetailsAll.marital_status === 'MARRIED (OCOP)' ?
                                 <tr id="tr_date_married" style={{ display: RowPrivateHideShow }}>
                                    <td className="caption">Date Married</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.married_date}
                                    </td>
                                 </tr>
                                 : null }
                           
                                 <tr className="country_of_residence">
                                    <td className="caption">Country of Residence </td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.countries_of_residence}
                                    </td>
                                 </tr>
                                 <tr className="nationality"> 
                                    <td className="caption">Nationality <span className="text-danger">*</span></td>
                                    <td> 
                                       {ViewClientCompanyDetailsAll.nationality}
                                    </td>
                                 </tr>
                                 <tr id="home_language" style={{ display: RowPrivateHideShow }}>
                                    <td className="caption">Home Language</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.home_language}
                                    </td>
                                 </tr>
                                 <tr>
                                    <td className="caption">Correspondence Language</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.correspondance_language}
                                    </td>
                                 </tr>
                                 <tr>
                                    <td className="caption">Preferred Statement Method</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.preferred_statement_method}
                                    </td>
                                 </tr>
                                 <tr> 
                                    <td className="caption">Work Number</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.office_number}
                                    </td>
                                 </tr> 
                                 <tr>
                                    <td className="caption">Mobile Number</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.mobile_number}
                                    </td>
                                 </tr> 
                                 <tr id="home_number" style={{ display: RowPrivateHideShow }}>
                                    <td className="caption">Home Number</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.home_number}
                                    </td>
                                 </tr>
                                 <tr>
                                    <td className="caption">Fax Number</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.fax_number}
                                    </td>
                                 </tr>
                                 <tr>
                                    <td className="caption">Email <span className="text-danger">*</span></td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.user_email}
                                    </td>
                                 </tr>
                                 <tr> 
                                    <td className="caption">Physical Address Line 1</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.physical_address_line1}
                                    </td>
                                 </tr>
                                 <tr>
                                    <td className="caption">Physical Address Line 2</td>
                                    <td>{ViewClientCompanyDetailsAll.physical_address_line2}</td>
                                 </tr>
                                 <tr>
                                    <td className="caption">Physical Address City</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.physical_address_city}
                                    </td>
                                 </tr>
                                 
                                 <tr>
                                    <td className="caption">Postal Address Line 1</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.postal_address_line1}
                                    </td>
                                 </tr>
                                 <tr>
                                    <td className="caption">Postal Address Line 2</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.postal_address_line2}
                                    </td>
                                 </tr>
                                 <tr>
                                    <td className="caption">Postal Address City</td>
                                    <td>{ViewClientCompanyDetailsAll.postal_address_city}</td>
                                 </tr>
                                 <tr className="not_commercial" style={{ display: RowPrivateHideShow }}>
                                    <td className="caption">Occupancy date at current address</td>
                                    <td>
                                      {ViewClientCompanyDetailsAll.date_at_current_address}
                                    </td>
                                 </tr>
                                 <tr className="not_commercial" id="prev_address_tr" style={{ display: RowPrivateHideShow }}>
                                    <td className="caption">Occupancy date at previous address</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.date_at_previous_address}
                                    </td>
                                 </tr>
                                 <tr className="not_commercial" style={{ display: RowPrivateHideShow }}>
                                    <td className="caption">Residential Status</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.residential_status}
                                    </td>
                                 </tr>
                                 <tr>
                                    <td className="caption">Marketing Source</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.marketing_source}
                                    </td>
                                 </tr>
                                 {/* { system_type === 1 ? (
                                  <tr>
                                    <td className="caption">Taxi Operator</td>
                                    <td><input type="checkbox" name="taxi_operator" value={TaxiOperator} onChange={(e) => { setTaxiOperator(!TaxiOperator) }} checked={TaxiOperator} /></td>
                                 </tr>
                                  ): null }
                                 { system_type === 1 ? (
                                 <tr>
                                    <td className="caption">Public Drivers Permit</td>
                                    <td><input type="checkbox" name="public_drivers_permit" value={PublicDriversPermit} onChange={(e) => { setPublicDriversPermit(!PublicDriversPermit) }} checked={PublicDriversPermit} /></td>
                                 </tr>
                                  ): null } */}

                                 <tr id="web_address" style={{ display: RowHideShow }}>
                                    <td className="caption">Web Address</td>
                                    <td>
                                       {ViewClientCompanyDetailsAll.website_address}
                                    </td>
                                 </tr>
                                 <tr>
                                    <td className="caption text-center" colSpan={3} >
                                       <Link to={"/transaction/create_transaction/" + transaction_id}> 
                                          <input type="submit" name="command" className="hide_button_after_inception" value="View Transaction"  />
                                       </Link> 
                                       
                                       {
                                          ViewClientCompanyDetailsAll.orignal_client_number > 0 && ViewClientCompanyDetailsAll.iTransactionStatus != 3 && ViewClientCompanyDetailsAll.sIsVehicleEdited === 0 ?
                                       
                                        <input type="submit" name="command" className="hide_button_after_inception" value="Select Another Vehicle"  /> 
                                        : null }
                                    </td>
                                 </tr>
                                 
                              </tbody>
                            </table>
                          </td>
                      </tr>
                    </tbody>
                  </table>
                  </form>
                </td> 
              </tr>
            </tbody>
          </table> 
          <Footer />
        </div>
      </>
    );
}

export default Client_company_view;