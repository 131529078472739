import { Link, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from "react";
import Report_img from '../Images/core/Reports.png';
import Admintrative_img from '../Images/core/Administration.png';
import Heading_search_img from '../Images/core/heading_search.gif';
//import Heading_img from '../Images/core/heading_bg.gif';
import Heading_recently_viewed from '../Images/core/heading_recently_viewed.gif';
//import Footer_img from '../Images/core/h11_bg.gif';
import Template from "../Components/Template";
import Footer from "../Dashboard/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "../axios";
import Loader from "../Components/Loader";
import { useDispatch } from "react-redux";
import { token } from "../features/TokenSlice";

const Home = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [Usergroup, setUsergroup] = useState(0);
    const [UsergroupAll, setUsergroupAll] = useState([]);
    const [loader, setLoader] = useState(false);

    const [Userbranch, setUserbranch] = useState("");
    const [UserbranchAll, setUserbranchAll] = useState([]);
    const [RecentViewData, setRecentViewData] = useState([]);

    const [search_text, setAllText] = useState("");
    const [create_fdate, setCreate_Fdate] = useState("");
    const Finalcreate_fdate = create_fdate != '' ? moment(create_fdate).format("YYYY-MM-DD") : 0;
    const [create_todate, setCreate_Todate] = useState("");
    const Finalcreate_todate = create_todate != '' ? moment(create_todate).format("YYYY-MM-DD") : 0;
    const [incepted_fdate, setIncepted_Fdate] = useState("");
    const Finalincepted_fdate = incepted_fdate != '' ? moment(incepted_fdate).format("YYYY-MM-DD") : 0;
    const [incepted_todate, setIncepted_Todate] = useState("");
    const Finalincepted_todate = incepted_todate != '' ? moment(incepted_todate).format("YYYY-MM-DD") : 0;

    const [command, setCommand] = useState("Search");
    const [older, setOlder] = useState(0);

    const [RowHideShow, setRowHideShow] = useState("none");
    const [AddNewButton, setAddNewButton] = useState("table-row");


    const UserId = localStorage.getItem("UserId");
    const super_user = localStorage.getItem("super_user");
    const dashboard_hide_show_flag = localStorage.getItem("dashboard_hide_show_flag");

    const stay_login_expired_time = localStorage.getItem("stay_login_expired_time");
    const stay_login_flag = localStorage.getItem("stay_login_flag");

    const [flag, setFlag] = useState(true);
    const [ReadOnly, setReadOnly] = useState(false);
    const [AdminFlag, setAdminFlag] = useState(false);
    const [SearchingFlag, setSearchingFlag] = useState(0);
    const [SearchingFlag1, setSearchingFlag1] = useState(false);

    
   const user_department_access = localStorage.getItem("user_department_access_rights");

    useEffect(() => {
        document.title = 'Seriti';

        if(dashboard_hide_show_flag == 1){
            setFlag(true); 
            setRowHideShow("table-row");
            setReadOnly(true); 
            setAdminFlag(true);
        }

    }, []);

    useEffect(() => {
        const payload = {
            user_id: UserId,
            super_user: super_user
        }
        axios
            .post("/admin/user_department_access_rights", payload)
            .then((response) => {
                //console.log(response.data.data)
                localStorage.setItem("user_department_access_rights", response.data.data);
                //setUserDepartmentAccess(response.data.data)
            })
            .catch((response) => {
                //alert(response.data);
            });
    
    }, []);

    const documentShowRow = (e) => {
        const payload = {
            UserId: UserId,
            super_user: super_user,
            module_name: "Transactions"
        }
        axios
            .post("getRights", payload)
            .then((response) => {
                // console.log(response.data.data)
                if (response.data.status === 200) {
                    if (response.data.data === 0) {//None
                        alert(response.data.message);
                        navigate('/home');
                    } else if (response.data.data === 1) {//Read
                        setFlag(true);//setReadOnly(true);
                    } else if (response.data.data === 2) {//Write
                        setFlag(true); setAdminFlag(true);
                    } else if (response.data.data === 3) {//Modify
                        setFlag(true); setAdminFlag(true);
                    } else if (response.data.data === 4) {//Admin
                        setFlag(true); setAdminFlag(true);
                    }
                    localStorage.setItem("dashboard_hide_show_flag", 1);
                }
            })
            .catch(() => {
                //setLoginLoader(false);
            });
        const loc_div_id = e.currentTarget.id;
        if (loc_div_id == 'add_vehicle') {
            const RowHideShow = "table-row";
            const AddNewButton = "none";
            setRowHideShow(RowHideShow);
            setAddNewButton(AddNewButton);
        }
    }

    useEffect(() => {
        localStorage.setItem("transaction_id", 0);
        localStorage.setItem("loanhouse_id", 0);
        localStorage.setItem("parent_id", 0);
        localStorage.setItem("company_id", 0);
        localStorage.setItem("instance_id", 0);
        localStorage.setItem("older", '');
        localStorage.setItem("created_from", '');
        localStorage.setItem("created_to", '');
        localStorage.setItem("incepted_from", '');
        localStorage.setItem("incepted_to", '');
        localStorage.setItem("division", '');
        localStorage.setItem("department", '');
        localStorage.setItem("application_id", 0);
        localStorage.setItem("form_id", 0);
        localStorage.setItem("client_id", 0);
        localStorage.setItem("group_id", 0);
        localStorage.setItem("product_hidden_tp_id", 0);
        localStorage.setItem("product_hidden_id", 0);
        localStorage.setItem("search_text", '');
    }, []);

    //----------- get groups -----------------
    // useEffect(() => {
    //     axios
    //         .get("index/group")
    //         .then((response) => {
    //             //console.log(response);
    //             setUsergroupAll(response.data.data);
    //         })
    //         .catch(() => {
    //             //setLoader(false);
    //         });
    // }, []);

    //----------- get branch -----------------
    useEffect(() => {

        axios
            .get("index/branch/" + Usergroup)
            .then((response) => {
                // console.log(response);
                if (response.status === 200) {
                    setUserbranchAll(response.data.data);
                }
            })
            .catch(() => {
                //setLoader(false);
            });
    }, [Usergroup]);

    useEffect(() => {
        const payload = {
            user_department_access_rights: user_department_access,
        };
        axios
            .post("user_right_accesable_division", payload)
            .then((response) => {
                //console.log(response);
                setUsergroupAll(response.data.data);
            })
            .catch(() => {
                console.log('error');
               // setLoader(false);
            });

    }, [user_department_access]);

    //----------- get resent view -----------------
    useEffect(() => {
        setLoader(true);
        const payload = {
            user_id: localStorage.getItem("UserId"),
            super_user: localStorage.getItem("super_user"),
            system_type: localStorage.getItem("system_type"),
        };
        axios
            .post("recent/view", payload)
            .then((response) => {
                //console.log(response.data);
                setRecentViewData(response.data.data);
                setLoader(false);
            })
            .catch(() => {
                //console.log('error');
                setLoader(false);
            });

    }, []);

    //----- check user access Adminstration-------------------------------
    const Check_user_access_adminstration = (event, id) => {
        //console.log(id);
        const payload = {
            UserId: UserId,
            super_user: super_user,
            module_name: "Administration"
        };

        axios
            .post("getRights", payload)
            .then((response) => {
                //console.log(response);
                if (response.data.status === 200) {
                    if (parseInt(response.data.data) === 0) {//None
                        alert(response.data.message);
                        navigate('/home'); 
                    } else if (parseInt(response.data.data) === 1) {//Read
                        alert(response.data.message);
                        navigate('/home'); 
                    } else if (parseInt(response.data.data) === 2) {//Write
                        alert(response.data.message);
                        navigate('/home'); 
                    } else if (parseInt(response.data.data) === 3) {//Modify
                        alert(response.data.message);
                        navigate('/home'); 
                    } else if (parseInt(response.data.data) === 4) {//Admin
                        navigate('/admin'); 
                    }
                    // return true;
                }
            })
            .catch(() => {
                //console.log('error');
            });
    };

    //----- check user access Reports-------------------------------
    const Check_user_access_reports = (event, id) => {
        //console.log(id);
        const payload = {
            UserId: UserId,
            super_user: super_user,
            module_name: "Reports"
        };

        axios
            .post("getRights", payload)
            .then((response) => {
                //console.log(response);
                if (response.data.status === 200) {
                    if (parseInt(response.data.data) === 0) {//None
                        alert(response.data.message);
                        navigate('/home'); 
                    } else if (parseInt(response.data.data) === 1) {//Read
                        // alert(response.data.message);
                        navigate('/reports/folders'); 
                    } else if (parseInt(response.data.data) === 2) {//Write
                        alert(response.data.message);
                        navigate('/reports/folders'); 
                    } else if (parseInt(response.data.data) === 3) {//Modify
                        alert(response.data.message);
                        navigate('/reports/folders'); 
                    } else if (parseInt(response.data.data) === 4) {//Admin
                        navigate('/reports/folders'); 
                    }
                    // return true;
                }
            })
            .catch(() => {
                //console.log('error');
            });
    };

    const CheckSearchPermission = () => {
       // console.log('ss');
       // setSearchingFlag1(true);
        const payload = {
            UserId: UserId,
            super_user: super_user,
            module_name: "Search"
        }
        axios
        .post("getRights", payload)
        .then((response) => {
            if (response.data.status === 200) {
                //console.log(response.data.data)
                if (parseInt(response.data.data) === 0) {//None
                    //setAdminFlag(false);setReadOnly(false); 
                    setSearchingFlag(response.data.data);
                    alert(response.data.message);
                    navigate('/home'); 
                    setSearchingFlag1(true);
                } else if (parseInt(response.data.data) === 1) {//Read
                    setSearchingFlag(response.data.data);
                    // alert(response.data.message);
                    // navigate('/home'); 
                    setSearchingFlag1(true);
                    //setFlag(true); setReadOnly(true);
                } else if (parseInt(response.data.data) === 2) {//Write
                    setSearchingFlag(response.data.data);
                    //setFlag(true); setReadOnly(false); setAdminFlag(true);
                } else if (parseInt(response.data.data) === 3) {//Modify
                    setSearchingFlag(response.data.data);
                    //setFlag(true); setReadOnly(false); setAdminFlag(true);
                } else if (parseInt(response.data.data) === 4) {//Admin
                    setSearchingFlag(response.data.data);
                   // setFlag(true); setAdminFlag(true); setReadOnly(false);
                }
                // return true;
            }
        })
        .catch(() => {
            //setLoginLoader(false);
        });
    }

    useEffect(() => {
       
      if(SearchingFlag1){
       
        const payload = {
            UserId: UserId,
            super_user: super_user,
            module_name: "Search"
        }
        axios
            .post("getRights", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    //console.log(response.data.data)
                    if (parseInt(response.data.data) === 0) {//None
                        //setAdminFlag(false);setReadOnly(false); 
                        setSearchingFlag(response.data.data);
                        alert(response.data.message);
                        navigate('/home'); 
                        setSearchingFlag1(true);
                    } else if (parseInt(response.data.data) === 1) {//Read
                        setSearchingFlag(response.data.data);
                        // alert(response.data.message);
                        // navigate('/home'); 
                        setSearchingFlag1(true);
                        //setFlag(true); setReadOnly(true);
                    } else if (parseInt(response.data.data) === 2) {//Write
                        setSearchingFlag(response.data.data);
                        //setFlag(true); setReadOnly(false); setAdminFlag(true);
                    } else if (parseInt(response.data.data) === 3) {//Modify
                        setSearchingFlag(response.data.data);
                        //setFlag(true); setReadOnly(false); setAdminFlag(true);
                    } else if (parseInt(response.data.data) === 4) {//Admin
                        setSearchingFlag(response.data.data);
                       // setFlag(true); setAdminFlag(true); setReadOnly(false);
                    }
                    // return true;
                }
            })
            .catch(() => {
                //setLoginLoader(false);
            });
        }
    }, [SearchingFlag1]);
    // console.log(ReadOnly, AdminFlag);

    const handleValidation = () => {
        //console.log(BorrowingGuaranteed);
        var flag = true;
        if(super_user == 0){
            if(SearchingFlag === 0){
                navigate('/home'); 
                setSearchingFlag1(true);
                flag = false;
            }else{
                flag = true;
            }
        }else{
            flag = true;
        }     
        return flag;
    };


    //----------recent search than redirect page search_new---------
    const handleSubmit = (e) => {
        e.preventDefault();
       
        // console.log(SearchingFlag);
       // if (SearchingFlag != 0 || SearchingFlag != 1) {
        CheckSearchPermission();
        const flag = handleValidation();
        if (flag) {
            const payload = {
                user_id: localStorage.getItem("UserId"),
                super_user: localStorage.getItem("super_user"),
                division_id: localStorage.getItem("division_id"),
                command: command,
                search_text: search_text,
                division: Usergroup,
                department: Userbranch,
                created_from: Finalcreate_fdate,
                created_to: Finalcreate_todate,
                incepted_from: Finalincepted_fdate,
                incepted_to: Finalincepted_todate,
                older: older,
            };
            axios
                .post("search/search_new", payload)
                .then((response) => {
                   // console.log(response);
                    navigate('/search/search_new/1', { state: response.data });
                    localStorage.setItem("older", older);
                    localStorage.setItem("created_from", Finalcreate_fdate);
                    localStorage.setItem("created_to", Finalcreate_todate);
                    localStorage.setItem("incepted_from", Finalincepted_fdate);
                    localStorage.setItem("incepted_to", Finalincepted_todate);
                    localStorage.setItem("division", Usergroup);
                    localStorage.setItem("department", Userbranch);
                    localStorage.setItem("search_text", search_text);
                })
                .catch(() => {
                    // console.log('error');
                });
        }
    }

    //----------- get bank api token -----------------
    useEffect(() => {
        axios
            .get("index/bank_api_token")
            .then((response) => {
                // console.log(response);
                localStorage.setItem("sess_access_token", response.data.data.sess_access_token);
                localStorage.setItem("sess_token_expire", response.data.data.sess_token_expire);
              
                //------------------------------------ temp hide
                //  const payload = {
                //     "sess_access_token": response.data.data.sess_access_token
                //     }
                //     axios
                //     .post("getFinanceStatuses", payload)
                //     .then((response) => {
                //            // console.log(response);
                //     })
                //     .catch((response) => {
                //         console.log('error');
                //     });
                //------------------------------------
            })
            .catch(() => {
                //setLoader(false);
            });
    }, []);

    useEffect(() => {
        //--------------- time expiration
        var current_exp_time = Math.floor((new Date()).getTime() / 1000);
        //var exp_time = today.getHours() + ':' + today.getMinutes();
        // console.log(today);
        // var current_exp_time = Date.now();
        
        if(stay_login_expired_time != '' && (stay_login_flag != '' || stay_login_flag != 0)){
            if(stay_login_flag == 1 && current_exp_time > stay_login_expired_time){   // if(exp_time == '12:50'){
               //alert(current_exp_time+'-------'+stay_login_expired_time);
                alert('Login Expired ..! Please Login Again.');
                localStorage.removeItem("Token");
                localStorage.removeItem("sess_access_token");
                localStorage.removeItem("sess_token_expire");
                localStorage.clear();
                dispatch(token(""));

                const payload = {
                    user_id: UserId,
                }
                axios
                    .post("reset_stay_login", payload)
                    .then((response) => {
                        //console.log(response)
                    })
                    .catch(() => {
                        console.log('error');
                    });
                navigate('/login'); 
                window.location.reload(false);
            }
        }
    }, []);

    //----------
    // console.log(Date.now());  

    return <>
        <Template />
        {loader ? <Loader /> : null}
        <div style={{ marginTop: '100px' }}>
            <table border="0" cellPadding={0} cellSpacing={0} width="100%" bgcolor="#ffffff" style={{ padding: '0px 50px 0px 50px' }}>
                <tbody>
                    <tr valign="top">
                        <td style={{ height: '100%' }}></td>

                        <td>
                            <table border="0" cellPadding={0} cellSpacing={0} width="178" bgcolor="#ffffff">
                                <tbody>
                                    <tr>
                                        <td>
                                            <table width="100%" border="0" cellPadding={0} cellSpacing={0} >
                                                <tbody>
                                                    <tr style={{ textAlign: 'center' }}>
                                                        <td>
                                                            

                                                            { super_user === 1 ? 
                                                                <Link to="/reports/folders" >
                                                                   <img height="40" border="0" src={Report_img} alt="" />
                                                                </Link>
                                                            :
                                                                <Link onClick={event => Check_user_access_reports(event, 1)} >
                                                                    <img height="40" border="0" src={Report_img} alt="" />
                                                                </Link>
                                                            }

                                                        </td>
                                                    </tr>
                                                    <tr style={{ textAlign: 'center' }}>
                                                        <td>
                                                            { super_user === 1 ? 
                                                                <Link to="/admin" >
                                                                    <img height="40" border="0" src={Admintrative_img} alt="" />
                                                                </Link>
                                                            :
                                                                <Link onClick={event => Check_user_access_adminstration(event, 1)} >
                                                                    <img height="40" border="0" src={Admintrative_img} alt="" />
                                                                </Link>
                                                            }
                                                        </td>
                                                    </tr>
                                                    {/* <tr style={{ textAlign: 'center'}}>
                                    <td>
                                        
                                            <img height="40" border="0" src={Security_img} alt=""/>
                                        
                                    </td>
                                </tr>
                                <tr style={{ textAlign: 'center'}}>
                                    <td>
                                        
                                           <img height="40" border="0" src={News_archives_img} alt=""/>
                                        
                                    </td>
                                </tr> */}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>

                        <td bgcolor="#ffffff" width="100%" style={{ padding: '0px 5px 0px 5px' }} >
                            <div id="parent_wrapper" className="temp-black-strip"></div>
                            <table border="0" cellPadding={0} cellSpacing={0} width="100%" bgcolor="#ffffff">
                                <tbody>
                                    <tr><td className="heading-search-bg" height="29">
                                        <div>
                                            <div align="center">
                                                <img border="0" src={Heading_search_img} alt="" />
                                            </div>
                                        </div>
                                    </td>
                                    </tr>

                                    {/* php code here if condition*/}
                                    {flag ?
                                        <tr style={{ display: RowHideShow }}>
                                            <td>
                                                <form name="form1" method="post" onSubmit={handleSubmit}>
                                                    <input type="hidden" name="command" value={command} onChange={(e) => { setCommand(e.target.value) }} />
                                                    <table width="600" border="0" cellPadding={3} cellSpacing={3} align="center">
                                                        <tbody>

                                                            <tr>
                                                                <td style={{ textAlign: 'center' }} colSpan="4"><strong>Enter a firstname, surname, ID, Registration number or transaction number</strong></td>
                                                            </tr>

                                                            <tr>
                                                                <td>&nbsp;</td><td><input type="text" name="search_text" value={search_text} onChange={(e) => { setAllText(e.target.value) }} className="text-uppercase w-100" /></td>
                                                                <td>&nbsp;</td><td><input type="submit" name="command" value="Search" /></td>
                                                            </tr><tr><td colSpan="4"></td></tr>

                                                            <tr><td><b>GROUP</b></td><td>
                                                                <select value={Usergroup} onChange={(e) => setUsergroup(e.target.value)} >
                                                                    <option value='0'>--- SELECT A GROUP ---</option>
                                                                    {UsergroupAll.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td><td colSpan="2">&nbsp;</td></tr>
                                                            <tr><td><b>BRANCH</b></td><td>
                                                                <select value={Userbranch} onChange={(e) => setUserbranch(e.target.value)}>
                                                                    <option>--- SELECT A GROUP FIRST---</option>
                                                                    {UserbranchAll.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td><td colSpan="2">&nbsp;</td></tr>
                                                            <tr>
                                                                <td><b>CREATED BETWEEN</b></td>
                                                                <td className="customDatePicker">
                                                                    {/* <input type="date" className="birthdate" name="created_from" value={create_fdate} onChange = {(e) => setCreate_Fdate(e.target.value)} /> */}
                                                                    <DatePicker id="birthdate" selected={create_fdate} name="created_from" onChange={(date) => { setCreate_Fdate(date); }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" />
                                                                </td>
                                                                <td><b>AND</b></td>
                                                                <td>
                                                                    {/* <input type="date" className="birthdate" name="created_to" value={create_todate} onChange = {(e) => setCreate_Todate(e.target.value)} /> */}
                                                                    <DatePicker id="birthdate" selected={create_todate} name="created_to" onChange={(date) => { setCreate_Todate(date); }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td><b>INCEPTED BETWEEN</b></td>
                                                                <td className="customDatePicker">
                                                                    {/* <input type="date" className="date" name="incepted_from"  value={incepted_fdate} onChange = {(e) => setIncepted_Fdate(e.target.value)}/> */}
                                                                    <DatePicker id="birthdate" selected={incepted_fdate} name="incepted_from" onChange={(date) => { setIncepted_Fdate(date); }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" />
                                                                </td>

                                                                <td><b>AND</b></td>

                                                                <td>
                                                                    {/* <input type="date" className="birthdate" name="incepted_to"  value={incepted_todate} onChange = {(e) => setIncepted_Todate(e.target.value)} /> */}
                                                                    <DatePicker id="birthdate" selected={incepted_todate} name="incepted_to" onChange={(date) => { setIncepted_Todate(date); }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" />
                                                                </td>
                                                            </tr>

                                                            <tr><td colSpan="2"></td></tr>

                                                            <tr>
                                                                <td style={{ textAlign: 'center' }} colSpan="4"><input type="checkbox" name="older" value={older} onChange={(e) => setOlder(1)} />&nbsp;Show older than 2 months</td>
                                                            </tr>
                                                        </tbody>

                                                    </table>
                                                </form>
                                            </td>
                                        </tr>
                                        : null}
                                    {/* else */}
                                   
                                    { dashboard_hide_show_flag == 0 ?
                                    <tr style={{ display: AddNewButton }}>
                                        <td>
                                            <table width="100%" border="0" cellPadding={3} cellSpacing={3} >
                                                <tbody>
                                                    <tr>
                                                        <td style={{ textAlign: 'center' }} >
                                                            <strong>Please choose a transaction type in order to continue:</strong><br /><br />
                                                            <input type="submit" name="command" id="add_vehicle" value="Vehicle" onClick={function (e) { documentShowRow(e) }} />
                                                           
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    : null }

                                    {/* end if */}

                                    {flag ?
                                        <tr>
                                            <td> <br /><br />
                                                <table border="0" cellPadding={0} cellSpacing={0} width="100%" bgcolor="#ffffff">
                                                    <tbody>
                                                        <tr>
                                                            <td className="heading-search-bg" height="29" width="178" style={{ textAlign: 'center' }}>
                                                                <img src={Heading_recently_viewed} height="29" alt="" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table className="defaultTbl" width="100%">
                                                                    <tbody>
                                                                        <tr className="caption">
                                                                            <td style={{ textAlign: 'center' }}>CREATED</td>
                                                                            <td style={{ textAlign: 'center' }}>TRANSACTION #</td>
                                                                            <td>CLIENT NAME</td>
                                                                            <td>TRANSACTION STATUS</td>
                                                                            <td>FINANCE STATUS</td>
                                                                        </tr>
                                                                        {RecentViewData.length > 0 ?
                                                                            RecentViewData.map((item, index) => {
                                                                                return (
                                                                                    <tr key={index} style={{ display: RowHideShow }}>
                                                                                        <td style={{ textAlign: 'center' }}>{item.created}</td>
                                                                                        <td style={{ textAlign: 'center' }}>
                                                                                            {/* onClick={() => { refreshPage(item.trascation_id) }} */}
                                                                                            <Link to={"/transaction/create_transaction/" + item.trascation_id} >
                                                                                                {item.trascation_id}
                                                                                            </Link>
                                                                                        </td>
                                                                                        <td>{item.client_name}</td>
                                                                                        <td>{item.transction_status}</td>
                                                                                        <td>{item.finance_status}</td>
                                                                                    </tr>

                                                                                );
                                                                            })
                                                                            :

                                                                            <tr><td style={{ textAlign: "center" }} colSpan={5}><em>Please select a system type</em></td></tr>
                                                                        }
                                                                       
                                                                        { dashboard_hide_show_flag == 0 ?
                                                                        <tr style={{ display: AddNewButton }}>
                                                                            <td style={{ textAlign: 'left' }} colSpan={5}>
                                                                                PLEASE SELECT A SYSTEM TYPE
                                                                            </td>
                                                                        </tr>
                                                                        : null }

                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>

                                        : null}

                                </tbody>
                            </table>
                        </td>
                        <td>
                            <table border="0" cellPadding={0} cellSpacing={0} width="178" bgcolor="#ffffff">
                                <tbody>
                                    <tr>
                                        <td>

                                        </td>
                                        {/* <td className="heading-search-bg" height="29" width="178" style={{textAlign: 'center'}}>
                        <img src={Heading_news} height="29"/>
                    </td>  */}
                                    </tr>
                                </tbody>
                            </table>
                        </td>

                    </tr>
                </tbody>
            </table>

            {/* <table width="100%" height="14" cellPadding={0} cellSpacing={0} style={{position: 'fixed', bottom: '0px', left: '0px'}}>
                <tbody>
                    <tr>
                        <td width="100%" height="14" className="footer-img">
                             <div dangerouslySetInnerHTML={ {__html:FooterData} } />
                        </td>
                    </tr>
                </tbody>
            </table> */}
            <Footer />

        </div>
    </>
}

export default Home