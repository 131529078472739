import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Branch_details() {
    document.title = "Administration | Seriti - Group Admin";
    const navigate = useNavigate();
    const { division } = useParams();
    const { department } = useParams();

    const [sDepartment, setsDepartment] = useState([])

    const [branchName, setBranchName] = useState('')

    const [name, setComapnyName] = useState('');
    const [merchant_name, setMerchantName] = useState('')
    const [merchant_code, setMerchantCode] = useState('')
    const [abbreviation, setAbbre] = useState('')
    const [company_registration_number, setRegNo] = useState('')
    const [vat_number, setVatNo] = useState('')
    const [physical_address, setPhysicalAddr] = useState('')
    const [postal_address, setPostalAddr] = useState('')
    const [office_number, setOfficeNo] = useState('')
    const [fax_number, setFaxNo] = useState('')
    const [website_address, setWebAddr] = useState('')
    const [email, setEmailAddr] = useState('')
    const [bank, setBank] = useState('')
    const [branch_number, setBranchNo] = useState('')
    const [account_name, setAccHolder] = useState('')
    const [account_number, setAccNo] = useState('')
    const [account_type1, setAccType] = useState('')

    // console.log(account_type1)
    //-----------------------view branch details-----------------------//
    useEffect(() => {
        const payload = {
            division: division,
            department: department
        };
        axios
            .post("/admin/affiliates/view_branch", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data.data.branch_details)
                    setsDepartment(response.data.data.sDepartment);
                    setBranchName(response.data.data.branchName)

                    var obj = response.data.data.branch_details
                    setComapnyName(obj.name)
                    setMerchantName(obj.merchant_name)
                    setMerchantCode(obj.merchant_code)
                    setAbbre(obj.abbreviation)
                    setRegNo(obj.company_registration_number)
                    setVatNo(obj.vat_number)
                    setPhysicalAddr(obj.physical_address)
                    setPostalAddr(obj.postal_address)
                    setOfficeNo(obj.office_number)
                    setFaxNo(obj.fax_number)
                    setWebAddr(obj.website_address)
                    setEmailAddr(obj.email)
                    setBank(obj.bank)
                    setBranchNo(obj.branch_number)
                    setAccHolder(obj.account_name)
                    setAccNo(obj.account_number)
                    setAccType(obj.account_type1)
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                alert(response.response.data.data);
            });
    }, [division, department]);
    // console.log(department)

    const handleValidation = () => {
        var flag = true;
        if (name === '' || company_registration_number === '' || email === '') {
            alert('Please fill in all the fields!');
            flag = false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            alert('Please enter a valid email address');
            flag = false;
        }
        return flag;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        if (flag) {
            const payload = {
                division: division,
                department: department,
                company_name: name,
                merchant_name: merchant_name,
                merchant_code: merchant_code,
                abbreviation: abbreviation,
                company_registration_number: company_registration_number,
                vat_number: vat_number,
                physical_address: physical_address,
                postal_address: postal_address,
                office_number: office_number,
                fax_number: fax_number,
                website_address: website_address,
                email: email,
                bank: bank,
                branch_number: branch_number,
                account_holder: account_name,
                account_number: account_number,
                account_type1: account_type1
            };

            axios
                .post("/admin/group_admin/branch_details", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data);
                        navigate('/admin/group_admin/branch_defaults/' + division + '/' + department);
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    alert(response.response.data.data);
                    if (response.response.data.status === 400)
                        toast.error(response.response.data.data, {
                            position: toast.POSITION.TOP_CENTER
                        });
                });
        }
    };

    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Admin_menu />
            <form method="post" onSubmit={handleSubmit} encType="multipart/form-data" >
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">
                                <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center"><p></p>
                                                <table className="defaultTbl">
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan="2" className="text-center">Edit {sDepartment} Details for {branchName}</th>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Company Name<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="text" name="name" value={name} onChange={(e) => { setComapnyName(e.target.value) }} className="text-uppercase" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Merchant Name</td>
                                                            <td><input type="text" name="merchant_name" value={merchant_name} onChange={(e) => { setMerchantName(e.target.value) }} className="text-uppercase" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Merchant Code</td>
                                                            <td><input type="text" name="merchant_code" className="text-uppercase" value={merchant_code} onChange={(e) => { setMerchantCode(e.target.value) }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Abbreviation</td>
                                                            <td><input type="text" name="abbreviation" className="text-uppercase" value={abbreviation} onChange={(e) => { setAbbre(e.target.value) }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Registration Number<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="text" name="company_registration_number" className="text-uppercase" value={company_registration_number} onChange={(e) => { setRegNo(e.target.value) }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">VAT Number</td>
                                                            <td><input type="text" name="vat_number" className="text-uppercase" value={vat_number} onChange={(e) => { setVatNo(e.target.value) }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Physical Address</td>
                                                            <td><textarea name="physical_address" className="text-uppercase" cols="50" rows="4" value={physical_address} onChange={(e) => { setPhysicalAddr(e.target.value) }}></textarea></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Postal Address</td>
                                                            <td><textarea name="postal_address" className="text-uppercase" cols="50" rows="4" value={postal_address} onChange={(e) => { setPostalAddr(e.target.value) }}></textarea></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Office Phone</td>
                                                            <td><input type="text" id="phone" className="text-uppercase" name="office_number" value={office_number} onChange={(e) => { setOfficeNo(e.target.value) }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Fax Number</td>
                                                            <td><input type="text" id="fax" className="text-uppercase" name="fax_number" value={fax_number} onChange={(e) => { setFaxNo(e.target.value) }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Web Address</td>
                                                            <td><input type="text" name="website_address" className="text-uppercase" value={website_address} onChange={(e) => { setWebAddr(e.target.value) }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Email Address<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="text" name="email" className="text-uppercase" value={email} onChange={(e) => { setEmailAddr(e.target.value) }} /></td>
                                                        </tr>
                                                        <tr className="caption">
                                                            <td colSpan="2" style={{ textAlign: "center" }}>{sDepartment} Banking Details</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Bank</td>
                                                            <td><input type="text" name="bank" className="text-uppercase" value={bank} onChange={(e) => { setBank(e.target.value) }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Branch Code</td>
                                                            <td><input type="text" name="branch_number" className="text-uppercase" value={branch_number} onChange={(e) => { setBranchNo(e.target.value) }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Account Holder</td>
                                                            <td><input type="text" className="text-uppercase" name="account_holder" value={account_name} onChange={(e) => { setAccHolder(e.target.value) }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Account Number</td>
                                                            <td><input type="text" className="text-uppercase" name="account_number" value={account_number} onChange={(e) => { setAccNo(e.target.value) }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Account Type</td>
                                                            <td><select name="account_type" className="text-uppercase" onChange={(e) => { setAccType(e.target.value) }} value={account_type1}>
                                                                {/* account_type1 */}
                                                                <option value={0}>- - Select a Type - -</option>
                                                                <option value={1} >Cheque</option>
                                                                <option value={2}>Credit</option>
                                                                <option value={3}>Current</option>
                                                                <option value={4}>Savings</option>
                                                                <option value={5}>Transmission</option>
                                                            </select></td>
                                                        </tr>
                                                        <tr className="caption">
                                                            <td colSpan="2" style={{ textAlign: "center" }}>
                                                                <input type="submit" name="command" value="Save" />
                                                                <Link to={'/admin/group_admin/branch_defaults/' + division + '/' + department}>
                                                                    <input type="button" value="Back" /></Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr><tr>
                                            <td align="center">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );

}

export default Branch_details