
import React, { useState, useEffect, useRef } from "react";
import Transaction_menu from "../Transaction/Transaction_menu";
import axios from "../axios"
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../Components/Loader";
import { NumericFormat } from 'react-number-format';

function Inception() {
    const transaction_id = localStorage.getItem("transaction_id");
    const division_id = localStorage.getItem("division_id");
    const department_id = localStorage.getItem("department_id");
    const system_type = localStorage.getItem("system_type");
    const user_id = localStorage.getItem("UserId");
    const super_user = localStorage.getItem("super_user");
    const Modules = localStorage.getItem("Modules");
    const Modules_info = localStorage.getItem("Modules_info");
    const Levels = localStorage.getItem("Levels");
    const Access = localStorage.getItem("Access");
    const unincept_flag = parseInt(localStorage.getItem("unincept_flag"));

    const aTitles = { '': '', 0: '', 1: 'Adv', 2: 'Dr', 3: 'Hon', 4: 'Miss', 5: 'Mr', 6: 'Mrs', 7: 'Prof', 8: 'Rev' };

    //use refs
    const FirstNameRef = useRef("");
    const LastnameRef = useRef("");
    const IdTypeRef = useRef("");
    const IdNumberRef = useRef(null);
    // console.log(unincept_flag);
    const [Command, setCommand] = useState('')
    const [InceptionDate, setInceptionDate] = useState('')
    const [InceptionDateFlag, setInceptionDateFlag] = useState(0)
    const [Sighted, setSighted] = useState('')
    const [TransactionType, setTransactionType] = useState('')
    const [SaleType, setSaleType] = useState('');

    const [AllSalesReps, setAllSalesReps] = useState([]);
    const [SalesReps, setSalesReps] = useState('');
    const [SalesRepsName, setSalesRepsName] = useState('');

    const [AllBussMarketer, setAllBussMarketer] = useState([]);
    const [BussMarketer, setBussMarketer] = useState('');
    const [BussMarketerName, setBussMarketerName] = useState('');

    const [AllFinanceType, setAllFinanceType] = useState([]);
    const [FinanceType, setFinanceType] = useState('');
    const [FinanceTypeName, setFinanceTypeName] = useState('');

    const [CustTypeId, setCustTypeId] = useState('')
    const [CustTypeName, setCustTypeName] = useState('')

    const [aClientDetails, setClientDetails] = useState([]);

    const [CashTransaction, setCashTransaction] = useState(false)
    const [InterestRate, setInterestRate] = useState('');
    const [InterestType, setInterestType] = useState('');

    const [AllSalesStatusArr, setAllSalesStatusArr] = useState([])
    const [SalesStatus, setSalesStatus] = useState('');
    const [SalesStatusName, setSalesStatusName] = useState('');

    const [AllTransactionFinanceStatus, setAllTransactionFinanceStatus] = useState([])
    const [TransactionFinanceStatusId, setTransactionFinanceStatusId] = useState('');
    const [TransactionFinanceStatusName, setTransactionFinanceStatusName] = useState('');

    const [AllFinanceTerm, setAllFinanceTerm] = useState([])
    const [FinanceTermId, setFinanceTermId] = useState('');
    const [FinanceTermName, setFinanceTermName] = useState('');

    const [sCurrency, setsCurrency] = useState('');

    const [AllFinanceCompany, setAllFinanceCompany] = useState([])
    const [FinanceCompanyId, setFinanceCompanyId] = useState('');
    const [FinanceCompanyName, setFinanceCompanyName] = useState('');

    const [FinanceAccountNumber, setFinanceAccountNumber] = useState('');
    const [InsuraceAccountNo, setInsuraceAccountNo] = useState('');
    const [PreArrangedFinanceName, setPreArrangedFinanceName] = useState('');
    const [PreArrangedFinanceChecked, setPreArrangedFinanceChecked] = useState('');

    const [SchemeDealName, setSchemeDealName] = useState('');
    const [SchemeDealChecked, setSchemeDealChecked] = useState('');

    const [InsuranceCompanyField, setInsuranceCompanyField] = useState('');
    // console.log(InsuranceCompanyField);
    const [InsuranceCompanyFieldName, setInsuranceCompanyFieldName] = useState('');
    const [ComprehensiveInsurance, setComprehensiveInsurance] = useState('');
    const [DocInvoiceNumber, setDocInvoiceNumber] = useState('')
    const [StockNumber, setStockNumber] = useState('')

    const [Deposit, setDeposit] = useState('')
    const [Discount, setDiscount] = useState('')
    const [BankDocFee, setBankDocFee] = useState('')
    const [ResidualBalloonValue, setResidualBalloonValue] = useState('')
    const [ShareCompanyDetails, setShareCompanyDetails] = useState('')
    const [SharePartners, setSharePartners] = useState('')
    const [ReceiveMarketingInfo, setReceiveMarketingInfo] = useState('')

    const [VehicleDetailsFlag, setVehicleDetailsFlag] = useState(false);

    const [VehicleDetails, setVehicleDetails] = useState([])
    const [VehicleId, setVehicleId] = useState(0)

    const [VehicleCondition, setVehicleCondition] = useState(0)
    const [VehicleUse, setVehicleUse] = useState(0)
    const [FirstRegDate, setFirstRegDate] = useState('')
    const [FirstRegDateFlag, setFirstRegDateFlag] = useState(0)
    const [OdometerReading, setOdometerReading] = useState('')
    const [RegNumber, setRegNumber] = useState('')
    const [VinNumber, setVinNumber] = useState('')
    const [EngineNumber, setEngineNumber] = useState('')
    const [VehicleColor, setVehicleColor] = useState('')
    const [SellingPrice, setSellingPrice] = useState('')

    const [BusinessFlag, setBusinessFlag] = useState(false);
    const [RsaIdFlag, setRsaIdFlag] = useState(true);
    const [PassportFlag, setPassportFlag] = useState(true);
    const [OmangFlag, setOmangFlag] = useState(true);
    const [CashDealFlag, setCashDealFlag] = useState(true);

    const [ProductSummary, setProductSummary] = useState([])
    const [aMakeDetails, setaMakeDetails] = useState([])
    // const [TransactionStatus, setTransactionStatus] = useState('');

    const [sInceptButton, setsInceptButton] = useState('')
    const [sSaveButton, setsSaveButton] = useState('')
    const [sPrintNoWording, setsPrintNoWording] = useState('')
    const [sPrintButton, setsPrintButton] = useState('')
    const [sSaveInceptionDate, setsSaveInceptionDate] = useState('')

    const [ReloadFlag, setReloadFlag] = useState(true);

    const [hidTransactionType, setiTransactionType] = useState('');
    const [hidOwnershipPercentage, setiOwnershipPercentage] = useState('');

    const [loader, setLoader] = useState(false);
    const [HeaderFlag, setHeaderFlag] = useState(1);
    //const [PdfLink, setPdfLink] = useState('')
    //console.log(CashTransaction);

    useEffect(() => {
        document.title = 'Transactions | Seriti BW';

    }, []);
    // console.log(VehicleDetails);
    //--------------------getting initial data--------------------------------------//
    useEffect(() => {
        if (ReloadFlag) {
            setLoader(true);
            const payload = {
                transaction_id: transaction_id,
                system_type: system_type,
                department_id: department_id,
                division_id: division_id,
                user_id: user_id,
                super_user: super_user,
                Modules: Modules,
                Modules_info: Modules_info,
                Levels: Levels,
                Access: Access
            }
            axios
                .post("get_transactions_details", payload)
                .then((response) => {
                    // console.log(response.data.data.rClientDetails);
                    setiTransactionType(response.data.data.iTransactionType)
                    setiOwnershipPercentage(response.data.data.ownership_percentage)
                    setaMakeDetails(response.data.data.aMakeDetails)
                    setProductSummary(response.data.data.rResult)

                    var client = response.data.data.rClientDetails;
                    setShareCompanyDetails(client.share_company_details)
                    setSharePartners(client.share_amongst_partners)
                    setReceiveMarketingInfo(client.receive_marketing_info)

                    setsCurrency(response.data.data.sCurrency)

                    // console.log(response.data.data.rVehicles.length);
                    if (response.data.data.rVehicles.length > 0) {
                        setVehicleDetailsFlag(true)
                        setVehicleDetails(response.data.data.rVehicles[0])
                        var vehicle = response.data.data.rVehicles[0];
                        setVehicleId(vehicle.id)
                        setVehicleCondition(vehicle.condition)
                        setVehicleUse(vehicle.vehicle_use)
                        setFirstRegDate(vehicle.first_reg_date)
                        if (vehicle.first_reg_date) setFirstRegDateFlag(1);
                        setOdometerReading(vehicle.odometer_reading)
                        setRegNumber(vehicle.registration_number)
                        setVinNumber(vehicle.vin_number)
                        setEngineNumber(vehicle.engine_number)
                        setVehicleColor(vehicle.colour)
                        setSellingPrice((vehicle.amount !== null) ? parseFloat(vehicle.amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "0.00")
                    }

                    setAllSalesReps(response.data.data.aSalesRepsArr);
                    setAllBussMarketer(response.data.data.rFIUsersArr);
                    setAllFinanceType(response.data.data.aFinanceTypesArr)
                    setCustTypeId(response.data.data.sTypeID)
                    if (response.data.data.sTypeID === 2) //private
                    {
                        // $('.business').hide(); //hide business only rows
                        setBusinessFlag(false)
                    } else {
                        setBusinessFlag(true)
                    }
                    setCustTypeName(response.data.data.sTypeName)
                    setClientDetails(response.data.data.rClientDetails)

                    // console.log(response.data.data.rClientDetails);
                    // omang / rsa id / passport
                    if (response.data.data.rClientDetails.id_document_type === '1') //omang
                    {
                        setRsaIdFlag(false)//$('.rsa_id').hide();
                        setPassportFlag(false)//$('.passport').hide();
                    }
                    else if (response.data.data.rClientDetails.id_document_type === '3') //rsa id
                    {
                        setOmangFlag(false)//$('.omang').hide();
                        setPassportFlag(false)//$('.passport').hide();
                    }
                    else if (response.data.data.rClientDetails.id_document_type === '2')//passport
                    {
                        setRsaIdFlag(false)//$('.rsa_id').hide();
                        setOmangFlag(false)//$('.omang').hide();
                    }
                    else {
                        setPassportFlag(false)//$('.passport').hide();
                        setRsaIdFlag(false)//$('.rsa_id').hide();
                        setOmangFlag(false)//$('.omang').hide();
                    }

                    setAllFinanceTerm(response.data.data.aFinanceTermArray)
                    setFinanceTermId(response.data.data.sSelectedId)
                    setFinanceTermName(response.data.data.sSelectedName)
                    setAllFinanceCompany(response.data.data.FinanceCompanyArr)
                    setFinanceCompanyId(response.data.data.FinanceCompanyId)
                    setFinanceCompanyName(response.data.data.FinanceCompanyName)
                    setAllSalesStatusArr(response.data.data.aSalesStatusArr)
                    setAllTransactionFinanceStatus(response.data.data.aFinanceStatusesArr)
                    //transaction array
                    var obj = response.data.data.trans_details[0];
                    // console.log(obj);
                    setInceptionDate(obj.sInceptionDate)
                    if (obj.sInceptionDate !== "") setInceptionDateFlag(1)
                    setSighted(obj.sighted)
                    setTransactionType(obj.sTransactionType)
                    setSaleType(obj.sale_type)
                    setSalesReps(obj.sSalesReps)
                    setSalesRepsName(obj.sSalesRepsName)
                    setBussMarketer(obj.sFandI)
                    setBussMarketerName(obj.sFandIName)
                    setFinanceType(obj.sFinanceTypeSelect)
                    if (obj.sFinanceTypeSelect === 1) {
                        setCashDealFlag(false);//$("#cash_deal").parent().parent().hide();
                    }

                    setFinanceTypeName(obj.sFinanceTypeSelectName)
                    setCashTransaction(obj.sCashChecked ? true : false)
                    setInterestRate(obj.interest)
                    setInterestType(obj.interest_type)
                    setSalesStatus(obj.sSalesStatus)
                    setSalesStatusName(obj.sSalesStatusName)
                    setTransactionFinanceStatusId(obj.sTransactionFinanceStatusId)
                    setTransactionFinanceStatusName(obj.sTransactionFinanceStatusName)
                    setFinanceAccountNumber(obj.sAccountNumber)
                    setPreArrangedFinanceName(obj.sPreArrangedFinanceName)
                    setPreArrangedFinanceChecked(obj.sPreArrangedFinanceChecked)

                    setSchemeDealName(obj.sSchemeDealName)
                    setSchemeDealChecked(obj.sSchemeDealChecked)

                    obj.insurance_comp !== null ? setInsuranceCompanyField(obj.insurance_comp) : setInsuranceCompanyField("");
                    obj.insurance_comp !== null ? setInsuranceCompanyFieldName(obj.insurance_comp) : setInsuranceCompanyFieldName("");
                    
                    //setInsuraceAccountNo(obj.insurance_acc_no)
                    obj.insurance_acc_no !== null ? setInsuraceAccountNo(obj.insurance_acc_no) : setInsuraceAccountNo("");
                    setComprehensiveInsurance(obj.comp_ins_arranged_by)
                   // setDocInvoiceNumber(obj.doc_invoice_num)
                    obj.doc_invoice_num !== null ? setDocInvoiceNumber(obj.doc_invoice_num) : setDocInvoiceNumber("");
                    obj.stock_number !== null ? setStockNumber(obj.stock_number) : setStockNumber("");
                    setDeposit((obj.cash_cheque_eft_deposit !== null) ? parseFloat(obj.cash_cheque_eft_deposit).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "0.00")
                    setDiscount((obj.discount !== null) ? parseFloat(obj.discount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "0.00")
                    setBankDocFee((obj.bank_doc_fee !== null) ? parseFloat(obj.bank_doc_fee).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "0.00")
                    setResidualBalloonValue((obj.residual_balloon_value !== null) ? parseFloat(obj.residual_balloon_value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "0.00")

                    // console.log(response.data.data.rights);
                    if (obj.status !== 3) {
                        setsInceptButton(<input type="submit" name="command" value="Incept" onClick={(e) => setCommand('Incept')} />);
                        setsSaveButton(<input type="submit" name="command" value="Save" onClick={(e) => setCommand('Save')} />);
                        setsPrintNoWording('');
                        setsPrintButton('');
                        setsSaveInceptionDate('');
                    }
                    else {
                        setsInceptButton('');
                        setsSaveButton('');
                        setsPrintButton(<input type="submit" name="command" value="Print" onClick={(e) => setCommand('Print')} />);
                        setsPrintNoWording(<input type="submit" name="command" value="Print No Wording" onClick={(e) => setCommand('Print No Wording')} />);
                        setsSaveInceptionDate('');

                        if (response.data.data.rights)
                            setsSaveInceptionDate(<input type="submit" name="command" value="Save Inception Date" onClick={(e) => setCommand('Save Inception Date')} />);
                    }
                    setReloadFlag(false)
                    setLoader(false);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch(() => {
                    setLoader(false);
                });
        }
    }, [transaction_id, system_type, department_id, division_id, user_id, super_user, Modules, Modules_info, Levels, Access, ReloadFlag]);
    // console.log(aTitles[aClientDetails.company_type]);
    // const addCommas1 = (el, setState) => {
    //     var el2 = parseFloat(el).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    //     setState(el2);
    // }

    const ChangeCashTransaction = (FType) => {
        // alert(FType)
        if (parseInt(FType) === 1) {
            setCashDealFlag(false);
            setCashTransaction(false);
        } else {
            setCashDealFlag(true);
            setCashTransaction(false);
        }
    }
    // console.log(SaleType);
    //---------------------handle validation------------------------//
    const validateRegistration = () => {
        var regNum = RegNumber;
        var trNum = transaction_id;
        regNum = regNum.replace(/\s/g, '');
        if (regNum.length > 0) {
            if (regNum.length < 7) {
                alert('Please enter a valid registration number');
                return false;
            }
            if (Number(trNum) !== Number('21975')) 			// added just for 1 transaction on 09-03-2021
            {
                if (regNum.substr(0, 1) !== 'B') {
                    alert('First letter of the registration number should be a B');
                    return false;
                }

                var numbers = regNum.substr(1, 3);
                var regNumbers = new RegExp("^[0-9][0-9][0-9]$");
                if (regNumbers.test(numbers) === false) {
                    alert('Invalid registration number: first 3 characters after B should be numbers');
                    return false;
                }
            }
            if (regNum.length > 9) {
                alert('Please enter a valid registration number');
                return false;
            }
            return true;
        } else {
            alert('Please enter a vehicle registration number');
            return false;
        }
        // return true;
    }

    const showPopupMessage = () => {
        var showPopup;
        if (showPopup === '0') {
            var cash_deal = CashTransaction;

            // TASK 106: MIS Report discussion
            var financeStatus = TransactionFinanceStatusId
            if (!cash_deal) {
                var financeCompany = FinanceCompanyId;
                if (financeStatus <= 0 || financeCompany <= 0) {
                    alert("Please select Transaction Finance Status and Finance Company");
                    return false;
                }
            }
            if (financeStatus === 3 || financeStatus === 15 || financeStatus === 16 || financeStatus === 18 || financeStatus === 19 || financeStatus === 20) {
                alert("Unable to incept a transaction. Transaction Finance Status selected is Declined or Not Taken up");
                return false;
            }
            // TASK 106: MIS Report discussion

            if (!cash_deal)
                alert("Please ensure that you have uploaded the FINANCE CONTRACT, DELIVERY RECIEPT and all OTHER RELEVENT DOCUMENTS.");
        }

        // var iProdId = document.getElementById("hidProdId").value;
        //$("#hidProdId").val();
        var iProdId = 0;

        if (iProdId === 100) {
            var iSellingPrice = SellingPrice;
            if (iSellingPrice !== undefined) {
                var nSellingPrice = iSellingPrice.replace(/(\d)(?=(\d{3})+(?!\d))/g, "");
                nSellingPrice = parseFloat(nSellingPrice).toFixed(2);
                if (nSellingPrice >= 1500000)
                    alert("Refer to BIC for product premium");
            }
        }
        showPopup = '1';
        return true;
    }

    // const handleAmount = (amt, sState) => {
    //     //     console.log(amt);
    //     var s = sState;
    //     if (amt === "0.00") s('');
    // }
    // const handleBlurAmount = (amt, sState) => {
    //     var s = sState;
    //     if (amt === "") s('0.00');
    //     else {
    //         amt = amt.toString();

    //         var pattern = /(-?\d+)(\d{1})/;
    //         // console.log(pattern.test(amt));
    //         while (pattern.test(amt))
    //             amt = parseFloat(amt).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    //         sState(amt)
    //     }
    // }

    const handleValidation = () => {
        var flag = true;
        // console.log(FirstNameRef.current.value);
        if (Command === 'Incept') {

            var err = 'Please complete the following fields:\n';

            if (FirstNameRef.current.value === '') err += '- First Names\n';
            if (LastnameRef.current.value === '') err += '- Last Name\n';
            if (IdTypeRef.current.value === '<em>None Selected</em>') {
                err += '- ID Type\n';
            }
            else {
                if (IdNumberRef.current) {
                    if (IdNumberRef.current.value) {
                        switch (IdTypeRef.current.value) {
                            case 'OMANG': err += '- OMANG Number\n'; break; //omang
                            case 'PASSPORT': err += '- PASSPORT Number\n'; break; //passport
                            case 'RSA ID': err += '- RSA ID Number\n'; break; //national id
                            default: err += ''; break;
                        }
                    }
                }

            }
            // document.getElementById('comp_ins_arranged_by').value
            if (!CashTransaction) {
                if (FinanceType === 0) err += '- Finance Type\n';
                if (FinanceCompanyId === 0) err += '- Finance Company\n';
                if (FinanceAccountNumber === '') err += '- Finance Account Number\n';
                if (InsuranceCompanyField === '') err += '- Insurance Company\n';
                if (InsuraceAccountNo === '') err += '- Insurance Account Number\n';
                if (ComprehensiveInsurance) if (ComprehensiveInsurance.length === 0) err += '- Comprehensive Insurance Arranged\n';
            }

            if (StockNumber) {
                if (StockNumber.length > 0) {
                    //return true;
                }
            }
            else err += '- Stock Number\n';

            if (Deposit === '') err += '- Deposit\n';
            if (DocInvoiceNumber === '') err += '- DOC Invoice Number\n';
            if (FirstRegDate === '') err += '- First Registration Date\n';

            if (VehicleCondition) {
                if (VehicleCondition.length === 1) {
                    var condition = VehicleCondition;
                    var odometer = OdometerReading;
                    switch (condition) {
                        case '1':
                            {
                                if (odometer === '') setOdometerReading(0);
                                break;
                            }
                        case '2':
                        case '3':
                            {
                                if (parseInt(odometer) > 0) { }
                                else err += '- Odometer Reading must be bigger than 0\n';
                                break;
                            }
                        default:
                    }
                }
            }

            if (VinNumber) if (VinNumber.length < 5) err += '- Vehicle Chassis Number\n';
            if (EngineNumber) if (EngineNumber.length < 5) err += '- Vehicle Engine Number\n';
            if (VehicleColor === '') err += '- Vehicle Colour\n';
            if (BankDocFee === '') err += '- Bank Documentation Fee\n';
            if (ShareCompanyDetails) if (ShareCompanyDetails.length === 0) err += '- Consent to share details within company\n';
            if (SharePartners) if (SharePartners.length === 0) err += '- Consent to share details amongst partners\n';
            if (ReceiveMarketingInfo) if (ReceiveMarketingInfo.length === 0) err += '- Consent to receive marketing information\n';

            if (err.length > 40) {
                alert(err);
                flag = false;
            }
            else {
                var bReturn = validateRegistration(); //alert("validateRegistration : " + bReturn)
                if (bReturn) {
                    var check = showPopupMessage(); //alert("showPopupMessage : " + check)
                    if (check) {
                        var iTransactionType = hidTransactionType;
                        //$('#hid_transaction_type').val();
                        var iOwnershipPercentage = hidOwnershipPercentage;
                        //$('#hid_ownership_percentage').val();	// check if percentage of ownership is 100%
                        if (parseInt(iTransactionType) === 1) {
                            if (parseInt(iOwnershipPercentage) === 100) {
                                flag = true;
                            }
                            else {
                                alert("Percentage of Company ownership does not add up to 100%.");
                                flag = true;
                            }
                        }
                        else {
                            flag = true;
                        }
                    }
                    else {
                        flag = false;
                    }
                } else {
                    flag = false;
                }
            }
        }//Incept Command
        if (Command === 'Save Inception Date' && InceptionDate === '') {
            alert('Inception Date should not be empty');
            flag = false;
        }

        return flag;
    };
    // console.log(SaleType);
    //---------------submit data------------------------------------//
    const handleSubmit = (e) => {
        // alert(SaleType)
        e.preventDefault();
        const flag = handleValidation();
        if (flag) {
            // if (Command === 'Print' || Command === 'Print1' || Command === 'Print2' || Command === 'Print No Wording') {
             alert("Please ensure that you have uploaded the FINANCE CONTRACT, DELIVERY RECIEPT and all OTHER RELEVENT DOCUMENTS.");
            setLoader(true);
            // }
          
            const payload = {
                command: Command,
                user_id: user_id,
                //save inception date
                transaction_id: transaction_id,
                incepted_date: InceptionDate !== '' ? moment(InceptionDate).format("YYYY-MM-DD") : 0,
                //save
                sale_type: SaleType,
                finance_account_number: FinanceAccountNumber,
                finance_company: FinanceCompanyId,
                sales_rep: SalesReps,
                fi: BussMarketer,
                // advice_user : 
                cash_deal: CashTransaction ? 1 : 0,
                interest: InterestRate,
                interest_type: InterestType,
                finance_term: FinanceTermId,
                pre_arranged_finance: PreArrangedFinanceChecked ? 1 : 0,
                scheme_deal: SchemeDealChecked ? 1 : 0,
                finance_status: TransactionFinanceStatusId,
                insurance_comp: InsuranceCompanyField,
                insurance_acc_no: InsuraceAccountNo,
                comp_ins_arranged_by: ComprehensiveInsurance,
                doc_invoice_num: DocInvoiceNumber,
                stock_number: StockNumber,
                residual_balloon_value: ResidualBalloonValue,
                finance_type: FinanceType,
                // commencement_date : 
                bank_doc_fee: BankDocFee,
                // first_installment_amount :
                sales_status: SalesStatus,
                // decline_motor_comp_quote : 
                share_company_details: ShareCompanyDetails,
                share_amongst_partners: SharePartners,
                receive_marketing_info: ReceiveMarketingInfo,
                //vehicle details
                vehicle_id: VehicleId,
                vehicle_condition: VehicleCondition,
                vehicle_use: VehicleUse,
                first_reg_date: FirstRegDate !== '' ? moment(FirstRegDate).format("YYYY-MM-DD") : 0,
                odometer_reading: OdometerReading,
                registration_number: RegNumber,
                vin_number: VinNumber,
                engine_number: EngineNumber,
                colour: VehicleColor,
                amount: SellingPrice,
                deposit: Deposit,
                discount: Discount,
                system_type: system_type,
                division_id: division_id
            };

            if (Command === 'Print' || Command === 'Print1' || Command === 'Print2' || Command === 'Print No Wording') {
                axios
                    .post("fetch_inception_pdf", payload)
            
                    .then((response) => { setLoader(false);
                        if (response.data.status === 200) {
                            setLoader(false);
                            toast.success(response.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            // const url = window.URL.createObjectURL(new Blob([response.data.data.url]));
                            const url = response.data.data.url
                            const link = document.createElement('a');
                            link.href = url;
                            link.target="_blank"
                            link.setAttribute('download', response.data.data.filename); //or any other extension
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                    .catch((response) => {
                        setLoader(false);
                        if (response.response.data.status === 400)
                            toast.error(response.response.data.data, {
                                position: toast.POSITION.TOP_CENTER
                            });
                    });
            } else {
                axios
                    .post("handle_inception", payload)
                    .then((response) => {
                        if (response.data.status === 200) {
                            // alert(response.data.message)
                            toast.success(response.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            if (Command === 'Incept' && unincept_flag === 0) localStorage.setItem("unincept_flag", 1);

                            setReloadFlag(true)
                            setCommand('')
                            setLoader(false)
                            // console.log(response.data);
                            setHeaderFlag(2)
                        }
                    })
                    .catch((response) => {
                        setLoader(false)
                        if (response.response.data.status === 400)
                            toast.error(response.response.data.data, {
                                position: toast.POSITION.TOP_CENTER
                            });
                    });
            }
        }
    }

   // let panFlag = 1;

    const RemoveZeroVal = (e) => {
        // console.log(e);
        if(e === 1 && SellingPrice === '0.00'){ setSellingPrice("") }
        if(e === 2 && Deposit === '0.00'){ setDeposit("") }
        if(e === 3 && Discount === '0.00'){ setDiscount("") }
        if(e === 4 && BankDocFee === '0.00'){ setBankDocFee("") }
        if(e === 5 && ResidualBalloonValue === '0.00'){ setResidualBalloonValue("") }
    }

    const ShowZero = (e) => {
        // alert('dd');
        if(e === 1 && SellingPrice === ''){ setSellingPrice("0.00") } 
        if(e === 2 && Deposit === ''){ setDeposit("0.00") }
        if(e === 3 && Discount === ''){ setDiscount("0.00") }
        if(e === 4 && BankDocFee === ''){ setBankDocFee("0.00") }
        if(e === 5 && ResidualBalloonValue === ''){ setResidualBalloonValue("0.00") }
    }

    // console.log(InceptionDate);
    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Transaction_menu panel_flag={HeaderFlag}/>
            {loader ? <Loader /> : null}
            <div>
                <table width="100%" height="1024" id="mainContainer" border="0" cellPadding={0} cellSpacing={0} className="custom-body bg-white">
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">
                                <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                                <table width="100%" border="0" cellPadding={5} cellSpacing={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center">
                                                <form method="post" name="new_user" id="new_user" onSubmit={handleSubmit} >

                                                    {/* <a href={PdfLink} target="_balnk" download>link</a> */}
                                                    <table width="650" className="defaultTbl tablesorter" align="center" cellSpacing={1}>
                                                        <tbody>
                                                            <tr>
                                                                <th colSpan={2} style={{ textAlign: 'center' }}>INCEPT TRANSACTION</th>
                                                            </tr>

                                                            {/* INCEPTION DATE */}
                                                            {unincept_flag === 1 && (
                                                                <tr>
                                                                    <td className="caption" width="100">INCEPTION DATE</td>
                                                                    <td>
                                                                        {/* <input type="date" name="inception_date" value={InceptionDate} onChange={(e) => setInceptionDate(e.target.value)} format="yyyy'mm-dd" /> */}
                                                                        {InceptionDateFlag === 1 ?
                                                                            <DatePicker name="inception_date" value={InceptionDate} onChange={(date) => { setInceptionDate(date); setInceptionDateFlag(0) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                            :
                                                                            <DatePicker name="inception_date" selected={InceptionDate} onChange={(date) => setInceptionDate(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )}

                                                            {/* sighted */}
                                                            <tr>
                                                                <td className="caption" width="100">IS THIS DEAL SIGHTED?
                                                                {/* <span style={{ color: "red" }}> *</span> */}
                                                                </td>
                                                                <td>{Sighted}</td>
                                                            </tr>

                                                            {/* transaction type */}
                                                            <tr>
                                                                <td className="caption" width="100">TRANSACTION TYPE
                                                                {/* <span style={{ color: "red" }}> *</span> */}
                                                                </td>
                                                                {(unincept_flag === 1 && TransactionType === 'Aftermarket') ?
                                                                    <td>
                                                                        <input type="radio" name="sale_type" value="1" onChange={(e) => { setSaleType(1) }} defaultChecked={(SaleType === 1) ? true : null} disabled /> &nbsp; Aftermarket &nbsp;&nbsp;
                                                                    </td> : null
                                                                }
                                                                {(unincept_flag == 1 && (TransactionType == 'VEHICLE' || TransactionType == 'FLEET')) ?
                                                                    <td>
                                                                        <input type="radio" name="sale_type" value="2" onChange={(e) => { setSaleType(2) }} checked={(SaleType === 2) ? true : null} disabled /> &nbsp; Vehicle &nbsp;&nbsp;
                                                                        <input type="radio" name="sale_type" value="1" onChange={(e) => { setSaleType(1) }} checked={(SaleType == 1) ? true : null} disabled />  &nbsp; Fleet &nbsp;&nbsp;
                                                                    </td> : null
                                                                }
                                                                {unincept_flag === 0 && TransactionType === 'Aftermarket' && (
                                                                    <td><input type="radio" name="sale_type" value="1" onChange={(e) => { setSaleType(1) }} defaultChecked={(SaleType === 1) ? true : null} /> Aftermarket</td>
                                                                )}
                                                                {unincept_flag == 0 && (TransactionType == 'VEHICLE' || TransactionType == 'FLEET')  && (
                                                                    <td><input type="radio" name="sale_type" value="2" onChange={(e) => { setSaleType(2) }} checked={(SaleType == 2) ? true : null} /> Vehicle 
                                                                       &nbsp; <input type="radio" name="sale_type" value="1" onChange={(e) => { setSaleType(1) }} checked={(SaleType == 1) ? true : null} /> Fleet</td>

                                                                )}
                                                            </tr>

                                                            {/* sale person */}
                                                            <tr>
                                                                <td className="caption" nowrap="nowrap" width="100">SALES PERSON
                                                                {/* <span style={{ color: "red" }}> *</span> */}
                                                                </td>
                                                                {unincept_flag === 1 ?
                                                                    <td>{SalesRepsName}</td>
                                                                    :
                                                                    <td>
                                                                        <select name="sales_rep" id="sales_rep" className="text-uppercase" value={SalesReps} onChange={(e) => setSalesReps(e.target.value)} style={{ width: '239px' }} >
                                                                            <option value="0">-- Select Sales Rep --</option>
                                                                            {AllSalesReps.map((item) => {
                                                                                return (
                                                                                    <option key={item.id} value={item.id} >
                                                                                        {item.user_name}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </td>
                                                                }
                                                            </tr>

                                                            {/* business marketer */}
                                                            <tr>
                                                                <td className="caption">BUSINESS MANAGER/MARKETER
                                                                {/* <span style={{ color: "red" }}> *</span> */}
                                                                </td>
                                                                {unincept_flag === 1 ?
                                                                    <td>{BussMarketerName}</td>
                                                                    :
                                                                    <td><select name="fi" id="fi" className="text-uppercase" value={BussMarketer} onChange={(e) => setBussMarketer(e.target.value)} style={{ width: '239px' }} >
                                                                        <option value="0">-- Select Marketer --</option>
                                                                        {AllBussMarketer.map((item) => {
                                                                            return (
                                                                                <option key={item.id} value={item.id} >
                                                                                    {item.user_name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                    </td>
                                                                }
                                                            </tr>

                                                            {/* finance type */}
                                                            <tr>
                                                                <td className="caption">FINANCE TYPE
                                                                {/* <span style={{ color: "red" }}> *</span> */}
                                                                </td>
                                                                {unincept_flag === 1 ?
                                                                    <td>{FinanceTypeName}</td>
                                                                    :
                                                                    <td><select name="finance_type" id="finance_type" className="text-uppercase" value={FinanceType} onChange={(e) => setFinanceType(e.target.value)} onClick={(e) => ChangeCashTransaction(e.target.value)} style={{ width: '239px' }} >
                                                                        <option value="0">-- Select Sales Rep --</option>
                                                                        {AllFinanceType.map((item) => {
                                                                            return (
                                                                                <option key={item.id} value={item.id} >
                                                                                    {item.name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select></td>
                                                                }
                                                            </tr>

                                                            {/* client details */}
                                                            <tr>
                                                                <th colSpan={2} style={{ textAlign: 'center' }}>CLIENT DETAILS</th>
                                                            </tr>

                                                            {/* Customer type */}
                                                            <tr>
                                                                <td className="caption">Customer Type</td>
                                                                <td>{CustTypeName}</td>
                                                            </tr>
                                                            {(BusinessFlag) && (
                                                                <tr className="business">
                                                                    <td className="caption">Company Name</td>
                                                                    <td>{aClientDetails.name}</td>
                                                                </tr>
                                                            )}
                                                            {(BusinessFlag) && (
                                                                <tr className="business">
                                                                    <td className="caption">Trading as</td>
                                                                    <td>{aClientDetails.abbreviation}</td>
                                                                </tr>
                                                            )}
                                                            {(BusinessFlag) && (
                                                                <tr className="business">
                                                                    <td className="caption">Company Registration Number</td>
                                                                    <td>{aClientDetails.company_registration_number}</td>
                                                                </tr>
                                                            )}
                                                            {(BusinessFlag) && (
                                                                <tr className="business">
                                                                    <td className="caption">VAT NUMBER</td>
                                                                    <td>{aClientDetails.vat_number}</td>
                                                                </tr>
                                                            )}

                                                            {/* if ({aClientDetails.'type_id'} == 2) */}
                                                            {(CustTypeId === 2) && (
                                                                <tr>
                                                                    <td className="caption">Title</td>
                                                                    <td>{aTitles[aClientDetails.company_rprtitle]}</td>
                                                                </tr>
                                                            )}
                                                            {(CustTypeId === 2) && (
                                                                <tr>
                                                                    <td className="caption">First Names</td>
                                                                    <td><input type="hidden" id="firstnames" value={aClientDetails.name} ref={FirstNameRef} className="text-uppercase" />{aClientDetails.name}</td>
                                                                </tr>
                                                            )}
                                                            {(CustTypeId === 2) && (
                                                                <tr>
                                                                    <td className="caption">Preferred Name</td>
                                                                    <td>{aClientDetails.description}</td>
                                                                </tr>
                                                            )}
                                                            {(CustTypeId === 2) && (
                                                                <tr><td className="caption">Last Name</td>
                                                                    <td><input type="hidden" id="lastname" value={aClientDetails.abbreviation} ref={LastnameRef} className="text-uppercase" />{aClientDetails.abbreviation}</td>
                                                                </tr>
                                                            )}
                                                            {(CustTypeId === 2) && (
                                                                <tr>
                                                                    <td className="caption">Initials</td>
                                                                    <td>{aClientDetails.initials}</td>
                                                                </tr>
                                                            )}
                                                            {(CustTypeId === 2) && (
                                                                <tr>
                                                                    <td className="caption">ID TYPE</td>
                                                                    <td><input type="hidden" id="id_type" value={aClientDetails.id_document_type} ref={IdTypeRef} className="text-uppercase" />{aClientDetails.id_document_type_name}</td>
                                                                </tr>
                                                            )}
                                                            {/* else */}
                                                            {(CustTypeId !== 2) && (
                                                                <tr>
                                                                    <td className="caption">Number of years in business</td>
                                                                    <td>{aClientDetails.years_in_business}</td>
                                                                </tr>
                                                            )}
                                                            {(CustTypeId !== 2) && (
                                                                <tr>
                                                                    <td className="caption">Nature of business</td>
                                                                    <td>{aClientDetails.nature_of_business}</td>
                                                                </tr>
                                                            )}
                                                            {(CustTypeId !== 2) && (
                                                                <tr>
                                                                    <td className="caption">Land lord (If not owner of property)</td>
                                                                    <td>{aClientDetails.land_lord_name}</td>
                                                                </tr>
                                                            )}
                                                            {(CustTypeId !== 2) && (
                                                                <tr>
                                                                    <td className="caption">Holding company</td>
                                                                    <td>{aClientDetails.holding_company}</td>
                                                                </tr>
                                                            )}
                                                            {(CustTypeId !== 2) && (
                                                                <tr>
                                                                    <td className="caption">Associated company/ies</td>
                                                                    <td>{aClientDetails.associated_companies}</td>
                                                                </tr>
                                                            )}
                                                            {(CustTypeId !== 2) && (
                                                                <tr>
                                                                    <td className="caption">Subsidiary Company</td>
                                                                    <td>{aClientDetails.subsidiary_company}</td>
                                                                </tr>
                                                            )}
                                                            {(CustTypeId !== 2) && (
                                                                <tr>
                                                                    <td className="caption">Company Representative</td>
                                                                    <td>{aClientDetails.description}</td>
                                                                </tr>
                                                            )}
                                                            {/* close else */}

                                                            {OmangFlag && (
                                                                <tr className="omang">
                                                                    <td className="caption national">ID NUMBER</td>
                                                                    <td><input type="hidden" name="id_number" id="id_number" value={aClientDetails.company_registration_number} ref={IdNumberRef} className="text-uppercase" />{aClientDetails.company_registration_number}</td>
                                                                </tr>
                                                            )}
                                                            {RsaIdFlag && (
                                                                <tr className="rsa_id">
                                                                    <td className="caption">RSA ID NUMBER</td>
                                                                    <td>{aClientDetails.company_registration_number}</td>
                                                                </tr>
                                                            )}
                                                            {RsaIdFlag && (
                                                                <tr className="rsa_id">
                                                                    <td className="caption">RSA ID ISSUE DATE</td>
                                                                    <td>{aClientDetails.passport_issue}</td>
                                                                </tr>
                                                            )}
                                                            {PassportFlag && (
                                                                <tr className="passport">
                                                                    <td className="caption">Passport Number</td>
                                                                    <td>{aClientDetails.company_registration_number}</td>
                                                                </tr>
                                                            )}

                                                            {/* if ({aClientDetails.'type_id'} == 2) */}
                                                            {(CustTypeId === 2) && (
                                                                <tr>
                                                                    <td className="caption">DATE OF BIRTH</td>
                                                                    <td>{aClientDetails.date_of_birth}</td>
                                                                </tr>
                                                            )}
                                                            {(CustTypeId === 2) && (
                                                                <tr>
                                                                    <td className="caption">Gender</td>
                                                                    <td>{aClientDetails.gender}</td>
                                                                </tr>
                                                            )}
                                                            {/* close if */}

                                                            <tr>
                                                                <td className="caption">Work Number</td>
                                                                <td>{aClientDetails.office_number}</td>
                                                            </tr>

                                                            <tr>
                                                                <td className="caption">Mobile Number</td>
                                                                <td>{aClientDetails.cell_number}</td>
                                                            </tr>

                                                            {/* if ({aClientDetails.'type_id'} == 2) */}
                                                            {(CustTypeId === 2) && (
                                                                <tr>
                                                                    <td className="caption">Home Number</td>
                                                                    <td>{aClientDetails.home_number}</td>
                                                                </tr>
                                                            )}
                                                            {/* close if */}

                                                            <tr>
                                                                <td className="caption">Fax Number</td>
                                                                <td>{aClientDetails.fax_number}</td>
                                                            </tr>

                                                            <tr>
                                                                <td className="caption">Email</td>
                                                                <td>{aClientDetails.email}</td>
                                                            </tr>

                                                            {(CashDealFlag && unincept_flag === 0) && (
                                                                <tr>
                                                                    <td className="caption">Cash Transaction</td>
                                                                    <td><input type="checkbox" name="cash_deal" id="cash_deal" value={CashTransaction} onChange={(e) => { setCashTransaction(!CashTransaction) }} checked={CashTransaction} /></td>
                                                                </tr>
                                                            )}

                                                            {!CashTransaction && (
                                                                <tr id="not_cash_pref_int">
                                                                    <td className="caption">Preferred Interest Rate</td>
                                                                    {unincept_flag === 1 ?
                                                                        <td>{InterestRate} %</td>
                                                                        :
                                                                        <td><input type="text" name="interest" id="interest" value={InterestRate} size="5" pref="1" onChange={(e) => setInterestRate(e.target.value)} className="text-uppercase" /> %</td>
                                                                    }
                                                                </tr>
                                                            )}

                                                            {!CashTransaction && (
                                                                <tr id="not_cash_int_rate">
                                                                    <td className="caption">Interest Type</td>
                                                                    {
                                                                        (unincept_flag === 1) ?
                                                                            <td>
                                                                                <input type="radio" name="interest_type" value="1" onChange={(e) => { setInterestType(e.target.value) }} defaultChecked={(InterestType === 1) ? true : null} disabled /> &nbsp; Fixed &nbsp;&nbsp;
                                                                                <span show="BW ZA">
                                                                                    <input type="radio" name="interest_type" value="2" onChange={(e) => { setInterestType(e.target.value) }} defaultChecked={(InterestType === 2) ? true : null} disabled />  &nbsp; Linked &nbsp;&nbsp;
                                                                                </span>
                                                                            </td> : null
                                                                    }
                                                                    {
                                                                        (unincept_flag === 0) ?
                                                                            <td>
                                                                                <input type="radio" name="interest_type" value="1" onChange={(e) => { setInterestType(e.target.value) }} defaultChecked={(InterestType === 1) ? true : null} /> &nbsp; Fixed &nbsp;&nbsp;
                                                                                <span show="BW ZA">
                                                                                    <input type="radio" name="interest_type" value="2" onChange={(e) => { setInterestType(e.target.value) }} defaultChecked={(InterestType === 2) ? true : null} />  &nbsp; Linked &nbsp;&nbsp;
                                                                                </span>
                                                                            </td> : null
                                                                    }
                                                                </tr>
                                                            )}

                                                            <tr>
                                                                <th colSpan={2} style={{ textAlign: 'center' }}>TRANSACTION DETAILS</th>
                                                            </tr>

                                                            <tr>
                                                                <td className="caption">Sales Status</td>
                                                                {unincept_flag === 1 ?
                                                                    <td>{SalesStatusName}</td>
                                                                    :
                                                                    <td>
                                                                        <select name="sales_status" id="sales_status" className="text-uppercase" value={SalesStatus} onChange={(e) => setSalesStatus(e.target.value)} style={{ width: '239px' }} >
                                                                            <option value="0">-- SELECT A STATUS --</option>
                                                                            
                                                                            {AllSalesStatusArr.map((item) => {
                                                                                return (
                                                                                    <option key={item.id} value={item.id} >
                                                                                        {item.name}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </td>
                                                                }
                                                            </tr>

                                                            {/* <tr className="not_cash_applicable"> */}
                                                            <tr>
                                                                <td className="caption">Transaction Finance Status {!CashTransaction && <span style={{ color: "red" }} > *</span>}</td>
                                                                {unincept_flag === 1 ?
                                                                    <td>{TransactionFinanceStatusName}</td>
                                                                    :
                                                                    <td>
                                                                        <select name="transaction_finance_status" id="transaction_finance_status" className="text-uppercase" value={TransactionFinanceStatusId} onChange={(e) => setTransactionFinanceStatusId(e.target.value)} style={{ width: '239px' }} >
                                                                            <option value="0">-- SELECT A FINANCE STATUS --</option>
                                                                            {AllTransactionFinanceStatus.map((item) => {
                                                                                return (
                                                                                    <option key={item.id} value={item.id} >
                                                                                        {item.name}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </td>
                                                                }
                                                            </tr>


                                                            {!CashTransaction && (
                                                                <tr id="not_cash_fin_term">
                                                                    <td className="caption">Finance Term</td>
                                                                    {unincept_flag === 1 ?
                                                                        <td>{FinanceTermName}</td>
                                                                        :
                                                                        <td>
                                                                            <select name="finance_term" id="finance_term" className="text-uppercase" value={FinanceTermId} onChange={(e) => setFinanceTermId(e.target.value)} style={{ width: '239px' }} >
                                                                                <option value="0">-- Select Sales Rep --</option>
                                                                                {AllFinanceTerm.map((item) => {
                                                                                    return (
                                                                                        <option key={item.id} value={item.id} >
                                                                                            {item.name}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            )}

                                                            {!CashTransaction && (
                                                                <tr id="not_cash_fin_comp">
                                                                    <td className="caption">Finance Company {!CashTransaction && <span style={{ color: "red" }}> *</span>}</td>
                                                                    {unincept_flag === 1 ?
                                                                        <td>{FinanceCompanyName}</td>
                                                                        :
                                                                        <td>
                                                                            <select name="finance_company" id="finance_company" className="text-uppercase" value={FinanceCompanyId} onChange={(e) => setFinanceCompanyId(e.target.value)} style={{ width: '239px' }} >
                                                                                <option value="0">-- Select Finance Company --</option>
                                                                                {AllFinanceCompany.map((item) => {
                                                                                    return (
                                                                                        <option key={item.value} value={item.value} >
                                                                                            {item.text}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            )}

                                                            {!CashTransaction && (
                                                                <tr id="not_cash_fin_acc_num">
                                                                    <td className="caption">Finance Account number</td>
                                                                   
                                                                    {unincept_flag === 1 ?
                                                                        <td>{FinanceAccountNumber}</td>
                                                                        :
                                                                        <td><input type="text" value={FinanceAccountNumber} name="finance_account_number" onChange={(e) => setFinanceAccountNumber(e.target.value)} className="text-uppercase" /></td>
                                                                    }
                                                                
                                                                </tr>
                                                            )}

                                                            {!CashTransaction && (
                                                                <tr id="not_cash_pre_arr_fin">
                                                                    <td className="caption">Pre-arranged Finance</td>
                                                                    {unincept_flag === 1 ?
                                                                        <td>{PreArrangedFinanceName}</td>
                                                                        :
                                                                        <td><input type="checkbox" name="pre_arranged_finance" id="pre_arranged_finance" value={PreArrangedFinanceChecked} onChange={(e) => { setPreArrangedFinanceChecked(!PreArrangedFinanceChecked) }} checked={PreArrangedFinanceChecked} /></td>
                                                                    }
                                                                </tr>
                                                            )}

                                                            {!CashTransaction && (
                                                                <tr id="not_cash_fin_deal">
                                                                    <td className="caption">Finance Deal Scheme</td>
                                                                    {unincept_flag === 1 ?
                                                                        <td>{SchemeDealName}</td>
                                                                        :
                                                                        <td><input type="checkbox" name="scheme_deal" id="scheme_deal" value={SchemeDealChecked} onChange={(e) => { setSchemeDealChecked(!SchemeDealChecked) }} checked={SchemeDealChecked} /></td>
                                                                    }
                                                                </tr>
                                                            )}

                                                             <tr>
                                                                <td className="caption">Insurance Company</td>
                                                                {unincept_flag === 1 ?
                                                                    <td>{InsuranceCompanyFieldName}</td>
                                                                    :
                                                                    <td>
                                                                        <input type="text" id="insurance_comp" name="insurance_comp" className="text-uppercase" value={InsuranceCompanyField} onChange={(e) => setInsuranceCompanyField(e.target.value)} />
                                                                    </td>
                                                                }
                                                            </tr>

                                                           <tr>
                                                                <td className="caption">Insurance Account Number</td>
                                                                {
                                                                    unincept_flag === 1 ?
                                                                        <td>{InsuraceAccountNo}</td>
                                                                        :
                                                                        <td>
                                                                            <input type="text" id="insurance_acc_no" name="insurance_acc_no" className="text-uppercase" value={InsuraceAccountNo} onChange={(e) => setInsuraceAccountNo(e.target.value)} />
                                                                        </td>
                                                                }
                                                            </tr> 

                                                            <tr>
                                                                <td className="caption">Comprehensive Insurance</td>
                                                                {unincept_flag === 1 ?
                                                                    <td>
                                                                        <input type="radio" name="comp_ins_arranged_by" value="1" onChange={(e) => { setComprehensiveInsurance(1) }} defaultChecked={(ComprehensiveInsurance === 1) ? true : null} disabled />&nbsp;Customer Arranged<br />
                                                                        <input type="radio" name="comp_ins_arranged_by" value="2" onChange={(e) => { setComprehensiveInsurance(2) }} defaultChecked={(ComprehensiveInsurance === 2) ? true : null} disabled />&nbsp;Dealer Arranged<br />
                                                                        <input type="radio" name="comp_ins_arranged_by" value="3" onChange={(e) => { setComprehensiveInsurance(3) }} defaultChecked={(ComprehensiveInsurance === 3) ? true : null} disabled />&nbsp;Bank Arranged
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        <input type="radio" name="comp_ins_arranged_by" value="1" onChange={(e) => { setComprehensiveInsurance(1) }} defaultChecked={(ComprehensiveInsurance === 1) ? true : null} />&nbsp;Customer Arranged<br />
                                                                        <input type="radio" name="comp_ins_arranged_by" value="2" onChange={(e) => { setComprehensiveInsurance(2) }} defaultChecked={(ComprehensiveInsurance === 2) ? true : null} />&nbsp;Dealer Arranged<br />
                                                                        <input type="radio" name="comp_ins_arranged_by" value="3" onChange={(e) => { setComprehensiveInsurance(3) }} defaultChecked={(ComprehensiveInsurance === 3) ? true : null} />&nbsp;Bank Arranged
                                                                    </td>
                                                                }
                                                            </tr>

                                                            <tr>
                                                                <td className="caption">DOC Invoice Number</td>
                                                                {unincept_flag === 1 ?
                                                                    <td>{DocInvoiceNumber}</td>
                                                                    :
                                                                    <td><input type="text" id="doc_invoice_num" name="doc_invoice_num" value={DocInvoiceNumber} onChange={(e) => setDocInvoiceNumber(e.target.value)} className="text-uppercase" /></td>
                                                                }
                                                            </tr>

                                                            <tr>
                                                                <td className="caption">Stock Number</td>
                                                                {unincept_flag === 1 ?
                                                                    <td>{StockNumber}</td>
                                                                    :
                                                                    <td><input type="text" id="stock_number" name="stock_number" value={StockNumber} onChange={(e) => setStockNumber(e.target.value)} className="text-uppercase" /></td>
                                                                }
                                                            </tr>

                                                            <tr>
                                                                <th colSpan={2} style={{ textAlign: 'center' }}>VEHICLE DETAIL
                                                                </th>
                                                            </tr>
                                                            {VehicleDetailsFlag ?
                                                                <>
                                                                    <tr><td className="caption">Vehicle Code</td>
                                                                        <td className="caption"><input type="hidden" value={VehicleDetails.id} className="text-uppercase" />{VehicleDetails.code}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Vehicle Make</td>
                                                                        <td>{VehicleDetails.make}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Vehicle Model</td>
                                                                        <td>{VehicleDetails.model}</td>
                                                                    </tr>

                                                                    {aMakeDetails.length > 0 ?
                                                                        aMakeDetails.map((item, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td className="caption">{item.caption}</td>
                                                                                    <td>{((item.caption === 'Date Discontinued' && item.string_value === '') || (item.caption === 'Date Discontinued' && item.string_value === null)) ? 'TO CURRENT' : item.string_value}
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }) :
                                                                        <tr>
                                                                            <td colSpan={2}><em><em>There are no VAP products on this transaction.</em></em></td>
                                                                        </tr>
                                                                    }

                                                                    <tr>
                                                                        <td className="caption">Vehicle Condition</td>
                                                                        {unincept_flag === 1 ?
                                                                            <td>
                                                                                <input type="radio" name="vehicle_condition" value="1" onChange={(e) => { setVehicleCondition(1) }} defaultChecked={(VehicleCondition === 1) ? true : null} disabled />&nbsp;  New &nbsp;&nbsp;
                                                                                <input type="radio" name="vehicle_condition" value="2" onChange={(e) => { setVehicleCondition(2) }} defaultChecked={(VehicleCondition === 2) ? true : null} disabled />&nbsp;  Used &nbsp;&nbsp;
                                                                                <input type="radio" name="vehicle_condition" value="3" onChange={(e) => { setVehicleCondition(3) }} defaultChecked={(VehicleCondition === 3) ? true : null} disabled />&nbsp;  Demo
                                                                            </td>
                                                                            :
                                                                            <td>
                                                                                <input type="radio" name="vehicle_condition" value="1" onChange={(e) => { setVehicleCondition(1) }} defaultChecked={(VehicleCondition === 1) ? true : null} />   New&nbsp;&nbsp;
                                                                                <input type="radio" name="vehicle_condition" value="2" onChange={(e) => { setVehicleCondition(2) }} defaultChecked={(VehicleCondition === 2) ? true : null} />   Used&nbsp;&nbsp;
                                                                                <input type="radio" name="vehicle_condition" value="3" onChange={(e) => { setVehicleCondition(3) }} defaultChecked={(VehicleCondition === 3) ? true : null} />   Demo
                                                                            </td>
                                                                        }

                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Vehicle Use</td>
                                                                        {unincept_flag === 1 ?
                                                                            <td>
                                                                                <input type="radio" name="vehicle_use" value="1" onChange={(e) => { setVehicleUse(1) }} defaultChecked={(VehicleUse === 1) ? true : null} disabled /> &nbsp;Private&nbsp;&nbsp;
                                                                                <input type="radio" name="vehicle_use" value="2" onChange={(e) => { setVehicleUse(2) }} defaultChecked={(VehicleUse === 2) ? true : null} disabled />&nbsp; Business&nbsp;&nbsp;
                                                                                <input type="radio" name="vehicle_use" value="3" onChange={(e) => { setVehicleUse(3) }} defaultChecked={(VehicleUse === 3) ? true : null} disabled /> &nbsp;Taxi&nbsp;&nbsp;
                                                                                <input type="radio" name="vehicle_use" value="6" onChange={(e) => { setVehicleUse(6) }} defaultChecked={(VehicleUse === 6) ? true : null} disabled /> &nbsp;HCV
                                                                            </td>
                                                                            :
                                                                            <td>
                                                                                <input type="radio" name="vehicle_use" value="1" onChange={(e) => { setVehicleUse(1) }} defaultChecked={(VehicleUse === 1) ? true : null} /> &nbsp;Private&nbsp;&nbsp;
                                                                                <input type="radio" name="vehicle_use" value="2" onChange={(e) => { setVehicleUse(2) }} defaultChecked={(VehicleUse === 2) ? true : null} /> &nbsp;Business&nbsp;&nbsp;
                                                                                <input type="radio" name="vehicle_use" value="3" onChange={(e) => { setVehicleUse(3) }} defaultChecked={(VehicleUse === 3) ? true : null} /> &nbsp;Taxi&nbsp;&nbsp;
                                                                                <input type="radio" name="vehicle_use" value="6" onChange={(e) => { setVehicleUse(6) }} defaultChecked={(VehicleUse === 6) ? true : null} /> &nbsp;HCV
                                                                            </td>
                                                                        }
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">First Registration Date</td>
                                                                        {unincept_flag === 1 ?
                                                                            <td>{FirstRegDate}</td>
                                                                            :
                                                                            <td>
                                                                                {FirstRegDateFlag === 1 ?
                                                                                    <DatePicker id="end_date" name="end_date" value={FirstRegDate} onChange={(date) => { setFirstRegDate(date); setFirstRegDateFlag(0) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                                    :
                                                                                    <DatePicker id="end_date" name="end_date" selected={FirstRegDate} onChange={(date) => setFirstRegDate(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                                }
                                                                            </td>
                                                                        }
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Odometer Reading</td>
                                                                        {unincept_flag === 1 ?
                                                                            <td>{OdometerReading}</td>
                                                                            :
                                                                            <td><input type="text" name="odometer_reading" value={OdometerReading} onChange={(e) => setOdometerReading(e.target.value)} className="text-uppercase" /> KM</td>
                                                                        }
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Vehicle Registration Number</td>
                                                                        {unincept_flag === 1 ?
                                                                            <td>{RegNumber}</td>
                                                                            :
                                                                            <td><input type="text" name="registration_number" id="registration_number" value={RegNumber} onChange={(e) => setRegNumber(e.target.value)} className="text-uppercase" /></td>
                                                                        }
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Vehicle Chassis Number</td>
                                                                        {unincept_flag === 1 ?
                                                                            <td>{VinNumber}</td>
                                                                            :
                                                                            <td><input type="text" name="vin_number" value={VinNumber} onChange={(e) => setVinNumber(e.target.value)} className="text-uppercase" /></td>
                                                                        }
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Vehicle Engine Number</td>
                                                                        {unincept_flag === 1 ?
                                                                            <td>{EngineNumber}</td>
                                                                            :
                                                                            <td><input type="text" name="engine_number" value={EngineNumber} onChange={(e) => setEngineNumber(e.target.value)} className="text-uppercase" /></td>
                                                                        }
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Vehicle Colour</td>
                                                                        {unincept_flag === 1 ?
                                                                            <td>{VehicleColor}</td>
                                                                            :
                                                                            <td><input type="text" name="colour" value={VehicleColor} onChange={(e) => setVehicleColor(e.target.value)} className="text-uppercase" /></td>
                                                                        }
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Selling Price</td>
                                                                        {unincept_flag === 1 ?
                                                                            <td style={{ textAlign: "right" }}>{sCurrency} {SellingPrice}</td>
                                                                            :
                                                                            <td style={{ textAlign: "right" }}>{sCurrency}&nbsp;
                                                                            
                                                                                {/* <input type="text" style={{ textAlign: "right" }} name="amount" value={SellingPrice} onChange={(e) => setSellingPrice(e.target.value)} onClick={(e) => handleAmount(SellingPrice, setSellingPrice)} onBlur={(e) => handleBlurAmount(SellingPrice, setSellingPrice)} /> */}

                                                                                <NumericFormat type="text" name="amount" style={{textAlign: 'right'}}  value={SellingPrice} onChange={(e) => { setSellingPrice(e.target.value) }}  onClick={() => { RemoveZeroVal(1);}} onBlur={() => { ShowZero(1); }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" /> 

                                                                                <input type="hidden" name="hidSellingPrice" value={SellingPrice} />
                                                                            </td>
                                                                        }
                                                                    </tr>

                                                                    <tr id="not_cash_discount">
                                                                        <td className="caption">Deposit</td>
                                                                        {unincept_flag === 1 ?
                                                                            <td style={{ textAlign: "right" }}>{sCurrency} {Deposit}</td>
                                                                            :
                                                                            <td style={{ textAlign: "right" }}>{sCurrency}&nbsp;
                                                                            
                                                                                {/* <input type="text" style={{ textAlign: "right" }} name="deposit" value={Deposit} onChange={(e) => setDeposit(e.target.value)} onClick={(e) => handleAmount(Deposit, setDeposit)} onBlur={(e) => handleBlurAmount(Deposit, setDeposit)} /> */}
                                                                               
                                                                                <NumericFormat type="text" name="deposit" style={{textAlign: 'right'}}  value={Deposit} onChange={(e) => { setDeposit(e.target.value) }}  onClick={() => { RemoveZeroVal(2);}} onBlur={() => { ShowZero(2); }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" /> 
                                                                            </td>
                                                                        }
                                                                    </tr>

                                                                    <tr id="not_cash_discount">
                                                                        <td className="caption">Discount</td>
                                                                        {unincept_flag === 1 ?
                                                                            <td style={{ textAlign: "right" }}>{sCurrency} {Discount}</td>
                                                                            :
                                                                            <td style={{ textAlign: "right" }}>{sCurrency}&nbsp;
                                                                            
                                                                                {/* <input type="text" style={{ textAlign: "right" }} name="discount" value={Discount} onChange={(e) => setDiscount(e.target.value)} onClick={(e) => handleAmount(Discount, setDiscount)} onBlur={(e) => handleBlurAmount(Discount, setDiscount)} /> */}

                                                                                <NumericFormat type="text" name="discount" style={{textAlign: 'right'}}  value={Discount} onChange={(e) => { setDiscount(e.target.value) }}  onClick={() => { RemoveZeroVal(3);}} onBlur={() => { ShowZero(3); }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" /> 
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                </>
                                                                : null}

                                                            <tr className="caption"><td colSpan="2">&nbsp;</td></tr>

                                                            {/* < tr className="not_cash_applicable" > */}
                                                            <tr>
                                                                <td className="caption">Bank Documentation Fee</td>
                                                                {unincept_flag === 1 ?
                                                                    <td style={{ textAlign: "right" }}>{sCurrency} {BankDocFee}</td>
                                                                    :
                                                                    <td style={{ textAlign: "right" }}>{sCurrency} 
                                                                    
                                                                        {/* <input type="text" name="bank_doc_fee" value={BankDocFee} style={{ textAlign: "right" }} onChange={(e) => setBankDocFee(e.target.value)} onClick={(e) => handleAmount(BankDocFee, setBankDocFee)} onBlur={(e) => handleBlurAmount(BankDocFee, setBankDocFee)} /> */}

                                                                        <NumericFormat type="text" name="bank_doc_fee" style={{textAlign: 'right'}}  value={BankDocFee} onChange={(e) => { setBankDocFee(e.target.value) }}  onClick={() => { RemoveZeroVal(4);}} onBlur={() => { ShowZero(4); }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" /> 
                                                                    </td>
                                                                }
                                                            </tr >

                                                            <tr id="not_cash_residual">
                                                                <td className="caption">Residual / Balloon Value</td>
                                                                {unincept_flag === 1 ?
                                                                    <td style={{ textAlign: "right" }}>{sCurrency} {ResidualBalloonValue}</td>
                                                                    :
                                                                    <td style={{ textAlign: "right" }}>{sCurrency}&nbsp;
                                                                    
                                                                        {/* <input type="text" style={{ textAlign: "right" }} name="residual_balloon_value" value={ResidualBalloonValue} onChange={(e) => setResidualBalloonValue(e.target.value)} onClick={(e) => handleAmount(ResidualBalloonValue, setResidualBalloonValue)} onBlur={(e) => handleBlurAmount(ResidualBalloonValue, setResidualBalloonValue)} /> */}

                                                                        <NumericFormat type="text" name="residual_balloon_value" style={{textAlign: 'right'}}  value={ResidualBalloonValue} onChange={(e) => { setResidualBalloonValue(e.target.value) }}  onClick={() => { RemoveZeroVal(5);}} onBlur={() => { ShowZero(5); }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" /> 
                                                                    </td>
                                                                }
                                                            </tr>

                                                            <tr>
                                                                <td className="caption">Consent to share details within company</td>
                                                                {unincept_flag === 1 ?
                                                                    <td><input type="radio" name="share_company_details" value="1" onChange={(e) => { setShareCompanyDetails(1) }} defaultChecked={(ShareCompanyDetails === 1) ? true : null} disabled style={{ margin: "6px" }} />Yes
                                                                        <input type="radio" name="share_company_details" value="0" onChange={(e) => { setShareCompanyDetails(0) }} defaultChecked={(ShareCompanyDetails === 0) ? true : null} disabled style={{ margin: "6px" }} />No
                                                                    </td>
                                                                    :
                                                                    <td><input type="radio" name="share_company_details" value="1" onChange={(e) => { setShareCompanyDetails(1) }} defaultChecked={(ShareCompanyDetails === 1) ? true : null} style={{ margin: "6px" }} />Yes
                                                                        <input type="radio" name="share_company_details" value="0" onChange={(e) => { setShareCompanyDetails(0) }} defaultChecked={(ShareCompanyDetails === 0) ? true : null} style={{ margin: "6px" }} />No
                                                                    </td>
                                                                }
                                                            </tr>

                                                            <tr>
                                                                <td className="caption">Consent to share details amongst partners</td>
                                                                {unincept_flag === 1 ?
                                                                    <td><input type="radio" name="share_amongst_partners" value="1" onChange={(e) => { setSharePartners(1) }} defaultChecked={(SharePartners === 1) ? true : null} disabled style={{ margin: "6px" }} />Yes
                                                                        <input type="radio" name="share_amongst_partners" value="0" onChange={(e) => { setSharePartners(0) }} defaultChecked={(SharePartners === 0) ? true : null} disabled style={{ margin: "6px" }} />No
                                                                    </td>
                                                                    :
                                                                    <td><input type="radio" name="share_amongst_partners" value="1" onChange={(e) => { setSharePartners(1) }} defaultChecked={(SharePartners === 1) ? true : null} style={{ margin: "6px" }} />Yes
                                                                        <input type="radio" name="share_amongst_partners" value="0" onChange={(e) => { setSharePartners(0) }} defaultChecked={(SharePartners === 0) ? true : null} style={{ margin: "6px" }} />No
                                                                    </td>
                                                                }

                                                            </tr>

                                                            <tr>
                                                                <td className="caption">Consent to receive marketing information</td>
                                                                {unincept_flag === 1 ?
                                                                    <td><input type="radio" name="receive_marketing_info" value="1" onChange={(e) => { setReceiveMarketingInfo(1) }} defaultChecked={(ReceiveMarketingInfo === 1) ? true : null} disabled style={{ margin: "6px" }} />Yes
                                                                        <input type="radio" name="receive_marketing_info" value="0" onChange={(e) => { setReceiveMarketingInfo(0) }} defaultChecked={(ReceiveMarketingInfo === 0) ? true : null} disabled style={{ margin: "6px" }} />No
                                                                    </td>
                                                                    :
                                                                    <td><input type="radio" name="receive_marketing_info" value="1" onChange={(e) => { setReceiveMarketingInfo(1) }} defaultChecked={(ReceiveMarketingInfo === 1) ? true : null} style={{ margin: "6px" }} />Yes
                                                                        <input type="radio" name="receive_marketing_info" value="0" onChange={(e) => { setReceiveMarketingInfo(0) }} defaultChecked={(ReceiveMarketingInfo === 0) ? true : null} style={{ margin: "6px" }} />No
                                                                    </td>
                                                                }
                                                            </tr>

                                                            <tr>
                                                                <th colSpan={2} style={{ textAlign: 'center' }}>Products (VAPS) Summary
                                                                </th>
                                                            </tr>

                                                            {
                                                                ProductSummary.length > 0 ?
                                                                    ProductSummary.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td className="caption">{item.option}</td>
                                                                                <td style={{ textAlign: 'right' }}>{sCurrency} {item.price}</td>
                                                                            </tr>
                                                                        );
                                                                    }) :
                                                                    <tr>
                                                                        <td colSpan={2}><em><em>There are no VAP products on this transaction.</em></em></td>
                                                                    </tr>
                                                            }

                                                            <tr className="caption">
                                                                <td style={{ textAlign: 'center' }} colSpan="2">
                                                                    {sSaveButton}&nbsp;
                                                                    {sInceptButton}&nbsp;
                                                                    {/* <a href="/inception/pdf" target="_blank">{sPrintButton}</a>&nbsp; */}
                                                                    {sPrintButton}&nbsp;
                                                                    {sPrintNoWording}&nbsp;
                                                                    {sSaveInceptionDate}&nbsp;

                                                                    {/* <input type="submit" name="command" value="Print1" onClick={(e) => setCommand('Print1')} />
                                                                    &nbsp;
                                                                    
                                                                        <input type="submit" name="command" value="Print2" onClick={(e) => setCommand('Print2')} /> */}

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </form>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody></table>
            </div>
        </>
    )

}

export default Inception