//import { Link, useNavigate, useParams } from "react-router-dom"
import React, { useState, useEffect } from "react";
//import { useLocation } from "react-router";
// import Transaction_menu from "../Transaction/Transaction_menu";
import Roa_pdf from "../Transaction/Roa_pdf";
// import { PDFViewer } from '@react-pdf/renderer';

const Record_pdf = () => {

  
  

    return (
      <>
        {/* <Transaction_menu /> */}
        <div>
            {/* <Document file="somefile.pdf" onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
            </Document>
            <p>
            Page {pageNumber} of {numPages}
            </p> */}

    <Roa_pdf/>

        </div>
      </>
    );
}

export default Record_pdf;