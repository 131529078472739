import { Link, useNavigate, useParams } from "react-router-dom"
import React, { useState, useEffect, useRef } from "react";
//import { useLocation } from "react-router";
import Transaction_menu from "../Transaction/Transaction_menu";
import Footer from "../Dashboard/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import axios from "../axios";
import Loader from "../Components/Loader";

const Client_company_add_edit = () => {
   
   const navigate = useNavigate();
   let panFlag = 1;
   const [CustomerType, setCustomerType] = useState(1);
   const [CustomerTypeAll, setCustomerTypeAll] = useState([]);
   const [CompanyTitle, setCompanyTitle] = useState("");
   const [CompanyTitleAll, setCompanyTitleAll] = useState([]);
   const [CompanyRptrTitle, setCompanyRptrTitle] = useState("");
   const [CompanyRptrTitleAll, setCompanyRptrTitleAll] = useState([]);
   const [Gender, setGender] = useState("");
   const [GenderAll, setGenderAll] = useState([]);
   const [ApplicantClaim, setApplicantClaim] = useState("");
   const [ApplicantClaimAll, setApplicantClaimAll] = useState([]);
   const [PreferredStmtMethod, setPreferredStmtMethod] = useState("");
   const [PreferredStmtMethodAll, setPreferredStmtMethodAll] = useState([]);
   const [ResidencyStatus, setResidencyStatus] = useState(0);
   const [ResidencyStatusAll, setResidencyStatusAll] = useState([]);
   const [IdType, setIdType] = useState("");
   const [IdTypeAll, setIdTypeAll] = useState([]);
   const [ResidentialStatus, setResidentialStatus] = useState("");
   const [ResidentialStatusAll, setResidentialStatusAll] = useState([]);
   const [ClaimFreeGroup, setClaimFreeGroup] = useState("");
   const [ClaimFreeGroupAll, setClaimFreeGroupAll] = useState([]);
   const [HomeLanguage, setHomeLanguage] = useState("");
   const [HomeLanguageAll, setHomeLanguageAll] = useState([]);
   const [CorrespondanceLanguage, setCorrespondanceLanguage] = useState("");
   const [CorrespondanceLanguageAll, setCorrespondanceLanguageAll] = useState([]);
   const [CompanyReprNationality, setCompanyReprNationality] = useState("");
   const [CompanyReprNationalityAll, setCompanyReprNationalityAll] = useState([]);
   const [Nationality, setNationality] = useState("");
   const [NationalityAll, setNationalityAll] = useState([]);
   const [CountriesResidance, setCountriesResidance] = useState("");
   const [CountriesResidanceAll, setCountriesResidanceAll] = useState([]);
   const [CountriesOfIncorporation, setCountriesOfIncorporation] = useState("");
   const [CountriesOfIncorporationAll, setCountriesOfIncorporationAll] = useState([]);
   const [DriversLicenseIssueDate, setDriversLicenseIssueDate] = useState("");
   const [DriversLicenseIssueDateAll, setDriversLicenseIssueDateAll] = useState([]);
   const [CompanyType, setCompanyType] = useState("");
   const [CompanyTypeAll, setCompanyTypeAll] = useState([]);
   const [MarketingSource, setMarketingSource] = useState("");
   const [MarketingSourceAll, setMarketingSourceAll] = useState([]);
   const [NoOfClaims, setNoOfClaims] = useState("");
   const [NoOfClaimsAll, setNoOfClaimsAll] = useState([]);


   const [MaritalStatus, setMaritalStatus] = useState("");
   const [MaritalStatusAll, setMaritalStatusAll] = useState([]);
   const [HowMarried, setHowMarried] = useState("");
   const [HowMarriedAll, setHowMarriedAll] = useState([]);
   const [State1, setState1] = useState(0);
   const [StateAll1, setStateAll1] = useState([]);

   //console.log(DriversLicenseIssueDateAll);
   const [CompanyDetails, setCompanyDetails] = useState([]);

   const [RowHideShow, setRowHideShow] = useState("");
   const [RowPrivateHideShow, setRowPrivateHideShow] = useState("");
   const [ResHideShow, setResHideShow] = useState("none");
   const [MaritalHideShow, setMaritalHideShow] = useState("none");
   const [ApplicantClaimWiseHideShow, setApplicantClaimWiseHideShow] = useState("none");
   const [DurationStayPreCountryWiseHideShow, setDurationStayPreCountryWiseHideShow] = useState("none");

   const [CompanyName, setCompanyName] = useState("");
   const [abbreviation, setAbbreviation] = useState(""); // last name
   const [initials, setInitials] = useState("");
   const [description, setDescription] = useState("");
   const [compReprSurname, setCompReprSurname] = useState("");
   const [ComercialIdNumber, setComercialIdNumber] = useState("");
   const [Occupation, setOccupation] = useState("");
   const [CompanyRegistNumber, setCompanyRegistNumber] = useState("");
   const [VatNumber, setVatNumber] = useState("");
   const [YearsInBusiness, setYearsInBusiness] = useState("");
   const [NatureOfBusiness, setNatureOfBusiness] = useState("");
   const [LandLordName, setLandLordName] = useState("");
   const [HoldingCompany, setHoldingCompany] = useState("");
   const [AssociatedCompanies, setAssociatedCompanies] = useState("");
   const [SubsidiaryCompany, setSubsidiaryCompany] = useState("");
   const [IncomeTaxNumber, setIncomeTaxNumber] = useState("");
   const [DurationOfStay, setDurationOfStay] = useState("");
   const [PrevResCountry, setPrevResCountry] = useState("");
   const [OfficeNumber, setOfficeNumber] = useState("");
   const [CellNumber, setCellNumber] = useState("");
   const [HomeNumber, setHomeNumber] = useState("");
   const [FaxNumber, setFaxNumber] = useState("");
   const [UserEmail, setUserEmail] = useState("");
   const [PhysicalAddressLine1, setPhysicalAddressLine1] = useState("");
   const [PhysicalAddressLine2, setPhysicalAddressLine2] = useState("");
   const [PhysicalAddressCity, setPhysicalAddressCity] = useState("");
   const [PostalAddressLine1, setPostalAddressLine1] = useState("");
   const [PostalAddressLine2, setPostalAddressLine2] = useState("");
   const [PostalAddressCity, setPostalAddressCity] = useState("");
   const [DateCurrentAddress, setDateCurrentAddress] = useState("");
   const FinalDateCurrentAddress = DateCurrentAddress != '' ? moment(DateCurrentAddress).format("YYYY-MM-DD") : 0;
   const [DatePreviousAddress, setDatePreviousAddress] = useState("");
   const FinalDatePreviousAddress = DatePreviousAddress != '' ? moment(DatePreviousAddress).format("YYYY-MM-DD") : 0;
   const [WebAddress, setWebAddress] = useState("");
   const [GuarantorApplication, setGuarantorApplication] = useState(false);
   const [RegularDriver, setRegularDriver] = useState(false);
   const [TaxiOperator, setTaxiOperator] = useState(false);
   const [PublicDriversPermit, setPublicDriversPermit] = useState(false);
   const [MarriedDate, setMarriedDate] = useState("");
   const FinalMarriedDate = MarriedDate != '' ? moment(MarriedDate).format("YYYY-MM-DD") : 0;
   const [IdExpiryDate, setIdExpiryDate] = useState("");
   const FinalIdExpiryDate = IdExpiryDate != '' ? moment(IdExpiryDate).format("YYYY-MM-DD") : 0;
   // const FinalIdExpiryDate = new Date(IdExpiryDate).toLocaleDateString();
   const [dateFlag, setdateFlag] = useState(0);
   const [currentdateFlag, setcurrentdateFlag] = useState(0);
   const [previousdateFlag, setpreviousdateFlag] = useState(0);
   const [marrieddateFlag, setmarrieddateFlag] = useState(0);
   const [expirydateFlag, setexpirydateFlag] = useState(0);
   const [ClientCompSave, setClientCompSave] = useState("");

   const [startDate, setStartDate] = useState("");
   const date_of_birth = startDate != '' ? moment(startDate).format("YYYY-MM-DD") : 0;
   const [FieldtxtName, setFieldtxtName] = useState("ID NUMBER");

   //--------------- other data ----------------------
   const UserEmailRef = useRef();
   const firstNameRef = useRef();
   const lastName1Ref = useRef();
   const nationalityRef = useRef();
   const [nationalityColor, setnationalityColor] = useState("");


   const division_id = localStorage.getItem("division_id");
   const department_id = localStorage.getItem("department_id");
   const system_type = localStorage.getItem("system_type");
   const user_id = localStorage.getItem("UserId");
   const transaction_id = localStorage.getItem("transaction_id");
   const product_hidden_tp_id = localStorage.getItem("product_hidden_tp_id");

   // const maindate = new Date(startDate).toLocaleDateString();
   const [loader, setLoader] = useState(false);
   const url_data = useParams();

   useEffect(() => {
      document.title = 'Transactions | Seriti BW';
   }, []);

   const UserId = localStorage.getItem("UserId");
   const super_user = localStorage.getItem("super_user");
   const [flag, setFlag] = useState(false);
   const [ReadOnly, setReadOnly] = useState(false);
   //----------------check permission------------------
   // useEffect(() => {
   //    const payload = {
   //       UserId: UserId,
   //       super_user: super_user,
   //       module_name: "Products"
   //    }
   //    axios
   //       .post("getRights", payload)
   //       .then((response) => {
   //          if (response.data.status === 200) {
   //             if (response.data.data === 0) {//None
   //                alert(response.data.message); setFlag(false);
   //             } else if (response.data.data === 1) {//Read
   //                setFlag(true); setReadOnly(true);
   //             } else if (response.data.data === 2) {//Write
   //                setFlag(true); setReadOnly(false);
   //             } else if (response.data.data === 3) {//Modify
   //                setFlag(true); setReadOnly(false);
   //             } else if (response.data.data === 4) {//Admin
   //                setFlag(true); setReadOnly(false);
   //             }
   //          }
   //       })
   //       .catch(() => {
   //          //setLoginLoader(false);
   //       });
   // }, [UserId, super_user, navigate]);

   //------------------- Add /Edit client company 
   const handleValidation = () => {
      var flag = true;
      const regMatch = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(UserEmail);

      if (CustomerType == 2) {
         if (CompanyName == "") {
            alert("Please enter first name");
            firstNameRef.current.focus();
            flag = false;
         } else if (abbreviation == "") {
            alert("Please enter last name");
            lastName1Ref.current.focus();
            flag = false;
         }
      } else if (UserEmail == "") {
         alert("Please enter email address");
         UserEmailRef.current.focus();
         flag = false;
      } else if (!regMatch) {
         alert("Please enter a valid email address for this client");
         UserEmailRef.current.focus();
         flag = false;
      }

      if (Nationality == "") {
         alert("Please select NATIONALITY")
         nationalityRef.current.focus();
         setnationalityColor("red");
         flag = false;
      }

      return flag;
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      const flag = handleValidation();
      if (flag) {
         //setNameError("");
         if (ClientCompSave == "Save" || ClientCompSave == "Save and Continue") {
            const payload = {
               transaction_id: transaction_id,
               client_id: url_data.id,
               command: ClientCompSave,
               system_type: system_type,
               user_id: user_id,
               department_id: department_id,
               //division_id: division_id,
               type_id: CustomerType,
               company_name: CompanyName,
               abbreviation: abbreviation,
               vat_number: VatNumber,
               company_registration_number: CompanyRegistNumber,
               nationality: Nationality,
               comp_repr_nationality: CompanyReprNationality,
               incorporation_country: CountriesOfIncorporation,
               countries_country: CountriesResidance,
               company_type: CompanyType,
               company_rprtitle: CompanyRptrTitle,
               drivers_issue_date: DriversLicenseIssueDate,
               id_expiry_date: FinalIdExpiryDate,
               cfg: ClaimFreeGroup,
               residency_status: ResidencyStatus,
               prev_res_country: PrevResCountry,
               email: UserEmail,
               home_language: HomeLanguage,
               marital_status: MaritalStatus,
               id_type: IdType,
               how_married: HowMarried,
               taxi_operator: TaxiOperator,
               date_of_birth: date_of_birth,
               public_drivers_permit: PublicDriversPermit,
               correspondance_language: CorrespondanceLanguage,
               preferred_statement_method: PreferredStmtMethod,
               initials: initials,
               description: description,
               office_number: OfficeNumber,
               fax_number: FaxNumber,
               web_address: WebAddress,
               comp_repr_surname: compReprSurname,
               cell_number: CellNumber,
               home_number: HomeNumber,
               gender: Gender,
               physical_address_line1: PhysicalAddressLine1,
               physical_address_line2: PhysicalAddressLine2,
               physical_address_city: PhysicalAddressCity,
               postal_address_line1: PostalAddressLine1,
               postal_address_line2: PostalAddressLine2,
               postal_address_city: PostalAddressCity,
               occupation: Occupation,
               income_tax_number: IncomeTaxNumber,
               state: State1,
               residential_status: ResidentialStatus,
               regular_driver: RegularDriver,
               guarantor_application: GuarantorApplication,
               married_date: FinalMarriedDate,
               years_in_business: YearsInBusiness,
               nature_of_business: NatureOfBusiness,
               land_lord_name: LandLordName,
               holding_company: HoldingCompany,
               associated_companies: AssociatedCompanies,
               subsidiary_company: SubsidiaryCompany,
               claim_has_made: ApplicantClaim,
               comercial_id_number: ComercialIdNumber,
               referred_from: MarketingSource,
               province: 0,
               notes: "",
               date_at_current_address: FinalDateCurrentAddress,
               date_at_previous_address: FinalDatePreviousAddress,
               duration_of_stay: DurationOfStay,
               insolvent: 0,
               insolvency_date: 0,
               date_rehabilitated: 0,
               dispute_credit_bereau: 0,
               debt_counselling: 0,
               curatorship: 0,
               current_admin_order: 0,
               ethnic_group: 0,
               bee_expiration_date: 0,
               account_manager_id: 0,
               number_of_claims: NoOfClaims,
            };
            // console.log(payload);
            axios
               .post("add_edit_company/add_edit_company_details", payload)
               .then((response) => {
                  //console.log(response.data.message); 
                  if (response.status == 200) {
                     if (response.data.data.command == "Save") {
                        //  window.location.reload(false);  
                        toast.success(response.data.message, {
                           position: toast.POSITION.TOP_CENTER
                        });
                     } else if (response.data.data.command == "Save and Continue") {
                        navigate('/transaction/edit_transaction/' + response.data.data.transaction_id);
                     }
                  }
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
               })
               .catch(() => {
                  console.log('error');
               });
         }
      }
   };


   //----------- get how_married -----------------
   // useEffect(() => {
   //    axios
   //       .get("add_edit_company/featch_how_married")
   //       .then((response) => {
   //          if (response.status === 200) {
   //             setHowMarriedAll(response.data.data.how_married_array);
   //          }
   //       })
   //       .catch(() => {
   //          console.log('error');
   //       });
   // }, []);

   // useEffect(() => {
   //    axios
   //       .get("add_edit_company/featch_customer_type")
   //       .then((response) => {
   //          // console.log(response.data.data.customer_type_array);
   //          if (response.status === 200) {
   //             setCustomerTypeAll(response.data.data.customer_type_array);
   //          }
   //       })
   //       .catch(() => {
   //          console.log('error');
   //       });
   // }, []);

   useEffect(() => {
      axios
         .get("add_edit_company/featch_common_vehicle_dropdown")
         .then((response) => {
             //console.log(response.data.data.customer_type_array);
            if (response.status === 200) {
               setCustomerTypeAll(response.data.data.customer_type_array);
               setHowMarriedAll(response.data.data.how_married_array);
               setCompanyTitleAll(response.data.data.title_array);
               setCompanyRptrTitleAll(response.data.data.title_array);
               setGenderAll(response.data.data.gender_array);
               setApplicantClaimAll(response.data.data.applicant_claim_array);
               setResidencyStatusAll(response.data.data.residency_status_array);
               setPreferredStmtMethodAll(response.data.data.preferred_statement_method_array);
               setIdTypeAll(response.data.data.id_type_array);
               setResidentialStatusAll(response.data.data.residential_status);
               setNoOfClaimsAll(response.data.data.no_claim_array);
               setCompanyTypeAll(response.data.data.company_type_array);
            }
         })
         .catch(() => {
            console.log('error');
         });
   }, []);

   //----------- get company represitive / title -----------------
   // useEffect(() => {
   //    axios
   //       .get("add_edit_company/featch_titles")
   //       .then((response) => {
   //          // console.log(response.data.data.customer_type_array);
   //          if (response.status === 200) {
   //             setCompanyTitleAll(response.data.data.title_array);
   //             setCompanyRptrTitleAll(response.data.data.title_array);
   //          }
   //       })
   //       .catch(() => {
   //          console.log('error');
   //       });
   // }, []);

   //----------- get gender -----------------
   // useEffect(() => {
   //    axios
   //       .get("add_edit_company/featch_gender")
   //       .then((response) => {
   //          if (response.status === 200) {
   //             setGenderAll(response.data.data.gender_array);
   //          }
   //       })
   //       .catch(() => {
   //          console.log('error');
   //       });
   // }, []);

   //----------- get applicant claim -----------------
   // useEffect(() => {
   //    axios
   //       .get("add_edit_company/featch_applicant_claim")
   //       .then((response) => {
   //          if (response.status === 200) {
   //             setApplicantClaimAll(response.data.data.applicant_claim_array);
   //          }
   //       })
   //       .catch(() => {
   //          console.log('error');
   //       });
   // }, []);

   //----------- get residency status -----------------
   // useEffect(() => {
   //    axios
   //       .get("add_edit_company/featch_residency_status")
   //       .then((response) => {
   //          if (response.status === 200) {
   //             setResidencyStatusAll(response.data.data.residency_status_array);
   //          }
   //       })
   //       .catch(() => {
   //          console.log('error');
   //       });
   // }, []);

   //----------- get preferred Statement Method -----------------
   // useEffect(() => {
   //    axios
   //       .get("add_edit_company/featch_preferred_statement_method")
   //       .then((response) => {
   //          if (response.status === 200) {
   //             setPreferredStmtMethodAll(response.data.data.preferred_statement_method_array);
   //          }
   //       })
   //       .catch(() => {
   //          console.log('error');
   //       });
   // }, []);

   //----------- get id type -----------------
   // useEffect(() => {
   //    axios
   //       .get("add_edit_company/featch_type_id")
   //       .then((response) => {
   //          if (response.status === 200) {
   //             setIdTypeAll(response.data.data.id_type_array);
   //          }
   //       })
   //       .catch(() => {
   //          console.log('error');
   //       });
   // }, []);

   //----------- get residential_status -----------------
   // useEffect(() => {
   //    axios
   //       .get("add_edit_company/featch_residential_status")
   //       .then((response) => {
   //          if (response.status === 200) {
   //             setResidentialStatusAll(response.data.data.residential_status);
   //          }
   //       })
   //       .catch(() => {
   //          console.log('error');
   //       });
   // }, []);

   //----------- get claim_free_group -----------------
   useEffect(() => {
      axios
         .get("add_edit_company/featch_claim_free_group")
         .then((response) => {
            if (response.status === 200) {
               setClaimFreeGroupAll(response.data.data.claim_free_group);
            }
         })
         .catch(() => {
            console.log('error');
         });
   }, []);

   //----------- get no of claim -----------------
   // useEffect(() => {
   //    axios
   //       .get("add_edit_company/featch_no_of_claim")
   //       .then((response) => {
   //          // console.log(response);
   //          if (response.status === 200) {
   //             setNoOfClaimsAll(response.data.data.no_claim_array);
   //          }
   //       })
   //       .catch(() => {
   //          console.log('error');
   //       });
   // }, []);

   //----------- get home / correspondance language ( 2 array )-----------------
   useEffect(() => {
      const payload = {
         division_id: division_id,
      };

      axios
         .post("add_edit_company/featch_correspondace_language", payload)
         .then((response) => {
            if (response.status === 200) {
               setHomeLanguageAll(response.data.data.home_language);
               setCorrespondanceLanguageAll(response.data.data.correspondance_language);
            }
         })
         .catch(() => {
            console.log('error');
         });
   }, []);

   //-------------------- get Nationality,Company Repr Nationality,Countries Residance,Countries Of Incorporation   
   useEffect(() => {
      const payload = {
         division_id: division_id,
      };

      axios
         .post("add_edit_company/featch_company_repr_nationality", payload)
         .then((response) => {
            if (response.status === 200) {
               setCompanyReprNationalityAll(response.data.data.company_repr_nationality);
               setNationalityAll(response.data.data.company_repr_nationality);
               setCountriesResidanceAll(response.data.data.company_repr_nationality);
               setCountriesOfIncorporationAll(response.data.data.company_repr_nationality);
            }
         })
         .catch(() => {
            console.log('error');
         });
   }, []);

   //----------- get drivers license issue date-----------------
   useEffect(() => {
      axios
         .get("add_edit_company/featch_drivers_license_issue_date")
         .then((response) => {
            //console.log(response);
            if (response.status === 200) {
               setDriversLicenseIssueDateAll(response.data.data.drivers_license_issue_date);
            }
         })
         .catch(() => {
            console.log('error');
         });
   }, []);

   //----------- get company type-----------------
   // useEffect(() => {
   //    axios
   //       .get("add_edit_company/featch_company_type")
   //       .then((response) => {
   //          //console.log(response);
   //          if (response.status === 200) {
   //             setCompanyTypeAll(response.data.data.company_type_array);
   //          }
   //       })
   //       .catch(() => {
   //          console.log('error');
   //       });
   // }, []);

   //----------- get marketing source-----------------
   useEffect(() => {
      axios
         .get("add_edit_company/featch_marketing_source")
         .then((response) => {
            //console.log(response);
            if (response.status === 200) {
               setMarketingSourceAll(response.data.data.marketing_source);
            }
         })
         .catch(() => {
            console.log('error');
         });
   }, []);

   //----------- get marital status-----------------
   useEffect(() => {
      const payload = {
         division_id: division_id,
         transaction_id: transaction_id,
      };

      axios
         .post("add_edit_company/featch_marital_status", payload)
         .then((response) => {
            if (response.status === 200) {
               setMaritalStatusAll(response.data.data.marital_status_array);
            }
         })
         .catch(() => {
            console.log('error');
         });
   }, []);

   //-------------- hide /show div
   const TypeWiseShowRow = (e) => {
      // 1 = COMMERCIAL  / 2 = PRIVATE
      const loc_div_id = e.currentTarget.value;

      if (loc_div_id == 1) {
         const RowHideShow = "table-row";
         const RowPrivateHideShow = "none";
         setRowHideShow(RowHideShow);
         setRowPrivateHideShow(RowPrivateHideShow);
         setFieldtxtName("COMPANY REGISTRATION NUMBER")
      } else if (loc_div_id == 2) {
         const RowHideShow = "none";
         const RowPrivateHideShow = "table-row";
         setRowHideShow(RowHideShow);
         setRowPrivateHideShow(RowPrivateHideShow);
         setFieldtxtName("ID Number")

      }
   }

   const ResidencyStatusWiseShowHide = (val) => {
      // 2 = expert residance permit  / 3 = non-presidance 
      if (val == 2 || val == 3) {
         const ResHideShow = "table-row";
         setResHideShow(ResHideShow);
      } else {
         const ResHideShow = "none";
         setResHideShow(ResHideShow);
      }
   }

   const MaritalStatusWiseShowHide = (val) => {
      // 3 = MARRIED (COP) / 4 = MARRIED (OCOP) 
      if (val == 3 || val == 4) {
         const MaritalHideShow = "table-row";
         setMaritalHideShow(MaritalHideShow);
      } else {
         const MaritalHideShow = "none";
         setMaritalHideShow(MaritalHideShow);
      }
   }

   //--------------- to check only type integer value
   const NumberChange = (val, flag) => {
      const re = /^[0-9\b]+$/;
      if (flag == 1) {
         if (val === '' || re.test(val)) {
            setOfficeNumber(val);
         }
      } else if (flag == 3) {
         if (val === '' || re.test(val)) {
            setHomeNumber(val);
         }
      } else if (flag == 4) {
         if (val === '' || re.test(val)) {
            setFaxNumber(val);
         }
      }
   }

   //--------------- title selected wise changes gender
   const changeGender = (val) => {
      if (val == 4 || val == 6) {
         setGender(1);
      } else {
         setGender(2);
      }
   }

   //------- same as physical address
   const SameAsAbove = (e) => {
      const loc_address = e.currentTarget.name;
      //console.log(loc_address);
      if (loc_address == 'same_as_physical') {
         setPostalAddressLine1(PhysicalAddressLine1)
         setPostalAddressLine2(PhysicalAddressLine2);
         setPostalAddressCity(PhysicalAddressCity);
      }
   }

   //------------------- featch company details ------------- 
   useEffect(() => {
      setLoader(true);
      const payload = {
         transaction_id: transaction_id,
         client_id: url_data.id,
      };

      axios
         .post("add_edit_company/featch_edit_company_details", payload)
         .then((response) => {
            // console.log(response.data.data);
            if (response.status === 200) {
               setCompanyDetails(response.data.data.edit_company_details);
               setCustomerType(response.data.data.edit_company_details.customer_type)
               setGuarantorApplication(response.data.data.edit_company_details.guarantor_application)
               setCompanyType(response.data.data.edit_company_details.company_type)
               setCompanyName(response.data.data.edit_company_details.company_name)
               setAbbreviation(response.data.data.edit_company_details.abbreviation_trading_as)
               setCompanyTitle(response.data.data.edit_company_details.company_representative_title)
               setCompanyRptrTitle(response.data.data.edit_company_details.company_representative_title)
               setInitials(response.data.data.edit_company_details.initials_input)
               setDescription(response.data.data.edit_company_details.preferred_name)
               setCompReprSurname(response.data.data.edit_company_details.company_representative_surname)
               setCompanyReprNationality(response.data.data.edit_company_details.company_representative_nationality)
               setCountriesOfIncorporation(response.data.data.edit_company_details.country_of_incorporation)
               setComercialIdNumber(response.data.data.edit_company_details.id_number)
               setOccupation(response.data.data.edit_company_details.occupation)
               setRegularDriver(response.data.data.edit_company_details.regular_driver)
               setGender(response.data.data.edit_company_details.gender)
               setStartDate(response.data.data.edit_company_details.date_of_birth)
               setDriversLicenseIssueDate(response.data.data.edit_company_details.drivers_issue_date)
               setClaimFreeGroup(response.data.data.edit_company_details.claim_free_group)
               setApplicantClaim(response.data.data.edit_company_details.claim_has_made)
               setIdType(response.data.data.edit_company_details.type_of_id)
               setCompanyRegistNumber(response.data.data.edit_company_details.company_registration_number)
               setVatNumber(response.data.data.edit_company_details.vat_number)
               setYearsInBusiness(response.data.data.edit_company_details.years_in_business)
               setNatureOfBusiness(response.data.data.edit_company_details.nature_of_business)
               setLandLordName(response.data.data.edit_company_details.land_lord_name)
               setHoldingCompany(response.data.data.edit_company_details.holding_company)
               setAssociatedCompanies(response.data.data.edit_company_details.associated_companies)
               setSubsidiaryCompany(response.data.data.edit_company_details.subsidiary_company)
               setIdExpiryDate(response.data.data.edit_company_details.id_expiry_date)
               setResidencyStatus(response.data.data.edit_company_details.residency_status)
               setCountriesResidance(response.data.data.edit_company_details.countries_of_residence)
               setNationality(response.data.data.edit_company_details.nationality)
               setHomeLanguage(response.data.data.edit_company_details.home_language)
               setCorrespondanceLanguage(response.data.data.edit_company_details.correspondance_language)
               setPreferredStmtMethod(response.data.data.edit_company_details.preferred_statement_method)
               setOfficeNumber(response.data.data.edit_company_details.office_number)
               setCellNumber(response.data.data.edit_company_details.mobile_number)
               setHomeNumber(response.data.data.edit_company_details.home_number)
               setFaxNumber(response.data.data.edit_company_details.fax_number)
               setUserEmail(response.data.data.edit_company_details.user_email)
               setPhysicalAddressLine1(response.data.data.edit_company_details.physical_address_line1)
               setPhysicalAddressLine2(response.data.data.edit_company_details.physical_address_line2)
               setPhysicalAddressCity(response.data.data.edit_company_details.physical_address_city)
               setPostalAddressLine1(response.data.data.edit_company_details.postal_address_line1)
               setPostalAddressLine2(response.data.data.edit_company_details.postal_address_line2)
               setPostalAddressCity(response.data.data.edit_company_details.postal_address_city)
               setDateCurrentAddress(response.data.data.edit_company_details.date_at_current_address)
               setDatePreviousAddress(response.data.data.edit_company_details.date_at_previous_address)
               setResidentialStatus(response.data.data.edit_company_details.residential_status)
               setMarketingSource(response.data.data.edit_company_details.marketing_source)
               setWebAddress(response.data.data.edit_company_details.website_address)
               setMaritalStatus(response.data.data.edit_company_details.marital_status)
               setHowMarried(response.data.data.edit_company_details.how_married)
               setMarriedDate(response.data.data.edit_company_details.married_date)
               setDurationOfStay(response.data.data.edit_company_details.duration_of_stay)
               setNoOfClaims(response.data.data.edit_company_details.number_of_claims)
               setPrevResCountry(response.data.data.edit_company_details.prev_res_country)

               if (response.data.data.edit_company_details.customer_type === 2) {
                  const RowHideShow = "none";
                  const RowPrivateHideShow = "table-row";
                  setRowHideShow(RowHideShow);
                  setRowPrivateHideShow(RowPrivateHideShow);
                  setFieldtxtName(response.data.data.edit_company_details.display_name)
               } else if (response.data.data.edit_company_details.customer_type === 1) {
                  const RowHideShow = "table-row";
                  const RowPrivateHideShow = "none";
                  setRowHideShow(RowHideShow);
                  setRowPrivateHideShow(RowPrivateHideShow);
                  setFieldtxtName(response.data.data.edit_company_details.display_name)
               }

               if (response.data.data.edit_company_details.residency_status === 2 || response.data.data.edit_company_details.residency_status === 3) {
                  const ResHideShow = "table-row";
                  setResHideShow(ResHideShow);
               }

               if (response.data.data.edit_company_details.marital_status == 3 || response.data.data.edit_company_details.marital_status == 4) {
                  const MaritalHideShow = "table-row";
                  setMaritalHideShow(MaritalHideShow);
               } else {
                  const MaritalHideShow = "none";
                  setMaritalHideShow(MaritalHideShow);
               }

               if (response.data.data.edit_company_details.type_of_id == 1) {
                  setFieldtxtName("ID NUMBER	");
               } else if (response.data.data.edit_company_details.type_of_id == 2) {
                  setFieldtxtName("PASSPORT NUMBER	");
               } else if (response.data.data.edit_company_details.type_of_id == 3) {
                  setFieldtxtName("RSA ID NUMBER");
               }

               if (response.data.data.edit_company_details.claim_has_made == 1) {
                  setApplicantClaimWiseHideShow("table-row");
               } else if (response.data.data.edit_company_details.claim_has_made == 0) {
                  setApplicantClaimWiseHideShow("none");
               }

               if (response.data.data.edit_company_details.prev_res_country != '' && response.data.data.edit_company_details.duration_of_stay > 2) {
                  setDurationStayPreCountryWiseHideShow("table-row");
               } else {
                  setDurationStayPreCountryWiseHideShow("none");
               }
               setLoader(false);
               window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }
         })
         .catch(() => {
            console.log('error');
            setLoader(false);
         });
   }, []);


   const onchangeTypeName = (type) => {
      // const txt_name = e.target.value;
      // console.log(IdType);
      if (type == 1) {
         setFieldtxtName("ID NUMBER");
      } else if (type == 2) {
         setFieldtxtName("PASSPORT NUMBER");
      } else if (type == 3) {
         setFieldtxtName("RSA ID NUMBER");
      }
   }

   const onchangeApplicantMade = (e) => {

      if (ApplicantClaim == 1) {
         setApplicantClaimWiseHideShow("none");
      } else if (ApplicantClaim == 0) {
         setApplicantClaimWiseHideShow("table-row");
      }
   }

   const DurationStayPreCountryHideShow = (e) => {

      if (DurationOfStay > 2) {
         setDurationStayPreCountryWiseHideShow("table-row");
      } else {
         setDurationStayPreCountryWiseHideShow("none");
      }
   }

   const copyToPreffredName = (val) => {
      var discription = val.split(' ');
      var final_discription = discription[0];
            // console.log(ll[0]);
      setDescription(final_discription);
   }

   const ChangeIntials = (val) => {
      var initials = val.split(' ');
      // var initials1 = initials[0];
      // var initials2 = initials[1];
      var finalInitials = '';
      for (var i = 0; i < initials.length; i++) {
         var name = initials[i];
         finalInitials = finalInitials.concat(name.substring(0, 1));
      }
      setInitials(finalInitials);
     //console.log(dynWording);
      //setInitials(val.substring(0, 1));
      //setInitials(val.substring(val.indexOf(' ') + 1));
   }

   return (
      <>
         <ToastContainer theme="colored" style={{ width: "450px", marginLeft: "-200px" }} />
         <Transaction_menu panel_flag={panFlag}/>
         {loader ? <Loader /> : null}
        
            <div>
               <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing={0} cellPadding={0} className="custom-body">
                  <tbody>
                     <tr valign="top">
                        <td></td>
                        <td width="100%">
                           <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                           <form name="command" method="post" onSubmit={handleSubmit} >
                              {/* <fieldset disabled={ReadOnly}> */}
                                 <table width="100%" border="0" cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                       <tr>
                                          <td valign="top" align="center">
                                             <input type="hidden" name="id" value="12223" />
                                             <input type="hidden" name="command" value="Update" id="command" />
                                             <input type="hidden" id="external_id" name="external_id" value="0" />
                                             <input type="hidden" name="department" value="0" />
                                             <div id="soap_result"></div>
                                             <table cellSpacing={1} className="defaultTbl no_caption_color" width="650">
                                                <tbody>
                                                   <tr>
                                                      <th colSpan={2} style={{ textAlign: 'center' }}>Client Details</th>
                                                   </tr>
                                                   {CompanyDetails.orignal_client_number != '' ? (
                                                      <tr id="client_type">
                                                         <td className="caption" width="240">Original Client Number</td>
                                                         <td>
                                                            <Link to={"/transaction/create_transaction/" + CompanyDetails.orignal_client_number} >
                                                                 {CompanyDetails.orignal_client_number}
                                                            </Link>
                                                           
                                                         </td>
                                                      </tr>
                                                   ) : null}
                                                   <tr id="client_type">
                                                      <td className="caption" width="240">Customer Type</td>
                                                      <td>
                                                         <select className="text-uppercase" name="type_id" id="type_id" value={CustomerType} onChange={(e) => setCustomerType(e.target.value)} onClick={TypeWiseShowRow}>
                                                            {CustomerTypeAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.type}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   <tr id="guarantor_application" style={{ display: RowPrivateHideShow }}>
                                                      <td className="caption">Is this a guarantor application?</td>
                                                      <td><input type="checkbox" name="guarantor_application" value={GuarantorApplication} onChange={(e) => { setGuarantorApplication(!GuarantorApplication) }} checked={GuarantorApplication} /></td>
                                                   </tr>
                                                   <tr style={{ display: RowHideShow }}>
                                                      <td className="caption" id="title">Company Type</td>
                                                      <td id="title_contents">
                                                         <select name="company_type" className="text-uppercase" value={CompanyType} onChange={(e) => setCompanyType(e.target.value)}>
                                                            {CompanyTypeAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.type}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   <tr style={{ display: RowHideShow }}>
                                                      <td className="caption" id="company_name">Company Name</td>
                                                      <td>
                                                         <input type="text" name="company_name" size="30" className="text-uppercase" value={CompanyName} onChange={(e) => setCompanyName(e.target.value)} />
                                                      </td>
                                                   </tr>
                                                   <tr style={{ display: RowHideShow }}>
                                                      <td className="caption" id="abbreviation">Trading As</td>
                                                      <td>
                                                         <input type="text" name="abbreviation" size="30" className="text-uppercase" value={abbreviation} onChange={(e) => setAbbreviation(e.target.value)} />
                                                         {/* <span style="float: right;"></span> */}
                                                      </td>
                                                   </tr>
                                                   <tr style={{ display: RowPrivateHideShow }}>
                                                      <td className="caption" id="title">Title</td>
                                                      <td id="title_contents">
                                                         <select id="test" name="company_type" className="text-uppercase" value={CompanyRptrTitle} onChange={(e) => { setCompanyRptrTitle(e.target.value); changeGender(e.target.value) }} >
                                                            {CompanyTitleAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.title}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   <tr style={{ display: RowPrivateHideShow }}>
                                                      <td className="caption" id="company_name">First Name <span className="text-danger">*</span></td>
                                                      <td>
                                                         <input type="text" name="company_name" size="30" className="text-uppercase" value={CompanyName} onChange={(e) => setCompanyName(e.target.value)} onKeyUp={(e) => { ChangeIntials(e.target.value); }} onBlur={(e) => { copyToPreffredName(e.target.value); }} ref={firstNameRef} />
                                                      </td>
                                                   </tr>
                                                   <tr style={{ display: RowPrivateHideShow }}>
                                                      <td className="caption" id="abbreviation">Last Name <span className="text-danger">*</span></td>
                                                      <td>
                                                         <input type="text" name="abbreviation" size="30" className="text-uppercase" value={abbreviation} onChange={(e) => setAbbreviation(e.target.value)} />
                                                         <span className="float-right" ref={lastName1Ref}></span>
                                                      </td>
                                                   </tr>
                                                   <tr className="compReprTitle" style={{ display: RowHideShow }}>
                                                      <td className="caption" id="compReprTitle">Company Representative Title</td>
                                                      <td id="title_contents">
                                                         <select id="company_rprtitle" name="company_rprtitle" className="text-uppercase" value={CompanyRptrTitle} onChange={(e) => setCompanyRptrTitle(e.target.value)}>
                                                            {CompanyRptrTitleAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.title}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   <tr id="initials" style={{ display: RowPrivateHideShow }}>
                                                      <td className="caption">Initials</td>
                                                      <td><input type="text" id="initials_input" name="initials" size="30" maxLength={16} className="text-uppercase" value={initials} onChange={(e) => setInitials(e.target.value)} /></td>
                                                   </tr>
                                                   <tr style={{ display: RowPrivateHideShow }}>
                                                      <td className="caption" id="preferred_name">Preferred Name</td>
                                                      <td><input type="text" name="description" size="30" className="text-uppercase" value={description} onChange={(e) => setDescription(e.target.value)} /></td>
                                                   </tr>
                                                   <tr className="compReprname" style={{ display: RowHideShow }}>
                                                      <td className="caption" id="compReprname">Company Representative Name</td>
                                                      <td>
                                                         <input type="text" name="description" size="30" className="text-uppercase" value={description} onChange={(e) => setDescription(e.target.value)} />
                                                         <span className="float-right"></span>
                                                      </td>
                                                   </tr>
                                                   <tr className="compReprSurname" style={{ display: RowHideShow }}>
                                                      <td className="caption" id="compReprSurname">Company Representative Surname</td>
                                                      <td>
                                                         <input type="text" name="comp_repr_surname" size="30" className="text-uppercase" value={compReprSurname} onChange={(e) => setCompReprSurname(e.target.value)} />
                                                         <span className="float-right"></span>
                                                      </td>
                                                   </tr>
                                                   <tr className="comp_repr_nationality" style={{ display: RowHideShow }}>
                                                      <td className="caption">Nationality </td>
                                                      <td>
                                                         <select name="comp_repr_nationality" id="comp_repr_nationality" className="text-uppercase" value={CompanyReprNationality} onChange={(e) => setCompanyReprNationality(e.target.value)} style={{ textTransform: "uppercase", borderColor: nationalityColor }} ref={nationalityRef}>
                                                            <option value="">--Select Nationality--</option>
                                                            {CompanyReprNationalityAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.text}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   <tr className="country_of_incorporation" style={{ display: RowHideShow }}>
                                                      <td className="caption">Country of Incorporation </td>
                                                      <td>
                                                         <select name="incorporation_country" className="text-uppercase" value={CountriesOfIncorporation} onChange={(e) => setCountriesOfIncorporation(e.target.value)}>
                                                            <option value="">--Select Country of Incorporation--</option>
                                                            {CountriesOfIncorporationAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.text}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   <tr id="comercial_id_number_row" style={{ display: RowHideShow }}>
                                                      <td className="caption">ID Number</td>
                                                      <td>
                                                         <input type="text" name="comercial_id_number" id="comercial_id_number" size="30" className="text-uppercase" value={ComercialIdNumber} onChange={(e) => setComercialIdNumber(e.target.value)} />
                                                      </td>
                                                   </tr>

                                                   <tr>
                                                      <td className="caption">Occupation</td>
                                                      <td><input type="text" name="occupation" className="text-uppercase" value={Occupation} onChange={(e) => setOccupation(e.target.value)} /></td>
                                                   </tr>
                                                   <tr id="regular_driver">
                                                      <td className="caption">Regular Driver</td>
                                                      <td><input type="checkbox" name="regular_driver" value={RegularDriver} onChange={(e) => { setRegularDriver(!RegularDriver) }} checked={RegularDriver} /></td>
                                                   </tr>
                                                   <tr id="gender">
                                                      <td className="caption">Gender</td>
                                                      <td>
                                                         <select name="gender" id="gender_select" className="text-uppercase" value={Gender} onChange={(e) => setGender(e.target.value)} >
                                                            {GenderAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.gender}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   <tr id="date_of_birth">
                                                      <td className="caption">Date of Birth</td>
                                                      <td>
                                                         {/* <input type="date" id="birthdate" name="date_of_birth" className="text-uppercase" /> */}
                                                         {
                                                            dateFlag == 1 ?
                                                               <DatePicker id="birthdate" selected={startDate} name="date_of_birth" onChange={(date) => { setStartDate(date); setdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                  return new Date() > d;
                                                               }} />
                                                               :
                                                               <DatePicker id="birthdate" value={startDate} name="date_of_birth" onChange={(date) => { setStartDate(date); setdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                  return new Date() > d;
                                                               }} />
                                                         }
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td className="caption">Driver's License Issue Date</td>
                                                      <td>
                                                         <select name="drivers_issue_date" id="drivers_issue_date" className="text-uppercase" value={DriversLicenseIssueDate} onChange={(e) => setDriversLicenseIssueDate(e.target.value)} >
                                                            <option value="0">- - SELECT A YEAR - -</option>
                                                            {DriversLicenseIssueDateAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.year}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td className="caption">Claim Free Group</td>
                                                      <td>
                                                         <select name="cfg" className="text-uppercase" value={ClaimFreeGroup} onChange={(e) => setClaimFreeGroup(e.target.value)}>
                                                            {ClaimFreeGroupAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.group}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td className="caption">Applicant has made a claim in the last 12  months</td>
                                                      <td>
                                                         <select name="claim_has_made" id="claim_has_made" className="text-uppercase" value={ApplicantClaim} onChange={(e) => { setApplicantClaim(e.target.value); onchangeApplicantMade(); }} >
                                                            {ApplicantClaimAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.claim}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>

                                                   <tr style={{ display: ApplicantClaimWiseHideShow }}>
                                                      <td className="caption">NUMBER OF CLAIMS</td>
                                                      <td>
                                                         <select name="no_of_claims" className="text-uppercase" value={NoOfClaims} onChange={(e) => setNoOfClaims(e.target.value)} >
                                                            {NoOfClaimsAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.cfg}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>

                                                   <tr id="id_type" style={{ display: RowPrivateHideShow }}>
                                                      <td className="caption">ID Type</td>
                                                      <td>
                                                         <select name="id_type" id="type_of_id" className="text-uppercase" value={IdType} onChange={(e) => { setIdType(e.target.value); onchangeTypeName(e.target.value); }}>
                                                            <option value="0">-- SELECT AN ID TYPE --</option>
                                                            {IdTypeAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.type}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   {/* ID Number */}
                                                   <tr id="id_number" >
                                                      <td className="caption" id="registration">{FieldtxtName}</td>
                                                      <td>
                                                         <input type="text" id="company_registration_number" name="company_registration_number" size="30" className="text-uppercase" value={CompanyRegistNumber} onChange={(e) => setCompanyRegistNumber(e.target.value)} />
                                                      </td>
                                                   </tr>
                                                   <tr id="passport_number" style={{ display: RowHideShow }}>
                                                      <td className="caption" id="vat_number">VAT NUMBER</td>
                                                      <td>
                                                         <input type="text" id="vat_number_control" name="vat_number" size="30" className="text-uppercase" value={VatNumber} onChange={(e) => setVatNumber(e.target.value)} />
                                                      </td>
                                                   </tr>
                                                   <tr className="commercial_only" style={{ display: RowHideShow }}>
                                                      <td className="caption">Number of years in business</td>
                                                      <td><input type="text" name="years_in_business" className="text-uppercase" value={YearsInBusiness} onChange={(e) => setYearsInBusiness(e.target.value)} /></td>
                                                   </tr>
                                                   <tr className="commercial_only" style={{ display: RowHideShow }}>
                                                      <td className="caption">Nature of business</td>
                                                      <td><input type="text" name="nature_of_business" className="text-uppercase" value={NatureOfBusiness} onChange={(e) => setNatureOfBusiness(e.target.value)} /></td>
                                                   </tr>
                                                   <tr className="commercial_only" style={{ display: RowHideShow }}>
                                                      <td className="caption">Land lord<br />(if not owner of property)</td>
                                                      <td><input type="text" name="land_lord_name" className="text-uppercase" value={LandLordName} onChange={(e) => setLandLordName(e.target.value)} /></td>
                                                   </tr>
                                                   <tr className="commercial_only" style={{ display: RowHideShow }}>
                                                      <td className="caption">Holding company</td>
                                                      <td><input type="text" name="holding_company" className="text-uppercase" value={HoldingCompany} onChange={(e) => setHoldingCompany(e.target.value)} /></td>
                                                   </tr>
                                                   <tr className="commercial_only" style={{ display: RowHideShow }}>
                                                      <td className="caption">Associated company/ies</td>
                                                      <td><input type="text" name="associated_companies" className="text-uppercase" value={AssociatedCompanies} onChange={(e) => setAssociatedCompanies(e.target.value)} /></td>
                                                   </tr>
                                                   <tr className="commercial_only" style={{ display: RowHideShow }}>
                                                      <td className="caption">Subsidiary company</td>
                                                      <td><input type="text" name="subsidiary_company" className="text-uppercase" value={SubsidiaryCompany} onChange={(e) => setSubsidiaryCompany(e.target.value)} /></td>
                                                   </tr>

                                                   {system_type === 1 ? (
                                                      <tr style={{ display: RowHideShow }}>
                                                         <td className="caption">Income Tax Number</td>
                                                         <td>
                                                            <input type="text" id="income_tax_number" name="income_tax_number" size="30" className="text-uppercase" value={IncomeTaxNumber} onChange={(e) => setIncomeTaxNumber(e.target.value)} />
                                                         </td>
                                                      </tr>
                                                   ) : null}

                                                   <tr>
                                                      <td className="caption">ID Expiry Date</td>
                                                      <td>
                                                         {/* <input type="text" id="id_expiry_date" name="id_expiry_date" className="text-uppercase" value={IdExpiryDate} onChange = {(e) => setIdExpiryDate(e.target.value)} /> */}
                                                         {
                                                            expirydateFlag == 1 ?
                                                               <DatePicker id="id_expiry_date" selected={IdExpiryDate} name="id_expiry_date" onChange={(date) => { setIdExpiryDate(date); setexpirydateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                               :
                                                               <DatePicker id="id_expiry_date" value={IdExpiryDate} name="id_expiry_date" onChange={(date) => { setIdExpiryDate(date); setexpirydateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                         }
                                                      </td>
                                                   </tr>
                                                   <tr className="not_commercial" style={{ display: RowPrivateHideShow }}>
                                                      <td className="caption">Residency Status</td>
                                                      <td>
                                                         <select name="residency_status" id="residency_status" className="text-uppercase" value={ResidencyStatus} onChange={(e) => { setResidencyStatus(e.target.value); ResidencyStatusWiseShowHide(e.target.value) }}>
                                                            <option value="0">-- Select a Residency Status --</option>
                                                            {ResidencyStatusAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.res_status}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   <tr className="stay_duration" style={{ display: ResHideShow }}>
                                                      <td className="caption">Duration Of Stay</td>
                                                      <td>
                                                         <input type="text" id="duration_of_stay" name="duration_of_stay" size="30" className="text-uppercase" value={DurationOfStay} onChange={(e) => setDurationOfStay(e.target.value)} onKeyUp={DurationStayPreCountryHideShow} />
                                                      </td>
                                                   </tr>

                                                   <tr className="prev_res_country" style={{ display: DurationStayPreCountryWiseHideShow }}>
                                                      <td className="caption">If duration of stay &gt;2 years give previous country of residence</td>
                                                      <td>
                                                         <input type="text" id="prev_res_country" name="prev_res_country" size="30" className="text-uppercase" value={PrevResCountry} onChange={(e) => setPrevResCountry(e.target.value)} /></td>
                                                   </tr>

                                                   <tr id="marital_status" style={{ display: RowPrivateHideShow }}>
                                                      <td className="caption">Marital Status</td>
                                                      <td>
                                                         <select name="marital_status" className="text-uppercase" value={MaritalStatus} onChange={(e) => { setMaritalStatus(e.target.value); MaritalStatusWiseShowHide(e.target.value) }}>
                                                            {MaritalStatusAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.marital_status}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   
                                                   { CustomerType === 2 ?
                                                   <tr id="how_married" style={{ display: MaritalHideShow }}>
                                                      <td className="caption">How Married</td>
                                                      <td>
                                                         <select name="how_married" className="text-uppercase" value={HowMarried} onChange={(e) => setHowMarried(e.target.value)}>
                                                            {HowMarriedAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.type}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   : null }
                                                   
                                                   { CustomerType === 2 ?
                                                   <tr id="tr_date_married" style={{ display: MaritalHideShow }}>
                                                      <td className="caption">Date Married</td>
                                                      <td>
                                                         
                                                         {
                                                            marrieddateFlag == 1 ?
                                                               <DatePicker id="married_date" selected={MarriedDate} name="married_date" onChange={(date) => { setMarriedDate(date); setmarrieddateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                  return new Date() > d;
                                                               }}
                                                               />
                                                               :
                                                               <DatePicker id="married_date" value={MarriedDate} name="married_date" onChange={(date) => { setMarriedDate(date); setmarrieddateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                  return new Date() > d;
                                                               }}
                                                               />
                                                         }
                                                      </td>
                                                   </tr>
                                                   : null }

                                                   <tr className="country_of_residence">
                                                      <td className="caption">Country of Residence </td>
                                                      <td>
                                                         <select name="countries_country" className="text-uppercase" value={CountriesResidance} onChange={(e) => setCountriesResidance(e.target.value)}>
                                                            <option value="">--Select Country of Residence--</option>
                                                            {CountriesResidanceAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.text}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   <tr className="nationality">
                                                      <td className="caption">Nationality <span className="text-danger">*</span></td>
                                                      <td>
                                                         <select name="nationality" id="nationality" value={Nationality} onChange={(e) => setNationality(e.target.value)} style={{ textTransform: "uppercase", borderColor: nationalityColor }} ref={nationalityRef}>
                                                            <option value="">--Select Nationality--</option>
                                                            {NationalityAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.text}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   <tr id="home_language" style={{ display: RowPrivateHideShow }}>
                                                      <td className="caption">Home Language</td>
                                                      <td>
                                                         <select name="home_language" className="text-uppercase" value={HomeLanguage} onChange={(e) => setHomeLanguage(e.target.value)} >
                                                            {HomeLanguageAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.text}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td className="caption">Correspondence Language</td>
                                                      <td>
                                                         <select name="correspondance_language" className="text-uppercase" value={CorrespondanceLanguage} onChange={(e) => setCorrespondanceLanguage(e.target.value)} >
                                                            {CorrespondanceLanguageAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.text}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td className="caption">Preferred Statement Method</td>
                                                      <td>
                                                         <select name="preferred_statement_method" className="text-uppercase" value={PreferredStmtMethod} onChange={(e) => setPreferredStmtMethod(e.target.value)} >
                                                            <option value="0">-- SELECT A METHOD --</option>
                                                            {PreferredStmtMethodAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.statement}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td className="caption">Work Number</td>
                                                      <td>
                                                         <input type="text" id="office_number" name="office_number" size="30" value={OfficeNumber} onChange={(e) => NumberChange(e.target.value, 1)} />
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td className="caption">Mobile Number</td>
                                                      <td>
                                                         <input type="text" id="cell_number" name="cell_number" size="30" className="text-uppercase" value={CellNumber} onChange={(e) => { setCellNumber(e.target.value) }} />
                                                      </td>
                                                   </tr>
                                                   <tr id="home_number" style={{ display: RowPrivateHideShow }}>
                                                      <td className="caption">Home Number</td>
                                                      <td>
                                                         <input type="text" id="home" name="home_number" size="30" className="text-uppercase" value={HomeNumber} onChange={(e) => { NumberChange(e.target.value, 3) }} />
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td className="caption">Fax Number</td>
                                                      <td>
                                                         <input type="text" id="fax_number" name="fax_number" size="30" className="text-uppercase" value={FaxNumber} onChange={(e) => NumberChange(e.target.value, 4)} />
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td className="caption">Email <span className="text-danger">*</span></td>
                                                      <td>
                                                         <input type="text" name="email" id="user_email" size="30" className="text-uppercase" value={UserEmail} onChange={(e) => setUserEmail(e.target.value)} ref={UserEmailRef} />
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td className="caption">Physical Address Line 1</td>
                                                      <td>
                                                         <input type="text" name="physical_address_line1" id="physical_address_line1" className="text-uppercase" value={PhysicalAddressLine1} onChange={(e) => setPhysicalAddressLine1(e.target.value)} />
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td className="caption">Physical Address Line 2</td>
                                                      <td>
                                                         <input type="text" name="physical_address_line2" id="physical_address_line2" className="text-uppercase" value={PhysicalAddressLine2} onChange={(e) => setPhysicalAddressLine2(e.target.value)} /></td>
                                                   </tr>
                                                   <tr>
                                                      <td className="caption">Physical Address City</td>
                                                      <td>
                                                         <input type="text" name="physical_address_city" id="physical_address_city" className="text-uppercase" value={PhysicalAddressCity} onChange={(e) => setPhysicalAddressCity(e.target.value)} />
                                                      </td>
                                                   </tr>
                                                   {/* <tr id="au_state_select" style={{ display: RowHideShow }}>
                                    <td className="caption">State</td>
                                    <td>
                                       <select name="state" className="text-uppercase" >
                                          <option value="0">-- SELECT A STATE --</option>
                                          <option value="1">ACT</option>
                                          <option value="2">NSW</option>
                                       </select>
                                    </td>
                                 </tr> */}
                                                   <tr>
                                                      <td className="caption">Postal Address Line 1</td>
                                                      <td>
                                                         <input type="text" name="postal_address_line1" id="postal_address_line1" className="text-uppercase" value={PostalAddressLine1} onChange={(e) => setPostalAddressLine1(e.target.value)} />
                                                         <div className="float-right">
                                                            <input type="submit" className="button_after_inception" name="same_as_physical" value="Same as Physical" onClick={function (e) { SameAsAbove(e); }} />
                                                         </div>
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td className="caption">Postal Address Line 2</td>
                                                      <td>
                                                         <input type="text" name="postal_address_line2" id="postal_address_line2" className="text-uppercase" value={PostalAddressLine2} onChange={(e) => setPostalAddressLine2(e.target.value)} />
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td className="caption">Postal Address City</td>
                                                      <td><input type="text" name="postal_address_city" id="postal_address_city" className="text-uppercase" value={PostalAddressCity} onChange={(e) => setPostalAddressCity(e.target.value)} />
                                                      </td>
                                                   </tr>
                                                   <tr className="not_commercial" style={{ display: RowPrivateHideShow }}>
                                                      <td className="caption">Occupancy date at current address</td>
                                                      <td>
                                                         {/* <input type="text" name="date_at_current_address" id="date_at_current_address" className="text-uppercase" value={DateCurrentAddress} onChange = {(e) => setDateCurrentAddress(e.target.value)} /> */}
                                                         {
                                                            currentdateFlag == 1 ?
                                                               <DatePicker id="date_at_current_address" selected={DateCurrentAddress} name="date_at_current_address" onChange={(date) => { setDateCurrentAddress(date); setcurrentdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => { return new Date() > d; }} minDate={new Date(DatePreviousAddress)}
                                                               maxDate={new Date()}/>
                                                               :
                                                               <DatePicker id="date_at_current_address" value={DateCurrentAddress} name="date_at_current_address" onChange={(date) => { setDateCurrentAddress(date); setcurrentdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => { return new Date() > d; }} />
                                                         }

                                                      </td>
                                                   </tr>
                                                   <tr className="not_commercial" id="prev_address_tr" style={{ display: RowPrivateHideShow }}>
                                                      <td className="caption">Occupancy date at previous address</td>
                                                      <td>
                                                         {/* <input type="text" name="date_at_previous_address" className="text-uppercase" id="dp1675236152256" value={DatePreviousAddress} onChange = {(e) => setDatePreviousAddress(e.target.value)} /> */}

                                                         {
                                                            previousdateFlag == 1 ?
                                                               <DatePicker id="date_at_previous_address" selected={DatePreviousAddress} name="date_at_previous_address" onChange={(date) => { setDatePreviousAddress(date); setpreviousdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                  return new Date() > d;
                                                               }} />
                                                               :
                                                               <DatePicker id="date_at_previous_address" value={DatePreviousAddress} name="date_at_previous_address" onChange={(date) => { setDatePreviousAddress(date); setpreviousdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                  return new Date() > d;
                                                               }} />
                                                         }
                                                      </td>
                                                   </tr>
                                                   <tr className="not_commercial" style={{ display: RowPrivateHideShow }}>
                                                      <td className="caption">Residential Status</td>
                                                      <td>
                                                         <select name="residential_status" className="text-uppercase" value={ResidentialStatus} onChange={(e) => setResidentialStatus(e.target.value)} >
                                                            <option value="0">- - SELECT A STATUS - - </option>
                                                            {ResidentialStatusAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.status}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td className="caption">Marketing Source</td>
                                                      <td>
                                                         <select id="referred_from" name="referred_from" className="text-uppercase" value={MarketingSource} onChange={(e) => setMarketingSource(e.target.value)}>
                                                            <option value="0">-- Select a type --</option>
                                                            {MarketingSourceAll.map((item) => {
                                                               return (
                                                                  <option key={item.id} value={item.id}>
                                                                     {item.source}
                                                                  </option>
                                                               );
                                                            })}
                                                         </select>
                                                      </td>
                                                   </tr>
                                                   {system_type === 1 ? (
                                                      <tr style={{ display: RowHideShow }}>
                                                         <td className="caption">Taxi Operator</td>
                                                         <td><input type="checkbox" name="taxi_operator" value={TaxiOperator} onChange={(e) => { setTaxiOperator(!TaxiOperator) }} checked={TaxiOperator} /></td>
                                                      </tr>
                                                   ) : null}
                                                   {system_type === 1 ? (
                                                      <tr style={{ display: RowHideShow }}>
                                                         <td className="caption">Public Drivers Permit</td>
                                                         <td><input type="checkbox" name="public_drivers_permit" value={PublicDriversPermit} onChange={(e) => { setPublicDriversPermit(!PublicDriversPermit) }} checked={PublicDriversPermit} /></td>
                                                      </tr>
                                                   ) : null}

                                                   <tr id="web_address" style={{ display: RowHideShow }}>
                                                      <td className="caption">Web Address</td>
                                                      <td>
                                                         <input type="text" name="web_address" size="50" className="text-uppercase" value={WebAddress} onChange={(e) => setWebAddress(e.target.value)} />
                                                      </td>
                                                   </tr>
                                                   {/* <tr id="gps" style={{ display: RowHideShow }}>
                                    <td className="caption">Add GPS Location</td>
                                    <td className="text-center">
                                      <input type="button" className="hide_button_after_inception" name="add_gps_coord"  /></td>
                                 </tr>	  */}
                                                   <tr>
                                                      <td className="caption text-center" colSpan={3} >
                                                         <input type="submit" name="command" value="Save" onClick={(e) => { setClientCompSave(e.target.value) }} />&nbsp;
                                                         <input type="submit" name="command" className="hide_button_after_inception" value="Save and Continue" onClick={(e) => { setClientCompSave(e.target.value) }} />&nbsp;

                                                         <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                            <input type="button" className="p-relative" value="Cancel" />
                                                         </Link>
                                                      </td>
                                                   </tr>

                                                </tbody>
                                             </table>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              {/* </fieldset> */}
                           </form>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <Footer />
            </div>
      </>
   );
}

export default Client_company_add_edit;