import { Link, useNavigate, useParams } from "react-router-dom"
import React, { useState, useEffect, useRef } from "react";
import Transaction_menu from "../Transaction/Transaction_menu";
import Footer from "../Dashboard/Footer";
import axios from "../axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Loader from "../Components/Loader"
//import HistoryModalDialog from './../HistoryModalDialog'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { Modal, Button } from 'react-bootstrap'

const Prepare = () => {
    // console.log('asasa');
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [ProcessFinanceDetails, setProcessFinanceDetails] = useState([]);
    const [SubmitFinanceDetails, setSubmitFinanceDetails] = useState([]);
    const [DealerDocsDetails, setDealerDocsDetails] = useState([]);
    const [DealerDocsChkDetails, setDealerDocsChkDetails] = useState([]);
    const [IncludeDocsChkDetails, setIncludeDocsChkDetails] = useState([]);
    const [ApplicationHistoryDetails, setApplicationHistoryDetails] = useState([]);

    const [ReferenceEditHideShow, setReferenceEditHideShow] = useState("");
    const [ReferenceDataHideShow, setReferenceDataHideShow] = useState("none");
    const [AccountNoEditHideShow, setAccountNoEditHideShow] = useState("");
    const [AccountNoDataHideShow, setAccountNoDataHideShow] = useState("none");
    const [LoanhouseMail, setLoanhouseMail] = useState("");
    const [LoanhouseName, setLoanhouseName] = useState("");
    const [HasPreferedApp, setHasPreferedApp] = useState("");
    const [PdfSendName, setPdfSendName] = useState("");


    const [Reference, setReference] = useState("");
    const [AccountNumber, setAccountNumber] = useState("");
    const [ReferenceSave, setReferenceSave] = useState("Save Reference");
    const [AccountNumberSave, setAccountNumberSave] = useState("Save Account Number");
   
    const [sign_file_name, setFile] = useState("");
    
    const ccOtherEmailRef = useRef();
    const [ccOther, setccOther] = useState("");
    const [ccUser, setccUser] = useState(false);
    const [ccClient, setccClient] = useState(false);
   
    // console.log(sign_file_name); 

    const transaction_id = localStorage.getItem("transaction_id");
    const application_id = localStorage.getItem("application_id");
    const system_type    = localStorage.getItem("system_type");
    const instance_id    = localStorage.getItem("instance_id");
    const loanhouse_id   = localStorage.getItem("loanhouse_id");
    const user_id        = localStorage.getItem("UserId");
    const unincept_flag  = localStorage.getItem("unincept_flag");

    const sess_access_token        = localStorage.getItem("sess_access_token");
    const sess_token_expire        = localStorage.getItem("sess_token_expire");
    
    useEffect(() => {
        document.title = 'Transactions | Seriti BW';
    }, []);
    
    //----------- featch process finance application details -----------------
    useEffect(() => {
        const payload = {
            transaction_id: transaction_id,
            application_id: application_id,
            system_type: system_type,
        };
        axios
          .post("prepare_application/featch_process_finance_application_details", payload)
          .then((response) => {
            if(response.data.status === 200){
               // console.log(response.data.data);
                setProcessFinanceDetails(response.data.data);
                setReference(response.data.data.reference); //reference
                setAccountNumber(response.data.data.account_number); //account number
                setPdfSendName(response.data.data.pdf_name);
            }
          })
          .catch(() => {
            console.log('error'); 
          });
    }, []);

    const ReferenceWiseShowHide = () => {    
        setReferenceDataHideShow("");
        setReferenceEditHideShow("none");
    }
    
    const ReferenceEditWiseShowHide = () => {    
        setReferenceDataHideShow("none");
        setReferenceEditHideShow("");
    }

    const AccountNoWiseShowHide = () => {    
        setAccountNoDataHideShow("");
        setAccountNoEditHideShow("none");
    }
    
    const AccountNoEditWiseShowHide = () => {    
        setAccountNoDataHideShow("none");
        setAccountNoEditHideShow("");
    }

    //----------- featch application history details -----------------
    useEffect(() => {
        const payload = {
            transaction_id: transaction_id,
            loan_id: loanhouse_id,
        };
        axios
          .post("prepare_application/featch_application_history", payload)
          .then((response) => {
            if(response.data.status === 200){
                //console.log(response);
                setApplicationHistoryDetails(response.data.data);
            }
          })
          .catch(() => {
            console.log('error'); 
          });
    }, []);

  
    //----------- featch submit finance details -----------------
    useEffect(() => {
        setLoader(true);
        const payload = {
            transaction_id: transaction_id,
            application_id: application_id,
            instance_id: instance_id,
        };
        axios
          .post("prepare_application/featch_submit_finance_application", payload)
          .then((response) => {
            if(response.data.status === 200){
                //console.log(response.data.data.dealer_docs_array);
                setSubmitFinanceDetails(response.data.data.other_array);
                setDealerDocsDetails(response.data.data.dealer_docs_array);
                setDealerDocsChkDetails(response.data.data.document_chk_edit_array);
                setIncludeDocsChkDetails(response.data.data.document_chk_edit_array);
                setLoanhouseMail(response.data.data.other_array.loanhouse_mail);
                setLoanhouseName(response.data.data.other_array.loanhouse_name);
                setHasPreferedApp(response.data.data.other_array.has_prefered_app);
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }
            setLoader(false);
          }) 
          .catch(() => {
            console.log('error'); 
            setLoader(false);
          });
    }, []);

    const handleSubmitData = () => {
        
        const payload = {
            application_id: application_id,
            command: ReferenceSave,
            command1: AccountNumberSave,
            reference: Reference,
            account_number: AccountNumber,
        }
       // console.log(payload);
        axios
        .post("prepare_application/edit_reference_acccount_number", payload)
        .then((response) => {
            //console.log(response);
            if(response.status == 200){
                   window.location.reload(false);  
            }
        })
        .catch(() => {
           console.log('error');
        });
    }

    const handleValidation = () => {
        
        var flag = true;
        if (sign_file_name === "") {
            alert('You did not select any file to upload.');
            flag = false;
        }else if(sign_file_name.type !== "application/pdf"){
            alert('The document you selected to upload is not a valid PDF document and was therefor not uploaded.');
            flag = false;
        }

        return flag;
    };

    const SubmitSignPdf = () => {
        //alert('sd');
        const flag = handleValidation();
        if (flag) {

            var bodyFormData = new FormData();
            bodyFormData.append("application_id", application_id);
            bodyFormData.append("signed_pdf", sign_file_name);
           // console.log(bodyFormData);
            axios
            .post("prepare_application/upload_sign_pdf", bodyFormData)
            .then((response) => {
                //console.log(response);
                if(response.status == 200){
                    alert('Finance contract has been uploaded successfully.');
                    window.location.reload(false);  
                }
            })
            .catch(() => {
               console.log('error');
            });
        }
    }

    const handleBankChkChange = (index, evnt)=>{   // edit checkbox array
        
        let BankDocsArray = [...DealerDocsChkDetails];
        BankDocsArray[index][evnt.target.name] = !BankDocsArray[index][evnt.target.name];
        
        if(BankDocsArray[index][evnt.target.name] == true){
            BankDocsArray[index][evnt.target.name] = evnt.target.value;
        }else{
            BankDocsArray[index][evnt.target.name] = "";
        }
        //console.log(DocsArray);
        setDealerDocsChkDetails(BankDocsArray);
    }

    const handleIncludeDocsChange = (index, evnt)=>{   // edit checkbox array
        
        let DocsArray = [...IncludeDocsChkDetails];
        DocsArray[index][evnt.target.name] = !DocsArray[index][evnt.target.name];
        
        if(DocsArray[index][evnt.target.name] == true){
            DocsArray[index][evnt.target.name] = evnt.target.value;
        }else{
            DocsArray[index][evnt.target.name] = "";
        }
        //console.log(DocsArray);
        setIncludeDocsChkDetails(DocsArray);
    }

    const handleSubmitSendToBank = (e) => {
        e.preventDefault();
            setLoader(true);
            const payload = {
                transaction_id: transaction_id,
                application_id: application_id,
                dealer_file: DealerDocsChkDetails,
                send_pdf_name: PdfSendName,
            };
            //console.log(payload);
            axios
            .post("prepare_application/send_to_bank", payload)
            .then((response) => {
               // console.log(response);
                alert(response.data.data);
                window.location.reload(false);
                setLoader(false);
            })
            .catch(() => {
               console.log('error');
               setLoader(false);
            });
    }
  
    //--------------- submit to financer
    const validateUserEmail = () => {
        var flag = true;

		if(!ccOther) {
			return true;	//dont validate empty email addresses
		}else if ((ccOther.length > 0) && (ccOther.length < 5)) {
			alert('Please enter a valid email address');
			ccOtherEmailRef.current.focus();
			flag = false;
		}else{
			const regMatch = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(ccOther);
            //const regMatch = /([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)/ig.test(ccOther);

			if (ccOther != "") {
                if(regMatch == false){
                    alert('Please enter a valid email address');
                    ccOtherEmailRef.current.focus();
                    flag = false;
                }
                flag = true;
            }			
		}
		return flag;  
    }

    const checkPreferredBank = () => {
        var flag = true;
        // if (HasPreferedApp == 0)
		// {
        //     confirmAlert({
        //         title: '',
        //         message: 'Press OK to submit the Application! Press Cancel to select preferred financier!',
        //         buttons: [
        //           {
        //             label: 'Yes',
        //             onClick: () => { handleSubmitToFinancer() }
                    
        //           },
        //           {
        //             label: 'No',
        //             onClick: () => navigate("/transaction/create_transaction/"+transaction_id)
        //           }
        //         ]
        //     });
        //     flag = false;
		// }
        return flag
    }

    const checkBank = () => {
       // alert('b');
       var flag = true;
       var email = LoanhouseMail;
		if (email.length == 0)
		{
			var newmail = '';
			newmail = prompt('There is no email address saved for '+LoanhouseName+'. Please enter an email address');
			if (newmail == null || newmail == 'undefined') 
            newmail = '';
			if (newmail.length != 0)
			{
				setLoanhouseMail(newmail);
				flag = true;
			}
			else
			{
				alert('This application cannot be submitted without an email address for the bank.');
				flag = false;
			}
			flag = false;
		}
		else{
            flag = true;
        }
        return flag
    }

    const handleSubmitToFinancerValidation = () => {
        var bValidEmail = validateUserEmail();

        if (bValidEmail) {
              var a = checkPreferredBank();
			if (a)
               var b = checkBank();       
			else
				return a;
			return b;
		} else {
			return false;
		}
    }

    const handleSubmitToFinancer = (e) => {
        e.preventDefault();
        const flag = handleSubmitToFinancerValidation();
       // console.log(flag);
      
        if (flag) {
            setLoader(true);
            const payload = {
                transaction_id: transaction_id,
                application_id: application_id,
                loanhouse_id: loanhouse_id,
                user_id: user_id,
                system_type: system_type,
                command: "Submit to Financier",
                bank_email: LoanhouseMail,
                cc_other: ccOther,
                cc_user: ccUser,
                cc_client: ccClient,
                include_file: IncludeDocsChkDetails,
                sess_access_token: sess_access_token,
                sess_token_expire: sess_token_expire,
            };
            //console.log(payload);
            axios
                .post("prepare_application/submit_to_financier", payload)
                .then((response) => {
             //console.log(response);
                 alert(response.data.data);
                 window.location.reload(false);
                 setLoader(false);
                })
                .catch(() => {
                //console.log('error');
                setLoader(false);
                });
        }
    }

    let panFlag = 1;

    //----------------- compare to history
    const [CompareHistoryModalFlag1, setCompareHistoryModalFlag1] = useState(false);
    const [CompareApplicationHistoryData, setCompareApplicationHistoryData] = useState("");
    
    const [CompHistoryLoanHouseId, setCompHistoryLoanHouseId] = useState("");
    const [HistorySubmitDate, setHistorySubmitDate] = useState("");
    const [HistoryCurrentDate, setHistoryCurrentDate] = useState("");
    const [modelFlag11, setmodelFlag11] = useState(false);

    const handleCompareToHitory = (submitDate, LoanHouseId, currentDate) => {
        
        setCompareHistoryModalFlag1(true);
        setCompHistoryLoanHouseId(LoanHouseId);
        setHistorySubmitDate(submitDate);
        setHistoryCurrentDate(currentDate);
        setmodelFlag11(true);
    }

    useEffect(() => {
        setLoader(true);
        if(modelFlag11){
        const payload = {
            iPreviousTransactionDate: HistorySubmitDate,
            iCurrentTransactionDate: HistoryCurrentDate,
            loan_id: CompHistoryLoanHouseId,
            iTransactionID: transaction_id,
        };
        //console.log(payload);
        axios
            .post("transcation/compare_application_history", payload)
            .then((response) => {
                // console.log(response);
                setCompareApplicationHistoryData(response.data);
                setLoader(false);
            })
            .catch(() => {
                // console.log('error');
                setLoader(false);
            });
        }
    }, [modelFlag11]);
    
    const closeModal1 = () => {
        setCompareHistoryModalFlag1(false);
    }


return (
    <>
        <Transaction_menu panel_flag={panFlag}/>
        {loader ? <Loader /> : null}

         {/* ------------------- Compare previos history -------------------- */}

         {CompareHistoryModalFlag1 ?
            <Modal show={CompareHistoryModalFlag1} dialogClassName="modal-90w">
                <Modal.Header closeButton onClick={closeModal1} style={{ backgroundColor: "#B86512" }}>
                    <p style={{ height: "5px", fontSize: "14px", color: "white" }}><b>COMPARISON</b></p>
                </Modal.Header>
                <Modal.Body className="comparison-table" style={{height: "50vh", overflow: "auto"}}>
                    <div>
                        <div dangerouslySetInnerHTML={ {__html: CompareApplicationHistoryData} } />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={closeModal1} className="modal-submit-btn">
                    Back to application History 
                    </Button>
                </Modal.Footer>
            </Modal>
        : null }

        <div>
        <table width="100%" height="1024" id="mainContainer" border="0" cellPadding={0} cellSpacing={0} className="custom-body">
          <tbody>
            <tr valign="top">
            <td></td>
            <td width="100%">
                <div id="parent_wrapper" className="custom-parent_wrapper">
                </div>
                <table width="100%" border="0"  cellPadding={5} cellSpacing={5}>
                    <tbody>
                        <tr>
                           <td valign="top" align="center">
                            {/* <form name="command" method="post" onSubmit={handleSubmit} > */}
                             <table cellSpacing={1} className="defaultTbl no_caption_color" width="700">
                                <tbody>
                                    <tr>
                                        <th colSpan={2} className="text-center">Process Financial Application</th>
                                    </tr>
                                    <tr>
                                        <td className="caption" >Transaction #</td>
                                        <td >{ProcessFinanceDetails.transaction_id}</td>
                                    </tr>
                                    <tr>
                                        <td className="caption" >Finance House</td>
                                        <td>{ProcessFinanceDetails.finance_house}</td>
                                    </tr>
                                    <tr>
                                        <td className="caption" >Reference #</td>
                                        { ProcessFinanceDetails.reference_flag == 'condition1' ?
                                            <td>
                                                <span style={{display : ReferenceEditHideShow}}>{ProcessFinanceDetails.reference ? ProcessFinanceDetails.reference : ''}</span>&nbsp;

                                                <input type="button" name="command" value="Edit" onClick={ReferenceWiseShowHide} style={{display : ReferenceEditHideShow}} /> 

                                                <input name="reference" type="text" id="reference"  value={Reference} onChange = {(e) => setReference(e.target.value)} style={{display : ReferenceDataHideShow}}/>&nbsp;

                                                <input type="submit" name="command" value="Save Reference" style={{display : ReferenceDataHideShow}} onClick={(e) => { setReferenceSave(e.target.value); handleSubmitData(); }}/>&nbsp;

                                                <input type="button" value="Cancel" style={{display : ReferenceDataHideShow}} onClick={ReferenceEditWiseShowHide} />
                                            </td>
                                       
                                        : ProcessFinanceDetails.reference_flag == 'condition2' ?
                                            <td>
                                                <input type="button" name="command" value="Capture Reference" onClick={ReferenceWiseShowHide} style={{display : ReferenceEditHideShow}} /> 

                                                <input name="reference" type="text" id="reference"  value={Reference} onChange = {(e) => setReference(e.target.value)} style={{display : ReferenceDataHideShow}}/>&nbsp;

                                                <input type="submit" name="command" value="Save Reference" style={{display : ReferenceDataHideShow}} onClick={(e) => { setReferenceSave(e.target.value); handleSubmitData(); }}/>&nbsp;

                                                <input type="button" value="Cancel" style={{display : ReferenceDataHideShow}} onClick={ReferenceEditWiseShowHide} />
                                            </td>
                                        
                                        : 
                                            <td>
                                                <em>{ProcessFinanceDetails.reference_flag}</em>
                                            </td>
                                        }


                                    </tr>
                                    <tr>
                                        <td className="caption" >Account Number</td>
                                     
                                        { ProcessFinanceDetails.account_number_flag == 'ac_condition1' ?
                                            <td>
                                                <span style={{display : AccountNoEditHideShow}}>{ProcessFinanceDetails.account_number ? ProcessFinanceDetails.account_number : '' }</span>&nbsp;

                                                <input type="button" name="command" value="Edit" onClick={AccountNoWiseShowHide} style={{display : AccountNoEditHideShow}} /> 

                                                <input name="account_number" type="text" id="account_number"  value={AccountNumber} onChange = {(e) => setAccountNumber(e.target.value)} style={{display : AccountNoDataHideShow}}/>&nbsp;

                                                <input type="submit" name="command" value="Save Account Number" style={{display : AccountNoDataHideShow}} onClick={(e) => { setAccountNumberSave(e.target.value); handleSubmitData(); }} />&nbsp;

                                                <input type="button" value="Cancel" style={{display : AccountNoDataHideShow}} onClick={AccountNoEditWiseShowHide} />

                                            </td> 
                                            
                                         : ProcessFinanceDetails.account_number_flag == 'ac_condition2' ?
                                        
                                            <td>
                                                <span style={{display : AccountNoEditHideShow}}>{ProcessFinanceDetails.account_number ? ProcessFinanceDetails.account_number : '' }</span>&nbsp;
                                               
                                                { AccountNumber == '' ?
                                                  <input type="button" name="command" value="Capture Account Number" onClick={AccountNoWiseShowHide} style={{display : AccountNoEditHideShow}} /> 
                                                : 
                                                   <input type="button" name="command" value="Edit" onClick={AccountNoWiseShowHide} style={{display : AccountNoEditHideShow}} /> 
                                                }

                                                <input name="account_number" type="text" id="account_number"  value={AccountNumber} onChange = {(e) => setAccountNumber(e.target.value)} style={{display : AccountNoDataHideShow}}/>&nbsp;

                                                <input type="submit" name="command" value="Save Account Number" style={{display : AccountNoDataHideShow}} onClick={(e) => { setAccountNumberSave(e.target.value); handleSubmitData(); }} />&nbsp;

                                                <input type="button" value="Cancel" style={{display : AccountNoDataHideShow}} onClick={AccountNoEditWiseShowHide} />

                                            </td>
                                         :
                                           <td>
                                                <em>{ProcessFinanceDetails.account_number_flag}</em>
                                           </td>
                                        }
                                    </tr>
                                    <tr>
                                        <td className="caption" >Inception Status</td>
                                        { unincept_flag == 0 ?
                                        <td>
                                            {
                                                ProcessFinanceDetails.inception_status == 'Incept Transaction' || ProcessFinanceDetails.inception_status == 'Incepted' ?
                                            <Link to='/transaction/inception'>
                                                 <input type="button" value={ProcessFinanceDetails.inception_status} />
                                            </Link>
                                             :
                                               <em>{ProcessFinanceDetails.inception_status}</em>
                                            }
                                        </td>
                                        :
                                        <td>{ProcessFinanceDetails.inception_status}</td>
                                        }
                                    </tr>
                                  
                                    { ProcessFinanceDetails.sign_upload_pdf_flag == 'yes' ?
                                    <tr>
                                        <td className="caption" >Sign PDF</td>
                                        { ProcessFinanceDetails.upload_pdf == 'Upload' ? 
                                            <td>
                                                <input type="submit" name="command" value="Sign Now" target="_blank" />
                                            </td>
                                            : 
                                            <td>
                                                <em>{ProcessFinanceDetails.sign_pdf}</em>
                                            </td>
                                        }
                                    </tr>
                                    : null }
                                    
                                    { ProcessFinanceDetails.sign_upload_pdf_flag == 'yes' ?
                                    <tr>
                                        <td className="caption" >Upload PDF</td>
                                         {/* { ProcessFinanceDetails.upload_pdf == 'Upload' ? */}
                                            <td>
                                                <input type="file" name="signed_pdf" onChange={(e) => setFile(e.target.files[0])} /> 
                                                <input type="submit" name="command" value="Upload" onClick={SubmitSignPdf}/>
                                            </td>
                                            {/* : 
                                            <td>
                                               <em>{ProcessFinanceDetails.upload_pdf}</em>
                                            </td>
                                        } */}
                                    </tr>
                                     : null }

                                   
                                    { unincept_flag == 1 ?
                                        <tr>
                                            <td className="caption" >Finance app</td>
                                            <td className="text-left">
                                                <a href={ProcessFinanceDetails.pdf_url} target="_blank">
                                                    <input type="button" name="view" value="View Finance App" />&nbsp;
                                                </a>
                                            </td>
                                        </tr>
                                   
                                    : <tr>
                                        <td className="caption" >Finance app</td>
                                        { SubmitFinanceDetails.flag_condition != '' ?  
                                            <td className="text-center">
                                                
                                                    <iframe src={ProcessFinanceDetails.pdf_url} id="myIframe" height="350" width="600"></iframe>
                                                
                                            <br />
                                            <a href={ProcessFinanceDetails.pdf_url} target="_blank">
                                                <input type="button" name="view" value="View Full Screen" />&nbsp;
                                            </a>

                                                { loanhouse_id == 43 ?
                                                    <Link to="/transaction/finance_application">
                                                        <input type="submit" name="command2" value="Edit Application Form" />	
                                                    </Link> 
                                                : loanhouse_id == 44 ?
                                                    <Link to="/transaction/finance_application_standbic">
                                                     <input type="submit" name="command2" value="Edit Application Form" />	
                                                    </Link> 
                                                : loanhouse_id == 51 ?
                                                    <Link to="/transaction/finance_application_ford_finance">
                                                     <input type="submit" name="command2" value="Edit Application Form" />	
                                                    </Link> 
                                                : loanhouse_id == 45 ?
                                                    <Link to="/transaction/finance_application_bank_garborone">
                                                     <input type="submit" name="command2" value="Edit Application Form" />	
                                                    </Link>
                                                : loanhouse_id == 42 ?
                                                    <Link to="/transaction/finance_application_bank_absa">
                                                    <input type="submit" name="command2" value="Edit Application Form" />	
                                                    </Link> 
                                                : null } 
                                            
                                            </td>
                                        : 
                                            <td className="text-left">
                                                <a href={ProcessFinanceDetails.pdf_url} target="_blank">
                                                    <input type="button" name="view" value="View Finance app" />&nbsp;
                                                </a>
                                               
                                                { loanhouse_id == 43 ?
                                                    <Link to="/transaction/finance_application">
                                                        <input type="submit" name="command2" value="Edit Application Form" />	
                                                    </Link> 
                                                : loanhouse_id == 44 ?
                                                    <Link to="/transaction/finance_application_standbic">
                                                     <input type="submit" name="command2" value="Edit Application Form" />	
                                                    </Link> 
                                                : loanhouse_id == 51 ?
                                                    <Link to="/transaction/finance_application_ford_finance">
                                                     <input type="submit" name="command2" value="Edit Application Form" />	
                                                    </Link> 
                                                : loanhouse_id == 45 ?
                                                    <Link to="/transaction/finance_application_bank_garborone">
                                                     <input type="submit" name="command2" value="Edit Application Form" />	
                                                    </Link>
                                                : loanhouse_id == 42 ?
                                                    <Link to="/transaction/finance_application_bank_absa">
                                                    <input type="submit" name="command2" value="Edit Application Form" />	
                                                    </Link> 
                                                : null }
                                        
                                            </td>
                                        }
                                    </tr>
                                }
                                  
                                    <tr>
                                        <td className="caption" style={{textAlign: 'center'}} colSpan={2}>
                                            <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                 <input type="button" name="cancel" value="Back" />
                                            </Link>
                                        </td>
                                    </tr>
                                  
                                  </tbody>
                                </table>
                            {/* </form> */}
                           </td>
                        </tr>
    {/* ---------------------------------------- Submit Financial Application  ---------------------------- */}
                        {/* <tr>
                           <td> <br /></td>
                        </tr> */}

                        { SubmitFinanceDetails.flag_condition != null && unincept_flag == 0 && ProcessFinanceDetails.sign_upload_pdf_flag == 'yes' ?    
                        <tr>
                           <td valign="top" align="center">
                            <form name="clientDetails" method="post" onSubmit={handleSubmitToFinancer} >  
                             <table cellSpacing={1} className="defaultTbl no_caption_color" width="600">
                                <tbody>
                                    <tr>
                                        <th colSpan={4} className="text-center">Submit Financial Application</th>
                                    </tr>
                                    <tr>
                                        <td colSpan={4} className="caption" style={{textAlign: 'center'}}>Status: {SubmitFinanceDetails.status}</td>
                                    </tr>
                                    
                                    { SubmitFinanceDetails.flag_condition == 'condition1' ?  // 1
                                    <tr>
                                        <td colSpan={4} className="caption" style={{textAlign: 'center'}}>
                                            <strong>
                                                <em>{SubmitFinanceDetails.display_text ? SubmitFinanceDetails.display_text : ''}</em>
                                                </strong>
                                        </td>
                                    </tr>
                                    : SubmitFinanceDetails.flag_condition == 'condition2' ? // 2
                                       null
                                    : SubmitFinanceDetails.flag_condition == 'condition3' ? // 3
                                      
                                        <tr>
                                            <td colSpan={2} >
                                                <table width="100%">
                                                    <tbody>
                                                        <tr>
                                                           <td colSpan={2} className="caption" style={{textAlign: 'center'}}>Email options</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">CC Myself</td>
                                                            <td><input type="checkbox" name="cc_user" value={ccUser} onChange={(e) => { setccUser(!ccUser) }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">CC Client</td>
                                                            <td><input type="checkbox" name="cc_client" value={ccClient} onChange={(e) => { setccClient(!ccClient) }} /></td>
                                                        </tr>
                                                        <tr> 
                                                            <td className="caption">CC Other</td>
                                                            <td><input type="text" name="cc_other" size="18"  value={ccOther} onChange = {(e) => setccOther(e.target.value)} ref={ccOtherEmailRef}/></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>

                                            <td colSpan={2} valign="top">
                                                <table width="100%">
                                                    <tbody>
                                                        <tr>
                                                           <td className="caption" style={{textAlign: 'center', padding: '0px', verticalAlign: 'top'}} colSpan={2}>Include Documents</td>
                                                        </tr>
                                                      
                                                       {/* { SubmitFinanceDetails.loop_data == '' ?

                                                        <tr>
                                                            <td className="caption" colSpan={2}>
                                                              <em>There are no files upload in the documents section.</em>
                                                            </td>
                                                        </tr>
                                                        : null } */}

                                                    {DealerDocsDetails.length > 0 ?
                                                        DealerDocsDetails.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                    <td className="caption" width="50%">{item.document_type}</td>
                                                                    <td width="50%">
                                                                     
                                                                      <input type="checkbox" name={item.control_instance_number} value={item.file_value} onChange={(evnt) => {{  handleIncludeDocsChange(index, evnt)}}} />
                                                                    
                                                                      &nbsp;&nbsp;<a>{item.description}</a>
                                                                    </td>
                                                            </tr>
                                                        );
                                                    }) : 
                                                        <tr>
                                                            <td> THERE ARE NO FILES UPLOAD IN THE DOCUMENTS SECTION. </td>
                                                        </tr>
                                                    }
                                                      
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>

                                    : null
                                    }
                                    
                                    { SubmitFinanceDetails.flag_condition == 'condition3' ? 
                                        <tr>
                                            <td colSpan={4} className="caption" style={{textAlign: 'center'}}>
                                            <input type="submit" name="command" value="Submit to Financier" />
                                            </td>
                                        </tr>
                                    : null } 
                                </tbody>
                              </table>
                              </form>
                            </td>
                        </tr>
                        : null }

    {/* -------------------------- Documents------------------------------- */}
                        <tr>
                           <td></td>
                        </tr>
                    { DealerDocsDetails != '' ?        
                        <tr>
                           <td valign="top" align="center">
                            <form name="command" method="post" onSubmit={handleSubmitSendToBank} >
                             <table cellSpacing={1} className="defaultTbl no_caption_color" width="500">
                                <tbody>
                                    <tr>
                                        <th colSpan={4} className="text-center">Documents</th>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} className="caption" style={{textAlign: 'center'}}>Uploaded by bank user</td>
                                        <td colSpan={2} className="caption" style={{textAlign: 'center'}}>Uploaded by dealer</td>
                                    </tr>

                                    <tr>
                                        <td colSpan={2} width="50%"> 
                                            <table>
                                               <tbody>
                                                    <tr>
                                                            {/* <td className="caption" width="50%"></td>
                                                            <td width="50%">
                                                                <input type="button" value="View" />
                                                            </td> */}
                                                    </tr>
                                               </tbody>
                                            </table>
                                        </td>

                                        <td colSpan={2} width="50%"> 
                                            <table>
                                               <tbody>
                                               
                                                {DealerDocsDetails.length > 0 ?
                                                    DealerDocsDetails.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                                <td className="caption" width="50%">{item.document_type}</td>
                                                                <td width="50%">
                                                                { item.docs_flag == 2 ?
                                                                
                                                                   <input type="checkbox" name={item.control_instance_number} value={item.file_value} onChange={(evnt) => {{  handleBankChkChange(index, evnt)}}} />
                                                                
                                                                 : null } 
                                                                   &nbsp;<a href={item.display_file} target="_blank">{item.description}</a>
                                                                </td>
                                                        </tr>
                                                    );
                                                }) : null }
                                               </tbody>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={4} className="caption" style={{textAlign: 'center'}}>
                                            <input type="submit" name="command" value="Send To Bank" />
                                        </td>
                                    </tr>

                                </tbody>
                              </table>
                              </form>
                            </td>
                        </tr>
                    : null }
    {/* -------------------------- Application history------------------------------- */}
                    { DealerDocsDetails != '' ?    
                        <tr>
                           <td></td>
                        </tr>
                    : null }

                     { ApplicationHistoryDetails != '' ?           
                        <tr>
                           <td valign="top" align="center">
                            <table cellSpacing={1} className="defaultTbl no_caption_color" width="430">
                                <tbody>
                                    <tr>
                                        <th colSpan={4} className="text-center">Application History</th>
                                    </tr>
                                    <tr>
                                        <td colSpan={4} style={{textAlign: 'center'}}>
                                            <table>
                                               <tbody>
                                                    <tr>
                                                        <td className="caption">Submission Date</td>
                                                        <td className="caption">Status</td>
                                                        <td className="caption">Application</td>
                                                        { unincept_flag == 0 ?
                                                        <td className="caption">&nbsp;</td>
                                                        : null }
                                                    </tr>
                                                    
                                                    {ApplicationHistoryDetails.length > 0 ?
                                                       ApplicationHistoryDetails.map((item, index) => {
                                                       return (
                                                        item.signed_flag == 1 ?
                                                          <tr key={index}>
                                                            <td>{item.submission_date}</td>
                                                            <td>{item.status}</td>
                                                            <td>
                                                                <a href={item.file_path} target="_blank">{item.submission_date}.PDF</a>
                                                            </td>
                                                           
                                                            {/* { unincept_flag == 0 ? */}
                                                            <td>
                                                                { item.compare_button_flag == 'yes' ?   
                                                                  <input type="button" value="Compare to Previous" onClick={(e) => handleCompareToHitory(item.submission_date,item.loan_id,item.current_transaction_date)} />
                                                                : null } 
                                                            </td>
                                                             {/* : null } */}
                                                            </tr>
                                                          : null 
                                                       );
                                                    }) : null }

                                               </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                              </table>
                              {/* </form> */}
                            </td>
                        </tr>
                        : null }

                    </tbody>
                </table>
            </td>
            </tr>
          </tbody>
        </table>
        <Footer />
        </div>
    </>
);

}

export default Prepare