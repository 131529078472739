
import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link } from 'react-router-dom';
import { useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function User_rights() {
    document.title = "Administration | Seriti - Add User";
    const { employee } = useParams()
    const super_user = localStorage.getItem("super_user");//login super user
    const UserId = localStorage.getItem("UserId");
    const Modules = localStorage.getItem("Modules");
    const Modules_info = localStorage.getItem("Modules_info");
    const Levels = localStorage.getItem("Levels");
    const Access = localStorage.getItem("Access");

    const [userName, setUserName] = useState('')
    const [userDetails, setUserDetails] = useState([]);
    const [modules, setModules] = useState([]);

    const [role2, setRole] = useState('');
    const [allRole, setAllRole] = useState([]);
    const [fetch, setFetch] = useState(true);
    const [button, setButton] = useState('');
    const [UserRights, setUserRights] = useState('');
    const [IsThisSuperUser,setIsThisSuperUser] = useState('');

    // console.log(super_user);
    //---------------getting user rights----------------------//
    useEffect(() => {
        const payload = {
            employee: employee,
            role2: role2,
            super_user: super_user,
            user_id: employee,
            UserId: UserId,
            Modules: Modules,
            Modules_info: Modules_info,
            Levels: Levels,
            Access: Access
        }
        if (fetch === true || role2 !== '' || button !== '') {
            axios
                .post("admin/user_rights", payload)
                .then((response) => {
                    // console.log(response.data.data)
                    setFetch(false)
                    if (role2 === '') {
                        setRole(response.data.data.user[0].user_role)
                    } else {
                        setRole(role2)
                    }
                    setUserName(response.data.data.user[0].name)
                    setIsThisSuperUser(response.data.data.user[0].super_user)
                    setUserDetails(response.data.data.user);
                    setModules(response.data.data.modules);
                    setAllRole(response.data.data.roles)
                    setUserRights(response.data.data.UserRights)
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch(() => {
                    //setLoginLoader(false);
                });
        }
    }, [fetch, role2, button, employee, super_user, UserId, Modules, Modules_info, Levels, Access]);

    //---------------update user role with its rights and also activate/deactivate----------------------//
    const handleEdit = (e) => {
        e.preventDefault();
        // console.log(button);console.log(employee);console.log(role2);console.log(UserId);
        const payload = {
            button: button,
            super_user: super_user,
            employee: employee,
            role: role2,
            user_id: UserId,
            Modules: Modules,
            Modules_info: Modules_info,
            Levels: Levels,
            Access: Access
        };
        console.log(payload);
        axios
            .post("/admin/update_user_rights", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data.data); 
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setFetch(true)
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                // console.log(response.response.data.data);
                if (response.response.data.status === 400)
                toast.error(response.response.data.data, {
                    position: toast.POSITION.TOP_CENTER
                });
            });
    };

    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Admin_menu />
            <form method="post" action="" encType="multipart/form-data" onSubmit={handleEdit}>
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="500">
                                <table width="500" border={0} cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center"><p></p>
                                                <table className="defaultTbl" cellSpacing={1} align="center">
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan={7} className="text-center">ACCESS RIGHTS FOR {userName}</th>
                                                        </tr>
                                                        <tr className="caption">
                                                            <td colSpan={2}>MODULE</td>
                                                            <td>NONE</td>
                                                            <td>READ</td>
                                                            <td>WRITE</td>
                                                            <td>MODIFY</td>
                                                            <td>ADMIN</td>
                                                        </tr>
                                                        {
                                                            modules && modules.length ? (
                                                                modules.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td colSpan={2}>{item.title}</td>

                                                                            <td style={{ textAlign: 'center' }}>
                                                                                <input type="radio" disabled checked={(item.level === 0) ? true : false} ></input>
                                                                            </td>
                                                                            <td style={{ textAlign: 'center' }}>
                                                                                <input type="radio" disabled checked={(item.level === 1) ? true : false}></input>
                                                                            </td>
                                                                            <td style={{ textAlign: 'center' }}>
                                                                                <input type="radio" disabled checked={(item.level === 2) ? true : false}></input>
                                                                            </td>
                                                                            <td style={{ textAlign: 'center' }}>
                                                                                <input type="radio" disabled checked={(item.level === 3) ? true : false}></input>
                                                                            </td>
                                                                            <td style={{ textAlign: 'center' }}>
                                                                                <input type="radio" disabled checked={(item.level === 4) ? true : false}></input>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : <tr><td colSpan={7}>"NO USER(S) WERE FOUND MATCHING YOUR SEARCH CRITERIA"</td></tr>
                                                        }

                                                        <tr>
                                                            <td className="caption" style={{ textAlign: 'center' }} colSpan={2}>User Role</td>
                                                            <td className="caption" colSpan={4} style={{ textAlign: 'center' }}>
                                                                <select name="role2" value={role2} onChange={(e) => setRole(e.target.value)}>
                                                                    <option value="0">-- Select Option --</option>

                                                                    {allRole.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id} >
                                                                                {item.role}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                            <td className="caption" style={{ textAlign: 'center' }}>
                                                                <input type="submit" name="command" value="Update" onClick={(e) => setButton(e.target.value)} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr><tr>
                                            <td>
                                                <table className="defaultTbl" cellSpacing={1} align="center">
                                                    <tbody>
                                                        <tr>
                                                            <th>Username</th>
                                                            <th>Real Name</th>
                                                            <th>Email</th>
                                                            <th width="10">Active</th>
                                                            <th width="10">Edit</th>
                                                            <th width="1">Access</th>
                                                        </tr>
                                                        {
                                                            userDetails && userDetails.length ? (
                                                                userDetails.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{item.username}</td>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.email}</td>

                                                                            {
                                                                                UserRights > 4 ?
                                                                                    <td className="text-center" width="50%">
                                                                                        {item.active === "y" ?
                                                                                            <input type="submit" value="De-activate" onClick={(e) => setButton('deactivate')} />
                                                                                            :
                                                                                            <input type="submit" value="Activate" onClick={(e) => setButton('activate')} />}
                                                                                    </td>
                                                                                    :
                                                                                    <td className="text-center" width="50%">
                                                                                        {(item.active === "y") ? 'Yes' : 'No'}
                                                                                    </td>
                                                                            }
                                                                            <td>
                                                                                <Link to={"/admin/profile/" + item.id} className="btn btn-result-open">
                                                                                    <input type="submit" value="Edit" />
                                                                                </Link>
                                                                            </td>

                                                                            <td>
                                                                                <Link to={"/admin/branch_access/" + item.id} className="btn btn-result-open">
                                                                                    <input type="button" value="Access" />
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : <tr><td colSpan={6}>"NO USER(S) WERE FOUND MATCHING"</td></tr>
                                                        }
                                                        <tr className="caption"><td colSpan="6">&nbsp;</td></tr>
                                                    </tbody>
                                                </table>
                                                <br/>
                                                <p className="text-center">{IsThisSuperUser === 1 ? "This user has Super User rights, which effectively gives them all rights to all modules." : null}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody >
                </table >
            </form >
        </>
    );
}

export default User_rights