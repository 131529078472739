import { Link, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import Transaction_menu from './Transaction_menu';
import Footer from "../Dashboard/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "../axios";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { NumericFormat } from 'react-number-format';
import absa_img from '../Images/logo/absa.png';
import Loader from "../Components/Loader"

const Finance_application_bank_absa = () => {
    
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [MaritalStatus, setMaritalStatus] = useState("");
    const [MaritalStatusAll, setMaritalStatusAll] = useState([]);
    const [Title, setTitle] = useState("");
    const [TitleAll, setTitleAll] = useState([]);
    const [ResidencyStatus, setResidencyStatus] = useState(0);
    const [ResidencyStatusAll, setResidencyStatusAll] = useState([]);
    const [ResidentialStatus, setResidentialStatus] = useState("");
    const [ResidentialStatusAll, setResidentialStatusAll] = useState([]);
    const [ClientDetails, setClientDetails] = useState("");
    const [ClientDetailsAll, setClientDetailsAll] = useState([]);
    const [Gender, setGender] = useState("");
    const [GenderAll, setGenderAll] = useState([]);
    const [IncomeCategoryAll, setIncomeCategoryAll] = useState([]);
    const [FinantialProviderLoan1, setFinantialProviderLoan1] = useState("");
    const [FinantialProviderLoan2, setFinantialProviderLoan2] = useState("");
    const [FinantialProviderAll, setFinantialProviderAll] = useState([]);
    const [FinantialCompany, setFinantialCompany] = useState("");
    const [FinantialCompanyAll, setFinantialCompanyAll] = useState([]);
    const [TermMonth, setTermMonth] = useState("");
    const [TermMonthAll, setTermMonthAll] = useState([]); 
    const [FinanceType, setFinanceType] = useState("");
    const [FinanceTypeAll, setFinanceTypeAll] = useState([]); 
    const [ConsumerSegmentAll, setConsumerSegmentAll] = useState([]);
    const [BusinessSegment, setBusinessSegment] = useState("");
    const [BusinessSegmentAll, setBusinessSegmentAll] = useState([]); 
    const [SalaryDate, setSalaryDate] = useState("");
    const [SalaryDateAll, setSalaryDateAll] = useState([]); 
    
    const [SpouseDetails, setSpouseDetails] = useState("");
    const [SpouseDetailsAll, setSpouseDetailsAll] = useState([]);
    const [SpouseTitle, setSpouseTitle] = useState("");
    const [SpouseResidencyStatus, setSpouseResidencyStatus] = useState(0);
    const [SpouseGender, setSpouseGender] = useState("");

    //------- contact details
    const [HighestEducationAll, setHighestEducationAll] = useState([]);
    const [HighestEducation, setHighestEducation] = useState("");
    const [IndividualAll, setIndividualAll] = useState([]);
    const [ContactIndividual, setContactIndividual] = useState("");
    const [StaffIndicatorAll, setStaffIndicatorAll] = useState([]);
    const [StaffIndicator, setStaffIndicator] = useState("");
  
    const [ContactDetailsAll, setContactDetailsAll] = useState([]);
    const [RelationshipAll, setRelationshipAll] = useState([]);
    const [ContactTitleAll, setContactTitleAll] = useState([]);
    const [ContactDetailsSave, setContactDetailsSave] = useState("");
    const [PrintButtonData, setPrintButtonData] = useState("");

    const [ContactDetails, setContactDetails] = useState("");
    const [ContactRelationship, setContactRelationship] = useState("");
    const [ContactTitle, setContactTitle] = useState("");
    const [ContactFirstName, setContactFirstName] = useState("");
    const [ContactSurnameName, setContactSurnameName] = useState("");
    const [ContactMobileNo, setContactMobileNo] = useState("");
    const [ContactHomePhone, setContactHomePhone] = useState("");
    const [ContactWorkPhone, setContactWorkPhone] = useState("");
    const [ContactPhysicalAddress, setContactPhysicalAddress] = useState("");
    const [ContactEmployerName, setContactEmployerName] = useState("");
    const [ContactDetId, setContactDetId] = useState(0);
    const [ContactOccupation, setContactOccupation] = useState("");
   
    // ----------------- EMPLOYEE DETAILS
    const [EmpIncomeCategoryAll, setEmpIncomeCategoryAll] = useState([]); // dropdown array
    const [EmpIncomeCategory, setEmpIncomeCategory] = useState("");
    const [EmpSalaryDateAll, setEmpSalaryDateAll] = useState([]); // dropdown array
    const [EmpSalaryDate, setEmpSalaryDate] = useState("");
   
    const [EmpEmployer, setEmpEmployer] = useState("");
    const [EmpOccupation, setEmpOccupation] = useState("");
    const [EmpEmpoyeeHash, setEmpEmpoyeeHash] = useState("");
    const [Department, setDepartment] = useState("");
    const [EmpContactNo, setEmpContactNo] = useState("");
    const [EmpSupervisor, setEmpSupervisor] = useState("");
    const [EmpAddress, setEmpAddress] = useState("");
    const [BussinessActivity, setBussinessActivity] = useState("");

    const [TelephoneHome, setTelephoneHome] = useState("");
    const [TelephoneWork, setTelephoneWork] = useState("");
    const [EmailAddress, setEmailAddress] = useState("");
    const [EmpMobileNumber, setEmpMobileNumber] = useState("");

    const [DateAccountOpened, setDateAccountOpened] = useState('');
    const  FinalDateAccountOpened = DateAccountOpened != '' ? moment(DateAccountOpened).format("YYYY-MM-DD") : 0;
    const [EmployeeFrom, setEmployeeFrom] = useState('');
    const  FinalEmployeeFrom = EmployeeFrom != '' ? moment(EmployeeFrom).format("YYYY-MM-DD") : 0;
    const [EmployeeTo, setEmployeeTo] = useState('');
    const  FinalEmployeeTo = EmployeeTo != '' ? moment(EmployeeTo).format("YYYY-MM-DD") : 0;
    const [ContractExpDate, setContractExpDate] = useState('');
    const  FinalContractExpDate = ContractExpDate != '' ? moment(ContractExpDate).format("YYYY-MM-DD") : 0;
    
    const [colorFocus, setcolorFocus] = useState("");
    const [colorFocus1, setcolorFocus1] = useState("");
    const [colorFocus2, setcolorFocus2] = useState("");
    const [colorFocus3, setcolorFocus3] = useState("");
    const [colorFocus4, setcolorFocus4] = useState("");
    const [colorFocus5, setcolorFocus5] = useState("");
    const [colorFocus6, setcolorFocus6] = useState("");
    const [colorFocus7, setcolorFocus7] = useState("");

    const [colorFocus11, setcolorFocus11] = useState("");
    const [colorFocus12, setcolorFocus12] = useState("");
    const [colorFocus13, setcolorFocus13] = useState("");


    const [EmployeeDetailsSave, setEmployeeDetailsSave] = useState("");
      // console.log(focusDate);

    // STATE FOR FLAGS
    const [ClientDetailFlag, setClientDetailFlag] = useState(true)
    const [EmployeeDetailFlag, setEmployeeDetailFlag] = useState(false)
    const [FinancialDetailFlag, setFinancialDetailFlag] = useState(true)
    const [LoanDetailFlag, setLoanDetailFlag] = useState(false)
    const [SpouseDetailFlag, setSpouseDetailFlag] = useState(false)
    const [ContactDetailFlag, setContactDetailFlag] = useState(false)
   
    //------------------- manage date flag
    const [BirthdateFlag, setBirthdateFlag] = useState(0);
    const [CurrentdateFlag, setCurrentdateFlag] = useState(0);
    const [PrevdateFlag, setPrevdateFlag] = useState(0);
    const [MarrieddateFlag, setMarrieddateFlag] = useState(0);
    const [SpouseBirthdateFlag, setSpouseBirthdateFlag] = useState(0);
    const [SpouseDateEmployeeFlag, setSpouseDateEmployeeFlag] = useState(0);
    const [LoanCommencementDateFlag, setLoanCommencementDateFlag] = useState(0);
    const [LoanFirstDebitOrderDateFlag, setLoanFirstDebitOrderDateFlag] = useState(0);
    const [EmployeeFromFlag, setEmployeeFromFlag] = useState(0);
    const [EmployeeToFlag, setEmployeeToFlag] = useState(0);
    const [PurchaseDateFlag, setPurchaseDateFlag] = useState(0);
    const [LoanExpDateFlag, setLoanExpDateFlag] = useState(0);
    const [CardExpiryDateFlag, setCardExpiryDateFlag] = useState(0);
    const [AccountWithBarclaysDateFlag, setAccountWithBarclaysDateFlag] = useState(0);
   
    // DATA STATE FOR CLIENT DETAILS
    const [DateOfBirth, setDateOfBirth] = useState('');
    const  FinalDateOfBirth = DateOfBirth != '' ? moment(DateOfBirth).format("YYYY-MM-DD") : 0;
    const [Firstname, setFirstname] = useState('');
    const [Surname, setSurname] = useState('');
    const [IDNumber, setIDNumber] = useState('');
    const [PlaceOfBirth, setPlaceOfBirth] = useState('');
    const [HomeNumber, setHomeNumber] = useState('');
    const [MobileNumber, setMobileNumber] = useState('');
    const [WorkNumber, setWorkNumber] = useState('');
    const [FixNumber, setFixNumber] = useState('');
    const [Email, setEmail] = useState('');
    const [Ward, setWard] = useState('');
    const [PhysicalAddress1, setPhysicalAddress1] = useState('');
    const [PhysicalAddress2, setPhysicalAddress2] = useState('');
    const [PostalAddress1, setPostalAddress1] = useState('');
    const [PostalAddress2, setPostalAddress2] = useState('');
    const [PhysicalAddressCity, setPhysicalAddressCity] = useState('');
    const [PostalAddressCity, setPostalAddressCity] = useState('');
    const [PhysicalAddressPostCode, setPhysicalAddressPostCode] = useState('');
    const [PostalAddressCode, setPostalAddressCode] = useState('');
    const [HomeVillage, setHomeVillage] = useState('');
    const [NearestLandmark, setNearestLandmark] = useState('');
    const [IssuingOffice, setIssuingOffice] = useState('');
    const [ClientRelationshipManager, setClientRelationshipManager] = useState('');
    const [MarriedDate, setMarriedDate] = useState('');
    const FinalMarriedDate = MarriedDate != '' ? moment(MarriedDate).format("YYYY-MM-DD") : 0;
    const [OccupancyPreviousDate, setOccupancyPreviousDate] = useState('');
    const FinalOccupancyPreviousDate = OccupancyPreviousDate != '' ? moment(OccupancyPreviousDate).format("YYYY-MM-DD") : 0;
    const [OccupancyCurrentDate, setOccupancyCurrentDate] = useState('');
    const FinalOccupancyCurrentDate = OccupancyCurrentDate != '' ? moment(OccupancyCurrentDate).format("YYYY-MM-DD") : 0;
    const [Dependant, setDependant] = useState("");
    const [OwnerPropertyTypeAll, setOwnerPropertyTypeAll] = useState([])
    const [OwnerPropertyType, setOwnerPropertyType] = useState("")
    const [FiClientId, setFiClientId] = useState("")

    const [OwnHomeColor, setOwnHomeColor] = useState("");
    const [MonthLeftColor, setMonthLeftColor] = useState("");
    const [HiddenTotalIncome, setHiddenTotalIncome] = useState("")
    const [HiddenSurplus, setHiddenSurplus] = useState("");
    const [MonthinstallReadonly, setMonthinstallReadonly] = useState("");
    const [MonthinstallReadonly1, setMonthinstallReadonly1] = useState("");

    const [RentalValueBorder, setRentalValueBorder] = useState("");
    const [BondMonthlyBorder, setBondMonthlyBorder] = useState("");
    const [DefectSurpulse, setDefectSurpulse] = useState("SURPLUS");
    
    //------------------------- FINANCE CLIENT DETAILS ---------------------------------------
    const [GarentorSeftyAll, setGarentorSeftyAll] = useState([])
    const [DeclearedInsolvent, setDeclearedInsolvent] = useState("")
   
    const [RehabilitationDate, setRehabilitationDate] = useState('');
    const FinalRehabilitationDate = RehabilitationDate != '' ? moment(RehabilitationDate).format("YYYY-MM-DD") : 0;
    const [PurchaseDate, setPurchaseDate] = useState("")
    const FinalPurchaseDate = PurchaseDate != '' ? moment(PurchaseDate).format("YYYY-MM-DD") : 0;
    
    const [GrossMonthlySalary, setGrossMonthlySalary] = useState("")
    const [Commission6MonthAvg, setCommission6MonthAvg] = useState("")
    const [Overtime6MonthAvg, setOvertime6MonthAvg] = useState("")
    const [MonthlyAllowance, setMonthlyAllowance] = useState("")
    const [MonthlyCarAllowance, setMonthlyCarAllowance] = useState("")
    const [PaySlipMAllowance, setPaySlipMAllowance] = useState("")
    const [AllOtherIncome, setAllOtherIncome] = useState("")
    const [TotalIncome, setTotalIncome] = useState("")
    const [GranttedBouns, setGranttedBouns] = useState("")
    const [ExistingLoan, setExistingLoan] = useState(0)
    const [ToBeSealed1, setToBeSealed1] = useState(0)
    const [MonthlyInvestment1, setMonthlyInvestment1] = useState("")
    const [ToBeSealed2, setToBeSealed2] = useState(0)
    const [MonthlyInvestment2, setMonthlyInvestment2] = useState("")
    const [FinanceTransactionId, setFinanceTransactionId] = useState("")
    
    const [CreditCardName, setCreditCardName] = useState("")
    const [CardNumber, setCardNumber] = useState("")
    const [CardExpiryDate, setCardExpiryDate] = useState("")
    const FinalCardExpiryDate = CardExpiryDate != '' ? moment(CardExpiryDate).format("YYYY-MM-DD") : 0;
    const [ExistingAccountHolder, setExistingAccountHolder] = useState("")
    const [SalaryPaid, setSalaryPaid] = useState("")
    const [TransferAccount, setTransferAccount] = useState("")
    const [AccountWithBarclaysDate, setAccountWithBarclaysDate] = useState("")
    const FinalAccountWithBarclaysDate = AccountWithBarclaysDate != '' ? moment(AccountWithBarclaysDate).format("YYYY-MM-DD") : 0;
    const [SettlementAmount1, setSettlementAmount1] = useState("")
    const [SettlementAmount2, setSettlementAmount2] = useState("")
    
    const [FinanaceTotal, setFinanaceTotal] = useState("")
    const [DetailsOfGuarantee, setDetailsOfGuarantee] = useState("")
    const [ProofDocs, setFile] = useState("");

    const [AccountNumber, setAccountNumber] = useState("")
    const [previousBranch, setpreviousBranch] = useState("")
    const [Installment, setInstallment] = useState("")
    const [ToBeSealed3, setToBeSealed3] = useState(786)
    const [Settlement, setSettlement] = useState("")
    const [MonthLeft, setMonthLeft] = useState("")
    const [OwnHome, setOwnHome] = useState(0)
    const [RentalValue, setRentalValue] = useState("")
    const [LandlordName, setLandlordName] = useState("")
    const [LandlordAddress, setLandlordAddress] = useState("")
    const [MonthlyBond, setMonthlyBond] = useState("")
    const [BondHolder, setBondHolder] = useState("")
    const [PurchasePrice, setPurchasePrice] = useState("")
    const [MarketValue, setMarketValue] = useState("")
    const [PovertyName, setPovertyName] = useState("")
    const [BondAmount, setBondAmount] = useState("")
    const [StandNumber, setStandNumber] = useState("")
    const [Suburb, setSuburb] = useState("")
    const [Surplus, setSurplus] = useState("")
    const [ClientsFinanceDetSave, setClientsFinanceDetSave] = useState("")
    
    const [Last6MonthStmtSubmitted, setLast6MonthStmtSubmitted] = useState("");
    const [FinancialsSubmitted, setFinancialsSubmitted] = useState("786");
    const [PeriodSselfEmployee, setPeriodSselfEmployee] = useState("786");
    const [SelfEmpHideShow, setSelfEmpHideShow] = useState("none");
    const [ParmentHideShow, setParmentHideShow] = useState("");

    //----------------------- LOAN SPECIFIES ---------------------------------------------------
    const [LoanValidateColor, setLoanValidateColor] = useState("");
    
    //const [LoanTransaction, setLoanTransaction] = useState("")
    const [LoanTransactionDisplay, setLoanTransactionDisplay] = useState("")
    const [CommencementDate, setCommencementDate] = useState("")
    const FinalCommencementDate = CommencementDate != '' ? moment(CommencementDate).format("YYYY-MM-DD") : '1970-01-01';
    const [InterestType, setInterestType] = useState("")
    const [LoanExpDate, setLoanExpDate] = useState("")
    const FinalLoanExpDate = LoanExpDate != '' ? moment(LoanExpDate).format("YYYY-MM-DD") : '1970-01-01';
    const [FirstDebitOrder, setFirstDebitOrder] = useState("")
    const FinalFirstDebitOrder = FirstDebitOrder != '' ? moment(FirstDebitOrder).format("YYYY-MM-DD") : '1970-01-01';
    const [SaleType, setSaleType] = useState("")
    const [InterestRate, setInterestRate] = useState("")
    const [PreArrangeSceme, setPreArrangeSceme] = useState(0)
    const [BankFee, setBankFee] = useState("")
    const [LoanEquity, setLoanEquity] = useState("")
    const [LoanAffordability, setLoanAffordability] = useState("")
    const [LoanDepositPercentage, setLoanDepositPercentage] = useState("")
    const [LoanAfforabilityInsurance, setLoanAfforabilityInsurance] = useState("")
    const [LoanAssetAmount, setLoanAssetAmount] = useState("")
    const [PreffredPaymentDate, setPreffredPaymentDate] = useState("")
    const [LoanInsuranceProduct, setLoanInsuranceProduct] = useState("")
    const [LoanNonInsuranceProduct, setLoanNonInsuranceProduct] = useState("")
    const [LoanCompersiveInsurance, setLoanCompersiveInsurance] = useState("")
    const [LoanDiscount, setLoanDiscount] = useState("")
    const [CreditLifeInsurance, setCreditLifeInsurance] = useState("")
    const [LoanCashDepositValue, setLoanCashDepositValue] = useState("")
    const [LoanNettTradeDeposit, setLoanNettTradeDeposit] = useState("")
    const [LoanTransactionFee, setLoanTransactionFee] = useState("")
    const [LoanFinanceAmount, setLoanFinanceAmount] = useState("")
    const [LoanSpecificsDetSave, setLoanSpecificsDetSave] = useState("")
    const [LoanCommencementFlag, setLoanCommencementFlag] = useState(0);
    
   //------------------------- SPOUSE DETAILS -----------------------------------------------
    const [SpouseFirstName, setSpouseFirstName] = useState("")
    const [SpouseInitial, setSpouseInitial] = useState("")
    const [SpouseSurname, setSpouseSurname] = useState("")
    const [SpouseIdNumber, setSpouseIdNumber] = useState("")
    const [SpouseDOB, setSpouseDOB] = useState("")
    const FinalSpouseDOB = SpouseDOB != '' ? moment(SpouseDOB).format("YYYY-MM-DD") : 0;
    const [SpouseHomePhone, setSpouseHomePhone] = useState("")
    const [SpouseWorkPhone, setSpouseWorkPhone] = useState("")
    const [SpouseMobilePhone, setSpouseMobilePhone] = useState("")
    const [SpouseEmail, setSpouseEmail] = useState("")
    const [SpousePhysicalAdd1, setSpousePhysicalAdd1] = useState("")
    const [SpousePhysicalAdd2, setSpousePhysicalAdd2] = useState("")
    const [SpousePostalAdd1, setSpousePostalAdd1] = useState("")
    const [SpousePostalAdd2, setSpousePostalAdd2] = useState("")
    const [SpousePhysicalAddCity, setSpousePhysicalAddCity] = useState("")
    const [SpousePostalAddCity, setSpousePostalAddCity] = useState("")
    const [SpouseOccupation, setSpouseOccupation] = useState("")
    const [SpouseEmployerName, setSpouseEmployerName] = useState("")
    const [SpouseEmployerAdd, setSpouseEmployerAdd] = useState("")
    const [SpouseDate, setSpouseDate] = useState("")
    const FinalSpouseDate = SpouseDate != '' ? moment(SpouseDate).format("YYYY-MM-DD") : 0;
    const [SpouseIncome, setSpouseIncome] = useState("")
    const [SpouseId, setSpouseId] = useState(0)
    
    const [GarentorTitleAll, setGarentorTitleAll] = useState([]);
    const [SpouseName, setSpouseName] = useState("")
    const [SpouseChidren, setSpouseChidren] = useState("")
    const [SpouseOther, setSpouseOther] = useState("")
    const [GarentorSpouseTitle, setGarentorSpouseTitle] = useState("")
    const [GarentorFirstName, setGarentorFirstName] = useState("")
    const [GarentorSurname, setGarentorSurname] = useState("")
    const [GarentorMobileNo, setGarentorMobileNo] = useState("")
    const [ContactNationalityAll, setContactNationalityAll] = useState([]);
    const [ContactNationality, setContactNationality] = useState("")
    const [SpouseDetSave, setSpouseDetSave] = useState("")

    const [ValidateColor, setValidateColor] = useState("");
    const [ValidateColor1, setValidateColor1] = useState("");
    const [ValidateColor2, setValidateColor2] = useState("");
    const [ClientDetSave, setClientDetSave] = useState([]);
    const [LoanhouseLogo, setLoanhouseLogo] = useState([]);

    const [PrintButton, setPrintButton] = useState("");

    const [div_id1, setHide1] = useState("table");
    const [div_id2, setHide2] = useState("none");
    const [div_id3, setHide3] = useState("none");
    const [div_id4, setHide4] = useState("none");
    const [div_id5, setHide5] = useState("none");
    const [div_id6, setHide6] = useState("none");
    const [tabWidth, settabWidth] = useState("800");
    
    const [PropertyHideShow, setPropertyHideShow] = useState("");
    const [PropertyHideShow1, setPropertyHideShow1] = useState("none");
    const [Loan1HideShow, setLoan1HideShow] = useState("none");
    const [Loan2HideShow, setLoan2HideShow] = useState("none");
    const [OtherExistingHideShow, setOtherExistingHideShow] = useState("");
    const [AccountHolderHideShow, setAccountHolderHideShow] = useState("none");
    const [PrintButtonVisible, setPrintButtonVisible] = useState(false);

    const division_id = localStorage.getItem("division_id");
    const department_id = localStorage.getItem("department_id");
    const system_type = localStorage.getItem("system_type");
    const user_id = localStorage.getItem("UserId");
    const transaction_id = localStorage.getItem("transaction_id");
    const client_id = localStorage.getItem("client_id");
    const group_id = localStorage.getItem("group_id");
    const application_id = localStorage.getItem("application_id");
    const instance_id = localStorage.getItem("instance_id");
    const company_id = localStorage.getItem("company_id");
    const loanhouse_id = localStorage.getItem("loanhouse_id");
    const AbsaBankTabId = localStorage.getItem("AbsaBankTabId");

    const [HeaderFlag, setHeaderFlag] = useState(1);
    
    useEffect(() => {
        document.title = 'Transactions | Seriti BW';
    }, []);
    
    //----------- get owner property type -----------------
    useEffect(() => {  
        if(AbsaBankTabId === '91'){ 
            setLoader(true); 
            axios.get("finance_application_absa/fetach_absa_client_details_dropdown")
            .then((response) => {
            //console.log(response);
                if(response.status === 200){
                setOwnerPropertyTypeAll(response.data.data.owner_property_type_array);
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false); 
            });
        }
    }, [AbsaBankTabId]);

    //----------- get higest education / individual / staff indicator / garentor sefty -----------------
    // useEffect(() => {   
    //     axios
    //     .get("finance_application_standbic/fetach_standbic_client_details_dropdown")
    //     .then((response) => {
    //        // console.log(response);
    //         if(response.status === 200){
    //            setHighestEducationAll(response.data.data.highets_level_education_array);
    //            setIndividualAll(response.data.data.individual_array);
    //            setStaffIndicatorAll(response.data.data.staff_indicator_array);
    //            setGarentorSeftyAll(response.data.data.garentor_sefty_array);
    //            //setDeclearedInsolventAll(response.data.data.garentor_sefty_array);
    //         } 
    //     })
    //     .catch(() => {
    //         console.log('error');
    //     });
    // }, []);
   
    //----------- get client title -----------------
    useEffect(() => {   
        if(AbsaBankTabId === '91' || AbsaBankTabId === '95' || AbsaBankTabId === '96'){   
            setLoader(true); 
            axios.get("add_edit_company/featch_titles")
            .then((response) => {
            // console.log(response.data.data.customer_type_array);
                if(response.status === 200){
                setTitleAll(response.data.data.title_array);
                setContactTitleAll(response.data.data.title_array);
                setGarentorTitleAll(response.data.data.title_array);
                }
            })
            .catch(() => {
                console.log('error');
                setLoader(false); 
            });
        }
    }, [AbsaBankTabId]);

    //----------- get residency status -----------------
    useEffect(() => {   
        if(AbsaBankTabId === '91' || AbsaBankTabId === '95'){  
            setLoader(true);    
            axios.get("add_edit_company/featch_residency_status")
            .then((response) => {
                if(response.status === 200){
                setResidencyStatusAll(response.data.data.residency_status_array);
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false); 
            });
        }
    }, [AbsaBankTabId]);

    //----------- get residential_status -----------------
    useEffect(() => {   
        if(AbsaBankTabId === '91'){    
            setLoader(true);    
            axios.get("add_edit_company/featch_residential_status")
            .then((response) => {
                if(response.status === 200){
                  setResidentialStatusAll(response.data.data.residential_status);
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false);    
            });
        }
    }, [AbsaBankTabId]);

    //----------- get marital status-----------------
    useEffect(() => {   
        if(AbsaBankTabId === '91'){   
            setLoader(true);     
            const payload = {
                division_id: division_id,
                transaction_id: transaction_id,
            };
            axios.post("add_edit_company/featch_marital_status",payload)
            .then((response) => {
                if(response.status === 200){
                   setMaritalStatusAll(response.data.data.marital_status_array);
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false);    
            });
        }
    }, [AbsaBankTabId]);

    //----------- get gender -----------------
    useEffect(() => {  
        if(AbsaBankTabId === '95'){ 
            setLoader(true);         
            axios.get("add_edit_company/featch_gender")
            .then((response) => {
                if(response.status === 200){
                  setGenderAll(response.data.data.gender_array);
                }
            })
            .catch(() => {
                console.log('error');
                setLoader(false);    
            });
        }
    }, [AbsaBankTabId]);

    //----------- get higest education / individual / staff indicator -----------------
    useEffect(() => {  
        if(AbsaBankTabId === '92'){ 
            setLoader(true);       
            axios.get("finance_application_standbic/fetach_standbic_employee_details_dropdown")
            .then((response) => {
                //console.log(response);
                if(response.status === 200){
                  setEmpIncomeCategoryAll(response.data.data.income_category_array);
                  setEmpSalaryDateAll(response.data.data.salary_date);
                } 
            }) 
            .catch(() => {
                console.log('error');
                setLoader(false);      
            });
        }
    }, [AbsaBankTabId]);

    //----------- get client title -----------------
    useEffect(() => {    
        if(AbsaBankTabId === '95'){  
            setLoader(true);     
            axios.get("finance_application_standbic/fetach_standbic_contact_nationality_dropdown")
            .then((response) => {
            //console.log(response);
                if(response.status === 200){
                  setContactNationalityAll(response.data.data.contact_nationality);
                }
            })
            .catch(() => {
                console.log('error');
                setLoader(false); 
            });
        }
    }, [AbsaBankTabId]);

    //----------- featch client details data-----------------
    useEffect(() => {   
        if(AbsaBankTabId === '91'){  
            setLoader(true);  
            const payload = {
                instance_id: instance_id,
            };
            axios.post("finance_application_absa/featch_absa_client_details_id",payload)
            .then((response) => {
            //console.log(response);
                if(response.status === 200){
                    setFiClientId(response.data.data);
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false);  
            });
        }
    }, [AbsaBankTabId]);

    useEffect(() => { 
        if(FiClientId){  
            setLoader(true);   
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                company_id: FiClientId,
            };
            axios.post("finance_application_absa/featch_absa_finance_client_details",payload)
            .then((response) => {
            //console.log(response);
                if(response.status === 200){
                    const obj = response.data.data.client_details_array;
                // setClientDetailsArray(obj);
                    setFirstname(obj.firstname);
                    setClientDetails(obj.client_details);
                    setTitle(obj.title);
                    setSurname(obj.surname);
                    setIDNumber(obj.id_number);
                    setHomeNumber(obj.home_number);
                    setMobileNumber(obj.mobile_number);
                    setWorkNumber(obj.work_number);
                    setFixNumber(obj.fax_number);
                    setEmail(obj.email);
                    setPhysicalAddress1(obj.physical_address_lane1);
                    setPhysicalAddress2(obj.physical_address_lane2);
                    setPostalAddress1(obj.postal_address_lane1);
                    setPostalAddress2(obj.postal_address_lane2);
                    setPhysicalAddressCity(obj.physical_address_city);
                    setPostalAddressCity(obj.postal_address_city);
                    setResidentialStatus(obj.residential_status);
                    setResidencyStatus(obj.residancy_status);
                    setGender(obj.gender);
                    setMaritalStatus(obj.marial_status);
                    setDependant(obj.number_of_dependent);
                    setDateOfBirth(obj.date_of_birth);
                    setOccupancyCurrentDate(obj.occupancy_date);
                    setOccupancyPreviousDate(obj.occupancy_prev_date);
                    setMarriedDate(obj.date_married);
                    setHighestEducation(obj.highest_education);
                    setPhysicalAddressPostCode(obj.physical_address_code);
                    setPostalAddressCode(obj.postal_address_code);
                    setHomeVillage(obj.home_village);
                    setNearestLandmark(obj.neraest_landmark);
                    setContactIndividual(obj.individual);
                    setStaffIndicator(obj.staff_indicator);
                    setWard(obj.ward);
                    setIssuingOffice(obj.issuing_office);
                    setOwnerPropertyType(obj.owner_property_type);
                    setClientRelationshipManager(obj.client_relationship_manager);
                    setPlaceOfBirth(obj.place_of_birth);
                    setLoader(false);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }   
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
        }
    }, [FiClientId,AbsaBankTabId]); 

    const OnClientWiseChange = () => {
        if(ClientDetails != 0){
            setFirstname(""); setClientDetails(""); setTitle(""); setPlaceOfBirth("");  setSurname("");
            setIDNumber(""); setHomeNumber(""); setMobileNumber(""); setWorkNumber("");
            setFixNumber(""); setEmail(""); setPhysicalAddress1("");  setPhysicalAddress2("");
            setPostalAddress1(""); setPostalAddress2(""); setPhysicalAddressCity(""); setPostalAddressCity("");
            setResidentialStatus(""); setResidencyStatus(""); setGender(""); setMaritalStatus("");
            setDateOfBirth(""); setOccupancyCurrentDate(""); setOccupancyPreviousDate(""); setMarriedDate("");
            setHighestEducation(""); setPhysicalAddressPostCode(""); setPostalAddressCode(""); setDependant("");
            setHomeVillage(""); setNearestLandmark(""); setContactIndividual(""); setStaffIndicator("");
            setWard(""); setIssuingOffice("");
            setOwnerPropertyType(""); setClientRelationshipManager("");
            }else{
                setLoader(true);
                const payload = {
                    transaction_id: transaction_id,
                    instance_id: instance_id,
                    company_id: FiClientId,
                };
           // console.log(payload);
              axios
                .post("finance_application_absa/featch_absa_finance_client_details", payload)
                .then((response) => {
                    if(response.status === 200){
                        const obj = response.data.data.client_details_array;
                        setFirstname(obj.firstname);
                        setClientDetails(obj.client_details);
                        setTitle(obj.title);
                        setSurname(obj.surname);
                        setIDNumber(obj.id_number);
                        setHomeNumber(obj.home_number);
                        setMobileNumber(obj.mobile_number);
                        setWorkNumber(obj.work_number);
                        setFixNumber(obj.fax_number);
                        setEmail(obj.email);
                        setPhysicalAddress1(obj.physical_address_lane1);
                        setPhysicalAddress2(obj.physical_address_lane2);
                        setPostalAddress1(obj.postal_address_lane1);
                        setPostalAddress2(obj.postal_address_lane2);
                        setPhysicalAddressCity(obj.physical_address_city);
                        setPostalAddressCity(obj.postal_address_city);
                        setResidentialStatus(obj.residential_status);
                        setResidencyStatus(obj.residancy_status);
                        setGender(obj.gender);
                        setMaritalStatus(obj.marial_status);
                        setDependant(obj.number_of_dependent);
                        setDateOfBirth(obj.date_of_birth);
                        setOccupancyCurrentDate(obj.occupancy_date);
                        setOccupancyPreviousDate(obj.occupancy_prev_date);
                        setMarriedDate(obj.date_married);
                        setHighestEducation(obj.highest_education);
                        setPhysicalAddressPostCode(obj.physical_address_code);
                        setPostalAddressCode(obj.postal_address_code);
                        setHomeVillage(obj.home_village);
                        setNearestLandmark(obj.neraest_landmark);
                        setContactIndividual(obj.individual);
                        setStaffIndicator(obj.staff_indicator);
                        setWard(obj.ward);
                        setIssuingOffice(obj.issuing_office);
                        setOwnerPropertyType(obj.owner_property_type);
                        setClientRelationshipManager(obj.client_relationship_manager);
                        setPlaceOfBirth(obj.place_of_birth);
                        setLoader(false);
                    } 
                })
                .catch(() => {
                   console.log('error');
                   setLoader(false);
                });
        }
    }

     //----------- Add/Edit client details data-----------------
     const handleClientDetailsValidation = () => {
        var flag = true;
        if (ClientDetailFlag) {
            if (Firstname === '') {
                alert('First Name field is mandatory!');
                setValidateColor("red");
                setValidateColor1("");
                setValidateColor2("");
                flag = false;
            }else if (Surname === '') {
                alert('Surname field is mandatory!');
                setValidateColor1("red");
                setValidateColor("");
                setValidateColor2("");
                flag = false;
            }else if (Email === '') {
                alert('Email field is mandatory!');
                setValidateColor2("red");
                setValidateColor1("");
                setValidateColor("");
                flag = false;
            }
        }
        return flag;
    }

    const handleClientDetailsSubmit = (e) => {
        e.preventDefault();
        const flag = handleClientDetailsValidation();
        if (flag) {
            setLoader(true);  
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                user_id : user_id,
                form_id: 91,      // absa bank
                group_id: group_id,
                company_id: company_id, 
                application_id: application_id,
                command: ClientDetSave,
                sub_1_field_930: ClientDetails, 
                sub_1_field_934: IDNumber,
                sub_1_field_931: Title,
                sub_1_field_932: Firstname,
                sub_1_field_933: Surname,
                sub_1_field_935: FinalDateOfBirth,
                sub_1_field_1729: PlaceOfBirth,
                sub_1_field_946: MaritalStatus,
                sub_1_field_1038: ResidencyStatus,
                sub_1_field_936: HomeNumber,
                sub_1_field_938: WorkNumber,
                sub_1_field_939: MobileNumber,
                sub_1_field_1527: FixNumber,
                sub_1_field_940: Email,
                sub_1_field_1497: PhysicalAddress1,
                sub_1_field_1500: PostalAddress1,
                sub_1_field_1498: PhysicalAddress2,
                sub_1_field_1501: PostalAddress2,
                sub_1_field_1511: PhysicalAddressCity,
                sub_1_field_1513: PostalAddressCity,
                sub_1_field_1732: HomeVillage,
                sub_1_field_1731: NearestLandmark,
                sub_1_field_1466: FinalMarriedDate,
                sub_1_field_951: ResidentialStatus,
                sub_1_field_949: FinalOccupancyCurrentDate,
                sub_1_field_950: FinalOccupancyPreviousDate,
                sub_1_field_1733: Ward, 
                sub_1_field_1730: IssuingOffice,
                sub_1_field_1737: OwnerPropertyType,
                sub_1_field_1821: ClientRelationshipManager,
            };
            //console.log(payload);
              axios.post("finance_application_absa/add_edit_absa_finance_client_details", payload)
                .then((response) => {
                   // console.log(response);
                    if(response.status === 200){
                        if(response.data.data == "Save"){
                           //window.location.reload(false); 
                            toast.success(response.data.message, {
                              position: toast.POSITION.TOP_CENTER
                            }); 
                            setLoader(false); 
                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); 
                        }else if(response.data.data == "Save and Exit"){
                            navigate('/transaction/create_transaction/' +transaction_id);
                            setLoader(false); 
                        }else if(response.data.data == "Print" || response.data.data == "View Finance App"){
                            GeneratePrintPdf();
                        }
                    } 
                })
                .catch(() => {
                   console.log('error');
                   setLoader(false); 
                });
        }
    };
    
    //----------- featch employee details data-----------------
    useEffect(() => { 
        if(AbsaBankTabId === '92'){ 
            setLoader(true);  
            const payload = {
                instance_id: instance_id,
            };
            axios.post("finance_application_absa/featch_absa_employee_details",payload)
            .then((response) => {
            // console.log(response);
                if(response.status === 200){
                    if(response.data.data != ''){
                    //console.log(response.data);
                        const obj = response.data.data.select_array[0];
                        setEmpIncomeCategory(obj.income_category); setEmpEmployer(obj.employer);
                        setEmpOccupation(obj.occupation); setEmployeeFrom(obj.employee_from);
                        setEmployeeTo(obj.employee_to); setEmpEmpoyeeHash(obj.employee_hash); 
                        setDepartment(obj.department); setEmpContactNo(obj.contact_number); 
                        setEmpSalaryDate(obj.salary_date); setEmpSupervisor(obj.supervisor);
                        setEmpAddress(obj.employer_address); setTelephoneHome(obj.telephone_home);
                        setTelephoneWork(obj.telephone_work); setEmailAddress(obj.email_address);
                        setMobileNumber(obj.mobile_no); setBussinessActivity(obj.business_activity);
                        setEmpMobileNumber(obj.mobile_no);
                        setPeriodSselfEmployee(obj.period_self_employee); setFinancialsSubmitted(obj.financital_submitted);
                        setLast6MonthStmtSubmitted(obj.last_6month_bank);
                        
                        if(obj.income_category == 795){
                            setSelfEmpHideShow("")
                            setParmentHideShow("")
                        }else if(obj.income_category == 798){
                            setParmentHideShow("none")
                            setSelfEmpHideShow("none")
                        }else{
                            setSelfEmpHideShow("none")
                            setParmentHideShow("")
                        }
                    }
                    setLoader(false); 
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false); 
            });
        }
    }, [AbsaBankTabId]); 
   
    //----------- Add/Edit Employee details data-----------------
    const EmployeeDetValidation = () => {
        var flag = true;
            if (EmpIncomeCategory === "0") {
                alert("Income Category field is mandatory");
                setcolorFocus1('red');
                flag = false;
            }else if (EmpSalaryDate == "0") {
                alert("Salary Date field is mandatory");
                setcolorFocus('red');
                flag = false;
            }else if (EmpAddress === "") {
                alert("Employer Address field is mandatory");
                setcolorFocus2('red');
                flag = false;
            }else if (TelephoneHome === "") {
                alert("Telephone (home) field is mandatory");
                setcolorFocus3('red');
                flag = false;
            }else if (TelephoneWork === "") {
                alert("Telephone (work) field is mandatory");
                setcolorFocus4('red');
                flag = false;
            }else if (EmailAddress === "") {
                alert("Email Address field is mandatory");
                setcolorFocus5('red');
                flag = false;
            }else if (EmpMobileNumber === "") {
                alert("Mobile Number field is mandatory");
                setcolorFocus6('red');
                flag = false;
            }else if (EmpSupervisor === "") {
                alert("Supervisor field is mandatory");
                setcolorFocus7('red');
                flag = false;
            }
            
        return flag;
    }
    
    const handleAddEditEmployee = (e) => {
        e.preventDefault();
        const flag1 = EmployeeDetValidation();
        if (flag1) {
            setLoader(true); 
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                user_id : user_id,
                form_id: 92,      
                group_id: group_id,
                company_id: company_id, 
                application_id: application_id,
                command: EmployeeDetailsSave,
                sub_1_field_986: EmpIncomeCategory, 
                sub_1_field_977: EmpEmployer,
                sub_1_field_979: EmpOccupation, 
                sub_1_field_980: FinalEmployeeFrom, 
                sub_1_field_981: FinalEmployeeTo, 
                sub_1_field_982: EmpEmpoyeeHash, 
                sub_1_field_1758: Department,
                sub_1_field_985: EmpContactNo, 
                sub_1_field_1069: EmpSalaryDate,
                sub_1_field_1077: EmpSupervisor,
                sub_1_field_984: EmpAddress,
                sub_1_field_1864: TelephoneHome,
                sub_1_field_1865: TelephoneWork,
                sub_1_field_1866: EmailAddress,
                sub_1_field_1867: EmpMobileNumber,  
                sub_1_field_1756: BussinessActivity,  
                sub_1_field_1516: PeriodSselfEmployee,
                sub_1_field_1517: FinancialsSubmitted,
                sub_1_field_1518: Last6MonthStmtSubmitted,      
            };
            //console.log(payload);
            axios.post("finance_application_absa/add_edit_absa_employee_details", payload)
            .then((response) => {
               // console.log(response);
                if(response.status == 200){
                    if(response.data.data == "Save" || response.data.data == "Save New Entry"){
                       //window.location.reload(false);  
                        toast.success(response.data.message, {
                          position: toast.POSITION.TOP_CENTER
                        }); 
                        setLoader(false); 
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }else if(response.data.data == "Save and Exit"){
                        navigate("/transaction/create_transaction/" + transaction_id);
                        setLoader(false); 
                    }else if(response.data.data == "Print" || response.data.data == "View Finance App"){
                        GeneratePrintPdf();
                    }
                }
            })
            .catch(() => {
               console.log('error');
               setLoader(true); 
            });
        }
    }

    //----------- featch clients finance details -----------------
    useEffect(() => {   
        if(AbsaBankTabId === '93'){
            setLoader(true); 
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
            };
            axios.post("finance_application_absa/featch_absa_clients_finance_details",payload)
            .then((response) => {
            //console.log(response.data.data.clients_finance_details_array.tobe_settled_loan1);
                if(response.status === 200){
                    const obj = response.data.data.clients_finance_details_array;
                    setFinanceTransactionId(obj.transaction_number);
                    setGrossMonthlySalary(obj.monthly_basic_salary); setCommission6MonthAvg(obj.commission_six_month)
                    setOvertime6MonthAvg(obj.overtime); setPaySlipMAllowance(obj.net_monthly_income);
                    setAllOtherIncome(obj.all_other_income); setTotalIncome(obj.total_income);
                    
                    setCreditCardName(obj.credit_card_name); setCardNumber(obj.card_number); setCardExpiryDate(obj.card_expiry_date);

                    setExistingLoan(obj.other_exsting_loans);
                    setToBeSealed1(obj.tobe_settled_loan1); setFinantialProviderLoan1(obj.finance_provider_loan1);
                    setMonthlyInvestment1(obj.monthly_installment_loan1); setToBeSealed2(obj.tobe_settled_loan2);
                    setFinantialProviderLoan2(obj.finance_provider_loan2); setMonthlyInvestment2(obj.monthly_installment_loan2); 
                    setSettlementAmount1(obj.settlement_account1);
                    setSettlementAmount2(obj.settlement_account2);

                    if(obj.tobe_settled_loan1 != '' && obj.tobe_settled_loan1 == 786 ){
                        setMonthinstallReadonly("");
                        setLoan1HideShow("none");
                    }else if(obj.tobe_settled_loan1 != '' && obj.tobe_settled_loan1 == 785 ){
                        setMonthinstallReadonly("none");
                        setLoan1HideShow("");
                    }else{
                        setMonthinstallReadonly("");
                        setLoan1HideShow("none");
                    }

                    if(obj.tobe_settled_loan2 != '' && obj.tobe_settled_loan2 == 786 ){
                        setMonthinstallReadonly1("");
                        setLoan2HideShow("none");
                    }else if(obj.tobe_settled_loan2 != '' && obj.tobe_settled_loan2 == 785 ){
                        setMonthinstallReadonly1("none");
                        setLoan2HideShow("");
                    }else{
                        setMonthinstallReadonly1("");
                        setLoan2HideShow("none");
                    }

                    setFinantialCompany(obj.existing_previous_vehicle); setAccountNumber(obj.account_number);
                    setpreviousBranch(obj.finance_company_branch);setInstallment(obj.instalment); 
                    setToBeSealed3(obj.tobe_settled3);setSettlement(obj.settlement); 
                    setMonthLeft(obj.months_left);

                    if(obj.settlement != '' && obj.settlement != 0){
                        setSettlement(obj.settlement); 
                    }else{
                        setSettlement('0.00');
                    }
                    
                    setOwnHome(obj.own_your_home);
                    if(obj.own_your_home == 786){
                        setPropertyHideShow("");
                        setPropertyHideShow1("none");
                    }else if(obj.own_your_home == 785){
                        setPropertyHideShow1("");
                        setPropertyHideShow("none");
                    }else{
                        setPropertyHideShow("none"); 
                    } 
    
                    setMonthlyBond(obj.bond_monthly_installement); setBondHolder(obj.bond_holder_bank);
                    setPurchasePrice(obj.purchase_price); setPurchaseDate(obj.purchase_date);
                    setMarketValue(obj.present_market_value); setPovertyName(obj.in_whose_name);
                    setBondAmount(obj.outstanding_bond_amount); setStandNumber(obj.stand_number);
                
                    setRentalValue(obj.rental_lodging_value);
                    setLandlordName(obj.landlord_name); setLandlordAddress(obj.landlord_address);
                    setSuburb(obj.subrub); setHiddenTotalIncome(obj.hidden_total_income);
                    
                    setExistingAccountHolder(obj.exsting_account_holder); setAccountWithBarclaysDate(obj.account_with_barclays); setSalaryPaid(obj.salary_paid); setTransferAccount(obj.transfer_account);
                // setSurplus(obj.surplus);
                    
                    if(obj.other_exsting_loans == 785){
                        setOtherExistingHideShow("");
                    }else{
                        setOtherExistingHideShow("none");
                    }

                    if(obj.exsting_account_holder == 786){
                        setAccountHolderHideShow("none");
                    }else if(obj.exsting_account_holder == 785){
                        setAccountHolderHideShow("");
                    }  

                    //------------ onload calculation
                    var total_income_new = obj.total_income.replace(/,(?=.*\.\d+)/g, ''); 

                    var monthly_installment_loan1 = obj.monthly_installment_loan1.replace(/,(?=.*\.\d+)/g, ''); 
                    var monthly_installment_loan2 = obj.monthly_installment_loan2.replace(/,(?=.*\.\d+)/g, ''); 
                    
                    if(obj.instalment != '' && obj.instalment != 0){
                        var instalment1 = obj.instalment.replace(/,(?=.*\.\d+)/g, ''); 
                    }else{
                        var instalment1 = '0.00';
                    }

                    if(obj.tobe_settled3 == 786){
                        var instalment1 = obj.instalment.replace(/,(?=.*\.\d+)/g, ''); 
                    }else if(obj.tobe_settled3 == 785 || obj.tobe_settled3 == '0.00'){
                        var instalment1 = '0.00';
                    }
                    
                    var lodging_value1 = obj.rental_lodging_value.replace(/,(?=.*\.\d+)/g, ''); 
                    var bond_monthly_installement1 = obj.bond_monthly_installement.replace(/,(?=.*\.\d+)/g, ''); 
                
                    // console.log(obj.pre_tobe_settled);
                    var new_surplus = Number(total_income_new)-Number(monthly_installment_loan1)-Number(monthly_installment_loan2)-Number(instalment1)-Number(lodging_value1)-Number(bond_monthly_installement1);
                    // console.log(new_surplus);
                    let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    setSurplus(final_surplus);
                    setHiddenSurplus(new_surplus);
                    setLoader(false); 
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                } 
                
            })
            .catch(() => {
                console.log('error');
                setLoader(false); 
            });
        }
    }, [AbsaBankTabId]); 

    //----------- Add/Edit clients finance details data-----------------
    const handleClientFinanceDetailsValidation = () => {
        var flag = true;
        if (FinancialDetailFlag) {
            
            if (MonthLeft === '') {
                alert('Months Left field is mandatory!');
                setMonthLeftColor('red');
                flag = false;
            }else if (OwnHome === '') {
                alert('Please select Do you own your home?');
                setOwnHomeColor('1px solid red');
                flag = false;
            }else if (Surplus === "0.00") {
                alert('Surplus field is mandatory!');
                flag = false;
            }else if(OwnHome == 786){
               if(RentalValue == "0.00"){
                alert('Please enter RENTAL/LODGING VALUE amount.');
                setRentalValueBorder('1px solid red');
                flag = false;
               }
            }else if(OwnHome == 785){
                if(MonthlyBond == "0.00"){
                 alert('Please enter BOND MONTHLY INSTALMENT amount.');
                 setBondMonthlyBorder('1px solid red');
                 flag = false;
                }
            }else if (Surplus === "") {
                alert('Surplus field is mandatory!');
                flag = false;
            }
        }
        return flag;
    }

    const handleClientFinanceDetailsSubmit = (e) => {
        e.preventDefault();
        const flag = handleClientFinanceDetailsValidation();
        if (flag) {
            setLoader(true); 
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                user_id : user_id,
                form_id: 93,      
                group_id: group_id,
                company_id: company_id, 
                application_id: application_id,
                command: ClientsFinanceDetSave,
                sub_1_field_988: GrossMonthlySalary, 
                sub_1_field_989: Commission6MonthAvg,
                sub_1_field_990: Overtime6MonthAvg, 
                sub_1_field_993: PaySlipMAllowance, 
                sub_1_field_996: AllOtherIncome, 
                sub_1_field_997: TotalIncome, 
                sub_1_field_1746: CreditCardName,
                sub_1_field_1743: CardNumber,
                sub_1_field_1745: FinalCardExpiryDate,
                sub_1_field_1708: ExistingLoan,
                sub_1_field_1726: ToBeSealed1, 
                sub_1_field_1709: FinantialProviderLoan1,
                sub_1_field_1711: MonthlyInvestment1,
                sub_1_field_1727: ToBeSealed2,
                sub_1_field_1718: FinantialProviderLoan2,
                sub_1_field_1717: MonthlyInvestment2,
                sub_1_field_1475: FinantialCompany,
                sub_1_field_1477: AccountNumber,  
                sub_1_field_1476: previousBranch,
                sub_1_field_1478: Installment,        
                sub_1_field_1481: ToBeSealed3,       
                sub_1_field_1479: Settlement,       
                sub_1_field_1480: MonthLeft,
                sub_1_field_1483: OwnHome,
                sub_1_field_1493: LandlordName,
                sub_1_field_1712: RentalValue,
                sub_1_field_1494: LandlordAddress,
                sub_1_field_1713: MonthlyBond,
                sub_1_field_1488: BondHolder,
                sub_1_field_1491: PurchasePrice,
                sub_1_field_1489: PurchaseDate,
                sub_1_field_1492: MarketValue,
                sub_1_field_1485: PovertyName,
                sub_1_field_1490: BondAmount,
                sub_1_field_1486: StandNumber,
                sub_1_field_1487: Suburb,   
                sub_1_field_1739: ExistingAccountHolder,
                sub_1_field_1026: FinalAccountWithBarclaysDate,
                sub_1_field_1750: SalaryPaid,
                sub_1_field_1757: TransferAccount,
                sub_1_field_1760: SettlementAmount1,
                sub_1_field_1761: SettlementAmount2,
                sub_1_field_1019: Surplus,
            };
            
            //console.log(bodyFormData);
              axios.post("finance_application_absa/add_edit_absa_clients_finance", payload)
                .then((response) => {
                   // console.log(response);
                    if(response.status === 200){
                        if(response.data.data == "Save"){
                            //window.location.reload(false); 
                            toast.success(response.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            });  
                            setPrintButtonVisible(true);
                            setHeaderFlag(2);
                            setLoader(false); 
                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }else if(response.data.data == "Save and Exit"){
                            navigate('/transaction/create_transaction/' +transaction_id);
                            setLoader(false); 
                        }else if(response.data.data == "Print" || response.data.data == "View Finance App"){
                            GeneratePrintPdf();
                        }
                    }
                })
                .catch(() => {
                   console.log('error');
                   setLoader(false); 
                });
        }
    };
    
    //----------- featch loan specifics details -----------------
    useEffect(() => {  
        if(AbsaBankTabId === '94'){ 
            setLoader(true); 
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                system_type: system_type,
            };
            axios.post("finance_application_absa/featch_absa_loan_specifics_details",payload)
            .then((response) => {
            //console.log(response);
                if(response.status === 200){
                
                const obj1 = response.data.data.loan_specifics_details_array;
                
                setCommencementDate(obj1.commencement_date); setInterestType(obj1.interest_type); 
                setLoanExpDate(obj1.loan_exp_date); setSaleType(obj1.sale_type);  
                setFirstDebitOrder(obj1.installment_date); setFinanceType(obj1.finance_type);
                setTermMonth(obj1.term_months);  setInterestRate(obj1.prefferd_interest_rate);
                setPreArrangeSceme(obj1.scheme_pdp);  setLoanEquity(obj1.loan_equility);
                setLoanTransactionDisplay(obj1.transaction);
                setLoanAffordability(obj1.affordability);  setLoanDepositPercentage(obj1.deposit_percentage);
                setLoanAfforabilityInsurance(obj1.affordability_insurance); setBusinessSegment(obj1.bussiness_segment);
                // setConsumerSegment(obj1.consumers_segment); 
                setPreffredPaymentDate(obj1.prefferd_payment_date);
                setLoanAssetAmount(obj1.asset_amount);  setLoanInsuranceProduct(obj1.insurance_product);
                setLoanNonInsuranceProduct(obj1.non_insurance_product);  setLoanCompersiveInsurance(obj1.comprehensive_insurance);
                setLoanDiscount(obj1.discount);  setLoanCashDepositValue(obj1.cash_deposit_value);
                setLoanNettTradeDeposit(obj1.nett_trade_in_deposit_value);  setBankFee(obj1.bank_documentation_fee);
                setLoanTransactionFee(obj1.transaction_fee);  setLoanFinanceAmount(obj1.financed_amount);
                setCreditLifeInsurance(obj1.credit_life_insurance)
                } 
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch(() => {
                console.log('error');
                setLoader(false); 
            });
        }
    }, [AbsaBankTabId]); 
    
    
    useEffect(() => { 
        if(AbsaBankTabId === '94' && (FinalCommencementDate !== '1970-01-01' || FinalFirstDebitOrder !== '1970-01-01')){  
            const payload = {
                instance_id: instance_id,
                sub_1_field_1533: FinalCommencementDate,
                sub_1_field_1510: FinalFirstDebitOrder,
            };
            axios.post("finance_application/featch_commencement_date_validate",payload)
            .then((response) => {
            //console.log(response);
                setLoanCommencementFlag(response.data.data);
                setLoader(false);  
            })
            .catch(() => {
                console.log('error');
            });
        }
    }, [FinalCommencementDate,FinalFirstDebitOrder,AbsaBankTabId]);
    //----------- Add/Edit Loan specifics details data-----------------
    const handleLoanDetailsValidation = () => {
        var flag = true;
        if ((InterestRate === "") || (parseInt(InterestRate) === 0)) {
            alert("Please ensure the amount is greater than 0.");
            setLoanValidateColor("red");
            flag = false;
        }else if ((LoanCommencementFlag === 1)) {
            alert("Commencement date cannot be less than the current date")
            flag = false;
        }
        return flag;
    }

    const handleLoanDetailsSubmit = (e) => {
        e.preventDefault();
        const flag = handleLoanDetailsValidation();
        if (flag) {
            setLoader(true); 
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                user_id : user_id,
                form_id: 94,      
                group_id: group_id,
                company_id: company_id, 
                application_id: application_id,
                command: LoanSpecificsDetSave,
                sub_1_field_1533: FinalCommencementDate,
                sub_1_field_1247: InterestType,
                sub_1_field_1751: FinalLoanExpDate,
                sub_1_field_1246: SaleType,
                sub_1_field_1510: FinalFirstDebitOrder,  
                sub_1_field_1509: FinanceType,     
                sub_1_field_1031: TermMonth,
                sub_1_field_1030: InterestRate,     
                sub_1_field_1456: LoanEquity,     
                sub_1_field_1459: LoanDepositPercentage,
                sub_1_field_1819: BusinessSegment,
                sub_1_field_1039: LoanAssetAmount,
                sub_1_field_1450: LoanInsuranceProduct,
                sub_1_field_1451: LoanNonInsuranceProduct,
                sub_1_field_1452: LoanCompersiveInsurance,
                sub_1_field_1042: LoanDiscount,
                sub_1_field_1043: LoanCashDepositValue,
                sub_1_field_1449: LoanNettTradeDeposit,
                sub_1_field_1448: BankFee,
                sub_1_field_1453: LoanTransactionFee,
                sub_1_field_1029: LoanFinanceAmount,
            };
            //console.log(payload);
              axios.post("finance_application_absa/add_edit_absa_loan_specifics_details", payload)
                .then((response) => {
                   // console.log(response);
                    if(response.status === 200){
                        if(response.data.data == "Save"){
                           // window.location.reload(false); 
                            toast.success(response.data.message, {
                               position: toast.POSITION.TOP_CENTER
                            }); 
                            setPrintButtonVisible(true);
                            setHeaderFlag(2);
                            setLoader(false); 
                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }else if(response.data.data == "Save and Exit"){
                            navigate('/transaction/create_transaction/' +transaction_id);
                            setLoader(false); 
                        }else if(response.data.data == "Print" || response.data.data == "View Finance App"){
                            GeneratePrintPdf();
                        }
                    }
                })
                .catch(() => {
                   console.log('error');
                   setLoader(false); 
                });
        }
    };

    //----------- featch contact details -----------------
    useEffect(() => {   
        if(AbsaBankTabId === '96'){
            setLoader(true); 
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                company_id: company_id,
            };
            axios.post("finance_application_absa/featch_absa_contact_details",payload)
            .then((response) => {
            //    console.log(response);
                if(response.status === 200 && response.data.data.select_array != ''){
                    
                    const obj1 = response.data.data.select_array[0];
                    setContactDetails(obj1.contact_details); setContactRelationship(obj1.relationship); 
                    setContactTitle(obj1.title); setContactFirstName(obj1.firstname);  
                    setContactSurnameName(obj1.surname); setContactMobileNo(obj1.mobile_number);
                    setContactHomePhone(obj1.home_phone);  setContactWorkPhone(obj1.work_phone);
                    setContactPhysicalAddress(obj1.physical_address); setContactEmployerName(obj1.employer_name);
                    setContactOccupation(obj1.occupation);
                    setLoader(false); 
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false); 
            });
        }
    }, [AbsaBankTabId]); 
    
    const handleContactDet = (evnt)=>{  
        let condetid = evnt.target.value;
        setContactDetId(condetid);
    }
   
    const OnContactWiseChange = (typeContactId) => {
        // console.log(type);
         if(typeContactId == 0){
             setContactDetails(""); setContactRelationship(""); setContactTitle(""); setContactFirstName(""); 
             setContactSurnameName(""); setContactMobileNo(""); setContactHomePhone("");
             setContactWorkPhone(""); setContactPhysicalAddress(""); setContactEmployerName(""); setContactOccupation("");
            
            }else{
                setLoader(true);
                const payload = {
                     transaction_id: transaction_id,
                     instance_id: instance_id,
                     company_id: company_id,
                     sel_contact_id: typeContactId,
                };
            
                axios.post("finance_application_absa/featch_absa_contact_details_onchange",payload)
                 .then((response) => {
                     if(response.status === 200 && response.data.data != ''){
                         const obj1 = response.data.data.selected_array[0];
                         setContactDetails(obj1.contact_details); setContactRelationship(obj1.relationship); 
                         setContactTitle(obj1.title); setContactFirstName(obj1.firstname);  
                         setContactSurnameName(obj1.surname); setContactMobileNo(obj1.mobile_number);
                         setContactHomePhone(obj1.home_phone);  setContactWorkPhone(obj1.work_phone);
                         setContactPhysicalAddress(obj1.physical_address); setContactEmployerName(obj1.employer_name);
                         setContactOccupation(obj1.occupation);
                         setLoader(false);
                     } 
                })
                .catch(() => {
                    console.log('error');
                    setLoader(false);
                });
        }
    }
    
    
    //----------- Add/Edit contact details-----------------
    const ContactDetailsValidation = () => {
        var flag = true;
        
        if(ContactRelationship == 0){
            alert("Please select contact relationship");
            setcolorFocus11('red');
            flag = false;
        }else if(ContactFirstName == ''){
            alert("Please enter first name");
            setcolorFocus12('red');
            flag = false;
        }else if(ContactSurnameName == ''){
            alert("Please enter surname");
            setcolorFocus13('red');
            flag = false;
        }
        
        return flag;
    }

    const handleAddContact = (e) => {
        e.preventDefault();
        const flag1 = ContactDetailsValidation();
        if (flag1) {
            setLoader(true); 
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                user_id : user_id,
                form_id: 96,      
                group_id: group_id,
                company_id: company_id, 
                application_id: application_id,
                command: ContactDetailsSave,
                sub_1_field_967: ContactDetails,
                sub_1_field_968: ContactRelationship,
                sub_1_field_969: ContactTitle,
                sub_1_field_970: ContactFirstName,
                sub_1_field_971: ContactSurnameName,
                sub_1_field_1748: ContactMobileNo,
                sub_1_field_972: ContactHomePhone,
                sub_1_field_973: ContactWorkPhone,
                sub_1_field_976: ContactPhysicalAddress,
                sub_1_field_974: ContactEmployerName,
                sub_1_field_975: ContactOccupation,
                
            };
           // console.log(payload);
            axios.post("finance_application_absa/add_edit_absa_contact_details", payload)
            .then((response) => {
               //console.log(response);
                if(response.status == 200){
                    if(response.data.data == "Save" || response.data.data == "Save New Entry"){
                        toast.success(response.data.message, {
                          position: toast.POSITION.TOP_CENTER
                        }); 
                      // window.location.reload(false);  
                        setLoader(false); 
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }else if(response.data.data == "Save and Exit"){
                        navigate("/transaction/create_transaction/" + transaction_id);
                        setLoader(false); 
                    }else if(response.data.data == "Print" || response.data.data == "View Finance App"){
                        GeneratePrintPdf();
                    }
                }
                
            })
            .catch(() => {
               console.log('error');
               setLoader(false); 
            });
        }
    }

    //----------- featch spouse details --------------
    useEffect(() => {   
        if(AbsaBankTabId === '95'){
            setLoader(true); 
            const payload = {
                instance_id: instance_id,
            };
            axios.post("finance_application_standbic/featch_standbic_spouse_details_id",payload)
            .then((response) => {
            //console.log(response);
                if(response.status === 200){
                    setSpouseId(response.data.data);
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false); 
            });
        }
    }, [AbsaBankTabId]);

    useEffect(() => {   
        if(SpouseId){
            setLoader(true); 
            const payload = {
                instance_id: instance_id,
                company_id: company_id,
                spouse_id: SpouseId,
            };
            axios.post("finance_application_absa/featch_absa_spouse_details",payload)
            .then((response) => {
            //console.log(response);
                if(response.status === 200){
                    const obj = response.data.data.spouse_details_array;
                    setSpouseDetails(obj.spouse_details); setSpouseTitle(obj.title)
                    setSpouseFirstName(obj.firstname); setSpouseInitial(obj.initials);
                    setSpouseSurname(obj.surname); setSpouseIdNumber(obj.id_number);
                    setSpouseDOB(obj.date_of_birth); setSpouseGender(obj.gender);
                    setSpouseHomePhone(obj.home_phone); setSpouseWorkPhone(obj.work_phone);
                    setSpouseMobilePhone(obj.mobile_phone); setSpouseEmail(obj.email);
                    setSpousePhysicalAdd1(obj.physical_address_lane1); setSpousePostalAdd1(obj.postal_address_lane1);
                    setSpousePhysicalAdd2(obj.physical_address_lane2); setSpousePostalAdd2(obj.postal_address_lane2);
                    setSpousePhysicalAddCity(obj.physical_address_city); setSpousePostalAddCity(obj.postal_address_city);
                    setSpouseResidencyStatus(obj.residancy_status); setSpouseOccupation(obj.occupation);
                    setSpouseEmployerName(obj.employer_name); setSpouseEmployerAdd(obj.employer_address);
                    setSpouseDate(obj.date_employed); setSpouseIncome(obj.spouse_nett_income);
                    setContactNationality(obj.contact_nationality);
                    setSpouseName(obj.spouse_name);
                    setSpouseChidren(obj.children);
                    setSpouseOther(obj.other);
                    setGarentorSpouseTitle(obj.spouse_title);
                    setGarentorFirstName(obj.spouse_first_name);
                    setGarentorSurname(obj.spouse_surname);
                    setGarentorMobileNo(obj.mobile);
                    setLoader(false); 
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                } 
            })
            .catch(() => {
                console.log('error');
            });
        }
    }, [SpouseId,AbsaBankTabId]);

    useEffect(() => {   
        if(SpouseDetails){
            setLoader(true); 
            const payload = {
                instance_id: instance_id,
                company_id: company_id,
                spouse_id: SpouseDetails,
            };
            axios.post("finance_application_absa/featch_absa_spouse_details",payload)
            .then((response) => {
            //console.log(response);
                if(response.status === 200){
                    const obj = response.data.data.spouse_details_array;
                    setSpouseDetails(obj.spouse_details); setSpouseTitle(obj.title)
                    setSpouseFirstName(obj.firstname); setSpouseInitial(obj.initials);
                    setSpouseSurname(obj.surname); setSpouseIdNumber(obj.id_number);
                    setSpouseDOB(obj.date_of_birth); setSpouseGender(obj.gender);
                    setSpouseHomePhone(obj.home_phone); setSpouseWorkPhone(obj.work_phone);
                    setSpouseMobilePhone(obj.mobile_phone); setSpouseEmail(obj.email);
                    setSpousePhysicalAdd1(obj.physical_address_lane1); setSpousePostalAdd1(obj.postal_address_lane1);
                    setSpousePhysicalAdd2(obj.physical_address_lane2); setSpousePostalAdd2(obj.postal_address_lane2);
                    setSpousePhysicalAddCity(obj.physical_address_city); setSpousePostalAddCity(obj.postal_address_city);
                    setSpouseResidencyStatus(obj.residancy_status); setSpouseOccupation(obj.occupation);
                    setSpouseEmployerName(obj.employer_name); setSpouseEmployerAdd(obj.employer_address);
                    setSpouseDate(obj.date_employed); setSpouseIncome(obj.spouse_nett_income);
                    setContactNationality(obj.contact_nationality);
                    setSpouseName(obj.spouse_name);
                    setSpouseChidren(obj.children);
                    setSpouseOther(obj.other);
                    setGarentorSpouseTitle(obj.spouse_title);
                    setGarentorFirstName(obj.spouse_first_name);
                    setGarentorSurname(obj.spouse_surname);
                    setGarentorMobileNo(obj.mobile);
                    setLoader(false); 
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                } 
            })
            .catch(() => {
                console.log('error');
            });
        }
    }, [SpouseDetails,AbsaBankTabId]);

   
    //----------- Add/Edit spouse details data-----------------
    const handleSpouseDetailsValidation = () => {
        var flag = true;
        if (SpouseDetails == 0) {
            alert('Please select spouse details!');
            flag = false;
        }
        return flag;
    }

    const handleSpouseDetailsSubmit = (e) => {
        e.preventDefault();
        const flag = handleSpouseDetailsValidation();
        if (flag) {
            setLoader(true); 
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                user_id : user_id,
                form_id: 95,      
                group_id: group_id,
                company_id: company_id, 
                application_id: application_id,
                command: SpouseDetSave,
                sub_1_field_952: SpouseDetails,
                sub_1_field_954: SpouseTitle, 
                sub_1_field_955: SpouseFirstName,
                sub_1_field_1075: SpouseInitial,
                sub_1_field_956: SpouseSurname,
                sub_1_field_957: SpouseIdNumber,
                sub_1_field_958: FinalSpouseDOB,
                sub_1_field_965: SpouseGender,
                sub_1_field_959: SpouseHomePhone,
                sub_1_field_960: SpouseWorkPhone,
                sub_1_field_961: SpouseMobilePhone,
                sub_1_field_962: SpouseEmail,
                sub_1_field_1503: SpousePhysicalAdd1,
                sub_1_field_1506: SpousePostalAdd1,
                sub_1_field_1504: SpousePhysicalAdd2,
                sub_1_field_1507: SpousePostalAdd2,
                sub_1_field_1514: SpousePhysicalAddCity,
                sub_1_field_1515: SpousePostalAddCity,
                sub_1_field_947 : SpouseResidencyStatus,
                sub_1_field_1468: SpouseOccupation,
                sub_1_field_1469: SpouseEmployerName,
                sub_1_field_1470: SpouseEmployerAdd,
                sub_1_field_1471: FinalSpouseDate,
                sub_1_field_994 : SpouseIncome,
                sub_1_field_1738: ContactNationality,
            };
            //console.log(payload);
              axios
                .post("finance_application_absa/add_edit_absa_spouse_details", payload)
                .then((response) => {
                    if(response.status === 200){
                        if(response.data.data == "Save"){
                           // window.location.reload(false);
                            toast.success(response.data.message, {
                              position: toast.POSITION.TOP_CENTER
                            });  
                            setLoader(false); 
                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }else if(response.data.data == "Save and Exit"){
                            navigate('/transaction/create_transaction/' +transaction_id);
                            setLoader(false); 
                        }else if(response.data.data == "Print" || response.data.data == "View Finance App"){
                            GeneratePrintPdf();
                        }
                    }
                })
                .catch(() => {
                   console.log('error');
                   setLoader(false); 
                });
        }
    };

    //--------------------------------------------------------------------------------

    //----------- get client details -----------------
    useEffect(() => { 
        if(AbsaBankTabId === '91'){
            setLoader(true); 
            const payload = {
                client_id: client_id,
            };    
            axios.post("finance_application/fetach_client_details_dropdown",payload)
            .then((response) => {
                if(response.status === 200){
                   setClientDetailsAll(response.data.data);
                }
            })
            .catch(() => {
                console.log('error');
                setLoader(false); 
            });
        }
    }, [AbsaBankTabId]); 
    
 
    
    //----------------------- get logo ---------------
    useEffect(() => { 
        const payload = {
            application_id: application_id,
        };    
        axios.post("finance_application/featch_bank_logo",payload)
        .then((response) => {
          //  console.log(response);
            if(response.status === 200){
               setLoanhouseLogo(response.data.data);
            }
        })
        .catch(() => {
            console.log('error');
        });
    }, []); 

    //----------- get income category / finantial provider / finantial company / term months / finance type / consumer segment / business segment -----------------
    useEffect(() => { 
        if(AbsaBankTabId === '93'){    
            setLoader(true); 
            axios.get("finance_application/featch_income_category_finantial_provider")
            .then((response) => {
                if(response.status === 200){
                    //console.log(response.data.data);
                    setIncomeCategoryAll(response.data.data.income_category);
                    setFinantialProviderAll(response.data.data.finantial_provider);
                    setFinantialCompanyAll(response.data.data.finance_company);
                    setTermMonthAll(response.data.data.term_month);
                    setFinanceTypeAll(response.data.data.finance_type);
                    setConsumerSegmentAll(response.data.data.consumer_segment);
                    //setBusinessSegmentAll(response.data.data.business_segment);
                }
            })
            .catch(() => {
                console.log('error');
                setLoader(false); 
            });
        }
    }, [AbsaBankTabId]);

    useEffect(() => {     
        if(AbsaBankTabId === '94'){    
            setLoader(true); 
            axios.get("finance_application_absa/fetach_absa_loan_details_dropdown")
            .then((response) => {
                if(response.status === 200){
                    setBusinessSegmentAll(response.data.data.business_segment_array);
                }
            })
            .catch(() => {
                console.log('error');
                setLoader(false); 
            });
        }
    }, [AbsaBankTabId]);

    //----------- get client details -----------------
    useEffect(() => { 
        if(AbsaBankTabId === '95' || AbsaBankTabId === '96'){ 
            setLoader(true);    
            const payload = {
                client_id: client_id,
            };    
            axios.post("finance_application/fetach_spouse_details_dropdown",payload)
            .then((response) => {
                if(response.status === 200){
                    //console.log(response);
                    setSpouseDetailsAll(response.data.data);
                    setContactDetailsAll(response.data.data)
                }
            }) 
            .catch(() => {
                console.log('error');
                setLoader(false); 
            });
        }
    }, [AbsaBankTabId]); 
    
    //----------- get salary date / relationship (employee details) -----------------
    useEffect(() => {  
        if(AbsaBankTabId === '95' || AbsaBankTabId === '96'){  
            setLoader(true);      
            axios.get("finance_application/featch_salary_date_relationship")
            .then((response) => {
                if(response.status === 200){
                    setSalaryDateAll(response.data.data.salary_date);
                    setRelationshipAll(response.data.data.relationship);
                    setLoader(false);    
                } 
            })
            .catch(() => {
                console.log('error');
                setLoader(false);    
            });
        }
    }, [AbsaBankTabId]);

    //------------------- table hide /show
    const ChangeNavigation = (tab) => {
        // const CheckValidation = handleClientDetailsValidation();
        
        // if (CheckValidation) {
          //  console.log(tab)
            if(tab === "Client Details"){  // 1 
                setHide1('table'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('none'); 
                setHide6('none'); settabWidth(800);
                localStorage.setItem("AbsaBankTabId", 91);
            }else if(tab === "Employer Details"){  // 2
                setHide1('none'); setHide2('table'); setHide3('none'); setHide4('none'); setHide5('none');
                setHide6('none'); settabWidth(800); //previous width - 1407
                localStorage.setItem("AbsaBankTabId", 92);
            }else if(tab === "Client's Financial Details"){   // 3
                setHide1('none'); setHide2('none'); setHide3('table'); setHide4('none'); setHide5('none');
                setHide6('none'); settabWidth(800); 
                localStorage.setItem("AbsaBankTabId", 93);
            }else if(tab === "Loan Specifics"){  // 4
                setHide1('none'); setHide2('none'); setHide3('none'); setHide4('table'); setHide5('none');
                setHide6('none'); settabWidth(800);
                localStorage.setItem("AbsaBankTabId", 94);
            }else if(tab === "Spouse Details"){  // 5
                setHide1('none'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('table');
                setHide6('none'); settabWidth(800);
                localStorage.setItem("AbsaBankTabId", 95);
            }else if(tab === "Contact Details"){  // 6
                setHide1('none'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('none');
                setHide6('table'); settabWidth(800);
                localStorage.setItem("AbsaBankTabId", 96);
            }
        // }
    }

    useEffect(() => {
        if (AbsaBankTabId == 91) {   //client details
            setHide1('table'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('none'); 
            setHide6('none'); settabWidth(800);
        } else if (AbsaBankTabId == 92) {  //employee details
            setHide1('none'); setHide2('table'); setHide3('none'); setHide4('none'); setHide5('none');
            setHide6('none'); settabWidth(800);
        }else if (AbsaBankTabId == 93) {  // client finance details
            setHide1('none'); setHide2('none'); setHide3('table'); setHide4('none'); setHide5('none');
            setHide6('none'); settabWidth(800); 
        } else if (AbsaBankTabId == 94) {  // loan specifics details
            setHide1('none'); setHide2('none'); setHide3('none'); setHide4('table'); setHide5('none');
            setHide6('none'); settabWidth(800);
        } else if (AbsaBankTabId == 95) {  // spouse details
            setHide1('none'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('table');
            setHide6('none'); settabWidth(800);
        } else if (AbsaBankTabId == 96) {  // contact details
            setHide1('none'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('none');
            setHide6('table'); settabWidth(800);
        } 

    }, [AbsaBankTabId]);

    const ChangeForsurpulse = () => {
        if(Math.sign(HiddenSurplus) === -1){ 
            setDefectSurpulse("DEFICIT");
        } else { 
            setDefectSurpulse("SURPLUS");
        }
    }

    const RemoveZeroVal = (e) => {
        // console.log(e);
        if(e === 1 && GrossMonthlySalary === '0.00'){ setGrossMonthlySalary("") }
        if(e === 2 && Commission6MonthAvg === '0.00'){ setCommission6MonthAvg("") }
        if(e === 3 && Overtime6MonthAvg === '0.00'){ setOvertime6MonthAvg("") }
        if(e === 4 && MonthlyAllowance === '0.00'){ setMonthlyAllowance("") }
        if(e === 5 && MonthlyCarAllowance === '0.00'){ setMonthlyCarAllowance("") }
        if(e === 6 && PaySlipMAllowance === '0.00'){ setPaySlipMAllowance("") }
        if(e === 7 && AllOtherIncome === '0.00'){ setAllOtherIncome("") }
        if(e === 8 && TotalIncome === '0.00'){ setTotalIncome("") }
        if(e === 9 && MonthlyInvestment1 === '0.00'){ setMonthlyInvestment1("") }
        if(e === 10 && MonthlyInvestment2 === '0.00'){ setMonthlyInvestment2("") }
        if(e === 11 && Installment === '0.00'){ setInstallment("") }
        if(e === 12 && Settlement === '0.00'){ setSettlement("") }
        if(e === 13 && RentalValue === '0.00'){ setRentalValue("") }
        if(e === 14 && Surplus === '0.00'){ setSurplus("") }
        if(e === 15 && GranttedBouns === '0.00'){ setGranttedBouns("") }
        if(e === 16 && SettlementAmount1 === '0.00'){ setSettlementAmount1("") }
        if(e === 17 && SettlementAmount2 === '0.00'){ setSettlementAmount2("") }

        
        
        if(e === 81 && BankFee === '0.00'){ setBankFee("") }
        if(e === 82 && SpouseIncome === '0.00'){ setSpouseIncome("") } 
        if(e === 83 && MonthlyBond === '0.00'){ setMonthlyBond("") } 
        if(e === 84 && PurchasePrice === '0.00'){ setPurchasePrice("") } 
        if(e === 85 && MarketValue === '0.00'){ setMarketValue("") } 
        if(e === 86 && BondAmount === '0.00'){ setBondAmount("") } 
    }

    const ShowZero = (e) => {
        // alert('dd');
        if(e === 1 && GrossMonthlySalary === ''){ setGrossMonthlySalary("0.00") } 
        if(e === 2 && Commission6MonthAvg === ''){ setCommission6MonthAvg("0.00") }
        if(e === 3 && Overtime6MonthAvg === ''){ setOvertime6MonthAvg("0.00") }
        if(e === 4 && MonthlyAllowance === ''){ setMonthlyAllowance("0.00") }
        if(e === 5 && MonthlyCarAllowance === ''){ setMonthlyCarAllowance("0.00") }
        if(e === 6 && PaySlipMAllowance === ''){ setPaySlipMAllowance("0.00") }
        if(e === 7 && AllOtherIncome === ''){ setAllOtherIncome("0.00") }
        if(e === 8 && TotalIncome === ''){ setTotalIncome("0.00") }
        if(e === 9 && MonthlyInvestment1 === ''){ setMonthlyInvestment1("0.00") }
        if(e === 10 && MonthlyInvestment2 === ''){ setMonthlyInvestment2("0.00") }
        if(e === 11 && Installment === ''){ setInstallment("0.00") }
        if(e === 12 && Settlement === ''){ setSettlement("0.00") }
        if(e === 13 && RentalValue === ''){ setRentalValue("0.00") }
        if(e === 14 && Surplus === ''){ setSurplus("0.00") }
        if(e === 15 && GranttedBouns === ''){ setGranttedBouns("0.00") }
        if(e === 16 && SettlementAmount1 === ''){ setSettlementAmount1("0.00") }
        if(e === 17 && SettlementAmount2 === ''){ setSettlementAmount2("0.00") }

        if(e === 81 && BankFee === ''){ setBankFee("0.00") } 
        if(e === 82 && SpouseIncome === ''){ setSpouseIncome("0.00") } 
        if(e === 83 && MonthlyBond === ''){ setMonthlyBond("0.00") } 
        if(e === 84 && PurchasePrice === ''){ setPurchasePrice("0.00") } 
        if(e === 85 && MarketValue === ''){ setMarketValue("0.00") } 
        if(e === 86 && BondAmount === ''){ setBondAmount("0.00") } 

    }

    //----------- get print button details (hide/show) -----------------
    useEffect(() => { 
        const payload = {
            transaction_id: transaction_id,
            application_id: application_id,
            instance_id: instance_id,
            group_id: group_id,
        };    
        axios.post("finance_application/print_label_details",payload)
        .then((response) => {
            if(response.status === 200){
               // console.log(response);
                setPrintButtonData(response.data.data);
            
                if(response.data.data == 'Print'){
                   setPrintButton("Print");
                }else if(response.data.data == 'View Finance App'){
                    setPrintButton("View Finance App")
                }else{
                    setPrintButton("Print"); 
                }
            }
        })
        .catch(() => {
            console.log('error');
        });
    }, [PrintButtonVisible]); 

    //---------------- generate pdf and redirect url -------------
    const GeneratePrintPdf = ()=>{
        //alert('as');
        setLoader(true);
        const payload = {
            transaction_id: transaction_id,
            application_id: application_id,
            instance_id: instance_id,
            group_id: group_id,
            loanhouse_id: loanhouse_id,
            system_type: system_type,
            user_id: user_id,
            division_id: division_id,
            department: department_id,
            command: PrintButton,
        };
        //console.log(payload);setExpenditureHideShow
        axios.post("finance_application/finance_pdf_calling", payload)
        .then((response) => {
            if(response.status == 200){
               //window.location.reload(false);
               navigate('/transaction/prepare/application_id/'+response.data.data+'/regenerated=true');
               setLoader(false);
            } 
        })
        .catch(() => {
           console.log('error');
        }); 
    }
    
    const OtherFieldHideShow = (type)=>{
        //console.log(type);
        if(type == 786){
            setPropertyHideShow("");
            setPropertyHideShow1("none");
          
            if(isNaN(HiddenSurplus)){
                var txt_surplus = HiddenSurplus.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(HiddenSurplus == ""){
                var txt_surplus = "0.00";
            }else{
                var txt_surplus = HiddenSurplus;
            }
           //console.log(txt_surplus);
           
            if(isNaN(MonthlyBond)){
                var txt_MonthlyBond = MonthlyBond.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(MonthlyBond == ""){
                var txt_MonthlyBond = "0.00";
            }else{
                var txt_MonthlyBond = MonthlyBond;
            }
            //console.log(txt_RentalValue);
           
            var new_surplus = parseFloat(txt_surplus)+parseFloat(txt_MonthlyBond);
            let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setSurplus(final_surplus);
            setHiddenSurplus(new_surplus);
            setMonthlyBond("0.00")

        }else if(type == 785){
            setPropertyHideShow1("");
            setPropertyHideShow("none");
            
            if(isNaN(HiddenSurplus)){
                var txt_surplus = HiddenSurplus.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(HiddenSurplus == ""){
                var txt_surplus = "0.00";
            }else{
                var txt_surplus = HiddenSurplus;
            }
           //console.log(txt_surplus);
            if(isNaN(RentalValue)){
                var txt_RentalValue = RentalValue.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(RentalValue == ""){
                var txt_RentalValue = "0.00";
            }else{
                var txt_RentalValue = RentalValue;
            }
           
            var new_surplus = parseFloat(txt_surplus)+parseFloat(txt_RentalValue);
            let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setSurplus(final_surplus);
            setHiddenSurplus(new_surplus);
            setRentalValue("0.00")
        }  
    }

    const Loan1HideShowTab = (type1)=>{
        //console.log(type1);
        if(type1 == 'no'){
            setLoan1HideShow("none");
            setMonthinstallReadonly("");
        }else if(type1 == 'yes'){
            setLoan1HideShow("");
            setMonthinstallReadonly("none");
        }  
    }

    const Loan2HideShowTab = (type2)=>{
        //console.log(type1);
        if(type2 == 'no'){
            setLoan2HideShow("none");
            setMonthinstallReadonly1("");
        }else if(type2 == 'yes'){
            setLoan2HideShow("");
            setMonthinstallReadonly1("none");
        }  
    }

    const OtherExistingHideShowTab = (type3)=>{
        if(type3 == 'no'){
            setOtherExistingHideShow("none");
        }else if(type3 == 'yes'){
            setOtherExistingHideShow("");
        }  
    }
    
    const AccountHoldersHideShow = (type4)=>{
        if(type4 == 'no'){
            setAccountHolderHideShow("none");
        }else if(type4 == 'yes'){
            setAccountHolderHideShow("");
        }  
    }

    const ClearInstallemnt = (Install_val)=>{
        //setInstallment("0.00");
        if(isNaN(HiddenSurplus)){
            var txt_surplus = HiddenSurplus.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(HiddenSurplus == ""){
            var txt_surplus = "0.00";
        }else{
            var txt_surplus = HiddenSurplus;
        }
       //console.log(txt_surplus);
        if(isNaN(Installment)){
            var txt_Installment = Installment.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(Installment == ""){
            var txt_Installment = "0.00";
        }else{
            var txt_Installment = Installment;
        }

        if(Install_val == 785){
            var new_surplus = parseFloat(txt_surplus)+parseFloat(txt_Installment);
            let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setSurplus(final_surplus);
            setHiddenSurplus(new_surplus);
        }else if(Install_val == 786){
            var new_surplus = parseFloat(txt_surplus)-parseFloat(txt_Installment);
            let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setSurplus(final_surplus);
            setHiddenSurplus(new_surplus);
        }
    }

    const Calculate = ()=>{
       
        if(isNaN(PaySlipMAllowance)) {
            var nett_monthly_income = PaySlipMAllowance.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(PaySlipMAllowance == ""){
            var nett_monthly_income = "0.00";
        }else{
            var nett_monthly_income = PaySlipMAllowance;
        }

        if(isNaN(AllOtherIncome)) {
            var all_other_income = AllOtherIncome.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(AllOtherIncome == ""){
            var all_other_income = "0.00";
        }else{
            var all_other_income = AllOtherIncome;
        }
        
        let total_income = parseFloat(nett_monthly_income)+parseFloat(all_other_income);
        let final_total_income = total_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setTotalIncome(final_total_income);
        setHiddenTotalIncome(total_income);
        
        if(isNaN(MonthlyInvestment1)){
            var monthly_investment1 = MonthlyInvestment1.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(MonthlyInvestment1 == ""){
            var monthly_investment1 = "0.00";
        }else{
            var monthly_investment1 = MonthlyInvestment1;
        }

        if(isNaN(MonthlyInvestment2)) {
            var monthly_investment2 = MonthlyInvestment2.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(MonthlyInvestment2 == ""){
            var monthly_investment2 = "0.00";
        }else{
            var monthly_investment2 = MonthlyInvestment2;
        }
        
        if(ToBeSealed3 == '786'){   // condition no 
            if(isNaN(Installment)) {
                var total_installment = Installment.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(Installment == ""){
                var total_installment = "0.00";
            }else{
                var total_installment = Installment;
            }
        }else{
            var total_installment = "0.00";  // condition yes
        }
        
        if(OwnHome == '786'){
            if(isNaN(RentalValue)) {
                var rental_value = RentalValue.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(RentalValue == ""){
                var rental_value = "0.00";
            }else{
                var rental_value = RentalValue;
            }
        }else{
            var rental_value = "0.00";
        }

        if(OwnHome == '785'){
            if(isNaN(MonthlyBond)) {
                var monthly_bond = MonthlyBond.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(MonthlyBond == ""){
                var monthly_bond = "0.00";
            }else{
                var monthly_bond = MonthlyBond;
            }
        }else{
            var monthly_bond = "0.00";
        }

        var new_surplus = parseFloat(total_income)-parseFloat(monthly_investment1)-parseFloat(monthly_investment2)-parseFloat(rental_value)-parseFloat(monthly_bond)-parseFloat(total_installment);
        let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setSurplus(final_surplus);
        setHiddenSurplus(new_surplus);
    }

    const Calculate2 = ()=>{
      // console.log(RentalValue);
        if(isNaN(HiddenTotalIncome)) {
            var total_income1 = HiddenTotalIncome.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(HiddenTotalIncome == ""){
            var total_income1 = "0.00";
        }else{
            var total_income1 = HiddenTotalIncome;
        }

        if(isNaN(MonthlyInvestment1)){
            var monthly_investment1 = MonthlyInvestment1.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(MonthlyInvestment1 == ""){
            var monthly_investment1 = "0.00";
        }else{
            var monthly_investment1 = MonthlyInvestment1;
        }

        if(isNaN(MonthlyInvestment2)) {
            var monthly_investment2 = MonthlyInvestment2.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(MonthlyInvestment2 == ""){
            var monthly_investment2 = "0.00";
        }else{
            var monthly_investment2 = MonthlyInvestment2;
        }

        if(ToBeSealed3 == '786'){   // condition no 
            if(isNaN(Installment)) {
                var total_installment = Installment.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(Installment == ""){
                var total_installment = "0.00";
            }else{
                var total_installment = Installment;
            }
        }else{
            var total_installment = "0.00";  // condition yes
        }

        if(OwnHome == '786'){
            if(isNaN(RentalValue)) {
                var rental_value = RentalValue.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(RentalValue == ""){
                var rental_value = "0.00";
            }else{
                var rental_value = RentalValue;
            }
        }else{
            var rental_value = "0.00";
        }

        if(OwnHome == '785'){
            if(isNaN(MonthlyBond)) {
                var monthly_bond = MonthlyBond.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(MonthlyBond == ""){
                var monthly_bond = "0.00";
            }else{
                var monthly_bond = MonthlyBond;
            }
        }else{
            var monthly_bond = "0.00";
        }
       
        var new_surplus = parseFloat(total_income1)-parseFloat(monthly_investment1)-parseFloat(monthly_investment2)-parseFloat(rental_value)-parseFloat(monthly_bond)-parseFloat(total_installment);
        let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setSurplus(final_surplus);
        setHiddenSurplus(new_surplus); 
    }

    const ClearMonthlyInstallment = (type)=>{
        if(type === '786'){
            setMonthlyInvestment1("0.00");
            setMonthlyInvestment2("0.00");
            
            if(isNaN(HiddenSurplus)){
                var txt_surplus = HiddenSurplus.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(HiddenSurplus == ""){
                var txt_surplus = "0.00";
            }else{
                var txt_surplus = HiddenSurplus;
            }
           //console.log(txt_surplus);
            if(isNaN(MonthlyInvestment1)){
                var monthly_investment1 = MonthlyInvestment1.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(MonthlyInvestment1 == ""){
                var monthly_investment1 = "0.00";
            }else{
                var monthly_investment1 = MonthlyInvestment1;
            }
    
            if(isNaN(MonthlyInvestment2)) {
                var monthly_investment2 = MonthlyInvestment2.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(MonthlyInvestment2 == ""){
                var monthly_investment2 = "0.00";
            }else{
                var monthly_investment2 = MonthlyInvestment2;
            }
           
            var new_surplus = parseFloat(txt_surplus)-parseFloat(monthly_investment1)-parseFloat(monthly_investment2);
            let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setSurplus(final_surplus);
            setHiddenSurplus(new_surplus);
        }
    }
   
    const OnchangeEmpCateWiseHideSHow = (type) => {
        // console.log(type);
        if(type == 795){
            setSelfEmpHideShow("")
            setParmentHideShow("")
        }else if(type == 798){
            setParmentHideShow("none")
            setSelfEmpHideShow("none")
        }else{
            setSelfEmpHideShow("none")
            setParmentHideShow("")
        }
    }

    // let panFlag = 1;
    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px", marginLeft: "-200px" }} />
            <Transaction_menu panel_flag={HeaderFlag}/>
            {/* <form onSubmit={handleSubmit}> */}
            {loader ? <Loader /> : null}
                <table width="100%" height="800" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td width="100%">
                                <p align="center">
                                     <img height="90"  border="0" src={absa_img} alt=""/>
                                </p>
                                <table width="100%" border={0} cellSpacing={5} cellPadding={5} >
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center">
                                                <table className="defaultTbl" cellSpacing={1} align="center" width={tabWidth}>
                                                    <tbody>
                                                        <tr className="caption" id="group_list">
                                                            <th colSpan={(ClientDetailFlag && 4) || (EmployeeDetailFlag && 11) || (FinancialDetailFlag && 4) || (LoanDetailFlag && 4) || (SpouseDetailFlag && 4) || (ContactDetailFlag && 11)} style={{ textAlign: 'center' }}>

                                                                <input type="button" name="command" value="Client Details" onClick={() => { ChangeNavigation("Client Details") }} />
                                                           
                                                                <input type="button" name="command" value="Employer Details" onClick={() => { ChangeNavigation("Employer Details") }} />
                                                           
                                                                <input type="button" name="command" value="Client's Financial Details" onClick={() => { ChangeNavigation("Client's Financial Details") }} />
                                                           
                                                                <input type="button" name="command" value="Loan Specifics" onClick={() => { ChangeNavigation("Loan Specifics") }} />
                                                           
                                                                <input type="button" name="command" value="Spouse Details" onClick={() => { ChangeNavigation("Spouse Details") }} />
                                
                                                                <input type="button" name="command" value="Contact Details" onClick={() => { ChangeNavigation("Contact Details") }} /></th>
                                                        </tr>
                                                    </tbody>
                                                </table>

       {/*------------------ Client Details -----------------------------------------  */}
                                           <form name="clientDetails" method="post" onSubmit={handleClientDetailsSubmit} >            
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="800" style={{ display: div_id1 }}>
                                                    <tbody>
                                                        {ClientDetailFlag ?
                                                            <>
                                                                <tr className="caption">
                                                                    <th colSpan={4} style={{ textAlign: 'center' }}>CLIENT DETAILS</th>
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                        CLIENT DETAILS
                                                                    </td>

                                                                    <td style={{ textAlign: "left", width: "25%" }}>
                                                                        <select id="test" name="sub_1_field_930" className="text-uppercase" value={ClientDetails} onChange = {(e) => { setClientDetails(e.target.value); OnClientWiseChange(e.target.value); }} >
                                                                        <option value="0">-- Select An Option --</option>
                                                                          {ClientDetailsAll.length > 0 ?
                                                                            ClientDetailsAll.map((item) => {
                                                                                return (
                                                                                <option key={item.id} value={item.id}>
                                                                                {item.name}
                                                                                </option>
                                                                                );
                                                                            })
                                                                          : null }
                                                                        </select>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        ID NUMBER
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_934" type="text" value={IDNumber} onChange={(e) => { setIDNumber(e.target.value) }} className="text-uppercase"/>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                        TITLE
                                                                    </td>
                                                                    <td style={{ textAlign: "left", width: "25%" }}>
                                                                       
                                                                        <select id="test" name="sub_1_field_931" className="text-uppercase" value={Title} onChange = {(e) => { setTitle(e.target.value) }} >
                                                                            <option value="0">SELECT AN OPTION</option>
                                                                            {TitleAll.length > 0 ?
                                                                               TitleAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.title}
                                                                                    </option>
                                                                                    );
                                                                              })
                                                                         : null }
                                                                        </select>
                                                                    </td>

                                                                    <td className='caption'>
                                                                       <span style={{ color: ValidateColor }}> FIRST NAME</span> <span style={{ color: "red" }}>*</span>
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_932" type="text" value={Firstname} onChange={(e) => { setFirstname(e.target.value) }} className="text-uppercase"/>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                    <span style={{ color: ValidateColor1 }}> SURNAME</span> <span style={{ color: "red" }}>*</span>
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_933" type="text" value={Surname} onChange={(e) => { setSurname(e.target.value) }} className="text-uppercase"/>
                                                                    </td>
                                                                    
                                                                    <td className='caption'>
                                                                        DATE OF BIRTH
                                                                    </td>
                                                                    
                                                                    <td>
                                                                        {   BirthdateFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_935" selected={DateOfBirth} name="sub_1_field_935" onChange={(date) => { setDateOfBirth(date); setBirthdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_935" value={DateOfBirth} name="sub_1_field_935"  onChange={(date) => { setDateOfBirth(date); setBirthdateFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                        }
                                                                    </td>
   
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                       <span> PLACE OF BIRTH </span>
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_1729" type="text" value={PlaceOfBirth} onChange={(e) => { setPlaceOfBirth(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        MARITAL STATUS
                                                                    </td>

                                                                    <td>
                                                                        <select name="sub_1_field_946" className="text-uppercase" value={MaritalStatus} onChange = {(e) => { setMaritalStatus(e.target.value) }}> 
                                                                        <option value="0">-- Select An Option --</option>
                                                                           {MaritalStatusAll.length > 0 ?
                                                                             MaritalStatusAll.map((item) => {
                                                                                return (
                                                                                <option key={item.id} value={item.id}>
                                                                                {item.marital_status}
                                                                                </option>
                                                                                );
                                                                             })
                                                                            : null }
                                                                        </select>
                                                                    </td>
                                                                    
                                                                </tr>
                                                                <tr>
                                                                   
                                                                    <td className='caption'>
                                                                        RESIDENCY STATUS
                                                                    </td>
                                                                    <td>
                                                                        <select name="sub_1_field_1038" id="sub_1_field_1038" className="text-uppercase" value={ResidencyStatus} onChange = {(e) => { setResidencyStatus(e.target.value) }}>
                                                                            <option value="0">-- Select an option --</option>
                                                                            {ResidencyStatusAll.length > 0 ?
                                                                                ResidencyStatusAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.res_status}
                                                                                    </option>
                                                                                    );
                                                                                })
                                                                            : null }
                                                                        </select>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        HOME NUMBER
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_936" type="text" value={HomeNumber} onChange={(e) => { setHomeNumber(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    
                                                                    <td className='caption'>
                                                                        WORK NUMBER
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_938" type="text" value={WorkNumber} onChange={(e) => { setWorkNumber(e.target.value) }} className="text-uppercase" />
                                                                    </td>
                                                                    
                                                                    <td className='caption'>
                                                                        MOBILE NUMBER
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_939" type="text" value={MobileNumber} onChange={(e) => { setMobileNumber(e.target.value) }} className="text-uppercase" />
                                                                    </td>
                                                                
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        FAX NUMBER
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1527" type="text" value={FixNumber} onChange={(e) => { setFixNumber(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                    <span style={{ color: ValidateColor2 }}> EMAIL</span> 
                                                                         <span style={{ color: "red" }}>*</span>
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_940" type="text" value={Email} onChange={(e) => { setEmail(e.target.value) }} className="text-uppercase" />
                                                                    </td>
                                                                </tr>
                                                               
                                                                <tr>
                                                                    <td className='caption'>
                                                                        PHYSICAL ADDRESS LINE 1
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1497" type="text" value={PhysicalAddress1} onChange={(e) => { setPhysicalAddress1(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        POSTAL ADDRESS LINE 1
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_1500" type="text" value={PostalAddress1} onChange={(e) => { setPostalAddress1(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        PHYSICAL ADDRESS LINE 2
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1498" type="text" value={PhysicalAddress2} onChange={(e) => { setPhysicalAddress2(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        POSTAL ADDRESS LINE 2
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_1501" type="text" value={PostalAddress2} onChange={(e) => { setPostalAddress2(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        PHYSICAL ADDRESS CITY
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1511" type="text" value={PhysicalAddressCity} onChange={(e) => { setPhysicalAddressCity(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        POSTAL ADDRESS CITY
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_1513" type="text" value={PostalAddressCity} onChange={(e) => { setPostalAddressCity(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                </tr> 

                                                                <tr>
                                                                    <td className='caption'>
                                                                        HOME VILLAGE	
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_1732" type="text" value={HomeVillage} onChange={(e) => { setHomeVillage(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        WARD
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1733" type="text" value={Ward} onChange={(e) => { setWard(e.target.value) }} className="text-uppercase" />
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                        NEAREST LANDMARK
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1731" type="text" value={NearestLandmark} onChange={(e) => { setNearestLandmark(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        DATE MARRIED
                                                                    </td>
                                                                    <td>
                                                                        {   MarrieddateFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_1466" selected={MarriedDate} name="sub_1_field_1466" onChange={(date) => { setMarriedDate(date); setMarrieddateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_1466" value={MarriedDate} name="sub_1_field_1466"  onChange={(date) => { setMarriedDate(date); setMarrieddateFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                        }
                                                                    </td>

                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                        OCCUPANCY DATE AT CURRENT ADDRESS
                                                                    </td>

                                                                    <td>
                                                                        {   CurrentdateFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_949" selected={OccupancyCurrentDate} name="sub_1_field_949" onChange={(date) => { setOccupancyCurrentDate(date); setCurrentdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_949" value={OccupancyCurrentDate} name="sub_1_field_949"  onChange={(date) => { setOccupancyCurrentDate(date); setCurrentdateFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                        }  
                                                                    </td>
                                                                   
                                                                    <td className='caption'>
                                                                        OCCUPANCY DATE AT PREVIOUS ADDRESS
                                                                    </td>
                                                                    <td>
                                                                        {   PrevdateFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_950" selected={OccupancyPreviousDate} name="sub_1_field_950" onChange={(date) => { setOccupancyPreviousDate(date); setPrevdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_950" value={OccupancyPreviousDate} name="sub_1_field_950"  onChange={(date) => { setOccupancyPreviousDate(date); setPrevdateFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                        }  
                         
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                        RESIDENTIAL STATUS
                                                                    </td>
                                                                    <td>
                                                                        <select name="sub_1_field_951" className="text-uppercase" value={ResidentialStatus} onChange = {(e) => setResidentialStatus(e.target.value)} >
                                                                           <option value="0">-- Select an option --</option>
                                                                            {ResidentialStatusAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.status}
                                                                                    </option>
                                                                                    );
                                                                            })}
                                                                        </select>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        ISSUING OFFICE	
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_1730" type="text" value={IssuingOffice} onChange={(e) => { setIssuingOffice(e.target.value) }} className="text-uppercase" />
                                                                    </td>
                                                                    
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        OWNED PROPERTY TYPE	
                                                                    </td>

                                                                    <td style={{ textAlign: "left", width: "25%" }}>
                                                                        <select id="test" name="sub_1_field_1737" className="text-uppercase" value={OwnerPropertyType} onChange = {(e) => { setOwnerPropertyType(e.target.value) }} >
                                                                            <option value="0">-- Select An Option --</option>
                                                                            {OwnerPropertyTypeAll ?
                                                                                OwnerPropertyTypeAll.map((item) => {
                                                                                return (
                                                                                <option key={item.id} value={item.id}>
                                                                                {item.type}
                                                                                </option>
                                                                                );
                                                                            })
                                                                          : null }
                                                                        </select>
                                                                    </td>
                                                                

                                                                    <td className='caption'>
                                                                        PROVIDE NAME AND SURNAME OF CLIENT RELATIONSHIP MANAGER IF CLIENT HAS ONE
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_1821" type="text" value={ClientRelationshipManager} onChange={(e) => { setClientRelationshipManager(e.target.value) }} className="text-uppercase" />
                                                                    </td>
                                                                </tr>

                                                                <tr className="caption text-center" >
                                                                    <td colSpan={4} style={{ textAlign: "center" }}>
                                                                    
                                                                        { PrintButtonData != '' ?
                                                                            <input type="submit" name="command" value={PrintButton} onClick={(e) => { setPrintButton(e.target.value); setClientDetSave(e.target.value);  }} />
                                                                        : null }
                                                                        &nbsp;

                                                                        <input type="submit" name="Save" value="Save" onClick={(e) => { setClientDetSave(e.target.value) }} />&nbsp;
                                                                        <input type="submit" name="Save and Exit" value="Save and Exit" onClick={(e) => { setClientDetSave(e.target.value) }}/>&nbsp;
                                                                        
                                                                        <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                            <input type="button" className="p-relative" value="Cancel" />
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            </> : null}
                                                        
                                                    </tbody>
                                                </table>
                                        </form>

 {/*------------------ Employee Details -----------------------------------------  */}
                                           <form name="emp_details" method="post" onSubmit={handleAddEditEmployee} >
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="800" style={{ display: div_id2 }}>
                                                    <tbody>
                                                                <tr className="caption">
                                                                    <th colSpan={11} style={{ textAlign: 'center' }}>EMPLOYER DETAILS </th>
                                                                </tr>
                                                                
                                                                <tr>
                                                                    <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                        <span style={{ color: colorFocus1 }}> INCOME CATEGORY</span> <span style={{ color: "red" }}>*</span>
                                                                    </td>

                                                                    <td style={{ textAlign: "left", width: "25%" }}>
                                                                        <select id="test" name="sub_1_field_986" className="text-uppercase" value={EmpIncomeCategory} onChange = {(e) => { setEmpIncomeCategory(e.target.value); OnchangeEmpCateWiseHideSHow(e.target.value); }} >
                                                                            <option value="0">-- Select An Option --</option>
                                                                            {EmpIncomeCategoryAll.length > 0 ?
                                                                                EmpIncomeCategoryAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.type}
                                                                                    </option>
                                                                                    );
                                                                                })
                                                                            : null }
                                                                        </select>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        EMPLOYER
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_977" type="text" value={EmpEmployer} onChange={(e) => { setEmpEmployer(e.target.value) }} className="text-uppercase"/>
                                                                    </td>
                                                                </tr>

                                                                 {/* self employee wise hide show */}
                                                                
                                                                 <tr style={{display: SelfEmpHideShow}} >
                                                                    <td className='caption'>
                                                                        PERIOD SELF-EMPLOYED	
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1516" type="text" value={PeriodSselfEmployee} onChange={(e) => { setPeriodSselfEmployee(e.target.value) }} className="text-uppercase"/>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        FINANCIALS SUBMITTED		
                                                                    </td>
                                                                    <td> 
                                                                           <input type="radio" name="sub_1_field_1517"  value={FinancialsSubmitted == '786' ? FinancialsSubmitted : '786'} checked={FinancialsSubmitted == '786'} onChange={(e) => { setFinancialsSubmitted(e.target.value) }} />
                                                                            &nbsp;No
                                                                            <br />
                                                                            <input type="radio" name="sub_1_field_1517" value={FinancialsSubmitted == '785' ? FinancialsSubmitted : '785'} checked={FinancialsSubmitted == '785'} onChange={(e) => { setFinancialsSubmitted(e.target.value) }} />
                                                                            &nbsp;Yes
                                                                    </td>
                                                                </tr>

                                                                <tr style={{display: SelfEmpHideShow}}>
                                                                   
                                                                    <td className='caption'>
                                                                        LAST 6 MONTHS BANK STATEMENTS SUBMITTED		
                                                                    </td>
                                                                    <td> 
                                                                        <input type="radio" name="sub_1_field_1518"  value={Last6MonthStmtSubmitted == '786' ? Last6MonthStmtSubmitted : '786'} checked={Last6MonthStmtSubmitted == '786'} onChange={(e) => { setLast6MonthStmtSubmitted(e.target.value) }} />
                                                                            &nbsp;No
                                                                            <br />
                                                                        <input type="radio" name="sub_1_field_1518" value={Last6MonthStmtSubmitted == '785' ? Last6MonthStmtSubmitted : '785'} checked={Last6MonthStmtSubmitted == '785'} onChange={(e) => { setLast6MonthStmtSubmitted(e.target.value) }} />
                                                                            &nbsp;Yes
                                                                    </td>

                                                                    <td className='caption'>
                                                                        &nbsp;	
                                                                    </td>
                                                                    <td>
                                                                        &nbsp;
                                                                    </td>
                                                                </tr>
                                                                
                                                                {/* end */}
                                                                 
                                                                <tr>
                                                                    <td className='caption'>
                                                                       BUSINESS ACTIVITY<br/>
                                                                       <span style={{fontSize: "8px"}}>(IN CASE OF SELF-EMPLOYED CUSTOMERS)</span>
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1756" type="text" value={BussinessActivity} onChange={(e) => { setBussinessActivity(e.target.value) }} className="text-uppercase"/>
                                                                    </td>


                                                                    <td className='caption'>
                                                                        OCCUPATION
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_979" type="text" value={EmpOccupation} onChange={(e) => { setEmpOccupation(e.target.value) }} className="text-uppercase"/>
                                                                    </td>

                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                       EMPLOYEE #	
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_982" type="text" value={EmpEmpoyeeHash} onChange={(e) => { setEmpEmpoyeeHash(e.target.value) }} className="text-uppercase"/>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        CONTACT NUMBER	
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_985" type="text" value={EmpContactNo} onChange={(e) => { setEmpContactNo(e.target.value) }} className="text-uppercase"/>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                        <span style={{ color: colorFocus }}> SALARY DATE</span> <span style={{ color: "red" }}>*</span>
                                                                    </td>
                                                                    <td>
                                                                        <select id="test" name="sub_1_field_1069" className="text-uppercase" value={EmpSalaryDate} onChange = {(e) => { setEmpSalaryDate(e.target.value) }} >
                                                                            <option value="0">-- Select An Option --</option>
                                                                            {EmpSalaryDateAll.length > 0 ?
                                                                                EmpSalaryDateAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.date}
                                                                                    </option>
                                                                                    );
                                                                                })
                                                                            : null }
                                                                        </select>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        DEPARTMENT
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1758" type="text" value={Department} onChange={(e) => { setDepartment(e.target.value) }} className="text-uppercase"/>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                        <span style={{ color: colorFocus2 }}> EMPLOYER ADDRESS </span> <span style={{ color: "red" }}>*</span>
                                                                    </td>
                                                                    <td>
                                                                        <textarea name="sub_1_field_984" type="text" value={EmpAddress} onChange={(e) => { setEmpAddress(e.target.value) }} className="text-uppercase"></textarea>
                                                                      
                                                                    </td>
                                                                    
                                                                    <td className='caption'>
                                                                    <span style={{ color: colorFocus3 }}> TELEPHONE (HOME) </span>  <span style={{ color: "red" }}>*</span>
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1864" type="text" value={TelephoneHome} onChange={(e) => { setTelephoneHome(e.target.value) }} className="text-uppercase"/>
                                                                    </td>

                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                        <span style={{ color: colorFocus4 }}> TELEPHONE (WORK) </span> <span style={{ color: "red" }}>*</span>
                                                                    </td>
                                                                    <td>
                                                                       
                                                                        <input name="sub_1_field_1865"  type="text" value={TelephoneWork} onChange={(e) => { setTelephoneWork(e.target.value) }} className="text-uppercase" />
                                                                      
                                                                    </td>
                                                                    
                                                                    <td className='caption'>
                                                                    <span style={{ color: colorFocus5 }}> EMAIL ADDRESS</span> <span style={{ color: "red" }}>*</span>
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1866" type="text" value={EmailAddress} onChange={(e) => { setEmailAddress(e.target.value) }} className="text-uppercase"/>
                                                                    </td>

                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                    <span style={{ color: colorFocus6 }}>  MOBILE NUMBER </span> <span style={{ color: "red" }}>*</span>
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1867" type="text" value={EmpMobileNumber} onChange={(e) => { setEmpMobileNumber(e.target.value) }} className="text-uppercase"/>
                                                                    </td>

                                                                     <td className='caption'>
                                                                        <span style={{ color: colorFocus7 }}> SUPERVISOR</span> <span style={{ color: "red" }}>*</span>
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1077" type="text" value={EmpSupervisor} onChange={(e) => { setEmpSupervisor(e.target.value) }} className="text-uppercase"/>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                       EMPLOYED FROM	
                                                                    </td>
                                                                    <td>
                                                                        {   EmployeeFromFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_980" selected={EmployeeFrom} name="sub_1_field_980" onChange={(date) => { setEmployeeFrom(date); setEmployeeFromFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off"  filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_980" value={EmployeeFrom} name="sub_1_field_980"  onChange={(date) => { setEmployeeFrom(date); setEmployeeFromFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off"  filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                        }
                                                                    </td>

                                                                    <td className='caption' style={{display: ParmentHideShow}} >
                                                                        EMPLOYED TO
                                                                    </td>
                                                                    <td style={{display: ParmentHideShow}}>
                                                                        {   EmployeeToFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_981" selected={EmployeeTo} name="sub_1_field_981" onChange={(date) => { setEmployeeTo(date); setEmployeeToFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off"  filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_981" value={EmployeeTo} name="sub_1_field_981"  onChange={(date) => { setEmployeeTo(date); setEmployeeToFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off"  filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                        }
                                                                    </td>
                                                                </tr>

                                                                <tr className="caption text-center" >
                                                                    <td colSpan={11} style={{ textAlign: "center" }}>
                                                                        
                                                                        { PrintButtonData != '' ?
                                                                                 <input type="submit" name="command" value={PrintButton} onClick={(e) => { setPrintButton(e.target.value); setEmployeeDetailsSave(e.target.value); }} />
                                                                        : null }
                                                                        &nbsp;

                                                                        <input type="submit" name="command" value="Save" onClick={(e) => { setEmployeeDetailsSave(e.target.value) }}/>&nbsp;
                                                                       
                                                                        <input type="submit" name="command" value="Save and Exit" onClick={(e) => { setEmployeeDetailsSave(e.target.value) }} />&nbsp;
                                                                       
                                                                        <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                        <input type="button" className="p-relative" value="Cancel" />
                                                                        </Link>
                                                                
                                                                    </td>
                                                                </tr>
                                                     </tbody>
                                                </table> 
                                            </form>   

 {/*------------------ Client Finance Details -----------------------------------------  */}

                                            <form name="clientDetails" method="post" onSubmit={handleClientFinanceDetailsSubmit} >            
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="800" style={{ display: div_id3 }}>
                                                    <tbody>  
                                                        {/* {FinancialDetailFlag ?
                                                            <>   */}
                                                                    <tr className="caption">
                                                                        <th colSpan={4} style={{ textAlign: 'center' }}>CLIENT'S FINANCIAL DETAILS </th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>TRANSACTION</td>
                                                                        <td>{FinanceTransactionId}</td>

                                                                        <td className="caption" style={{ width: "25%" }}>&nbsp;</td>
                                                                        <td>&nbsp;</td>
                                                                    </tr>

                                                                  
                                                                    <tr>
                                                                        <th className="caption" colSpan="4" style={{ textAlign: "center" }}>Income</th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>Gross Monthly Basic Salary</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;
                                                                           
                                                                            <NumericFormat type="text" id="sub_1_field_988" name="sub_1_field_988"  value={GrossMonthlySalary} onChange={(e) => { setGrossMonthlySalary(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(1);}} onBlur={() => { ShowZero(1); }}/> 
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>Commission (Last 6 months average)</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;
                                                                           
                                                                            <NumericFormat type="text" id="sub_1_field_989" name="sub_1_field_989"  value={Commission6MonthAvg} onChange={(e) => { setCommission6MonthAvg(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(2);}} onBlur={() => { ShowZero(2); }}/> 
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>OVERTIME (LAST 6 MONTHS AVERAGE)</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;
                                                                            
                                                                            <NumericFormat type="text" id="sub_1_field_990" name="sub_1_field_990"  value={Overtime6MonthAvg} onChange={(e) => { setOvertime6MonthAvg(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(3);}} onBlur={() => { ShowZero(3); }}/> 
                                                                        </td>
                                                                 
                                                                        <td className="caption" style={{ width: "25%" }}>PAYSLIP NETT MONTHLY INCOME</td>
                                                                        <td id="1" style={{ textAlign: "right", width: "25%" }} >
                                                                            P&nbsp;
                                                                            
                                                                            <NumericFormat type="text" id="sub_1_field_993" name="sub_1_field_993"  value={PaySlipMAllowance} onChange={(e) => { setPaySlipMAllowance(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(6);}} onBlur={() => { ShowZero(6); }} onKeyUp={(e) => { Calculate(e.target.value) }}/> 
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>ALL OTHER INCOME</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;
                                                                           
                                                                            <NumericFormat type="text" id="sub_1_field_996" name="sub_1_field_996"  value={AllOtherIncome} onChange={(e) => { setAllOtherIncome(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(7);}} onBlur={() => { ShowZero(7); }} onKeyUp={(e) => { Calculate(e.target.value) }}/>
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>TOTAL INCOME</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;
                                                                            <NumericFormat type="hidden" name="hidden_total_income" value={HiddenTotalIncome} onChange={(e) => { setHiddenTotalIncome(e.target.value) }} fixedDecimalScale=".00"/>

                                                                            <NumericFormat type="text" id="sub_1_field_997" name="sub_1_field_997"  value={TotalIncome} onChange={(e) => { setTotalIncome(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(8);}} onBlur={() => { ShowZero(8); }} readOnly/>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <th className="caption" colSpan="4" style={{ textAlign: "center" }}>CARD DETAILS</th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>CREDIT/STORE CARD NAME	</td>
                                                                        <td>
                                                                            <input type="text" name="sub_1_field_1746" className="text-uppercase" value={CreditCardName} onChange={(e) => { setCreditCardName(e.target.value) }} />
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>CARD NUMBER</td>
                                                                        <td>
                                                                            <input type="text" name="sub_1_field_1743" className="text-uppercase" value={CardNumber} onChange={(e) => { setCardNumber(e.target.value) }}  />
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>CARD EXPIRY</td>
                                                                        <td>
                                                                            {  CardExpiryDateFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_1745" selected={CardExpiryDate} name="sub_1_field_1745" onChange={(date) => { setCardExpiryDate(date); setCardExpiryDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_1745" value={CardExpiryDate} name="sub_1_field_1745"  onChange={(date) => { setCardExpiryDate(date); setCardExpiryDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            }  
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>&nbsp;</td>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                   
                                                                    <tr>
                                                                        <th className="caption" colSpan="4" style={{ textAlign: "center" }}>EXPENSES</th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>OTHER EXISTING LOANS</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                            <input type="radio" name="sub_1_field_1708"  value={ExistingLoan == '786' ? ExistingLoan : '786'} checked={ExistingLoan == '786'} onChange={(e) => { setExistingLoan(e.target.value) }} onClick={(e) => { OtherExistingHideShowTab("no"); ClearMonthlyInstallment(e.target.value); }} />
                                                                            &nbsp;No
                                                                            <br />
                                                                            <input type="radio" name="sub_1_field_1708" value={ExistingLoan == '785' ? ExistingLoan : '785'} checked={ExistingLoan == '785'} onChange={(e) => { setExistingLoan(e.target.value) }} onClick={(e) => { OtherExistingHideShowTab("yes") }} />
                                                                            &nbsp;Yes
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            &nbsp;
                                                                        </td>

                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            &nbsp;
                                                                        </td>
                                                                    </tr>
                                                                  
                                                                            <tr style={{ display: OtherExistingHideShow }}>
                                                                                <td className='caption' colSpan="4" style={{ textAlign: "center" }}>LOAN 1</td>
                                                                            </tr>

                                                                            <tr style={{ display: OtherExistingHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>TO BE SETTLED	</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                                    <input type="radio" name="sub_1_field_1726" value={ToBeSealed1 == '786' ? ToBeSealed1 : '786'} checked={ToBeSealed1 == '786'} onChange={(e) => { setToBeSealed1(e.target.value) }} onClick={(e) => { Loan1HideShowTab("no") }} /> No
                                                                                    <br />
                                                                                    <input type="radio" name="sub_1_field_1726" value={ToBeSealed1 == '785' ? ToBeSealed1 : '785'} checked={ToBeSealed1 == '785'} onChange={(e) => { setToBeSealed1(e.target.value) }} onClick={(e) => { Loan1HideShowTab("yes") }} />
                                                                                     Yes
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>
                                                                                   SETTLEMENT AMOUNT	
                                                                                </td>

                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                               
                                                                                    <NumericFormat type="text" id="sub_1_field_1760" name="sub_1_field_1760"  value={SettlementAmount1} onChange={(e) => { setSettlementAmount1(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(16);}} onBlur={() => { ShowZero(16); }} />
                                                                                
                                                                                </td>
                                                                            </tr>
                                                                            <tr className='caption' colSpan="4" style={{ display: Loan1HideShow }}>

                                                                                <td colSpan={4} style={{ color: "red", textAlign: "center", fontSize: "10px" }}>* if loan to be settled do not complete monthly installment value below</td>
                                                                            </tr>

                                                                            <tr style={{ display: OtherExistingHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>FINANCIAL PROVIDER</td>
                                                                                <td id="1" style={{ textAlign: "left", width: "25%" }}>
                                                                                <select name="sub_1_field_1709" className="text-uppercase" style={{ textTransform: "uppercase" }} value={FinantialProviderLoan1} onChange = {(e) => setFinantialProviderLoan1(e.target.value)} >
                                                                                    <option value="0">-- Select An Option --</option>
                                                                                    {FinantialProviderAll.map((item) => {
                                                                                        return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                        {item.type}
                                                                                        </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>MONTHLY INSTALMENT</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1711" name="sub_1_field_1711"  value={MonthlyInvestment1} onChange={(e) => { setMonthlyInvestment1(e.target.value) }} style={{textAlign: 'right', width: '12.4em', pointerEvents: MonthinstallReadonly}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(9);}} onBlur={() => { ShowZero(9); }} onKeyUp={(e) => { Calculate2(e.target.value);}} />
                                                                                </td>
                                                                            </tr>

                                                                            <tr style={{ display: OtherExistingHideShow }}>
                                                                                <td className='caption' colSpan="4" style={{ textAlign: "center" }}>LOAN 2</td>
                                                                            </tr>

                                                                            <tr style={{ display: OtherExistingHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>TO BE SETTLED	</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                                    <input type="radio" name="sub_1_field_1727" value={ToBeSealed2 == '786' ? ToBeSealed2 : '786'} checked={ToBeSealed2 == '786'} onChange={(e) => { setToBeSealed2(e.target.value) }} onClick={(e) => { Loan2HideShowTab("no") }} />&nbsp;No
                                                                                    <br />
                                                                                    <input type="radio" name="sub_1_field_1727" value={ToBeSealed2 == '785' ? ToBeSealed2 : '785'} checked={ToBeSealed2 == '785'} onChange={(e) => { setToBeSealed2(e.target.value) }} onClick={(e) => { Loan2HideShowTab("yes") }} />
                                                                                    &nbsp;Yes
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>
                                                                                    SETTLEMENT AMOUNT	
                                                                                </td>

                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    <NumericFormat type="text" id="sub_1_field_1761" name="sub_1_field_1761"  value={SettlementAmount2} onChange={(e) => { setSettlementAmount2(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(17);}} onBlur={() => { ShowZero(17);}} />
                                                                                </td>
                                                                                
                                                                            </tr>
                                                                            
                                                                            <tr className='caption' colSpan="4" style={{ display: Loan2HideShow }} >
                                                                                <td colSpan={4} style={{ color: "red", textAlign: "center", fontSize: "10px" }}>* if loan to be settled do not complete monthly installment value below</td>
                                                                            </tr>

                                                                            <tr style={{ display: OtherExistingHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>FINANCIAL PROVIDER</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                                <select name="sub_1_field_1718" className="text-uppercase" style={{ textTransform: "uppercase" }} value={FinantialProviderLoan2} onChange = {(e) => setFinantialProviderLoan2(e.target.value)} >
                                                                                    <option value="0">-- Select An Option --</option>
                                                                                    {FinantialProviderAll.map((item) => {
                                                                                        return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                        {item.type}
                                                                                        </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>MONTHLY INSTALMENT</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1717" name="sub_1_field_1717"  value={MonthlyInvestment2} onChange={(e) => { setMonthlyInvestment2(e.target.value) }} style={{textAlign: 'right', width: '12.4em', pointerEvents: MonthinstallReadonly1}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(10);}} onBlur={() => { ShowZero(10); }} onKeyUp={(e) => { Calculate2(e.target.value);}} />
                                                                                </td>
                                                                            </tr>
                                                                       
                                                                    <tr> 
                                                                        <th className='caption' colSpan="4" style={{ textAlign: "center" }}>EXISTING / PREVIOUS VEHICLE ACCOUNTS</th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            <span id="sub_1_label_986" title="Income field is mandatory">EXISTING / PREVIOUS VEHICLE FINANCE COMPANY </span>
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                           
                                                                            <select name="sub_1_field_1475" className="text-uppercase" style={{ textTransform: "uppercase" }} value={FinantialCompany} onChange = {(e) => setFinantialCompany(e.target.value)} >
                                                                                    <option value="0">-- Select An Option --</option>
                                                                                    {FinantialCompanyAll.map((item) => {
                                                                                        return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                        {item.type}
                                                                                        </option>
                                                                                        );
                                                                                    })}
                                                                            </select>

                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            ACCOUNT NUMBER
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" className="text-uppercase" name="sub_1_field_1477" value={AccountNumber} onChange={(e) => { setAccountNumber(e.target.value) }} />

                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            PREVIOUS FINANCE COMPANY BRANCH	
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" className="text-uppercase" name="sub_1_field_1476" value={previousBranch} onChange={(e) => { setpreviousBranch(e.target.value) }} />

                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>INSTALMENT	</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;
                                                                            
                                                                            <NumericFormat type="text" id="sub_1_field_1478" name="sub_1_field_1478"  value={Installment} onChange={(e) => { setInstallment(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(11);}} onBlur={() => { ShowZero(11);}} onKeyUp={(e) => { Calculate2(e.target.value);}}/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>TO BE SETTLED	</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                            <input type="radio" name="sub_1_field_1481"  value={ToBeSealed3 == '786' ? ToBeSealed3 : '786'} checked={ToBeSealed3 == '786'} onChange={(e) => { setToBeSealed3(e.target.value) }} onClick={(e) => { ClearInstallemnt(e.target.value) }} />
                                                                            &nbsp;No
                                                                            <br />
                                                                            <input type="radio" name="sub_1_field_1481" value={ToBeSealed3 == '785' ? ToBeSealed3 : '785'} checked={ToBeSealed3 == '785'} onChange={(e) => { setToBeSealed3(e.target.value) }} onClick={(e) => { ClearInstallemnt(e.target.value) }} />
                                                                            &nbsp;Yes
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>SETTLEMENT	</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;
                                                                           
                                                                            <NumericFormat type="text" id="sub_1_field_1479" name="sub_1_field_1479"  value={Settlement} onChange={(e) => { setSettlement(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(12);}} onBlur={() => { ShowZero(12); }}/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%",color: MonthLeftColor }}>
                                                                            MONTHS LEFT<span style={{ color: "red"}}> *</span>
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" className="text-uppercase" name="sub_1_field_1480" value={MonthLeft} onChange={(e) => { setMonthLeft(e.target.value) }} />

                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <th className="caption" colSpan="4" style={{ textAlign: "center" }}>
                                                                            PROPERTY
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%", border: OwnHomeColor }}>DO YOU OWN YOUR HOME?</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="radio" name="sub_1_field_1483" value="786" onChange={(e) => setOwnHome(786)} checked={OwnHome == '786'} onClick={(e) => { OtherFieldHideShow(e.target.value); }}/>
                                                                            &nbsp;No
                                                                            <br />
                                                                            <input type="radio" name="sub_1_field_1483" value="785" onChange={(e) => setOwnHome(785)} checked={OwnHome == '785'} onClick={(e) => { OtherFieldHideShow(e.target.value); }}/>
                                                                            &nbsp;Yes
                                                                        </td>
                                                                        <td className="caption"></td>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                    {/* {OwnHome === 0 ?
                                                                        <> */}
                                                                            <tr style={{ display: PropertyHideShow }} >
                                                                                <td className="caption" style={{ width: "25%" }}>
                                                                                    LANDLORD NAME
                                                                                </td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                                    <input type="text" name="sub_1_field_1493" className="text-uppercase" value={LandlordName} onChange={(e) => { setLandlordName(e.target.value) }} />

                                                                                </td>

                                                                                <td className="caption" style={{ width: "25%" }}>RENTAL / LODGING VALUE	<span style={{ color: "red" }}>*</span>	</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                    
                                                                                    <NumericFormat type="text" id="sub_1_field_1712" name="sub_1_field_1712"  value={RentalValue} onChange={(e) => { setRentalValue(e.target.value) }} style={{textAlign: 'right', width: '12.4em', border: RentalValueBorder}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(13);}} onBlur={(e) => { ShowZero(13); ChangeForsurpulse(e.target.value); }} onKeyUp={(e) => { Calculate2(e.target.value);}} />
                                                                                </td>
                                                                               
                                                                            </tr>

                                                                            <tr style={{ display: PropertyHideShow }}>
                                                                                <td className="caption" style={{ width: "25%" }}>
                                                                                    LANDLORD ADDRESS
                                                                                </td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                                    <input type="text" name="sub_1_field_1494" className="text-uppercase" value={LandlordAddress} onChange={(e) => { setLandlordAddress(e.target.value) }} />

                                                                                </td>

                                                                                <td className="caption" style={{ width: "25%" }}></td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                </td>
                                                                               
                                                                            </tr>
                                                                      
                                                                            <tr style={{ display: PropertyHideShow1 }} >
                                                                                <td className="caption" style={{ width: "25%" }}>BOND MONTHLY INSTALMENT <span style={{ color: "red" }}>*</span> </td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                    
                                                                                    <NumericFormat type="text" id="sub_1_field_1713" name="sub_1_field_1713"  value={MonthlyBond} onChange={(e) => { setMonthlyBond(e.target.value) }} style={{textAlign: 'right', width: '12.4em', border: BondMonthlyBorder}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(83);}} onBlur={() => { ShowZero(83); }} onKeyUp={(e) => { Calculate2(e.target.value) }}/>

                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>
                                                                                    BOND HOLDER (BANK)
                                                                                </td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                                    <input type="text" id="sub_1_field_1488" className="text-uppercase" name="bond_holder" value={BondHolder} onChange={(e) => { setBondHolder(e.target.value) }} />

                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: PropertyHideShow1 }} >
                                                                                <td className="caption" style={{ width: "25%" }}>PURCHASE PRICE	</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                    
                                                                                    <NumericFormat type="text" id="sub_1_field_1491" name="sub_1_field_1491"  value={PurchasePrice} onChange={(e) => { setPurchasePrice(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(84);}} onBlur={() => { ShowZero(84); }}/>
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>
                                                                                    PURCHASE DATE
                                                                                </td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
 
                                                                                {   PurchaseDateFlag == 1 ?
                                                                                    <DatePicker id="sub_1_field_1489" selected={PurchaseDate} name="sub_1_field_1489" onChange={(date) => { setPurchaseDate(date); setPurchaseDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                        return new Date() > d; }}/>
                                                                                :
                                                                                    <DatePicker id="sub_1_field_1489" value={PurchaseDate} name="sub_1_field_1489"  onChange={(date) => { setPurchaseDate(date); setPurchaseDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                        return new Date() > d; }}/>
                                                                                }  

                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: PropertyHideShow1 }} >
                                                                                <td className="caption" style={{ width: "25%" }}>PRESENT MARKET VALUE	</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                  
                                                                                    <NumericFormat type="text" id="sub_1_field_1492" name="sub_1_field_1492"  value={MarketValue} onChange={(e) => { setMarketValue(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(85);}} onBlur={() => { ShowZero(85); }}/>
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>
                                                                                    IN WHOSE NAME?
                                                                                </td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                                    <input type="text" id="sub_1_field_1485" className="text-uppercase"  name="poverty_name" value={PovertyName} onChange={(e) => { setPovertyName(e.target.value) }} />

                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ display: PropertyHideShow1 }}>
                                                                                <td className="caption" style={{ width: "25%" }}>OUTSTANDING BOND AMOUNT</td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                    P&nbsp;
                                                                                   
                                                                                    <NumericFormat type="text" id="sub_1_field_1490" name="sub_1_field_1490"  value={BondAmount} onChange={(e) => { setBondAmount(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(86);}} onBlur={() => { ShowZero(86); }}/>
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>
                                                                                    STAND NUMBER
                                                                                </td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                                    <input type="text" className="text-uppercase" id="sub_1_field_1486"   name="sub_1_field_1486" value={StandNumber} onChange={(e) => { setStandNumber(e.target.value) }} />

                                                                                </td>
                                                                            </tr>
                                                                           
                                                                            <tr style={{ display: PropertyHideShow1 }}>
                                                                                <td className="caption" style={{ width: "25%" }}></td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                                </td>
                                                                                <td className="caption" style={{ width: "25%" }}>
                                                                                    SUBURB
                                                                                </td>
                                                                                <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                                    <input type="text" id="sub_1_field_1487" name="suburb" value={Suburb} onChange={(e) => { setSuburb(e.target.value) }} />

                                                                                </td>
                                                                            </tr> 
                                                                    
                                                                    <tr>
                                                                        <th className="caption" colSpan="4" style={{ textAlign: "center" }}>
                                                                            BANK DETAILS
                                                                        </th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                             ARE YOU AN EXISTING BARCLAYS ACCOUNT HOLDER
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="radio" name="sub_1_field_1739" value="786" onChange={(e) => setExistingAccountHolder(786)} checked={ExistingAccountHolder == '786'} onClick={() => { AccountHoldersHideShow('no');}}/>
                                                                            &nbsp;No
                                                                            <br />
                                                                            <input type="radio" name="sub_1_field_1739" value="785" onChange={(e) => setExistingAccountHolder(785)} checked={ExistingAccountHolder == '785'} onClick={() => { AccountHoldersHideShow('yes');}}/>
                                                                            &nbsp;Yes
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            &nbsp;
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            &nbsp;
                                                                        </td>
                                                                    </tr> 
                                                                  
                                                                    <tr style={{ display: AccountHolderHideShow }}> 
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            HOW LONG HAVE YOU HAD AN ACCOUNT WITH BARCLAYS?	
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            {   AccountWithBarclaysDateFlag == 1 ?
                                                                                    <DatePicker id="sub_1_field_1026" selected={AccountWithBarclaysDate} name="sub_1_field_1026" onChange={(date) => { setAccountWithBarclaysDate(date); setAccountWithBarclaysDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                        return new Date() > d; }}/>
                                                                                :
                                                                                    <DatePicker id="sub_1_field_1026" value={AccountWithBarclaysDate} name="sub_1_field_1026"  onChange={(date) => { setAccountWithBarclaysDate(date); setAccountWithBarclaysDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                        return new Date() > d; }}/>
                                                                            }  
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            IS YOUR SALARY PAID DIRECTLY TO BARCLAYS	
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="radio" name="sub_1_field_1750" value="786" onChange={(e) => setSalaryPaid(786)} checked={SalaryPaid == '786'} />
                                                                            &nbsp;No
                                                                            <br />
                                                                            <input type="radio" name="sub_1_field_1750" value="785" onChange={(e) => setSalaryPaid(785)} checked={SalaryPaid == '785'} />
                                                                            &nbsp;Yes
                                                                        </td>
                                                                    </tr> 

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            WILL YOU TRANSFER YOUR ACCOUNT TO BARCLAYS	
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="radio" name="sub_1_field_1757" value="786" onChange={(e) => setTransferAccount(786)} checked={TransferAccount == '786'} />
                                                                            &nbsp;No
                                                                            <br />
                                                                            <input type="radio" name="sub_1_field_1757" value="785" onChange={(e) => setTransferAccount(785)} checked={TransferAccount == '785'} />
                                                                            &nbsp;Yes
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            &nbsp;
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            &nbsp;
                                                                        </td>
                                                                    </tr> 

                                                                    <tr>
                                                                        <th className="caption" colSpan="4" style={{ textAlign: "center" }}>
                                                                            TOTAL DISPOSABLE INCOME
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>{DefectSurpulse}<span style={{ color: "red" }}> *</span>	</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;

                                                                            <NumericFormat type="hidden" name="hidden_sirplus" value={HiddenSurplus} onChange={(e) => { setHiddenSurplus(e.target.value) }} decimalScale={2} fixedDecimalScale=".00" />
                                                                           
                                                                            <NumericFormat type="text" id="sub_1_field_1019" name="sub_1_field_1019"  value={Surplus} onChange={(e) => { setSurplus(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(14);}} onBlur={() => { ShowZero(14); }} readOnly/>
                                                                        </td>
                                                                    </tr>

                                                                    <tr className="caption text-center" >
                                                                        <td colSpan={4} style={{ textAlign: "center" }}>
                                                                            { PrintButtonData != '' ?
                                                                                 <input type="submit" name="command" value={PrintButton} onClick={(e) => { setPrintButton(e.target.value);setClientsFinanceDetSave(e.target.value); }} />
                                                                            : null }
                                                                            &nbsp;

                                                                            <input type="submit" name="Save" value="Save" onClick={(e) => { setClientsFinanceDetSave(e.target.value) }} />&nbsp;
                                                                            <input type="submit" name="Save and Exit" value="Save and Exit" onClick={(e) => { setClientsFinanceDetSave(e.target.value) }}/>&nbsp;
                                                                            
                                                                            <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                                <input type="button" className="p-relative" value="Cancel" />
                                                                            </Link>
                                                                        </td>
                                                                    </tr>

                                                                {/* </> : null } */}
                                                     </tbody>
                                                </table>
                                            </form>    
 {/* --------- Loan Specifics -------------------------------- */}
                                            <form name="clientDetails" method="post" onSubmit={handleLoanDetailsSubmit}>    
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="800" style={{ display: div_id4 }}>
                                                    <tbody>  
                                                       
                                                                    <tr className="caption">
                                                                        <th colSpan={4} style={{ textAlign: 'center' }}>LOAN SPECIFICS
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>Transaction</td><td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                           {/* <input type="hidden" id="sub_1_field_1028" name="sub_1_field_1028" value={LoanTransaction} onChange={(e) => { setLoanTransaction(e.target.value) }}/>*/}
                                                                            {LoanTransactionDisplay} 
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            COMMENCEMENT DATE
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                        
                                                                            {   LoanCommencementDateFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_1533" selected={CommencementDate} name="sub_1_field_1533" onChange={(date) => { setCommencementDate(date); setLoanCommencementDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" />
                                                                            :
                                                                                <DatePicker id="sub_1_field_1533" value={CommencementDate} name="sub_1_field_1533"  onChange={(date) => { setCommencementDate(date); setLoanCommencementDateFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" />
                                                                            }
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>INTEREST TYPE</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                            <input type="radio" name="sub_1_field_1247"  value={InterestType == '1' ? InterestType : '1'} checked={InterestType == '1'} onChange={(e) => { setInterestType(e.target.value) }} />
                                                                            &nbsp; FIXED
                                                                            <br />
                                                                            <input type="radio" name="sub_1_field_1247" value={InterestType == '2' ? InterestType : '2'} checked={InterestType == '2'} onChange={(e) => { setInterestType(e.target.value) }} />
                                                                            &nbsp; LINKED
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            FIRST DEBIT ORDER/ INSTALMENT DATE
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            
                                                                            {   LoanFirstDebitOrderDateFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_1510" selected={FirstDebitOrder} name="sub_1_field_1510" onChange={(date) => { setFirstDebitOrder(date); setLoanFirstDebitOrderDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" />
                                                                            :
                                                                                <DatePicker id="sub_1_field_1510" value={FirstDebitOrder} name="sub_1_field_1510"  onChange={(date) => { setFirstDebitOrder(date); setLoanFirstDebitOrderDateFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" />
                                                                            }
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>SALE TYPE</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                            <input type="radio" name="sub_1_field_1246" value={SaleType == '1' ? SaleType : '1'} checked={SaleType == '1'} onChange={(e) => { setSaleType(e.target.value) }} />
                                                                            &nbsp; FLEET
                                                                            <br />
                                                                            <input type="radio" name="sub_1_field_1246" value={SaleType == '2' ? SaleType : '2'} checked={SaleType == '2'} onChange={(e) => { setSaleType(e.target.value) }} />
                                                                            &nbsp; VEHICLE
                                                                        </td>

                                                                        <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                            FINANCE TYPE
                                                                        </td>

                                                                        <td style={{ textAlign: "left", width: "25%" }}>
                                                                            <select name="sub_1_field_1509" className="text-uppercase" style={{ textTransform: "uppercase" }} value={FinanceType} onChange = {(e) => setFinanceType(e.target.value)} >
                                                                                <option value="0">-- Select An Option --</option>
                                                                                {FinanceTypeAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.type}
                                                                                    </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                           LOAN EXPIRY DATE	
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            
                                                                            {   LoanExpDateFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_1751" selected={LoanExpDate} name="sub_1_field_1751" onChange={(date) => { setLoanExpDate(date); setLoanExpDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" />
                                                                            :
                                                                                <DatePicker id="sub_1_field_1751" value={LoanExpDate} name="sub_1_field_1751"  onChange={(date) => { setLoanExpDate(date); setLoanExpDateFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" />
                                                                            }
                                                                        </td>            
                                                                        
                                                                        <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                            TERM (MONTHS)
                                                                        </td> 

                                                                        <td style={{ textAlign: "left", width: "25%" }}>
                                                                            <select name="sub_1_field_1031" className="text-uppercase" style={{ textTransform: "uppercase" }} value={TermMonth} onChange = {(e) => setTermMonth(e.target.value)} >
                                                                                <option value="0">-- Select An Option --</option>
                                                                                {TermMonthAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.month}
                                                                                    </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            <span style={{ color: LoanValidateColor }}>PREFERRED INTEREST RATE (%)</span> <span style={{ color: "red" }}>*</span>
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1030" value={InterestRate} onChange={(e) => { setInterestRate(e.target.value) }} />
                                                                        </td>
                                                                       
                                                                        <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                            BUISNESS SEGMENT
                                                                        </td>

                                                                        <td style={{ textAlign: "left", width: "25%" }}>
                                                                            <select name="sub_1_field_1819" className="text-uppercase" style={{ textTransform: "uppercase" }} value={BusinessSegment} onChange = {(e) => setBusinessSegment(e.target.value)} >
                                                                                <option value="0">-- Select An Option --</option>
                                                                                {BusinessSegmentAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.segment}
                                                                                    </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>LOAN EQUITY</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                           <input id="sub_1_field_1456" type="hidden" name="sub_1_field_1456" value={LoanEquity} onChange={(e) => { setLoanEquity(e.target.value) }} />
                                                                             {LoanEquity} %
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>DEPOSIT PERCENTAGE	</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input id="sub_1_field_1459" type="hidden" name="sub_1_field_1459" value={LoanDepositPercentage} onChange={(e) => { setLoanDepositPercentage(e.target.value) }} />
                                                                            {LoanDepositPercentage} %
                                                                        </td>
                                                                    </tr>
                                                                    
                                                                    <tr className="caption">
                                                                        <th colSpan={4} style={{ textAlign: 'center' }}>TRANSACTION SPECIFICS
                                                                        </th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>ASSET AMOUNT</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                          <input type="hidden" id="sub_1_field_1039" name="sub_1_field_1039" value={LoanAssetAmount} onChange={(e) => { setLoanAssetAmount(e.target.value) }} />
                                                                           P {LoanAssetAmount}
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>INSURANCE PRODUCTS	</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                           <input type="hidden" id="sub_1_field_1450" name="sub_1_field_1450" value={LoanInsuranceProduct} onChange={(e) => { setLoanInsuranceProduct(e.target.value) }} />
                                                                           P {LoanInsuranceProduct}
                                                                        </td>
                                                                    </tr>
                                                                    
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>NON-INSURANCE PRODUCTS	</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                          <input type="hidden" id="sub_1_field_1451" name="sub_1_field_1451" value={LoanNonInsuranceProduct} onChange={(e) => { setLoanNonInsuranceProduct(e.target.value) }} />
                                                                          P {LoanNonInsuranceProduct}
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            COMPREHENSIVE INSURANCE
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                           <input type="hidden" id="sub_1_field_1452" name="sub_1_field_1452" value={LoanCompersiveInsurance} onChange={(e) => { setLoanCompersiveInsurance(e.target.value) }} />
                                                                           P {LoanCompersiveInsurance}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                       
                                                                        <td className="caption" style={{ width: "25%" }}>DISCOUNT</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            <input type="hidden" id="sub_1_field_1042" name="sub_1_field_1042" value={LoanDiscount} onChange={(e) => { setLoanDiscount(e.target.value) }}  />
                                                                            P {LoanDiscount}
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>CREDIT LIFE INSURANCE	</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            {/* <input type="hidden" id="sub_1_field_1042" name="sub_1_field_1042" value={LoanDiscount} onChange={(e) => { setLoanDiscount(e.target.value) }}  /> */}
                                                                            P {CreditLifeInsurance} 
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>CASH/CHEQUE/EFT DEPOSIT VALUE</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                           <input type="hidden" id="sub_1_field_1043" name="sub_1_field_1043"  value={LoanCashDepositValue} onChange={(e) => { setLoanCashDepositValue(e.target.value) }} />
                                                                            P {LoanCashDepositValue}
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>NETT TRADE IN DEPOSIT VALUE</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                           <input type="hidden" id="sub_1_field_1449" name="sub_1_field_1449" value={LoanNettTradeDeposit} onChange={(e) => { setLoanNettTradeDeposit(e.target.value) }} />
                                                                            P {LoanNettTradeDeposit}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>BANK DOCUMENTATION FEE	</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }} >
                                                                            P&nbsp;
                                                                           
                                                                            <NumericFormat type="text" id="sub_1_field_1448" name="sub_1_field_1448"  value={BankFee} onChange={(e) => { setBankFee(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(81);}} onBlur={() => { ShowZero(81); }}/>
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>TRANSACTION FEE</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                           <input type="hidden" id="sub_1_field_1453" name="sub_1_field_1453" value={LoanTransactionFee} onChange={(e) => { setLoanTransactionFee(e.target.value) }} />
                                                                            P {LoanTransactionFee}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>FINANCED AMOUNT</td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                           <input type="hidden" id="sub_1_field_1029" name="sub_1_field_1029" value={LoanFinanceAmount} onChange={(e) => { setLoanFinanceAmount(e.target.value) }}/>
                                                                            P {LoanFinanceAmount}
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>&nbsp;</td>
                                                                        <td>&nbsp;</td>
                                                                    </tr>

                                                                    <tr className="caption text-center" >
                                                                        <td colSpan={4} style={{ textAlign: "center" }}>
                                                                            { PrintButtonData != '' ?
                                                                                 <input type="submit" name="command" value={PrintButton} onClick={(e) => { setPrintButton(e.target.value);setLoanSpecificsDetSave(e.target.value); }} />
                                                                            : null }
                                                                            &nbsp;

                                                                            <input type="submit" name="Save" value="Save" onClick={(e) => { setLoanSpecificsDetSave(e.target.value) }} />&nbsp;
                                                                            <input type="submit" name="Save and Exit" value="Save and Exit" onClick={(e) => { setLoanSpecificsDetSave(e.target.value) }}/>&nbsp;
                                                                            
                                                                            <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                                <input type="button" className="p-relative" value="Cancel" />
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                            </tbody>
                                                        </table>
                                                    </form>    
        {/* ----------------- Spouse Details ----------------------------------  */}
                                                <form name="clientDetails" method="post" onSubmit={handleSpouseDetailsSubmit} > 
                                                    <table className="defaultTbl" cellSpacing={1} align="center" width="800" style={{ display: div_id5 }}>
                                                        <tbody>  
                                                        
                                                                    <tr className="caption">
                                                                        <th colSpan={4} style={{ textAlign: 'center' }}>SPOUSE DETAILS
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                            SPOUSE DETAILS
                                                                        </td>

                                                                        <td style={{ textAlign: "left", width: "25%" }}>
                                                                          
                                                                            <select id="test" name="sub_1_field_952" className="text-uppercase" value={SpouseDetails} onChange = {(e) => { setSpouseDetails(e.target.value) }} >
                                                                            <option value="0">-- Select An Option --</option>
                                                                             {SpouseDetailsAll != null ?
                                                                                SpouseDetailsAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.name}
                                                                                    </option>
                                                                                    );
                                                                                })
                                                                              : null }
                                                                            </select>
                                                                        </td>
                                                                        <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                            TITLE
                                                                        </td>

                                                                        <td style={{ textAlign: "left", width: "25%" }}>
                                                                            <select id="test" name="sub_1_field_954" className="text-uppercase" value={SpouseTitle} onChange = {(e) => { setSpouseTitle(e.target.value) }} >
                                                                                <option value="0">SELECT AN OPTION</option>
                                                                                {TitleAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.title}
                                                                                    </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            FIRST NAME
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_955" value={SpouseFirstName} onChange={(e) => { setSpouseFirstName(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            INITIALS
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1075" value={SpouseInitial} onChange={(e) => { setSpouseInitial(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            SURNAME
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_956" value={SpouseSurname} onChange={(e) => { setSpouseSurname(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            ID NUMBER
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_957" value={SpouseIdNumber} onChange={(e) => { setSpouseIdNumber(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            DATE OF BIRTH
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            {   SpouseBirthdateFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_958" selected={SpouseDOB} name="sub_1_field_958" onChange={(date) => { setSpouseDOB(date); setSpouseBirthdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_958" value={SpouseDOB} name="sub_1_field_958"  onChange={(date) => { setSpouseDOB(date); setSpouseBirthdateFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            }  
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            GENDER
                                                                        </td>
                                                                        <td id="1" style={{ textAlign: "left", width: "25%" }}>
                                                                            <select name="sub_1_field_965" id="gender_select"   className="text-uppercase" value={SpouseGender} onChange = {(e) => setSpouseGender(e.target.value)} >
                                                                                <option value="0">-- Select An Option --</option>
                                                                                {GenderAll.map((item) => {
                                                                                    return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                    {item.gender}
                                                                                    </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            HOME PHONE
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_959" value={SpouseHomePhone} onChange={(e) => { setSpouseHomePhone(e.target.value) }} />
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            WORK PHONE
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_960" value={SpouseWorkPhone} onChange={(e) => { setSpouseWorkPhone(e.target.value) }} />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            MOBILE PHONE
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_961" value={SpouseMobilePhone} onChange={(e) => { setSpouseMobilePhone(e.target.value) }} />
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            E-MAIL
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_962" value={SpouseEmail} onChange={(e) => { setSpouseEmail(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            PHYSICAL ADDRESS LINE 1
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1503" value={SpousePhysicalAdd1} onChange={(e) => { setSpousePhysicalAdd1(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            POSTAL ADDRESS LINE 1
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1506" value={SpousePostalAdd1} onChange={(e) => { setSpousePostalAdd1(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            PHYSICAL ADDRESS LINE 2
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1504" value={SpousePhysicalAdd2} onChange={(e) => { setSpousePhysicalAdd2(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            POSTAL ADDRESS LINE 2
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1507" value={SpousePostalAdd2} onChange={(e) => { setSpousePostalAdd2(e.target.value) }} className="text-uppercase" />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            PHYSICAL ADDRESS CITY
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1514" value={SpousePhysicalAddCity} onChange={(e) => { setSpousePhysicalAddCity(e.target.value) }} className="text-uppercase" />
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            POSTAL ADDRESS CITY
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1515" value={SpousePostalAddCity} onChange={(e) => { setSpousePostalAddCity(e.target.value) }} className="text-uppercase" />
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                           NATIONALITY	
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <select id="test" name="sub_1_field_1738" className="text-uppercase" value={ContactNationality} onChange = {(e) => { setContactNationality(e.target.value) }} >
                                                                                <option value="0">-- Select An Option --</option>
                                                                                {ContactNationalityAll != null ?
                                                                                    ContactNationalityAll.map((item) => {
                                                                                        return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                        {item.name}
                                                                                        </option>
                                                                                        );
                                                                                    })
                                                                                : null }
                                                                            </select>
                                                                        </td>

                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                           &nbsp;
                                                                        </td>
                                                                        <td>&nbsp;</td>
                                                                    </tr>

                                                                    <tr className="caption">
                                                                        <th colSpan={4} style={{ textAlign: 'center' }}>ADDITIONAL SPOUSE DETAILS
                                                                        </th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            RESIDENCY STATUS
                                                                        </td>
                                                                        <td id="1" style={{ textAlign: "left", width: "25%" }}>
                                                                            <select name="sub_1_field_947" id="sub_1_field_947" className="text-uppercase" value={SpouseResidencyStatus} onChange = {(e) => { setSpouseResidencyStatus(e.target.value) }}>
                                                                                <option value="0">-- Select an option --</option>
                                                                                {ResidencyStatusAll.map((item) => {
                                                                                        return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                        {item.res_status}
                                                                                        </option>
                                                                                        );
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            OCCUPATION
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1468" value={SpouseOccupation} onChange={(e) => { setSpouseOccupation(e.target.value) }} className="text-uppercase" />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            EMPLOYER NAME
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1469" value={SpouseEmployerName} onChange={(e) => { setSpouseEmployerName(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            EMPLOYER ADDRESS
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                            <input type="text" name="sub_1_field_1470" value={SpouseEmployerAdd} onChange={(e) => { setSpouseEmployerAdd(e.target.value) }} className="text-uppercase"/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            DATE EMPLOYED
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                           
                                                                            {   SpouseDateEmployeeFlag == 1 ?
                                                                                <DatePicker id="sub_1_field_1471" selected={SpouseDate} name="sub_1_field_1471" onChange={(date) => { setSpouseDate(date); setSpouseDateEmployeeFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            :
                                                                                <DatePicker id="sub_1_field_1471" value={SpouseDate} name="sub_1_field_1471"  onChange={(date) => { setSpouseDate(date); setSpouseDateEmployeeFlag(1) }}  dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "select" autoComplete="off" filterDate={d => {
                                                                                    return new Date() > d; }}/>
                                                                            }  
                                                                        </td>
                                                                        <td className="caption" style={{ width: "25%" }}>
                                                                            SPOUSE NETT INCOME
                                                                        </td>
                                                                        <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                            P&nbsp;
                                                                           
                                                                            <NumericFormat type="text" id="sub_1_field_994" name="sub_1_field_994"  value={SpouseIncome} onChange={(e) => { setSpouseIncome(e.target.value) }} style={{textAlign: 'right', width: '12.4em'}} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(82);}} onBlur={() => { ShowZero(82); }}/>
                                                                        </td>
                                                                    </tr>

                                                                    <tr className="caption text-center" >
                                                                        <td colSpan={4} style={{ textAlign: "center" }}>
                                                                           
                                                                            { PrintButtonData != '' ?
                                                                                 <input type="submit" name="command" value={PrintButton} onClick={(e) => { setPrintButton(e.target.value); setSpouseDetSave(e.target.value); }} />
                                                                            : null }
                                                                            &nbsp;

                                                                            <input type="submit" name="Save" value="Save" onClick={(e) => { setSpouseDetSave(e.target.value) }} />&nbsp;
                                                                            
                                                                            <input type="submit" name="Save and Exit" value="Save and Exit" onClick={(e) => { setSpouseDetSave(e.target.value) }}/>&nbsp;
                                                                            
                                                                            <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                                <input type="button" className="p-relative" value="Cancel" />
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                            </tbody>
                                                        </table>
                                                    </form>    
        {/* ------------- Contact Details ------------  */}
                                                <form name="contact_details" method="post" onSubmit={handleAddContact} >
                                                    <table className="defaultTbl" cellSpacing={1} align="center" width="800" style={{ display: div_id6 }}>
                                                        <tbody>  
                                                            <tr className="caption">
                                                                <th colSpan={4} style={{ textAlign: 'center' }}>CONTACT DETAILS
                                                                </th>
                                                            </tr>

                                                            <tr>
                                                                <td className="caption">
                                                                     CONTACT
                                                                </td>
                                                                <td>
                                                                    <select id="sub_1_field_967" name="sub_1_field_967" className="text-uppercase" value={ContactDetails} onChange = {(e) => { setContactDetails(e.target.value); handleContactDet(e); OnContactWiseChange(e.target.value); }}>
                                                                        <option value="0">-- Select An Option --</option>
                                                                        {ContactDetailsAll != null ?
                                                                        ContactDetailsAll.map((item) => {
                                                                            return (
                                                                            <option key={item.id} value={item.id}>
                                                                            {item.name}
                                                                            </option>
                                                                            );
                                                                        })
                                                                        : null }
                                                                    </select>
                                                                </td>

                                                                <td className="caption">
                                                                    RELATIONSHIP <span style={{ color: "red" }}>*</span>
                                                                </td>
                                                                <td>
                                                                    <select id="sub_1_field_968" name="sub_1_field_968"  className="text-uppercase" value={ContactRelationship} onChange = {(e) => { setContactRelationship(e.target.value) }} style={colorFocus11 != '' ? {borderColor: colorFocus11} : null } >
                                                                        <option value="0">-- Select An Option --</option>
                                                                        {RelationshipAll.map((item) => {
                                                                            return (
                                                                            <option key={item.id} value={item.id}>
                                                                            {item.relation}
                                                                            </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td className="caption">
                                                                    TITLE
                                                                </td>
                                                                <td>
                                                                    <select id="sub_1_field_969" className="text-uppercase" name="sub_1_field_969" value={ContactTitle} onChange = {(e) => { setContactTitle(e.target.value) }} >
                                                                        <option value="0">-- Select An Option --</option>
                                                                        {ContactTitleAll.map((item) => {
                                                                                return (
                                                                                <option key={item.id} value={item.id}>
                                                                                {item.title}
                                                                                </option>
                                                                                );
                                                                        })}
                                                                    </select>
                                                                </td>

                                                                <td className="caption">
                                                                    FIRST NAME <span style={{ color: "red" }}>*</span>
                                                                </td>
                                                                <td>
                                                                    <input type="text" id="firstname" name="sub_1_field_970" className="text-uppercase" value={ContactFirstName} onChange = {(e) => { setContactFirstName(e.target.value) }} style={colorFocus12 != '' ? {borderColor: colorFocus12} : null } />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td className="caption">
                                                                   SURNAME <span style={{ color: "red" }}>*</span>
                                                                </td>
                                                                <td>
                                                                    <input type="text" id="surname" name="sub_1_field_971" className="text-uppercase" value={ContactSurnameName} onChange = {(e) => { setContactSurnameName(e.target.value) }} style={colorFocus13 != '' ? {borderColor: colorFocus13} : null } />
                                                                </td>
                                                                
                                                                <td className="caption">
                                                                    HOME PHONE
                                                                </td>
                                                                <td>
                                                                    <input type="text" id="home_phone" name="sub_1_field_972" style={{ textTransform: "uppercase" }} value={ContactHomePhone} onChange = {(e) => { setContactHomePhone(e.target.value) }} />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td className="caption">
                                                                    WORK PHONE
                                                                </td>
                                                                <td>
                                                                    <input type="text" id="work_phone" name="sub_1_field_973" style={{ textTransform: "uppercase" }} value={ContactWorkPhone} onChange = {(e) => { setContactWorkPhone(e.target.value) }} />
                                                                </td>
                                                          
                                                                <td className="caption">
                                                                    PHYSICAL ADDRESS
                                                                </td>
                                                                <td>
                                                                    <textarea rows="4" id="physical_address" name="sub_1_field_976" style={{ textTransform: "uppercase" }} value={ContactPhysicalAddress} onChange = {(e) => { setContactPhysicalAddress(e.target.value) }} ></textarea>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td className="caption">
                                                                    EMPLOYER NAME
                                                                </td>
                                                                <td>
                                                                    <input type="text" id="employee_name" name="sub_1_field_974" style={{ textTransform: "uppercase" }} value={ContactEmployerName} onChange = {(e) => { setContactEmployerName(e.target.value) }} />
                                                                </td>

                                                                <td className="caption">
                                                                    OCCUPATION
                                                                </td>
                                                                <td>
                                                                    <input type="text" id="occupation" name="sub_1_field_975" style={{ textTransform: "uppercase" }} value={ContactOccupation} onChange = {(e) => { setContactOccupation(e.target.value) }} />
                                                                </td>

                                                            </tr>

                                                            <tr>
                                                                <td className="caption">
                                                                    CONTACT MOBILE PHONE	
                                                                </td>
                                                                <td>
                                                                    <input type="text" id="mobile_no" name="sub_1_field_1748" style={{ textTransform: "uppercase" }} value={ContactMobileNo} onChange = {(e) => { setContactMobileNo(e.target.value) }} />
                                                                </td>

                                                            </tr>
                                                           
                                                            <tr className="caption text-center" >
                                                                <td colSpan={11} style={{ textAlign: "center" }}>

                                                                    { PrintButtonData != '' ?
                                                                        <input type="submit" name="command" value={PrintButton} onClick={(e) => { setPrintButton(e.target.value); setContactDetailsSave(e.target.value); }} />
                                                                    : null }
                                                                    &nbsp;

                                                                    <input type="submit" name="command" value="Save" onClick={(e) => { setContactDetailsSave(e.target.value) }}/>&nbsp;
                                                                
                                                                    <input type="submit" name="command" value="Save and Exit" onClick={(e) => { setContactDetailsSave(e.target.value) }} />&nbsp;
                                                                
                                                                    <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                    <input type="button" className="p-relative" value="Cancel" />
                                                                    </Link>
                                                            
                                                                </td>
                                                            </tr>
                                                 
                                                    </tbody>
                                                </table>
                                              </form>  
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            {/* </form> */}
            <Footer /> 
        </>
    )
}

export default Finance_application_bank_absa