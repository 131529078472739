import { useNavigate } from "react-router-dom"
import React, { useState, useEffect } from "react";
import axios from "../axios";
import Template from "../Components/Template";
import Admin_menu from "../Admin/Admin_menu";

function Admin() {
    const navigate = useNavigate();
    const UserId = localStorage.getItem("UserId");
    const super_user = localStorage.getItem("super_user");
    const [flag,setFlag] = useState(false);
    const [ReadOnly, setReadOnly] = useState(false);
    
    //----------------check permission------------------
    useEffect(() => {
        const payload = {
            UserId : UserId,
            super_user : super_user,
            module_name : "Administration"
        }
        axios
            .post("getRights",payload)
            .then((response) => {
                //console.log(response.data.data)
                
                if (response.data.status === 200) {
                    // alert(response.data.message);
                    if(response.data.data === 0){//None
                        alert(response.data.message);
                        navigate('/home');
                    } else if (response.data.data === 1) {//Read
                        setFlag(true);setReadOnly(true);
                    } else if (response.data.data === 2) {//Write
                        setFlag(true); setReadOnly(false);
                    } else if (response.data.data === 3) {//Modify
                        setFlag(true); setReadOnly(false);
                    } else if (response.data.data === 4) {//Admin
                        setFlag(true);setReadOnly(false);
                    }
                }
            })
            .catch(() => {
                //setLoginLoader(false);
            });
    }, [UserId,super_user,navigate]);

    return (
        
        <>
        {flag ? 
            <>
            <Template />
            <Admin_menu /> 
            <div>
                <table width="100%" height="60" border="0" cellPadding={0} cellSpacing={0} className="custom-icon-menu-shadow ">
                    <tbody>
                        <tr>
                        <td width="100%" className="text-center">PLEASE SELECT AN OPTION FROM THE RIBBON ABOVE
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
            : null }
        </>
        
    );
}

export default Admin