import React, { useState, useEffect } from "react";
import VechileAccessories_menu from "./VechileAccessories_menu"
import { Link } from "react-router-dom"
import axios from "../../axios";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Sub_products() {
    document.title = "Products | Seriti - Vehicle & Accessories";
    const { parent_id } = useParams();
    const [parentProductname, setParentProductName] = useState('')
    const [category, setCategory] = useState('');
    const [allCategory, setAllCategory] = useState([]);
    const [search_text, setSearchText] = useState('');

    const [subProducts, setSubProducts] = useState([]);

    //for add
    const [forAddCategory, setForAddCategory] = useState('');
    const [productName, setProductname] = useState('');
    const [cost_price, setcost_price] = useState('');
    const [selling_price, setselling_price] = useState('');
    const [description, setdescription] = useState('');
    const [command, setCommand] = useState('');
    const [reload, setReload] = useState(true);

    //for enable / disable field
    const [productId, setProductId] = useState('');
    // console.log(category,search_text)
    // console.log(reload);
    //------------------------get initial data and search products-----------------//
    useEffect(() => {
        // alert('reload set')
        const payload = {
            search_text: search_text, //for search
            prod_category: category//for search
        };
        if (reload) {
            axios
                .post("/product/sub_products/" + parent_id, payload)
                .then((response) => {
                    // console.log(response.data.data)
                    setParentProductName(response.data.data.aParentProduct);
                    setAllCategory(response.data.data.sCategorySelect);
                    setSubProducts(response.data.data.rProducts)
                    setReload(false);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    //alert(response.data);
                });
        }
    }, [search_text, category, reload, parent_id]);

    //---------------------handle validation------------------------//
    const handleValidation = () => {
        var flag = true;
        // var regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
        const regexp = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/
        if (forAddCategory === '0' || forAddCategory === '') {
            alert('Please select Product Type.');
            flag = false;
        }
        if (productName === '') {
            alert('Please enter at least one character for Product Name.');
            flag = false;
        } else
            if (cost_price === '' || selling_price === '') {
                alert('Please enter a valid amount.');
                flag = false;
            }
        if (!regexp.test(cost_price)) {
            alert('Please enter a valid amount for Cost Price.');
            setcost_price('')
            flag = false;
        }
        if (!regexp.test(selling_price)) {
            alert('Please enter a valid amount for Selling Price.');
            setselling_price('')
            flag = false;
        }
        return flag;
    };
    //------------------------add new products----------------------------//
    const handleAddProduct = (e) => {
        e.preventDefault();
        const flag = (command === 'Add') ? handleValidation() : true;

        if (flag) {
            const payload = {
                command: command,
                parent_id: parent_id,
                cost_price: cost_price,
                selling_price: selling_price,
                title: productName,
                category_id: forAddCategory,
                description: description,
                productId: productId //for enable,disable 

            };
            axios
                .post("/sub_products/add", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data.data)
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        setReload(true);
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    if (response.response.data.status === 400)
                    toast.error(response.response.data.data, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    }

    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <VechileAccessories_menu />

            <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                <tbody>
                    <tr valign="top" className="bg-white">
                        <td></td>
                        <td width="100%" className="bg-white">
                            <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                            <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">

                                            <table className="defaultTbl" width="500" cellSpacing="1">
                                                <tbody>
                                                    <tr><th colSpan={2} className="text-center">Filter Sub-products of {parentProductname}</th></tr>
                                                    <tr><td className="caption">Product Type</td><td>
                                                        <select name="category" id="category" className="text-uppercase" value={category} onChange={(e) =>
                                                            setCategory(e.target.value)} style={{ width: '382px' }}>
                                                            {/* onChange = {(e) => setBranch(e.target.value)} */}
                                                            <option value="0">-- Select option --</option>
                                                            {allCategory.map((item) => {
                                                                return (
                                                                    <option key={item.id} value={item.id} >
                                                                        {item.name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </td></tr>

                                                    <tr>
                                                        <td className="caption">Search Criteria</td>
                                                        <td>
                                                            <input type="text" name="search_text" className="text-uppercase" value={search_text} onChange={(e) => setSearchText(e.target.value)} style={{ width: '382px' }} />
                                                        </td>
                                                    </tr>
                                                    <tr className="caption">
                                                        <td colSpan={2} style={{ textAlign: "center" }}>
                                                            <input type="submit" name="command" value="Search" onClick={(e) => setReload(true)} />
                                                            <Link to={"/products/search/new_product"}>
                                                                <input type="button" value="Back" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>

                                            <br />
                                            <form method="post" onSubmit={handleAddProduct}>
                                                <table className="defaultTbl" width="600" >
                                                    <tbody>
                                                        <tr><th colSpan={6} className="text-center">Sub-products of {parentProductname}</th></tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "58px" }} ></td>
                                                            <td className="caption" style={{ width: "130px" }}></td>
                                                            <td className="caption" style={{ width: "150px" }}>Product Name</td>
                                                            <td className="caption" style={{ width: "110px" }}>Category</td>
                                                            <td className="caption" style={{ width: "100px" }}>Cost Price</td>
                                                            <td className="caption" style={{ width: "62px" }}></td>
                                                        </tr>

                                                        <tr><td colSpan={6}>
                                                            {
                                                                subProducts && subProducts.length ? (
                                                                    subProducts.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>

                                                                                <td style={{ width: "58px" }}>
                                                                                    <Link to={"/product/modify_product/" + item.id}>
                                                                                        <input type="button" value="Modify" />
                                                                                    </Link>
                                                                                </td>
                                                                                <td style={{ width: "130px" }}>

                                                                                    <Link to={"/product/sub_products/" + item.id}>
                                                                                        <input type="button" name="sub_products" value="View Accessories" />
                                                                                    </Link>
                                                                                </td>

                                                                                <td style={{ width: "150px" }}>{item.name}</td>
                                                                                <td style={{ width: "110px" }}>{item.category}</td>
                                                                                <td style={{ textAlign: "right", width: "100px" }}>P {item.cost_price}</td>
                                                                                <td style={{ width: "62px" }}>
                                                                                    <input type="submit" name="command" value={item.btn} onClick={(e) => { setCommand(e.target.value); setProductId(item.id) }} />
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                ) : <tr><td colSpan={6}>"No products were found"</td></tr>
                                                            }
                                                        </td></tr>

                                                    </tbody>
                                                </table>

                                                <br />

                                                <table className="defaultTbl" width="500" cellSpacing="1">
                                                    <tbody>
                                                        <tr><th colSpan={2} className="text-center">Add Sub-Product</th></tr>
                                                        <tr><td className="caption">Product Type<span style={{ color: "red" }}> *</span></td><td>
                                                            <select name="category" id="category" className="text-uppercase" value={forAddCategory} onChange={(e) =>
                                                                setForAddCategory(e.target.value)} style={{ width: '382px' }}>
                                                                {/* onChange = {(e) => setBranch(e.target.value)} */}
                                                                <option value="0">-- Select option --</option>
                                                                {allCategory.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id} >
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </td></tr>
                                                        <tr>
                                                            <td width="30%" className="caption">Product Name<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="text" name="title" value={productName} onChange={(e) => setProductname(e.target.value)} className="text-uppercase" style={{ width: '382px' }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td width="30%" className="caption">Cost Price<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="text" name="cost_price" value={cost_price} onChange={(e) => setcost_price(e.target.value)} style={{ width: '382px' }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td width="30%" className="caption">Selling Price<span style={{ color: "red" }}> *</span></td>
                                                            <td><input type="text" name="selling_price" value={selling_price} onChange={(e) => setselling_price(e.target.value)} style={{ width: '382px' }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption">Description</td>
                                                            <td><textarea style={{ width: "382px", "height": "100px" }} name="description" className="text-uppercase" value={description} onChange={(e) => setdescription(e.target.value)}></textarea></td>
                                                        </tr>

                                                        <tr className="caption">
                                                            <td colSpan={2} style={{ textAlign: "center" }}>
                                                                <input type="submit" name="command" value="Add" onClick={(e) => setCommand(e.target.value)} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    {/* style={{ width: '382px' }} */}
                                                </table>
                                            </form>
                                        </td>
                                        <td>

                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </td>
                        <td></td>

                    </tr>
                </tbody>
            </table>

        </>
    )
}
export default Sub_products