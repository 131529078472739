
import React, { useState, useEffect } from "react";
import axios from "../axios";

const Footer = () => {

    const [FooterData, setFooterData] = useState("");
    const transaction_id   = localStorage.getItem("transaction_id");
    const system_type      = localStorage.getItem("system_type");
    const user_country_id  = localStorage.getItem("user_country_id");
    const user_id          = localStorage.getItem("UserId");
    
    //----------- get footer html -----------------
    useEffect(() => {
        const payload = {
            transaction_id: transaction_id,
            system_type: system_type,
            user_country_id: user_country_id,
            user_id: user_id,
        };
        //console.log(payload); 
        axios
        .post("dashboard_transcation/dashboard_transaction_footer", payload)
        .then((response) => {
        // console.log(response); 
            setFooterData(response.data); 
        })
        .catch(() => {
            console.log('error'); 
        });
    }, [transaction_id]);
    
    return<>
       
       <div style={{marginTop: '100px'}}>

            <table width="100%" height="14" cellPadding={0} cellSpacing={0} style={{position: 'fixed', bottom: '0px', left: '0px'}}>
                <tbody>
                    <tr>
                        <td width="100%" height="14" className="footer-img">
                             <div dangerouslySetInnerHTML={ {__html:FooterData} } />
                        </td>
                    </tr>
                </tbody>
            </table>

       </div>
    </>
}

export default Footer