import { Link, useNavigate } from "react-router-dom"
import React, { useState, useEffect, useRef } from "react";
import Transaction_menu from "../Transaction/Transaction_menu";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import Footer from "../Dashboard/Footer";
import axios from "../axios";
import { NumericFormat } from 'react-number-format';
import Loader from "../Components/Loader"

const Add_accessories_to_transaction = () => {
   // const url_data = useParams();
   const navigate = useNavigate();

   const transaction_id = localStorage.getItem("transaction_id");
   const parent_id = localStorage.getItem("parent_id");
   const user_id = localStorage.getItem("UserId");
   const super_user = localStorage.getItem("super_user");
   const unincept_flag = localStorage.getItem("unincept_flag");
   const [AccessoiesTranscationDetails, setAccessoiesTranscationDetails] = useState([]);
   // const [RowHideShow, setRowHideShow] = useState("");
   const [CostIncl, setCostIncl] = useState([]);
   const [PriceIncl, setPriceIncl] = useState([]);
   const [CostGroup, setCostGroup] = useState([]);
   const [Description, setDescription] = useState([]);
   const [AssessoriesArray, setAssessoriesArray] = useState([]);
   const [AssessoriesSave, setAssessoriesSave] = useState([]);
   const [CashArray, setCashArray] = useState([]);
   const [AssessoriesNameArray, setAssessoriesNameArray] = useState([]);

   const [loader, setLoader] = useState(false);
   const [flag, setFlag] = useState(false);
   const [ReadOnly, setReadOnly] = useState(false);

   //----------------check permission------------------
   useEffect(() => {
   const payload = {
     UserId: user_id,
     super_user: super_user,
     module_name: "Products"
   }
   axios
     .post("getRights", payload)
     .then((response) => {

       if (response.data.status === 200) {
         if (response.data.data === 0) {//None
           alert(response.data.message); setFlag(false);
           navigate('/transaction/create_transaction/'+ transaction_id); 
         } else if (response.data.data === 1) {//Read
           setFlag(true); setReadOnly(true);
         } else if (response.data.data === 2) {//Write
           setFlag(true); setReadOnly(false);
         } else if (response.data.data === 3) {//Modify
           setFlag(true); setReadOnly(false);
         } else if (response.data.data === 4) {//Admin
           setFlag(true); setReadOnly(false);
         }
       }
     })
     .catch(() => {
       //setLoginLoader(false);
     });
 }, [user_id, super_user, navigate]);

   //----------- featch transcation details -----------------
   useEffect(() => {
      setLoader(true);
      const payload = {
         transaction_id: transaction_id,
         parent_id: parent_id,
      };
      axios
         .post("accessories_to_transaction/fetach_accessories_to_transaction", payload)
         .then((response) => {
           // console.log(response.data.data.main_array);
            if (response.data.status === 200) {
               setAccessoiesTranscationDetails(response.data.data.main_array);
               //setaccessories(response.data.data.disp_accessories)
               setDescription(response.data.data.description_array);
               setCostGroup(response.data.data.cost_group_array);
               setCostIncl(response.data.data.cost_array);
               setPriceIncl(response.data.data.price_array);
               setAssessoriesArray(response.data.data.accessories_array);
               setCashArray(response.data.data.cash_array);
               setAssessoriesNameArray(response.data.data.accessories_name_array);
            }
            setLoader(false);
         })
         .catch(() => {
            console.log('error');
            setLoader(false);
         });

         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
   }, []);

   const handleCostGroupChange = (index, event) => {

      let data = [...CostGroup];
      data[index][event.target.name] = (event.target.value) ? event.target.value : 1;
      setCostGroup(data);
   }

   const handleDescriptionChange = (index, event) => {

      let data = [...Description];
      data[index][event.target.name] = event.target.value;
      setDescription(data);
      //console.log(data);
   }

   const handleCostInclChange = (index, event) => {

      let data = [...CostIncl];
      data[index][event.target.name] = (event.target.value) ? event.target.value.replace(/,(?=.*\.\d+)/g, '') : 0;
      setCostIncl(data);
   }

   const handlePriceInclChange = (index, event) => {

      let data = [...PriceIncl];
      data[index][event.target.name] = (event.target.value) ? event.target.value.replace(/,(?=.*\.\d+)/g, '') : 0;
      setPriceIncl(data);

      let data1 = [...AccessoiesTranscationDetails];
      data1[index]["disp_accessories"] = true;
      setAccessoiesTranscationDetails(data1);

      let AssArray = [...AssessoriesArray];
      AssArray[index][data1[index]["accessories"]] = true;
      setAssessoriesArray(AssArray);

   }

   const handleAccessoiesChange = (index, event) => {

      let AssArray = [...AssessoriesArray];
      AssArray[index][event.target.name] = !AssArray[index][event.target.name];
      setAssessoriesArray(AssArray);

      let accessoiesTranscationDetails = [...AccessoiesTranscationDetails];
      accessoiesTranscationDetails[index]["disp_accessories"] = !accessoiesTranscationDetails[index]["disp_accessories"];

      setAccessoiesTranscationDetails(accessoiesTranscationDetails);
   }

   const handleCashChange = (index, event) => {

      let CashArraydata = [...CashArray];
      CashArraydata[index][event.target.name] = !CashArraydata[index][event.target.name];
      setCashArray(CashArraydata);
   }


   const handleEditAccessories = (e) => {
      setLoader(true);
      e.preventDefault();
      const payload = {
         transaction_id: transaction_id,
         parent_id: parent_id,
         user_id: user_id,
         command: AssessoriesSave,
         cost_group: CostGroup,
         description: Description,
         price: PriceIncl,
         cost: CostIncl,
         cash: CashArray,
         accessories: AssessoriesArray,
         accessories_name: AssessoriesNameArray,

      };
      //console.log(payload); 
      axios
         .post("accessories_to_transaction/edit_accessories_to_transaction", payload)
         .then((response) => {
            setLoader(false);
            //console.log(response); 
            if (response.data.data.command === "Save") {
               toast.success(response.data.message, {
                  position: toast.POSITION.TOP_CENTER
               });
               window.location.reload(false);
            } else if (response.data.data.command === "Save and Continue") {
               navigate('/transaction/products/module=Transactions/category=2/' + parent_id);
            }
         })
         .catch((response) => {
            setLoader(false);
         });
   };

   const RemoveZeroValcomm = (index, event) => {
      // alert('ww');
      //console.log([index][event.target.value]);

      // let data1 = [...AccessoiesTranscationDetails];

      // data1[index]["disp_costs"] = (event.target.value) ? event.target.value : '0.00';
      // console.log(data1);
      if (index == 0) {
         let data = [...AccessoiesTranscationDetails];
         if (data[0].disp_cost === '0.00') {
            data[0]["disp_cost"] = '';
            setAccessoiesTranscationDetails(data);
         }
      }

      if (index == 1) {
         let data1 = [...AccessoiesTranscationDetails];
         if (data1[1].disp_cost === '0.00') {
            data1[1]["disp_cost"] = '';
            setAccessoiesTranscationDetails(data1);
         }
      }

      if (index == 2) {
         let data2 = [...AccessoiesTranscationDetails];
         if (data2[2].disp_cost === '0.00') {
            data2[2]["disp_cost"] = '';
            setAccessoiesTranscationDetails(data2);
         }
      }

      if (index == 3) {
         let data3 = [...AccessoiesTranscationDetails];
         if (data3[3].disp_cost === '0.00') {
            data3[3]["disp_cost"] = '';
            setAccessoiesTranscationDetails(data3);
         }
      }

      if (index == 4) {
         let data4 = [...AccessoiesTranscationDetails];
         if (data4[4].disp_cost === '0.00') {
            data4[4]["disp_cost"] = '';
            setAccessoiesTranscationDetails(data4);
         }
      }

      //console.log(data);
      // let cost_arr = {}
      // for(var i=0; i<data.length; i++){
      //    var costArray = data[i]['disp_cost'].replace(" ", "_");
      //    cost_arr[costArray] = data[i]['cost_array'];
      //    if(data[i]['cost'] == '0.00'){
      //       Costref.current.value = '';
      //    }

      // }
   }

   const ShowZeroValcomm = (index, event) => {

      let data1 = [...AccessoiesTranscationDetails];
      data1[index]["cost_name"] = (event.target.value) ? event.target.value : 0;

      let data = [...AccessoiesTranscationDetails];

      if (data1[0].disp_cost === '') {
         data[index]["disp_cost"] = '0.00';
         setAccessoiesTranscationDetails(data);
      } else if (data1[1].disp_cost === '') {
         data[index]["disp_cost"] = '0.00';
         setAccessoiesTranscationDetails(data);
      } else if (data1[2].disp_cost === '') {
         data[index]["disp_cost"] = '0.00';
         setAccessoiesTranscationDetails(data);
      } else if (data1[3].disp_cost === '') {
         data[index]["disp_cost"] = '0.00';
         setAccessoiesTranscationDetails(data);
      } else if (data1[4].disp_cost === '') {
         data[index]["disp_cost"] = '0.00';
         setAccessoiesTranscationDetails(data);
      }
   }

   const AppendValcomm = (index, event) => {
      let data1 = [...AccessoiesTranscationDetails];
      data1[index]["disp_cost"] = (event.target.value) ? event.target.value : 0;
      setAccessoiesTranscationDetails(data1);
   }

   const RemoveZeroValcomm1 = (index, event) => {

      //    let data1 = [...AccessoiesTranscationDetails];
      //    data1[index]["price_name"] = (event.target.value) ? event.target.value : 0;
      //   // console.log(data1[0].disp_price);
      //    let data = [...AccessoiesTranscationDetails];
      //    //alert(data1[1].disp_price)
      //    if(data1[0].disp_price === '0.00'){
      //       data1[index]["disp_price"] = '';
      //       setAccessoiesTranscationDetails(data);
      //    }else if(data1[1].disp_price === '0.00'){
      //       data1[index]["disp_price"] = '';
      //       setAccessoiesTranscationDetails(data);
      //    }else if(data1[2].disp_price === '0.00'){
      //       data1[index]["disp_price"] = '';
      //       setAccessoiesTranscationDetails(data);
      //    }else if(data1[3].disp_price === '0.00'){
      //       data1[index]["disp_price"] = '';
      //       setAccessoiesTranscationDetails(data);
      //    }else if(data1[4].disp_price === '0.00'){
      //       data1[index]["disp_price"] = '';
      //       setAccessoiesTranscationDetails(data);
      //    }

      if (index == 0) {
         let data = [...AccessoiesTranscationDetails];
         if (data[0].disp_price === '0.00') {
            data[0]["disp_price"] = '';
            setAccessoiesTranscationDetails(data);
         }
      }

      if (index == 1) {
         let data1 = [...AccessoiesTranscationDetails];
         if (data1[1].disp_price === '0.00') {
            data1[1]["disp_price"] = '';
            setAccessoiesTranscationDetails(data1);
         }
      }

      if (index == 2) {
         let data2 = [...AccessoiesTranscationDetails];
         if (data2[2].disp_price === '0.00') {
            data2[2]["disp_price"] = '';
            setAccessoiesTranscationDetails(data2);
         }
      }

      if (index == 3) {
         let data3 = [...AccessoiesTranscationDetails];
         if (data3[3].disp_price === '0.00') {
            data3[3]["disp_price"] = '';
            setAccessoiesTranscationDetails(data3);
         }
      }

      if (index == 4) {
         let data4 = [...AccessoiesTranscationDetails];
         if (data4[4].disp_price === '0.00') {
            data4[4]["disp_price"] = '';
            setAccessoiesTranscationDetails(data4);
         }
      }

   }

   const ShowZeroValcomm1 = (index, event) => {

      let data1 = [...AccessoiesTranscationDetails];
      data1[index]["price_name"] = (event.target.value) ? event.target.value : 0;
      let data = [...AccessoiesTranscationDetails];

      if (data1[0].disp_price === '') {
         data[index]["disp_price"] = '0.00';
         setAccessoiesTranscationDetails(data);
      } else if (data1[1].disp_price === '') {
         data[index]["disp_price"] = '0.00';
         setAccessoiesTranscationDetails(data);
      } else if (data1[2].disp_price === '') {
         data[index]["disp_price"] = '0.00';
         setAccessoiesTranscationDetails(data);
      } else if (data1[3].disp_price === '') {
         data[index]["disp_price"] = '0.00';
         setAccessoiesTranscationDetails(data);
      } else if (data1[4].disp_price === '') {
         data[index]["disp_price"] = '0.00';
         setAccessoiesTranscationDetails(data);
      }
   }

   const AppendValcomm1 = (index, event) => {
      let data1 = [...AccessoiesTranscationDetails];
      data1[index]["disp_price"] = (event.target.value) ? event.target.value : 0;
      setAccessoiesTranscationDetails(data1);
   }
   
   let panFlag = 1;

   return (
      <>
         <ToastContainer theme="colored" style={{ width: "450px", marginLeft: "-200px" }} />
         <Transaction_menu panel_flag={panFlag}/>
         {loader ? <Loader /> : null}
         <div>
            <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing={0} cellPadding={0} className="custom-body">
               <tbody>
                  <tr valign="top">
                     <td></td>
                     <td width="100%">
                        <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                        {/* <form name="command" method="post" > */}
                        {parent_id != 0 ?
                           <table width="100%" border="0" cellSpacing={5} cellPadding={5}>
                              <tbody>
                                 {/* <tr>
                         <td valign="top" align="center">
                            <table cellSpacing={1} className="defaultTbl no_caption_color" width="800">
                              <tbody>
                                 <tr>
                                    <th colSpan={8} style={{textAlign: 'center'}}>M&M ACCESSORIES FOR 100 5E</th>
                                 </tr>
                                 <tr>
                                    <td className="caption">&nbsp;</td>
                                    <td className="caption">Cost Group <br />&nbsp;&nbsp;1&nbsp;&nbsp;&nbsp;2&nbsp;&nbsp;&nbsp;3&nbsp;&nbsp;</td>
                                    <td className="caption">Code</td>
                                    <td className="caption">Accessory Name</td>
                                    <td className="caption">Cash</td>
                                    <td className="caption">Cost (Incl. VAT)</td>
                                    <td className="caption">Price (Incl. VAT)</td>
                                 </tr>
                                 <tr>
                                    <td colSpan={8}>THERE ARE NO ACCESSORIES.</td>
                                 </tr>
                                 <tr>
                                    <td className="caption" colSpan={8}></td>
                                 </tr>
                                 
                              </tbody>
                            </table>
                          </td>
                      </tr> */}

                                 <tr>
                                    <td valign="top" align="center">
                                      <fieldset disabled={ReadOnly}>
                                       <form name="generic_accessories" method="post" onSubmit={handleEditAccessories} >
                                          <table cellSpacing={1} className="defaultTbl no_caption_color" width="800">
                                             <tbody>
                                                <tr>
                                                   <th colSpan={7} style={{ textAlign: 'center' }}>Generic Accessories</th>
                                                </tr>
                                                <tr>
                                                   <td className="caption">&nbsp;</td>
                                                   <td className="caption">Cost Group <br />&nbsp;1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3&nbsp;&nbsp;</td>
                                                   <td className="caption">Accessory Name</td>
                                                   <td className="caption" style={{ textAlign: 'left' }}>Detail</td>
                                                   <td className="caption" style={{ textAlign: 'center' }}>Cash</td>
                                                   <td className="caption" style={{ textAlign: 'right' }}>Cost (Incl. VAT)</td>
                                                   <td className="caption" style={{ textAlign: 'right' }}>Price (Incl. VAT)</td>
                                                </tr>

                                                {AccessoiesTranscationDetails.length > 0 ?
                                                   AccessoiesTranscationDetails.map((item, index) => {
                                                      // console.log()

                                                      return (
                                                         <tr key={index}>
                                                            {unincept_flag == 1 ?
                                                               <td style={{ textAlign: 'left' }}>
                                                                  {item.view_accessories}
                                                               </td>
                                                            : 
                                                               <td style={{ textAlign: 'center' }}>
                                                                  <input type="checkbox" defaultValue={item.disp_accessories} name={item.accessories} checked={item.disp_accessories} onChange={(e) => { handleAccessoiesChange(index, e) }} />
                                                               </td>
                                                            }

                                                            <td>
                                                               {unincept_flag == 1 ?
                                                                  <input type="radio" name={item.cost_group} value="1" onChange={(e) => { { setCostGroup(e.target.value); handleCostGroupChange(index, e) } }} defaultChecked={(item.rdo_cost_group === 1) ? true : null} disabled={true} />
                                                                  :
                                                                  <input type="radio" name={item.cost_group} value="1" onChange={(e) => { { setCostGroup(e.target.value); handleCostGroupChange(index, e) } }} defaultChecked={(item.rdo_cost_group === 1) ? true : null} />
                                                               }
                                                               &nbsp;&nbsp;&nbsp;&nbsp;
                                                               {unincept_flag == 1 ?
                                                                  <input type="radio" name={item.cost_group} value="2" onChange={(e) => { { setCostGroup(e.target.value); handleCostGroupChange(index, e) } }} defaultChecked={(item.rdo_cost_group === 2) ? true : null} disabled={true} />
                                                                  :
                                                                  <input type="radio" name={item.cost_group} value="2" onChange={(e) => { { setCostGroup(e.target.value); handleCostGroupChange(index, e) } }} defaultChecked={(item.rdo_cost_group === 2) ? true : null} />
                                                               }
                                                               &nbsp;&nbsp;&nbsp;&nbsp;
                                                               {unincept_flag == 1 ?
                                                                  <input type="radio" name={item.cost_group} value="3" onChange={(e) => { { setCostGroup(e.target.value); handleCostGroupChange(index, e) } }} defaultChecked={(item.rdo_cost_group === 3) ? true : null} disabled={true} />
                                                                  :
                                                                  <input type="radio" name={item.cost_group} value="3" onChange={(e) => { { setCostGroup(e.target.value); handleCostGroupChange(index, e) } }} defaultChecked={(item.rdo_cost_group === 3) ? true : null} />
                                                               }

                                                            </td>
                                                            <td style={{ textAlign: 'left' }}>
                                                               {item.accessories_val}
                                                               {/* <input type="text" name={item.accessories_name} defaultValue={item.accessories_val} onChange={(e) => {{ handleAccessoriesNameChange(index, e) }}} className="text-uppercase"/> */}
                                                            </td>
                                                            <td>
                                                               {unincept_flag == 1 ?
                                                                  item.disp_description
                                                                  :
                                                                  <input type="text" name={item.description} maxLength={254} style={{ width: '94%' }} defaultValue={item.disp_description} onChange={(e) => { { setDescription(e.target.value); handleDescriptionChange(index, e) } }} className="text-uppercase" />
                                                               }

                                                            </td>

                                                            {unincept_flag == 1 ?
                                                               <td style={{ textAlign: 'center' }}>
                                                                   {item.view_cash}
                                                               </td>
                                                            :
                                                               <td style={{ textAlign: 'center' }}>
                                                                      <input type="checkbox" defaultValue={item[AccessoiesTranscationDetails[index]["cash"]]} name={item.cash} defaultChecked={(item.disp_cash === true) ? true : false} onChange={(e) => { handleCashChange(index, e) }} />
                                                               </td>
                                                             }

                                                            {/* {
                                       item.cash === 'cash_1022' ?
                                    
                                    <td style={{textAlign: 'center'}}>
                                       {  unincept_flag == 1 ? 
                                          item.view_cash
                                       : 
                                          <input type="checkbox" name="cash_1022" id="cash" width="1" value={cash_1022} onChange={(e) => {{ item.disp_cash === true ? setcash_1022(!cash_1022) : setcash_1022(!cash_1022); }}} defaultChecked={(item.disp_cash === true) ? true : false}/> 
                                       }
                                       
                                    </td>
                                    : item.cash === 'cash_898' ?
                                       <td style={{textAlign: 'center'}}>
                                          {  unincept_flag == 1 ? 
                                             item.view_cash
                                          : 
                                             <input type="checkbox" name="cash_898" id="cash" width="1"  value={cash_898} onChange={(e) => {{ item.disp_cash === true ? setcash_898(!cash_898) : setcash_898(!cash_898); }}}  defaultChecked={(item.disp_cash === true) ? true : false} />
                                          }
                                        </td>
                                    : item.cash === 'cash_1023' ?
                                       <td style={{textAlign: 'center'}}>
                                          {  unincept_flag == 1 ? 
                                            item.view_cash
                                          : 
                                             <input type="checkbox" name="cash_1023" id="cash" width="1" value={cash_1023} onChange={(e) => {{ item.disp_cash === true ? setcash_1023(!cash_1023) : setcash_1023(!cash_1023); }}}  defaultChecked={(item.disp_cash === true) ? true : false}/>
                                          }
                                       </td>
                                      
                                    : item.cash === 'cash_1024' ?
                                       <td style={{textAlign: 'center'}}>
                                          {  unincept_flag == 1 ? 
                                            item.view_cash
                                          : 
                                            <input type="checkbox" name="cash_1024" id="cash" width="1" value={cash_1024} onChange={(e) => {{ item.disp_cash === true ? setcash_1024(!cash_1024) : setcash_1024(!cash_1024); }}} defaultChecked={(item.disp_cash === true) ? true : false}/>
                                          }
                                       </td>
                                    : item.cash === 'cash_1025' ?
                                       <td style={{textAlign: 'center'}}>
                                          {  unincept_flag == 1 ? 
                                            item.view_cash
                                          : 
                                             <input type="checkbox" name="cash_1025" id="cash" width="1" value={cash_1025} onChange={(e) => {{ item.disp_cash === true ? setcash_1025(!cash_1025) : setcash_1025(!cash_1025); }}} defaultChecked={(item.disp_cash === true) ? true : false} />
                                          }
                                       </td>
                                    : null

                                    } */}

                                                            <td style={{ textAlign: 'right' }}>
                                                               {unincept_flag == 1 ?
                                                                  item.disp_cost
                                                                  :
                                                                  <NumericFormat type="text" id="cost_incl" name={item.cost} value={item.disp_cost} onChange={(e) => { { setCostIncl(e.target.value); handleCostInclChange(index, e) } }} style={{ textAlign: 'right' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} onClick={(e) => { RemoveZeroValcomm(index, e) }} onBlur={(e) => { ShowZeroValcomm(index, e) }} onKeyUp={(e) => { AppendValcomm(index, e) }} />
                                                               }
                                                               {/* RemoveZeroValcomm onClick={() => {removingZero(1)}} getInputRef={inputRef}*/}
                                                            </td>


                                                            <td style={{ textAlign: 'right' }}>
                                                               {unincept_flag == 1 ?
                                                                  item.disp_price
                                                                  :
                                                                  <NumericFormat type="text" id="price_incl" name={item.price} value={item.disp_price} onChange={(e) => { { setPriceIncl(e.target.value); handlePriceInclChange(index, e) } }} style={{ textAlign: 'right' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} onClick={(e) => { RemoveZeroValcomm1(index, e) }} onBlur={(e) => { ShowZeroValcomm1(index, e) }} onKeyUp={(e) => { AppendValcomm1(index, e) }} />
                                                               }

                                                            </td>
                                                         </tr>

                                                      );
                                                   }) :

                                                   <tr>
                                                      <td colSpan={2}><em>There are no forms to display.</em></td>
                                                   </tr>
                                                }

                                                <tr>

                                                   {unincept_flag == 1 ?
                                                      <td colSpan={8} className="caption" style={{ textAlign: 'center' }}>
                                                         <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                            <input type="button" value="View Transaction" name="view" />&nbsp;
                                                         </Link>
                                                      </td>
                                                      :
                                                      <td className="caption" colSpan={8} style={{ textAlign: 'center' }}>
                                                         <input type="submit" name="command" value="Save" onClick={(e) => { setAssessoriesSave(e.target.value) }} />&nbsp;
                                                         <input type="submit" name="command" value="Save and Continue" onClick={(e) => { setAssessoriesSave(e.target.value) }} />&nbsp;
                                                         <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                            <input type="button" className="p-relative" value="Cancel" />
                                                         </Link>
                                                      </td>
                                                   }
                                                </tr>

                                             </tbody>
                                          </table>
                                       </form>
                                      </fieldset>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                           :
                           <table width="100%" border="0" cellSpacing={5} cellPadding={5}>
                              <tbody>
                                 <tr>
                                    <td valign="top" align="center">
                                       <table cellSpacing={1} className="defaultTbl no_caption_color" width="500">
                                          <tbody>
                                             <tr>
                                                <th style={{ textAlign: 'center' }}>AVAILABLE VEHICLES</th>
                                             </tr>
                                             <tr>
                                                <td style={{ textAlign: 'center' }}>
                                                   <select className="text-uppercase">
                                                      <option value="0">-- Please add a vehicle to the transaction first --</option>
                                                   </select>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td className="caption">IN ORDER TO ADD OEM ACCESSORIES, PLEASE SELECT A VEHICLE.</td>
                                             </tr>

                                          </tbody>
                                       </table>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        }
                        {/* </form> */}
                     </td>
                  </tr>
               </tbody>
            </table>
            <Footer />
         </div>
      </>
   );
}

export default Add_accessories_to_transaction;