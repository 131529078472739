import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Roles() {
    document.title = "Administration | Seriti - Manage Roles";
    const [roles, setRoles] = useState([]);
    const [command, setCommand] = useState([]);
    const UserId = localStorage.getItem("UserId")
    const [flag, setFlag] = useState(true);

    const [inputRole, setInputRole] = useState('');
    //----------------------------------get roles------------------------------------//
    useEffect(() => {
        if (flag) {
            axios
                .get("/admin/manage_roles/roles")
                .then((response) => {
                    if (response.data.status === 200) {
                        setRoles(response.data.data);
                        setFlag(false);
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    // alert(response.response.data.message);
                });
        }
    }, [flag]);

    //---------------------------enable, disable and delete---------------------------------//
    const handleEdit = (e) => {
        // console.log(command)
        e.preventDefault();
        const payload = {
            command: command,
            user_id: UserId
        };
        axios
            .post("/admin/manage_roles/update", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setInputRole('')
                    setCommand('')
                    setFlag(true);
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                if (response.response.data.status === 400) {
                    toast.error(response.response.data.data, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            });
    };

    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Admin_menu />
            <form method="post" action="" onSubmit={handleEdit} encType="multipart/form-data" >
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">

                                <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center"><p></p>
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="600">
                                                    <tbody>
                                                        <tr className="caption">
                                                            <th colSpan={5} style={{ textAlign: 'center' }}>Security Roles</th>
                                                        </tr>

                                                        {
                                                            roles && roles.length ? (
                                                                roles.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <Link to={"/admin/role_edit/" + item.id} className="btn btn-result-open">
                                                                                    <input name="edit_role" type="submit" value="Edit Role" onClick={(e) => { setCommand(['edit_role', item.id]) }} />
                                                                                </Link>
                                                                            </td>

                                                                            <td>{item.role}</td>

                                                                            <td>
                                                                                <input type="submit" name="command" value={item.btn} onClick={(e) => { setCommand([item.btn, item.id]) }} />
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : <tr><td colSpan={2}>No records</td></tr>
                                                        }
                                                        {/* add */}
                                                        <tr className="caption">
                                                            <td></td>
                                                            <td>
                                                                <input type="text" name="inputRole" className="text-uppercase" value={inputRole} onChange={(e) => setInputRole(e.target.value)} /><span style={{ color: "red" }}> *</span>
                                                            </td>
                                                            <td>
                                                                <input type="submit" name="command" value="Add" onClick={(e) => { setCommand(['Add', inputRole]) }} />
                                                            </td>
                                                        </tr>

                                                        {/* add */}

                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr><tr>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );
}

export default Roles