import { createSlice } from "@reduxjs/toolkit";

const initialState = {
};

export const TimeZoneSlice = createSlice({
    name: "timeZoneValue",
    initialState: initialState,
    reducers: {
        timezone: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { timezone } = TimeZoneSlice.actions;

export default TimeZoneSlice.reducer;
