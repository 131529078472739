import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link } from 'react-router-dom';
import { useParams } from "react-router";

function Loanhouses() {
    document.title = "Administration | Seriti - Finance Institution";
    const { loanhouse_id } = useParams()
    const [Code, setCode] = useState('');
    const [name, setName] = useState('');
    const [country, setCountry] = useState('');
    const [PrivateForm, setPrivateForm] = useState('');
    const [BusinessForm, setBusinessForm] = useState('');
    const [logo, setLogo] = useState('');
    const [email1, setEmail1] = useState('');
    const [email2, setEmail2] = useState('');
    const [email3, setEmail3] = useState('');
    const [VehicleCodingSystem, setVechileCodingSystem] = useState('');

// console.log(logo);
    //-------------------------fetch loanhouse data to view--------------------------//
    useEffect(() =>{
        // alert("here");
        const payload = {
            loanhouse_id :loanhouse_id
        };
        axios
                .post("/admin/view_loanhouse",payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data.data[0]);
                        setCode(response.data.data[0].code)
                        setName(response.data.data[0].name)
                        setCountry(response.data.data[0].country_name)
                        setPrivateForm(response.data.data[0].formgroup)
                        setBusinessForm(response.data.data[0].business_formgroup)
                        setLogo(response.data.data[0].logo)
                        setEmail1(response.data.data[0].email)
                        setEmail2(response.data.data[0].email2)
                        setEmail3(response.data.data[0].email3)
                        setVechileCodingSystem(response.data.data[0].vehicle_coding_system)
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    console.log('error');
                    // alert(response.response.data.message);
                });
    }, [loanhouse_id]);

    return (
        <>
            <Admin_menu />
            <form method="post" action="" >
            <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                <tbody>
                    <tr valign="top">
                        <td></td>
                        <td width="100%">
                            <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center"><p></p>
                                            
                                            <table className="defaultTbl" cellSpacing={1} align="center">
                                            <tbody>
                                                <tr>
                                                    <th colSpan="2" className="text-center">VIEW {name}</th>
                                                </tr>
                                                <tr>
                                                    <td className="caption">Company Code</td>
                                                    <td>{Code}</td>
                                                </tr>
                                                <tr>
                                                    <td className="caption">Name</td>
                                                    <td>{name}</td>
                                                </tr>
                                                <tr id="country">
                                                    <td className="caption">Country</td>
                                                    <td>{country}</td>
                                                </tr>
                                                <tr>
                                                    <td className="caption">Private Form</td>
                                                    <td>{PrivateForm}</td>
                                                </tr>
                                                <tr>
                                                    <td className="caption">Business Form</td>
                                                    <td>{BusinessForm}</td>
                                                </tr>

                                                <tr>
                                                    <td className="caption">Logo</td>
                                                    <td><img src={logo} width="130" height="80" alt=""/></td>
                                                </tr>
                                                <tr>
                                                    <td className="caption">Email Address</td>
                                                    <td>{email1}</td>
                                                </tr>
                                                <tr>
                                                    <td className="caption">Email Address 2</td>
                                                    <td>{email2}</td>
                                                </tr>
                                                <tr>
                                                    <td className="caption">Email Address 3</td>
                                                    <td>{email3}</td>
                                                </tr>
                                                <tr>
                                                    <td className="caption">Vehicle Coding System</td>
                                                    <td>{VehicleCodingSystem}</td>
                                                </tr>
                                                <tr className="caption">
                                                    <td colSpan="2" style={{ textAlign: 'center' }}>
                                                    <Link to={"/admin/loanhouses_list"} className="btn btn-result-open">
                                                        <input type="button" name="refer" value="Back"/>
                                                    </Link>

                                                    &nbsp; &nbsp; &nbsp; &nbsp; 
                                                    <Link to={"/admin/loanhouses_edit/" + loanhouse_id} className="btn btn-result-open">
                                                    <input type="submit" name="command" value="Edit"/></Link>
                                                    </td>
                                                </tr>
                                                </tbody></table>
                                            {/* </form> */}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            </form>
        </>
    );
}

export default Loanhouses