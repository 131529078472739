import { Link } from "react-router-dom"
import React from "react";
import Add_user_img from '../Images/ribbon/jason1_add_user.png';
import Search_user_img from '../Images/ribbon/jason1_search_user.png';
import Edit_transction_img from '../Images/ribbon/jason1_edittransaction.png';
import Group_admin_img from '../Images/ribbon/jason1_group_admin.png';
import Add_company_img from '../Images/ribbon/jason1_add_company.png';
import Vehicle_accessories_img from '../Images/ribbon/jason1_vehicle_and_accessories.png';
import Manage_roles from '../Images/ribbon/jason1_manage_roles.png'
// import Manage_workflow_img from '../Images/ribbon/jason1_manage_workflows.png';
import Add_product_img from '../Images/ribbon/jason1_add_product.png';
import Hovers_setup_img from '../Images/ribbon/jason1_setup.png';
import Audit_trail_report_img from '../Images/ribbon/jason1_audit.png';
import Template from "../Components/Template";


function Admin_menu() {
    
    return (
        <>
            <Template />
            <div>
                <table width="100%" border="0" cellPadding={0} cellSpacing={0} className="custom-icon-menu bg-white">
                    <tbody>
                        <tr>
                            <td></td>
                            <td width="100%">
                                <table width="100%" border="0" cellPadding={0} cellSpacing={0} >
                                    <tbody>
                                        <tr>

                                            <td className="text-center" >
                                                <Link className="custom_menu_item" to="/admin/add_user/0" >
                                                    <img height="63" className="titleHover_ADD_USER demo" border="0" src={Add_user_img} alt='' />
                                                    <span className="span_tooltip">ADD USER</span>
                                                </Link>
                                            </td>
                                           
                                            <td className="text-center">
                                                <Link className="custom_menu_item" to="/admin/search/0" >
                                                    <img height="63" className="titleHover_SEARCH_USER" border="0" src={Search_user_img} alt='' />
                                                    <span className="span_tooltip">SEARCH USER</span>
                                                </Link>
                                            </td>

                                            <td className="text-center">
                                                <Link className="custom_menu_item" to="/admin/loanhouses_list" >
                                                    <img height="63" className="titleHover_FINANCE_INSTITUTION" border="0" src={Edit_transction_img} alt='' />
                                                    <span className="span_tooltip">FINANCE INSTITUTION</span>
                                                </Link>
                                            </td>

                                            <td className="text-center">
                                                <Link className="custom_menu_item" to="/admin/group_admin/affiliates" >
                                                    <img height="63" className="titleHover_GROUP_ADMIN" border="0" src={Group_admin_img} alt='' />
                                                    <span className="span_tooltip">GROUP ADMIN</span>
                                                </Link>
                                            </td>

                                            <td className="text-center">
                                                <Link className="custom_menu_item" to="/admin/search_company">
                                                    <img height="63" className="titleHover_ADD_COMPANY" border="0" src={Add_company_img} alt='' />
                                                    <span className="span_tooltip">ADD COMPANY</span>
                                                </Link>
                                            </td>

                                            <td className="text-center">
                                                <Link className="custom_menu_item" to="/products/search/new_product">
                                                    <img height="63" className="titleHover_VEHICLE_AND_ACCESSORIES" border="0" src={Vehicle_accessories_img} alt='' />
                                                    <span className="span_tooltip">VEHICLE AND ACCESSORIES</span>
                                                </Link>
                                            </td>

                                            <td className="text-center">
                                                <Link className="custom_menu_item" to="/admin/roles">
                                                    <img height="63" className="titleHover_MANAGE_ROLES" border="0" src={Manage_roles} alt='' />
                                                    <span className="span_tooltip">MANAGE ROLES</span>
                                                </Link>
                                            </td>

                                            <td className="text-center">
                                                {/* <img height="63" className="titleHover_MANAGE_WORKFLOWS" border="0" src={Manage_workflow_img} alt='' /> */}
                                            </td>

                                            <td className="text-center">
                                                <Link className="custom_menu_item" to="/admin/products">
                                                    <img height="63" className="titleHover_PRODUCT_ADMIN" border="0" src={Add_product_img} alt='' />
                                                    <span className="span_tooltip">PRODUCT ADMIN</span>
                                                </Link>
                                            </td>

                                            <td className="text-center">
                                                <Link className="custom_menu_item" to="/admin/setup">
                                                    <img height="63" className="titleHover_SETUP" border="0" src={Hovers_setup_img} alt='' />
                                                    <span className="span_tooltip">SETUP</span>
                                                </Link>
                                            </td>

                                            <td className="text-center">
                                                <Link  className="custom_menu_item" to="/admin/audit">
                                                    <img height="63" className="titleHover_AUDIT_TRAIL_REPORT" border="0" src={Audit_trail_report_img} alt='' />
                                                    <span className="span_tooltip">AUDIT TRAIL REPORT</span>
                                                </Link>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table width="100%" height="13" border="0" cellPadding={0} cellSpacing={0} className="custom-icon-menu-shadow ">
                    <tbody>
                        <tr>
                            <td width="100%" className="custom-shadow-img"></td>
                        </tr>
                    </tbody>
                </table>


            </div>

        </>
    );
}

export default Admin_menu