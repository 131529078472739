import axios from "axios";

const instance = axios.create({
//  baseURL: "http://127.0.0.1:8000/api/",  //--------- Local ip url
//  baseURL: "https://swtprime.com/seriti/backend/public/api/",  //--------- Prime login url
  // baseURL: "http://api.swttest.in/api/",
  baseURL: "https://api.bwmyseriti.com/api/",  //--------- main live url
  headers: {
    Accept: "application/json",
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("Token") ? JSON.parse(localStorage.getItem("Token")).token : ""}`;
      },
    },
  },
});

export default instance;
