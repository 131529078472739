import React, { useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap'

function ModalDialog(props) {
    const [isShow, invokeModal] = useState(props.modelStatus);
    const [Status, setStatus] = useState(props.status);
    const [Code, setCode] = useState(props.code)

    const [Box, setBox] = useState('');
    const [Wording, setWording] = useState('');
    const [PrintCode, setPrintCode] = useState("");
    // console.log(props);
    //console.log(isShow);

    const initModal = () => {
        invokeModal(false);
        localStorage.setItem("model_status", true);
        window.location.reload(false);
    }

    useEffect(() => {
        var sBox = '';
        if (Status !== '') {
            if (Status === 'Application') { sBox = 1; }
            if (Status === 'Declined') { sBox = 2; }
            if (Status === 'Referred') { sBox = 3; }
            setBox(sBox);
            var dynWording = '';
           
            const code_array = Code.split(',');
                // console.log(code_array);
            var text2 = '';
            for (var i = 0; i < code_array.length; i++) {
                //console.log(code_array[i]);
                if(code_array[i] == 'DA07'){
                    text2 = '<span>DA07 - Your applications has been approved in Principle, subject to verification.</span><br /><br />';
                     dynWording = dynWording.concat(text2);
                }else if(code_array[i] == 'AA01'){
                   // dynWording = dynWording.concat('--------------\n\n'); 
                    text2 = '<span> AA01 - Loan approved subject to the term being revised down to the product maximum and verification being successful.</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DA08'){
                     text2 = '<span> DA08 - Loan approved subject to the term being revised up to the product minimum and verification being successful.</span><br /><br />';
                     dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DA18'){
                     text2 = '<span> DA18 - Loan amount approved subject to the amount being revised down to the product maximum and verification being successful.</span><br /><br />';
                     dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DA20'){
                    text2 = '<span> DA20 - Loan approved subject to the requested Debt Ratio being revised down to the product maximum and verification being successful.</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'RR07'){
                    text2 = '<span> RR07 - Loan approved subject to verification of Employment, Identity and Income and verification being successful.</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'RR09'){
                    text2 = '<span> RR09 - Loan approved subject to verification of bank statements from Agent bank (non Stanbic) and verification being successful.</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DA25'){
                    text2 = '<span> DA25 - Loan approved subject to a revised deposit percentage and verification being successful.</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'RR73'){
                    text2 = '<span> RR73 - Refer - Employment status (Contractor)</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'RR99'){
                    text2 = '<span> RR99 - Refer - Politically Exposed Person (PEP)</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'RR12'){
                    text2 = '<span> RR12 - Refer - Negative SBA account status</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'RR14'){
                    text2 = '<span> RR14 - Refer - Employment Status</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'RR19'){
                    text2 = '<span> RR19 - Refer - Non citizen</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'RR15'){
                    text2 = '<span> RR15 - Refer - Non resident</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'RR26'){
                    text2 = '<span> RR26 - Return Debits (RD) on account</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'RR28'){
                    text2 = '<span> RR28 - Refer - Days in Excess on Cheq Account (negative account status)</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'RR43'){
                    text2 = '<span> RR43 - Refer - The requested interest rate is below the product minimum</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'RR23'){
                    text2 = '<span> RR23 - Refer - Age of the asset exceeds product maximum</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD08'){
                    text2 = '<span> DD08 - Customers income is below the minimum requirment</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD03'){
                    text2 = '<span> DD03 - Customers age is below the minimum requirement (too young)</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD04'){
                    text2 = '<span> DD04 - Customers age exceeds the maximum limit (too old)</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD05'){
                    text2 = '<span> DD05 - Customers age combined with the term exceeds the maximim limit</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD07'){
                    text2 = '<span> DD07 - Customer has negative bureau listing (default data)</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD09'){
                    text2 = '<span> DD09 - Customer has negative bureau listing (default data)</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD12'){
                    text2 = '<span> DD12 - Customer has a negative SBA account status (worst account status)</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD14'){
                    text2 = '<span> DD14 - Customers employment status does not meet employment requirments</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD16'){
                    text2 = '<span> DD16 - Customers employment period is below the requirement minimum</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD17'){
                    text2 = '<span> DD17 - The required loan amount is below the product minimum loan amount</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD20'){
                    text2 = '<span> DD20 - Customers total debt is exceeds the debt ratio limit</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD21'){
                    text2 = '<span> DD21 - Amount offered is too low (below product minimum)</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD25'){
                    text2 = '<span> DD25 - Employment type is Part time</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD26'){
                    text2 = '<span> DD26 - Employment type Unemployed</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD27'){
                    text2 = '<span> DD27 - Employment type is Retired</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD29'){
                    text2 = '<span> DD29 - Employment type is Unknown</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD34'){
                    text2 = '<span> DD34 - Customer has a negative SBA account status (Arrears)</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD36'){
                    text2 = '<span> DD36 - Customer has a negative SBA account status (Arrears)</span><br /><br />';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'DD28'){
                    text2 = '<span> DD28 - ';
                    dynWording = dynWording.concat(text2); 
                }else if(code_array[i] == 'RR35'){
                    text2 = '<span> RR35 - ';
                    dynWording = dynWording.concat(text2); 
                }else{
                    dynWording = ''; 
                }
                setPrintCode(dynWording)
               // setWording(dynWording);
            }

        }
        // return false;
        // }
    }, [Status, Code]);

    return (
        <>
            <Button variant="success" onClick={initModal}>
                Open Modal
            </Button>


            <Modal show={isShow}>
                <Modal.Header closeButton onClick={initModal} style={{ backgroundColor: "#B86512" }}>
                    <p style={{ height: "5px", fontSize: "14px", color: "white" }}><b>Service Message</b></p>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {(Box === 1) ?
                            <>
                                <p><b>Application Approved</b></p>
                                <p>Your applications has been approved in Principle, subject to verification</p>
                            </>
                            : null}

                        {(Box === 2) ?
                            <>
                                <p><b>Declined</b></p>
                                <p>Thank you for applying, we regret to inform your that your application has been declined. Please consult with your Dealer F&I Manager or visit your nearest branch for other options</p>
                            </>
                            : null}

                        {(Box === 3) ?
                            <>
                                <p><b>Referred</b></p>
                                <p>Thank you for applying, your application is currently being evaluated and you will be contacted shortly with a final decision</p>
                            </>
                            : null}

                        <div><p>{Wording}</p></div>
                        <div dangerouslySetInnerHTML={ {__html: PrintCode} } />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={initModal} style={{ backgroundColor: "rgb(223 143 63)" }}>
                      <strong>Close</strong> 
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ModalDialog