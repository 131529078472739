import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../Components/Loader"

function Add_user() {
    document.title = "Administration | Seriti - Add User";
    const { copy } = useParams()
    const super_user = localStorage.getItem("super_user");
    const UserId = localStorage.getItem("UserId");
    const Modules = localStorage.getItem("Modules");
    const Modules_info = localStorage.getItem("Modules_info");
    const Levels = localStorage.getItem("Levels");
    const Access = localStorage.getItem("Access");

    const [NameError, setNameError] = useState("");

    const navigate = useNavigate();
    const pwdRef = useRef();
    const emailRef = useRef();

    const [new_username, setUsername] = useState("");
    const [new_password, setPassword] = useState("");
    const [new_cpassword, setCpassword] = useState("");
    const [firstname, setFirstname] = useState("");
    const [surname, setSurname] = useState("");
    const [id_number, setIdnumber] = useState("");
    const [email, setEmail] = useState("");

    const [unincept, setUnincept] = useState(false);
    const [superuser, setSuperuser] = useState(false);
    const [stip_and_com_lines, setStipcom] = useState(false);
    const [ltic_b1_lines, setLticb1lines] = useState(false);
    const [rol_benefits, setRolbenefits] = useState(false);
    const [non_fais, setNonfais] = useState(false);

    const [under_supervision, setUndersupervision] = useState(false);
    const [block_inceptions, setBlockincept] = useState(false);
    const [block_finance_app, setBlockfinance] = useState(false);
    const [block_insurance_app, setBlockinsurance] = useState(false);
    const [block_non_asset_credit_app, setBlocknonasset] = useState(false);

    const [role, setRole] = useState([]);
    const [singleRole, setSingleRole] = useState('');
    const [bank, setBank] = useState([]);
    const [singleBank, setSingleBank] = useState('');

    const [allCollection, setAllcollection] = useState([]);
    const [collection, setCollection] = useState('');

    const [allGroup, setAllGroup] = useState([]);
    const [group, setGroup] = useState('');

    const [branch, setBranch] = useState('');
    const [allBranch, setAllBranch] = useState([]);
    // console.log(unincept);
    const [flag, setFlag] = useState(false);
    const [ReadOnly, setReadOnly] = useState(false);

    const [loader, setLoader] = useState(false);

    //----------------check permission------------------
    useEffect(() => {
        const payload = {
            UserId: UserId,
            super_user: super_user,
            module_name: "Administration"
        }
        axios
            .post("getRights", payload)
            .then((response) => {
                //console.log(response.data.data)
                // alert(response.data.message);
                if (response.data.status === 200) {
                    if (response.data.data === 0) {//None
                        alert(response.data.message);
                        navigate('/home');
                    } else if (response.data.data === 1) {//Read
                        setFlag(true); setReadOnly(true);
                    } else if (response.data.data === 2) {//Write
                        setFlag(true); setReadOnly(false);
                    } else if (response.data.data === 3) {//Modify
                        setFlag(true); setReadOnly(false);
                    } else if (response.data.data === 4) {//Admin
                        setFlag(true); setReadOnly(false);
                    }
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch(() => {
                //setLoginLoader(false);
            });
    }, [UserId, super_user, navigate]);

    //---------------------collection dropdown------------------------//
    useEffect(() => {
        axios
            .get("admin/get_masters")
            .then((response) => {
                setAllcollection(response.data.data.collection);
            })
            .catch(() => {
            });

        if (copy > 0) {
            axios
                .post("admin/get_copy_user/" + copy)
                .then((response) => {
                    //  console.log(response.data.data[0].unincept)
                    setFirstname(response.data.data[0].firstname);
                    setSurname(response.data.data[0].surname);
                    setIdnumber(response.data.data[0].id_number);
                    setEmail(response.data.data[0].email);
                    setCollection(response.data.data[0].affiliate_id);
                    setGroup(response.data.data[0].division_id);
                    setBranch(response.data.data[0].department_id);
                    setSingleRole(response.data.data[0].user_role);
                    setSingleBank(response.data.data[0].loanhouse_id);

                    setUnincept(response.data.data[0].unincept ? true : false);
                    setSuperuser(response.data.data[0].super_user ? true : false);
                    setStipcom(response.data.data[0].stip_and_com_lines ? true : false);
                    setLticb1lines(response.data.data[0].ltic_b1_lines ? true : false);
                    setRolbenefits(response.data.data[0].rol_benefits ? true : false);
                    setNonfais(response.data.data[0].non_fais ? true : false);
                    setUndersupervision(response.data.data[0].under_supervision ? true : false);
                    setBlockincept(response.data.data[0].block_inceptions ? true : false);
                    setBlockfinance(response.data.data[0].block_finance_app ? true : false);
                    setBlockinsurance(response.data.data[0].block_insurance_app ? true : false);
                    setBlocknonasset(response.data.data[0].block_non_asset_credit_app ? true : false);
                })
                .catch(() => {
                });
        }
    }, [copy]);

    //-------------group dropdown depends on collection----------------//
    useEffect(() => {
        //console.log(collection);
        axios
            .get("admin/get_masters/" + collection)
            .then((response) => {
                //console.log(response.data.data.group)
                setAllGroup(response.data.data.group);
            })
            .catch(() => {
            });
    }, [collection]);

    //---------------branch dropdown depends on group------------------//
    useEffect(() => {
        axios
            .get("admin/get_masters/" + collection + "/" + group)
            .then((response) => {
                // console.log(response.data.data.branch)
                setAllBranch(response.data.data.branch);
            })
            .catch(() => {
            });
    }, [collection, group]);

    //---------------------role dropdown------------------------//
    useEffect(() => {
        axios
            .get("admin/get_masters")
            .then((response) => {
                // console.log(response.data.data.role)
                setRole(response.data.data.role);
            })
            .catch(() => {
            });
    }, []);

    //---------------------bank dropdown------------------------//
    useEffect(() => {
        axios
            .get("admin/get_masters")
            .then((response) => {
                // console.log(response.data.data.bank)
                setBank(response.data.data.bank);
            })
            .catch(() => {
            });
    }, []);
    // console.log(singleRole);
    //---------------------handle validation------------------------//
    const handleValidation = () => {
        var flag = true;
        if (firstname === '' || surname === '' || new_username === '' || new_password === '' || new_cpassword === '' || email === '' || collection === '0' || collection === '' || group === '0' || group === '' || branch === '0' || branch === '' || singleRole === '') {
            alert('Please fill in all the fields!');
            flag = false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            alert('Please enter a valid email address');
            emailRef.current.focus();
            flag = false;
        }
        if (new_password !== new_cpassword) {
            alert('The 2 passwords entered do not match !\nRetype the 2 passwords');
            pwdRef.current.focus();
            flag = false;
        }
        if (new_password !== "") {
            var passwordcheck = /^(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,100}$/;
            if (!passwordcheck.test(new_password)) {
                alert('Please enter correct password! At least seven characters, one capital letter and one special character')
                pwdRef.current.focus();
                flag = false;
            }
        }
        return flag;
    };

    //---------------------add user------------------------//
    const handleSubmit = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        // const flag = true;
        const profile_type = document.getElementById('profile_type').value;
        if (flag) {
            setLoader(true);
            const payload = {
                copy: copy,
                username: new_username,
                password: new_password,
                firstname: firstname,
                surname: surname,
                email: email,
                id_number: id_number,
                unincept: unincept ? 1 : 0,
                super_user: superuser ? 1 : 0,
                stip_and_com_lines: stip_and_com_lines ? 1 : 0,
                ltic_b1_lines: ltic_b1_lines ? 1 : 0,
                rol_benefits: rol_benefits ? 1 : 0,
                non_fais: non_fais ? 1 : 0,
                under_supervision: under_supervision ? 1 : 0,
                block_inceptions: block_inceptions ? 1 : 0,
                block_finance_app: block_finance_app ? 1 : 0,
                block_insurance_app: block_insurance_app ? 1 : 0,
                block_non_asset_credit_app: block_non_asset_credit_app ? 1 : 0,
                user_role: singleRole,
                loanhouse_id: singleBank,
                affiliate_id: collection,
                division_id: group,
                department_id: branch,
                profile_type: profile_type,
                user_id: copy,
                UserId: UserId,
                Modules: Modules,
                Modules_info: Modules_info,
                Levels: Levels,
                Access: Access
            };
            axios
                .post("/admin/add_user", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        setLoader(false);
                        // console.log(response.data.data.user_id);
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        let employee = response.data.data.user_id;
                        navigate('/admin/user_rights/' + employee);
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    setLoader(false);
                    if (response.response.data.status === 400) {
                        toast.error(response.response.data.data, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                });
        }
    };

    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Admin_menu />
            {loader ? <Loader /> : null}
            {flag ? <>
                <div>
                    <table width="100%" height="1024" id="mainContainer" border="0" cellPadding={0} cellSpacing={0} className="custom-body bg-white">
                        <tbody>
                            <tr valign="top">
                                <td></td>
                                <td width="100%">
                                    <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                                    <table width="100%" border="0" cellPadding={5} cellSpacing={5}>
                                        <tbody>
                                            <tr>
                                                <td valign="top" align="center">
                                                    <fieldset disabled={ReadOnly}>
                                                        <form method="post" name="new_user" id="new_user" onSubmit={handleSubmit} >
                                                            <table width="500" className="defaultTbl tablesorter" align="center" cellSpacing={1}>
                                                                <tbody>
                                                                    <tr>
                                                                        <th colSpan={2} style={{ textAlign: 'center' }}>Add a User</th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" width="100">Username<span style={{ color: "red" }}> *</span></td>
                                                                        <td><input type="text" name="new_username" id="new_username" autoComplete="off" className="sectext text-uppercase" value={new_username} onChange={(e) => { setUsername(e.target.value) }} /></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" width="100">Password<span style={{ color: "red" }}> *</span></td>
                                                                        <td><input type="password" name="new_password" id="newpassword" autoComplete="off" value={new_password} onChange={(e) => { setPassword(e.target.value) }} ref={pwdRef} /></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" nowrap="nowrap" width="100">Confirm Password<span style={{ color: "red" }}> *</span></td>
                                                                        <td><input type="password" name="new_cpassword" id="confirmpassword" autoComplete="off" value={new_cpassword} onChange={(e) => { setCpassword(e.target.value) }} /></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">First Name<span style={{ color: "red" }}> *</span></td>
                                                                        <td><input type="text" name="firstname" value={firstname} className="text-uppercase" onChange={(e) => { setFirstname(e.target.value) }} /></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Last Name<span style={{ color: "red" }}> *</span></td>
                                                                        <td><input type="text" name="surname" value={surname} className="text-uppercase" onChange={(e) => { setSurname(e.target.value) }} /></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">ID Number</td>
                                                                        <td><input type="text" name="id_number" value={id_number} className="text-uppercase" onChange={(e) => { setIdnumber(e.target.value) }} /></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Email<span style={{ color: "red" }}> *</span></td>
                                                                        <td><input type="text" name="email" id="email" value={email} className="text-uppercase" onChange={(e) => { setEmail(e.target.value) }} ref={emailRef} /><input type="hidden" name="profile_type" id="profile_type" value="1" /></td>
                                                                    </tr>

                                                                    <tr id="aff_row">
                                                                        <td className="caption">Collection<span style={{ color: "red" }}> *</span></td>
                                                                        <td id="aff_container">
                                                                            <select name="affiliate" id="affiliate" className="text-uppercase" value={collection} onChange={(e) => setCollection(e.target.value)} style={{ width: '239px' }} >
                                                                                <option value="0">- - Select a Collection - -</option>
                                                                                {allCollection.map((item) => {
                                                                                    return (
                                                                                        <option key={item.id} value={item.id} >
                                                                                            {item.name}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select></td>
                                                                    </tr>

                                                                    <tr id="div_row">
                                                                        <td className="caption">Group<span style={{ color: "red" }}> *</span></td>
                                                                        <td id="div_container">
                                                                            <select name="group" value={group} onChange={(e) => setGroup(e.target.value)} className="text-uppercase" style={{ width: '239px' }}>
                                                                                <option value="0">-- Select a Collection First -</option>
                                                                                {allGroup.map((item) => {
                                                                                    return (
                                                                                        <option key={item.id} value={item.id} >
                                                                                            {item.name}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                    </tr>

                                                                    <tr id="div_row">
                                                                        <td className="caption">Branch<span style={{ color: "red" }}> *</span></td>
                                                                        <td id="dept_container">
                                                                            <select name="branch" className="text-uppercase" value={branch} onChange={(e) => setBranch(e.target.value)} style={{ width: '239px' }}>
                                                                                <option value="0">-- Select a Group First -</option>
                                                                                {allBranch.map((item) => {
                                                                                    return (
                                                                                        <option key={item.id} value={item.id} >
                                                                                            {item.name}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">User Role<span style={{ color: "red" }}> *</span></td>
                                                                        <td>
                                                                            <select name="role" id="role" className="text-uppercase" value={singleRole} onChange={(e) => setSingleRole(e.target.value)} style={{ width: '239px' }} >
                                                                                <option value="">-- Select Option --</option>
                                                                                {role.map((item) => {
                                                                                    return (
                                                                                        <option key={item.id} value={item.id} >
                                                                                            {item.role}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Loanhouse</td>
                                                                        <td>
                                                                            <select name="loanhouse" className="text-uppercase" value={singleBank} onChange={(e) => setSingleBank(e.target.value)} style={{ width: '239px' }}>
                                                                                <option value="">--Select loanhouse--</option>
                                                                                {bank.map((item) => {
                                                                                    return (
                                                                                        <option key={item.id} value={item.id} >
                                                                                            {item.name}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Can un-incept Transactions</td>
                                                                        <td><input className="unincept" type="checkbox" value={unincept} onChange={(e) => { setUnincept(!unincept) }} checked={unincept} /></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Super User</td>
                                                                        <td><input name="superuser" type="checkbox" value={superuser} onChange={(e) => { setSuperuser(!superuser) }} checked={superuser} /></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Short-Term Insurance<br />Personal and Commercial Lines?</td>
                                                                        <td><input type="checkbox" name="stip_and_com_lines" value={stip_and_com_lines} onChange={(e) => { setStipcom(!stip_and_com_lines) }} checked={stip_and_com_lines} /></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Long-Term Insurance<br />Category B1 Lines?</td>
                                                                        <td><input type="checkbox" name="ltic_b1_lines" value={ltic_b1_lines} onChange={(e) => { setLticb1lines(!ltic_b1_lines) }} checked={ltic_b1_lines} /></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Risk-Only Life Benefits?</td>
                                                                        <td><input type="checkbox" name="rol_benefits" value={rol_benefits} onChange={(e) => { setRolbenefits(!rol_benefits) }} checked={rol_benefits} /></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Non-FAIS User?</td>
                                                                        <td><input type="checkbox" name="non_fais" value={non_fais} onChange={(e) => { setNonfais(!non_fais) }} checked={non_fais} /></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Working Under Supervision?</td>
                                                                        <td><input type="checkbox" name="under_supervision" value={under_supervision} onChange={(e) => { setUndersupervision(!under_supervision) }} checked={under_supervision} /></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Block Inceptions?</td>
                                                                        <td><input type="checkbox" name="block_inceptions" value={block_inceptions} onChange={(e) => { setBlockincept(!block_inceptions) }} checked={block_inceptions} /></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Block Finance Applications?</td>
                                                                        <td><input type="checkbox" name="block_finance_app" value={block_finance_app} onChange={(e) => { setBlockfinance(!block_finance_app) }} checked={block_finance_app} /></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Block Insurance Applications?</td>
                                                                        <td><input type="checkbox" name="block_insurance_app" value={block_insurance_app} onChange={(e) => { setBlockinsurance(!block_insurance_app) }} checked={block_insurance_app} /></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Block Non-Asset Credit Applications?</td>
                                                                        <td><input type="checkbox" name="block_non_asset_credit_app" value={block_non_asset_credit_app} onChange={(e) => { setBlocknonasset(!block_non_asset_credit_app) }} checked={block_non_asset_credit_app} /></td>
                                                                    </tr>

                                                                    <tr className="caption">
                                                                        <td colSpan="2" className="text-center">
                                                                            <input type="submit" value="Save" name="submit" />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ textAlign: 'center' }}>
                                                                            <span style={{ fontSize: '10px', color: '#F23D18', textAlign: 'center', width: '100%' }} className="text-danger">{NameError}</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </form>
                                                    </fieldset>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody></table>
                </div>
            </> : null}
        </>
    );
}

export default Add_user