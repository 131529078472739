import { Link, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from "react";
//import { useLocation } from "react-router";
import Transaction_menu from "../Transaction/Transaction_menu";
import Footer from "../Dashboard/Footer";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import { NumericFormat } from 'react-number-format';
import axios from "../axios";
import Loader from "../Components/Loader"

const Products = () => {
  // const url_data = useParams();
  const transaction_id = localStorage.getItem("transaction_id");
  const parent_id = localStorage.getItem("parent_id");
  const user_id = localStorage.getItem("UserId");
  const system_type = localStorage.getItem("system_type");

  const [NonProductDetails, setNonProductDetails] = useState([]);
  //const [EditProductDetails, setEditProductDetails] = useState([]);
  const [HasPayoverAmount, setHasPayoverAmount] = useState(0);
  const [PayoverAmount, setPayoverAmount] = useState("");
  const [ProductsSave, setProductsSave] = useState("");

  const [NotOfferedDeclineAccept, setNotOfferedDeclineAccept] = useState([]);

  const [CostGroup, setCostGroup] = useState([]);
  const [RetailPrice, setRetailPrice] = useState([]);
  const [Cash, setCash] = useState([]);
  const [FinalCash, setFinalCash] = useState([]);

  const [AcceptProductDetails, setAcceptProductDetails] = useState([]);
  const [DeclainProductDetails, setDeclainProductDetails] = useState([]);
  const [AcceptanceProductDetails, setAcceptanceProductDetails] = useState([]);
  const [CostGroupProductDetails, setCostGroupProductDetails] = useState([]);
  const [RetailPriceProductDetails, setRetailPriceProductDetails] = useState([]);
  const [CashProductDetails, setCashProductDetails] = useState([]);

  const [Flag1, setFlag1] = useState(0);
  const [Flag2, setFlag2] = useState(0);
  const [Flag3, setFlag3] = useState(0);
  const [Flag4, setFlag4] = useState(0);

  const navigate = useNavigate();
  const UserId = localStorage.getItem("UserId");
  const super_user = localStorage.getItem("super_user");
  const [flag, setFlag] = useState(false);
  const [ReadOnly, setReadOnly] = useState(false);

  const [Reload, setReload] = useState(true);

  const [FinalArrayOfAccepted, setFinalArrayOfAccepted] = useState([]);
  const [loader, setLoader] = useState(false);
  const [HeaderFlag, setHeaderFlag] = useState(1);
  // const [Flag204, setFlag204] = useState(false);

  //console.log(FinalCostGroup); 
  useEffect(() => {
    document.title = 'Transactions | Seriti BW';

  }, []);

  //----------------check permission------------------
  useEffect(() => {
    const payload = {
      UserId: UserId,
      super_user: super_user,
      module_name: "Products"
    }
    axios
      .post("getRights", payload)
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.data === 0) {//None
            alert(response.data.message); setFlag(false);
            navigate('/transaction/create_transaction/'+ transaction_id); 
          } else if (response.data.data === 1) {//Read
            setFlag(true); setReadOnly(true);
          } else if (response.data.data === 2) {//Write
            setFlag(true); setReadOnly(false);
          } else if (response.data.data === 3) {//Modify
            setFlag(true); setReadOnly(false);
          } else if (response.data.data === 4) {//Admin
            setFlag(true); setReadOnly(false);
          }
        }
      })
      .catch(() => {
        //setLoginLoader(false);
      });
  }, [UserId, super_user, navigate]);

  //------------------- get data -----------------
  useEffect(() => {
    if (flag && Reload) {
     // setLoader(true);
      const payload = {
        transaction_id: transaction_id,
        user_id: user_id,
        parent_id: parent_id,
        category: 2,
        system_type: system_type,
        id: 0,
      };
      axios
        .post("getProductDetails", payload)
        .then((response) => {
         // setReload(false)
          // console.log(response.data.data.final_result);
          setNonProductDetails(response.data.data.final_result);
          setAcceptProductDetails(response.data.data.accept_product_result);
          setCostGroupProductDetails(response.data.data.cost_group_product_result);
          setRetailPriceProductDetails(response.data.data.retail_price_product_result);
          setCashProductDetails(response.data.data.cash_product_result);
          setDeclainProductDetails(response.data.data.accept_product_result);
          setAcceptanceProductDetails(response.data.data.accept_product_result);
          setFinalArrayOfAccepted(response.data.data.FinalArrayOfAccepted);//ACCEPT
          setRetailPrice(response.data.data.retail_price_product_result);
          setFinalCash(response.data.data.cash_product_result);
          setCostGroup(response.data.data.cost_group_product_result);
          // console.log(response.data.data.final_result.length);
          setPayoverAmount(response.data.data.final_result.length > 0 ? response.data.data.final_result[0].payover_amount : "");
          setReload(false)

          // var result = response.data.data.final_result;
          // var array = [];
          // for (var j = 0; j < result.length; j++) {
          //    // console.log(result[j]);
          //   var obj = result[j];
          //     if(result[j]['prod_id'] == 204){
          //       obj['disable'] = false;
          //     }else{
          //      obj['disable'] = true;
          //     }
          //    array.push(obj);
          // }
         // console.log(array);
         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        })
        .catch(() => {
         // setLoader(false);
        });
    }
  }, [flag, transaction_id, user_id, parent_id, system_type, Reload]);

  const addPayoverValue = (msg, payover_amount) => {
    const fPayover = prompt(msg, payover_amount);
    if (fPayover != null) {
      setHasPayoverAmount(1);
      setPayoverAmount(fPayover);
    }
  }

  // const handleIsPrintedValidation = () => {
  // var flag = true;
  // const payload = {
  //   transaction_id: transaction_id,
  //   array_of_accepted: FinalArrayOfAccepted,
  // }
  // axios
  // .post("transaction_product/checkValidations", payload)
  // .then((response) => {
  //   console.log(response); 

  // })
  // .catch((response) => {
  //   console.log('error');
  // });
  //   return flag;
  // }


  //---------------- add/edit product data ------------------------
  const handleAddEditProducts = (e) => {
    e.preventDefault();
    setLoader(true);
    const payload = {
      transaction_id: transaction_id,
      user_id: user_id,
      parent_id: parent_id,
      category: 2,
      system_type: system_type,
      command: ProductsSave,
      new_accept_data: FinalArrayOfAccepted,
      price_data: RetailPrice,
      cost_group_data: CostGroup,
      cash_data: FinalCash,
      has_payover_amount: HasPayoverAmount,
      payover_amount: PayoverAmount,
    };
    // console.log(payload); 
    axios
      .post("/addEditProducts", payload)
      .then((response) => {
        setLoader(false);
        // console.log(response);
        // window.location.reload(false);
        setReload(true);
        if (response.data.data.command === "Save") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER
          });
          setHeaderFlag(2)
        } else if (response.data.data.command === "Save and Continue") {
          navigate('/transaction/insurance_products/module=Transactions/category=1/' + parent_id);
        }
      })
      .catch((response) => {
        setLoader(false);
        console.log('error');
      });
  };

  const checkMinMax = (event, min, max) => {
    const price_amt = event.target.value;
    if (min >= price_amt) {
      alert('Price entered is below minimum amount of ' + min);
    }
    if ((max <= price_amt) && (max > 0)) {
      alert('Price entered is above maximum amount of ' + max);
    }
  }

  //---------------------- creating array ---------------------
  const handleNotOfferedAndDeclined = (index, event, un_index) => {
    let data = [...FinalArrayOfAccepted];
    data[un_index][event.target.name] = event.target.value;
    setFinalArrayOfAccepted(data);
    if (Flag2 === 0) setCostGroup(CostGroupProductDetails)
    if (Flag4 === 0) setFinalCash(CashProductDetails);
    setFlag1(1);
  }

  const handleAccepted = (index, event, accept_name, IsWordingDisplay, WordingDisplayName) => {
    let NotOfferedDeclineAccept2 = [...FinalArrayOfAccepted];
    for (let i = 0; i < FinalArrayOfAccepted.length; i++) {
      const valueOfKey = Object.keys(NotOfferedDeclineAccept2[i]);
      if (valueOfKey[0] === accept_name) {
        NotOfferedDeclineAccept2[i][valueOfKey[0]] = event.target.value;
      }
    }
    setFinalArrayOfAccepted(NotOfferedDeclineAccept2);
    if (Flag2 === 0) setCostGroup(CostGroupProductDetails)
    if (Flag3 === 0) setRetailPrice(RetailPriceProductDetails);
    if (Flag4 === 0) setFinalCash(CashProductDetails);
    setFlag1(1);
  }

  const handleCostGroupChange = (index, event) => {
    let CostGroupArray = [...CostGroupProductDetails];
    const cost_group_radio_name = event.target.name;
    CostGroupArray[index][cost_group_radio_name] = event.target.value;
    setCostGroup(CostGroupArray);
    if (Flag1 === 0) setNotOfferedDeclineAccept(index, event);
    if (Flag4 === 0) setFinalCash(CashProductDetails);
    setFlag2(1);
  }

  const handleRetailPriceChange = (index, event) => {
    let RetailPriceArray = [...RetailPriceProductDetails];
    const cost_retail_price_name = event.target.name;
    RetailPriceArray[index][cost_retail_price_name] = event.target.value;
    // console.log(RetailPriceArray);
    setRetailPrice(RetailPriceArray);
    if (Flag4 === 0) setFinalCash(CashProductDetails);
    if (Flag2 === 0) setCostGroup(CostGroupProductDetails)
    setFlag3(1);
  }

  const handleCashChange = (index, event) => {
    let CashArray = [...CashProductDetails];
    CashArray[index][event.target.name] = !CashArray[index][event.target.name];
    setFinalCash(CashArray);
    if (Flag1 === 0) setNotOfferedDeclineAccept(index, event);
    if (Flag2 === 0) setCostGroup(CostGroupProductDetails)
    if (Flag3 === 0) setRetailPrice(RetailPriceProductDetails);
    setFlag4(1);
  }

  // console.log(NonProductDetails);
  const RemoveZeroValcomm = (index, event) => {
    let data1 = [...NonProductDetails];
    data1[index]["retail_price"] = (event.target.value) ? event.target.value : 0;
    let data = [...NonProductDetails];
    if (data1[0].retail_price_value === '0.00') {
      data1[index]["retail_price_value"] = '';
      setNonProductDetails(data);
    }
  }

  const ShowZeroValcomm = (index, event) => {
    // let data1 = [...NonProductDetails];
    // data1[index]["retail_price"] = (event.target.value) ? event.target.value : 0;
    // if (data1[0].retail_price_value === '') {
    //   data1[index]["retail_price_value"] = '0.00';
    //   setNonProductDetails(data1);
    // }
  }

  // const TrackingUnitDetails = (index,display_id,prod_id) => {
  //   //console.log(prod_id);
  //   handleChildProducts(index,204,prod_id);
  // }


  const [NewArray, setNewArray] = useState([]);
  //------------------handle button click of category-----------------------------------//
  const handleChildProducts = (index, display_id) => {
    // alert(display_id)
    //console.log(prod_id);
    let DispArr = [...NewArray];
    let DispIdArr = [];

    //DELIVERY FEE
    if (display_id === 60) DispIdArr = [60];

    //SAFETY FILM
    if (display_id === 191) DispIdArr = [191];

    //PAINT PROTECTOR
    if (display_id === 146) DispIdArr = [146];

    //TRACKING DEVICES
    if (display_id === 143 || display_id === 147 || display_id === 154 || display_id === 155 || display_id === 204) DispIdArr = [143, 147, 154, 155, 204];
    
    // if(prod_id === 10273){
    //   if (display_id === 204) DispIdArr = [204];
    // }else if(prod_id === 1 || prod_id === 2){
    //   if (display_id === 204) DispIdArr = [1001];
    // }

    //CAR CARE VOUCHER
    if (display_id === 156) DispIdArr = [156];

    //SECURE COMBO
    if (display_id === 183 || display_id === 205) DispIdArr = [183,205];

    //VEHICLE SHIELD PROTECTION
    if (display_id === 151 || display_id === 152 || display_id === 153) DispIdArr = [151, 152, 153];

    //HEAT SHIELD
    if (display_id === 167) DispIdArr = [167];

    //DIAMON-FUSION
    if (display_id === 185) DispIdArr = [185];

    //LICENCE AND REGISTRATION
    if (display_id === 165) DispIdArr = [165];

    //GROUND ZERO
    if (display_id === 157) DispIdArr = [157];

    for (let i = 0; i < DispIdArr.length; i++) {
      (DispIdArr[i] in NewArray && NewArray[DispIdArr[i]] !== undefined && NewArray[DispIdArr[i]] !== "" || NewArray[DispIdArr[i]] == "1001") ? (DispArr[DispIdArr[i]] = "") : (DispArr[DispIdArr[i]] = DispIdArr[i]);
    }
    (display_id in NewArray && NewArray[display_id] !== "") ? (NonProductDetails[index]["sign"] = "+") : (NonProductDetails[index]["sign"] = "-");

    setNewArray(DispArr);
  }

  //on contract click
  const SaveIsPrinted = (display_id, is_printed) => {
    if (display_id === 143 || display_id === 147 || display_id === 154 || display_id === 155 || display_id === 164 || display_id === 183) {
      const payload = {
        transaction_id: transaction_id,
        product_id: display_id,
        is_printed: is_printed
      };
      axios
        .post("transaction_product/saveIsPrinted", payload)
        .then((response) => {
          if (response.data.status === 200) {
            //nothing to return
          }
        })
        .catch((response) => {
          console.log('error');
        });
    }
  }

  //on accepted click
  const ChkIsPrinted = (display_id) => {
    if (display_id === 143 || display_id === 147 || display_id === 154 || display_id === 155 || display_id === 164 || display_id === 183) {
      const payload = {
        transaction_id: transaction_id,
        product_id: display_id,
      };
      axios
        .post("transaction_product/chkIsPrinted", payload)
        .then((response) => {
          if (response.data.status === 200) {
            if (parseInt(response.data.data) === 0) {
              alert("CONTRACT must be printed by clicking on it!");
            }
          }
        })
        .catch((response) => {
          console.log('error');
        });
    }
  }

  //let panFlag = 1;

  return (
    <>
      <ToastContainer theme="colored" style={{ width: "450px", marginLeft: "-200px" }} />
      <Transaction_menu panel_flag={HeaderFlag}/>
      {loader ? <Loader /> : null}
      {flag ? <>
        <div>
          <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing={0} cellPadding={0} className="custom-body">
            <tbody>
              <tr valign="top">
                <td></td>
                <td width="100%">
                  <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                  {
                    parent_id != 0 ?
                      <form name="non_product" method="post" onSubmit={handleAddEditProducts} >
                        <fieldset disabled={ReadOnly}>
                          <table width="100%" border="0" cellSpacing={5} cellPadding={5}>
                            <tbody>
                              <tr>
                                <td valign="top" align="center">
                                  <table cellSpacing={1} className="defaultTbl no_caption_color" width="1000">
                                    <tbody>
                                      <tr>
                                        <th colSpan={9} style={{ textAlign: 'center' }}>NON-INSURANCE PRODUCTS</th>
                                      </tr>
                                      <tr>
                                        <td className="caption" width={150}>Item</td>
                                        <td className="caption" width={175} style={{ textAlign: 'center' }}>Not Offered</td>
                                        <td className="caption">Declined</td>
                                        <td className="caption">Accepted</td>
                                        <td className="caption" width={125}>Cost Group <br />&nbsp;&nbsp;1&nbsp;&nbsp;&nbsp;2&nbsp;&nbsp;&nbsp;3&nbsp;&nbsp;</td>
                                        <td className="caption">Cash</td>
                                        <td className="caption">WORDING</td>
                                        <td className="caption" width={100}>Retail price</td>
                                        <td className="caption" width={175}>Installment</td>
                                      </tr>

                                      {/* {console.log(NonProductDetails)//not_offered_radio_delined_name} */}
                                      {
                                        NonProductDetails.length > 0 ?
                                          NonProductDetails.map((item, index) => {
                                            //var test = index+100; 
                                            // console.log(item.not_offered_radio_delined_name1+" : Not Offered : "+item.offered_checked_status+"--Delined : "+item.delined_checked_status+"--Assepted : "+item.accept_check_box_radio_status)
                                            return [

                                              <tr key={item.even} style={{ display: (item.display_category == true) ? "" : "none" }}>

                                                <td className="caption">
                                                  {(item.display_button_value !== "") ?
                                                    <input type="button" name={item.display_button_id} id={item.display_button_id} value={(item.display_button_value + " " + item.sign)} style={{ display: item.display_button_style }} onClick={(e) => handleChildProducts(index, item.display_button_id)} />
                                                    : null}
                                                </td>

                                                <td className="caption" style={{ textAlign: 'center' }}>
                                                  <input type="radio" value="1" name={item.not_offered_radio_delined_name1} defaultChecked={(item.offered_checked_status === 'checked')} onChange={(e) => { setNotOfferedDeclineAccept(e.target.value) }} onClick={(e) => { SaveIsPrinted(item.display_button_id, 0); handleNotOfferedAndDeclined(index, e, item.unique_index); }} />
                                                </td> 

                                                <td className="caption" style={{ textAlign: 'center' }}>
                                                  <input type="radio" value="2" name={item.not_offered_radio_delined_name1} defaultChecked={item.delined_checked_status === 'checked'} onChange={(e) => { setNotOfferedDeclineAccept(e.target.value) }} onClick={(e) => { SaveIsPrinted(item.display_button_id, 0); handleNotOfferedAndDeclined(index, e, item.unique_index); }} />
                                                </td>

                                                <td className="caption" style={{ textAlign: 'left' }} colSpan={6}>
                                                  {item.display_accepted}
                                                </td>
                                              </tr>,
                                              //  second tr -------------------------------------------
                                              (NewArray.length > 0 ?
                                                (item.display_button_id in NewArray) ?
                                                  (NewArray[item.display_button_id] !== undefined && NewArray[item.display_button_id] !== "") ?
                                                    <>
                                                      <tr key={item.odd}>
                                                        <td colSpan={3}>{item.td_display_name}</td>

                                                        {/* <td></td> */}
                                                        {item.accept_check_box_radio === 'radio' ?
                                                          <td style={{ textAlign: 'center' }}>
                                                            <input type="radio" value={item.accept_check_box_radio_value} name={item.not_offered_radio_delined_name} id={item.not_offered_radio_delined_name} defaultChecked={(item.accept_check_box_radio_status === 'checked') ? true : null} onChange={(e) => { setNotOfferedDeclineAccept(e.target.value); handleAccepted(index, e, item.not_offered_radio_delined_name, item.wording_display); ChkIsPrinted(item.display_button_id); }} />
                                                          </td> 
                                                          :
                                                          <td style={{ textAlign: 'center' }}>
                                                            <input type="checkbox" name={item.accept_check_box_radio_name} id={item.accept_check_box_radio_name} />
                                                          </td>
                                                        }

                                                        <td style={{ textAlign: 'center' }}>
                                                          <input type="radio" value="1" name={item.cost_group_radio_name} defaultChecked={(item.cost_group === 1) ? true : null} onChange={(e) => { setCostGroup(e.target.value); handleCostGroupChange(index, e) }} />
                                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                                          <input type="radio" value="2" name={item.cost_group_radio_name} defaultChecked={(item.cost_group === 2) ? true : null} onChange={(e) => { setCostGroup(e.target.value); handleCostGroupChange(index, e) }} />
                                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                                          <input type="radio" value="3" name={item.cost_group_radio_name} defaultChecked={(item.cost_group === 3) ? true : null} onChange={(e) => { setCostGroup(e.target.value); handleCostGroupChange(index, e) }} />
                                                        </td>

                                                        <td style={{ textAlign: 'center' }}>
                                                          <input type="checkbox" value="1" name={item.cash_checkbox_name} id={item.cash_checkbox_name} defaultChecked={(item.cash_status === 'checked') ? true : false} onChange={(e) => { item.cash_status === 'checked' ? setCash(Cash) : setCash(Cash); handleCashChange(index, e) }} />


                                                        </td>

                                                        <td style={{ textAlign: 'center' }}>
                                                          {
                                                            item.wording_display !== '' ?
                                                              <a href={item.wording_path} target="_blank" rel="noreferrer">
                                                                <span style={{ color: 'blue' }} onClick={(e) => SaveIsPrinted(item.display_button_id, 1)}>{item.wording_show_name}</span>
                                                              </a>
                                                              : null}
                                                        </td>

                                                        <td style={{ textAlign: 'right' }}>
                                                          {item.retail_price_textbox === 'text' ?

                                                            <NumericFormat type="text" name={item.retail_price_name} id={item.retail_price_name} value={item.retail_price_value} onChange={(e) => { setRetailPrice(e.target.value); handleRetailPriceChange(index, e) }} onClick={(e) => { RemoveZeroValcomm(index, e) }} onBlur={(e) => { ShowZeroValcomm(index, e); checkMinMax(e, item.price_min, item.price_max) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" />
                                                            :
                                                            item.retail_price_show
                                                          }

                                                          {
                                                            item.retail_price_textbox === '' ?
                                                              <input type="hidden" defaultValue={item.retail_price_value} />
                                                              : null}
                                                        </td>

                                                        <td style={{ textAlign: 'right' }}>
                                                          {
                                                            item.payover_button_id !== '' ?
                                                              <input type="button" name={item.payover_button_id} id={item.payover_button_id} value="Payover" onClick={(evt) => { addPayoverValue(item.show_alert_msg, item.payover_amount); }} />
                                                              :
                                                              item.payover_amount_value
                                                          }

                                                          {
                                                            item.payover_button_id !== '' ?
                                                              item.payover_amount_value
                                                              : null
                                                          }

                                                          {
                                                            item.payover_button_id !== '' ?
                                                              <input type="hidden" name={item.has_payover_amount_name} id={item.has_payover_amount_id} value={HasPayoverAmount} onChange={(e) => { setHasPayoverAmount(e.target.value) }} />
                                                              :
                                                              null
                                                          }

                                                          {
                                                            item.payover_button_id !== '' ?
                                                              <input type="hidden" name={item.payover_amount_name} id={item.payover_amount_id} value={PayoverAmount} onChange={(e) => { setPayoverAmount(e.target.value) }} />
                                                              :
                                                              null
                                                          }
                                                        </td>
                                                      </tr>
                                                    </>
                                                    : null
                                                  : null
                                                : null)
                                            ];
                                          }) :

                                          <tr>
                                            <td colSpan={9} className="text-center"><em>No record found</em></td>
                                          </tr>

                                      }

                                      <tr>
                                        <td className="caption" colSpan={9} style={{ textAlign: 'center' }}>
                                          <input type="submit" name="command" value="Save" onClick={(e) => { setProductsSave(e.target.value) }} />&nbsp;
                                          <input type="submit" name="command" value="Save and Continue" onClick={(e) => { setProductsSave(e.target.value) }} />&nbsp;
                                          <Link to={"/transaction/create_transaction/" + transaction_id}>
                                            <input type="button" className="p-relative" value="Cancel" />
                                          </Link>
                                        </td>
                                      </tr>

                                    </tbody>
                                  </table>
                                </td>
                              </tr>

                            </tbody>
                          </table>
                        </fieldset>
                      </form>
                      :
                      <fieldset disabled={ReadOnly}>
                        <table width="100%" border="0" cellSpacing={5} cellPadding={5}>
                          <tbody>
                            <tr>
                              <td valign="top" align="center">
                                <table cellSpacing={1} className="defaultTbl no_caption_color" width="500">
                                  <tbody>
                                    <tr>
                                      <th style={{ textAlign: 'center' }}>AVAILABLE VEHICLES</th>
                                    </tr>
                                    <tr>
                                      <td style={{ textAlign: 'center' }}>
                                        <select className="text-uppercase">
                                          <option value="0">-- Please add a vehicle to the transaction first --</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="caption">IN ORDER TO ADD OEM ACCESSORIES, PLEASE SELECT A VEHICLE.</td>
                                    </tr>

                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </fieldset>
                  }
                </td>
              </tr>
            </tbody>
          </table>
          <Footer />
        </div>
      </> : null}

    </>
  );
}

export default Products;