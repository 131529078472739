import { Link, useNavigate, useParams } from "react-router-dom"
import React, { useState, useEffect, useRef } from "react";
//import { useLocation } from "react-router";
import DatePicker from "react-datepicker";
import Transaction_menu from "../Transaction/Transaction_menu";
import Footer from "../Dashboard/Footer";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import moment from "moment";
import axios from "../axios";
import Loader from "../Components/Loader";

const Add_Person = () => {

    const navigate = useNavigate();
    const UserEmailRef = useRef();
    const firstNameRef = useRef();
    const lastNameRef = useRef();

    const [Nationality, setNationality] = useState("");
    const [NationalityAll, setNationalityAll] = useState([]);
    const [Gender, setGender] = useState("");
    const [GenderAll, setGenderAll] = useState([]);
    const [CountriesResidance, setCountriesResidance] = useState("");
    const [CountriesResidanceAll, setCountriesResidanceAll] = useState([]);
    const [IdType, setIdType] = useState("");
    const [IdTypeAll, setIdTypeAll] = useState([]);
    const [Title, setTitle] = useState("");
    const [TitleAll, setTitleAll] = useState([]);
    const [ResidencyStatus, setResidencyStatus] = useState(0);
    const [ResidencyStatusAll, setResidencyStatusAll] = useState([]);
    const [HomeLanguage, setHomeLanguage] = useState("");
    const [HomeLanguageAll, setHomeLanguageAll] = useState([]);
    const [CorrespondanceLanguage, setCorrespondanceLanguage] = useState("");
    const [CorrespondanceLanguageAll, setCorrespondanceLanguageAll] = useState([]);
    const [Relationship, setRelationship] = useState("");
    const [RelationshipAll, setRelationshipAll] = useState([]);
    const [ContactViewDetails, setContactViewDetails] = useState([]);

    // const [TranscationDetails, setTranscationDetails] = useState([]);
    const [RelationshipDesignationName, setRelationshipDesignationName] = useState("");
    const [ContactId, setContactId] = useState("");
    const [SaveDetails, setSaveDetails] = useState("");

    const [SecondInsured, setSecondInsured] = useState(false);
    const [RegularDriver, setRegularDriver] = useState(false);
    const [FirstName, setFirstName] = useState("");
    const [SurName, setSurName] = useState("");
    const [PreferredName, setPreferredName] = useState("");
    const [Initials, setInitials] = useState("");
    const [IdNumber, setIdNumber] = useState("");
    const [PassportNumber, setPassportNumber] = useState("");
    const [OtherIdValue, setOtherIdValue] = useState("");

    const [BirthDate, setBirthDate] = useState("");
    const FinalBirthDate = BirthDate != '' ? moment(BirthDate).format("YYYY-MM-DD") : 0;
    const [currentdateFlag, setcurrentdateFlag] = useState(0);
    const [IncomeTaxNumber, setIncomeTaxNumber] = useState("");

    const [OfficeNumber, setOfficeNumber] = useState("");
    const [CellNumber, setCellNumber] = useState("");
    const [HomeNumber, setHomeNumber] = useState("");
    const [FaxNumber, setFaxNumber] = useState("");
    const [UserEmail, setUserEmail] = useState("");
    const [PhysicalAddressLine1, setPhysicalAddressLine1] = useState("");
    const [PhysicalAddressLine2, setPhysicalAddressLine2] = useState("");
    const [PhysicalAddressCity, setPhysicalAddressCity] = useState("");
    const [PostalAddressLine1, setPostalAddressLine1] = useState("");
    const [PostalAddressLine2, setPostalAddressLine2] = useState("");
    const [PostalAddressCity, setPostalAddressCity] = useState("");
    const [Occupation, setOccupation] = useState("");
    const [EmployeeName, setEmployeeName] = useState("");
    const [Department, setDepartment] = useState("");
    const [Note, setNote] = useState("");

    const [ResHideShow, setResHideShow] = useState("none");
    const [ResHideShow1, setResHideShow1] = useState("none");
    const [ResHideShow2, setResHideShow2] = useState("none");

    const url_data = useParams();

    const transaction_id = localStorage.getItem("transaction_id");
    const division_id = localStorage.getItem("division_id");
    const system_type = localStorage.getItem("system_type");
    const user_id = localStorage.getItem("UserId");
    const unincept_flag = localStorage.getItem("unincept_flag");
    const client_id = localStorage.getItem("client_id");
    //console.log(ContactId);

    const UserId = localStorage.getItem("UserId");
    const super_user = localStorage.getItem("super_user");
    const [flag, setFlag] = useState(false);
    const [ReadOnly, setReadOnly] = useState(false);
    const [loader, setLoader] = useState(false);
    
    const [ContactDetailsFlag, setContactDetailsFlag] = useState(0);
    
    // console.log(ContactDetailsFlag);
    //----------------check permission------------------
    useEffect(() => {
        const payload = {
            UserId: UserId,
            super_user: super_user,
            module_name: "Contacts"
        }
        axios
            .post("getRights", payload)
            .then((response) => {
                //console.log(response.data.data)
                // alert(response.data.message);
                if (response.data.status === 200) {
                    if (response.data.data === 0) {//None
                        alert(response.data.message);
                        navigate('/transaction/create_transaction/'+ transaction_id); 
                    } else if (response.data.data === 1) {//Read
                        alert(response.data.message);
                        setFlag(true); setReadOnly(true);
                    } else if (response.data.data === 2) {//Write
                        setFlag(true); setReadOnly(false);
                    } else if (response.data.data === 3) {//Modify
                        setFlag(true); setReadOnly(false);
                    } else if (response.data.data === 4) {//Admin
                        setFlag(true); setReadOnly(false);
                    }
                }
            })
            .catch(() => {
                //setLoginLoader(false);
            });
    }, [UserId, super_user, navigate]);

    useEffect(() => {
        document.title = 'Transactions | Seriti BW';
    }, []);

    //------------------- Add /Edit client company 
    const handleValidation = () => {
        var flag = true;
        const regMatch = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(UserEmail);
        
        if(ContactDetailsFlag == 0 && SaveDetails == "Save and Continue"){
            if (FirstName == "") {
                alert("Please Enter first name");
                firstNameRef.current.focus();
                flag = false; 
            }else if (SurName == "") {
                alert("Please Enter last name");
                lastNameRef.current.focus();
                flag = false; 
            }
        }else{
            if (FirstName == "" && SaveDetails == "Save") {
                alert("Please Enter first name");
                firstNameRef.current.focus();
                flag = false; 
            }else if (SurName == "" && SaveDetails == "Save") {
                alert("Please Enter last name");
                lastNameRef.current.focus();
                flag = false; 
            }
        }
       
        if (UserEmail != "") {
            if (regMatch == false) {
                alert("Please enter a valid email address for this client");
                UserEmailRef.current.focus();
                flag = false;
            }
            flag = true;
        }
        return flag;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        if (flag) {
            if (SaveDetails == "Save" || SaveDetails == "Save and Continue") {
                setLoader(true);    
                const payload = {
                    transaction_id: transaction_id,
                    system_type: system_type,
                    user_id: user_id,
                    id: ContactId,
                    person_id: url_data.person_id,
                    cclient: 1,
                    command: SaveDetails,
                    relationship: Relationship,
                    firstname: FirstName,
                    surname: SurName,
                    preferred_name: PreferredName,
                    id_number: IdNumber,
                    passport_number: PassportNumber,
                    other_id: OtherIdValue,
                    id_type: IdType,
                    mobile_number: CellNumber,
                    office_number: OfficeNumber,
                    home_number: HomeNumber,
                    email: UserEmail,
                    title: Title,
                    gender: Gender,
                    birthdate: FinalBirthDate,
                    fax_number: FaxNumber,
                    home_address_line1: PhysicalAddressLine1,
                    home_address_line2: PhysicalAddressLine2,
                    home_address_city: PhysicalAddressCity,
                    po_address_line1: PostalAddressLine1,
                    po_address_line2: PostalAddressLine2,
                    po_address_city: PostalAddressCity,
                    countries_country: Nationality,
                    notes: Note,
                    initials: Initials,
                    employer_name: EmployeeName,
                    employer_department: Department,
                    regular_driver: RegularDriver,
                    income_tax_number: IncomeTaxNumber,
                    country_of_residence_id: CountriesResidance,
                    residency_status: ResidencyStatus,
                    occupation: Occupation,
                    home_language: HomeLanguage,
                    correspondace_language: CorrespondanceLanguage,
                    second_insured: SecondInsured,
                };
               // console.log(payload);
                axios
                    .post("add_person/add_edit_client_details", payload)
                    .then((response) => {
                      //  console.log(response); 
                        if (response.status == 200) {
                            if (response.data.data.command == "Save") {
                                // 
                                toast.success(response.data.message, {
                                    position: toast.POSITION.TOP_CENTER
                                });
                                window.location.reload(false);
                            } else if (response.data.data.command == "Save and Continue") {
                                navigate('/transaction/fill_forms');
                            }
                        }
                        setLoader(false);
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    })
                    .catch(() => {
                        setLoader(false);
                        console.log('error');
                    });
            }
        }
    };

    //----------- featch person/contact details -----------------
    useEffect(() => {
        setLoader(true);
        const payload = {
            transaction_id: transaction_id,
            id: client_id,
            person_id: url_data.person_id,
            cclient: 1,
        };
        //console.log(payload);
        axios
            .post("add_person/featch_copy_client_details", payload)
            .then((response) => {
               // console.log(response);
                if (response.data.status === 200) {
                    //setTranscationDetails(response.data.data.transaction_details);
                    const obj = response.data.data.contact_details;
                    setRelationship(obj.relationship_id);
                    setSecondInsured(obj.second_insured);
                    setRegularDriver(obj.regular_driver);
                    setTitle(obj.title);
                    setFirstName(obj.first_name);
                    setSurName(obj.last_name);
                    setPreferredName(obj.preffred_name);
                    setInitials(obj.initials);
                    setIdType(obj.id_type);
                    setIdNumber(obj.id_number);
                    setPassportNumber(obj.passport_number);
                    setOtherIdValue(obj.rsa_number);
                    setIncomeTaxNumber(obj.income_tax_number);
                    setBirthDate(obj.birth_date);
                    setNationality(obj.nationality);
                    setGender(obj.gender);
                    setOfficeNumber(obj.work_number);
                    setCellNumber(obj.mobile_number);
                    setHomeNumber(obj.home_number);
                    setFaxNumber(obj.fax_number);
                    setUserEmail(obj.email);
                    setCountriesResidance(obj.country_of_residence);
                    setResidencyStatus(obj.residency_status);
                    setHomeLanguage(obj.home_language);
                    setCorrespondanceLanguage(obj.correspondace_language);
                    setPhysicalAddressLine1(obj.physical_address_line1);
                    setPhysicalAddressLine2(obj.physical_address_line2);
                    setPhysicalAddressCity(obj.physical_address_city);
                    setPostalAddressLine1(obj.postal_address_line1);
                    setPostalAddressLine2(obj.postal_address_line2);
                    setPostalAddressCity(obj.postal_address_city);
                    setOccupation(obj.occupation);
                    setEmployeeName(obj.employer_name);
                    setDepartment(obj.employer_department);
                    setNote(obj.notes);
                    setContactViewDetails(obj);
                    
                       // 1 = OMANG  / 2 = PASSPORT / 3 = RSA ID
                        if (obj.id_type == 1) {
                            const ResHideShow = "table-row";
                            setResHideShow(ResHideShow);
                            setResHideShow1("none");
                            setResHideShow2("none");
                        } else if (obj.id_type == 2) {
                            setResHideShow("none");
                            setResHideShow1("table-row");
                            setResHideShow2("none");
                        } else if (obj.id_type == 3) {
                            setResHideShow("none");
                            setResHideShow1("none");
                            setResHideShow2("table-row");
                        } else {
                            setResHideShow("none");
                            setResHideShow1("none");
                            setResHideShow2("none");
                        }

                }
                setLoader(false);
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
    }, []);


    const clientCopyDet = () => {
        // alert(ContactId);
        const payload = {
            transaction_id: transaction_id,
            id: client_id,
        };

        axios
            .post("add_person/person_copy_client_details", payload)
            .then((response) => {
                //console.log(response.data.data.copy_contact_details); 
                const obj = response.data.data.copy_contact_details;
                setRelationship(obj.relationship_id);
                setSecondInsured(obj.second_insured);
                setRegularDriver(obj.regular_driver);
                setTitle(obj.title);
                setFirstName(obj.first_name);
                setSurName(obj.last_name);
                setPreferredName(obj.preffred_name);
                setInitials(obj.initials);
                setIdType(obj.id_type);
                setIdNumber(obj.id_number);
                setPassportNumber(obj.passport_number);
                setOtherIdValue(obj.rsa_number);
                setIncomeTaxNumber(obj.income_tax_number);
                setBirthDate(obj.birth_date);
                setNationality(obj.nationality);
                setGender(obj.gender);
                setOfficeNumber(obj.work_number);
                setCellNumber(obj.mobile_number);
                setHomeNumber(obj.home_number);
                setFaxNumber(obj.fax_number);
                setUserEmail(obj.email);
                setCountriesResidance(obj.country_of_residence);
                setResidencyStatus(obj.residency_status);
                setHomeLanguage(obj.home_language);
                setCorrespondanceLanguage(obj.correspondace_language);
                setPhysicalAddressLine1(obj.physical_address_line1);
                setPhysicalAddressLine2(obj.physical_address_line2);
                setPhysicalAddressCity(obj.physical_address_city);
                setPostalAddressLine1(obj.postal_address_line1);
                setPostalAddressLine2(obj.postal_address_line2);
                setPostalAddressCity(obj.postal_address_city);
                setOccupation(obj.occupation);
                setEmployeeName(obj.employer_name);
                setDepartment(obj.employer_department);
                setNote(obj.notes);
                setContactViewDetails(obj);
            })
            .catch(() => {
                console.log('error');
            });
    }

    //----------- get gender -----------------
    useEffect(() => {
        axios
            .get("add_edit_company/featch_gender")
            .then((response) => {
                if (response.status === 200) {
                    setGenderAll(response.data.data.gender_array);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //-------------------- get Nationality,Company Repr Nationality,Countries Residance,Countries Of Incorporation   
    useEffect(() => {
        const payload = {
            division_id: division_id,
            client_id: client_id,
        };

        axios
            .post("add_edit_company/featch_company_repr_nationality", payload)
            .then((response) => {
                //console.log(response);
                if (response.status === 200) {
                    setNationalityAll(response.data.data.company_repr_nationality);
                    setCountriesResidanceAll(response.data.data.company_repr_nationality);
                    setContactDetailsFlag(response.data.data.contact_data_flag);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get id type -----------------
    useEffect(() => {
        axios
            .get("add_edit_company/featch_type_id")
            .then((response) => {
                if (response.status === 200) {
                    setIdTypeAll(response.data.data.id_type_array);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get company  title -----------------
    useEffect(() => {
        axios
            .get("add_edit_company/featch_titles")
            .then((response) => {
                // console.log(response.data.data.title_array);
                if (response.status === 200) {
                    setTitleAll(response.data.data.title_array);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get residency status -----------------
    useEffect(() => {
        axios
            .get("add_edit_company/featch_residency_status")
            .then((response) => {
                if (response.status === 200) {
                    setResidencyStatusAll(response.data.data.residency_status_array);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get home / correspondance language ( 2 array )-----------------
    useEffect(() => {
        const payload = {
            division_id: division_id,
        };

        axios
            .post("add_edit_company/featch_correspondace_language", payload)
            .then((response) => {
                if (response.status === 200) {
                    setHomeLanguageAll(response.data.data.home_language);
                    setCorrespondanceLanguageAll(response.data.data.correspondance_language);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get relationship array----------------
    useEffect(() => {
        const payload = {
            transaction_id: transaction_id,
            id: 0,
        };

        axios
            .post("add_person/featch_relationship", payload)
            .then((response) => {
                if (response.status === 200) {
                    //console.log(response);
                    setRelationshipAll(response.data.data.relationship_array);
                    setRelationshipDesignationName(response.data.data.display_relationship);
                    setContactId(response.data.data.id);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //--------------- to check only type integer value
    const NumberChange = (val, flag) => {
        const re = /^[0-9\b]+$/;
        if (flag == 1) {
            if (val === '' || re.test(val)) {
                setOfficeNumber(val);
            }
        } else if (flag == 2) {
            if (val === '' || re.test(val)) {
                setCellNumber(val);
            }
        } else if (flag == 3) {
            if (val === '' || re.test(val)) {
                setHomeNumber(val);
            }
        } else if (flag == 4) {
            if (val === '' || re.test(val)) {
                setFaxNumber(val);
            }
        }
    }

    //------- same as physical address
    const SameAsAbove = (e) => {
        const loc_address = e.currentTarget.name;
        // console.log(loc_address);
        if (loc_address == 'same_as_physical') {
            setPostalAddressLine1(PhysicalAddressLine1)
            setPostalAddressLine2(PhysicalAddressLine2);
            setPostalAddressCity(PhysicalAddressCity);
        }
    }

    //---------- Id typewise hide show row
    const IdTypeWiseShowHide = (val) => {
        // 1 = OMANG  / 2 = PASSPORT / 3 = RSA ID
        if (val == 1) {
            const ResHideShow = "table-row";
            setResHideShow(ResHideShow);
            setResHideShow1("none");
            setResHideShow2("none");
        } else if (val == 2) {
            setResHideShow("none");
            setResHideShow1("table-row");
            setResHideShow2("none");
        } else if (val == 3) {
            setResHideShow("none");
            setResHideShow1("none");
            setResHideShow2("table-row");
        } else {
            setResHideShow("none");
            setResHideShow1("none");
            setResHideShow2("none");
        }
    }

    //--------------- title selected wise changes gender
    const changeGender = (val) => {
        if (val == 4 || val == 6) {
            setGender(1);
        } else {
            setGender(2);
        }
    }

    // const copyToPreffredName = (val) => {
    //     setPreferredName(val);
    // }

    // const ChangeIntials = (val) => {
    //     setInitials(val.substring(0, 1));
    // }

    const copyToPreffredName = (val) => {
        var discription = val.split(' ');
        var final_discription = discription[0];
              // console.log(ll[0]);
        setPreferredName(final_discription);
    }

    const ChangeIntials = (val) => {
        var initials = val.split(' ');
        var finalInitials = '';
        for (var i = 0; i < initials.length; i++) {
           var name = initials[i];
           finalInitials = finalInitials.concat(name.substring(0, 1));
        }
        setInitials(finalInitials);
       //console.log(dynWording);
    }

    let panFlag = 1;

    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px", marginLeft: "-200px" }} />
            <Transaction_menu panel_flag={panFlag}/>
            {loader ? <Loader /> : null}
            {flag ? <>
                <div>
                    <table width="100%" height="1024" id="mainContainer" border="0" cellPadding={0} cellSpacing={0} className="custom-body">
                        <tbody>
                            <tr valign="top">
                                <td></td>
                                <td width="100%">
                                    <div id="parent_wrapper" className="custom-parent_wrapper">
                                    </div>
                                    <table width="100%" border="0" cellPadding={5} cellSpacing={5}>
                                        <tbody>
                                            <tr>
                                                <td valign="top" align="center">
                                                    <form name="command" method="post" onSubmit={handleSubmit}>
                                                        <fieldset disabled={ReadOnly}>
                                                            <table cellSpacing={1} className="defaultTbl no_caption_color" width="650">
                                                                <tbody>
                                                                    <tr>
                                                                        {url_data.person_id ?
                                                                            <th colSpan={2} className="text-center">
                                                                                EDIT TRANSACTION CONTACT
                                                                            </th>
                                                                            :
                                                                            <th colSpan={2} className="text-center">
                                                                                Add New Contact
                                                                            </th>
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ textAlign: 'center' }} className="caption">
                                                                            {unincept_flag == 0 ?
                                                                                // <Link to={"/transaction/add_person/" + ContactId + "/person_id=0/cclient=1"}>
                                                                                <input type="button" className="hide_button_after_inception" value="Copy Client Details" onClick={clientCopyDet} />
                                                                                // </Link>
                                                                                : null}
                                                                        </td>
                                                                    </tr>
                                                                    {/* Relationship / Designation */}
                                                                    <tr>
                                                                        <td className="caption" width="35%">{RelationshipDesignationName}</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <select name="relationship" id="relationship" className="caption" style={{ textTransform: 'uppercase' }} value={Relationship} onChange={(e) => { setRelationship(e.target.value); }}>
                                                                                    {RelationshipAll.map((item) => {
                                                                                        return (
                                                                                            <option key={item.id} value={item.id}>
                                                                                                {item.name}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                                : ContactViewDetails.relationship_name}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" width="35%">Second Insured</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="checkbox" name="second_insured" value={SecondInsured} onChange={(e) => { setSecondInsured(!SecondInsured) }} checked={SecondInsured} />
                                                                                : ContactViewDetails.second_insured_name}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" width="35%">Regular Driver</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="checkbox" name="regular_driver" value={RegularDriver} onChange={(e) => { setRegularDriver(!RegularDriver) }} checked={RegularDriver} />
                                                                                : ContactViewDetails.is_driver_name}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" width="35%">Title</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <select name="title" id="title" className="caption" style={{ textTransform: 'uppercase' }} value={Title} onChange={(e) => { setTitle(e.target.value); changeGender(e.target.value) }}>
                                                                                    <option value="0">-- SELECT A TITLE --</option>
                                                                                    {TitleAll.map((item) => {
                                                                                        return (
                                                                                            <option key={item.id} value={item.id}>
                                                                                                {item.title}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                                : ContactViewDetails.title_name}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" width="35%">First Name <span className="text-danger">*</span></td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" name="firstname" size="50" value={FirstName} onChange={(e) => { setFirstName(e.target.value); }} className="text-uppercase" onKeyUp={(e) => { ChangeIntials(e.target.value); }} onBlur={(e) => { copyToPreffredName(e.target.value); }} ref={firstNameRef}/>
                                                                            : FirstName}
                                                                        </td>
                                                                    </tr>
                                                                    
                                                                    <tr>
                                                                        <td className="caption" width="35%">Last Name <span className="text-danger">*</span></td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" name="surname" size="50" value={SurName} onChange={(e) => setSurName(e.target.value)} className="text-uppercase" ref={lastNameRef}/>
                                                                            : SurName}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" width="35%">Preferred Name</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" name="preferred_name" size="50" value={PreferredName} onChange={(e) => setPreferredName(e.target.value)} className="text-uppercase" />
                                                                                : PreferredName}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" width="35%">Initials</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" name="initials" size="50" value={Initials} onChange={(e) => setInitials(e.target.value)} className="text-uppercase" />
                                                                                : Initials}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" width="35%">ID Type</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <select name="id_type" id="type_of_id" className="text-uppercase" value={IdType} onChange={(e) => { setIdType(e.target.value); IdTypeWiseShowHide(e.target.value) }} >
                                                                                    <option value="0">-- SELECT A TYPE --</option>
                                                                                    {IdTypeAll.map((item) => {
                                                                                        return (
                                                                                            <option key={item.id} value={item.id}>
                                                                                                {item.type}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                                : ContactViewDetails.id_type_name}
                                                                        </td>
                                                                    </tr>

                                                                    <tr style={{ display: ResHideShow }}>
                                                                        <td className="caption" width="35%">ID Number</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" name="id_number" size="50" value={IdNumber} onChange={(e) => setIdNumber(e.target.value)} className="text-uppercase" />
                                                                                : IdNumber}
                                                                        </td>
                                                                    </tr>

                                                                    <tr style={{ display: ResHideShow1 }}>
                                                                        <td className="caption" width="35%">Passport Number</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" name="passport_number" size="50" value={PassportNumber} onChange={(e) => setPassportNumber(e.target.value)} className="text-uppercase" />
                                                                                : PassportNumber}
                                                                        </td>
                                                                    </tr>

                                                                    <tr style={{ display: ResHideShow2 }}>
                                                                        <td className="caption" width="35%">RSA ID Number</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" name="other_id_value" size="50" value={OtherIdValue} onChange={(e) => setOtherIdValue(e.target.value)} className="text-uppercase" />
                                                                                : OtherIdValue}
                                                                        </td>
                                                                    </tr>

                                                                    {/* <tr>
                                        <td className="caption" width="35%">Income Tax Number</td>
                                        <td>
                                             <input type="text" id="income_tax_number" name="income_tax_number" size="30" className="text-uppercase" value={IncomeTaxNumber} onChange = {(e) => setIncomeTaxNumber(e.target.value)} className="text-uppercase"/>
                                        </td>
                                    </tr> */}

                                                                    <tr>
                                                                        <td className="caption" width="35%">Date of Birth</td>
                                                                        {unincept_flag == 0 ?
                                                                            <td>
                                                                                {
                                                                                    currentdateFlag == 1 ?
                                                                                        <DatePicker id="date_at_current_address" selected={BirthDate} name="date_at_current_address" onChange={(date) => { setBirthDate(date); setcurrentdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => { return new Date() > d; }} />
                                                                                        :
                                                                                        <DatePicker id="date_at_current_address" value={BirthDate} name="date_at_current_address" onChange={(date) => { setBirthDate(date); setcurrentdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => { return new Date() > d; }} />
                                                                                }
                                                                            </td>
                                                                            :
                                                                            <td>
                                                                                {BirthDate}
                                                                            </td>
                                                                        }
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" width="35%">Nationality</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <select name="nationality" id="nationality" value={Nationality} onChange={(e) => setNationality(e.target.value)} style={{ textTransform: "uppercase" }} >
                                                                                    <option value="">--Select Nationality--</option>
                                                                                    {NationalityAll.map((item) => {
                                                                                        return (
                                                                                            <option key={item.id} value={item.id}>
                                                                                                {item.text}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                                : ContactViewDetails.nationality_name}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption" width="35%">Gender</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <select id="gender" name="gender" className="text-uppercase" value={Gender} onChange={(e) => { setGender(e.target.value) }} >
                                                                                    <option>-- Select a Gender --</option>
                                                                                    {GenderAll.map((item) => {
                                                                                        return (
                                                                                            <option key={item.id} value={item.id}>
                                                                                                {item.gender}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                                : ContactViewDetails.gender_name}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <th colSpan={2} className="text-center">Contact Details</th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Work Number</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" id="office_number" name="office_number" size="50" value={OfficeNumber} onChange={(e) => NumberChange(e.target.value, 1)} />
                                                                                : OfficeNumber}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Mobile Number</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" id="cell_number" name="cell_number" size="50" className="text-uppercase" value={CellNumber} onChange={(e) => { NumberChange(e.target.value, 2) }} />
                                                                                : CellNumber}
                                                                        </td>
                                                                    </tr>

                                                                    <tr id="home_number">
                                                                        <td className="caption">Home Number</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" id="home" name="home_number" size="50" className="text-uppercase" value={HomeNumber} onChange={(e) => { NumberChange(e.target.value, 3) }} />
                                                                                : HomeNumber}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Fax Number</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" id="fax_number" name="fax_number" size="50" className="text-uppercase" value={FaxNumber} onChange={(e) => NumberChange(e.target.value, 4)} />
                                                                                : FaxNumber}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Email</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" name="email" id="user_email" size="50" className="text-uppercase" value={UserEmail} onChange={(e) => setUserEmail(e.target.value)} ref={UserEmailRef} />
                                                                                : UserEmail}
                                                                        </td>
                                                                    </tr>

                                                                    <tr className="country_of_residence">
                                                                        <td className="caption">Country of Residence </td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <select name="countries_country" className="text-uppercase" value={CountriesResidance} onChange={(e) => setCountriesResidance(e.target.value)}>
                                                                                    <option value="">--Select Country of Residence--</option>
                                                                                    {CountriesResidanceAll.map((item) => {
                                                                                        return (
                                                                                            <option key={item.id} value={item.id}>
                                                                                                {item.text}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                                : ContactViewDetails.country_of_residence_name}
                                                                        </td>
                                                                    </tr>

                                                                    <tr className="not_commercial" >
                                                                        <td className="caption">Residency Status</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <select name="residency_status" id="residency_status" className="text-uppercase" value={ResidencyStatus} onChange={(e) => { setResidencyStatus(e.target.value); }}>
                                                                                    <option value="0">-- Select a Residency Status --</option>
                                                                                    {ResidencyStatusAll.map((item) => {
                                                                                        return (
                                                                                            <option key={item.id} value={item.id}>
                                                                                                {item.res_status}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                                : ContactViewDetails.residency_status_name}
                                                                        </td>
                                                                    </tr>

                                                                    <tr className="not_commercial" >
                                                                        <td className="caption">Home Language</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <select name="home_language" className="text-uppercase" value={HomeLanguage} onChange={(e) => setHomeLanguage(e.target.value)} >
                                                                                    {HomeLanguageAll.map((item) => {
                                                                                        return (
                                                                                            <option key={item.id} value={item.id}>
                                                                                                {item.text}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                                : ContactViewDetails.home_language_name}
                                                                        </td>
                                                                    </tr>

                                                                    <tr className="not_commercial" >
                                                                        <td className="caption">Correspondence Language</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <select name="correspondance_language" className="text-uppercase" value={CorrespondanceLanguage} onChange={(e) => setCorrespondanceLanguage(e.target.value)} >
                                                                                    {CorrespondanceLanguageAll.map((item) => {
                                                                                        return (
                                                                                            <option key={item.id} value={item.id}>
                                                                                                {item.text}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                                : ContactViewDetails.correspondace_language_name}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Physical Address Line 1</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" name="physical_address_line1" id="physical_address_line1" className="text-uppercase" value={PhysicalAddressLine1} onChange={(e) => setPhysicalAddressLine1(e.target.value)} />
                                                                                : PhysicalAddressLine1}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Physical Address Line 2</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" name="physical_address_line2" id="physical_address_line2" className="text-uppercase" value={PhysicalAddressLine2} onChange={(e) => setPhysicalAddressLine2(e.target.value)} />
                                                                                : PhysicalAddressLine2}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Physical Address City</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" name="physical_address_city" id="physical_address_city" className="text-uppercase" value={PhysicalAddressCity} onChange={(e) => setPhysicalAddressCity(e.target.value)} />
                                                                                : PhysicalAddressCity}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Postal Address Line 1</td>
                                                                        {unincept_flag == 0 ?
                                                                            <td>
                                                                                <input type="text" name="postal_address_line1" id="postal_address_line1" className="text-uppercase" value={PostalAddressLine1} onChange={(e) => setPostalAddressLine1(e.target.value)} />
                                                                                <div className="float-right">
                                                                                    <input type="button" className="button_after_inception" name="same_as_physical" value="Same as Physical" onClick={function (e) { SameAsAbove(e); }} />
                                                                                </div>
                                                                            </td>
                                                                            :
                                                                            <td>
                                                                                {PostalAddressLine1}
                                                                            </td>
                                                                        }
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Postal Address Line 2</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" name="postal_address_line2" id="postal_address_line2" className="text-uppercase" value={PostalAddressLine2} onChange={(e) => setPostalAddressLine2(e.target.value)} />
                                                                                : PhysicalAddressCity}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Postal Address City</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" name="postal_address_city" id="postal_address_city" className="text-uppercase" value={PostalAddressCity} onChange={(e) => setPostalAddressCity(e.target.value)} />
                                                                                : PostalAddressCity}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Occupation</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" name="occupation" className="text-uppercase" value={Occupation} onChange={(e) => setOccupation(e.target.value)} />
                                                                                : Occupation}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Employer Name</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" name="employer_name" className="text-uppercase" value={EmployeeName} onChange={(e) => setEmployeeName(e.target.value)} />
                                                                                : EmployeeName}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Department</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <input type="text" name="employer_department" className="text-uppercase" value={Department} onChange={(e) => setDepartment(e.target.value)} />
                                                                                : Department}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="caption">Note</td>
                                                                        <td>
                                                                            {unincept_flag == 0 ?
                                                                                <textarea name="notes" rows={4} cols={45} value={Note} onChange={(e) => setNote(e.target.value)} className="text-uppercase"></textarea>
                                                                                : Note}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        {unincept_flag == 0 ?
                                                                            <td className="caption" colSpan={2} style={{ textAlign: 'center' }} >
                                                                                <input type="submit" name="command" value="Save" onClick={(e) => { setSaveDetails(e.target.value) }} />&nbsp;
                                                                                <input type="submit" name="command" value="Save and Continue" onClick={(e) => { setSaveDetails(e.target.value) }} />&nbsp;
                                                                                <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                                    <input type="button" className="p-relative" value="Cancel" />
                                                                                </Link>
                                                                            </td>
                                                                            :
                                                                            <td className="caption" colSpan={2} style={{ textAlign: 'center' }}>
                                                                                <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                                    <input type="submit" name="command" className="hide_button_after_inception" value="View Transaction" />
                                                                                </Link>
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </fieldset>
                                                    </form>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Footer />
                </div>
            </> : null}

        </>
    );

}

export default Add_Person