import Admin_menu from "../Admin_menu"
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import axios from "../../axios";

function Transaction_products() {
    document.title = "Administration | Seriti - Product Admin";
    const navigate = useNavigate();

    const division_id = localStorage.getItem("division_id");
    const system_type = localStorage.getItem("system_type");

    const [parentId, setParentId] = useState('');//type id(category id)
    const [allParentId, setAllParentId] = useState([]);//sub-type id(sub-category id)

    const [listParent, setListParent] = useState('');
    const [allListParent, setAllListParent] = useState([]);

    const [flag, setFlag] = useState(false);
    const [command, setCommand] = useState('');
    // console.log(parentId);
    const handleFlag = () => {
        if(parentId === '0' || parentId === '') setFlag(false)
        else setFlag(true)
    }
    //----------------------------dropdown of category----------------------------------// 
    useEffect(() => {
        const payload = {
            division_id: division_id,
            system_type: system_type
        };
        axios
            .post("category_options", payload)
            .then((response) => {
                //   console.log(response.data.data)
                setAllParentId(response.data.data.rTypes);
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, [division_id, system_type]);

    //-----------------------handle validation fro select & list------------------------//
    const handleValidation = () => {
        var f = true;
        if (parentId === '') {
            alert('Please select category!');
            f = false;
        }
        return f;
    };
    
    //-------------------------------getting list-------------------------------------//
    const handleSelect = (e) => {
        e.preventDefault();
        // const f = (command === 'Select' || command === 'List') ? handleValidation() : true;
        const f = handleValidation();
        if (f) {
            if (command === 'Select') {
                axios
                    .get("get_list/" + parentId)
                    .then((response) => {
                        //console.log(response.data.data)
                        setAllListParent(response.data.data);
                    })
                    .catch((response) => {
                        //alert(response.data);
                    });
            } else if (command === 'List') {
                if (listParent !== '') {
                    navigate('/admin/financial_products',{state:{type: parentId,listParent : listParent}});
                } else {
                    alert('Please select sub-category!');
                    return false;
                }
            } else if (command === 'CategoryAdmin') {
                navigate('/admin/categories/' + parentId);
            } else if (command === 'PackageAdmin') {
                navigate('/admin/categories/' + parentId);
            }
        }
    };

    return (
        <>
            <Admin_menu />
            <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                <tbody>
                    <tr valign="top" className="bg-white">
                        <td></td>
                        <td width="100%" className="bg-white">
                            <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                            <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                            <form method="post" action="" onSubmit={handleSelect}>
                                                <table className="defaultTbl" width="400" cellSpacing="1">
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan={2} className="text-center">List Financial Products</th>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <select name="parentId" value={parentId} className="text-uppercase" onChange={(e) => { setParentId(e.target.value); setCommand('Select') }} style={{ width : "310px" }}>
                                                                    <option value="0"> -- Select -- </option>
                                                                    {allParentId.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id} >
                                                                                {item.option}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                            <td><input type="submit" name="command" value="Select" onClick={(e) => handleFlag()} /></td>
                                                        </tr>
                                                        {flag && (
                                                            <tr>
                                                                <td>
                                                                    <select name="listParent" id="listParent" className="text-uppercase" value={listParent} onChange={(e) => setListParent(e.target.value)} style={{ width : "310px" }}>
                                                                        <option value="0"> -- Select -- </option>
                                                                        {allListParent.map((item) => {
                                                                            return (
                                                                                <option key={item.id} value={item.id} >
                                                                                    {item.option}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <input type="submit" name="command" value="List" onClick={(e) => setCommand('List')} />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                <br />
                                                <p>
                                                    <input type="submit" value="Category Administration" onClick={(e) => setCommand('CategoryAdmin')} />
                                                    {/* <input type="button" value="Package Administration" onClick={(e) => setCommand('PackageAdmin')} /> */}
                                                </p>
                                            </form>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default Transaction_products