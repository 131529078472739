import { Link, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import Transaction_menu from './Transaction_menu';
import Footer from "../Dashboard/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "../axios";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { NumericFormat } from 'react-number-format';
import delete_row_img from '../Images/icons/x.png';
import Employee_details_table_rows from "../Transaction/Employee_details_table_rows";
import Contact_details_table_rows from "../Transaction/Contact_details_table_rows";
import garborone_bank_img from '../Images/logo/LOGO_01.gif';
import Loader from "../Components/Loader"

const Finance_application_bank_garborone = () => {

    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [MaritalStatus, setMaritalStatus] = useState("");
    const [MaritalStatusAll, setMaritalStatusAll] = useState([]);
    const [Title, setTitle] = useState("");
    const [TitleAll, setTitleAll] = useState([]);
    const [ResidencyStatus, setResidencyStatus] = useState(0);
    const [ResidencyStatusAll, setResidencyStatusAll] = useState([]);
    const [ResidentialStatus, setResidentialStatus] = useState("");
    const [ResidentialStatusAll, setResidentialStatusAll] = useState([]);
    const [HomeLanguage, setHomeLanguage] = useState("");
    const [HomeLanguageAll, setHomeLanguageAll] = useState([]);
    const [CommunicationLanguage, setCommunicationLanguage] = useState("");
    const [CommunicationLanguageAll, setCommunicationLanguageAll] = useState([]);
    const [ClientDetails, setClientDetails] = useState("");
    const [ClientDetailsAll, setClientDetailsAll] = useState([]);
    const [Gender, setGender] = useState("");
    const [GenderAll, setGenderAll] = useState([]);
    const [IncomeCategory, setIncomeCategory] = useState("");
    const [IncomeCategoryAll, setIncomeCategoryAll] = useState([]);
    const [FinantialProviderLoan1, setFinantialProviderLoan1] = useState("");
    const [FinantialProviderLoan2, setFinantialProviderLoan2] = useState("");
    const [FinantialProviderAll, setFinantialProviderAll] = useState([]);
    const [FinantialCompany, setFinantialCompany] = useState("");
    const [FinantialCompanyAll, setFinantialCompanyAll] = useState([]);
    const [TermMonth, setTermMonth] = useState("");
    const [TermMonthAll, setTermMonthAll] = useState([]);
    const [FinanceType, setFinanceType] = useState("");
    const [FinanceTypeAll, setFinanceTypeAll] = useState([]);
    const [ConsumerSegment, setConsumerSegment] = useState("");
    const [ConsumerSegmentAll, setConsumerSegmentAll] = useState([]);
    const [BusinessSegment, setBusinessSegment] = useState("");
    const [BusinessSegmentAll, setBusinessSegmentAll] = useState([]);
    const [SalaryDate, setSalaryDate] = useState("");
    const [SalaryDateAll, setSalaryDateAll] = useState([]);

    const [SpouseDetails, setSpouseDetails] = useState("");
    const [SpouseDetailsAll, setSpouseDetailsAll] = useState([]);
    const [SpouseTitle, setSpouseTitle] = useState("");
    const [SpouseResidencyStatus, setSpouseResidencyStatus] = useState(0);
    const [SpouseGender, setSpouseGender] = useState("");

    //------- contact details
    const [ContactDetailsAll, setContactDetailsAll] = useState([]);
    const [RelationshipAll, setRelationshipAll] = useState([]);
    const [ContactTitleAll, setContactTitleAll] = useState([]);
    const [ContactDetailsArray, setContactDetailsArray] = useState([]); // select
    const [ContactDetailsEditArray, setContactDetailsEditArray] = useState([]); // edit
    const [ContactDetailsAddArray, setContactDetailsAddArray] = useState([]); // Add
    const [ContactDatazero, setContactDatazero] = useState(0);
    const [ContactControlInstanceNumber, setContactControlInstanceNumber] = useState(0);
    const [rowsAddContactData, setrowsAddContactData] = useState([]);
    const [RowHideShow2, setRowHideShow2] = useState("none");
    const [AddNewButton2, setAddNewButton2] = useState("table-row");
    const [ContactDetailsSave, setContactDetailsSave] = useState("");
    const [handleDeleteFlag, sethandleDeleteFlag] = useState(0);
    const [PrintButtonData, setPrintButtonData] = useState("");
    const [ContactDipFlag, setContactDipFlag] = useState(0);
    const [AddEntryDiplayFlag, setAddEntryDiplayFlag] = useState("");

    const [ContactDetailsFlag, setContactDetailsFlag] = useState(0);  //setSalaryDateFlag
    const [ContactValidate, setContactValidate] = useState(""); //setEmpValidateSalary
    const [colorFocus1, setcolorFocus1] = useState(""); // setcolorFocus

    const [ContactRelationFlag, setContactRelationFlag] = useState(0);
    const [RelationValidate, setRelationValidate] = useState(0);
    const [colorFocus2, setcolorFocus2] = useState("");

    const [FirstNameFlag, setFirstNameFlag] = useState(0);
    const [FirstNameValidate, setFirstNameValidate] = useState("");
    const [colorFocus3, setcolorFocus3] = useState("");

    const [LastNameFlag, setLastNameFlag] = useState(0);
    const [LastNameValidate, setLastNameValidate] = useState("");
    const [colorFocus4, setcolorFocus4] = useState("");

    const [contactDet, setcontactDet] = useState(0);
    const [rowsAddContactData2, setrowsAddContactData2] = useState([]);
    const [OnchangeContactValidateFlag, setOnchangeContactValidateFlag] = useState(0);

    // STATE FOR FLAGS
    const [ClientDetailFlag, setClientDetailFlag] = useState(true)
    const [EmployeeDetailFlag, setEmployeeDetailFlag] = useState(false)
    const [FinancialDetailFlag, setFinancialDetailFlag] = useState(true)
    const [LoanDetailFlag, setLoanDetailFlag] = useState(false)
    const [SpouseDetailFlag, setSpouseDetailFlag] = useState(false)
    const [ContactDetailFlag, setContactDetailFlag] = useState(false)
    //------------------- manage date flag
    const [BirthdateFlag, setBirthdateFlag] = useState(0);
    const [CurrentdateFlag, setCurrentdateFlag] = useState(0);
    const [PrevdateFlag, setPrevdateFlag] = useState(0);
    const [MarrieddateFlag, setMarrieddateFlag] = useState(0);
    const [SpouseBirthdateFlag, setSpouseBirthdateFlag] = useState(0);
    const [SpouseDateEmployeeFlag, setSpouseDateEmployeeFlag] = useState(0);
    const [LoanCommencementDateFlag, setLoanCommencementDateFlag] = useState(0);
    const [LoanFirstDebitOrderDateFlag, setLoanFirstDebitOrderDateFlag] = useState(0);
    const [PurchaseDateFlag, setPurchaseDateFlag] = useState(0);

    // DATA STATE FOR CLIENT DETAILS
    const [DateOfBirth, setDateOfBirth] = useState('');
    const FinalDateOfBirth = DateOfBirth != '' ? moment(DateOfBirth).format("YYYY-MM-DD") : 0;
    const [Firstname, setFirstname] = useState('');
    const [Surname, setSurname] = useState('');
    const [IDNumber, setIDNumber] = useState('');
    const [Initials, setInitials] = useState('');
    const [HomeNumber, setHomeNumber] = useState('');
    const [MobileNumber, setMobileNumber] = useState('');
    const [WorkNumber, setWorkNumber] = useState('');
    const [FixNumber, setFixNumber] = useState('');
    const [Email, setEmail] = useState('');
    const [PhysicalAddress1, setPhysicalAddress1] = useState('');
    const [PhysicalAddress2, setPhysicalAddress2] = useState('');
    const [PostalAddress1, setPostalAddress1] = useState('');
    const [PostalAddress2, setPostalAddress2] = useState('');
    const [PhysicalAddressCity, setPhysicalAddressCity] = useState('');
    const [PostalAddressCity, setPostalAddressCity] = useState('');
    const [MarriedDate, setMarriedDate] = useState('');
    const FinalMarriedDate = MarriedDate != '' ? moment(MarriedDate).format("YYYY-MM-DD") : 0;
    //const [Communication, setCommunication] = useState('');
    const [OccupancyPreviousDate, setOccupancyPreviousDate] = useState('');
    const FinalOccupancyPreviousDate = OccupancyPreviousDate != '' ? moment(OccupancyPreviousDate).format("YYYY-MM-DD") : 0;
    const [OccupancyCurrentDate, setOccupancyCurrentDate] = useState('');
    const FinalOccupancyCurrentDate = OccupancyCurrentDate != '' ? moment(OccupancyCurrentDate).format("YYYY-MM-DD") : 0;
    const [Guarantor, setGuarantor] = useState(0);
    const [Dependant, setDependant] = useState("")


    // DATA STATE FOR EMPLOYEE DETAILS
    const [EmployeeDetailsArray, setEmployeeDetailsArray] = useState([]); // select
    const [EmployeeDetailsEditArray, setEmployeeDetailsEditArray] = useState([]); // edit
    const [EmployeeDetailsAddArray, setEmployeeDetailsAddArray] = useState([]); // Add
    const [EmployeeDetailsCheckboxEditArray, setEmployeeDetailsCheckboxEditArray] = useState([]); // edit checkbox
    const [EmployeeDatazero, setEmployeeDatazero] = useState(0);
    const [EmpCurrent, setEmpCurrent] = useState(true); // checkbox
    const [RowHideShow1, setRowHideShow1] = useState("none");
    const [AddNewButton1, setAddNewButton1] = useState("table-row");
    const [rowsAddEmployeeData, setrowsAddEmployeeData] = useState([]);
    const [EmpControlInstanceNumber, setEmpControlInstanceNumber] = useState(0);
    const [handleFlag, sethandleFlag] = useState(0);
    const [EmployeeDetailsSave, setEmployeeDetailsSave] = useState("");
    const [SalaryDateFlag, setSalaryDateFlag] = useState(0);
    const [EmpValidateSalary, setEmpValidateSalary] = useState("");
    const [colorFocus, setcolorFocus] = useState("");
    const [DateRowHideShow1, setDateRowHideShow1] = useState(0);
    const [DateRowHideShow2, setDateRowHideShow2] = useState(0);
    const [DateRowHideShow3, setDateRowHideShow3] = useState(0);
    const [DateRowHideShow4, setDateRowHideShow4] = useState(0);
    const [DateRowHideShow5, setDateRowHideShow5] = useState(0);
    const [DateRowHideShow6, setDateRowHideShow6] = useState(0);
    const [DateRowHideShow7, setDateRowHideShow7] = useState(0);
    const [DateRowHideShow8, setDateRowHideShow8] = useState(0);
    const [DateRowHideShow9, setDateRowHideShow9] = useState(0);
    //const [focusDate, setfocusDate] = useState("");
    const [flag, setFlag] = useState(0);
    const [EmpDipFlag, setEmpDipFlag] = useState(0);
    // console.log(focusDate);

    //------------------------- CLIENT DETAILS ----------------------------------------
    const [Transaction, setTransaction] = useState("")
    const [GrossMonthlySalary, setGrossMonthlySalary] = useState("")
    const [Commission6MonthAvg, setCommission6MonthAvg] = useState("")
    const [Overtime6MonthAvg, setOvertime6MonthAvg] = useState("")
    const [MonthlyAllowance, setMonthlyAllowance] = useState("")
    const [MonthlyCarAllowance, setMonthlyCarAllowance] = useState("")
    const [PaySlipMAllowance, setPaySlipMAllowance] = useState("")
    const [AllOtherIncome, setAllOtherIncome] = useState("")
    const [TotalIncome, setTotalIncome] = useState("")
    const [ExistingLoan, setExistingLoan] = useState(0)
    const [Expenditure, setExpenditure] = useState(0)
    const [ToBeSealed1, setToBeSealed1] = useState(0)
    const [MonthlyInvestment1, setMonthlyInvestment1] = useState("")
    const [ToBeSealed2, setToBeSealed2] = useState(0)
    const [MonthlyInvestment2, setMonthlyInvestment2] = useState("")
    const [RentAmount, setRentAmount] = useState("")
    const [Telephone, setTelephone] = useState("")
    const [RateTaxWater, setRateTaxWater] = useState("")
    const [Fuel, setFuel] = useState("")
    const [VehicleInstallment, setVehicleInstallment] = useState("")
    const [FoodEntertainment, setFoodEntertainment] = useState("")
    const [LoanPayment, setLoanPayment] = useState("")
    const [EducationCost, setEducationCost] = useState("")
    const [CardRePayment, setCardRePayment] = useState("")
    const [Maintenance, setMaintenance] = useState("")
    const [Overdraft, setOverdraft] = useState("")
    const [Household, setHousehold] = useState("")
    const [Furniture, setFurniture] = useState("")
    const [Other1, setOther1] = useState("")
    const [Clothing, setClothing] = useState("")
    const [Other2, setOther2] = useState("")
    const [PaymentPolicy, setPaymentPolicy] = useState("")
    const [Other3, setOther3] = useState("")
    const [TotalExpenditure, setTotalExpenditure] = useState("")
    const [TransactionNumber, setTransactionNumber] = useState("")
    const [AccountNumber, setAccountNumber] = useState("")
    const [previousBranch, setpreviousBranch] = useState("")
    const [Installment, setInstallment] = useState("")
    const [ToBeSealed3, setToBeSealed3] = useState(786)
    const [Settlement, setSettlement] = useState("")
    const [MonthLeft, setMonthLeft] = useState("")
    const [OwnHome, setOwnHome] = useState(0)
    const [RentalValue, setRentalValue] = useState("")
    const [LandlordName, setLandlordName] = useState("")
    const [LandlordAddress, setLandlordAddress] = useState("")
    const [MonthlyBond, setMonthlyBond] = useState("")
    const [BondHolder, setBondHolder] = useState("")
    const [PurchasePrice, setPurchasePrice] = useState("")
    const [MarketValue, setMarketValue] = useState("")
    const [PovertyName, setPovertyName] = useState("")
    const [BondAmount, setBondAmount] = useState("")
    const [StandNumber, setStandNumber] = useState("")
    const [Suburb, setSuburb] = useState("")
    const [PurchaseDate, setPurchaseDate] = useState("")
    const [Surplus, setSurplus] = useState("")
    const [ClientsFinanceDetSave, setClientsFinanceDetSave] = useState("")
    const [FiClientId, setFiClientId] = useState(0);
    const [IncomeCategoryColor, setIncomeCategoryColor] = useState("");
    const [MonthLeftColor, setMonthLeftColor] = useState("");
    const [OwnHomeColor, setOwnHomeColor] = useState("");
    const [HiddenSurplus, setHiddenSurplus] = useState("");
    const [MonthinstallReadonly, setMonthinstallReadonly] = useState("");
    const [MonthinstallReadonly1, setMonthinstallReadonly1] = useState("");

    const [RentalValueBorder, setRentalValueBorder] = useState("");
    const [BondMonthlyBorder, setBondMonthlyBorder] = useState("");
    const [DefectSurpulse, setDefectSurpulse] = useState("SURPLUS");
    const [HiddenTotalIncome, setHiddenTotalIncome] = useState("")

    const [Last6MonthStmtSubmitted, setLast6MonthStmtSubmitted] = useState("786");
    const [FinancialsSubmitted, setFinancialsSubmitted] = useState("786");
    const [PeriodSselfEmployee, setPeriodSselfEmployee] = useState("");
    const [FndClient, setFndClient] = useState("786");
    const [SelfEmpHideShow, setSelfEmpHideShow] = useState("none");

    //----------------------- LOAN SPECIFIES ---------------------------------------------------
    const [LoanTransaction, setLoanTransaction] = useState("")
    const [LoanTransactionDisplay, setLoanTransactionDisplay] = useState("")
    const [CommencementDate, setCommencementDate] = useState("")
    const FinalCommencementDate = CommencementDate != '' ? moment(CommencementDate).format("YYYY-MM-DD") : 0;
    const [InterestType, setInterestType] = useState("")
    const [FirstDebitOrder, setFirstDebitOrder] = useState("")
    const FinalFirstDebitOrder = FirstDebitOrder != '' ? moment(FirstDebitOrder).format("YYYY-MM-DD") : 0;
    const [SaleType, setSaleType] = useState("")
    const [InterestRate, setInterestRate] = useState("")
    const [PreArrangeSceme, setPreArrangeSceme] = useState(false)
    const [BankFee, setBankFee] = useState("")
    const [LoanEquity, setLoanEquity] = useState("")
    const [LoanAffordability, setLoanAffordability] = useState("")
    const [LoanDepositPercentage, setLoanDepositPercentage] = useState("")
    const [LoanAfforabilityInsurance, setLoanAfforabilityInsurance] = useState("")
    const [LoanAssetAmount, setLoanAssetAmount] = useState("")
    const [LoanInsuranceProduct, setLoanInsuranceProduct] = useState("")
    const [LoanNonInsuranceProduct, setLoanNonInsuranceProduct] = useState("")
    const [LoanCompersiveInsurance, setLoanCompersiveInsurance] = useState("")
    const [LoanDiscount, setLoanDiscount] = useState("")
    const [LoanCashDepositValue, setLoanCashDepositValue] = useState("")
    const [LoanNettTradeDeposit, setLoanNettTradeDeposit] = useState("")
    const [LoanTransactionFee, setLoanTransactionFee] = useState("")
    const [LoanFinanceAmount, setLoanFinanceAmount] = useState("")
    const [LoanSpecificsDetSave, setLoanSpecificsDetSave] = useState("")
    const [LoanCommencementFlag, setLoanCommencementFlag] = useState(0);

    //------------------------- SPOUSE DETAILS -----------------------------------------------
    const [SpouseFirstName, setSpouseFirstName] = useState("")
    const [SpouseInitial, setSpouseInitial] = useState("")
    const [SpouseSurname, setSpouseSurname] = useState("")
    const [SpouseIdNumber, setSpouseIdNumber] = useState("")
    const [SpouseDOB, setSpouseDOB] = useState("")
    const FinalSpouseDOB = SpouseDOB != '' ? moment(SpouseDOB).format("YYYY-MM-DD") : 0;
    const [SpouseHomePhone, setSpouseHomePhone] = useState("")
    const [SpouseWorkPhone, setSpouseWorkPhone] = useState("")
    const [SpouseMobilePhone, setSpouseMobilePhone] = useState("")
    const [SpouseEmail, setSpouseEmail] = useState("")
    const [SpousePhysicalAdd1, setSpousePhysicalAdd1] = useState("")
    const [SpousePhysicalAdd2, setSpousePhysicalAdd2] = useState("")
    const [SpousePostalAdd1, setSpousePostalAdd1] = useState("")
    const [SpousePostalAdd2, setSpousePostalAdd2] = useState("")
    const [SpousePhysicalAddCity, setSpousePhysicalAddCity] = useState("")
    const [SpousePostalAddCity, setSpousePostalAddCity] = useState("")
    const [SpouseOccupation, setSpouseOccupation] = useState("")
    const [SpouseEmployerName, setSpouseEmployerName] = useState("")
    const [SpouseEmployerAdd, setSpouseEmployerAdd] = useState("")
    const [SpouseDate, setSpouseDate] = useState("")
    const FinalSpouseDate = SpouseDate != '' ? moment(SpouseDate).format("YYYY-MM-DD") : 0;
    const [SpouseIncome, setSpouseIncome] = useState("")
    const [SpouseDetSave, setSpouseDetSave] = useState("")

    const [ValidateColor, setValidateColor] = useState("");
    const [ValidateColor1, setValidateColor1] = useState("");
    const [ValidateColor2, setValidateColor2] = useState("");
    const [ClientDetSave, setClientDetSave] = useState([]);
    const [SpouseId, setSpouseId] = useState("");

    const [PrintButton, setPrintButton] = useState("");

    const [div_id1, setHide1] = useState("table");
    const [div_id2, setHide2] = useState("none");
    const [div_id3, setHide3] = useState("none");
    const [div_id4, setHide4] = useState("none");
    const [div_id5, setHide5] = useState("none");
    const [div_id6, setHide6] = useState("none");
    const [tabWidth, settabWidth] = useState("800");

    const [PropertyHideShow, setPropertyHideShow] = useState("");
    const [PropertyHideShow1, setPropertyHideShow1] = useState("none");
    const [Loan1HideShow, setLoan1HideShow] = useState("none");
    const [Loan2HideShow, setLoan2HideShow] = useState("none");
    const [OtherExistingHideShow, setOtherExistingHideShow] = useState("");
    const [ExpenditureHideShow, setExpenditureHideShow] = useState("");
    const [PrintButtonVisible, setPrintButtonVisible] = useState(false);
    
    const division_id = localStorage.getItem("division_id");
    const department_id = localStorage.getItem("department_id");
    const system_type = localStorage.getItem("system_type");
    const user_id = localStorage.getItem("UserId");
    const transaction_id = localStorage.getItem("transaction_id");
    const client_id = localStorage.getItem("client_id");
    const group_id = localStorage.getItem("group_id");
    const application_id = localStorage.getItem("application_id");
    const instance_id = localStorage.getItem("instance_id");
    const company_id = localStorage.getItem("company_id");
    const loanhouse_id = localStorage.getItem("loanhouse_id");
    const BankGaboroneTabId = localStorage.getItem("BankGaboroneTabId");

    useEffect(() => {
        document.title = 'Transactions | Seriti BW';
    }, []);

    const OnchangeEmpCateWiseHideSHow = (type) => {
        // console.log(type);
        if(type == 795){
             setSelfEmpHideShow("")
        }else{
             setSelfEmpHideShow("none")
        }
    }

    //----------- featch client details data-----------------
    useEffect(() => {
        setLoader(true); 
        const payload = {
            transaction_id: transaction_id,
            instance_id: instance_id,
            comp_id: FiClientId,
        };
        axios
            .post("finance_application/featch_finance_client_details", payload)
            .then((response) => {
                // console.log(response);
                if (response.status === 200) {
                    const obj = response.data.data.client_details_array;
                    // setClientDetailsArray(obj);
                    setFirstname(obj.firstname);
                    setClientDetails(obj.client_details);
                    setTitle(obj.title);
                    setInitials(obj.initials);
                    setSurname(obj.surname);
                    setIDNumber(obj.id_number);
                    setHomeNumber(obj.home_number);
                    setMobileNumber(obj.mobile_number);
                    setWorkNumber(obj.work_number);
                    setFixNumber(obj.fax_number);
                    setEmail(obj.email);
                    setPhysicalAddress1(obj.physical_address_lane1);
                    setPhysicalAddress2(obj.physical_address_lane2);
                    setPostalAddress1(obj.postal_address_lane1);
                    setPostalAddress2(obj.postal_address_lane2);
                    setPhysicalAddressCity(obj.physical_address_city);
                    setPostalAddressCity(obj.postal_address_city);
                    setGuarantor(obj.guarantor_application);
                    setResidentialStatus(obj.residential_status);
                    setResidencyStatus(obj.residancy_status);
                    setGender(obj.gender);
                    setMaritalStatus(obj.marial_status);
                    setHomeLanguage(obj.home_language);
                    setCommunicationLanguage(obj.communication_language);
                    setGuarantor(obj.guarantor_application);
                    setDependant(obj.number_of_dependent);
                    setDateOfBirth(obj.date_of_birth);
                    setOccupancyCurrentDate(obj.occupancy_date);
                    setOccupancyPreviousDate(obj.occupancy_prev_date);
                    setMarriedDate(obj.date_married);
                }
                setLoader(false);
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
    }, [FiClientId]);

    const OnClientWiseChange = () => {
        // console.log(ClientDetails);
        if (ClientDetails != 0) {
            setFirstname(""); setClientDetails(""); setTitle(""); setInitials(""); setSurname("");
            setIDNumber(""); setHomeNumber(""); setMobileNumber(""); setWorkNumber("");
            setFixNumber(""); setEmail(""); setPhysicalAddress1(""); setPhysicalAddress2("");
            setPostalAddress1(""); setPostalAddress2(""); setPhysicalAddressCity(""); setPostalAddressCity("");
            setResidentialStatus(""); setResidencyStatus(""); setGender(""); setMaritalStatus("");
            setHomeLanguage(""); setCommunicationLanguage(""); setGuarantor(""); setDependant("");
            setDateOfBirth(""); setOccupancyCurrentDate(""); setOccupancyPreviousDate(""); setMarriedDate("");

        } else {
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                comp_id: FiClientId,
            };
            // console.log(payload);
            axios
                .post("finance_application/featch_finance_client_details", payload)
                .then((response) => {
                    if (response.status === 200) {
                        const obj = response.data.data.client_details_array;
                        // setClientDetailsArray(obj);
                        setFirstname(obj.firstname);
                        setClientDetails(obj.client_details);
                        setTitle(obj.title);
                        setInitials(obj.initials);
                        setSurname(obj.surname);
                        setIDNumber(obj.id_number);
                        setHomeNumber(obj.home_number);
                        setMobileNumber(obj.mobile_number);
                        setWorkNumber(obj.work_number);
                        setFixNumber(obj.fax_number);
                        setEmail(obj.email);
                        setPhysicalAddress1(obj.physical_address_lane1);
                        setPhysicalAddress2(obj.physical_address_lane2);
                        setPostalAddress1(obj.postal_address_lane1);
                        setPostalAddress2(obj.postal_address_lane2);
                        setPhysicalAddressCity(obj.physical_address_city);
                        setPostalAddressCity(obj.postal_address_city);
                        setResidentialStatus(obj.residential_status);
                        setResidencyStatus(obj.residancy_status);
                        setGender(obj.gender);
                        setMaritalStatus(obj.marial_status);
                        setHomeLanguage(obj.home_language);
                        setCommunicationLanguage(obj.communication_language);
                        setGuarantor(obj.guarantor_application);
                        setDependant(obj.number_of_dependent);
                        setDateOfBirth(obj.date_of_birth);
                        setOccupancyCurrentDate(obj.occupancy_date);
                        setOccupancyPreviousDate(obj.occupancy_prev_date);
                        setMarriedDate(obj.date_married);
                    }
                })
                .catch(() => {
                    console.log('error');
                });
        }

    }

    useEffect(() => {
        const payload = {
            instance_id: instance_id,
        };
        axios
            .post("finance_application/featch_client_details_id", payload)
            .then((response) => {
                //console.log(response);
                if (response.status === 200) {
                    setFiClientId(response.data.data);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- add/edit client data-----------------
    const handleClientDetailsValidation = () => {
        var flag = true;
        if (ClientDetailFlag) {
            if (Firstname === '') {
                alert('First Name field is mandatory!');
                setValidateColor("red");
                setValidateColor1("");
                setValidateColor2("");
                flag = false;
            } else if (Surname === '') {
                alert('Surname field is mandatory!');
                setValidateColor1("red");
                setValidateColor("");
                setValidateColor2("");
                flag = false;
            } else if (Email === '') {
                alert('Email field is mandatory!');
                setValidateColor2("red");
                setValidateColor1("");
                setValidateColor("");
                flag = false;
            }
        }
        return flag;
    }
    //----------- Add/Edit client details data-----------------
    const handleClientDetailsSubmit = (e) => {
        e.preventDefault();
        const flag = handleClientDetailsValidation();
        if (flag) {
            setLoader(true);  
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                user_id: user_id,
                form_id: 25,      // wes bank
                group_id: group_id,
                company_id: company_id,
                application_id: application_id,
                command: ClientDetSave,
                sub_1_field_930: ClientDetails,
                sub_1_field_931: Title,
                sub_1_field_932: Firstname,
                sub_1_field_1048: Initials,
                sub_1_field_933: Surname,
                sub_1_field_934: IDNumber,
                sub_1_field_935: FinalDateOfBirth,
                sub_1_field_945: Gender,
                sub_1_field_946: MaritalStatus,
                sub_1_field_1038: ResidencyStatus,
                sub_1_field_936: HomeNumber,
                sub_1_field_938: WorkNumber,
                sub_1_field_939: MobileNumber,
                sub_1_field_1527: FixNumber,
                sub_1_field_940: Email,
                sub_1_field_1497: PhysicalAddress1,
                sub_1_field_1500: PostalAddress1,
                sub_1_field_1498: PhysicalAddress2,
                sub_1_field_1501: PostalAddress2,
                sub_1_field_1511: PhysicalAddressCity,
                sub_1_field_1513: PostalAddressCity,
                sub_1_field_1466: FinalMarriedDate,
                sub_1_field_1047: HomeLanguage,
                sub_1_field_1045: CommunicationLanguage,
                sub_1_field_949: FinalOccupancyCurrentDate,
                sub_1_field_950: FinalOccupancyPreviousDate,
                sub_1_field_1467: Guarantor,
                sub_1_field_951: ResidentialStatus,
                sub_1_field_1535: Dependant,
            };
            //console.log(payload);
            axios
                .post("finance_application/add_edit_finance_client_details", payload)
                .then((response) => {
                    //console.log(response);
                    if (response.status === 200) {
                        if (response.data.data == "Save") {
                            //window.location.reload(false);
                            toast.success(response.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                        } else if (response.data.data == "Save and Exit") {
                            navigate('/transaction/create_transaction/' + transaction_id);
                        }else if(response.data.data == "Print" || response.data.data == "View Finance App"){
                            GeneratePrintPdf();
                        }
                        
                    }
                    setLoader(false);  
                })
                .catch(() => {
                    console.log('error');
                    setLoader(false);  
                });
        }
    };

    useEffect(() => {
        const payload = {
            instance_id: instance_id,
            sub_1_field_1533: FinalCommencementDate,
            sub_1_field_1510: FinalFirstDebitOrder,
        };
        axios
            .post("finance_application/featch_commencement_date_validate", payload)
            .then((response) => {
                //console.log(response);
                setLoanCommencementFlag(response.data.data);
            })
            .catch(() => {
                console.log('error');
            });
    }, [FinalCommencementDate, FinalFirstDebitOrder]);

    //----------- Add/Edit Loan specifics details data-----------------
    const handleLoanDetailsValidation = () => {
        var flag = true;
        if ((InterestRate === "") || (parseInt(InterestRate) === 0)) {
            alert("Please ensure the amount is greater than 0.")
            flag = false;
        } else if ((LoanCommencementFlag === 1)) {
            alert("Commencement date cannot be less than the current date")
            flag = false;
        }
        return flag;
    }

    const handleLoanDetailsSubmit = (e) => {
        e.preventDefault();
        const flag = handleLoanDetailsValidation();
        if (flag) {
            setLoader(true);  
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                user_id: user_id,
                form_id: 48,
                group_id: group_id,
                company_id: company_id,
                application_id: application_id,
                command: LoanSpecificsDetSave,
                sub_1_field_1028: transaction_id,
                sub_1_field_1533: FinalCommencementDate,
                sub_1_field_1247: InterestType,
                sub_1_field_1510: FinalFirstDebitOrder,
                sub_1_field_1246: SaleType,
                sub_1_field_1509: FinanceType,
                sub_1_field_1031: TermMonth,
                sub_1_field_1030: InterestRate,
                sub_1_field_1872: PreArrangeSceme ? 1 : 0,
                sub_1_field_1456: LoanEquity,
                sub_1_field_1457: LoanAffordability,
                sub_1_field_1459: LoanDepositPercentage,
                sub_1_field_1458: LoanAfforabilityInsurance,
                sub_1_field_1813: BusinessSegment,
                sub_1_field_1810: ConsumerSegment,
                sub_1_field_1039: LoanAssetAmount,
                sub_1_field_1450: LoanInsuranceProduct,
                sub_1_field_1451: LoanNonInsuranceProduct,
                sub_1_field_1452: LoanCompersiveInsurance,
                sub_1_field_1042: LoanDiscount,
                sub_1_field_1043: LoanCashDepositValue,
                sub_1_field_1449: LoanNettTradeDeposit,
                sub_1_field_1448: BankFee,
                sub_1_field_1453: LoanTransactionFee,
                sub_1_field_1029: LoanFinanceAmount,
            };
            //console.log(payload);
            axios
                .post("finance_application/add_edit_loan_specifics_details", payload)
                .then((response) => {
                    //console.log(response);
                    if (response.status === 200) {
                        if (response.data.data == "Save") {
                            //window.location.reload(false); 
                            toast.success(response.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            setPrintButtonVisible(true);
                        } else if (response.data.data == "Save and Exit") {
                            navigate('/transaction/create_transaction/' + transaction_id);
                        }else if(response.data.data == "Print" || response.data.data == "View Finance App"){
                            GeneratePrintPdf();
                        }
                    }
                    setLoader(false);  
                })
                .catch(() => {
                    console.log('error');
                    setLoader(false);  
                });
        }
    };

    //----------- featch spouse details --------------
    useEffect(() => {
        const payload = {
            instance_id: instance_id,
            company_id: company_id,
            spouse_id: SpouseDetails,
        };
        axios
            .post("finance_application/featch_spouse_details", payload)
            .then((response) => {
                //console.log(response);
                if (response.status === 200) {
                    const obj = response.data.data.spouse_details_array;
                    setSpouseDetails(obj.spouse_details); setSpouseTitle(obj.title)
                    setSpouseFirstName(obj.firstname); setSpouseInitial(obj.initials);
                    setSpouseSurname(obj.surname); setSpouseIdNumber(obj.id_number);
                    setSpouseDOB(obj.date_of_birth); setSpouseGender(obj.gender);
                    setSpouseHomePhone(obj.home_phone); setSpouseWorkPhone(obj.work_phone);
                    setSpouseMobilePhone(obj.mobile_phone); setSpouseEmail(obj.email);
                    setSpousePhysicalAdd1(obj.physical_address_lane1); setSpousePostalAdd1(obj.postal_address_lane1);
                    setSpousePhysicalAdd2(obj.physical_address_lane2); setSpousePostalAdd2(obj.postal_address_lane2);
                    setSpousePhysicalAddCity(obj.physical_address_city); setSpousePostalAddCity(obj.postal_address_city);
                    setSpouseResidencyStatus(obj.residancy_status); setSpouseOccupation(obj.occupation);
                    setSpouseEmployerName(obj.employer_name); setSpouseEmployerAdd(obj.employer_address);
                    setSpouseDate(obj.date_employed); setSpouseIncome(obj.spouse_nett_income);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, [SpouseDetails]);

    useEffect(() => {
        const payload = {
            instance_id: instance_id,
            company_id: company_id,
            spouse_id: SpouseId,
        };
        axios
            .post("finance_application/featch_spouse_details", payload)
            .then((response) => {
                // console.log(response.data.data.spouse_details_array);
                if (response.status === 200) {
                    const obj = response.data.data.spouse_details_array;
                    setSpouseDetails(obj.spouse_details); setSpouseTitle(obj.title)
                    setSpouseFirstName(obj.firstname); setSpouseInitial(obj.initials);
                    setSpouseSurname(obj.surname); setSpouseIdNumber(obj.id_number);
                    setSpouseDOB(obj.date_of_birth); setSpouseGender(obj.gender);
                    setSpouseHomePhone(obj.home_phone); setSpouseWorkPhone(obj.work_phone);
                    setSpouseMobilePhone(obj.mobile_phone); setSpouseEmail(obj.email);
                    setSpousePhysicalAdd1(obj.physical_address_lane1); setSpousePostalAdd1(obj.postal_address_lane1);
                    setSpousePhysicalAdd2(obj.physical_address_lane2); setSpousePostalAdd2(obj.postal_address_lane2);
                    setSpousePhysicalAddCity(obj.physical_address_city); setSpousePostalAddCity(obj.postal_address_city);
                    setSpouseResidencyStatus(obj.residancy_status); setSpouseOccupation(obj.occupation);
                    setSpouseEmployerName(obj.employer_name); setSpouseEmployerAdd(obj.employer_address);
                    setSpouseDate(obj.date_employed); setSpouseIncome(obj.spouse_nett_income);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, [SpouseId]);


    useEffect(() => {
        const payload = {
            instance_id: instance_id,
        };
        axios
            .post("finance_application/featch_spouse_details_id", payload)
            .then((response) => {
                //console.log(response);
                if (response.status === 200) {
                    setSpouseId(response.data.data);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    const handleSpouseDetailsValidation = () => {
        var flag = true;
        if (SpouseDetails == 0) {
            alert('Please select spouse details!');
            flag = false;
        }
        return flag;
    }

    //----------- Add/Edit spouse details data-----------------
    const handleSpouseDetailsSubmit = (e) => {
        e.preventDefault();
        const flag = handleSpouseDetailsValidation();
        if (flag) {
            setLoader(true);  
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                user_id: user_id,
                form_id: 45,
                group_id: group_id,
                company_id: company_id,
                application_id: application_id,
                command: SpouseDetSave,
                sub_1_field_952: SpouseDetails,
                sub_1_field_954: SpouseTitle,
                sub_1_field_955: SpouseFirstName,
                sub_1_field_1075: SpouseInitial,
                sub_1_field_956: SpouseSurname,
                sub_1_field_957: SpouseIdNumber,
                sub_1_field_958: FinalSpouseDOB,
                sub_1_field_965: SpouseGender,
                sub_1_field_959: SpouseHomePhone,
                sub_1_field_960: SpouseWorkPhone,
                sub_1_field_961: SpouseMobilePhone,
                sub_1_field_962: SpouseEmail,
                sub_1_field_1503: SpousePhysicalAdd1,
                sub_1_field_1506: SpousePostalAdd1,
                sub_1_field_1504: SpousePhysicalAdd2,
                sub_1_field_1507: SpousePostalAdd2,
                sub_1_field_1514: SpousePhysicalAddCity,
                sub_1_field_1515: SpousePostalAddCity,
                sub_1_field_947: SpouseResidencyStatus,
                sub_1_field_1468: SpouseOccupation,
                sub_1_field_1469: SpouseEmployerName,
                sub_1_field_1470: SpouseEmployerAdd,
                sub_1_field_1471: FinalSpouseDate,
                sub_1_field_994: SpouseIncome,
            };
            //console.log(payload);
            axios
                .post("finance_application/add_edit_spouse_details", payload)
                .then((response) => {
                    if (response.status === 200) {
                        //window.location.reload(false);
                        if (response.data.data == "Save") {
                            //window.location.reload(false); 
                            toast.success(response.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                        } else if (response.data.data == "Save and Exit") {
                            navigate('/transaction/create_transaction/' + transaction_id);
                        }else if(response.data.data == "Print" || response.data.data == "View Finance App"){
                            GeneratePrintPdf();
                        }
                    }
                    setLoader(false);  
                })
                .catch(() => {
                    console.log('error');
                    setLoader(false);  
                });
        }
    };

    //----------- featch clients finance details / loan specifics details data-----------------
    useEffect(() => {
        const payload = {
            transaction_id: transaction_id,
            instance_id: instance_id,
            company_id: company_id,
        };
        axios
            .post("finance_application/featch_clients_finance_loan_specifics_details", payload)
            .then((response) => {
                //console.log(response.data.data.clients_finance_details_array);
                if (response.status === 200) {
                    const obj = response.data.data.clients_finance_details_array;
                    setTransactionNumber(obj.transaction); setIncomeCategory(obj.income_category);
                    setGrossMonthlySalary(obj.monthly_basic_salary); setCommission6MonthAvg(obj.commission_six_month)
                    setOvertime6MonthAvg(obj.overtime); setMonthlyAllowance(obj.monthly_other_allowance);
                    setMonthlyCarAllowance(obj.monthly_car_allowance); setPaySlipMAllowance(obj.net_monthly_income);
                    setAllOtherIncome(obj.all_other_income); setTotalIncome(obj.total_income);

                    setExistingLoan(obj.other_exsting_loans); setExpenditure(obj.expenditure);
                    setToBeSealed1(obj.tobe_settled_loan1); setFinantialProviderLoan1(obj.finance_provider_loan1);
                    setMonthlyInvestment1(obj.monthly_installment_loan1); setToBeSealed2(obj.tobe_settled_loan2);
                    setFinantialProviderLoan2(obj.finance_provider_loan2); setMonthlyInvestment2(obj.monthly_installment_loan2);
                    
                    if(obj.income_category == 795){
                        setSelfEmpHideShow("")
                    }else{
                        setSelfEmpHideShow("none")
                    }

                    if (obj.tobe_settled_loan1 != '' && obj.tobe_settled_loan1 == 786) {
                        setMonthinstallReadonly("");
                        setLoan1HideShow("none");
                    } else if (obj.tobe_settled_loan1 != '' && obj.tobe_settled_loan1 == 785) {
                        setMonthinstallReadonly("none");
                        setLoan1HideShow("");
                    } else {
                        setMonthinstallReadonly("");
                        setLoan1HideShow("none");
                    }

                    if (obj.tobe_settled_loan2 != '' && obj.tobe_settled_loan2 == 786) {
                        setMonthinstallReadonly1("");
                        setLoan2HideShow("none");
                    } else if (obj.tobe_settled_loan2 != '' && obj.tobe_settled_loan2 == 785) {
                        setMonthinstallReadonly1("none");
                        setLoan2HideShow("");
                    } else {
                        setMonthinstallReadonly1("");
                        setLoan2HideShow("none");
                    }

                    setRentAmount(obj.rent_amount); setTelephone(obj.telephone); setRateTaxWater(obj.rate_tax);
                    setFuel(obj.fuel); setVehicleInstallment(obj.vehicle_installments);
                    setFoodEntertainment(obj.food_entertentment); setLoanPayment(obj.personal_loan);
                    setEducationCost(obj.education_cost); setCardRePayment(obj.credit_card_payments);
                    setMaintenance(obj.maintenance); setOverdraft(obj.overdraft_repayment);
                    setHousehold(obj.household_expenses); setFurniture(obj.furniture_account_payment);
                    setOther1(obj.other1); setClothing(obj.clothing_account_payment);
                    setOther2(obj.other2); setPaymentPolicy(obj.policy_payment);
                    setOther3(obj.other3); setTotalExpenditure(obj.total_expenditure);
                    setFinantialCompany(obj.existing_previous_vehicle);
                    setAccountNumber(obj.account_number); setpreviousBranch(obj.finance_company_branch);
                    setToBeSealed3(obj.pre_tobe_settled); setMonthLeft(obj.months_left);

                    if (obj.instalment != '' && obj.instalment != 0) {
                        setInstallment(obj.instalment);
                    } else {
                        setInstallment('0.00');
                    }
                    if (obj.settlement != '' && obj.settlement != 0) {
                        setSettlement(obj.settlement);
                    } else {
                        setSettlement('0.00');
                    }

                    setOwnHome(obj.own_your_home);
                    if (obj.own_your_home == '786') {
                        setPropertyHideShow("");
                        setPropertyHideShow1("none");
                    } else if (obj.own_your_home == '785') {
                        setPropertyHideShow1("");
                        setPropertyHideShow("none");
                    } else {
                        setPropertyHideShow("none");
                    }

                    setRentalValue(obj.lodging_value);
                    setLandlordName(obj.landlord_name); setLandlordAddress(obj.landlord_address);
                    setMonthlyBond(obj.bond_monthly_installement); setBondHolder(obj.bond_holder_bank);
                    setPurchasePrice(obj.purchase_price); setPurchaseDate(obj.purchase_date);
                    setMarketValue(obj.present_market_value); setPovertyName(obj.in_whose_name);
                    setBondAmount(obj.outstanding_bond_amount); setStandNumber(obj.stand_number);
                    setSuburb(obj.subrub); setSurplus(obj.surplus);

                    if (obj.expenditure == '785') {
                        setExpenditureHideShow("");
                    } else {
                        setExpenditureHideShow("none");
                    }

                    if (obj.other_exsting_loans == '785') {
                        setOtherExistingHideShow("");
                    } else {
                        setOtherExistingHideShow("none");
                    }
                    setHiddenTotalIncome(obj.hidden_total_income); setHiddenSurplus(obj.hidden_surplus);
                    setPeriodSselfEmployee(obj.perod_self_employee); setLast6MonthStmtSubmitted(obj.financtial_submit);
                    setFinancialsSubmitted(obj.last_6_month_statement); setFndClient(obj.fnb_client)

                     //------------ onload total expenditure

                    var rent_amount1 = obj.rent_amount.replace(/,(?=.*\.\d+)/g, ''); 
                    var telephone1   = obj.telephone.replace(/,(?=.*\.\d+)/g, '');
                    var rate_tax1    = obj.rate_tax.replace(/,(?=.*\.\d+)/g, '');
                    var fuel1        = obj.fuel.replace(/,(?=.*\.\d+)/g, '');
                    var vehicle_installments1 = obj.vehicle_installments.replace(/,(?=.*\.\d+)/g, '');
                    var food_entertentment1   = obj.food_entertentment.replace(/,(?=.*\.\d+)/g, '');
                    var personal_loan1        = obj.personal_loan.replace(/,(?=.*\.\d+)/g, '');
                    var education_cost1       = obj.education_cost.replace(/,(?=.*\.\d+)/g, '');
                    var credit_card_payments1 = obj.credit_card_payments.replace(/,(?=.*\.\d+)/g, '');
                    var maintenance1          = obj.maintenance.replace(/,(?=.*\.\d+)/g, '');
                    var overdraft_repayment1  = obj.overdraft_repayment.replace(/,(?=.*\.\d+)/g, '');
                    var household_expenses1   = obj.household_expenses.replace(/,(?=.*\.\d+)/g, '');
                    var furniture_account_payment1 = obj.furniture_account_payment.replace(/,(?=.*\.\d+)/g, '');
                    var other1                     = obj.other1.replace(/,(?=.*\.\d+)/g, '');
                    var clothing_account_payment1  = obj.clothing_account_payment.replace(/,(?=.*\.\d+)/g, '');
                    var other2                     = obj.other2.replace(/,(?=.*\.\d+)/g, '');
                    var policy_payment1            = obj.policy_payment.replace(/,(?=.*\.\d+)/g, '');
                    var other3                     = obj.other3.replace(/,(?=.*\.\d+)/g, '');
                    
                    var onload_total_expenditure = Number(rent_amount1)+Number(telephone1)+Number(rate_tax1)+Number(fuel1)+Number(vehicle_installments1)+Number(food_entertentment1)+Number(personal_loan1)+Number(education_cost1)+Number(credit_card_payments1)+Number(maintenance1)+Number(overdraft_repayment1)+Number(household_expenses1)+Number(furniture_account_payment1)+Number(other1)+Number(clothing_account_payment1)+Number(other2)+Number(policy_payment1)+Number(other3);
                    
                    if(obj.total_expenditure != '0.00'){
                        let final_total_expenditure = onload_total_expenditure.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+'.00';
                        setTotalExpenditure(final_total_expenditure);
                    // console.log(final_total_expenditure);
                    }else{
                        setTotalExpenditure('0.00');
                    }

                    var hidden_total_income1 = obj.hidden_total_income; 
                    var monthly_installment_loan1 = obj.monthly_installment_loan1.replace(/,(?=.*\.\d+)/g, ''); 
                    var monthly_installment_loan2 = obj.monthly_installment_loan2.replace(/,(?=.*\.\d+)/g, ''); 
                
                    if(obj.instalment != '' && obj.instalment != 0){
                        var instalment1 = obj.instalment.replace(/,(?=.*\.\d+)/g, ''); 
                    }else{
                        var instalment1 = '0.00';
                    }

                    if(obj.pre_tobe_settled == 786){
                        var instalment1 = obj.instalment.replace(/,(?=.*\.\d+)/g, ''); 
                    }else if(obj.pre_tobe_settled == 785 || obj.pre_tobe_settled == '0.00'){
                        var instalment1 = '0.00';
                    }

                    var lodging_value1 = obj.lodging_value.replace(/,(?=.*\.\d+)/g, ''); 
                    var bond_monthly_installement1 = obj.bond_monthly_installement.replace(/,(?=.*\.\d+)/g, ''); 
            
                    var new_surplus = Number(hidden_total_income1)-Number(onload_total_expenditure)-Number(monthly_installment_loan1)-Number(monthly_installment_loan2)-Number(instalment1)-Number(lodging_value1)-Number(bond_monthly_installement1);
                    // console.log(new_surplus);
                    let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    setSurplus(final_surplus);
                    setHiddenSurplus(new_surplus);

                    //-------------------- loan specifics details -----------------
                    const obj1 = response.data.data.loan_specifics_details_array;

                    setLoanTransactionDisplay(obj1.transaction); setCommencementDate(obj1.commencement_date);
                    setInterestType(obj1.interest_type); setFirstDebitOrder(obj1.installment_date);
                    setSaleType(obj1.sale_type); setFinanceType(obj1.finance_type);
                    setTermMonth(obj1.term_months); setInterestRate(obj1.prefferd_interest_rate);
                    setPreArrangeSceme(obj1.scheme_pdp); setLoanEquity(obj1.loan_equility);
                    setLoanAffordability(obj1.affordability); setLoanDepositPercentage(obj1.deposit_percentage);
                    setLoanAfforabilityInsurance(obj1.affordability_insurance); setBusinessSegment(obj1.bussiness_segment);
                    setConsumerSegment(obj1.consumers_segment);

                    setLoanAssetAmount(obj1.asset_amount); setLoanInsuranceProduct(obj1.insurance_product);
                    setLoanNonInsuranceProduct(obj1.non_insurance_product); setLoanCompersiveInsurance(obj1.comprehensive_insurance);
                    setLoanDiscount(obj1.discount); setLoanCashDepositValue(obj1.cash_deposit_value);
                    setLoanNettTradeDeposit(obj1.nett_trade_in_deposit_value); setBankFee(obj1.bank_documentation_fee);
                    setLoanTransactionFee(obj1.transaction_fee); setLoanFinanceAmount(obj1.financed_amount);

                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    const handleClientFinanceDetailsValidation = () => {
        var flag = true;
        if (FinancialDetailFlag) {

            if (IncomeCategory == '0') {
                alert('Income Category field is mandatory!');
                setIncomeCategoryColor('red');
                flag = false;
            } else if (MonthLeft === '') {
                alert('Months Left field is mandatory!');
                setMonthLeftColor('red');
                flag = false;
            } else if (OwnHome === '') {
                alert('Please select Do you own your home?');
                setOwnHomeColor('1px solid red');
                flag = false;
            } else if (Surplus === "0.00") {
                alert('Surplus field is mandatory!');
                flag = false;
            } else if (OwnHome == 786) {
                if (RentalValue === "0.00") {
                    alert('Please enter RENTAL/LODGING VALUE amount.');
                    setRentalValueBorder('1px solid red');
                    flag = false;
                }
            } else if (OwnHome == 785) {
                if (MonthlyBond === "0.00") {
                    alert('Please enter BOND MONTHLY INSTALMENT amount.');
                    setBondMonthlyBorder('1px solid red');
                    flag = false;
                }
            }
        }
        return flag;
    }

    //----------- Add/Edit clients finance details data-----------------
    const handleClientFinanceDetailsSubmit = (e) => {
        e.preventDefault();
        const flag = handleClientFinanceDetailsValidation();
        if (flag) {
            setLoader(true);  
            const payload = {
                transaction_id: transaction_id,
                instance_id: instance_id,
                user_id: user_id,
                form_id: 68,      // wes bank
                group_id: group_id,
                company_id: company_id,
                application_id: application_id,
                command: ClientsFinanceDetSave,
                sub_1_field_986: IncomeCategory,
                sub_1_field_1028: transaction_id,
                sub_1_field_988: GrossMonthlySalary,
                sub_1_field_989: Commission6MonthAvg,
                sub_1_field_990: Overtime6MonthAvg,
                sub_1_field_1869: MonthlyAllowance,
                sub_1_field_1868: MonthlyCarAllowance,
                sub_1_field_993: PaySlipMAllowance,
                sub_1_field_996: AllOtherIncome,
                sub_1_field_997: TotalIncome,
                sub_1_field_1708: ExistingLoan,
                sub_1_field_1862: Expenditure,
                sub_1_field_1726: ToBeSealed1,
                sub_1_field_1709: FinantialProviderLoan1,
                sub_1_field_1711: MonthlyInvestment1,
                sub_1_field_1727: ToBeSealed2,
                sub_1_field_1718: FinantialProviderLoan2,
                sub_1_field_1717: MonthlyInvestment2,
                sub_1_field_1822: RentAmount,
                sub_1_field_1823: Telephone,
                sub_1_field_1010: RateTaxWater,
                sub_1_field_1824: Fuel,
                sub_1_field_1825: VehicleInstallment,
                sub_1_field_1826: FoodEntertainment,
                sub_1_field_1827: LoanPayment,
                sub_1_field_1828: EducationCost,
                sub_1_field_1829: CardRePayment,
                sub_1_field_1014: Maintenance,
                sub_1_field_1013: Overdraft,
                sub_1_field_1831: Household,
                sub_1_field_1832: Furniture,
                sub_1_field_1833: Other1,
                sub_1_field_1009: Clothing,
                sub_1_field_1834: Other2,
                sub_1_field_1835: PaymentPolicy,
                sub_1_field_1836: Other3,
                sub_1_field_1842: TotalExpenditure,
                sub_1_field_1475: FinantialCompany,
                sub_1_field_1477: AccountNumber,
                sub_1_field_1476: previousBranch,
                sub_1_field_1478: Installment,
                sub_1_field_1481: ToBeSealed3,
                sub_1_field_1479: Settlement,
                sub_1_field_1480: MonthLeft,
                sub_1_field_1483: OwnHome,
                sub_1_field_1712: RentalValue,
                sub_1_field_1493: LandlordName,
                sub_1_field_1494: LandlordAddress,
                sub_1_field_1019: Surplus,
                sub_1_field_1713: MonthlyBond,
                sub_1_field_1488: BondHolder,
                sub_1_field_1491: PurchasePrice,
                sub_1_field_1489: PurchaseDate,
                sub_1_field_1492: MarketValue,
                sub_1_field_1485: PovertyName,
                sub_1_field_1490: BondAmount,
                sub_1_field_1486: StandNumber,
                sub_1_field_1487: Suburb,
                sub_1_field_1516: PeriodSselfEmployee,
                sub_1_field_1518: Last6MonthStmtSubmitted,
                sub_1_field_1517: FinancialsSubmitted,
                sub_1_field_1519: FndClient,
            };
            // console.log(payload);
            axios
                .post("finance_application/add_edit_clients_finance", payload)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.data == "Save") {
                            //window.location.reload(false); 
                            toast.success(response.data.message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            setBondMonthlyBorder(""); setMonthLeftColor(""); setOwnHomeColor(""); setRentalValueBorder("");
                            setPrintButtonVisible(true);
                        }else if (response.data.data == "Save and Exit") {
                            navigate('/transaction/create_transaction/' + transaction_id);
                        }else if(response.data.data == "Print" || response.data.data == "View Finance App"){
                            GeneratePrintPdf();
                        }
                    }
                    setLoader(false);  
                })
                .catch(() => {
                    console.log('error');
                    setLoader(false);  
                });
        }
    };

    //----------- get marital status-----------------
    useEffect(() => {
        const payload = {
            division_id: division_id,
            transaction_id: transaction_id,
        };
        axios
            .post("add_edit_company/featch_marital_status", payload)
            .then((response) => {
                if (response.status === 200) {
                    setMaritalStatusAll(response.data.data.marital_status_array);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get client title -----------------
    useEffect(() => {
        axios
            .get("add_edit_company/featch_titles")
            .then((response) => {
                // console.log(response.data.data.customer_type_array);
                if (response.status === 200) {
                    setTitleAll(response.data.data.title_array);
                    setContactTitleAll(response.data.data.title_array);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get residency status -----------------
    useEffect(() => {
        axios
            .get("add_edit_company/featch_residency_status")
            .then((response) => {
                if (response.status === 200) {
                    setResidencyStatusAll(response.data.data.residency_status_array);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get residential_status -----------------
    useEffect(() => {
        axios
            .get("add_edit_company/featch_residential_status")
            .then((response) => {
                if (response.status === 200) {
                    setResidentialStatusAll(response.data.data.residential_status);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get home langauage -----------------
    useEffect(() => {
        const payload = {
            division_id: division_id,
        };
        axios
            .post("add_edit_company/featch_correspondace_language", payload)
            .then((response) => {
                if (response.status === 200) {
                    setHomeLanguageAll(response.data.data.home_language);
                    setCommunicationLanguageAll(response.data.data.correspondance_language);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get client details -----------------
    useEffect(() => {
        const payload = {
            client_id: client_id,
        };
        axios
            .post("finance_application/fetach_client_details_dropdown", payload)
            .then((response) => {
                if (response.status === 200) {
                    //console.log(response);
                    setClientDetailsAll(response.data.data);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get gender -----------------
    useEffect(() => {
        axios
            .get("add_edit_company/featch_gender")
            .then((response) => {
                if (response.status === 200) {
                    setGenderAll(response.data.data.gender_array);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get income category / finantial provider / finantial company / term months / finance type / consumer segment / business segment -----------------
    useEffect(() => {
        axios
            .get("finance_application/featch_income_category_finantial_provider")
            .then((response) => {
                if (response.status === 200) {
                    //console.log(response.data.data);
                    setIncomeCategoryAll(response.data.data.income_category);
                    setFinantialProviderAll(response.data.data.finantial_provider);
                    setFinantialCompanyAll(response.data.data.finance_company);
                    setTermMonthAll(response.data.data.term_month);
                    setFinanceTypeAll(response.data.data.finance_type);
                    setConsumerSegmentAll(response.data.data.consumer_segment);
                    setBusinessSegmentAll(response.data.data.business_segment);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get spouse details -----------------
    useEffect(() => { 
        const payload = {
            client_id: client_id,
        };    
        axios
        .post("finance_application/fetach_spouse_details_dropdown",payload)
        .then((response) => {
            if(response.status === 200){
                setSpouseDetailsAll(response.data.data);
            }
        }) 
        .catch(() => {
            console.log('error');
        });
    }, []); 
    
    //----------- get contact details -----------------
    useEffect(() => { 
        const payload = {
            client_id: client_id,
            instance_id: instance_id,
        };    
        axios
        .post("finance_application/fetach_contact_details_dropdown",payload)
        .then((response) => {
            //console.log(response);
            if(response.status === 200){
                setContactDetailsAll(response.data.data.edit_select_data);
                if(response.data.data.other_data === 1){
                    setAddEntryDiplayFlag(1);
                }else{
                    setAddEntryDiplayFlag(0);
                }

            }
        }) 
        .catch(() => {
            console.log('error');
        });
    }, []); 

    //----------- get salary date / relationship (employee details) -----------------
    useEffect(() => {
        axios
            .get("finance_application/featch_salary_date_relationship")
            .then((response) => {
                if (response.status === 200) {
                    setSalaryDateAll(response.data.data.salary_date);
                    setRelationshipAll(response.data.data.relationship);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //------------------- table hide /show
    const ChangeNavigation = (tab) => {
        const CheckValidation = handleClientDetailsValidation();

        if (CheckValidation) { 
            //  console.log(tab)
            if (tab === "Client Details") {  // 1
                setHide1('table'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('none');
                setHide6('none'); settabWidth(800);
                localStorage.setItem("BankGaboroneTabId", 25);
            } else if (tab === "Employer Details") {  // 2
                setHide1('none'); setHide2('table'); setHide3('none'); setHide4('none'); setHide5('none');
                setHide6('none'); settabWidth(1488); //previous width - 1407
                localStorage.setItem("BankGaboroneTabId", 47);
            } else if (tab === "Client's Financial Details") {   // 3
                setHide1('none'); setHide2('none'); setHide3('table'); setHide4('none'); setHide5('none');
                setHide6('none'); settabWidth(800);
                localStorage.setItem("BankGaboroneTabId", 68);
            } else if (tab === "Loan Specifics") {  // 4
                setHide1('none'); setHide2('none'); setHide3('none'); setHide4('table'); setHide5('none');
                setHide6('none'); settabWidth(800);
                localStorage.setItem("BankGaboroneTabId", 48);
            } else if (tab === "Spouse Details") {  // 5
                setHide1('none'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('table');
                setHide6('none'); settabWidth(800);
                localStorage.setItem("BankGaboroneTabId", 45);
            } else if (tab === "Contact Details") {  // 6
                setHide1('none'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('none');
                setHide6('table'); settabWidth(1708);
                localStorage.setItem("BankGaboroneTabId", 67);
            }
        }
    }

    useEffect(() => {
        if (BankGaboroneTabId == 25) {   //client details
            setHide1('table'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('none'); 
            setHide6('none'); settabWidth(800);
        } else if (BankGaboroneTabId == 47) {  //employee details
            setHide1('none'); setHide2('table'); setHide3('none'); setHide4('none'); setHide5('none');
            setHide6('none'); settabWidth(1488);
        }else if (BankGaboroneTabId == 68) {  // client finance details
            setHide1('none'); setHide2('none'); setHide3('table'); setHide4('none'); setHide5('none');
            setHide6('none'); settabWidth(800); 
        } else if (BankGaboroneTabId == 48) {  // loan specifics details
            setHide1('none'); setHide2('none'); setHide3('none'); setHide4('table'); setHide5('none');
            setHide6('none'); settabWidth(800);
        } else if (BankGaboroneTabId == 45) {  // spouse details
            setHide1('none'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('table');
            setHide6('none'); settabWidth(800);
        } else if (BankGaboroneTabId == 67) {  // contact details
            setHide1('none'); setHide2('none'); setHide3('none'); setHide4('none'); setHide5('none');
            setHide6('table'); settabWidth(1708);
        } 

    }, [BankGaboroneTabId]);

    const ChangeForsurpulse = () => {

        if (Math.sign(HiddenSurplus) === -1) {
            setDefectSurpulse("DEFICIT");
        } else {
            setDefectSurpulse("SURPLUS");
        }
    }

    const RemoveZeroVal = (e) => {
        // console.log(e);
        if (e === 1 && GrossMonthlySalary === '0.00') { setGrossMonthlySalary("") }
        if (e === 2 && Commission6MonthAvg === '0.00') { setCommission6MonthAvg("") }
        if (e === 3 && Overtime6MonthAvg === '0.00') { setOvertime6MonthAvg("") }
        if (e === 4 && MonthlyAllowance === '0.00') { setMonthlyAllowance("") }
        if (e === 5 && MonthlyCarAllowance === '0.00') { setMonthlyCarAllowance("") }
        if (e === 6 && PaySlipMAllowance === '0.00') { setPaySlipMAllowance("") }
        if (e === 7 && AllOtherIncome === '0.00') { setAllOtherIncome("") }
        if (e === 8 && TotalIncome === '0.00') { setTotalIncome("") }
        if (e === 9 && MonthlyInvestment1 === '0.00') { setMonthlyInvestment1("") }
        if (e === 10 && MonthlyInvestment2 === '0.00') { setMonthlyInvestment2("") }
        if (e === 11 && Installment === '0.00') { setInstallment("") }
        if (e === 12 && Settlement === '0.00') { setSettlement("") }
        if (e === 13 && RentalValue === '0.00') { setRentalValue("") }
        if (e === 14 && Surplus === '0.00') { setSurplus("") }
        if (e === 21 && RentAmount === '0.00') { setRentAmount("") }
        if (e === 22 && Telephone === '0.00') { setTelephone("") }
        if (e === 23 && RateTaxWater === '0.00') { setRateTaxWater("") }
        if (e === 24 && Fuel === '0.00') { setFuel("") }
        if (e === 25 && VehicleInstallment === '0.00') { setVehicleInstallment("") }
        if (e === 26 && FoodEntertainment === '0.00') { setFoodEntertainment("") }
        if (e === 27 && LoanPayment === '0.00') { setLoanPayment("") }
        if (e === 28 && EducationCost === '0.00') { setEducationCost("") }
        if (e === 29 && CardRePayment === '0.00') { setCardRePayment("") }
        if (e === 30 && Maintenance === '0.00') { setMaintenance("") }
        if (e === 31 && Overdraft === '0.00') { setOverdraft("") }
        if (e === 32 && Household === '0.00') { setHousehold("") }
        if (e === 33 && Furniture === '0.00') { setFurniture("") }
        if (e === 34 && Other1 === '0.00') { setOther1("") }
        if (e === 35 && Clothing === '0.00') { setClothing("") }
        if (e === 36 && Other2 === '0.00') { setOther2("") }
        if (e === 37 && PaymentPolicy === '0.00') { setPaymentPolicy("") }
        if (e === 38 && Other3 === '0.00') { setOther3("") }
        if (e === 39 && TotalExpenditure === '0.00') { setTotalExpenditure("") }

        if (e === 51 && BankFee === '0.00') { setBankFee("") }
        if (e === 52 && SpouseIncome === '0.00') { setSpouseIncome("") }
        if (e === 53 && MonthlyBond === '0.00') { setMonthlyBond("") }
        if (e === 54 && PurchasePrice === '0.00') { setPurchasePrice("") }
        if (e === 55 && MarketValue === '0.00') { setMarketValue("") }
        if (e === 56 && BondAmount === '0.00') { setBondAmount("") }
    }

    const ShowZero = (e) => {
        // alert('dd');
        if (e === 1 && GrossMonthlySalary === '') { setGrossMonthlySalary("0.00") }
        if (e === 2 && Commission6MonthAvg === '') { setCommission6MonthAvg("0.00") }
        if (e === 3 && Overtime6MonthAvg === '') { setOvertime6MonthAvg("0.00") }
        if (e === 4 && MonthlyAllowance === '') { setMonthlyAllowance("0.00") }
        if (e === 5 && MonthlyCarAllowance === '') { setMonthlyCarAllowance("0.00") }
        if (e === 6 && PaySlipMAllowance === '') { setPaySlipMAllowance("0.00") }
        if (e === 7 && AllOtherIncome === '') { setAllOtherIncome("0.00") }
        if (e === 8 && TotalIncome === '') { setTotalIncome("0.00") }
        if (e === 9 && MonthlyInvestment1 === '') { setMonthlyInvestment1("0.00") }
        if (e === 10 && MonthlyInvestment2 === '') { setMonthlyInvestment2("0.00") }
        if (e === 11 && Installment === '') { setInstallment("0.00") }
        if (e === 12 && Settlement === '') { setSettlement("0.00") }
        if (e === 13 && RentalValue === '') { setRentalValue("0.00") }
        if (e === 14 && Surplus === '') { setSurplus("0.00") }
        if (e === 21 && RentAmount === '') { setRentAmount("0.00") }
        if (e === 22 && Telephone === '') { setTelephone("0.00") }
        if (e === 23 && RateTaxWater === '') { setRateTaxWater("0.00") }
        if (e === 24 && Fuel === '') { setFuel("0.00") }
        if (e === 25 && VehicleInstallment === '') { setVehicleInstallment("0.00") }
        if (e === 26 && FoodEntertainment === '') { setFoodEntertainment("0.00") }
        if (e === 27 && LoanPayment === '') { setLoanPayment("0.00") }
        if (e === 28 && EducationCost === '') { setEducationCost("0.00") }
        if (e === 29 && CardRePayment === '') { setCardRePayment("0.00") }
        if (e === 30 && Maintenance === '') { setMaintenance("0.00") }
        if (e === 31 && Overdraft === '') { setOverdraft("0.00") }
        if (e === 32 && Household === '') { setHousehold("0.00") }
        if (e === 33 && Furniture === '') { setFurniture("0.00") }
        if (e === 34 && Other1 === '') { setOther1("0.00") }
        if (e === 35 && Clothing === '') { setClothing("0.00") }
        if (e === 36 && Other2 === '') { setOther2("0.00") }
        if (e === 37 && PaymentPolicy === '') { setPaymentPolicy("0.00") }
        if (e === 38 && Other3 === '') { setOther3("0.00") }
        if (e === 39 && TotalExpenditure === '') { setTotalExpenditure("0.00") }

        if (e === 51 && BankFee === '') { setBankFee("0.00") }
        if (e === 52 && SpouseIncome === '') { setSpouseIncome("0.00") }
        if (e === 53 && MonthlyBond === '') { setMonthlyBond("0.00") }
        if (e === 54 && PurchasePrice === '') { setPurchasePrice("0.00") }
        if (e === 55 && MarketValue === '') { setMarketValue("0.00") }
        if (e === 56 && BondAmount === '') { setBondAmount("0.00") }

    }

    //--------------------- featch employee details -------------
    useEffect(() => {
        const payload = {
            instance_id: instance_id,
        };
        axios
            .post("finance_application/featch_finance_employee_details", payload)
            .then((response) => {
                //console.log(response);
                if (response.status === 200) {
                    //console.log(response.data.data.select_array);
                    setEmployeeDetailsArray(response.data.data.select_array);
                    setEmployeeDetailsEditArray(response.data.data.edit_array);
                    setEmployeeDetailsCheckboxEditArray(response.data.data.checkbox_edit_array);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, [EmpDipFlag]);

    //-------------- add/edit employee details
    const EmployeeSalaryValidation = () => {
        var flag = true;

        if(EmployeeDetailsArray != ''){      // edit array validation
            for(var i=0; i<EmployeeDetailsArray.length; i++){
                if(EmployeeDetailsArray[i]['salary_date'] == ""){
                    alert("Salary Date field is mandatory");
                    flag = false;
                }
            }
        }
        
        if (SalaryDateFlag == 1) {
            alert("Salary Date field is mandatory");
            setcolorFocus('red');
            flag = false;
        }
        return flag;
    }

    const handleAddEmployee = (e) => {
        e.preventDefault();
        const flag1 = EmployeeSalaryValidation();
        if (flag1) {
            if (handleFlag == 0) {
                setLoader(true);  
                const payload = {
                    transaction_id: transaction_id,
                    instance_id: instance_id,
                    user_id: user_id,
                    form_id: 47,
                    group_id: group_id,
                    company_id: company_id,
                    application_id: application_id,
                    command: EmployeeDetailsSave,
                    edit_emp_data: EmployeeDetailsEditArray,
                    add_emp_data: EmployeeDetailsAddArray,
                    edit_checkbox: EmployeeDetailsCheckboxEditArray,
                };
                //console.log(payload);
                axios
                    .post("finance_application/add_edit_employee_details", payload)
                    .then((response) => {
                        // console.log(response);
                        if (response.status == 200) {
                            if (response.data.data == "Save" || response.data.data == "Save New Entry") {
                                // window.location.reload(false);  
                                toast.success(response.data.message, {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            }else if (response.data.data == "Save and Exit") {
                                navigate("/transaction/create_transaction/" + transaction_id);
                            }else if(response.data.data == "Print" || response.data.data == "View Finance App"){
                                GeneratePrintPdf();
                            }  
                        }
                        setLoader(false);  
                        window.location.reload(false);
                    })
                    .catch(() => {
                        console.log('error');
                        setLoader(false);  
                    });
            }
        }
    }

    const handleEmployeeChange1 = (index, evnt) => {   // edit array

        let EmpDetArray = [...EmployeeDetailsEditArray];
        EmpDetArray[index][evnt.target.name] = evnt.target.value;
        //console.log(EmpDetArray);
        setEmployeeDetailsEditArray(EmpDetArray);
        setEmployeeDatazero(1)

        //------------------
        let EmpDetArray1 = [...EmployeeDetailsArray];
        EmpDetArray1[index][evnt.target.id] = evnt.target.value;
        setEmployeeDetailsArray(EmpDetArray1);
    }

    const handleEmployeeChkChange = (index, evnt, temp_current) => {   // edit checkbox array

        let EmpDetArray = [...EmployeeDetailsCheckboxEditArray];
        EmpDetArray[index][evnt.target.name] = !EmpDetArray[index][evnt.target.name];
        //console.log(EmpDetArray);
        setEmployeeDetailsCheckboxEditArray(EmpDetArray);
        setEmployeeDatazero(1)

        var arr = [...EmployeeDetailsArray];
        arr[index]["current"] = !arr[index]["current"]
        setEmployeeDetailsArray(arr)
    }

    const HideShowValidate = (evnt) => {
        const toname = evnt.target.name;
        //console.log(EmployeeDetailsCheckboxEditArray[0].sub_1_field_978);
        if (EmployeeDetailsCheckboxEditArray[0] && EmployeeDetailsCheckboxEditArray[0].sub_1_field_978 === false && toname == 'sub_1_field_978') { setDateRowHideShow1(1); } else { setDateRowHideShow1(0); }

        if (EmployeeDetailsCheckboxEditArray[1] && EmployeeDetailsCheckboxEditArray[1].sub_2_field_978 === false && toname == 'sub_2_field_978') { setDateRowHideShow2(2); }
        else { setDateRowHideShow2(0); }

        if (EmployeeDetailsCheckboxEditArray[2] && EmployeeDetailsCheckboxEditArray[2].sub_3_field_978 === false && toname == 'sub_3_field_978') { setDateRowHideShow3(3); }
        else { setDateRowHideShow3(0); }

        if (EmployeeDetailsCheckboxEditArray[3] && EmployeeDetailsCheckboxEditArray[3].sub_4_field_978 === false && toname == 'sub_4_field_978') { setDateRowHideShow4(4); }
        else { setDateRowHideShow4(0); }

        if (EmployeeDetailsCheckboxEditArray[4] && EmployeeDetailsCheckboxEditArray[4].sub_5_field_978 === false && toname == 'sub_5_field_978') { setDateRowHideShow5(5); }
        else { setDateRowHideShow5(0); }

        if (EmployeeDetailsCheckboxEditArray[5] && EmployeeDetailsCheckboxEditArray[5].sub_6_field_978 === false && toname == 'sub_6_field_978') { setDateRowHideShow6(6); }
        else { setDateRowHideShow6(0); }

        if (EmployeeDetailsCheckboxEditArray[6] && EmployeeDetailsCheckboxEditArray[6].sub_7_field_978 === false && toname == 'sub_7_field_978') { setDateRowHideShow7(7); }
        else { setDateRowHideShow7(0); }

        if (EmployeeDetailsCheckboxEditArray[7] && EmployeeDetailsCheckboxEditArray[7].sub_8_field_978 === false && toname == 'sub_8_field_978') { setDateRowHideShow8(8); }
        else { setDateRowHideShow8(0); }

        if (EmployeeDetailsCheckboxEditArray[8] && EmployeeDetailsCheckboxEditArray[8].sub_9_field_978 === false && toname == 'sub_9_field_978') { setDateRowHideShow9(9); }
        else { setDateRowHideShow9(0); }
    }


    const handleEmployeeChange = (index, evnt, final_instance_number) => { //add array
        //console.log(final_instance_number);
        const { name, value } = evnt.target;
        const rowsEmpInput = [...rowsAddEmployeeData];
        rowsEmpInput[index][name] = value;
        setEmployeeDetailsAddArray(rowsEmpInput);
    }

    //-------- employee details (button hide/show)
    const EmployeeShowRow = (e) => {
        const loc_div_id1 = e.currentTarget.id;
        if (loc_div_id1 == 'add_new_entry') {
            setRowHideShow1("table-row");
            setAddNewButton1("none")
        } else if (loc_div_id1 == 'remove_new_entry') {
            setRowHideShow1("none");
            setAddNewButton1("table-row")
        }
    }

    const removeEmployeeRow = (index) => {
        const rows = [...rowsAddEmployeeData];
        rows.splice(index, 1);
        setrowsAddEmployeeData(rows);
        setSalaryDateFlag(0);
    }

    const addEmpRowTable = () => {
        const rowsEmpInput = {}
        setrowsAddEmployeeData([...rowsAddEmployeeData, rowsEmpInput])
        setFlag(1)
        if (EmployeeDetailsArray) {
            const EditDocsCount = EmployeeDetailsArray.length;  // control instance count
            const main_number = EditDocsCount + 1;
            const validate_salary = `sub_${main_number}_field_1069`;
            setEmpValidateSalary(validate_salary);
            setEmpControlInstanceNumber(EditDocsCount);
        } else {
            const EditDocsCount = 0;  // control instance count
            const main_number = EditDocsCount + 1;
            const validate_salary = `sub_${main_number}_field_1069`;
            setEmpValidateSalary(validate_salary);
            setEmpControlInstanceNumber(EditDocsCount);
        }

        setSalaryDateFlag(1);
    }

    const handleEmployeeDeleteRow = (instance_number) => {
        setLoader(true);
        const payload = {
            instance_id: instance_id,
            user_id: user_id,
            form_id: 47,
            group_id: group_id,
            final_instance_number: instance_number,
        };
        //console.log(payload);
        axios
            .post("finance_application/delete_row_employee_contact_details", payload)
            .then((response) => {
                //console.log(response);
                if (response.status == 200) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setEmpDipFlag(1);
                }
                setLoader(false);
                window.location.reload(false);
            })
            .catch(() => {
                console.log('error');
                setLoader(false);
            });
    }

    //--------------------- contact details -------------
    useEffect(() => {
        const payload = {
            instance_id: instance_id,
            company_id: company_id,
        };
        axios
            .post("finance_application/featch_finance_contact_details", payload)
            .then((response) => {
                //console.log(response);
                if (response.status === 200) {
                    //console.log(response.data.data.select_array);
                    setContactDetailsArray(response.data.data.select_array);
                    setContactDetailsEditArray(response.data.data.edit_array);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, [ContactDipFlag]);

    const ContactDetailsValidation = () => {
        // console.log(rowsAddContactData2);
         var flag = true;
         if(ContactDetailsArray != ''){      // edit array validation
             for(var i=0; i<ContactDetailsArray.length; i++){
                 if(ContactDetailsArray[i]['contact_details'] == 0){
                     alert("Please select contact details");
                     flag = false;
                 }else if(ContactDetailsArray[i]['relationship'] == 0){
                     alert("Please select contact relationship");
                     flag = false;
                 }else if(ContactDetailsArray[i]['firstname'] == ''){
                     alert("Please enter first name");
                     flag = false;
                 }else if(ContactDetailsArray[i]['surname'] == ''){
                     alert("Please enter surname");
                     flag = false;
                 }
             }
         }
     
        if(rowsAddContactData2 != '' && OnchangeContactValidateFlag == 0){      // add array validation
            // if(rowsAddContactData2[0]['contact_details'] == 0){
            //     alert("Please select contact details");
            //     setcolorFocus1('red');
            //     flag = false;
            // }else
            if(rowsAddContactData2[0]['relationship'] == 0){
                alert("Please select contact relationship");
                setcolorFocus2('red');
                flag = false;
            }else if(rowsAddContactData2[0]['firstname'] == ''){
                alert("Please enter first name");
                setcolorFocus3('red');
                flag = false;
            }else if(rowsAddContactData2[0]['surname'] == ''){
                alert("Please enter surname");
                setcolorFocus4('red');
                flag = false;
            }
        }

         return flag;
     }
 
     const handleAddContact = (e) => {
         e.preventDefault();
 
         if(handleDeleteFlag == 0){
             const flag1 = ContactDetailsValidation();
             if (flag1) {
             setLoader(true);
             const payload = {
                 transaction_id: transaction_id,
                 instance_id: instance_id,
                 user_id : user_id,
                 form_id: 67,      
                 group_id: group_id,
                 company_id: company_id, 
                 application_id: application_id,
                 command: ContactDetailsSave,
                 edit_emp_data: ContactDetailsEditArray,
                 add_emp_data: ContactDetailsAddArray,
             };
            // console.log(payload);
             axios
            .post("finance_application/add_edit_contact_details", payload)
             .then((response) => {
               // console.log(response);
                 if(response.status == 200){
                    if(response.data.data == "Save" || response.data.data == "Save New Entry"){
                       // window.location.reload(false);  
                         toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                         }); 
                         window.location.reload(false);  
                    }else if(response.data.data == "Save and Exit"){
                         navigate("/transaction/create_transaction/" + transaction_id);
                    }else if(response.data.data == "Print" || response.data.data == "View Finance App"){
                        GeneratePrintPdf();
                    }
                 }
                 setLoader(false);
             })
             .catch(() => {
                console.log('error');
                setLoader(false);
             });
         }
       }
    }

    const handleContactChange1 = (index, evnt) => {   // edit array
        let ContactDetArray = [...ContactDetailsEditArray];
        ContactDetArray[index][evnt.target.name] = evnt.target.value;
        //console.log(ContactDetArray);
        setContactDetailsEditArray(ContactDetArray);
        setContactDatazero(1)

        //-------------------
        let ContactDetArray12 = [...ContactDetailsArray];
        ContactDetArray12[index][evnt.target.id] = evnt.target.value;
        setContactDetailsArray(ContactDetArray12);
    }

    const handleContactChange = (index, evnt, contact_name, final_instance_number) => { //add array
        //console.log(final_instance_number);
        const { name, value } = evnt.target;
        const rowsContactInput = [...rowsAddContactData];
        rowsContactInput[index][name] = value;
        setContactDetailsAddArray(rowsContactInput);

        //---------------------
        if(name == contact_name){
            setcontactDet(rowsContactInput[index][evnt.target.name]);
            if(rowsContactInput[index][evnt.target.name] == '0'){
                setContactDetailsFlag(1) 
            }else{
                setContactDetailsFlag(0) 
            }
        }
       
        let ContactDetArray11 = [...rowsAddContactData2];
        ContactDetArray11[index][evnt.target.id] = evnt.target.value;
        setrowsAddContactData2(ContactDetArray11);
        // console.log(ContactDetArray11);
        let con_id = ContactDetArray11[0].contact_details ? ContactDetArray11[0].contact_details : 0;
        if(con_id != 0){
            setOnchangeContactValidateFlag(1);
        }else{
            setOnchangeContactValidateFlag(0);
        }
    }

    const addContactRowTable = ()=>{

        if(ContactDetailsArray){
            const EditcontCount = ContactDetailsArray.length;  // control instance count
            setContactControlInstanceNumber(EditcontCount);

            const main_number = EditcontCount+1;
            const validate_contact = `sub_${main_number}_field_967`;
            const validate_relation = `sub_${main_number}_field_968`;
            const validate_firstname = `sub_${main_number}_field_970`;
            const validate_lastname = `sub_${main_number}_field_971`;
            setContactValidate(validate_contact);
            setRelationValidate(validate_relation)
            setFirstNameValidate(validate_firstname) 
            setLastNameValidate(validate_lastname)
            
           // setFinalInstanceNo(main_number);
            const rowsContactInput = {}
            setrowsAddContactData([...rowsAddContactData, rowsContactInput])

            const rowsContactInput2 = {contact_details: 0, relationship: 0, firstname: '', surname: ''}
            setrowsAddContactData2([...rowsAddContactData2, rowsContactInput2])


        }else{
            setContactControlInstanceNumber(0);

            const validate_contact = `sub_1_field_967`;
            const validate_relation = `sub_1_field_968`;
            const validate_firstname = `sub_1_field_970`;
            const validate_lastname = `sub_1_field_971`;
            setContactValidate(validate_contact);
            setRelationValidate(validate_relation)
            setFirstNameValidate(validate_firstname) 
            setLastNameValidate(validate_lastname)
        } 
        setContactDetailsFlag(1); 
        setContactRelationFlag(1); 
        setFirstNameFlag(1); 
        setLastNameFlag(1);
    }

    const ContactShowRow = (e) => {
        const loc_div_id1 = e.currentTarget.id;
        if (loc_div_id1 == 'add_new_entry') {
            setRowHideShow2("table-row");
            setAddNewButton2("none")
        } else if (loc_div_id1 == 'remove_new_entry') {
            setRowHideShow2("none");
            setAddNewButton2("table-row")
        }
    }

    const removeContactRow = (index) => {
        const rows = [...rowsAddContactData];
        rows.splice(index, 1);
        setrowsAddContactData(rows);
        setrowsAddContactData2([]); 
        setContactDetailsAddArray([]); 
    }

    const handleContactDeleteRow = (instance_number) => {
        setLoader(true);  
        const payload = {
            instance_id: instance_id,
            user_id: user_id,
            form_id: 67,
            group_id: group_id,
            final_instance_number: instance_number,
        };
        //console.log(payload);
        axios
            .post("finance_application/delete_row_employee_contact_details", payload)
            .then((response) => {
                //console.log(response);
                if (response.status == 200) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setContactDipFlag(1);
                }
                window.location.reload(false);
                setLoader(false);  
            })
            .catch(() => {
                console.log('error');
                setLoader(false);  
            });
    }

    //----------- get print button details (hide/show) -----------------
    useEffect(() => {
        const payload = {
            transaction_id: transaction_id,
            application_id: application_id,
            instance_id: instance_id,
            group_id: group_id,
        };
        axios
            .post("finance_application/print_label_details", payload)
            .then((response) => {
                if (response.status === 200) {
                    //console.log(response);
                    setPrintButtonData(response.data.data);

                    if (response.data.data == 'Print') {
                        setPrintButton("Print");
                    } else if (response.data.data == 'View Finance App') {
                        setPrintButton("View Finance App")
                    } else {
                        setPrintButton("Print");
                    }
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, [PrintButtonVisible]);

    //---------------- generate pdf and redirect url -------------
    const GeneratePrintPdf = () => {
        //alert('as');
        setLoader(true);
        const payload = {
            transaction_id: transaction_id,
            application_id: application_id,
            instance_id: instance_id,
            group_id: group_id,
            loanhouse_id: loanhouse_id,
            system_type: system_type,
            user_id: user_id,
            division_id: division_id,
            department: department_id,
            command: PrintButton,
        };
        //console.log(payload);setExpenditureHideShow
        axios
            .post("finance_application/finance_pdf_calling", payload)
            .then((response) => {
                //console.log(response);
                if (response.status == 200) {
                    //window.location.reload(false);
                    navigate('/transaction/prepare/application_id/'+response.data.data+'/regenerated=true');
                    setLoader(false);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }

    const OtherFieldHideShow = (type)=>{
        //console.log(type);
        if(type == 786){
            setPropertyHideShow("");
            setPropertyHideShow1("none");
          
            if(isNaN(HiddenSurplus)){
                var txt_surplus = HiddenSurplus.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(HiddenSurplus == ""){
                var txt_surplus = "0.00";
            }else{
                var txt_surplus = HiddenSurplus;
            }
           //console.log(txt_surplus);
           
            if(isNaN(MonthlyBond)){
                var txt_MonthlyBond = MonthlyBond.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(MonthlyBond == ""){
                var txt_MonthlyBond = "0.00";
            }else{
                var txt_MonthlyBond = MonthlyBond;
            }
            //console.log(txt_RentalValue);
           
            var new_surplus = parseFloat(txt_surplus)+parseFloat(txt_MonthlyBond);
            let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setSurplus(final_surplus);
            setHiddenSurplus(new_surplus);
            setMonthlyBond("0.00")

        }else if(type == 785){
            setPropertyHideShow1("");
            setPropertyHideShow("none");
            
            if(isNaN(HiddenSurplus)){
                var txt_surplus = HiddenSurplus.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(HiddenSurplus == ""){
                var txt_surplus = "0.00";
            }else{
                var txt_surplus = HiddenSurplus;
            }
           //console.log(txt_surplus);
            if(isNaN(RentalValue)){
                var txt_RentalValue = RentalValue.replace(/,(?=.*\.\d+)/g, ''); 
            }else if(RentalValue == ""){
                var txt_RentalValue = "0.00";
            }else{
                var txt_RentalValue = RentalValue;
            }
           
            var new_surplus = parseFloat(txt_surplus)+parseFloat(txt_RentalValue);
            let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setSurplus(final_surplus);
            setHiddenSurplus(new_surplus);
            setRentalValue("0.00")
        }  
    }

    const Loan1HideShowTab = (type1) => {
        //console.log(type1);
        if (type1 == 'no') {
            setLoan1HideShow("none");
            setMonthinstallReadonly("");
        } else if (type1 == 'yes') {
            setLoan1HideShow("");
            setMonthinstallReadonly("none");
        }
    }

    const Loan2HideShowTab = (type2) => {
        //console.log(type1);
        if (type2 == 'no') {
            setLoan2HideShow("none");
            setMonthinstallReadonly1("");
        } else if (type2 == 'yes') {
            setLoan2HideShow("");
            setMonthinstallReadonly1("none");
        }
    }

    const OtherExistingHideShowTab = (type3) => {
        if (type3 == 'no') {
            setOtherExistingHideShow("none");
        } else if (type3 == 'yes') {
            setOtherExistingHideShow("");
        }
    }

    const ExpenditureHideShowTab = (type4) => {
        if (type4 == 'no') {
            setExpenditureHideShow("none");
        } else if (type4 == 'yes') {
            setExpenditureHideShow("");
        }
    }

    const ClearInstallemnt = (Install_val)=>{
        //setInstallment("0.00");
        if(isNaN(HiddenSurplus)){
            var txt_surplus = HiddenSurplus.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(HiddenSurplus == ""){
            var txt_surplus = "0.00";
        }else{
            var txt_surplus = HiddenSurplus;
        }
       //console.log(txt_surplus);
        if(isNaN(Installment)){
            var txt_Installment = Installment.replace(/,(?=.*\.\d+)/g, ''); 
        }else if(Installment == ""){
            var txt_Installment = "0.00";
        }else{
            var txt_Installment = Installment;
        }

        if(Install_val == 785){
            var new_surplus = parseFloat(txt_surplus)+parseFloat(txt_Installment);
            let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setSurplus(final_surplus);
            setHiddenSurplus(new_surplus);
        }else if(Install_val == 786){
            var new_surplus = parseFloat(txt_surplus)-parseFloat(txt_Installment);
            let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setSurplus(final_surplus);
            setHiddenSurplus(new_surplus);
        }
    }


    const Calculate = () => {

        if (isNaN(PaySlipMAllowance)) {
            var nett_monthly_income = PaySlipMAllowance.replace(/,(?=.*\.\d+)/g, '');
        } else if (PaySlipMAllowance == "") {
            var nett_monthly_income = "0.00";
        } else {
            var nett_monthly_income = PaySlipMAllowance;
        }

        if (isNaN(AllOtherIncome)) {
            var all_other_income = AllOtherIncome.replace(/,(?=.*\.\d+)/g, '');
        } else if (AllOtherIncome == "") {
            var all_other_income = "0.00";
        } else {
            var all_other_income = AllOtherIncome;
        }

        let total_income = parseFloat(nett_monthly_income) + parseFloat(all_other_income);
        let final_total_income = total_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setTotalIncome(final_total_income);
        setHiddenTotalIncome(total_income);

        if (isNaN(TotalExpenditure)) {
            var total_expenditure_new = TotalExpenditure.replace(/,(?=.*\.\d+)/g, '');
        } else if (TotalExpenditure == "") {
            var total_expenditure_new = "0.00";
        } else {
            var total_expenditure_new = TotalExpenditure;
        }

        if (isNaN(MonthlyInvestment1)) {
            var monthly_investment1 = MonthlyInvestment1.replace(/,(?=.*\.\d+)/g, '');
        } else if (MonthlyInvestment1 == "") {
            var monthly_investment1 = "0.00";
        } else {
            var monthly_investment1 = MonthlyInvestment1;
        }

        if (isNaN(MonthlyInvestment2)) {
            var monthly_investment2 = MonthlyInvestment2.replace(/,(?=.*\.\d+)/g, '');
        } else if (MonthlyInvestment2 == "") {
            var monthly_investment2 = "0.00";
        } else {
            var monthly_investment2 = MonthlyInvestment2;
        }

        if (ToBeSealed3 == '786') {   // condition no 
            if (isNaN(Installment)) {
                var total_installment = Installment.replace(/,(?=.*\.\d+)/g, '');
            } else if (Installment == "") {
                var total_installment = "0.00";
            } else {
                var total_installment = Installment;
            }
        } else {
            var total_installment = "0.00";  // condition yes
        }

        if (OwnHome == '786') {
            if (isNaN(RentalValue)) {
                var rental_value = RentalValue.replace(/,(?=.*\.\d+)/g, '');
            } else if (RentalValue == "") {
                var rental_value = "0.00";
            } else {
                var rental_value = RentalValue;
            }
        } else {
            var rental_value = "0.00";
        }

        if (OwnHome == '785') {
            if (isNaN(MonthlyBond)) {
                var monthly_bond = MonthlyBond.replace(/,(?=.*\.\d+)/g, '');
            } else if (MonthlyBond == "") {
                var monthly_bond = "0.00";
            } else {
                var monthly_bond = MonthlyBond;
            }
        } else {
            var monthly_bond = "0.00";
        }

        var new_surplus = parseFloat(total_income) - parseFloat(total_expenditure_new) - parseFloat(monthly_investment1) - parseFloat(monthly_investment2) - parseFloat(rental_value) - parseFloat(monthly_bond) - parseFloat(total_installment);
        let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setSurplus(final_surplus);
        setHiddenSurplus(new_surplus);
    }

    const Calculate1 = () => {

        if (isNaN(RentAmount)) {
            var rent_amount = RentAmount.replace(/,(?=.*\.\d+)/g, '');
        } else if (RentAmount == "") {
            var rent_amount = "0.00";
        } else {
            var rent_amount = RentAmount;
        }

        if (isNaN(Telephone)) {
            var txt_telephone = Telephone.replace(/,(?=.*\.\d+)/g, '');
        } else if (Telephone == "") {
            var txt_telephone = "0.00";
        } else {
            var txt_telephone = Telephone;
        }

        if (isNaN(RateTaxWater)) {
            var rate_taxwater = RateTaxWater.replace(/,(?=.*\.\d+)/g, '');
        } else if (RateTaxWater == "") {
            var rate_taxwater = "0.00";
        } else {
            var rate_taxwater = RateTaxWater;
        }

        if (isNaN(Fuel)) {
            var txt_fuel = Fuel.replace(/,(?=.*\.\d+)/g, '');
        } else if (Fuel == "") {
            var txt_fuel = "0.00";
        } else {
            var txt_fuel = Fuel;
        }

        if (isNaN(VehicleInstallment)) {
            var vehicle_installment = VehicleInstallment.replace(/,(?=.*\.\d+)/g, '');
        } else if (VehicleInstallment == "") {
            var vehicle_installment = "0.00";
        } else {
            var vehicle_installment = VehicleInstallment;
        }

        if (isNaN(FoodEntertainment)) {
            var food_entertentment = FoodEntertainment.replace(/,(?=.*\.\d+)/g, '');
        } else if (FoodEntertainment == "") {
            var food_entertentment = "0.00";
        } else {
            var food_entertentment = FoodEntertainment;
        }

        if (isNaN(LoanPayment)) {
            var loan_payment = LoanPayment.replace(/,(?=.*\.\d+)/g, '');
        } else if (LoanPayment == "") {
            var loan_payment = "0.00";
        } else {
            var loan_payment = LoanPayment;
        }

        if (isNaN(EducationCost)) {
            var education_cost = EducationCost.replace(/,(?=.*\.\d+)/g, '');
        } else if (EducationCost == "") {
            var education_cost = "0.00";
        } else {
            var education_cost = EducationCost;
        }

        if (isNaN(CardRePayment)) {
            var card_payment = CardRePayment.replace(/,(?=.*\.\d+)/g, '');
        } else if (CardRePayment == "") {
            var card_payment = "0.00";
        } else {
            var card_payment = CardRePayment;
        }

        if (isNaN(Maintenance)) {
            var txt_maintenance = Maintenance.replace(/,(?=.*\.\d+)/g, '');
        } else if (Maintenance == "") {
            var txt_maintenance = "0.00";
        } else {
            var txt_maintenance = Maintenance;
        }

        if (isNaN(Overdraft)) {
            var txt_overdraft = Overdraft.replace(/,(?=.*\.\d+)/g, '');
        } else if (Overdraft == "") {
            var txt_overdraft = "0.00";
        } else {
            var txt_overdraft = Overdraft;
        }

        if (isNaN(Household)) {
            var txt_household = Household.replace(/,(?=.*\.\d+)/g, '');
        } else if (Household == "") {
            var txt_household = "0.00";
        } else {
            var txt_household = Household;
        }

        if (isNaN(Furniture)) {
            var txt_furniture = Furniture.replace(/,(?=.*\.\d+)/g, '');
        } else if (Furniture == "") {
            var txt_furniture = "0.00";
        } else {
            var txt_furniture = Furniture;
        }

        if (isNaN(Other1)) {
            var txt_other1 = Other1.replace(/,(?=.*\.\d+)/g, '');
        } else if (Other1 == "") {
            var txt_other1 = "0.00";
        } else {
            var txt_other1 = Other1;
        }

        if (isNaN(Clothing)) {
            var txt_clothing = Clothing.replace(/,(?=.*\.\d+)/g, '');
        } else if (Clothing == "") {
            var txt_clothing = "0.00";
        } else {
            var txt_clothing = Clothing;
        }

        if (isNaN(Other2)) {
            var txt_other2 = Other2.replace(/,(?=.*\.\d+)/g, '');
        } else if (Other2 == "") {
            var txt_other2 = "0.00";
        } else {
            var txt_other2 = Other2;
        }

        if (isNaN(PaymentPolicy)) {
            var payment_policy = PaymentPolicy.replace(/,(?=.*\.\d+)/g, '');
        } else if (PaymentPolicy == "") {
            var payment_policy = "0.00";
        } else {
            var payment_policy = PaymentPolicy;
        }

        if (isNaN(Other3)) {
            var txt_other3 = Other3.replace(/,(?=.*\.\d+)/g, '');
        } else if (Other3 == "") {
            var txt_other3 = "0.00";
        } else {
            var txt_other3 = Other3;
        }

        let total_expenditure = parseFloat(rent_amount) + parseFloat(txt_telephone) + parseFloat(rate_taxwater) + parseFloat(txt_fuel) + parseFloat(vehicle_installment) + parseFloat(food_entertentment) + parseFloat(loan_payment) + parseFloat(education_cost) + parseFloat(card_payment) + parseFloat(txt_maintenance) + parseFloat(txt_overdraft) + parseFloat(txt_household) + parseFloat(txt_furniture) + parseFloat(txt_other1) + parseFloat(txt_clothing) + parseFloat(txt_other2) + parseFloat(payment_policy) + parseFloat(txt_other3);

        let final_total_expenditure = total_expenditure.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // console.log(final_total_expenditure);
        setTotalExpenditure(final_total_expenditure);

        //----------------
        if (isNaN(HiddenTotalIncome)) {
            var all_total_income = HiddenTotalIncome.replace(/,(?=.*\.\d+)/g, '');
        } else if (HiddenTotalIncome == "") {
            var all_total_income = "0.00";
        } else {
            var all_total_income = HiddenTotalIncome;
        }

        if (isNaN(MonthlyInvestment1)) {
            var monthly_investment1 = MonthlyInvestment1.replace(/,(?=.*\.\d+)/g, '');
        } else if (MonthlyInvestment1 == "") {
            var monthly_investment1 = "0.00";
        } else {
            var monthly_investment1 = MonthlyInvestment1;
        }

        if (isNaN(MonthlyInvestment2)) {
            var monthly_investment2 = MonthlyInvestment2.replace(/,(?=.*\.\d+)/g, '');
        } else if (MonthlyInvestment2 == "") {
            var monthly_investment2 = "0.00";
        } else {
            var monthly_investment2 = MonthlyInvestment2;
        }

        if (ToBeSealed3 == '786') {   // condition no 
            if (isNaN(Installment)) {
                var total_installment = Installment.replace(/,(?=.*\.\d+)/g, '');
            } else if (Installment == "") {
                var total_installment = "0.00";
            } else {
                var total_installment = Installment;
            }
        } else {
            var total_installment = "0.00";  // condition yes
        }

        if (OwnHome == '786') {
            if (isNaN(RentalValue)) {
                var rental_value = RentalValue.replace(/,(?=.*\.\d+)/g, '');
            } else if (RentalValue == "") {
                var rental_value = "0.00";
            } else {
                var rental_value = RentalValue;
            }
        } else {
            var rental_value = "0.00";
        }

        if (OwnHome == '785') {
            if (isNaN(MonthlyBond)) {
                var monthly_bond = MonthlyBond.replace(/,(?=.*\.\d+)/g, '');
            } else if (MonthlyBond == "") {
                var monthly_bond = "0.00";
            } else {
                var monthly_bond = MonthlyBond;
            }
        } else {
            var monthly_bond = "0.00";
        }

        // setSurplus(final_surplus);
        var new_surplus = parseFloat(all_total_income) - parseFloat(rent_amount) - parseFloat(txt_telephone) - parseFloat(rate_taxwater) - parseFloat(txt_fuel) - parseFloat(vehicle_installment) - parseFloat(food_entertentment) - parseFloat(loan_payment) - parseFloat(education_cost) - parseFloat(card_payment) - parseFloat(txt_maintenance) - parseFloat(txt_overdraft) - parseFloat(txt_furniture) - parseFloat(txt_other1) - parseFloat(txt_clothing) - parseFloat(txt_other2) - parseFloat(payment_policy) - parseFloat(txt_other3) - parseFloat(txt_household) - parseFloat(monthly_investment1) - parseFloat(monthly_investment2) - parseFloat(rental_value) - parseFloat(monthly_bond) - parseFloat(total_installment);
        let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setSurplus(final_surplus);
        setHiddenSurplus(new_surplus);
    }

    const Calculate2 = () => {
        // console.log(Surplus);
        if (isNaN(HiddenTotalIncome)) {
            var total_income1 = HiddenTotalIncome.replace(/,(?=.*\.\d+)/g, '');
        } else if (HiddenTotalIncome == "") {
            var total_income1 = "0.00";
        } else {
            var total_income1 = HiddenTotalIncome;
        }

        if (isNaN(TotalExpenditure)) {
            var total_expenditure = TotalExpenditure.replace(/,(?=.*\.\d+)/g, '');
        } else if (TotalExpenditure == "") {
            var total_expenditure = "0.00";
        } else {
            var total_expenditure = TotalExpenditure;
        }

        if (isNaN(MonthlyInvestment1)) {
            var monthly_investment1 = MonthlyInvestment1.replace(/,(?=.*\.\d+)/g, '');
        } else if (MonthlyInvestment1 == "") {
            var monthly_investment1 = "0.00";
        } else {
            var monthly_investment1 = MonthlyInvestment1;
        }

        if (isNaN(MonthlyInvestment2)) {
            var monthly_investment2 = MonthlyInvestment2.replace(/,(?=.*\.\d+)/g, '');
        } else if (MonthlyInvestment2 == "") {
            var monthly_investment2 = "0.00";
        } else {
            var monthly_investment2 = MonthlyInvestment2;
        }

        if (ToBeSealed3 == '786') {   // condition no 
            if (isNaN(Installment)) {
                var total_installment = Installment.replace(/,(?=.*\.\d+)/g, '');
            } else if (Installment == "") {
                var total_installment = "0.00";
            } else {
                var total_installment = Installment;
            }
        } else {
            var total_installment = "0.00";  // condition yes
        }

        if (OwnHome == '786') {
            if (isNaN(RentalValue)) {
                var rental_value = RentalValue.replace(/,(?=.*\.\d+)/g, '');
            } else if (RentalValue == "") {
                var rental_value = "0.00";
            } else {
                var rental_value = RentalValue;
            }
        } else {
            var rental_value = "0.00";
        }

        if (OwnHome == '785') {
            if (isNaN(MonthlyBond)) {
                var monthly_bond = MonthlyBond.replace(/,(?=.*\.\d+)/g, '');
            } else if (MonthlyBond == "") {
                var monthly_bond = "0.00";
            } else {
                var monthly_bond = MonthlyBond;
            }
        } else {
            var monthly_bond = "0.00";
        }
        //console.log(total_income+'---'+monthly_investment1);

        var new_surplus = parseFloat(total_income1) - parseFloat(monthly_investment1) - parseFloat(monthly_investment2) - parseFloat(total_expenditure) - parseFloat(rental_value) - parseFloat(monthly_bond) - parseFloat(total_installment);
        let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setSurplus(final_surplus);
        setHiddenSurplus(new_surplus);
    }

    const ClearMonthlyInstallment = (type) => {
        if (type === '786') {
            setMonthlyInvestment1("0.00");
            setMonthlyInvestment2("0.00");

            if (isNaN(HiddenSurplus)) {
                var txt_surplus = HiddenSurplus.replace(/,(?=.*\.\d+)/g, '');
            } else if (HiddenSurplus == "") {
                var txt_surplus = "0.00";
            } else {
                var txt_surplus = HiddenSurplus;
            }
            //console.log(txt_surplus);
            if (isNaN(MonthlyInvestment1)) {
                var monthly_investment1 = MonthlyInvestment1.replace(/,(?=.*\.\d+)/g, '');
            } else if (MonthlyInvestment1 == "") {
                var monthly_investment1 = "0.00";
            } else {
                var monthly_investment1 = MonthlyInvestment1;
            }

            if (isNaN(MonthlyInvestment2)) {
                var monthly_investment2 = MonthlyInvestment2.replace(/,(?=.*\.\d+)/g, '');
            } else if (MonthlyInvestment2 == "") {
                var monthly_investment2 = "0.00";
            } else {
                var monthly_investment2 = MonthlyInvestment2;
            }

            var new_surplus = parseFloat(txt_surplus) - parseFloat(monthly_investment1) - parseFloat(monthly_investment2);
            let final_surplus = new_surplus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setSurplus(final_surplus);
            setHiddenSurplus(new_surplus);
        }
    }
    let panFlag = 1;


    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px", marginLeft: "-200px" }} />
            <Transaction_menu panel_flag={panFlag}/>
            {/* <form onSubmit={handleSubmit}> */}
            {loader ? <Loader /> : null}
            <table width="100%" height="800" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                <tbody>
                    <tr valign="top">
                        <td width="100%">
                            <p align="center">
                                <img height="50" border="0" src={garborone_bank_img} alt="" />
                            </p>
                            <table width="100%" border={0} cellSpacing={5} cellPadding={5} >
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                            <table className="defaultTbl" cellSpacing={1} align="center" width={tabWidth}>
                                                <tbody>
                                                    <tr className="caption" id="group_list">
                                                        <th colSpan={(ClientDetailFlag && 4) || (EmployeeDetailFlag && 11) || (FinancialDetailFlag && 4) || (LoanDetailFlag && 4) || (SpouseDetailFlag && 4) || (ContactDetailFlag && 11)} style={{ textAlign: 'center' }}>

                                                            <input type="button" name="command" value="Client Details" onClick={() => { ChangeNavigation("Client Details") }} />

                                                            <input type="button" name="command" value="Employer Details" onClick={() => { ChangeNavigation("Employer Details") }} />

                                                            <input type="button" name="command" value="Client's Financial Details" onClick={() => { ChangeNavigation("Client's Financial Details") }} />

                                                            <input type="button" name="command" value="Loan Specifics" onClick={() => { ChangeNavigation("Loan Specifics") }} />

                                                            <input type="button" name="command" value="Spouse Details" onClick={() => { ChangeNavigation("Spouse Details") }} />

                                                            <input type="button" name="command" value="Contact Details" onClick={() => { ChangeNavigation("Contact Details") }} /></th>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            {/*------------------ Client Details -----------------------------------------  */}
                                            <form name="clientDetails" method="post" onSubmit={handleClientDetailsSubmit} >
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="800" style={{ display: div_id1 }}>
                                                    <tbody>
                                                        {ClientDetailFlag ?
                                                            <>
                                                                <tr className="caption">
                                                                    <th colSpan={4} style={{ textAlign: 'center' }}>CLIENT DETAILS</th>
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                        CLIENT DETAILS
                                                                    </td>

                                                                    <td style={{ textAlign: "left", width: "25%" }}>
                                                                        <select id="test" name="sub_1_field_930" className="text-uppercase" value={ClientDetails} onChange={(e) => { setClientDetails(e.target.value); OnClientWiseChange(e.target.value); }} >
                                                                            <option value="0">-- Select An Option --</option>
                                                                            {ClientDetailsAll.length > 0 ?
                                                                                ClientDetailsAll.map((item) => {
                                                                                    return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                            {item.name}
                                                                                        </option>
                                                                                    );
                                                                                })
                                                                                : null}
                                                                        </select>
                                                                    </td>

                                                                    <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                        TITLE
                                                                    </td>
                                                                    <td style={{ textAlign: "left", width: "25%" }}>

                                                                        <select id="test" name="sub_1_field_931" className="text-uppercase" value={Title} onChange={(e) => { setTitle(e.target.value) }} >
                                                                            <option value="0">-- Select An Option --</option>
                                                                            {TitleAll.length > 0 ?
                                                                                TitleAll.map((item) => {
                                                                                    return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                            {item.title}
                                                                                        </option>
                                                                                    );
                                                                                })
                                                                                : null}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        <span style={{ color: ValidateColor }}> FIRST NAME</span> <span style={{ color: "red" }}>*</span>
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_932" type="text" value={Firstname} onChange={(e) => { setFirstname(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        INITIALS
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1048" type="text" value={Initials} onChange={(e) => { setInitials(e.target.value) }} className="text-uppercase" />
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='caption'>
                                                                        <span style={{ color: ValidateColor1 }}> SURNAME</span> <span style={{ color: "red" }}>*</span>
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_933" type="text" value={Surname} onChange={(e) => { setSurname(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        ID NUMBER
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_934" type="text" value={IDNumber} onChange={(e) => { setIDNumber(e.target.value) }} className="text-uppercase" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        DATE OF BIRTH
                                                                    </td>

                                                                    <td>
                                                                        {BirthdateFlag == 1 ?
                                                                            <DatePicker id="sub_1_field_935" selected={DateOfBirth} name="sub_1_field_935" onChange={(date) => { setDateOfBirth(date); setBirthdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                                return new Date() > d;
                                                                            }} />
                                                                            :
                                                                            <DatePicker id="sub_1_field_935" value={DateOfBirth} name="sub_1_field_935" onChange={(date) => { setDateOfBirth(date); setBirthdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                                return new Date() > d;
                                                                            }} />
                                                                        }
                                                                    </td>

                                                                    <td className='caption'>
                                                                        GENDER
                                                                    </td>
                                                                    <td>
                                                                        <select name="sub_1_field_945" id="gender_select" className="text-uppercase" value={Gender} onChange={(e) => setGender(e.target.value)} >
                                                                            <option value="0">-- Select An Option --</option>
                                                                            {GenderAll.length > 0 ?
                                                                                GenderAll.map((item) => {
                                                                                    return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                            {item.gender}
                                                                                        </option>
                                                                                    );
                                                                                })
                                                                                : null}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        MARITAL STATUS
                                                                    </td>

                                                                    <td>

                                                                        <select name="sub_1_field_946" className="text-uppercase" value={MaritalStatus} onChange={(e) => { setMaritalStatus(e.target.value) }}>
                                                                            <option value="0">-- Select An Option --</option>
                                                                            {MaritalStatusAll.length > 0 ?
                                                                                MaritalStatusAll.map((item) => {
                                                                                    return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                            {item.marital_status}
                                                                                        </option>
                                                                                    );
                                                                                })
                                                                                : null}
                                                                        </select>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        RESIDENCY STATUS
                                                                    </td>
                                                                    <td>

                                                                        <select name="sub_1_field_1038" id="sub_1_field_1038" className="text-uppercase" value={ResidencyStatus} onChange={(e) => { setResidencyStatus(e.target.value) }}>
                                                                            <option value="0">-- Select an option --</option>
                                                                            {ResidencyStatusAll.length > 0 ?
                                                                                ResidencyStatusAll.map((item) => {
                                                                                    return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                            {item.res_status}
                                                                                        </option>
                                                                                    );
                                                                                })
                                                                                : null}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        HOME NUMBER
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_936" type="text" value={HomeNumber} onChange={(e) => { setHomeNumber(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        WORK NUMBER
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_938" type="text" value={WorkNumber} onChange={(e) => { setWorkNumber(e.target.value) }} className="text-uppercase" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        MOBILE NUMBER
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_939" type="text" value={MobileNumber} onChange={(e) => { setMobileNumber(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        FAX NUMBER
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1527" type="text" value={FixNumber} onChange={(e) => { setFixNumber(e.target.value) }} className="text-uppercase" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        <span style={{ color: ValidateColor2 }}> EMAIL</span>
                                                                        <span style={{ color: "red" }}>*</span>
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_940" type="text" value={Email} onChange={(e) => { setEmail(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        PHYSICAL ADDRESS LINE 1
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1497" type="text" value={PhysicalAddress1} onChange={(e) => { setPhysicalAddress1(e.target.value) }} className="text-uppercase" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        POSTAL ADDRESS LINE 1
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_1500" type="text" value={PostalAddress1} onChange={(e) => { setPostalAddress1(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        PHYSICAL ADDRESS LINE 2
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1498" type="text" value={PhysicalAddress2} onChange={(e) => { setPhysicalAddress2(e.target.value) }} className="text-uppercase" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        POSTAL ADDRESS LINE 2
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_1501" type="text" value={PostalAddress2} onChange={(e) => { setPostalAddress2(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        PHYSICAL ADDRESS CITY
                                                                    </td>
                                                                    <td>
                                                                        <input name="sub_1_field_1511" type="text" value={PhysicalAddressCity} onChange={(e) => { setPhysicalAddressCity(e.target.value) }} className="text-uppercase" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        POSTAL ADDRESS CITY
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_1513" type="text" value={PostalAddressCity} onChange={(e) => { setPostalAddressCity(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td className='caption'>
                                                                        DATE MARRIED
                                                                    </td>
                                                                    <td>
                                                                        {MarrieddateFlag == 1 ?
                                                                            <DatePicker id="sub_1_field_1466" selected={MarriedDate} name="sub_1_field_1466" onChange={(date) => { setMarriedDate(date); setMarrieddateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                                return new Date() > d;
                                                                            }} />
                                                                            :
                                                                            <DatePicker id="sub_1_field_1466" value={MarriedDate} name="sub_1_field_1466" onChange={(date) => { setMarriedDate(date); setMarrieddateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                                return new Date() > d;
                                                                            }} />
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        HOME LANGUAGE
                                                                    </td>

                                                                    <td>
                                                                        <select name="sub_1_field_1047" className="text-uppercase" value={HomeLanguage} onChange={(e) => setHomeLanguage(e.target.value)} >
                                                                            {HomeLanguageAll.map((item) => {
                                                                                return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                        {item.text}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </td>

                                                                    <td className='caption'>
                                                                        COMMUNICATION LANGUAGE
                                                                    </td>
                                                                    <td>
                                                                        <select name="sub_1_field_1045" className="text-uppercase" value={CommunicationLanguage} onChange={(e) => setCommunicationLanguage(e.target.value)} >
                                                                            {CommunicationLanguageAll.map((item) => {
                                                                                return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                        {item.text}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        OCCUPANCY DATE AT CURRENT ADDRESS
                                                                    </td>

                                                                    <td>
                                                                        {CurrentdateFlag == 1 ?
                                                                            <DatePicker id="sub_1_field_949" selected={OccupancyCurrentDate} name="sub_1_field_949" onChange={(date) => { setOccupancyCurrentDate(date); setCurrentdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                                return new Date() > d;
                                                                            }} />
                                                                            :
                                                                            <DatePicker id="sub_1_field_949" value={OccupancyCurrentDate} name="sub_1_field_949" onChange={(date) => { setOccupancyCurrentDate(date); setCurrentdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                                return new Date() > d;
                                                                            }} />
                                                                        }
                                                                    </td>

                                                                    <td className='caption'>
                                                                        OCCUPANCY DATE AT PREVIOUS ADDRESS
                                                                    </td>
                                                                    <td>
                                                                        {PrevdateFlag == 1 ?
                                                                            <DatePicker id="sub_1_field_950" selected={OccupancyPreviousDate} name="sub_1_field_950" onChange={(date) => { setOccupancyPreviousDate(date); setPrevdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                                return new Date() > d;
                                                                            }} />
                                                                            :
                                                                            <DatePicker id="sub_1_field_950" value={OccupancyPreviousDate} name="sub_1_field_950" onChange={(date) => { setOccupancyPreviousDate(date); setPrevdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                                return new Date() > d;
                                                                            }} />
                                                                        }

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        IS THIS A GUARANTOR APPLICATION?
                                                                    </td>

                                                                    <td>
                                                                        <input type="radio" name="sub_1_field_1467" value={Guarantor == '786' ? Guarantor : '786'} checked={Guarantor == '786'} onChange={(e) => { setGuarantor(e.target.value) }} />&nbsp;NO
                                                                        <br />
                                                                        <input type="radio" name="sub_1_field_1467" value={Guarantor == '785' ? Guarantor : '785'} checked={Guarantor == '785'} onChange={(e) => { setGuarantor(e.target.value) }} />&nbsp;YES
                                                                    </td>

                                                                    <td className='caption'>
                                                                        RESIDENTIAL STATUS
                                                                    </td>
                                                                    <td>
                                                                        <select name="sub_1_field_951" className="text-uppercase" value={ResidentialStatus} onChange={(e) => setResidentialStatus(e.target.value)} >
                                                                            <option value="0">-- Select an option --</option>
                                                                            {ResidentialStatusAll.map((item) => {
                                                                                return (
                                                                                    <option key={item.id} value={item.id}>
                                                                                        {item.status}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='caption'>
                                                                        NUMBER OF DEPENDANTS
                                                                    </td>

                                                                    <td>
                                                                        <input name="sub_1_field_1535" type="text" value={Dependant} onChange={(e) => { setDependant(e.target.value) }} className="text-uppercase" />
                                                                    </td>

                                                                    <td colSpan={2}></td>
                                                                </tr>

                                                                <tr className="caption text-center" >
                                                                    <td colSpan={4} style={{ textAlign: "center" }}>

                                                                        {PrintButtonData != '' ?
                                                                            <input type="submit" name="command" value={PrintButton} onClick={(e) => { setPrintButton(e.target.value); setClientDetSave(e.target.value);  }} />
                                                                            : null}
                                                                        &nbsp;

                                                                        <input type="submit" name="Save" value="Save" onClick={(e) => { setClientDetSave(e.target.value) }} />&nbsp;
                                                                        <input type="submit" name="Save and Exit" value="Save and Exit" onClick={(e) => { setClientDetSave(e.target.value) }} />&nbsp;

                                                                        <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                            <input type="button" className="p-relative" value="Cancel" />
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            </> : null}

                                                    </tbody>
                                                </table>
                                            </form>

                                            {/*------------------ Employee Details -----------------------------------------  */}
                                            <form name="emp_details" method="post" onSubmit={handleAddEmployee} >
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="1488" style={{ display: div_id2 }}>
                                                    <tbody>
                                                        <tr className="caption">
                                                            <th colSpan={11} style={{ textAlign: 'center' }}>EMPLOYER DETAILS </th>
                                                        </tr>
                                                        <tr className="caption">
                                                            <td>Employer</td>
                                                            <td>Current</td>
                                                            <td>Position</td>
                                                            <td style={{ whiteSpace: "nowrap" }}>Employed From</td>
                                                            <td style={{ width: "105px" }}>Employed To</td>
                                                            <td>Employee #</td>
                                                            <td>Employer Address</td>
                                                            <td>Contact Number</td>
                                                            <td><span id="sub_1_label_1069" title="Salary field is mandatory" >Salary Date <span style={{ color: "red" }}>*<span></span></span></span></td>
                                                            <td>Supervisor</td><td>&nbsp;</td>
                                                        </tr>

                                                        {EmployeeDetailsArray ?
                                                            EmployeeDetailsArray.map((item, index) => {
                                                                const { employer, position, employed_from, employed_to, employee_hash, employer_address, contact_number, supervisor, salary_date1 } = item;

                                                                const final_instance_number = index + 1;
                                                                const temp_employer = `sub_${final_instance_number}_field_977`;
                                                                const temp_current = `sub_${final_instance_number}_field_978`;
                                                                const temp_position = `sub_${final_instance_number}_field_979`;
                                                                const temp_employed_from = `sub_${final_instance_number}_field_980`;
                                                                const temp_employed_to = `sub_${final_instance_number}_field_981`;
                                                                const temp_employee_hash = `sub_${final_instance_number}_field_982`;
                                                                const temp_employer_address = `sub_${final_instance_number}_field_984`;
                                                                const temp_contact_number = `sub_${final_instance_number}_field_985`;
                                                                const temp_salary_date = `sub_${final_instance_number}_field_1069`;
                                                                const temp_supervisor = `sub_${final_instance_number}_field_1077`;



                                                                return (

                                                                    <tr className="new_entry_line" key={index}>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            <input id="employer" type="text" name={temp_employer} defaultValue={EmployeeDatazero === 0 ? item.employer : employer} onChange={(evnt) => { handleEmployeeChange1(index, evnt, item.control_instance_number); setFlag(1) }} style={{ textTransform: "uppercase" }} />
                                                                        </td>

                                                                        <td style={{ textAlign: "center" }}>
                                                                            <input type="checkbox" id="current" defaultValue={EmployeeDatazero === 0 ? item.current : EmpCurrent} name={temp_current} defaultChecked={(item.current == true) ? true : false} onChange={(evnt) => { { item.current === true ? setEmpCurrent(EmpCurrent) : setEmpCurrent(EmpCurrent); handleEmployeeChkChange(index, evnt, temp_current) } }} onClick={(evnt) => { HideShowValidate(evnt) }} />
                                                                        </td>


                                                                        <td style={{ textAlign: "center" }}>
                                                                            <input type="text" id="position" name={temp_position} defaultValue={EmployeeDatazero === 0 ? item.position : position} onChange={(evnt) => { handleEmployeeChange1(index, evnt, item.control_instance_number); setFlag(1) }} style={{ textTransform: "uppercase" }} />
                                                                        </td>


                                                                        <td style={{ textAlign: "center" }}>
                                                                            <input type="date" id="from" name={temp_employed_from} defaultValue={EmployeeDatazero === 0 ? item.employed_from : employed_from} onChange={(evnt) => { handleEmployeeChange1(index, evnt, item.control_instance_number); setFlag(1) }} style={{ textTransform: "uppercase" }} />

                                                                        </td>

                                                                        <td style={{ textAlign: "center" }}>
                                                                            {
                                                                                !Boolean(item.current) ?
                                                                                    temp_employed_to == 'sub_1_field_981' ?

                                                                                        <input type="date" id="to" name={temp_employed_to} defaultValue={EmployeeDatazero === 0 ? item.employed_to : employed_to} onChange={(evnt) => { handleEmployeeChange1(index, evnt, item.control_instance_number); setFlag(1) }} style={{ textTransform: "uppercase", display: DateRowHideShow1 == 1 ? 'none' : '' }} />

                                                                                        : temp_employed_to == 'sub_2_field_981' ?

                                                                                            <input type="date" id="to" name={temp_employed_to} defaultValue={EmployeeDatazero === 0 ? item.employed_to : employed_to} onChange={(evnt) => { handleEmployeeChange1(index, evnt, item.control_instance_number); setFlag(1) }} style={{ textTransform: "uppercase", display: DateRowHideShow2 == 2 ? 'none' : '' }} />

                                                                                            : temp_employed_to == 'sub_3_field_981' ?

                                                                                                <input type="date" id="to" name={temp_employed_to} defaultValue={EmployeeDatazero === 0 ? item.employed_to : employed_to} onChange={(evnt) => { handleEmployeeChange1(index, evnt, item.control_instance_number); setFlag(1) }} style={{ textTransform: "uppercase", display: DateRowHideShow3 == 3 ? 'none' : '' }} />
                                                                                                : temp_employed_to == 'sub_4_field_981' ?
                                                                                                    <input type="date" id="to" name={temp_employed_to} defaultValue={EmployeeDatazero === 0 ? item.employed_to : employed_to} onChange={(evnt) => { handleEmployeeChange1(index, evnt, item.control_instance_number); setFlag(1) }} style={{ textTransform: "uppercase", display: DateRowHideShow4 == 4 ? 'none' : '' }} />
                                                                                                    : temp_employed_to == 'sub_5_field_981' ?

                                                                                                        <input type="date" id="to" name={temp_employed_to} defaultValue={EmployeeDatazero === 0 ? item.employed_to : employed_to} onChange={(evnt) => { handleEmployeeChange1(index, evnt, item.control_instance_number); setFlag(1) }} style={{ textTransform: "uppercase", display: DateRowHideShow5 == 5 ? 'none' : '' }} />
                                                                                                        : temp_employed_to == 'sub_6_field_981' ?

                                                                                                            <input type="date" id="to" name={temp_employed_to} defaultValue={EmployeeDatazero === 0 ? item.employed_to : employed_to} onChange={(evnt) => { handleEmployeeChange1(index, evnt, item.control_instance_number); setFlag(1) }} style={{ textTransform: "uppercase", display: DateRowHideShow6 == 6 ? 'none' : '' }} />

                                                                                                            : temp_employed_to == 'sub_7_field_981' ?

                                                                                                                <input type="date" id="to" name={temp_employed_to} defaultValue={EmployeeDatazero === 0 ? item.employed_to : employed_to} onChange={(evnt) => { handleEmployeeChange1(index, evnt, item.control_instance_number); setFlag(1) }} style={{ textTransform: "uppercase", display: DateRowHideShow7 == 7 ? 'none' : '' }} />

                                                                                                                : temp_employed_to == 'sub_8_field_981' ?

                                                                                                                    <input type="date" id="to" name={temp_employed_to} defaultValue={EmployeeDatazero === 0 ? item.employed_to : employed_to} onChange={(evnt) => { handleEmployeeChange1(index, evnt, item.control_instance_number); setFlag(1) }} style={{ textTransform: "uppercase", display: DateRowHideShow8 == 8 ? 'none' : '' }} />

                                                                                                                    : temp_employed_to == 'sub_9_field_981' ?

                                                                                                                        <input type="date" id="to" name={temp_employed_to} defaultValue={EmployeeDatazero === 0 ? item.employed_to : employed_to} onChange={(evnt) => { handleEmployeeChange1(index, evnt, item.control_instance_number); setFlag(1) }} style={{ textTransform: "uppercase", display: DateRowHideShow9 == 9 ? 'none' : '' }} />

                                                                                                                        :

                                                                                                                        <input type="date" id="to" name={temp_employed_to} defaultValue={EmployeeDatazero === 0 ? item.employed_to : employed_to} onChange={(evnt) => { handleEmployeeChange1(index, evnt, item.control_instance_number); setFlag(1) }} style={{ textTransform: "uppercase", display: '' }} />
                                                                                    : null

                                                                            }
                                                                        </td>

                                                                        <td style={{ textAlign: "center" }}>
                                                                            <input type="text" id="employee" name={temp_employee_hash} defaultValue={EmployeeDatazero === 0 ? item.employee_hash : employee_hash} onChange={(evnt) => { handleEmployeeChange1(index, evnt, item.control_instance_number); setFlag(1) }} style={{ textTransform: "uppercase" }} />
                                                                        </td>

                                                                        <td style={{ textAlign: "center" }}>
                                                                            <textarea rows="4" id="address" name={temp_employer_address} style={{ textTransform: "uppercase" }} defaultValue={EmployeeDatazero === 0 ? item.employer_address : employer_address} onChange={(evnt) => { handleEmployeeChange1(index, evnt, item.control_instance_number); setFlag(1) }} ></textarea>
                                                                        </td>

                                                                        <td style={{ textAlign: "center" }}>
                                                                            <input id="contact" type="text" name={temp_contact_number} style={{ textTransform: "uppercase" }} defaultValue={EmployeeDatazero === 0 ? item.contact_number : contact_number} onChange={(evnt) => { handleEmployeeChange1(index, evnt, item.control_instance_number); setFlag(1) }} />
                                                                        </td>

                                                                        <td style={{ textAlign: "center" }}>

                                                                            <select id="salary_date" name={temp_salary_date} className="text-uppercase" value={EmployeeDatazero === 0 ? item.salary_date : salary_date1} onChange={(evnt) => { handleEmployeeChange1(index, evnt, item.control_instance_number); setFlag(1); setSalaryDateFlag(0); }} >
                                                                                <option value="">-- Select an option --</option>
                                                                                {SalaryDateAll.map((item) => {
                                                                                    return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                            {item.date}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>

                                                                        <td style={{ textAlign: "center" }}>
                                                                            <input id="supervisor" type="text" name={temp_supervisor} style={{ textTransform: "uppercase" }} defaultValue={EmployeeDatazero === 0 ? item.supervisor : supervisor} onChange={(evnt) => { handleEmployeeChange1(index, evnt, item.control_instance_number); setFlag(1) }} />
                                                                        </td>

                                                                        <td>
                                                                            <input type="image" name="delete_file" src={delete_row_img} width="16" onClick={() => { if (window.confirm('Are you sure you want to delete this row?')) { handleEmployeeDeleteRow(item.control_instance_number); sethandleFlag(1); } }} />
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }) : null}

                                                        <Employee_details_table_rows rowsData={rowsAddEmployeeData} handleEmployeeChange={handleEmployeeChange} instanceNumber={EmpControlInstanceNumber} colorFocus={colorFocus} EmpValidateSalary={EmpValidateSalary} setSalaryDateFlag={setSalaryDateFlag} />

                                                        <tr style={{ display: AddNewButton1 }}>
                                                            <td className="text-center" colSpan={11}>
                                                                <input type="button" id="add_new_entry" value="Add an Entry" onClick={function (e) { addEmpRowTable(); EmployeeShowRow(e); }} />

                                                            </td>
                                                        </tr>
                                                        <tr style={{ display: RowHideShow1 }}>
                                                            <td className="text-center" colSpan={11}>
                                                                <input className="new_entry_line" type="submit" value="Save New Entry" name="command" onClick={(e) => { setEmployeeDetailsSave(e.target.value); }} />
                                                                <input className="new_entry_line" id="remove_new_entry" type="button" value="Cancel" onClick={function (e) { removeEmployeeRow(); EmployeeShowRow(e) }} />

                                                            </td>
                                                        </tr>

                                                        <tr className="text-center" >
                                                            <td colSpan={11} style={{ textAlign: "center" }}>

                                                                {PrintButtonData != '' ?
                                                                    <input type="submit" name="command" value={PrintButton} onClick={(e) => { setPrintButton(e.target.value); setEmployeeDetailsSave(e.target.value); }} />
                                                                    : null}
                                                                &nbsp;

                                                                <input type="submit" name="command" value="Save" onClick={(e) => { setEmployeeDetailsSave(e.target.value) }} />&nbsp;

                                                                <input type="submit" name="command" value="Save and Exit" onClick={(e) => { setEmployeeDetailsSave(e.target.value) }} />&nbsp;

                                                                <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                    <input type="button" className="p-relative" value="Cancel" />
                                                                </Link>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </form>

                                            {/*------------------ Client Finance Details -----------------------------------------  */}

                                            <form name="clientDetails" method="post" onSubmit={handleClientFinanceDetailsSubmit} >
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="800" style={{ display: div_id3 }}>
                                                    <tbody>
                                                        {/* {FinancialDetailFlag ?
                                                            <>   */}
                                                        <tr className="caption">
                                                            <th colSpan={4} style={{ textAlign: 'center' }}>CLIENT'S FINANCIAL DETAILS </th>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                <span style={{ color: IncomeCategoryColor }}>Income Category</span> <span style={{ color: "red" }}>*</span>
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                <select name="sub_1_field_986" className="text-uppercase" style={{ textTransform: "uppercase" }} value={IncomeCategory} onChange={(e) => { setIncomeCategory(e.target.value); OnchangeEmpCateWiseHideSHow(e.target.value); }} >
                                                                    <option value="0">-- Select An Option --</option>
                                                                    {IncomeCategoryAll.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.type}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                Transaction
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="hidden" id="sub_1_field_1028" name="sub_1_field_1028" value={transaction_id} onChange={(e) => { setTransaction(e.target.value) }} />
                                                                {TransactionNumber}
                                                            </td>
                                                        </tr>

                                                        {/* self employee wise hide show */}
                                                                
                                                        <tr style={{display: SelfEmpHideShow}} >
                                                            <td className='caption'>
                                                                PERIOD SELF-EMPLOYED	
                                                            </td>
                                                            <td>
                                                                <input name="sub_1_field_1516" type="text" value={PeriodSselfEmployee} onChange={(e) => { setPeriodSselfEmployee(e.target.value) }} className="text-uppercase"/>
                                                            </td>

                                                            <td className='caption'>
                                                                FINANCIALS SUBMITTED		
                                                            </td>
                                                            <td> 
                                                                    <input type="radio" name="sub_1_field_1517"  value={FinancialsSubmitted == '786' ? FinancialsSubmitted : '786'} checked={FinancialsSubmitted == '786'} onChange={(e) => { setFinancialsSubmitted(e.target.value) }} />
                                                                    &nbsp;No
                                                                    <br />
                                                                    <input type="radio" name="sub_1_field_1517" value={FinancialsSubmitted == '785' ? FinancialsSubmitted : '785'} checked={FinancialsSubmitted == '785'} onChange={(e) => { setFinancialsSubmitted(e.target.value) }} />
                                                                    &nbsp;Yes
                                                            </td>
                                                        </tr>

                                                        <tr style={{display: SelfEmpHideShow}}>
                                                            
                                                            <td className='caption'>
                                                                LAST 6 MONTHS BANK STATEMENTS SUBMITTED		
                                                            </td>
                                                            <td> 
                                                                <input type="radio" name="sub_1_field_1518"  value={Last6MonthStmtSubmitted == '786' ? Last6MonthStmtSubmitted : '786'} checked={Last6MonthStmtSubmitted == '786'} onChange={(e) => { setLast6MonthStmtSubmitted(e.target.value) }} />
                                                                    &nbsp;No
                                                                    <br />
                                                                <input type="radio" name="sub_1_field_1518" value={Last6MonthStmtSubmitted == '785' ? Last6MonthStmtSubmitted : '785'} checked={Last6MonthStmtSubmitted == '785'} onChange={(e) => { setLast6MonthStmtSubmitted(e.target.value) }} />
                                                                    &nbsp;Yes
                                                            </td>
                                                            
                                                            <td className='caption'>
                                                                ARE YOU AN FNB CLIENT?		
                                                            </td>
                                                            <td> 
                                                                <input type="radio" name="sub_1_field_1519"  value={FndClient == '786' ? FndClient : '786'} checked={FndClient == '786'} onChange={(e) => { setFndClient(e.target.value) }} />
                                                                    &nbsp;No
                                                                    <br />
                                                                <input type="radio" name="sub_1_field_1519" value={FndClient == '785' ? FndClient : '785'} checked={FndClient == '785'} onChange={(e) => { setFndClient(e.target.value) }} />
                                                                    &nbsp;Yes
                                                            </td>

                                                        </tr>
                                                                
                                                                {/* end */}

                                                        <tr>
                                                            <th className="caption" colSpan="4" style={{ textAlign: "center" }}>Income</th>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>Gross Monthly Basic Salary</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_988" name="sub_1_field_988" value={GrossMonthlySalary} onChange={(e) => { setGrossMonthlySalary(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(1); }} onBlur={() => { ShowZero(1); }} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>Commission (Last 6 months average)</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_989" name="sub_1_field_989" value={Commission6MonthAvg} onChange={(e) => { setCommission6MonthAvg(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(2); }} onBlur={() => { ShowZero(2); }} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>OVERTIME (LAST 6 MONTHS AVERAGE)</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_990" name="sub_1_field_990" value={Overtime6MonthAvg} onChange={(e) => { setOvertime6MonthAvg(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(3); }} onBlur={() => { ShowZero(3); }} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>MONTHLY OTHER ALLOWANCE</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1869" name="sub_1_field_1869" value={MonthlyAllowance} onChange={(e) => { setMonthlyAllowance(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(4); }} onBlur={() => { ShowZero(4); }} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>MONTHLY CAR ALLOWANCE</td>
                                                            <td id="1" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1868" name="sub_1_field_1868" value={MonthlyCarAllowance} onChange={(e) => { setMonthlyCarAllowance(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(5); }} onBlur={() => { ShowZero(5); }} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>PAYSLIP NETT MONTHLY INCOME</td>
                                                            <td id="1" style={{ textAlign: "right", width: "25%" }} >
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_993" name="sub_1_field_993" value={PaySlipMAllowance} onChange={(e) => { setPaySlipMAllowance(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(6); }} onBlur={() => { ShowZero(6); }} onKeyUp={(e) => { Calculate(e.target.value) }} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>ALL OTHER INCOME</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_996" name="sub_1_field_996" value={AllOtherIncome} onChange={(e) => { setAllOtherIncome(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(7); }} onBlur={() => { ShowZero(7); }} onKeyUp={(e) => { Calculate(e.target.value) }} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>TOTAL INCOME</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;
                                                                <NumericFormat type="hidden" name="hidden_total_income" value={HiddenTotalIncome} onChange={(e) => { setHiddenTotalIncome(e.target.value) }} fixedDecimalScale=".00" />

                                                                <NumericFormat type="text" id="sub_1_field_997" name="sub_1_field_997" value={TotalIncome} onChange={(e) => { setTotalIncome(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(8); }} onBlur={() => { ShowZero(8); }} />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th className="caption" colSpan="4" style={{ textAlign: "center" }}>EXPENDITURE</th>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>OTHER EXISTING LOANS</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                <input type="radio" name="sub_1_field_1708" value={ExistingLoan == '786' ? ExistingLoan : '786'} checked={ExistingLoan == '786'} onChange={(e) => { setExistingLoan(e.target.value) }} onClick={(e) => { OtherExistingHideShowTab("no"); ClearMonthlyInstallment(e.target.value); }} />
                                                                &nbsp;No
                                                                <br />
                                                                <input type="radio" name="sub_1_field_1708" value={ExistingLoan == '785' ? ExistingLoan : '785'} checked={ExistingLoan == '785'} onChange={(e) => { setExistingLoan(e.target.value) }} onClick={(e) => { OtherExistingHideShowTab("yes") }} />
                                                                &nbsp;Yes
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                Expenditure
                                                            </td>

                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="radio" name="sub_1_field_1862" value={Expenditure == '786' ? Expenditure : '786'} checked={Expenditure == '786'} onChange={(e) => { setExpenditure(e.target.value) }} onClick={(e) => { ExpenditureHideShowTab("no") }} />
                                                                &nbsp;No
                                                                <br />
                                                                <input type="radio" name="sub_1_field_1862" value={Expenditure == '785' ? Expenditure : '785'} checked={Expenditure == '785'} onChange={(e) => { setExpenditure(e.target.value) }} onClick={(e) => { ExpenditureHideShowTab("yes") }} />
                                                                &nbsp;Yes
                                                            </td>
                                                        </tr>                                                      

                                                        <tr style={{ display: OtherExistingHideShow }}>
                                                            <td className='caption' colSpan="4" style={{ textAlign: "center" }}>LOAN 1</td>
                                                        </tr>

                                                        <tr style={{ display: OtherExistingHideShow }}>
                                                            <td className="caption" style={{ width: "25%" }}>TO BE SETTLED	</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                <input type="radio" name="sub_1_field_1726" value={ToBeSealed1 == '786' ? ToBeSealed1 : '786'} checked={ToBeSealed1 == '786'} onChange={(e) => { setToBeSealed1(e.target.value) }} onClick={(e) => { Loan1HideShowTab("no") }} /> No
                                                                <br />
                                                                <input type="radio" name="sub_1_field_1726" value={ToBeSealed1 == '785' ? ToBeSealed1 : '785'} checked={ToBeSealed1 == '785'} onChange={(e) => { setToBeSealed1(e.target.value) }} onClick={(e) => { Loan1HideShowTab("yes") }} />
                                                                Yes
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>

                                                            </td>

                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                            </td>
                                                        </tr>
                                                        <tr className='caption' colSpan="4" style={{ display: Loan1HideShow }}>

                                                            <td colSpan={4} style={{ color: "red", textAlign: "center", fontSize: "10px" }}>* if loan to be settled do not complete monthly installment value below</td>
                                                        </tr>

                                                        <tr style={{ display: OtherExistingHideShow }}>
                                                            <td className="caption" style={{ width: "25%" }}>FINANCIAL PROVIDER</td>
                                                            <td id="1" style={{ textAlign: "left", width: "25%" }}>
                                                                <select name="sub_1_field_1709" className="text-uppercase" style={{ textTransform: "uppercase" }} value={FinantialProviderLoan1} onChange={(e) => setFinantialProviderLoan1(e.target.value)} >
                                                                    <option value="0">-- Select An Option --</option>
                                                                    {FinantialProviderAll.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.type}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>MONTHLY INSTALMENT</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1711" name="sub_1_field_1711" value={MonthlyInvestment1} onChange={(e) => { setMonthlyInvestment1(e.target.value) }} style={{ textAlign: 'right', width: '12.4em', pointerEvents: MonthinstallReadonly }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(9); }} onBlur={() => { ShowZero(9); }} onKeyUp={(e) => { Calculate2(e.target.value) }} />
                                                            </td>
                                                        </tr>

                                                        <tr style={{ display: OtherExistingHideShow }}>
                                                            <td className='caption' colSpan="4" style={{ textAlign: "center" }}>LOAN 2</td>
                                                        </tr>

                                                        <tr style={{ display: OtherExistingHideShow }}>
                                                            <td className="caption" style={{ width: "25%" }}>TO BE SETTLED	</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                <input type="radio" name="sub_1_field_1727" value={ToBeSealed2 == '786' ? ToBeSealed2 : '786'} checked={ToBeSealed2 == '786'} onChange={(e) => { setToBeSealed2(e.target.value) }} onClick={(e) => { Loan2HideShowTab("no") }} />&nbsp;No
                                                                <br />
                                                                <input type="radio" name="sub_1_field_1727" value={ToBeSealed2 == '785' ? ToBeSealed2 : '785'} checked={ToBeSealed2 == '785'} onChange={(e) => { setToBeSealed2(e.target.value) }} onClick={(e) => { Loan2HideShowTab("yes") }} />
                                                                &nbsp;Yes
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>

                                                            </td>

                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                            </td>

                                                        </tr>

                                                        <tr className='caption' colSpan="4" style={{ display: Loan2HideShow }} >
                                                            <td colSpan={4} style={{ color: "red", textAlign: "center", fontSize: "10px" }}>* if loan to be settled do not complete monthly installment value below</td>
                                                        </tr>

                                                        <tr style={{ display: OtherExistingHideShow }}>
                                                            <td className="caption" style={{ width: "25%" }}>FINANCIAL PROVIDER</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <select name="sub_1_field_1718" className="text-uppercase" style={{ textTransform: "uppercase" }} value={FinantialProviderLoan2} onChange={(e) => setFinantialProviderLoan2(e.target.value)} >
                                                                    <option value="0">-- Select An Option --</option>
                                                                    {FinantialProviderAll.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.type}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>MONTHLY INSTALMENT</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1717" name="sub_1_field_1717" value={MonthlyInvestment2} onChange={(e) => { setMonthlyInvestment2(e.target.value) }} style={{ textAlign: 'right', width: '12.4em', pointerEvents: MonthinstallReadonly1 }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(10); }} onBlur={() => { ShowZero(10); }} onKeyUp={(e) => { Calculate2(e.target.value) }} />
                                                            </td>
                                                        </tr>

                                                        <tr style={{ display: ExpenditureHideShow }}>
                                                            <td className='caption' colSpan="4" style={{ textAlign: "center" }}>EXPENDITURE</td>
                                                        </tr>

                                                        <tr style={{ display: ExpenditureHideShow }}>
                                                            <td className="caption" style={{ width: "25%" }}>RENT AMOUNT</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1822" name="sub_1_field_1822" value={RentAmount} onChange={(e) => { setRentAmount(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(21); }} onBlur={() => { ShowZero(21); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>TELEPHONE</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1823" name="sub_1_field_1823" value={Telephone} onChange={(e) => { setTelephone(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(22); }} onBlur={() => { ShowZero(22); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                            </td>
                                                        </tr>
                                                        <tr style={{ display: ExpenditureHideShow }}>
                                                            <td className="caption" style={{ width: "25%" }}>RATE; TAXES; WATER AND LIGHTS</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1010" name="sub_1_field_1010" value={RateTaxWater} onChange={(e) => { setRateTaxWater(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(23); }} onBlur={() => { ShowZero(23); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>FUEL</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1824" name="sub_1_field_1824" value={Fuel} onChange={(e) => { setFuel(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(24); }} onBlur={() => { ShowZero(24); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                            </td>
                                                        </tr>
                                                        <tr style={{ display: ExpenditureHideShow }}>
                                                            <td className="caption" style={{ width: "25%" }}>VEHICLE INSTALLMENTS</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1825" name="sub_1_field_1825" value={VehicleInstallment} onChange={(e) => { setVehicleInstallment(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(25); }} onBlur={() => { ShowZero(25); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>FOOD & ENTERTAINMENT</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1826" name="sub_1_field_1826" value={FoodEntertainment} onChange={(e) => { setFoodEntertainment(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(26); }} onBlur={() => { ShowZero(26); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                            </td>
                                                        </tr>
                                                        <tr style={{ display: ExpenditureHideShow }}>
                                                            <td className="caption" style={{ width: "25%" }}>PERSONAL LOAN REPAYMENTS</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1827" name="sub_1_field_1827" value={LoanPayment} onChange={(e) => { setLoanPayment(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(27); }} onBlur={() => { ShowZero(27); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>EDUCATION COSTS</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1828" name="sub_1_field_1828" value={EducationCost} onChange={(e) => { setEducationCost(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(28); }} onBlur={() => { ShowZero(28); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                            </td>
                                                        </tr>
                                                        <tr style={{ display: ExpenditureHideShow }}>
                                                            <td className="caption" style={{ width: "25%" }}>CREDIT CARD REPAYMENT</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1829" name="sub_1_field_1829" value={CardRePayment} onChange={(e) => { setCardRePayment(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(29); }} onBlur={() => { ShowZero(29); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>MAINTENANCE</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1014" name="sub_1_field_1014" value={Maintenance} onChange={(e) => { setMaintenance(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(30); }} onBlur={() => { ShowZero(30); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                            </td>
                                                        </tr>
                                                        <tr style={{ display: ExpenditureHideShow }}>
                                                            <td className="caption" style={{ width: "25%" }}>OVERDRAFT REPAYMENT</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1013" name="sub_1_field_1013" value={Overdraft} onChange={(e) => { setOverdraft(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(31); }} onBlur={() => { ShowZero(31); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>HOUSEHOLD EXPENSES</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1831" name="sub_1_field_1831" value={Household} onChange={(e) => { setHousehold(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(32); }} onBlur={() => { ShowZero(32); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                            </td>
                                                        </tr>
                                                        <tr style={{ display: ExpenditureHideShow }}>
                                                            <td className="caption" style={{ width: "25%" }}>FURNITURE ACCOUNT PAYMENT	</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1832" name="sub_1_field_1832" value={Furniture} onChange={(e) => { setFurniture(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(33); }} onBlur={() => { ShowZero(33); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>OTHER</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1833" name="sub_1_field_1833" value={Other1} onChange={(e) => { setOther1(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(34); }} onBlur={() => { ShowZero(34); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                            </td>
                                                        </tr>
                                                        <tr style={{ display: ExpenditureHideShow }}>
                                                            <td className="caption" style={{ width: "25%" }}>CLOTHING ACCOUNT PAYMENT	</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1009" name="sub_1_field_1009" value={Clothing} onChange={(e) => { setClothing(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(35); }} onBlur={() => { ShowZero(35); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>OTHER</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1834" name="sub_1_field_1834" value={Other2} onChange={(e) => { setOther2(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(36); }} onBlur={() => { ShowZero(36); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                            </td>
                                                        </tr>
                                                        <tr style={{ display: ExpenditureHideShow }}>
                                                            <td className="caption" style={{ width: "25%" }}>POLICY PAYMENT	</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1835" name="sub_1_field_1835" value={PaymentPolicy} onChange={(e) => { setPaymentPolicy(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(37); }} onBlur={() => { ShowZero(37); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>OTHER</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1836" name="sub_1_field_1836" value={Other3} onChange={(e) => { setOther3(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(38); }} onBlur={() => { ShowZero(38); }} onKeyUp={(e) => { Calculate1(e.target.value) }} />
                                                            </td>
                                                        </tr>
                                                        <tr style={{ display: ExpenditureHideShow }}>
                                                            <td className="caption" style={{ width: "25%" }}>TOTAL EXPENDITURE	</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1842" name="sub_1_field_1842" value={TotalExpenditure} onChange={(e) => { setTotalExpenditure(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(39); }} onBlur={() => { ShowZero(39); }} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}></td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th className="caption" colSpan="4" style={{ textAlign: "center" }}>
                                                                EXISTING / PREVIOUS ACCOUNTS
                                                            </th>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                <span id="sub_1_label_986" title="Income field is mandatory">EXISTING / PREVIOUS VEHICLE FINANCE COMPANY </span>
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                <select name="sub_1_field_1475" className="text-uppercase" style={{ textTransform: "uppercase" }} value={FinantialCompany} onChange={(e) => setFinantialCompany(e.target.value)} >
                                                                    <option value="0">-- Select An Option --</option>
                                                                    {FinantialCompanyAll.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.type}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>

                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                ACCOUNT NUMBER
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_1477" value={AccountNumber} onChange={(e) => { setAccountNumber(e.target.value) }} className="text-uppercase" />

                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                PREVIOUS FINANCE COMPANY BRANCH
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_1476" value={previousBranch} onChange={(e) => { setpreviousBranch(e.target.value) }} className="text-uppercase" />

                                                            </td>

                                                            <td className="caption" style={{ width: "25%" }}>INSTALMENT	</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1478" name="sub_1_field_1478" value={Installment} onChange={(e) => { setInstallment(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(11); }} onBlur={() => { ShowZero(11); }} onKeyUp={(e) => { Calculate2(e.target.value) }} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>TO BE SETTLED	</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                <input type="radio" name="sub_1_field_1481" value={ToBeSealed3 == '786' ? ToBeSealed3 : '786'} checked={ToBeSealed3 == '786'} onChange={(e) => { setToBeSealed3(e.target.value) }} onClick={(e) => { ClearInstallemnt(e.target.value) }}/>
                                                                &nbsp;No
                                                                <br />
                                                                <input type="radio" name="sub_1_field_1481" value={ToBeSealed3 == '785' ? ToBeSealed3 : '785'} checked={ToBeSealed3 == '785'} onChange={(e) => { setToBeSealed3(e.target.value) }} onClick={(e) => { ClearInstallemnt(e.target.value) }} />
                                                                &nbsp;Yes
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>SETTLEMENT	</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1479" name="sub_1_field_1479" value={Settlement} onChange={(e) => { setSettlement(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(12); }} onBlur={() => { ShowZero(12); }} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%", color: MonthLeftColor }}>
                                                                MONTHS LEFT<span style={{ color: "red" }}>*</span>
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_1480" value={MonthLeft} onChange={(e) => { setMonthLeft(e.target.value) }} className="text-uppercase" />

                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th className="caption" colSpan="4" style={{ textAlign: "center" }}>
                                                                PROPERTY
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%", border: OwnHomeColor }}>DO YOU OWN YOUR HOME?</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="radio" name="sub_1_field_1483" value="786" onChange={(e) => setOwnHome(786)} checked={OwnHome == '786'} onClick={(e) => { OtherFieldHideShow(e.target.value); }} />
                                                                &nbsp;No
                                                                <br />
                                                                <input type="radio" name="sub_1_field_1483" value="785" onChange={(e) => setOwnHome(785)} checked={OwnHome == '785'} onClick={(e) => { OtherFieldHideShow(e.target.value); }} />
                                                                &nbsp;Yes
                                                            </td>
                                                            <td className="caption"></td>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                        {/* {OwnHome === 0 ?
                                                                        <> */}
                                                        <tr style={{ display: PropertyHideShow }} >
                                                            <td className="caption" style={{ width: "25%" }}>RENTAL / LODGING VALUE	<span style={{ color: "red" }}>*</span>	</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1712" name="sub_1_field_1712" value={RentalValue} onChange={(e) => { setRentalValue(e.target.value) }} style={{ textAlign: 'right', width: '12.4em', border: RentalValueBorder }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(13); }} onBlur={() => { ShowZero(13); }} onKeyUp={(e) => { Calculate2(e.target.value); ChangeForsurpulse(e.target.value); }} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                LANDLORD NAME
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_1493" value={LandlordName} onChange={(e) => { setLandlordName(e.target.value) }} className="text-uppercase" />

                                                            </td>
                                                        </tr>

                                                        <tr style={{ display: PropertyHideShow }}>
                                                            <td className="caption" style={{ width: "25%" }}></td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>

                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                LANDLORD ADDRESS
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_1494" value={LandlordAddress} onChange={(e) => { setLandlordAddress(e.target.value) }} className="text-uppercase" />

                                                            </td>
                                                        </tr>
                                                        {/* </> : */}
                                                        {/* <> */}
                                                        {/* --------------- other bank data ----------------------*/}
                                                        <tr style={{ display: PropertyHideShow1 }} >
                                                            <td className="caption" style={{ width: "25%" }}>BOND MONTHLY INSTALMENT <span style={{ color: "red" }}>*</span> </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;
                                                               
                                                                <NumericFormat type="text" id="sub_1_field_1713" name="sub_1_field_1713" value={MonthlyBond} onChange={(e) => { setMonthlyBond(e.target.value) }} style={{ textAlign: 'right', width: '12.4em', border: BondMonthlyBorder }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(53); }} onBlur={() => { ShowZero(53); }} onKeyUp={(e) => { Calculate2(e.target.value) }} />

                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                BOND HOLDER (BANK)
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" id="sub_1_field_1488" name="bond_holder" value={BondHolder} onChange={(e) => { setBondHolder(e.target.value) }} className="text-uppercase" />

                                                            </td>
                                                        </tr>
                                                        <tr style={{ display: PropertyHideShow1 }} >
                                                            <td className="caption" style={{ width: "25%" }}>PURCHASE PRICE	</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;
                                                                {/* <input className="text_currency" size="15" style={{ textAlign: "right", textTransform: "uppercase" }} type="text" name="purchase_price" value={PurchasePrice} onChange={(e) => { setPurchasePrice(e.target.value) }} /> */}
                                                                <NumericFormat type="text" id="sub_1_field_1491" name="sub_1_field_1491" value={PurchasePrice} onChange={(e) => { setPurchasePrice(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(54); }} onBlur={() => { ShowZero(54); }} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                PURCHASE DATE
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                {PurchaseDateFlag == 1 ?
                                                                    <DatePicker id="sub_1_field_1489" selected={PurchaseDate} name="sub_1_field_1489" onChange={(date) => { setPurchaseDate(date); setPurchaseDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" filterDate={d => {
                                                                        return new Date() > d;
                                                                    }} />
                                                                    :
                                                                    <DatePicker id="sub_1_field_1489" value={PurchaseDate} name="sub_1_field_1489" onChange={(date) => { setPurchaseDate(date); setPurchaseDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" filterDate={d => {
                                                                        return new Date() > d;
                                                                    }} />
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr style={{ display: PropertyHideShow1 }} >
                                                            <td className="caption" style={{ width: "25%" }}>PRESENT MARKET VALUE	</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;
                                                                {/* <input className="text_currency" size="15" style={{ textAlign: "right", textTransform: "uppercase" }} type="text" name="markrt_value" value={MarketValue} onChange={(e) => { setMarketValue(e.target.value) }} /> */}
                                                                <NumericFormat type="text" id="sub_1_field_1492" name="sub_1_field_1492" value={MarketValue} onChange={(e) => { setMarketValue(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(55); }} onBlur={() => { ShowZero(55); }} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                IN WHOSE NAME?
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" id="sub_1_field_1485" name="poverty_name" value={PovertyName} onChange={(e) => { setPovertyName(e.target.value) }} className="text-uppercase" />

                                                            </td>
                                                        </tr>
                                                        <tr style={{ display: PropertyHideShow1 }}>
                                                            <td className="caption" style={{ width: "25%" }}>OUTSTANDING BOND AMOUNT</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;
                                                                {/* <input className="text_currency" size="15" style={{ textAlign: "right", textTransform: "uppercase" }} type="text" name="bond_amount" value={BondAmount} onChange={(e) => { setBondAmount(e.target.value) }} /> */}
                                                                <NumericFormat type="text" id="sub_1_field_1490" name="sub_1_field_1490" value={BondAmount} onChange={(e) => { setBondAmount(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(56); }} onBlur={() => { ShowZero(56); }} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                STAND NUMBER
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" id="sub_1_field_1486" name="stand_number" value={StandNumber} onChange={(e) => { setStandNumber(e.target.value) }} className="text-uppercase" />

                                                            </td>
                                                        </tr>

                                                        <tr style={{ display: PropertyHideShow1 }}>
                                                            <td className="caption" style={{ width: "25%" }}></td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                SUBURB
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" id="sub_1_field_1487" name="suburb" value={Suburb} onChange={(e) => { setSuburb(e.target.value) }} className="text-uppercase" />

                                                            </td>
                                                        </tr>
                                                        {/* </>
                                                                    } */}
                                                        <tr>
                                                            <th className="caption" colSpan="4" style={{ textAlign: "center" }}>
                                                                TOTAL DISPOSABLE INCOME
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>{DefectSurpulse}<span style={{ color: "red" }}> *</span>	</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;
                                                                <NumericFormat type="hidden" name="hidden_sirplus" value={HiddenSurplus} onChange={(e) => { setHiddenSurplus(e.target.value) }} decimalScale={2} fixedDecimalScale=".00" />

                                                                <NumericFormat type="text" id="sub_1_field_1019" name="sub_1_field_1019" value={Surplus} onChange={(e) => { setSurplus(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(14); }} onBlur={() => { ShowZero(14); }} />
                                                            </td>
                                                        </tr>

                                                        <tr className="caption text-center" >
                                                            <td colSpan={4} style={{ textAlign: "center" }}>
                                                                {PrintButtonData != '' ?
                                                                    <input type="submit" name="command" value={PrintButton} onClick={(e) => { setPrintButton(e.target.value); setClientsFinanceDetSave(e.target.value);  }} />
                                                                    : null}
                                                                &nbsp;

                                                                <input type="submit" name="Save" value="Save" onClick={(e) => { setClientsFinanceDetSave(e.target.value) }} />&nbsp;
                                                                <input type="submit" name="Save and Exit" value="Save and Exit" onClick={(e) => { setClientsFinanceDetSave(e.target.value) }} />&nbsp;

                                                                <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                    <input type="button" className="p-relative" value="Cancel" />
                                                                </Link>
                                                            </td>
                                                        </tr>

                                                        {/* </> : null } */}
                                                    </tbody>
                                                </table>
                                            </form>
                                            {/* --------- Loan Specifics -------------------------------- */}
                                            <form name="clientDetails" method="post" onSubmit={handleLoanDetailsSubmit}>
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="800" style={{ display: div_id4 }}>
                                                    <tbody>

                                                        <tr className="caption">
                                                            <th colSpan={4} style={{ textAlign: 'center' }}>LOAN SPECIFICS
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>Transaction</td><td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="hidden" id="sub_1_field_1028" name="sub_1_field_1028" value={LoanTransaction} onChange={(e) => { setLoanTransaction(e.target.value) }} />
                                                                {LoanTransactionDisplay}
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                COMMENCEMENT DATE
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                {LoanCommencementDateFlag == 1 ?
                                                                    <DatePicker id="sub_1_field_1533" selected={CommencementDate} name="sub_1_field_1533" onChange={(date) => { setCommencementDate(date); setLoanCommencementDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                    :
                                                                    <DatePicker id="sub_1_field_1533" value={CommencementDate} name="sub_1_field_1533" onChange={(date) => { setCommencementDate(date); setLoanCommencementDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>INTEREST TYPE</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                <input type="radio" name="sub_1_field_1247" value={InterestType == '1' ? InterestType : '1'} checked={InterestType == '1'} onChange={(e) => { setInterestType(e.target.value) }} />
                                                                &nbsp; FIXED
                                                                <br />
                                                                <input type="radio" name="sub_1_field_1247" value={InterestType == '2' ? InterestType : '2'} checked={InterestType == '2'} onChange={(e) => { setInterestType(e.target.value) }} />
                                                                &nbsp; LINKED
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                FIRST DEBIT ORDER/ INSTALMENT DATE
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                {LoanFirstDebitOrderDateFlag == 1 ?
                                                                    <DatePicker id="sub_1_field_1510" selected={FirstDebitOrder} name="sub_1_field_1510" onChange={(date) => { setFirstDebitOrder(date); setLoanFirstDebitOrderDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                        return new Date() > d;
                                                                    }} />
                                                                    :
                                                                    <DatePicker id="sub_1_field_1510" value={FirstDebitOrder} name="sub_1_field_1510" onChange={(date) => { setFirstDebitOrder(date); setLoanFirstDebitOrderDateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                        return new Date() > d;
                                                                    }} />
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>SALE TYPE</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                <input type="radio" name="sub_1_field_1246" value={SaleType == '1' ? SaleType : '1'} checked={SaleType == '1'} onChange={(e) => { setSaleType(e.target.value) }} />
                                                                &nbsp; FLEET
                                                                <br />
                                                                <input type="radio" name="sub_1_field_1246" value={SaleType == '2' ? SaleType : '2'} checked={SaleType == '2'} onChange={(e) => { setSaleType(e.target.value) }} />
                                                                &nbsp; VEHICLE
                                                            </td>

                                                            <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                FINANCE TYPE
                                                            </td>

                                                            <td style={{ textAlign: "left", width: "25%" }}>
                                                                <select name="sub_1_field_1509" className="text-uppercase" style={{ textTransform: "uppercase" }} value={FinanceType} onChange={(e) => setFinanceType(e.target.value)} >
                                                                    <option value="0">-- Select An Option --</option>
                                                                    {FinanceTypeAll.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.type}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                TERM (MONTHS)
                                                            </td>

                                                            <td style={{ textAlign: "left", width: "25%" }}>
                                                                <select name="sub_1_field_1031" className="text-uppercase" style={{ textTransform: "uppercase" }} value={TermMonth} onChange={(e) => setTermMonth(e.target.value)} >
                                                                    <option value="0">-- Select An Option --</option>
                                                                    {TermMonthAll.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.month}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>

                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                PREFERRED INTEREST RATE (%) <span style={{ color: "red" }}>*</span>
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_1030" value={InterestRate} onChange={(e) => { setInterestRate(e.target.value) }} className="text-uppercase" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                PRE ARRANGED SCHEME PDP
                                                            </td>
                                                            <td style={{ textAlign: "left", width: "25%" }}>

                                                                <input type="checkbox" name="sub_1_field_1872" value={PreArrangeSceme} onChange={(e) => { setPreArrangeSceme(!PreArrangeSceme) }} checked={PreArrangeSceme == '1' ? true : false} />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>LOAN EQUITY</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input id="sub_1_field_1456" type="hidden" name="sub_1_field_1456" value={LoanEquity} onChange={(e) => { setLoanEquity(e.target.value) }} />
                                                                {LoanEquity} %
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>AFFORDABILITY</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input id="sub_1_field_1457" type="hidden" name="sub_1_field_1457" value={LoanAffordability} onChange={(e) => { setLoanAffordability(e.target.value) }} className="text-uppercase" />
                                                                {LoanAffordability} %
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>DEPOSIT PERCENTAGE	</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input id="sub_1_field_1459" type="hidden" name="sub_1_field_1459" value={LoanDepositPercentage} onChange={(e) => { setLoanDepositPercentage(e.target.value) }} className="text-uppercase" />
                                                                {LoanDepositPercentage} %
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>AFFORDABILITY WITH INSURANCE</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input id="sub_1_field_1458" type="hidden" name="sub_1_field_1458" value={LoanAfforabilityInsurance} onChange={(e) => { setLoanAfforabilityInsurance(e.target.value) }} />
                                                                {LoanAfforabilityInsurance} %
                                                            </td>

                                                            <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                BUISNESS SEGMENT
                                                            </td>

                                                            <td style={{ textAlign: "left", width: "25%" }}>
                                                                <select name="sub_1_field_1813" className="text-uppercase" style={{ textTransform: "uppercase" }} value={BusinessSegment} onChange={(e) => setBusinessSegment(e.target.value)} >
                                                                    <option value="0">-- Select An Option --</option>
                                                                    {BusinessSegmentAll.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.segment}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                CONSUMER SEGMENT
                                                            </td>

                                                            <td style={{ textAlign: "left", width: "25%" }}>
                                                                <select name="sub_1_field_1810" className="text-uppercase" style={{ textTransform: "uppercase" }} value={ConsumerSegment} onChange={(e) => setConsumerSegment(e.target.value)} >
                                                                    <option value="0">-- Select An Option --</option>
                                                                    {ConsumerSegmentAll.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.segment}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>

                                                            <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                            </td>

                                                            <td style={{ textAlign: "left", width: "25%" }}>
                                                            </td>
                                                        </tr>

                                                        <tr className="caption">
                                                            <th colSpan={4} style={{ textAlign: 'center' }}>TRANSACTION SPECIFICS
                                                            </th>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>ASSET AMOUNT</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                <input type="hidden" id="sub_1_field_1039" name="sub_1_field_1039" value={LoanAssetAmount} onChange={(e) => { setLoanAssetAmount(e.target.value) }} className="text-uppercase" />
                                                                P {LoanAssetAmount}
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>INSURANCE PRODUCTS	</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                <input type="hidden" id="sub_1_field_1450" name="sub_1_field_1450" value={LoanInsuranceProduct} onChange={(e) => { setLoanInsuranceProduct(e.target.value) }} className="text-uppercase" />
                                                                P {LoanInsuranceProduct}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>NON-INSURANCE PRODUCTS	</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                <input type="hidden" id="sub_1_field_1451" name="sub_1_field_1451" value={LoanNonInsuranceProduct} onChange={(e) => { setLoanNonInsuranceProduct(e.target.value) }} />
                                                                P {LoanNonInsuranceProduct}
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                COMPREHENSIVE INSURANCE
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                <input type="hidden" id="sub_1_field_1452" name="sub_1_field_1452" value={LoanCompersiveInsurance} onChange={(e) => { setLoanCompersiveInsurance(e.target.value) }} />
                                                                P {LoanCompersiveInsurance}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>DISCOUNT</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                <input type="hidden" id="sub_1_field_1042" name="sub_1_field_1042" value={LoanDiscount} onChange={(e) => { setLoanDiscount(e.target.value) }} />
                                                                P {LoanDiscount}
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>CASH/CHEQUE/EFT DEPOSIT VALUE</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                <input type="hidden" id="sub_1_field_1043" name="sub_1_field_1043" value={LoanCashDepositValue} onChange={(e) => { setLoanCashDepositValue(e.target.value) }} />
                                                                P {LoanCashDepositValue}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>NETT TRADE IN DEPOSIT VALUE</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                <input type="hidden" id="sub_1_field_1449" name="sub_1_field_1449" value={LoanNettTradeDeposit} onChange={(e) => { setLoanNettTradeDeposit(e.target.value) }} />
                                                                P {LoanNettTradeDeposit}
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>BANK DOCUMENTATION FEE	</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }} >
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_1448" name="sub_1_field_1448" value={BankFee} onChange={(e) => { setBankFee(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(51); }} onBlur={() => { ShowZero(51); }} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>TRANSACTION FEE</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                <input type="hidden" id="sub_1_field_1453" name="sub_1_field_1453" value={LoanTransactionFee} onChange={(e) => { setLoanTransactionFee(e.target.value) }} />
                                                                P {LoanTransactionFee}
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>FINANCED AMOUNT</td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                <input type="hidden" id="sub_1_field_1029" name="sub_1_field_1029" value={LoanFinanceAmount} onChange={(e) => { setLoanFinanceAmount(e.target.value) }} />
                                                                P {LoanFinanceAmount}
                                                            </td>
                                                        </tr>

                                                        <tr className="caption text-center" >
                                                            <td colSpan={4} style={{ textAlign: "center" }}>
                                                                {PrintButtonData != '' ?
                                                                    <input type="submit" name="command" value={PrintButton} onClick={(e) => { setPrintButton(e.target.value); setLoanSpecificsDetSave(e.target.value); }} />
                                                                    : null}
                                                                &nbsp;

                                                                <input type="submit" name="Save" value="Save" onClick={(e) => { setLoanSpecificsDetSave(e.target.value) }} />&nbsp;
                                                                <input type="submit" name="Save and Exit" value="Save and Exit" onClick={(e) => { setLoanSpecificsDetSave(e.target.value) }} />&nbsp;

                                                                <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                    <input type="button" className="p-relative" value="Cancel" />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </form>
                                            {/* ----------------- Spouse Details ----------------------------------  */}
                                            <form name="clientDetails" method="post" onSubmit={handleSpouseDetailsSubmit} >
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="800" style={{ display: div_id5 }}>
                                                    <tbody>

                                                        <tr className="caption">
                                                            <th colSpan={4} style={{ textAlign: 'center' }}>SPOUSE DETAILS
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                SPOUSE DETAILS
                                                            </td>

                                                            <td style={{ textAlign: "left", width: "25%" }}>

                                                                <select id="test" name="sub_1_field_952" className="text-uppercase" value={SpouseDetails} onChange={(e) => { setSpouseDetails(e.target.value) }} >
                                                                    <option value="0">-- Select An Option --</option>
                                                                    {SpouseDetailsAll != null ?
                                                                        SpouseDetailsAll.map((item) => {
                                                                            return (
                                                                                <option key={item.id} value={item.id}>
                                                                                    {item.name}
                                                                                </option>
                                                                            );
                                                                        })
                                                                        : null}
                                                                </select>
                                                            </td>
                                                            <td className='caption' style={{ textAlign: "left", width: "25%" }}>
                                                                TITLE
                                                            </td>

                                                            <td style={{ textAlign: "left", width: "25%" }}>
                                                                <select id="test" name="sub_1_field_954" className="text-uppercase" value={SpouseTitle} onChange={(e) => { setSpouseTitle(e.target.value) }} >
                                                                    <option value="0">-- Select An Option --</option>
                                                                    {TitleAll.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.title}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                FIRST NAME
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_955" value={SpouseFirstName} onChange={(e) => { setSpouseFirstName(e.target.value) }} className="text-uppercase" />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                INITIALS
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_1075" value={SpouseInitial} onChange={(e) => { setSpouseInitial(e.target.value) }} className="text-uppercase" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                SURNAME
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_956" value={SpouseSurname} onChange={(e) => { setSpouseSurname(e.target.value) }} className="text-uppercase" />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                ID NUMBER
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_957" value={SpouseIdNumber} onChange={(e) => { setSpouseIdNumber(e.target.value) }} className="text-uppercase" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                DATE OF BIRTH
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                {SpouseBirthdateFlag == 1 ?
                                                                    <DatePicker id="sub_1_field_958" selected={SpouseDOB} name="sub_1_field_958" onChange={(date) => { setSpouseDOB(date); setSpouseBirthdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                        return new Date() > d;
                                                                    }} />
                                                                    :
                                                                    <DatePicker id="sub_1_field_958" value={SpouseDOB} name="sub_1_field_958" onChange={(date) => { setSpouseDOB(date); setSpouseBirthdateFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                        return new Date() > d;
                                                                    }} />
                                                                }
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                GENDER
                                                            </td>
                                                            <td id="1" style={{ textAlign: "left", width: "25%" }}>
                                                                <select name="sub_1_field_965" id="gender_select" className="text-uppercase" value={SpouseGender} onChange={(e) => setSpouseGender(e.target.value)} >
                                                                    <option value="0">-- Select An Option --</option>
                                                                    {GenderAll.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.gender}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                HOME PHONE
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_959" value={SpouseHomePhone} onChange={(e) => { setSpouseHomePhone(e.target.value) }} className="text-uppercase" />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                WORK PHONE
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_960" value={SpouseWorkPhone} onChange={(e) => { setSpouseWorkPhone(e.target.value) }} className="text-uppercase" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                MOBILE PHONE
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_961" value={SpouseMobilePhone} onChange={(e) => { setSpouseMobilePhone(e.target.value) }} className="text-uppercase" />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                E-MAIL
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_962" value={SpouseEmail} onChange={(e) => { setSpouseEmail(e.target.value) }} className="text-uppercase" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                PHYSICAL ADDRESS LINE 1
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_1503" value={SpousePhysicalAdd1} onChange={(e) => { setSpousePhysicalAdd1(e.target.value) }} className="text-uppercase" />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                POSTAL ADDRESS LINE 1
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_1506" value={SpousePostalAdd1} onChange={(e) => { setSpousePostalAdd1(e.target.value) }} className="text-uppercase" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                PHYSICAL ADDRESS LINE 2
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_1504" value={SpousePhysicalAdd2} onChange={(e) => { setSpousePhysicalAdd2(e.target.value) }} className="text-uppercase" />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                POSTAL ADDRESS LINE 2
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_1507" value={SpousePostalAdd2} onChange={(e) => { setSpousePostalAdd2(e.target.value) }} className="text-uppercase" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                PHYSICAL ADDRESS CITY
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_1514" value={SpousePhysicalAddCity} onChange={(e) => { setSpousePhysicalAddCity(e.target.value) }} className="text-uppercase" />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                POSTAL ADDRESS CITY
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_1515" value={SpousePostalAddCity} onChange={(e) => { setSpousePostalAddCity(e.target.value) }} className="text-uppercase" />
                                                            </td>
                                                        </tr>
                                                        <tr className="caption">
                                                            <th colSpan={4} style={{ textAlign: 'center' }}>ADDITIONAL SPOUSE DETAILS
                                                            </th>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                RESIDENCY STATUS
                                                            </td>
                                                            <td id="1" style={{ textAlign: "left", width: "25%" }}>
                                                                <select name="sub_1_field_947" id="sub_1_field_947" className="text-uppercase" value={SpouseResidencyStatus} onChange={(e) => { setSpouseResidencyStatus(e.target.value) }}>
                                                                    <option value="0">-- Select an option --</option>
                                                                    {ResidencyStatusAll.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.res_status}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                OCCUPATION
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_1468" value={SpouseOccupation} onChange={(e) => { setSpouseOccupation(e.target.value) }} className="text-uppercase" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                EMPLOYER NAME
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_1469" value={SpouseEmployerName} onChange={(e) => { setSpouseEmployerName(e.target.value) }} className="text-uppercase" />
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                EMPLOYER ADDRESS
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>
                                                                <input type="text" name="sub_1_field_1470" value={SpouseEmployerAdd} onChange={(e) => { setSpouseEmployerAdd(e.target.value) }} className="text-uppercase" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                DATE EMPLOYED
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "left", width: "25%" }}>

                                                                {SpouseDateEmployeeFlag == 1 ?
                                                                    <DatePicker id="sub_1_field_1471" selected={SpouseDate} name="sub_1_field_1471" onChange={(date) => { setSpouseDate(date); setSpouseDateEmployeeFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                        return new Date() > d;
                                                                    }} />
                                                                    :
                                                                    <DatePicker id="sub_1_field_1471" value={SpouseDate} name="sub_1_field_1471" onChange={(date) => { setSpouseDate(date); setSpouseDateEmployeeFlag(1) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" filterDate={d => {
                                                                        return new Date() > d;
                                                                    }} />
                                                                }
                                                            </td>
                                                            <td className="caption" style={{ width: "25%" }}>
                                                                SPOUSE NETT INCOME
                                                            </td>
                                                            <td id="1" nowrap="nowrap" style={{ textAlign: "right", width: "25%" }}>
                                                                P&nbsp;

                                                                <NumericFormat type="text" id="sub_1_field_994" name="sub_1_field_994" value={SpouseIncome} onChange={(e) => { setSpouseIncome(e.target.value) }} style={{ textAlign: 'right', width: '12.4em' }} thousandSeparator={true} fixedDecimalScale=".00" decimalScale={2} autoComplete="off" onClick={() => { RemoveZeroVal(52); }} onBlur={() => { ShowZero(52); }} />
                                                            </td>
                                                        </tr>

                                                        <tr className="caption text-center" >
                                                            <td colSpan={4} style={{ textAlign: "center" }}>

                                                                {PrintButtonData != '' ?
                                                                    <input type="submit" name="command" value={PrintButton} onClick={(e) => { setPrintButton(e.target.value); setSpouseDetSave(e.target.value) }} />
                                                                    : null}
                                                                &nbsp;

                                                                <input type="submit" name="Save" value="Save" onClick={(e) => { setSpouseDetSave(e.target.value) }} />&nbsp;

                                                                <input type="submit" name="Save and Exit" value="Save and Exit" onClick={(e) => { setSpouseDetSave(e.target.value) }} />&nbsp;

                                                                <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                    <input type="button" className="p-relative" value="Cancel" />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </form>
                                            {/* ------------- Contact Details ------------  */}
                                            <form name="contact_details" method="post" onSubmit={handleAddContact} >
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="1708" style={{ display: div_id6 }}>
                                                    <tbody>
                                                        <tr className="caption">
                                                            <th colSpan={11} style={{ textAlign: 'center' }}>CONTACT DETAILS
                                                            </th>
                                                        </tr>
                                                        <tr className="caption">
                                                            <td>CONTACT <span style={{ color: "red" }}>*</span></td>
                                                            <td>RELATIONSHIP</td>
                                                            <td>TITLE</td>
                                                            <td>FIRST NAME</td>
                                                            <td>SURNAME</td>
                                                            <td>HOME PHONE</td>
                                                            <td>WORK PHONE</td>
                                                            <td>PHYSICAL ADDRESS</td>
                                                            <td>EMPLOYER NAME</td>
                                                            <td>DEPARTMENT</td>
                                                            <td>&nbsp;</td>
                                                        </tr>

                                                        {ContactDetailsArray ?
                                                            ContactDetailsArray.map((item, index) => {

                                                                const { contact_details1, relationship1, title1, firstname, surname, home_phone, work_phone, physical_address, employer_name, department } = item;

                                                                const final_instance_number = index + 1;
                                                                const contact_details_id = `sub_${final_instance_number}_field_967`;
                                                                const contact_relationship = `sub_${final_instance_number}_field_968`;
                                                                const contact_title = `sub_${final_instance_number}_field_969`;
                                                                const contact_firstname = `sub_${final_instance_number}_field_970`;
                                                                const contact_surname = `sub_${final_instance_number}_field_971`;
                                                                const contact_home_phone = `sub_${final_instance_number}_field_972`;
                                                                const contact_work_phone = `sub_${final_instance_number}_field_973`;
                                                                const contact_physical_address = `sub_${final_instance_number}_field_976`;
                                                                const contact_employer_name = `sub_${final_instance_number}_field_974`;
                                                                const contact_department = `sub_${final_instance_number}_field_975`;

                                                                return (

                                                                    <tr className="new_entry_line" key={index}>
                                                                        <td style={{ textAlign: "center" }}>

                                                                            <select id="contact_details" name={contact_details_id} className="text-uppercase" defaultValue={ContactDatazero === 0 ? item.contact_details : contact_details1} onChange={(evnt) => { handleContactChange1(index, evnt, item.control_instance_number) }} disabled>
                                                                                <option value="0">-- Select An Option --</option>
                                                                                {ContactDetailsAll != null ?
                                                                                    ContactDetailsAll.map((item) => {
                                                                                        return (
                                                                                            <option key={item.id} value={item.id}>
                                                                                                {item.name}
                                                                                            </option>
                                                                                        );
                                                                                    })
                                                                                    : null}
                                                                            </select>
                                                                        </td>
                                                                        <td style={{ textAlign: "center" }}>

                                                                            <select id="relationship" name={contact_relationship} className="text-uppercase" value={ContactDatazero === 0 ? item.relationship : relationship1} onChange={(evnt) => { handleContactChange1(index, evnt, item.control_instance_number) }} >
                                                                                <option value="0">-- Select An Option --</option>
                                                                                {RelationshipAll.map((item) => {
                                                                                    return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                            {item.relation}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            <select id="title" className="text-uppercase" name={contact_title} value={ContactDatazero === 0 ? item.title : title1} onChange={(evnt) => { handleContactChange1(index, evnt, item.control_instance_number) }} >
                                                                                <option value="0">-- Select An Option --</option>
                                                                                {ContactTitleAll.map((item) => {
                                                                                    return (
                                                                                        <option key={item.id} value={item.id}>
                                                                                            {item.title}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>


                                                                        <td style={{ textAlign: "center" }}>
                                                                            <input type="text" id="firstname" name={contact_firstname} style={{ textTransform: "uppercase" }} defaultValue={ContactDatazero === 0 ? item.firstname : firstname} onChange={(evnt) => { handleContactChange1(index, evnt, item.control_instance_number) }} />
                                                                        </td>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            <input type="text" id="surname" name={contact_surname} style={{ textTransform: "uppercase" }} defaultValue={ContactDatazero === 0 ? item.surname : surname} onChange={(evnt) => { handleContactChange1(index, evnt, item.control_instance_number) }} />
                                                                        </td>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            <input type="text" id="home_phone" name={contact_home_phone} style={{ textTransform: "uppercase" }} defaultValue={ContactDatazero === 0 ? item.home_phone : home_phone} onChange={(evnt) => { handleContactChange1(index, evnt, item.control_instance_number) }} />
                                                                        </td>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            <input type="text" id="work_phone" name={contact_work_phone} style={{ textTransform: "uppercase" }} defaultValue={ContactDatazero === 0 ? item.work_phone : work_phone} onChange={(evnt) => { handleContactChange1(index, evnt, item.control_instance_number) }} />
                                                                        </td>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            <textarea rows="4" id="physical_address" name={contact_physical_address} style={{ textTransform: "uppercase" }} defaultValue={ContactDatazero === 0 ? item.physical_address : physical_address} onChange={(evnt) => { handleContactChange1(index, evnt, item.control_instance_number) }} ></textarea>
                                                                        </td>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            <input type="text" id="employer_name" name={contact_employer_name} style={{ textTransform: "uppercase" }} defaultValue={ContactDatazero === 0 ? item.employer_name : employer_name} onChange={(evnt) => { handleContactChange1(index, evnt, item.control_instance_number) }} />
                                                                        </td>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            <input type="text" id="department" name={contact_department} style={{ textTransform: "uppercase" }} defaultValue={ContactDatazero === 0 ? item.department : department} onChange={(evnt) => { handleContactChange1(index, evnt, item.control_instance_number) }} />
                                                                        </td>
                                                                        <td>
                                                                            
                                                                            <input type="image" name="delete_file" src={delete_row_img} width="16" onClick={() => { if (window.confirm('Are you sure you want to delete this row?')) { handleContactDeleteRow(item.control_instance_number); sethandleDeleteFlag(1); } }} />
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }) : null}

                                                           <Contact_details_table_rows rowsData1={rowsAddContactData} handleContactChange={handleContactChange} instanceNumber1= {ContactControlInstanceNumber} colorFocus1={colorFocus1} ContactValidate={ContactValidate} setContactDetailsFlag={setContactDetailsFlag}  setContactRelationFlag = {setContactRelationFlag} setFirstNameFlag = {setFirstNameFlag} setLastNameFlag = {setLastNameFlag} colorFocus2={colorFocus2} colorFocus3={colorFocus3} colorFocus4={colorFocus4}  RelationValidate = {RelationValidate} FirstNameValidate = {FirstNameValidate} LastNameValidate = {LastNameValidate} /> 
                                                        <tr style={{ display: AddNewButton2 }}>
                                                            { AddEntryDiplayFlag == 0 ?
                                                                <td className="text-center" colSpan={11}>
                                                                    <input type="button" id="add_new_entry" value="Add an Entry" onClick={function (e) { addContactRowTable(); ContactShowRow(e); }} />

                                                                </td>
                                                            : null }
                                                        </tr>
                                                        <tr style={{ display: RowHideShow2 }}>
                                                            <td className="text-center" colSpan={11}>
                                                                <input className="new_entry_line" type="submit" value="Save New Entry" name="command" onClick={(e) => { setContactDetailsSave(e.target.value); }} />&nbsp;
                                                                <input className="new_entry_line" id="remove_new_entry" type="button" value="Cancel" onClick={function (e) { removeContactRow(); ContactShowRow(e) }} />

                                                            </td>
                                                        </tr>

                                                        <tr className="text-center" >
                                                            <td colSpan={11} style={{ textAlign: "center" }}>

                                                                {PrintButtonData != '' ?
                                                                    <input type="submit" name="command" value={PrintButton} onClick={(e) => { setPrintButton(e.target.value); setContactDetailsSave(e.target.value); }} />
                                                                    : null}
                                                                &nbsp;

                                                                <input type="submit" name="command" value="Save" onClick={(e) => { setContactDetailsSave(e.target.value) }} />&nbsp;

                                                                <input type="submit" name="command" value="Save and Exit" onClick={(e) => { setContactDetailsSave(e.target.value) }} />&nbsp;

                                                                <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                                    <input type="button" className="p-relative" value="Cancel" />
                                                                </Link>

                                                            </td>
                                                        </tr>

                                                        {/* <tr className="caption text-center" >
                                                            // <td colSpan={(ClientDetailFlag && 4) || (EmployeeDetailFlag && 11) || (FinancialDetailFlag && 4) || (LoanDetailFlag && 4) || (SpouseDetailFlag && 4) || (ContactDetailFlag && 11)} style={{ textAlign: "center" }}>
                                                                <input type="submit" name="Save" value="Save" onChange={() => { }} />
                                                                <input type="submit" name="Save and Exit" value="Save and Exit" onChange={() => { }} />
                                                                <input type="button" value="Cancel" name="Cancel" onChange={() => { }} />
                                                            </td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </form>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* </form> */}
            <Footer />
        </>
    )
}

export default Finance_application_bank_garborone