import { Link, useNavigate, useParams } from "react-router-dom"
import React, { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import Transaction_menu from "../Transaction/Transaction_menu";
import Footer from "../Dashboard/Footer";
import axios from "../axios";
import Loader from "../Components/Loader"

const Edit_transcation = () => {
    
    const navigate = useNavigate();
    const [SelesRep, setSelesRep] = useState(0);
    const [SelesRepAll, setSelesRepAll] = useState([]);
    const [BussinessManager, setBussinessManager] = useState(0);
    const [BussinessManagerAll, setBussinessManagerAll] = useState([]);
    const [FinanceType, setFinanceType] = useState(0);
    const [FinanceTypeAll, setFinanceTypeAll] = useState([]);
    const [FinanceTerm, setFinanceTerm] = useState(0);
    const [FinanceTermAll, setFinanceTermAll] = useState([]);
    const [AccountHolderName, setAccountHolderName] = useState(0);
    const [AccountHolderNameAll, setAccountHolderNameAll] = useState([]);
    const [BankName, setBankName] = useState(0);
    const [BankNameAll, setBankNameAll] = useState([]);
    const [AccountType, setAccountType] = useState(0);
    const [AccountTypeAll, setAccountTypeAll] = useState([]);

    const [TranscationDetails, setTranscationDetails] = useState([]);
    const [InterestRate, setInterestRate] = useState(0);
    const [SalesType, setSalesType] = useState("");
    const [BorrowingGuaranteed, setBorrowingGuaranteed] = useState("");
    const [BankConduct, setBankConduct] = useState("");
    const [InterestType, setInterestType] = useState("");
    const [DealHasBeenSighted, setDealHasBeenSighted] = useState(false);
    const [CashTranscation, setCashTranscation] = useState(false);
    const [BranchName, setBranchName] = useState("");
    const [AccountNumber, setAccountNumber] = useState("");
    const [BranchCode, setBranchCode] = useState("");
    const [RowHideShow, setRowHideShow] = useState("");
    const [TransSave, setTransSave] = useState("");
    const branchcodeRef = useRef();
    const branchnameRef = useRef();
    const acnumberRef = useRef();
    const [branchCodeColor, setbranchCodeColor] = useState("");
    const [branchbranchNameColor, setbranchbranchNameColor] = useState("");
    const [acNumberColor, setacNumberColor] = useState("");
    const [BrowingColor, setBrowingColor] = useState("");
    const [BankConductColor, setBankConductColor] = useState("");
    const [PrevCashDeal, setPrevCashDeal] = useState("");

    const transcation = useParams();
    const division_id = localStorage.getItem("division_id");
    const department_id = localStorage.getItem("department_id");
    const system_type = localStorage.getItem("system_type");
    const user_id = localStorage.getItem("UserId");
    const unincept_flag = localStorage.getItem("unincept_flag");
    const transaction_id = localStorage.getItem("transaction_id");
    const id             = localStorage.getItem("product_hidden_id");
    const tp_id          = localStorage.getItem("product_hidden_tp_id");

    const [loader, setLoader] = useState(false);
    const [HeaderFlag, setHeaderFlag] = useState(1);
    
    useEffect(() => {
        document.title = 'Transactions | Seriti BW';
    }, []);
    //console.log(CashTranscation); 
    //----------- get seles rep -----------------
    useEffect(() => {
        const payload = {
            transaction_id: transcation.id,
        };
        //console.log(payload); 
        axios
          .post("edit_transcation/featch_seles_rep", payload)
          .then((response) => {
            if(response.data.status === 200){
               setSelesRepAll(response.data.data.seles_rep);
            }
          })
          .catch(() => {
            console.log('error'); 
          });
    }, []);

    //----------- get bussiness manager marker -----------------
    useEffect(() => {
        const payload = {
            transaction_id: transcation.id,
            department_id: department_id,
        };
        axios
          .post("edit_transcation/featch_bussiness_manager_marker", payload)
          .then((response) => {
            if(response.data.status === 200){
                setBussinessManagerAll(response.data.data.bussiness_manager);
            }
          })
          .catch(() => {
            console.log('error'); 
          });
    }, []);

    //----------- get finance type -----------------
    useEffect(() => {
        const payload = {
            transaction_id: transcation.id,
            division_id: division_id,
        };
       // console.log(payload); 
        axios
          .post("edit_transcation/featch_finance_type", payload)
          .then((response) => {
            if(response.data.status === 200){
                setFinanceTypeAll(response.data.data.finance_type);
            }
          })
          .catch(() => {
            console.log('error'); 
          });
    }, []);

    //----------- get finance term -----------------
    useEffect(() => {
        axios
          .post("edit_transcation/featch_finance_term")
          .then((response) => {
            if(response.data.status === 200){
                setFinanceTermAll(response.data.data.finance_terms);
            }
          })
          .catch(() => {
            console.log('error'); 
          });
    }, []);

    //----------- get account holder name -----------------
    useEffect(() => {
        const payload = {
            transaction_id: transcation.id,
        };
        axios
          .post("edit_transcation/featch_account_holder", payload)
          .then((response) => {
            if(response.data.status === 200){
                setAccountHolderNameAll(response.data.data.account_holder);
            }
          })
          .catch(() => {
            console.log('error'); 
          });
    }, []);
    
    //----------- get bank name -----------------
    useEffect(() => {
        const payload = {
            transaction_id: transcation.id,
        };
        axios
          .post("edit_transcation/featch_bank_name", payload)
          .then((response) => {
            if(response.data.status === 200){
                setBankNameAll(response.data.data.bank_name);
            }
          })
          .catch(() => {
            console.log('error'); 
          });
    }, []);
    
    //----------- get account -----------------
    useEffect(() => {
        axios
          .post("edit_transcation/featch_account_type")
          .then((response) => {
            if(response.data.status === 200){
                setAccountTypeAll(response.data.data.account_type);
            }
          })
          .catch(() => {
            console.log('error'); 
          });
    }, []);

    //----------- featch transcation details -----------------
    useEffect(() => {
        setLoader(true);
        const payload = {
            transaction_id: transcation.id,
            department_id: department_id,
            system_type: system_type,
        };
        axios
          .post("edit_transcation/featch_transaction_details",payload)
          .then((response) => {
            //console.log(response.data.data.transaction_details);
            if(response.data.status === 200){
                setTranscationDetails(response.data.data.transaction_details);
                setSelesRep(response.data.data.transaction_details.sales_rep);
                setBussinessManager(response.data.data.transaction_details.bussiness_manager_id);
                setFinanceType(response.data.data.transaction_details.finance_type);
                setFinanceTerm(response.data.data.transaction_details.finance_terms_month);
                setInterestRate(response.data.data.transaction_details.interest_rate);
                setSalesType(response.data.data.transaction_details.sales_type);
                setBorrowingGuaranteed(response.data.data.transaction_details.borrowing_guaranteed);
                setBankConduct(response.data.data.transaction_details.bank_conduct);
                setInterestType(response.data.data.transaction_details.interest_type);
                setDealHasBeenSighted(response.data.data.transaction_details.deal_hash_beeen_sighted ? true : false);
                setCashTranscation(response.data.data.transaction_details.cash_transaction ? true : false);
                setAccountHolderName(response.data.data.transaction_details.loc_ac_holder_name);
                setBankName(response.data.data.transaction_details.loc_bank_name);
                setAccountType(response.data.data.transaction_details.loc_ac_type);
                setBranchName(response.data.data.transaction_details.loc_branch_name);
                setAccountNumber(response.data.data.transaction_details.loc_ac_number);
                setBranchCode(response.data.data.transaction_details.loc_branch_code);
                setPrevCashDeal(response.data.data.transaction_details.prev_cash_deal);

                if(response.data.data.transaction_details.cash_transaction == true){
                    setRowHideShow("none");
                }else{
                    setRowHideShow("table-row");
                }
            }
            setLoader(false);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          })
          .catch(() => {
            console.log('error'); 
            setLoader(false);
          });
    }, []);

    //-------------- hide /show div
    const ContenShowRow = (e) => {
        const loc_div_id = e.currentTarget.id;
        //console.log(CashTranscation);
        if(loc_div_id == 'cash_indicator'){
            if(CashTranscation === true){
                const RowHideShow = "table-row";
                setRowHideShow(RowHideShow);
            }else{
                const RowHideShow = "none";
                setRowHideShow(RowHideShow); 
            }  
        }
    }
    //--------------- edit transcation details
    const handleValidation = () => {
        //console.log(BorrowingGuaranteed);
        var flag = true;
        const regMatch = /^[a-zA-Z ]*$/.test(BranchName);
        const regMatch_num = /^[0-9\b ]+$/.test(AccountNumber);
       // alert(regMatch_num);
        if(CashTranscation == false){
            if (BranchCode === "") {
            alert("Please Enter Branch Code!");
            branchcodeRef.current.focus();
            setbranchCodeColor("red");
            flag = false;
            }
            else if (BranchName != "") {
                if(regMatch == false){
                    alert("Please enter valid Branch Name! Only letters are allowed!");
                    branchnameRef.current.focus();
                    setbranchbranchNameColor("red");
                    flag = false;
                }
                flag = true;
            }else if (AccountNumber != "") {
                if(regMatch_num == false){
                    alert("Please enter valid Account Number! Only numbers are allowed!");
                    acnumberRef.current.focus();
                    setacNumberColor("red");
                    flag = false;
                }
                flag = true;
            }else if(!(BorrowingGuaranteed == "Y" || BorrowingGuaranteed == "N"))
            {
                alert("Please select Is borrowing guaranteed !");
                setBrowingColor("1px solid red");
                flag = false;
            }
            else if(!(BankConduct == "G" || BankConduct == "B"))
            {
                alert("Please select Bank conduct !");
                setBankConductColor("1px solid red");
                flag = false;
            }
        }
        return flag;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        if (flag) {
            setLoader(true);
            //setNameError("");
            const payload = {
                transaction_id: transcation.id,
                department_id: department_id,
                division_id: division_id,
                user_id: user_id,
                command: TransSave,
                sales_rep: SelesRep,
                fi: BussinessManager,
                sighted: DealHasBeenSighted,
                sale_type: SalesType,
                finance_type: FinanceType,
                account_holder_name: AccountHolderName,
                bank_name: BankName,
                account_type: AccountType,
                branch_name: BranchName,
                account_number: AccountNumber,
                branch_code: BranchCode,
                cash_deal: CashTranscation,
                borrowing_guaranteed: BorrowingGuaranteed,
                bank_conduct: BankConduct,
                finance_term: FinanceTerm,
                interest: InterestRate,
                interest_type: InterestType,
                account_open_date: '',
                prev_cash_deal: PrevCashDeal,
                
            };
            //console.log(payload);
              axios
                .post("edit_transcation/edit_transaction_details", payload)
                .then((response) => {
                    setLoader(false);
                    // console.log(response);
                    if(response.status == 200){
                        if(response.data.data.command == "Save"){
                           //window.location.reload(false);  
                            toast.success(response.data.message, {
                               position: toast.POSITION.TOP_CENTER
                            });
                            setHeaderFlag(2)
                        }else if(response.data.data.command == "Save and Continue"){
                            if(id == 0){
                                navigate('/products/search/product_type=vehicles/command=search/module=Transactions/products_product_type/0'); 
                            }else{
                                navigate('/products/add_to_transaction/command=Edit/module=Transactions/'+id+'/'+tp_id);
                            }
                        }
                    }
                    
                })
                .catch(() => {
                    setLoader(false);
                   console.log('error');
                });
        }
    };

    // let panFlag = 1;

return (
    <>  
        <ToastContainer theme="colored" style={{ width: "450px", marginLeft: "-200px" }} />
        <Transaction_menu panel_flag={HeaderFlag}/>
        {loader ? <Loader /> : null}
        <div>
        <table width="100%" height="1024" id="mainContainer" border="0" cellPadding={0} cellSpacing={0} className="custom-body">
          <tbody>
            <tr valign="top">
            <td></td>
            <td width="100%">
                <div id="parent_wrapper" className="custom-parent_wrapper">
                </div>
                <table width="100%" border="0"  cellPadding={5} cellSpacing={5}>
                    <tbody>
                        <tr>
                           <td valign="top" align="center">
                            <form name="command" method="post" onSubmit={handleSubmit} >
                             <table cellSpacing={1} className="defaultTbl no_caption_color" width="500">
                                <tbody>
                                    <tr>
                                        <th colSpan={2} className="text-center">Transaction Details</th>
                                    </tr>
                                    <tr>
                                        <td className="caption" width="45%">Transaction #</td>
                                        <td><strong>{ TranscationDetails.transaction_id != '' ? TranscationDetails.transaction_id : null }</strong></td>
                                    </tr>
                                    <tr>
                                        <td className="caption" width="45%">Cash Transaction</td>
                                        <td>
                                            { unincept_flag == 1 ?
                                               TranscationDetails.display_cash_transaction
                                            :
                                                <input type="checkbox" id="cash_indicator" name="cash_deal" value={CashTranscation} onChange={(e) => { setCashTranscation(!CashTranscation) }} checked={CashTranscation} onClick={function(e){ ContenShowRow(e);}}/>
                                           }
                                            
                                        </td> 
                                    </tr>
                                    <tr>
                                        <td className="caption">Deal has been sighted</td>
                                        <td>
                                            { unincept_flag == 1 ?
                                               TranscationDetails.display_deal_hash_beeen_sighted
                                            :
                                               <input type="checkbox" name="sighted" id="sighted" value={DealHasBeenSighted} onChange={(e) => { setDealHasBeenSighted(!DealHasBeenSighted) }} checked={DealHasBeenSighted}/>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="caption" width="45%">Sales Rep</td>
                                        <td>
                                            { unincept_flag == 1 ?
                                               TranscationDetails.display_sales_rep
                                            :
                                                <select name="sales_rep" id="sales_rep" className="caption" style={{textTransform: 'uppercase'}} value={SelesRep} onChange = {(e) => setSelesRep(e.target.value)}>
                                                <option value="">-- Select Sales Rep --</option>
                                                    {SelesRepAll.map((item) => {
                                                        return (
                                                        <option key={item.id} value={item.id}>
                                                            {item.user_name}
                                                        </option>
                                                        );
                                                    })}
                                                </select>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="caption" width="45%">BUSINESS MANAGER/MARKETER</td>
                                        <td>
                                            { unincept_flag == 1 ?
                                               TranscationDetails.display_bussiness_manager
                                            :
                                                <select name="fi" id="fi" className="caption" style={{textTransform: 'uppercase'}} value={BussinessManager} onChange = {(e) => setBussinessManager(e.target.value)}>
                                                <option value="">-- Select Marketer --</option>
                                                    {BussinessManagerAll.map((item) => {
                                                            return (
                                                            <option key={item.id} value={item.id}>
                                                                {item.user_name}
                                                            </option>
                                                            );
                                                    })}
                                                </select>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="caption">Sale Type</td>
                                        { unincept_flag == 1 ?
                                        <td> {TranscationDetails.display_sales_type}</td>  
                                            :
                                        <td>
                                            <input type="radio" name="sale_type" value={SalesType == 2 ? SalesType : '2'} checked={SalesType == 2} onChange={(e) => { setSalesType(e.target.value) }}/>&nbsp; Vehicle &nbsp;&nbsp;
                                               <input type="radio" name="sale_type" value={SalesType == 1 ? SalesType : '1'} checked={SalesType == 1} onChange={(e) => { setSalesType(e.target.value) }}/>&nbsp; Fleet
                                        </td>
                                        }
                                    </tr>
                                   
                                    <tr style={{ display: RowHideShow }}>
                                        <td className="caption" width="45%">Finance Type</td>
                                        <td>
                                            { unincept_flag == 1 ?
                                               TranscationDetails.display_finance_type
                                            :
                                               <select name="finance_type" id="finance_type" className="caption" style={{textTransform: 'uppercase'}} value={FinanceType} onChange = {(e) => setFinanceType(e.target.value)}>
                                                <option value="0">-- SELECT A TYPE --</option>
                                                    {FinanceTypeAll.map((item) => {
                                                            return (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                            );
                                                    })}
                                                </select>
                                            }
                                        </td>
                                    </tr>
                                    <tr style={{ display: RowHideShow }}> 

                                        <td className="caption">Is borrowing guaranteed?</td>
                                           { unincept_flag == 1 ?
                                              <td>{TranscationDetails.display_borrowing_guaranteed}</td> 
                                            :
                                            <td style={{border: BrowingColor}}>
                                                <input type="radio" name="borrowing_guaranteed" value={BorrowingGuaranteed == 'Y' ? BorrowingGuaranteed : 'Y'} checked={BorrowingGuaranteed == 'Y'} onChange={(e) => { setBorrowingGuaranteed(e.target.value) }}/>&nbsp;YES &nbsp;&nbsp;
                                                <input type="radio" name="borrowing_guaranteed" value={BorrowingGuaranteed == 'N' ? BorrowingGuaranteed : 'N'} checked={BorrowingGuaranteed == 'N'} onChange={(e) => { setBorrowingGuaranteed(e.target.value) }}/>&nbsp;NO
                                            </td>
                                            }			
                                    </tr>
                                    <tr style={{ display: RowHideShow }}>
                                        <td className="caption">Bank conduct</td>
                                            { unincept_flag == 1 ?
                                              <td>{TranscationDetails.display_bank_conduct}</td> 
                                            :
                                            <td style={{border: BankConductColor}}>
                                                <input type="radio" name="bank_conduct" value={BankConduct == 'G' ? BankConduct : 'G'} checked={BankConduct == 'G'} onChange={(e) => { setBankConduct(e.target.value) }} />&nbsp;GOOD &nbsp;&nbsp; 
                                                <input type="radio" name="bank_conduct" value={BankConduct == 'B' ? BankConduct : 'B'} checked={BankConduct == 'B'} onChange={(e) => { setBankConduct(e.target.value) }} />&nbsp;BAD
                                            </td>
                                            }			
                                    </tr>
                                    <tr style={{ display: RowHideShow }}>
                                        <td className="caption">Finance Term (Months)</td>
                                        <td>
                                            { unincept_flag == 1 ?
                                                TranscationDetails.display_finance_terms_month+' MONTHS'
                                            :
                                                <select name="finance_term" id="finance_term" style={{textTransform: 'uppercase'}} value={FinanceTerm} onChange = {(e) => setFinanceTerm(e.target.value)}>
                                                    {FinanceTermAll.map((item) => {
                                                            return (
                                                            <option key={item.id} value={item.id}>
                                                                {item.term}
                                                            </option>
                                                            );
                                                    })}  
                                                </select>
                                            }
                                        </td>
                                    </tr>
                                    <tr style={{ display: RowHideShow }}>
                                        <td className="caption">Preferred Interest Rate</td>
                                       
                                            { unincept_flag == 1 ?
                                              <td>{TranscationDetails.display_interest_rate} %</td> 
                                            :
                                            <td>
                                               <input type="text&quot;" name="interest" id="interest" size="5" value={InterestRate} onChange = {(e) => setInterestRate(e.target.value)}/> %
                                            </td>
                                            }
                                        
                                    </tr>
                                    <tr style={{ display: RowHideShow }}>
                                        <td className="caption">Interest Type</td>
                                        { unincept_flag == 1 ?
                                            <td>{TranscationDetails.display_interest_type}</td> 
                                            :
                                            <td>	
                                                <input type="radio" name="interest_type" value={InterestType == '1' ? InterestType : '1'} checked={InterestType == '1'} onChange={(e) => { setInterestType(e.target.value) }} />&nbsp; Fixed&nbsp;&nbsp;
                                                <span>
                                                <input type="radio" name="interest_type" value={InterestType == '2' ? InterestType : '2'} checked={InterestType == '2'} onChange={(e) => { setInterestType(e.target.value) }} />&nbsp; Linked
                                                </span>
                                            </td>
                                        }
                                    </tr>
                                    <tr style={{ display: RowHideShow }}>
                                        <th colSpan={2} className="text-center">Bank Account Details</th>
                                    </tr>
                                    <tr style={{ display: RowHideShow }}>
                                        <td className="caption" width="45%">Account Holder Name</td>
                                        <td>
                                            { unincept_flag == 1 ?
                                                TranscationDetails.display_ac_holder_name
                                            :
                                                <select name="account_holder_name" id="account_holder_name" className="caption" style={{textTransform: 'uppercase'}} value={AccountHolderName} onChange = {(e) => setAccountHolderName(e.target.value)}>
                                                    <option value="">- - Select a Contact - -</option>
                                                    {AccountHolderNameAll.map((item) => {
                                                            return (
                                                            <option key={item.id} value={item.id}>
                                                                {item.text}
                                                            </option>
                                                            );
                                                    })}  
                                                </select>
                                            }                                                           
                                        </td>
                                    </tr>
                                    <tr style={{ display: RowHideShow }}>
                                        <td className="caption" width="45%">Bank</td>
                                        <td>
                                            { unincept_flag == 1 ?
                                                TranscationDetails.display_bank_name
                                            :
                                                <select name="bank_name" id="bank_name" className="caption" style={{textTransform: 'uppercase'}} value={BankName} onChange = {(e) => setBankName(e.target.value)}>
                                                <option value="">- - Select a Bank - -</option>
                                                {BankNameAll.map((item) => {
                                                        return (
                                                        <option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                        );
                                                })}  
                                                </select>
                                            }
                                        </td>
                                    </tr>
                                    <tr style={{ display: RowHideShow }}>
                                        <td className="caption" width="45%">Account Type</td>
                                        <td>
                                            { unincept_flag == 1 ?
                                                TranscationDetails.display_ac_type
                                            :
                                                <select name="account_type" id="account_type" className="caption" style={{textTransform: 'uppercase'}} value={AccountType} onChange = {(e) => setAccountType(e.target.value)}>
                                                    <option value="">- - Select an Account Type - -</option>
                                                    {AccountTypeAll.map((item) => {
                                                            return (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                            );
                                                    })} 
                                                </select>
                                            }
                                        </td>
                                    </tr>
                                    <tr style={{ display: RowHideShow }}>
                                        <td className="caption">Branch Name</td>
                                        <td>
                                            { unincept_flag == 1 ?
                                                TranscationDetails.display_branch_name
                                            :
                                               <input type="text" name="branch_name" id="branch_name" className="caption" value={BranchName} onChange={(e) => { setBranchName(e.target.value) }} style={{textTransform: "uppercase", borderColor: branchbranchNameColor}} ref={branchnameRef}/>
                                            } 
                                        </td>
                                    </tr>
                                    <tr style={{ display: RowHideShow }}> 
                                        <td className="caption">Account Number</td>
                                        <td>
                                            { unincept_flag == 1 ?
                                                TranscationDetails.display_ac_number
                                            :
                                                <input type="text" name="account_number" id="account_number" className="caption" value={AccountNumber} onChange={(e) => { setAccountNumber(e.target.value) }} ref={acnumberRef} style={{borderColor: acNumberColor}}/>
                                            }
                                        </td> 
                                    </tr>
                                    <tr style={{ display: RowHideShow }}>
                                        <td className="caption">Branch Code <span className="text-danger">*</span></td>
                                        <td>
                                            { unincept_flag == 1 ?
                                                TranscationDetails.display_branch_code
                                            :
                                                <input type="text" name="branch_code" id="branch_code" className="caption" value={BranchCode} onChange={(e) => { setBranchCode(e.target.value) }} ref={branchcodeRef} style={{borderColor: branchCodeColor}}/>
                                            }
                                        </td>
                                    </tr>
                                   
                                    <tr>
                                        { unincept_flag == 1 ?
                                               <td className="caption" colSpan={2} style={{textAlign: 'center'}}>
                                                    <Link to={"/transaction/create_transaction/" + transaction_id}> 
                                                        <input type="submit" name="command" className="hide_button_after_inception" value="View Transaction"  />
                                                    </Link>&nbsp;  
                                               </td>
                                            :
                                            <td className="caption" colSpan={2} style={{textAlign: 'center'}} >
                                                <input type="submit" name="command" value="Save" onClick={(e) => { setTransSave(e.target.value) }}/>&nbsp; 
                                                <input type="submit" name="command" value="Save and Continue" onClick={(e) => { setTransSave(e.target.value) }} />&nbsp; 
                                                <Link to={"/transaction/create_transaction/" + transaction_id}>
                                                  <input type="button" className="p-relative" value="Cancel" />
                                                </Link>
                                            </td>
                                            }
                                    </tr>
                                  </tbody>
                                </table>
                            </form>
                           </td>
                        </tr>
                    </tbody>
                </table>
            </td>
            </tr>
          </tbody>
        </table>
        <Footer />
        </div>
    </>
);

}

export default Edit_transcation