import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Loader from "../../Components/Loader"

function Company_edit() {
    document.title = "Administration | Seriti - Add Company";
    const { companyId } = useParams();//console.log(companyId)

    const UserId = localStorage.getItem("UserId");

    const [companyType, setCompanyType] = useState(''); const [title, setTitle] = useState('');//company_type/title
    const [companyName, setCompanyName] = useState(''); const [firstName, setFirstName] = useState('');//company_name/firstname
    const [tradingAs, setTradingAs] = useState(''); const [surname, setSurname] = useState('');//trading/surname
    const [companyRep, setCompanyRep] = useState(''); const [preffered_name, setPrefferedName] = useState('');
    const [registration, setRegistration] = useState(''); const [idNumber, setIdNumber] = useState('');
    const [vat_number, setVatNumber] = useState(''); const [passport_number, setPassportNumber] = useState('');

    const [country_id, setCountryId] = useState('');
    const [allCountry, setAllCountry] = useState([]);
    const [contactType, setContactType] = useState('');
    const [allContactType, setAllContactType] = useState([]);

    const [businessType, setBusinessType] = useState('');
    const [idType, setIdType] = useState('');
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [office_number, setOfficeNumber] = useState('');
    const [mobile_number, setMobileNumber] = useState('');
    const [home_number, setHomeNumber] = useState('');
    const [fax_number, setFaxNumber] = useState('');
    const [email, setEmail] = useState('');
    const [passport_issue_date, setPassportIssueDate] = useState('');
    const [passport_expiry_date, setPassportExpiryDate] = useState('');

    const [physical_address, setPhysicalAddr] = useState('');
    const [postal_address, setPostalAddr] = useState('');
    const [web_address, setWebAddr] = useState('');
    const [notes, setNotes] = useState('');

    const [isShown1, setIsShown1] = useState(false);//1 for CLIENT
    const [isShown2, setIsShown2] = useState(false);//2 for BROKER
    const [isShown3, setIsShown3] = useState(false);//Business or private

    const [isShownId1, setIsShownId1] = useState(false);
    const [isShownId2, setIsShownId2] = useState(false);
    const [obj, setObj] = useState([])
    const [flag, setFlag] = useState(false);
    const [id_flag, setIdFlag] = useState(false);

    const [DobDateFlag, setDobDateFlag] = useState(0)

    const [loader, setLoader] = useState(false);
    //-----------------------------------country dropdown-------------------------------//
    useEffect(() => {
        axios
            .get("/loanhouses_list/country_basic")
            .then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data.data[0]);
                    setAllCountry(response.data.data[0])
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                //alert(response.response.data.message);
            });
    }, []);

    //---------------------------Index Data / Contact Type dropdown-------------------------//
    useEffect(() => {
        axios
            .get("/admin/add_company/index")
            .then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data.data);
                    setAllContactType(response.data.data.sCategories)
                }
            })
            .catch((response) => {
                //alert(response.response.data.message);
            });
    }, []);

    //-------------------------on change of Business(type_id)---------------------------------//
    const handleSubmitContentType = (cType) => {
        // console.log(cType)
        // alert(cType)
        switch (cType) {

            case "1":
                //alert("case 1");
                setIsShown1(true)
                setIsShown2(false)
                //set empty of shown2 fields
                setCompanyName('');
                setTradingAs('');
                setCompanyRep('');
                setRegistration('');
                setVatNumber('');
                setWebAddr('');
                setCompanyType('');
                setTitle('');
                setHomeNumber('');
                break;
            case "2":
                //alert("case 2");
                setIsShown1(false)
                setIsShown2(true)
                setBusinessType('');
                setTitle('');
                setFirstName('');
                setSurname('');
                setPrefferedName('');
                setIdType('');
                setIdNumber('');
                setPassportNumber('');
                setPassportIssueDate('');
                setPassportExpiryDate('');
                setDob('');
                setGender('');
                break;
            default:
                // alert("case default");
                setIsShown1(false)
                setIsShown2(true);
                setBusinessType('');
                setTitle('');
                setFirstName('');
                setSurname('');
                setPrefferedName('');
                setIdType('');
                setIdNumber('');
                setPassportNumber('');
                setPassportIssueDate('');
                setPassportExpiryDate('');
                setDob('');
                setGender('');
        }
    }

    //--------------------------------on change of Business/Private----------------------------------//
    const handleSubmitBusinessType = (bType) => {
        // console.log(bType)
        switch (bType) {
            case "1":
                setIsShown1(true)
                setIsShown2(false)
                setIsShown3(false)
                setCompanyName('');
                setTradingAs('');
                setCompanyRep('');
                setRegistration('');
                setVatNumber('');
                setWebAddr('');
                setCompanyType('');
                setTitle('');
                setHomeNumber('');
                break;
            case "2":
                setIsShown1(false)
                setIsShown2(true)
                setIsShown3(true)
                // setBusinessType('');
                setTitle('');
                setFirstName('');
                setSurname('');
                setPrefferedName('');
                setIdType('');
                setIdNumber('');
                setPassportNumber('');
                setPassportIssueDate('');
                setPassportExpiryDate('');
                setDob('');
                setGender('');
                break;
            default:
                setIsShown1(false)
                setIsShown2(false)
                setBusinessType('');
                setTitle('');
                setFirstName('');
                setSurname('');
                setPrefferedName('');
                setIdType('');
                setIdNumber('');
                setPassportNumber('');
                setPassportIssueDate('');
                setPassportExpiryDate('');
                setDob('');
                setGender('');
        }
    }

    //--------------------------------on change of ID type------------------------------------//
    const handleSubmitIDType = (iType) => {

        switch (iType) {
            case "1":
                setIsShownId1(true)
                setIsShownId2(false)
                setPassportNumber('');
                setPassportIssueDate('');
                setPassportExpiryDate('');
                break;
            case "2":
                setIsShownId2(true)
                setIsShownId1(false)
                setIdNumber('');
                break;
            default:
                setIsShownId1(false)
                setIsShownId2(false)
                setIdNumber('');
                setPassportNumber('');
                setPassportIssueDate('');
                setPassportExpiryDate('');
        }
    }

    //---------------------handle validation------------------------//
    const handleValidation = () => {
        var flag = true;
        if (contactType === '') {
            alert('Please fill in the following fields:\n => Contact type');
            flag = false;
        } else if (parseInt(contactType) === 1 && businessType === "") {
            alert('Please select business type');
            flag = false;
        } else if (companyName === '' && firstName === '') {
            alert('Please fill all the fields');
            flag = false;
        } else if (parseInt(contactType) === 1 && parseInt(businessType) === 2 && registration === "") {
            alert('Please select Company Registration Number');
            flag = false;
        } else if (parseInt(contactType) === 1 && parseInt(businessType) === 2 && vat_number === "") {
            alert('Please select Vat Number');
            flag = false;
        } else if ((parseInt(contactType) === 2 || parseInt(contactType) === 67 || parseInt(contactType) === 793) && registration === "") {
            alert('Please select Company Registration Number');
            flag = false;
        } else if ((parseInt(contactType) === 2 || parseInt(contactType) === 67 || parseInt(contactType) === 793) && vat_number === "") {
            alert('Please select Vat Number');
            flag = false;
        } else if (email === '') {
            alert('Please enter Email');
            flag = false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            alert('Please enter a valid email address');
            flag = false;
        }
        return flag;
    };

    //-------------------------------fetch existing record-------------------------------------//
    useEffect(() => {
        axios
            .get("/admin/fetch_company/" + companyId)
            .then((response) => {
                if (response.data.status === 200) {
                    var obj = response.data.data.company;
                    // console.log(obj)
                    setContactType(obj.category_id); //handleSubmitContentType(obj.category_id);//console.log(contactType);
                    setBusinessType(obj.type_id); //handleSubmitBusinessType(obj.type_id);
                    setObj(obj);
                    setFlag(true);
                    // console.log("contactType : " + contactType + "  businessType : " + businessType)

                    setCountryId(obj.country);
                    setOfficeNumber(obj.office_number);
                    setMobileNumber(obj.cell_number);
                    setFaxNumber(obj.fax_number);
                    setEmail(obj.email);
                    setPhysicalAddr(obj.physical_address);
                    setPostalAddr(obj.postal_address);
                    setNotes(obj.notes);
                }
            })
            .catch((response) => {
                //alert(response.response.data.message);
            });
    }, [companyId]);

    useEffect(() => {
        if (flag) {
            // alert(flag)
            if (contactType === 1 && businessType === 1) {
                setIsShown1(true); setIsShown3(true); setFlag(false);
                setTitle(obj.company_type); setFirstName(obj.name); setSurname(obj.abbreviation);
                setPrefferedName(obj.description); setIdNumber(obj.company_registration_number);
                setIdType(obj.idType); setIdFlag(true)
                setDob(obj.date_of_birth); //setGender(obj.gender)
                if (obj.date_of_birth !== "") setDobDateFlag(1);
                setPassportNumber(obj.vat_number);
                setPassportIssueDate(obj.passport_issue);
                setPassportExpiryDate(obj.bee_expiration_date);
                // setIdType(e.target.value); handleSubmitIDType(e.target.value)
                setHomeNumber(obj.home_number);
                (obj.gender) ? setGender(obj.gender) : setGender('');
            }
            else {
                setIsShown2(true); setIsShown3(true); setFlag(false);
                setCompanyType(obj.company_type); setCompanyName(obj.name); setTradingAs(obj.abbreviation);
                setCompanyRep(obj.description); setRegistration(obj.company_registration_number); setIdType(obj.idType);
                setIdFlag(true)
                setVatNumber(obj.vat_number); setWebAddr(obj.website_address);
            }

        }
    }, [contactType, flag, businessType, obj])

    //--------------------fetch idtype and show its input as per value--------------------------//
    useEffect(() => {
        if (id_flag) {
            // alert(obj.idType)
            var id = obj.idType;
            if (id === 1) {
                setIsShownId1(true)
                setIsShownId2(false)
                setPassportNumber('');
                setPassportIssueDate('');
                setPassportExpiryDate('');
            } else if (id === 2) {
                setIsShownId2(true)
                setIsShownId1(false)
                setIdNumber('');
            } else {
                setIsShownId1(false)
                setIsShownId2(false)
                setIdNumber('');
                setPassportNumber('');
                setPassportIssueDate('');
                setPassportExpiryDate('');
            }
            // handleSubmitIDType(obj.idType);
        }
    }, [idType, obj, id_flag])

    //-------------------------------------Submit Data------------------------------------------//
    const handleSubmit = (e) => {
        e.preventDefault();
        const flag = handleValidation();
        // console.log(flag)
        if (flag) {
            setLoader(true);
            const payload = {
                UserId: UserId,
                company_id: companyId,
                contactType: contactType,
                type_id: businessType,
                company_type: (companyType !== '') ? companyType : title,
                company_name: (companyName !== '') ? companyName : firstName,
                abbreviation: (tradingAs !== '') ? tradingAs : surname,
                description: (companyRep !== '') ? companyRep : preffered_name,
                company_registration_number: (registration !== '') ? registration : idNumber,
                vat_number: (vat_number !== '') ? vat_number : passport_number,
                country: country_id,
                office_number: office_number,
                cell_number: mobile_number,
                fax_number: fax_number,
                email: email,
                physical_address: physical_address,
                postal_address: postal_address,
                web_address: web_address,
                notes: notes,
                date_of_birth: (dob !== '' ? moment(dob).format("YYYY-MM-DD") : 0),
                passport_issue: passport_issue_date,
                bee_expiration_date: passport_expiry_date,
                home_number: home_number,
                gender: gender
            };
            axios
                .post('/admin/add_company/edit', payload)
                .then((response) => {
                    setLoader(false);
                    if (response.data.status === 200) {
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    setLoader(false);
                    if (response.response.data.status === 400) {
                        toast.error(response.response.data.data, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                });
        }
    }

    // console.log(tradingAs);
    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Admin_menu />
            {loader ? <Loader /> : null}
            <form method="post" action="" onSubmit={handleSubmit} >
                <table width="100%" height="1024" id="mainContainer" cellSpacing="0" cellPadding="0" className="custom-body">
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">
                                <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                                <table width="100%" cellSpacing="5" cellPadding="5">
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center">
                                                <p></p>

                                                <table cellSpacing="1" className="defaultTbl" width="600">
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan="2" className="text-center">MODIFY COMPANY</th>
                                                        </tr>

                                                        {/* Contact Type */}
                                                        <tr>
                                                            <td className="caption">Contact Type<span style={{ color: "red" }}> *</span></td>
                                                            <td>
                                                                <select name="contact_type" value={contactType}
                                                                    onChange={(e) => { setContactType(e.target.value); handleSubmitContentType(e.target.value) }} className="text-uppercase" style={{ width: "210px" }}>
                                                                    <option value="">-- Select Option --</option>
                                                                    {allContactType.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}  >
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        {/* Business or Private */}
                                                        {(isShown1 || isShown3) && (
                                                            <tr>
                                                                <td className="caption" width="200">Business or Private<span style={{ color: "red" }}> *</span></td>
                                                                <td>
                                                                    <select name="business_id"
                                                                        onChange={(e) => { setBusinessType(e.target.value); handleSubmitBusinessType(e.target.value) }} value={businessType} className="text-uppercase">
                                                                        <option value="">-- Select Option --</option>
                                                                        <option value="1">Private</option>
                                                                        <option value="2">Business</option>
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* Title */}
                                                        {isShown1 && (
                                                            <tr>
                                                                <td className="caption" width="200" >Title</td>
                                                                <td>
                                                                    <select name="title" onChange={(e) => { setTitle(e.target.value) }} value={title}>
                                                                        <option value="1">Mr</option>
                                                                        <option value="2">Mrs</option>
                                                                        <option value="3">Miss</option>
                                                                        <option value="4">Dr</option>
                                                                        <option value="5">Prof</option>
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* First Names */}
                                                        {isShown1 && (
                                                            <tr>
                                                                <td className="caption" >First Names<span style={{ color: "red" }}> *</span></td>
                                                                <td>
                                                                    <input type="text" name="first_name" size="30" onChange={(e) => { setFirstName(e.target.value) }} value={firstName} className="text-uppercase" />
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* Surname */}
                                                        {isShown1 && (
                                                            <tr>
                                                                <td className="caption" >Surname</td>
                                                                <td>
                                                                    <input type="text" name="surname" value={surname} size="30" onChange={(e) => { setSurname(e.target.value) }}
                                                                        className="text-uppercase" />
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* Preffered Name */}
                                                        {isShown1 && (
                                                            <tr>
                                                                <td className="caption" >Preffered Name</td>
                                                                <td>
                                                                    <input type="text" name="preffered_name" value={preffered_name} size="30" onChange={(e) => setPrefferedName(e.target.value)}
                                                                        className="text-uppercase" />
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* ID Type */}
                                                        {isShown1 && (
                                                            <tr>
                                                                <td className="caption" >ID Type</td>
                                                                <td>
                                                                    <select name="id_type" id="type_of_id" value={idType} onChange={(e) => { setIdType(e.target.value); handleSubmitIDType(e.target.value) }} className="text-uppercase">
                                                                        <option value="">--Select ID Type---</option>
                                                                        <option value="1">RSA ID</option>
                                                                        <option value="2">Passport</option>
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* ID Number */}
                                                        {(isShownId1 && isShown1) && (
                                                            <tr>
                                                                <td className="caption" >ID Number</td>
                                                                <td>
                                                                    <input type="text" name="preffered_name" value={idNumber} size="30" onChange={(e) => setIdNumber(e.target.value)}
                                                                        className="text-uppercase" />
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* Passport Number */}
                                                        {(isShownId2 && isShown1) && (
                                                            <tr>
                                                                <td className="caption" >Passport Number</td>
                                                                <td>
                                                                    <input type="text" name="passport_number" value={passport_number} size="30" onChange={(e) => setPassportNumber(e.target.value)}
                                                                        className="text-uppercase" />
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* Passport Issue Date */}
                                                        {(isShownId2 && isShown1) && (
                                                            <tr>
                                                                <td className="caption" >Passport Issue Date</td>
                                                                <td>
                                                                    <input type="date" name="passport_issue_date" value={passport_issue_date} size="30" onChange={(e) => setPassportIssueDate(e.target.value)}
                                                                        className="text-uppercase" />
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* Passport Expiry Date */}
                                                        {(isShownId2 && isShown1) && (
                                                            <tr>
                                                                <td className="caption" >Passport Expiry Date</td>
                                                                <td>
                                                                    <input type="date" name="passport_expiry_date" value={passport_expiry_date} size="30" onChange={(e) => setPassportExpiryDate(e.target.value)}
                                                                        className="text-uppercase" />
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* Date Of Birth */}
                                                        {isShown1 && (
                                                            <tr>
                                                                <td className="caption" >Date Of Birth</td>
                                                                <td>
                                                                    {DobDateFlag === 1 ?
                                                                        <DatePicker id="date_of_birth" name="date_of_birth" value={dob} onChange={(date) => { setDob(date); setDobDateFlag(0) }} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                        :
                                                                        <DatePicker id="date_of_birth" name="date_of_birth" selected={dob} onChange={(date) => setDob(date)} dateFormat="yyyy-MM-dd" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" autoComplete="off" placeholderText="YYYY-MM-DD" />
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* Gender */}
                                                        {isShown1 && (
                                                            <tr id="gender">
                                                                <td className="caption">Gender</td>
                                                                <td>
                                                                    <select name="gender" value={gender} onChange={(e) => setGender(e.target.value)} className="text-uppercase">
                                                                        <option value="">--select gender--</option>
                                                                        <option value="1" defaultChecked={(gender === 1) ? true : null}>Male</option>
                                                                        <option value="2" defaultChecked={(gender === 2) ? true : null}>Female</option>
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* Company Type */}
                                                        {isShown2 && (
                                                            <tr>
                                                                <td className="caption" >Company Type</td>
                                                                <td id="title_contents">
                                                                    <select name="company_type" className="text-uppercase" value={companyType} onChange={(e) => setCompanyType(e.target.value)} style={{ width: "210px" }}>
                                                                        <option value=''>--Select Company Type--</option>
                                                                        <option value="1">Closed Corporation</option>
                                                                        <option value="2">Private Company</option>
                                                                        <option value="3">Limited Company</option>
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* Company Name */}
                                                        {isShown2 && (
                                                            <tr>
                                                                <td className="caption" >Company Name<span style={{ color: "red" }}> *</span></td>
                                                                <td>
                                                                    <input type="text" name="company_name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} size="30"
                                                                        className="text-uppercase" />
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* Trading As */}
                                                        {isShown2 && (
                                                            <tr>
                                                                <td className="caption" id="tradingAs">Trading As</td>
                                                                <td>
                                                                    <input type="text" name="tradingAs" value={tradingAs} size="30" onChange={(e) => setTradingAs(e.target.value)}
                                                                        className="text-uppercase" />
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* Company Representative */}
                                                        {isShown2 && (
                                                            <tr>
                                                                <td className="caption" id="company_rep">Company Representative</td>
                                                                <td>
                                                                    <input type="text" name="company_rep" value={companyRep} size="30" onChange={(e) => setCompanyRep(e.target.value)} className="text-uppercase" />
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* Company Registrataion Number */}
                                                        {isShown2 && (
                                                            <tr id="id_number">
                                                                <td className="caption" id="registration">Company Registration Number<span style={{ color: "red" }}> *</span></td>
                                                                <td>
                                                                    <input type="text" name="company_registration_number" value={registration} onChange={(e) => setRegistration(e.target.value)}
                                                                        size="30" className="text-uppercase" />
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* VAT Number */}
                                                        {isShown2 && (
                                                            <tr id="passport_number">
                                                                <td className="caption" id="vat_number">VAT Number<span style={{ color: "red" }}> *</span></td>
                                                                <td>
                                                                    <input type="text" name="vat_number" value={vat_number} size="30" onChange={(e) => setVatNumber(e.target.value)}
                                                                        className="text-uppercase" />
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* Country */}
                                                        <tr>
                                                            <td className="caption">Country</td>
                                                            <td>
                                                                <select name="country" value={country_id} onChange={(e) => { setCountryId(e.target.value) }} style={{ width: "210px" }} className="text-uppercase">
                                                                    <option value="">--Select A Country--</option>
                                                                    {allCountry.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id} defaultValue={(item.id === country_id) ? 'Selected' : ''} >
                                                                                {item.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        {/* Office Number */}
                                                        <tr>
                                                            <td className="caption">Office Number</td>
                                                            <td>
                                                                <input type="text" name="office_number" value={office_number} size="30" onChange={(e) => setOfficeNumber(e.target.value)} className="text-uppercase" />
                                                            </td>
                                                        </tr>

                                                        {/* Mobile Number */}
                                                        <tr>
                                                            <td className="caption">Mobile Number</td>
                                                            <td>
                                                                <input type="text" name="cell_number" value={mobile_number} size="30" onChange={(e) => setMobileNumber(e.target.value)}
                                                                    className="text-uppercase" />
                                                            </td>
                                                        </tr>

                                                        {/* Home Number */}
                                                        {isShown1 && (
                                                            <tr>
                                                                <td className="caption">Home Number</td>
                                                                <td>
                                                                    <input type="text" name="home_number" value={home_number} size="30" onChange={(e) => setHomeNumber(e.target.value)}
                                                                        className="text-uppercase" />
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* Fax Number */}
                                                        <tr>
                                                            <td className="caption">Fax Number</td>
                                                            <td>
                                                                <input type="text" name="fax_number" value={fax_number} size="30" onChange={(e) => setFaxNumber(e.target.value)} className="text-uppercase" />
                                                            </td>
                                                        </tr>

                                                        {/* Email */}
                                                        <tr>
                                                            <td className="caption">Email<span style={{ color: "red" }}> *</span></td>
                                                            <td>
                                                                <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} className="text-uppercase" style={{ width: "210px" }} />
                                                            </td>
                                                        </tr>

                                                        {/* Physical Address */}
                                                        <tr>
                                                            <td className="caption">Physical Address</td>
                                                            <td><textarea name="physical_address" id="physical_address" rows="4" value={physical_address} onChange={(e) => setPhysicalAddr(e.target.value)}
                                                                cols="45" className="text-uppercase"></textarea></td>
                                                        </tr>

                                                        {/* Postal Address */}
                                                        <tr>
                                                            <td className="caption">Postal Address</td>
                                                            <td>
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td><textarea name="postal_address" id="postal_address" value={postal_address} onChange={(e) => setPostalAddr(e.target.value)}
                                                                                rows="4" cols="45"
                                                                                className="text-uppercase"></textarea></td>
                                                                            <td>
                                                                                <input type="button" name="same_as_physical"
                                                                                    value="Same as Physical"
                                                                                    onClick={(e) => setPostalAddr(physical_address)} />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>

                                                        {/* Web Address */}
                                                        {isShown2 && (
                                                            <tr id="web_address">
                                                                <td className="caption">Web Address</td>
                                                                <td>
                                                                    <input type="text" name="web_address" value={web_address} size="50/" onChange={(e) => setWebAddr(e.target.value)}
                                                                        className="text-uppercase" style={{ width: "210px" }} />
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {/* GPS Location */}
                                                        {/* {isShown2 && (
                                                            <tr id="gps">
                                                                <td className="caption">Add GPS Location</td>
                                                                <td className="text-center">Please add the business first.</td>
                                                            </tr>
                                                        )} */}

                                                        <tr>
                                                            <td className="caption">Additional Notes</td>
                                                            <td><textarea className="nw-cmpny-txtarea text-uppercase" name="notes" value={notes} onChange={(e) => setNotes(e.target.value)} style={{ width: "210px" }}></textarea></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="caption text-center" colSpan="3"><input type="submit" name="command" value="Save" />
                                                                <Link to={"/admin/search_company"} className="btn btn-result-open">
                                                                    <input type="button" name="command" value="Back" />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <p></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );
}

export default Company_edit