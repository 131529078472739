import VechileAccessories_menu from "./VechileAccessories_menu"
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom"
import axios from "../../axios";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Accessory_admin() {
    document.title = "Products | Seriti - Vehicle & Accessories";
    const { id } = useParams();
    const { template_id } = useParams();

    const [sAccessoryName, setsAccessoryName] = useState('')
    const [sTemplateSelect, setsTemplateSelect] = useState([])
    const [rResults, setrResults] = useState([])
    const [productId, setProductId] = useState('')
    const [reload, setReload] = useState(true)
    //----------------------------fetch initial data-----------------------------// 
    useEffect(() => {
        if (reload) {
            const payload = {
                id: id,
                template_id: template_id
            };
            axios
                .post("product/accessory_admin", payload)
                .then((response) => {
                    // console.log(response.data.data)
                    setsAccessoryName(response.data.data.sAccessoryName);
                    setsTemplateSelect(response.data.data.sTemplateSelect);
                    setrResults(response.data.data.rResults);
                    setReload(false);
                })
                .catch((response) => {
                    //alert(response.data);
                });
        }
    }, [reload, id, template_id]);

    //--------------------------remove accessories------------------------------//
    const handleRemove = (e) => {
        e.preventDefault();
        const payload = {
            product_id: productId,
            itemId: id  //accessory id
        }
        axios
            .post("product/remove_accessory", payload)
            .then((response) => {
                // console.log(response.data.data)
                setReload(true);
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            })
            .catch((response) => {
                //alert(response.data);
                if (response.response.data.status === 400)
                toast.error(response.response.data.data, {
                    position: toast.POSITION.TOP_CENTER
                });
            });
    }
    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <VechileAccessories_menu />

            <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                <tbody>
                    <tr valign="top" className="bg-white">
                        <td></td>
                        <td width="100%" className="bg-white">
                            <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                            <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                            <form method="post" action="" >
                                                <table className="defaultTbl" width="400" cellSpacing="1">
                                                    <tbody>
                                                        <tr><th colSpan={2} className="text-center">{sAccessoryName}</th></tr>
                                                        <tr><td className="caption">Template</td><td>
                                                            <select name="template_id" id="template_id" className="text-uppercase" style={{ width: '382px' }}>
                                                                <option value="0">No Template</option>
                                                                {sTemplateSelect.map((item) => {
                                                                    return (
                                                                        <option key={item.value} value={item.value} >
                                                                            {item.text}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </td>
                                                        </tr>

                                                        <tr className="caption">
                                                            <td colSpan={2} style={{ textAlign: "center" }}>
                                                                <input type="submit" name="command" value="Save" />
                                                                <Link to={"/products/accessories"} >
                                                                    <input type="button" name="cancel" value="Back" />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </form>

                                            <br />

                                            <form method="post" onSubmit={handleRemove}>
                                                <table className="defaultTbl" width="800" >
                                                    <tbody>
                                                        <tr><th colSpan="2" style={{ textAlign: "center" }}>Products Using {sAccessoryName}</th></tr>
                                                        {
                                                            rResults && rResults.length ? (
                                                                rResults.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{item.name}</td>
                                                                            <td style={{ textAlign: "right" }}>
                                                                                <input type="submit" value="Remove Accessory" onClick={() => {
                                                                                    if (window.confirm('Are you sure you want to delete this accessories? Click OK to continue.')) {
                                                                                        setProductId(item.id)
                                                                                    }
                                                                                }
                                                                                } />
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : <tr><td colSpan={2}>"There are no products using this accessory."</td></tr>
                                                        }
                                                        <tr><td colSpan={2} width="90%" className="caption" style={{ textAlign: "center" }}>
                                                            <Link to={"/products/add_accessory_to_product/" + id} state={{ sAccessoryName: sAccessoryName, template_id: template_id }}>
                                                                <input type="button" value="Add this accessory to a Vehicle" />
                                                            </Link>
                                                        </td></tr>

                                                    </tbody>
                                                </table>
                                            </form>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td></td>

                    </tr>
                </tbody>
            </table>
        </>
    )
}
export default Accessory_admin
