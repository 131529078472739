import { Link, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import Template from "../Components/Template";
import Report_img from '../Images/core/Reports.png';
import Admintrative_img from '../Images/core/Administration.png';
import axios from "../axios";
import Pagination from "../Components/Pagination";
import Loader from "../Components/Loader";
//import Basic_search from "../Components/Basic_search";

function Search_new() {

    const navigate = useNavigate();
    const location = useLocation();
    const [Usergroup, setUsergroup] = useState(0);
    const [UsergroupAll, setUsergroupAll] = useState([]);
    const [loader, setLoader] = useState(false);

    const [Userbranch, setUserbranch] = useState("");
    const [UserbranchAll, setUserbranchAll] = useState([]);
    const [RecentViewData, setRecentViewData] = useState([]);
    const [ServiceResponce, setServiceResponce] = useState("");
    const [TotalRecord, setTotalRecord] = useState("");

    var url = new URL(window.location.href);
    const page = url.searchParams.get('PageNo') || 1
    const limit = url.searchParams.get('Limit') || 10
    const [ServiceCall, setServiceCall] = useState(true);

    const [search_text, setAllText] = useState("");
    const [command, setCommand] = useState("Search");
    const [older, setOlder] = useState(0);

    //----------- set advance search property--------
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastName] = useState("");
    const [idnumber, setNumber] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [tradingname, setTradingnname] = useState("");
    const [reg, setReg] = useState("");
    const [stock, setStock] = useState("");
    const [Advancecommand, setAdvanceCommand] = useState("AdvancedSearch");
    const [order, setOrder] = useState("");

    const [flag, setFlag] = useState(0);

    const super_user = localStorage.getItem("super_user");
    const user_id = localStorage.getItem("UserId");
    const [AccessFlag, setAccessFlag] = useState(false);
    const [ReadOnly, setReadOnly] = useState(false);
    
    const older_new = localStorage.getItem("older");
    const created_from = localStorage.getItem("created_from");
    const created_to = localStorage.getItem("created_to");
    const incepted_from = localStorage.getItem("incepted_from");
    const incepted_to = localStorage.getItem("incepted_to");
    const division = localStorage.getItem("division");
    const department = localStorage.getItem("department");
    const new_search_text = localStorage.getItem("search_text");
    const user_department_access = localStorage.getItem("user_department_access_rights");
    //----------------check permission------------------

    useEffect(() => {
          const payload = {
              UserId: user_id,
              super_user: super_user,
              module_name: "Search"
          }
          axios
              .post("getRights", payload)
              .then((response) => {
                    if (response.data.status === 200) {
                      //console.log(response.data.data)
                        if (parseInt(response.data.data) === 0) {//None
                          alert(response.data.message);
                          navigate('/home'); 
                        } 
                        // else if (parseInt(response.data.data) === 1) {//Read
                        //   alert(response.data.message);
                        //   navigate('/home'); 
                        // }
                    }
              })
              .catch(() => {
                  //setLoginLoader(false);
              });
    }, []);

    useEffect(() => {
        const payload = {
            UserId: user_id,
            super_user: super_user,
            module_name: "Transactions"
        }
        axios
            .post("getRights", payload)
            .then((response) => {
                //console.log(response.data.data)
                // alert(response.data.message);
                if (response.data.status === 200) {
                    if (response.data.data === 0) {//None
                        //navigate('/home');
                        alert(response.data.message);
                    } else if (response.data.data === 1) {//Read
                        setAccessFlag(true); setReadOnly(true);
                    } else if (response.data.data === 2) {//Write
                        setAccessFlag(true); setReadOnly(false);
                    } else if (response.data.data === 3) {//Modify
                        setAccessFlag(true); setReadOnly(false);
                    } else if (response.data.data === 4) {//Admin
                        setAccessFlag(true); setReadOnly(false);
                    }
                }
            })
            .catch(() => {
                //setLoginLoader(false);
            });
    }, [user_id, super_user, navigate]);
    //---------------------- set pagination --------------------------
    //console.log(search_text);
    useEffect(() => {
        setServiceCall(true);
    }, [limit, page, search_text, order, firstname, lastname, idnumber, email, mobile, company, tradingname, reg, stock, flag]);

    //alert(page);
    useEffect(() => {
        if (ServiceCall && page) {
            // alert('sdsd');
            setLoader(true);
            const payload = {
                user_id: localStorage.getItem("UserId"),
                super_user: localStorage.getItem("super_user"),
               // division_id: localStorage.getItem("division_id"),
                command: command,
                //Advancecommand: Advancecommand,
                search_text: search_text != '' ? search_text : new_search_text,
                firstname: firstname,
                lastname: lastname,
                idnumber: idnumber,
                email: email,
                mobile: mobile,
                company: company,
                tradingname: tradingname,
                reg: reg,
                stock: stock,
                limit: limit,
                page: page,
                order: order,
                flag: flag,
                older: older_new,
                incepted_from: incepted_from,
                incepted_to: incepted_to,
                created_from: created_from,
                created_to: created_to,
                division: division,
                department: department,

            };
            //console.log(flag)
            axios
                .post("search/search_new", payload)
                .then((response) => {
                   // console.log(response);
                    if (location.state) {
                        setServiceResponce(location.state.data);
                        setTotalRecord(location.state.total_record);
                    } else {
                        setServiceResponce(response.data.data)
                    }
                    setLoader(false);
                })
                .catch(() => {
                    console.log('error');
                    setLoader(false);
                });

        }
    }, [ServiceCall, page, limit, search_text, firstname, lastname, idnumber, email, mobile, company, tradingname, reg, stock, flag])
    
    // [ServiceCall, page, limit, search_text, firstname, lastname, idnumber, email, mobile, company, tradingname, reg, stock, flag])
    //--------------- table head onclick in order by ------------
    const change_status = (event, click_text) => {
        //alert(click_text);
        setOrder(click_text);
    }

    useEffect(() => {
        if (location.state) {
            setServiceResponce(location.state.data);
            setTotalRecord(location.state.total_record);
            navigate("/search/search_new/1", {
                state: null,
            });
        }
    }, [location, navigate]);

    // console.log(ServiceResponce);
    //----------- get groups -----------------
    // useEffect(() => {
    //     axios
    //         .get("index/group")
    //         .then((response) => {
    //             setUsergroupAll(response.data.data);
    //         })
    //         .catch(() => {
    //             //setLoader(false);
    //         });
    // }, []);

    useEffect(() => {
        const payload = {
            user_department_access_rights: user_department_access,
        };
        axios
            .post("user_right_accesable_division", payload)
            .then((response) => {
                //console.log(response);
                setUsergroupAll(response.data.data);
            })
            .catch(() => {
                console.log('error');
               // setLoader(false);
            });

    }, [user_department_access]);

    //----------- get branch -----------------
    useEffect(() => {
        axios
            .get("index/branch/" + Usergroup)
            .then((response) => {
                if (response.status === 200) {
                    setUserbranchAll(response.data.data);
                }
            })
            .catch(() => {
                //setLoader(false);
            });
    }, [Usergroup]);

    //----------- get resent transcation -----------------
    useEffect(() => {
        const payload = {
            user_id: localStorage.getItem("UserId"),
            super_user: localStorage.getItem("super_user"),
            system_type: localStorage.getItem("system_type"),
        };
        axios
            .post("recent/view", payload)
            .then((response) => {
                // console.log(response);
                setRecentViewData(response.data.data);
            })
            .catch(() => {
                //setLoader(false);
            });
    }, []);

    //----- check user access -------------------------------
    const Check_user_access = (event, id) => {
        //console.log(id);
        const payload = {
            module_name: 'Transactions',
            user_id: localStorage.getItem("UserId"),
            access_id: id,
            super_user: localStorage.getItem("super_user"),
            Access: localStorage.getItem("Access"),
            Modules: localStorage.getItem("Modules"),
        };

        axios
            .post("check_rights", payload)
            .then((response) => {
                //console.log(response.data);
                if (response.data == 4) {
                    // alert('You do not have access to the page.')
                    navigate('/admin');
                } else {
                    // navigate('/home');
                }
            })
            .catch(() => {
                console.log('error');
            });
    };

    //------------------ basic search ----------------------
    const handleSubmit = (e) => {
      
        if(older == 1){
            localStorage.setItem("older", older);
        }else{
            localStorage.setItem("older", 0);
        }

        e.preventDefault();
        const payload = {
            user_id: localStorage.getItem("UserId"),
            super_user: localStorage.getItem("super_user"),
            //division_id: localStorage.getItem("division_id"),
            command: command,
            search_text: search_text,
            division: Usergroup,
            department: Userbranch,
            older: older,
            limit: limit,
            page: page,
        };
    //console.log(payload);
        axios
            .post("search/search_new", payload)
            .then((response) => {
                //console.log(response.data);
                navigate('/search/search_new/1', { state: response.data });
                // window.location.reload(false);
                const div_id1 = "block";
                setHide1(div_id1);
                const div_id3 = "none";
                setHide3(div_id3);
                const div_class1 = "ui-tabs-selected ui-state-active";
                setclass1(div_class1);
                const div_class3 = "";
                setclass3(div_class3);
                setFlag(0)

                localStorage.setItem("division", Usergroup);
                localStorage.setItem("department", Userbranch);
            })
            .catch(() => {
                console.log('error');
            });
    }

    //------------------ Advance Search ----------------------
    const advanceSearchSubmit = (e) => {
        if(older == 1){
            localStorage.setItem("older", older);
        }else{
            localStorage.setItem("older", 0);
        }
        e.preventDefault();
        const payload = {
            user_id: localStorage.getItem("UserId"),
            super_user: localStorage.getItem("super_user"),
           // division_id: localStorage.getItem("division_id"),
            command: Advancecommand,
           // command: command,
            search_text: '',
            division: Usergroup,
            department: Userbranch,
            firstname: firstname,
            lastname: lastname,
            idnumber: idnumber,
            email: email,
            mobile: mobile,
            company: company,
            tradingname: tradingname,
            reg: reg,
            stock: stock,
            older: older,
            limit: limit,
            page: page,
        };
        //console.log(payload);
        axios
            .post("search/search_new", payload)
            .then((response) => {
                //console.log(response.data);
                navigate('/search/search_new/1', { state: response.data });
                //window.location.reload(false);
                const div_id1 = "block";
                setHide1(div_id1);
                const div_id4 = "none";
                setHide4(div_id4);
                const div_class1 = "ui-tabs-selected ui-state-active";
                setclass1(div_class1);
                const div_class4 = "";
                setclass4(div_class4);
                setFlag(1)

                localStorage.setItem("division", Usergroup);
                localStorage.setItem("department", Userbranch);
            })
            .catch(() => {
                console.log('error');
            });
    }
    // console.log(TotalRecord);
    //--------------- div panel hide show ---------------
    const [div_id1, setHide1] = useState("block");
    const [div_id2, setHide2] = useState("none");
    const [div_id3, setHide3] = useState("none");
    const [div_id4, setHide4] = useState("none");

    const [div_class1, setclass1] = useState("ui-tabs-selected ui-state-active");
    const [div_class2, setclass2] = useState("");
    const [div_class3, setclass3] = useState("");
    const [div_class4, setclass4] = useState("");

    const handleClick = event => {
        const loc_div = event.currentTarget.id;
        //console.log(loc_div);
        if (loc_div == 'div1') {
            const div_id1 = "block";
            setHide1(div_id1);

            const div_id2 = "none";
            setHide2(div_id2);

            const div_id3 = "none";
            setHide3(div_id3);

            const div_id4 = "none";
            setHide4(div_id4);

            //------
            const div_class1 = "ui-tabs-selected ui-state-active";
            setclass1(div_class1);

            const div_class2 = "";
            setclass2(div_class2);
            const div_class3 = "";
            setclass3(div_class3);
            const div_class4 = "";
            setclass4(div_class4);
        }
        else if (loc_div == 'div2') {
            const div_id2 = "block";
            setHide2(div_id2);

            const div_id1 = "none";
            setHide1(div_id1);

            const div_id3 = "none";
            setHide3(div_id3);

            const div_id4 = "none";
            setHide4(div_id4);

            //------
            const div_class2 = "ui-tabs-selected ui-state-active";
            setclass2(div_class2);
            const div_class1 = "";
            setclass1(div_class1);
            const div_class3 = "";
            setclass3(div_class3);
            const div_class4 = "";
            setclass4(div_class4);
        }
        else if (loc_div == 'div3') {
            const div_id3 = "block";
            setHide3(div_id3);

            const div_id2 = "none";
            setHide2(div_id2);

            const div_id1 = "none";
            setHide1(div_id1);

            const div_id4 = "none";
            setHide4(div_id4);

            //------
            const div_class3 = "ui-tabs-selected ui-state-active";
            setclass3(div_class3);
            const div_class1 = "";
            setclass1(div_class1);
            const div_class2 = "";
            setclass2(div_class2);
            const div_class4 = "";
            setclass4(div_class4);

        }
        else if (loc_div == 'div4') {
            const div_id4 = "block";
            setHide4(div_id4);

            const div_id3 = "none";
            setHide3(div_id3);

            const div_id2 = "none";
            setHide2(div_id2);

            const div_id1 = "none";
            setHide1(div_id1);

            //------
            const div_class4 = "ui-tabs-selected ui-state-active";
            setclass4(div_class4);
            const div_class3 = "";
            setclass3(div_class3);
            const div_class1 = "";
            setclass1(div_class1);
            const div_class2 = "";
            setclass2(div_class2);
        }

    };

    return (

        <>
            <Template />
             {loader ? <Loader /> : null}
            <div className="custom-container">
                <table border="0" cellPadding={0} cellSpacing={0} width="100%" className="px-50px bg-white">
                    <tbody>
                        <tr valign="top">
                            <td className="h-100"></td>
                            <td>
                                <table border="0" cellPadding={0} cellSpacing={0} width="178" >
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table width="100%" border="0" cellPadding={0} cellSpacing={0} className="pr-50px">
                                                    <tbody>
                                                        <tr className="text-center">
                                                            <td>
                                                                <Link to="/reports/folders" >
                                                                    <img height="40" border="0" src={Report_img} />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                        <tr className="text-center">
                                                            <td>

                                                                <Link onClick={event => Check_user_access(event, 1)} >
                                                                    {/* <a href="" onClick={(e) => checkAccess('1')}> */}
                                                                    <img height="40" border="0" src={Admintrative_img} />
                                                                    {/* </a> */}
                                                                </Link>

                                                            </td>
                                                        </tr>
                                                      
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            {/* end menu */}
                            {AccessFlag ?
                                <>
                                    <td width="100%" className="px-5px">
                                        <div className="ui-tabs ui-widget ui-widget-content ui-corner-all" id="tabs" >
                                            <ul className="ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all">
                                                <li className={"ui-state-default ui-corner-top " + div_class1}><Link to="/search/search_new/1" id="div1" onClick={handleClick}>Search Results</Link></li>
                                                <li className={"ui-state-default ui-corner-top " + div_class2}><Link to="/search/search_new/2" id="div2" onClick={handleClick}>Recent Transaction</Link></li>
                                                <li className={"ui-state-default ui-corner-top " + div_class3}><Link to="/search/search_new/3" id="div3" onClick={handleClick} >Basic Search</Link></li>
                                                <li className={"ui-state-default ui-corner-top " + div_class4}><Link to="/search/search_new/4" id="div4" onClick={handleClick} >Advanced Search</Link></li>
                                            </ul>


                                            <div id="tabs-1" className="ui-tabs-panel ui-widget-content ui-corner-bottom " style={{ display: div_id1 }}>
                                                <table className="defaultTbl" width="100%" cellSpacing={1}>
                                                    <tbody>
                                                        {/* <fieldset disabled={ReadOnly}> */}
                                                            <tr className="caption">
                                                                <td width="1" className="text-center">
                                                                    <Link to="/search/create_new/0/0">
                                                                        <input type="button" value="Create New" disabled={ReadOnly} />
                                                                    </Link>
                                                                </td>
                                                                <td><Link onClick={event => change_status(event, 'type')}><strong>Type</strong></Link></td>
                                                                <td style={{ textAlign: 'center' }}><Link onClick={event => change_status(event, 'created')}><strong>Created</strong></Link></td>
                                                                <td style={{ textAlign: 'center' }}><Link onClick={event => change_status(event, 'transaction')}><strong>Transaction #</strong></Link></td>
                                                                <td><Link onClick={event => change_status(event, '')}><strong>Sales Ref#</strong></Link></td>
                                                                <td><Link onClick={event => change_status(event, 'name')}><strong>Client Name</strong></Link></td>
                                                                <td>ID/Reg #</td>
                                                                <td>Transaction<br />Status</td>
                                                                <td>Finance<br />Status</td>
                                                            </tr>

                                                            {ServiceResponce.length > 0 ?
                                                                ServiceResponce.map((item, index) => {

                                                                    return (
                                                                        <tr key={index}>
                                                                            <td width={150} style={{ textAlign: 'center' }}>
                                                                                <Link to={"/search/create_new/" + item.client_id + '/' + item.transaction_id}>
                                                                                    <input type="button" value="Copy" style={{ width: '55px' }}  disabled={ReadOnly} />
                                                                                </Link>

                                                                                {(item.edit_view_button) ?
                                                                                    <Link to={"/transaction/create_transaction/" + item.transaction_id}>
                                                                                        <input type="button" value={item.edit_view_button} style={{ width: '55px' }}  disabled={ReadOnly} />
                                                                                    </Link>

                                                                                    : null}
                                                                            </td>
                                                                            <td><strong>{item.type}</strong></td>
                                                                            <td style={{ textAlign: 'center' }}>{item.created_date}</td>
                                                                            <td style={{ textAlign: 'center' }}>
                                                                                <Link to={"/transaction/create_transaction/" + item.transaction_id}>
                                                                                    {item.transaction_id}
                                                                                </Link>
                                                                            </td>
                                                                            <td>{item.sales_reference}</td>
                                                                            <td>{item.client_name}</td>
                                                                            <td>{item.company_registration_number}</td>
                                                                            <td>{item.transcation_status}</td>
                                                                            <td>{item.finance_status}</td>
                                                                        </tr>
                                                                    );
                                                                }) :

                                                                <tr>
                                                                    <td colSpan={9} className="text-center"><em>No search results were found</em></td>
                                                                </tr>
                                                            }

                                                        {/* </fieldset> */}
                                                        <tr className="caption">
                                                            {/* <td colSpan={9} className="text-center">| <a href=""><strong> 1 </strong></a> |</td>
                                                    */}
                                                            <td colSpan={9} className="text-center">
                                                                {

                                                                    TotalRecord > 0 ?
                                                                        // <Pagination
                                                                        //     records={ServiceResponce.length}
                                                                        //     link={"search/search_new/1"}
                                                                        //     SelectedPage={page}
                                                                        //     //search={''}
                                                                        //     search={"&Search=" + search_text + "&firstname=" + firstname + "&lastname=" + lastname + "&idnumber=" + idnumber + "&email=" + email + "&mobile=" + mobile + "&company=" + company + "&tradingname=" + tradingname + "&reg=" + reg + "&stock=" + stock + "&incepted_from=" + incepted_from + "&incepted_to=" + incepted_to}
                                                                        //     NoOfRecords={TotalRecord}
                                                                        // /> : null

                                                                        <Pagination
                                                                        records={ServiceResponce.length}
                                                                        link={"search/search_new/1"}
                                                                        SelectedPage={page}
                                                                        //search={''}
                                                                        search={"&Search=" + search_text + "&firstname=" + firstname + "&lastname=" + lastname + "&idnumber=" + idnumber + "&email=" + email + "&mobile=" + mobile + "&company=" + company + "&tradingname=" + tradingname + "&reg=" + reg + "&stock=" + stock + "&older=" + older_new + "&created_from=" + created_from + "&created_to=" + created_to + "&incepted_from=" + incepted_from + "&incepted_to=" + incepted_to + "&division=" + division + "&department=" + department}
                                                                        NoOfRecords={TotalRecord}
                                                                    /> : null
                                                                }
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <fieldset disabled={ReadOnly}>
                                                <div id="tabs-2" className="ui-tabs-panel ui-widget-content ui-corner-bottom " style={{ display: div_id2 }}>
                                                    <table className="defaultTbl" width="100%" cellSpacing={1}>
                                                        <tbody>
                                                            <tr className="caption">
                                                                <td className="text-center">CREATED</td>
                                                                <td className="text-center">TRANSACTION #</td>
                                                                <td>CLIENT NAME</td>
                                                                <td>TRANSACTION STATUS</td>
                                                                <td>FINANCE STATUS</td>
                                                            </tr>

                                                            {RecentViewData.length > 0 ?
                                                                RecentViewData.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td style={{ textAlign: 'center' }}>{item.created}</td>
                                                                            <td style={{ textAlign: 'center' }}>
                                                                                <Link to={"/transaction/create_transaction/" + item.trascation_id}>
                                                                                    {item.trascation_id}
                                                                                </Link>
                                                                            </td>
                                                                            <td>{item.client_name}</td>
                                                                            <td>{item.transction_status}</td>
                                                                            <td>{item.finance_status}</td>
                                                                        </tr>
                                                                    );
                                                                }) :

                                                                <tr><td style={{ textAlign: "center" }} colSpan={5}><em>Trascation Not Found</em></td></tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>


                                                <div id="tabs-3" className="ui-tabs-panel ui-widget-content ui-corner-bottom " style={{ display: div_id3 }}>
                                                    {/* <Basic_search /> */}
                                                    <table width="100%" cellSpacing={1}>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <form name="form1" method="post" onSubmit={handleSubmit}>
                                                                        <input type="hidden" name="command" value={command} onChange={(e) => { setCommand(e.target.value) }} />
                                                                        <table width="600" border="0" cellPadding={3} cellSpacing={3} align="center">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="text-center" colSpan={4} ><strong>Enter a firstname, surname, ID, Registration number or transaction number</strong><br /><br /></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>&nbsp;</td>
                                                                                    <td><input type="text" name="search_text" size="15" className="text-uppercase" value={search_text} onChange={(e) => { setAllText(e.target.value) }} /></td>
                                                                                    <td><input type="submit" name="command" value="Search" /></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan={4}></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><b>GROUP</b></td>
                                                                                    <td>
                                                                                        <select name="division" id="division" className="text-uppercase" value={Usergroup} onChange={(e) => setUsergroup(e.target.value)} >
                                                                                            <option value='0'>--- SELECT A GROUP ---</option>
                                                                                            {UsergroupAll.map((item) => {
                                                                                                return (
                                                                                                    <option key={item.id} value={item.id}>
                                                                                                        {item.name}
                                                                                                    </option>
                                                                                                );
                                                                                            })}
                                                                                        </select>

                                                                                    </td>
                                                                                    <td colSpan={2}>&nbsp;</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><b>BRANCH</b></td>
                                                                                    <td>
                                                                                        <select name="department" id="department" className="text-uppercase" value={Userbranch} onChange={(e) => setUserbranch(e.target.value)}>
                                                                                            <option>--- SELECT A GROUP FIRST---</option>
                                                                                            {UserbranchAll.map((item) => {
                                                                                                return (
                                                                                                    <option key={item.id} value={item.id}>
                                                                                                        {item.name}
                                                                                                    </option>
                                                                                                );
                                                                                            })}
                                                                                        </select>
                                                                                    </td>
                                                                                    <td colSpan={2}>&nbsp;</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan={4}></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="text-center" colSpan={4}><input type="checkbox" name="older" value={older} onChange={(e) => setOlder(1)} />&nbsp;Show older than 2 months</td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>
                                                                    </form>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div id="tabs-4" className="ui-tabs-panel ui-widget-content ui-corner-bottom " style={{ display: div_id4 }}>
                                                    <table width="100%" cellSpacing={1}>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <form name="form1" method="post" onSubmit={advanceSearchSubmit}>
                                                                        <table width="600" border="0" cellPadding={3} cellSpacing={3} align="center">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td colSpan={4}></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><b>FIRST NAME</b></td>
                                                                                    <td><input type="text" name="firstname" className="text-uppercase" value={firstname} onChange={(e) => { setFirstname(e.target.value) }} /></td>
                                                                                    <td><b>LAST NAME</b></td>
                                                                                    <td><input type="text" name="lastname" className="text-uppercase" value={lastname} onChange={(e) => { setLastName(e.target.value) }} /></td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td><b>IDENTITITY NUMBER</b></td>
                                                                                    <td><input type="text" name="idnumber" className="text-uppercase" value={idnumber} onChange={(e) => { setNumber(e.target.value) }} /></td>
                                                                                    <td><b>MOBILE NUMBER</b></td>
                                                                                    <td><input type="text" name="mobile" className="text-uppercase" value={mobile} onChange={(e) => { setMobile(e.target.value) }} /></td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td><b>EMAIL ADDRESS</b></td>
                                                                                    <td><input type="text" name="email" className="text-uppercase" value={email} onChange={(e) => { setEmail(e.target.value) }} /></td>
                                                                                    <td colSpan={2}>&nbsp;</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td colSpan={4}>&nbsp;</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td><b>COMPANY NAME</b></td>
                                                                                    <td><input type="text" name="company" className="text-uppercase" value={company} onChange={(e) => { setCompany(e.target.value) }} /></td>
                                                                                    <td><b>TRADING AS</b></td>
                                                                                    <td><input type="text" name="tradingname" className="text-uppercase" value={tradingname} onChange={(e) => { setTradingnname(e.target.value) }} /></td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td><b>REGISTRATION NUMBER</b></td>
                                                                                    <td><input type="text" name="reg" className="text-uppercase" value={reg} onChange={(e) => { setReg(e.target.value) }} /></td>
                                                                                    <td><b>STOCK NUMBER</b></td>
                                                                                    <td><input type="text" name="stock" className="text-uppercase" value={stock} onChange={(e) => { setStock(e.target.value) }} /></td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td colSpan={4}>&nbsp;</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td><b>GROUP</b></td>
                                                                                    <td>
                                                                                        <select name="division" id="division" className="text-uppercase" value={Usergroup} onChange={(e) => setUsergroup(e.target.value)} >
                                                                                            <option value='0'>--- SELECT A GROUP ---</option>
                                                                                            {UsergroupAll.map((item) => {
                                                                                                return (
                                                                                                    <option key={item.id} value={item.id}>
                                                                                                        {item.name}
                                                                                                    </option>
                                                                                                );
                                                                                            })}
                                                                                        </select>

                                                                                    </td>
                                                                                    <td colSpan={2}>&nbsp;</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td><b>BRANCH</b></td>
                                                                                    <td>
                                                                                        <select name="department" id="department" className="text-uppercase" value={Userbranch} onChange={(e) => setUserbranch(e.target.value)}>
                                                                                            <option>-- Select a Group --</option>
                                                                                            {UserbranchAll.map((item) => {
                                                                                                return (
                                                                                                    <option key={item.id} value={item.id}>
                                                                                                        {item.name}
                                                                                                    </option>
                                                                                                );
                                                                                            })}
                                                                                        </select>
                                                                                    </td>
                                                                                    <td colSpan={2}>&nbsp;</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td colSpan={4}></td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td className="text-center" colSpan={4}><input type="checkbox" name="older" value={older} onChange={(e) => setOlder(1)} />&nbsp;Show older than 2 months</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="text-center" colSpan={4}>
                                                                                        <input type="submit" name="sub_command" value="Search" />
                                                                                        <input type="hidden" name="command" value={Advancecommand} onChange={(e) => { setAdvanceCommand(e.target.value) }} />
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>
                                                                    </form>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </td>
                                </>
                                : null}
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );

}

export default Search_new