import Admin_menu from "../Admin_menu";
import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { useParams } from "react-router";
import { Link,useNavigate } from 'react-router-dom';

function Edit_prime_interest() {
    document.title = "Administration | Seriti - Setup";
    const navigate = useNavigate();
    const { countryId } = useParams();
    const [countryName, setCountryName] = useState('');
    const [vat, setVat] = useState('')
    const [iRate, setIRate] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [command, setCommand] = useState('');
    // console.log(coutries)
    //----------------------------fetch record of selected country----------------------------//
    useEffect(() => {
        axios
            .get("/admin/edit_fetch_setup/" + countryId)
            .then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data.data)
                    var obj = response.data.data.rResults[0];
                    setCountryName(obj.country_name);
                    setVat(obj.vat);
                    setIRate(obj.interest_rate)
                    setCountryCode(obj.country_code)
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                // alert(response.response.data.message);
            });
    }, [countryId]);

    //---------------------handle validation------------------------//
    const handleValidation = () => {
        var flag = true;
        // var regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
        const regexp = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/
        if(vat === '' || iRate === '') 
		{
            alert('Please enter a valid amount.'); 
            flag = false;
        } else if(!regexp.test(vat)){
            alert('Please enter a valid amount.'); 
            flag = false;
        }
        return flag;
    };


    //--------------------------set value of form in array----------------------------------//
    const handleEdit = (e) => {
        e.preventDefault();
        
        const flag = handleValidation();//console.log(flag);
        if (flag) {
            const payload = {
                command: command,
                country_id: countryId,
                countryName: countryName,
                vat: vat,
                interest_rate: iRate,
                country_code: countryCode
            };
            axios
                .post("/admin/setup/update", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        navigate('/admin/setup');
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {

                });
        }
    };

    return (
        <>
            <Admin_menu />
            <form method="post" action="" encType="multipart/form-data" onSubmit={handleEdit}>
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">

                                <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center"><p></p>
                                                <table className="defaultTbl" cellSpacing={1} align="center">
                                                    <tbody>
                                                        <tr><th colSpan={2}>Update defaults for {countryName}</th></tr>
                                                        <tr><td className="caption">VAT<span style={{color:"red"}}> *</span></td>
                                                            <td><input type="text" validate="currency" name="vat" value={vat} onChange={(e) => { setVat(e.target.value) }} /></td>
                                                        </tr>

                                                        <tr><td className="caption">Prime Interest Rate<span style={{color:"red"}}> *</span></td>
                                                            <td>
                                                                <input type="text" validate="currency" name="interest_rate" value={iRate} onChange={(e) => { setIRate(e.target.value) }} />
                                                            </td>
                                                        </tr>

                                                        <tr><td className="caption">Country Code</td>
                                                            <td><input type="text" className="text-uppercase" name="country_code" value={countryCode} onChange={(e) => { setCountryCode(e.target.value) }} /></td>
                                                        </tr>
                                                        <tr className="caption">
                                                            <td colSpan={2} style={{ textAlign: "center" }}>
                                                                <input type="submit" name="command" value="Save" onClick={(e) => { setCommand('Save') }} />
                                                                <Link to={"/admin/setup"} className="btn btn-result-open">
                                                                    <input type="button" value="Cancel" />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr><tr>
                                            <td>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    )
}

export default Edit_prime_interest