import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link } from 'react-router-dom';
import { useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Categories() {
    document.title = "Administration | Seriti - Product Admin";
    const { parentId } = useParams();//console.log(parentId);
    const division_id = localStorage.getItem("division_id");
    const system_type = localStorage.getItem("system_type");

    const [allCategory, setAllCategory] = useState([]);

    const [sHeading, setsHeading] = useState('');

    const [order, setOrder] = useState(1);
    const [option, setOption] = useState('');
    const [command, setCommand] = useState('');
    const [reload, setReload] = useState(true);
    const [optionId, setOptionId] = useState('');

    const [isAddShown, setIsAddShown] = useState(true);
    const [isUpdateShown, setIsUpdateShown] = useState(false);

    //----------------------------heading type----------------------------------// 
    useEffect(() => {
        const payload = {
            division_id: division_id,
            system_type: system_type
        };
        axios
            .post("category_options", payload)
            .then((response) => {
                //   console.log(response.data.data)
                setsHeading(response.data.data.sCatName);
                //setAllCategory(response.data.data.rTypes);
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, [division_id, system_type]);

    //----------------------------get listing data----------------------------------// 
    useEffect(() => {
        if (reload) {
            const payload = {
                parent_id: parentId,
                category: sHeading
            };
            axios
                .post("category_admin", payload)
                .then((response) => {
                    // console.log(response.data.data)
                    setReload(false);
                    setAllCategory(response.data.data.rTypes);
                })
                .catch((response) => {
                    //alert(response.data);
                });
        }
    }, [reload, parentId, sHeading]);

    //---------------------handle validation------------------------//
    const handleValidation = () => {
        var flag = true;
        var regexp = /^(0|[1-9][0-9]*)$/;
        if (option === '') {
            alert('Please fill option fields!');
            flag = false;
        }
        if (order !== '') {
            if (!regexp.test(order)) {
                alert('Please enter valid order number!');
                flag = false;
            }
        }
        return flag;
    };

    //on edit tab set data fro update command
    const handleEdit = (id, opt, ord) => {
        // alert(id);
        setOrder(ord);
        setOption(opt);
        setOptionId(id);
        setIsAddShown(false);
        setIsUpdateShown(true)
    }

    //----------------------------add-------------------------------------//
    const handleSubmit = (e) => {
        e.preventDefault();
        // const flag = handleValidation();
        const flag = (command === 'Add' || command === 'Update') ? handleValidation() : true;
        // const flag = true;
        if (flag) {
            const payload = {
                command: command,
                id: parentId,
                order: order,
                option: option,
                option_id: optionId
            };
            axios
                .post("category_admin_update", payload)
                .then((response) => {
                    //console.log(response.data.data)
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setIsAddShown(true)
                    setIsUpdateShown(false)
                    setReload(true);
                    setOrder(1);
                    setOption('');
                    setOptionId('');
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    // console.log(response.response.data.data)
                    if (response.response.data.status === 400)
                    toast.error(response.response.data.data, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    };
    // console.log(order);console.log(option);
    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Admin_menu />
            <form method="post" action="" encType="multipart/form-data" onSubmit={handleSubmit}>
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">

                                <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center"><p></p>
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="600">
                                                    <tbody>

                                                        <tr><th colSpan="6" style={{ textAlign: 'center' }}>{sHeading} Catagory Administration</th></tr>
                                                        <tr className="caption">
                                                            <td></td>
                                                            <td>Order</td>
                                                            <td>Type Name</td>
                                                            {/* { (iParentID > 0) ? <td>Product Type</td> : <td>Categories</td> } */}
                                                            <td>Categories</td>
                                                            <td></td>
                                                        </tr>

                                                        {
                                                            allCategory && allCategory.length ? (
                                                                allCategory.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <input name="edit_role" type="button" value="Edit" onClick={(e) => handleEdit(item.id, item.option, item.sorder)} />
                                                                            </td>
                                                                            <td>{item.sorder}</td>
                                                                            <td>{item.option}</td>
                                                                            {(item.cat_type !== '') ? <td>{item.cat_type}</td> : null}
                                                                            <td>
                                                                                <Link to={"/admin/list_categories/" + item.id} state={{ loc: false, type: "group", parentId: parentId }} >

                                                                                    <input type="submit" name="command" value={item.num_cats} />
                                                                                </Link>
                                                                            </td>
                                                                            <td>
                                                                                {(item.active) ?
                                                                                    <input type="submit" name="command" value="Disable" onClick={(e) => { setCommand('Disable'); setOptionId(item.id) }} /> : <input type="submit" name="command" value="Enable" onClick={(e) => { setCommand('Enable'); setOptionId(item.id) }} />}
                                                                            </td>

                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : <tr><td colSpan={6}>No records</td></tr>
                                                        }
                                                        {/* add */}
                                                        <tr className="caption">
                                                            <td></td>
                                                            <td><input type="text" name="order" size="2" value={order} onChange={(e) => setOrder(e.target.value)} /></td>
                                                            <td><input type="text" name="option" size="50" value={option} onChange={(e) => setOption(e.target.value)} className="text-uppercase" /><span style={{ color: "red" }}> *</span></td>


                                                            <td colSpan={2}>
                                                                {isAddShown && <input type="submit" name="command" value="Add" onClick={(e) => setCommand('Add')} />}

                                                                {isUpdateShown && <input type="submit" name="command" value="Update" onClick={(e) => setCommand('Update')} />}

                                                                <Link to={"/admin/products"} >
                                                                    <input type="button" name="back" value="Back" />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                        {/* add */}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr><tr>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );
}

export default Categories