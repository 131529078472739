import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link } from 'react-router-dom';

function Profile_history() {
    document.title = "Administration | Seriti - Search User";
    const { user_id } = useParams()
    const [history, setHistory] = useState([]);
    const [user_name, setUserName] = useState('');

    //-----------------------show user profile history------------------//
    useEffect(() => {
        if (user_id > 0) {
            axios
                .get("admin/profile_history/" + user_id)
                .then((response) => {
                    // console.log(response.data.data)
                    setUserName(response.data.data.user_name)
                    setHistory(response.data.data.history);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch(() => {
                    //setLoginLoader(false);
                });
        }
    }, [user_id]);

    return (
        <>
            <Admin_menu />
            <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                <tbody>
                    <tr valign="top" className="bg-white">
                        <td></td>
                        <td width="60%" className="bg-white">
                            <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                            <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                            <form method="post" action="" >
                                                <input type="hidden" name="command" value="Search" />
                                                <table className="defaultTbl" width="300" cellSpacing="1">
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan="2" className="text-center">Password history for {user_name}</th>
                                                        </tr>

                                                        <tr className="caption">
                                                            <td>Change Date</td>
                                                            <td>User Details</td>
                                                        </tr>
                                                        {
                                                            history && history.length ? (
                                                                history.map((item) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{item.change_date_ymd}</td>
                                                                            <td> {item.name} </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : <tr><td colSpan={2}>"No record found for this search"</td></tr>
                                                        }
                                                        <tr className="caption">
                                                            <td colSpan="3" style={{ textAlign: 'center' }}>
                                                                <Link to={"/admin/profile/" + user_id} className="btn btn-result-open">
                                                                    <input type="button" value="Back" />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </form>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}
export default Profile_history