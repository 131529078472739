import React, { useState, useEffect } from "react";
import axios from "../axios";

function Bank_account_table_rows({rowsData, handleBankChange, instanceNumber, setBankchkDipFlag, chkPrimaryAc,setchkPrimaryAc}) {

   // const [document_type, setDocumentType] = useState("");
    const [AccountHolderAll, setAccountHolderAll] = useState([]);
    const [BankNameAll, setBankNameAll] = useState([]);
    const [AccountTypeAll, setAccountTypeAll] = useState([]);
    const [chk, setchk] = useState(1);
   // const [chkPrimaryAc, setchkPrimaryAc] = useState(true);
    //console.log(typeof chkPrimaryAc);
    const company_id = localStorage.getItem("company_id");

    useEffect(() => {
        document.title = 'Transactions | Seriti BW';
    }, []);
    
    //----------- get account holder name -----------------
    useEffect(() => {
        const payload = {
            company_id: company_id,
        };
       // console.log(payload);
        axios
            .post("fill_forms/featch_bank_account_holder", payload)
            .then((response) => {
                // console.log(response.data);
                if(response.data.account_holder_array != ''){
                   setAccountHolderAll(response.data.account_holder_array);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }, []);

    //----------- get bank name -----------------
    useEffect(() => {     
        axios
          .get("fill_forms/featch_bank_name")
          .then((response) => {
            //console.log(response.data.type_array);
            if(response.data.bank_name_array != ''){
               setBankNameAll(response.data.bank_name_array);
            }
          })
          .catch(() => {
             console.log('error');
          });
    }, []);

    //----------- get account type -----------------
    useEffect(() => {     
        axios
          .get("fill_forms/featch_account_type")
          .then((response) => {
            //console.log(response.data.type_array);
            if(response.data.account_type_array != ''){
               setAccountTypeAll(response.data.account_type_array);
            }
           
          })
          .catch(() => {
             console.log('error');
          });
    }, []);

    return(
        
        rowsData.map((data, index)=>{
            // console.log(data);
            // console.log(index);
            const {holder_name, bank_name, account_type, branch_name, account_no, branch_code}= data;
            const final_instance_number = instanceNumber+index+1;
            const temp_holder_name = `sub_${final_instance_number}_field_1237`;
            const temp_bank_name = `sub_${final_instance_number}_field_1238`;
            const temp_account_type = `sub_${final_instance_number}_field_1239`;
            const temp_branch_name = `sub_${final_instance_number}_field_1240`;
            const temp_account_no = `sub_${final_instance_number}_field_1241`;
            const temp_branch_code = `sub_${final_instance_number}_field_1242`;
            const temp_bank_primary_account = `sub_${final_instance_number}_field_1244`;

            return(
                <tr key={index}>
                <td>
                    <select name={temp_holder_name} id="sub_1_field_1237" style={{width: '200px'}} className="text-uppercase" value={holder_name} onChange={(evnt)=>(handleBankChange(index, evnt))}>  
                        <option value="">Select an option</option>
                        {AccountHolderAll.map((data) => {
                            return (
                            <option key={data.id} value={data.id}>
                                {data.holder_name}
                            </option>
                            );
                        })}
                    </select>
                </td>
                <td nowrap="nowrap" className="text-center">
                    <select name={temp_bank_name} id="sub_1_field_1238" className="text-uppercase" value={bank_name} onChange={(evnt)=>(handleBankChange(index, evnt))}>  
                        <option value="">Select an option</option>
                        {BankNameAll.map((data) => {
                            return (
                            <option key={data.id} value={data.id}>
                                {data.bank_name}
                            </option>
                            );
                        })}
                    </select>
                </td>
                <td nowrap="nowrap" className="text-center">
                    <select name={temp_account_type} id="sub_1_field_1239" className="text-uppercase" value={account_type} onChange={(evnt)=>(handleBankChange(index, evnt))}>  
                        <option value="">Select an option</option>
                        {AccountTypeAll.map((data) => {
                            return (
                            <option key={data.id} value={data.id}>
                                {data.type}
                            </option>
                            );
                        })}
                    </select>
                </td>
                <td nowrap="nowrap" className="text-center">
                    <input type="text" id="sub_1_field_1240" name={temp_branch_name} value={branch_name} className="text-uppercase" onChange={(evnt)=>(handleBankChange(index, evnt))}/>
                </td>
                <td nowrap="nowrap" className="text-center">
                    <input type="text" id="sub_1_field_1241" name={temp_account_no} value={account_no} className="text-uppercase" onChange={(evnt)=>(handleBankChange(index, evnt))}/>
                </td>
                <td nowrap="nowrap" className="text-center">
                    <input type="text" id="sub_1_field_1242" name={temp_branch_code} value={branch_code} className="text-uppercase" onChange={(evnt)=>(handleBankChange(index, evnt))}/>
                </td>
                <td nowrap="nowrap" className="text-center">
                    <input type="checkbox" id="current" name={temp_bank_primary_account} value={chkPrimaryAc}  onChange={(evnt) => { handleBankChange(index, evnt, 1,temp_bank_primary_account);setchkPrimaryAc(!chkPrimaryAc); setBankchkDipFlag(1); }} checked={chkPrimaryAc}  style={{ textTransform: "uppercase" }} />  
                </td>
                <td nowrap="nowrap" className="text-center">
                   &nbsp;
                </td>
            </tr>
            )
        })
   
    )
    
}
export default Bank_account_table_rows;