import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link } from 'react-router-dom';
import { useParams } from "react-router";

function Apl_index() {
    document.title = "Administration | Seriti - Group Admin";
    const location = useLocation()
    const { division } = location.state

    const { department } = useParams();
    const [aplData, setAplData] = useState([])
    useEffect(() => {
        const payload = {
            department_id: department
        };
        axios
            .post("/admin/group_admin/apl_index", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data.data.apl_index)
                    setAplData(response.data.data.apl_index)
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                alert(response.response.data.data);
            });
    }, []);

    return (
        <>
            <Admin_menu />
            {/* onSubmit={handleEdit} */}
            <form method="post" action="" encType="multipart/form-data" >
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">
                                <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center"><p></p>
                                                <table className="defaultTbl">
                                                    <tbody>
                                                        <tr align="center"><th colSpan="2" >Branch Details</th></tr>
                                                        <tr><td className="caption">Branch Code</td><td>{aplData.branch_code}</td></tr>
                                                        <tr><td className="caption">Branch Name</td><td>{aplData.branch_name}</td></tr>
                                                        <tr><td className="caption">Group Name</td><td>{aplData.group_name}</td></tr>
                                                        <tr><td className="caption">Start Date</td><td>{aplData.start_date}</td></tr>
                                                        <tr><td className="caption">End Date</td><td>{aplData.end_date}</td></tr>
                                                        <tr><td className="caption">Prime Adjustment</td><td>{aplData.prime_adjustment} %</td></tr>
                                                        <tr><td className="caption">Finance Term</td><td>{aplData.finance_term} Months</td></tr>
                                                        <tr className="caption"><td colSpan="2" className="caption text-center">
                                                            <Link to={'/admin/group_admin/branch_defaults/' + division +'/' +department}>
                                                                <input type="button" value="Back" /></Link>
                                                        </td></tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr><tr>
                                            <td align="center">
                                                <table className="defaultTbl">
                                                    <tbody>
                                                        <tr><th colSpan={4}>Branch Demographics</th></tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );

}

export default Apl_index