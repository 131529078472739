import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link } from 'react-router-dom';
import { useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../Components/Loader"

function Role_settings() {
    document.title = "Administration | Seriti - Manage Roles";
    const { roleId } = useParams()
    const [modules, setModules] = useState([]);
    const [rights, setRights] = useState('');
    const [roleName, setRoleName] = useState('');
    const UserId = localStorage.getItem("UserId");

    const [title, setTitle] = useState([])
    const [loader, setLoader] = useState(false);

    const handleChange = (index, event) => {
        let data = [...modules];
        data[index]["level"] = (event.target.value) ? event.target.value : 0;
        var new_arr = {};
        for (var i = 0; i < data.length; i++) {
            var newTitle = data[i]['title'].replace(" ", "_");
            new_arr[newTitle] = data[i]['level'];
        }
        setTitle(new_arr);
    };
    // console.log(title);
    useEffect(() => {
        axios
            .get("admin/manage_roles/getRolesRights/" + roleId)
            .then((response) => {
                //console.log(response.data.data)
                setRoleName(response.data.data.role)
                setModules(response.data.data.modules);
                setTitle(response.data.data.titleArr)
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch(() => {
                //setLoginLoader(false);
            });
    }, [roleId]);

    const handleEdit = (e) => {
        e.preventDefault();
        setLoader(true);
        const payload = {
            roleId: roleId,
            roleName: roleName,
            user_id: UserId,
            title: title
        };
        axios
            .post("/admin/manage_roles/updateRoleSettings", payload)
            .then((response) => {
                setLoader(false);
                if (response.data.status === 200) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch(() => {
                setLoader(false);
                console.log('error');
            });

    };

    return (
        <>
            <ToastContainer theme="colored" style={{ width: "450px" }} />
            <Admin_menu />
            {loader ? <Loader /> : null}
            <form method="post" action="" encType="multipart/form-data" onSubmit={handleEdit}>
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="500">
                                <table width="500" border={0} cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center"><p></p>
                                                <table className="defaultTbl" cellSpacing={1} align="center">
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan={7} style={{ textAlign: 'center' }}>Role Seetings</th>
                                                        </tr>
                                                        <tr className="caption">
                                                            <td colSpan={2}>MODULE</td>
                                                            <td>NONE</td>
                                                            <td>READ</td>
                                                            <td>WRITE</td>
                                                            <td>MODIFY</td>
                                                            <td>ADMIN</td>
                                                        </tr>
                                                        {
                                                            modules && modules.length ? (
                                                                modules.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td colSpan={2} className="caption">{item.title}</td>

                                                                            <td style={{ textAlign: 'center' }}>
                                                                                <input type="radio" name={item.title} value="0" onChange={(e) => { setRights(e.target.value); handleChange(index, e) }} defaultChecked={(item.level === 0) ? true : null} />
                                                                            </td>
                                                                            
                                                                            <td style={{ textAlign: 'center' }}>
                                                                                <input type="radio" name={item.title} value="1" onChange={(e) => { setRights(e.target.value); handleChange(index, e) }} defaultChecked={(item.level === 1) ? true : null} />
                                                                            </td>

                                                                            <td style={{ textAlign: 'center' }}>
                                                                                <input type="radio" name={item.title} value="2" onChange={(e) => { setRights(e.target.value); handleChange(index, e) }} defaultChecked={(item.level === 2) ? true : null} />
                                                                            </td>

                                                                            <td style={{ textAlign: 'center' }}>
                                                                                <input type="radio" name={item.title} value="3" onChange={(e) => { setRights(e.target.value); handleChange(index, e) }} defaultChecked={(item.level === 3) ? true : null} />
                                                                            </td>

                                                                            <td style={{ textAlign: 'center' }}>
                                                                                <input type="radio" name={item.title} value="4" onChange={(e) => { setRights(e.target.value); handleChange(index, e) }} defaultChecked={(item.level === 4) ? true : null} />
                                                                            </td>

                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : <tr><td colSpan={7}></td></tr>
                                                        }

                                                        <tr className="caption">
                                                            <td colSpan="5" >
                                                                <input type="text" name="name" className="text-uppercase" value={roleName} onChange={(e) => setRoleName(e.target.value)} style={{ width: "230px" }} />
                                                            </td>
                                                            <td>
                                                                <input type="submit" name="command" value="Save" />
                                                            </td>
                                                            <td >
                                                                <Link to={'/admin/roles'}>
                                                                    <input type="submit" name="command" value="Back" />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr><tr>
                                            <td>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody >
                </table >
            </form >
        </>
    );
}

export default Role_settings