import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link } from 'react-router-dom';
import { useParams } from "react-router";

function Group_defaults() {
    document.title = "Administration | Seriti - Group Admin";
    const { division } = useParams();
    const [groupDetails, setGroupDetails] = useState([]);
    const [branches, setBranches] = useState([]);
    const [sBranchName, setsBranchName] = useState('');
   
    useEffect(() => {
        const payload = {
            division_id: division
        };
        axios
            .post("/admin/affiliates/view_group", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data.data.group)
                    setsBranchName(response.data.data.sBranchName)
                    setGroupDetails(response.data.data.group);
                    setBranches(response.data.data.branches)
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                // alert(response.response.data.message);
            });
    }, [division]);
    // console.log(branches);
    return (
        <>
            <Admin_menu />
            <form method="post" action="" encType="multipart/form-data" >
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">

                                <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center"><p></p>
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="500">
                                                    <tbody>
                                                        <tr ><th colSpan={2} className="text-center">Group Details: {groupDetails.name}</th></tr>
                                                        <tr><td className="caption">Display Logo</td>
                                                            <td><img height="92" name="logo" src={groupDetails.logo} width="330" alt="" /></td></tr>
                                                        <tr><td className="caption" width="160">Group code</td><td>{groupDetails.code}</td></tr>
                                                        <tr><td className="caption">Group name</td><td>{groupDetails.name}</td></tr>
                                                        <tr><td className="caption">Country</td><td>{groupDetails.country_name}</td></tr>
                                                        <tr><td className="caption">Currency Used</td><td>{groupDetails.currency}</td></tr>
                                                        <tr><td className="caption">Default prime adjustment</td><td>{groupDetails.prime_adjustment}</td></tr>
                                                        <tr><td className="caption">Default financial term</td><td>{groupDetails.finance_term}</td></tr>
                                                        <tr><td className="caption">Service type</td><td>{groupDetails.service_type}</td></tr>
                                                        <tr><td className="caption">FSP Representative</td><td>{groupDetails.fsp_rep_name}</td></tr>
                                                        <tr><td className="caption">FSP Complaints Officer</td><td>{groupDetails.fsp_complaints}</td></tr>
                                                        <tr><td className="caption">FSP Compliance Officer</td><td>{groupDetails.fsp_compliance}</td></tr>
                                                        <tr><td className="caption">Transaction Type</td><td>{groupDetails.system_type}</td></tr>
                                                        <tr><td className="caption">Default Vehicle Coding System</td><td>{groupDetails.vehicle_coding_system1}</td></tr>
                                                        <tr><td className="caption">Share Contacts</td><td>{groupDetails.share_branches}</td></tr>
                                                        <tr><td className="caption">SLA Signature date</td><td>{groupDetails.signature_date}</td></tr>
                                                        <tr><td className="caption">Payover date</td><td>{groupDetails.payover_date}</td></tr>
                                                        <tr><td className="caption">Payover job name</td><td>{groupDetails.payover_job_name}</td></tr>
                                                        <tr><td className="caption">Start date</td><td>{groupDetails.start_date}</td></tr>
                                                        <tr><td className="caption">End date</td><td>{groupDetails.end_date}</td></tr>
                                                        <tr><td className="caption">Transaction Fee</td><td>{groupDetails.transaction_fee}</td></tr>

                                                        <tr>
                                                            <td className="caption text-center" colSpan={2} >
                                                                <Link to={'/admin/group_admin/group_fsp/' + groupDetails.affiliate_id}>
                                                                    <input type="submit" name="command" value="Back" style={{ align: 'left' }} />
                                                                </Link>

                                                                <Link to={'/admin/search/' + division}>
                                                                    <input type="submit" name="command" value="Users" style={{ align: 'right' }} />
                                                                </Link>

                                                                <Link to={'/admin/group_admin/group_defaults_edit/' + groupDetails.affiliate_id + '/' + division}>
                                                                    <input type="submit" name="command" value="Edit" style={{ align: 'right' }} />
                                                                </Link>
                                                            </td>
                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr><tr>
                                            <td>
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="400">
                                                    <tbody>
                                                        <tr ><th colSpan={6} className="text-center">{sBranchName}</th></tr>
                                                        <tr>
                                                            <td className="caption" width="40">&nbsp;</td>
                                                            <td className="caption" width="50">Code</td>
                                                            <td className="caption">Name</td>
                                                            <td className="caption" width="80">Tel#</td>
                                                            <td className="caption" width="80">Start date</td>
                                                            <td className="caption" width="80">End date</td>
                                                        </tr>
                                                        {/* division */}
                                                        {
                                                            branches && branches.length ? (
                                                                branches.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td style={{ align: 'center' }}>
                                                                                <Link to={'/admin/group_admin/branch_defaults/' + division + '/' + item.id}>
                                                                                    <input type="submit" name="command" value="View" />
                                                                                </Link></td>
                                                                            <td>{item.code}</td>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.telephone_number}</td>
                                                                            <td> {item.start_date}</td>
                                                                            <td> {item.end_date}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : <tr><td colSpan={6}>"No {sBranchName} were found for this group"</td></tr>
                                                        }
                                                        <tr>
                                                            <td colSpan={6} className="caption text-center" >
                                                                <Link to={'/admin/group_admin/add_branch/' + groupDetails.affiliate_id + '/' + division}>
                                                                    <input type="button" value="Add Branch" style={{ align: 'center' }} />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );
}

export default Group_defaults