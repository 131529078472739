import React, { useState, useEffect } from "react";
import Admin_menu from "../Admin_menu"
import axios from "../../axios";
import { Link } from 'react-router-dom';
import { useParams } from "react-router";

function Group_fsp() {
    document.title = "Administration | Seriti - Group Admin";
    const { affiliate } = useParams();
    const [affiliate_name, setAffiliate] = useState('');
    const [division, setDivision] = useState('');
    const [allDivisions, setAllDivisions] = useState([]);
    const [command, setCommand] = useState('');
    const [fetch, setFetch] = useState(true);
    const [divId, setDivId] = useState('');

    //----------------------------------get initial data------------------------------------//
    useEffect(() => {
        if (fetch === true) {
            axios
                .get("/admin/group_admin/affiliate_groups/" + affiliate)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data.data)
                        setAffiliate(response.data.data.sAffiliateName)
                        setDivision(response.data.data.sDivisionName)
                        setAllDivisions(response.data.data.aDiv)
                        setFetch(false);
                    }
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                })
                .catch((response) => {
                    alert(response.response.data.message);
                });
        }
    }, [fetch,affiliate]);

    //---------------------------enable, disable and delete---------------------------------//
    const handleEdit = (e) => {
        // console.log(command)
        // console.log(affiliate)
        // console.log(divId)
        e.preventDefault();
        const payload = {
            command: command,
            affiliate : affiliate,
            division: divId
        };
        axios
            .post("/admin/group_admin/group_enable_disable", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data)
                    setFetch(true);
                    setCommand('');
                    setDivId('');
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                alert(response.response.data.message);
            });

    };
    
    return (
        <>
            <Admin_menu />
            <form method="post" action="" onSubmit={handleEdit} encType="multipart/form-data" >
                <table width="100%" height="1024" id="mainContainer" border={0} cellSpacing={0} cellPadding={0} className="custom-body bg-white" >
                    <tbody>
                        <tr valign="top">
                            <td></td>
                            <td width="100%">

                                <table width="100%" border={0} cellSpacing={5} cellPadding={5}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center"><p></p>
                                                <table className="defaultTbl" cellSpacing={1} align="center" width="600">
                                                    <tbody>
                                                        <tr className="caption">
                                                            <th colSpan={5} style={{ textAlign: 'center' }}>{affiliate_name} {division}s</th>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: 40 }}></td>
                                                            <td>Name</td>
                                                            <td style={{ width: 40 }}></td>
                                                            <td style={{ width: 40 }}></td>
                                                        </tr>
                                                        {
                                                            allDivisions && allDivisions.length ? (
                                                                allDivisions.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <Link to={"/admin/group_admin/group_defaults/"+item.id} className="btn btn-result-open">
                                                                                <input type='submit' name='command' value='View' />
                                                                                </Link>
                                                                            </td>
                                                                            <td>{item.name}</td>
                                                                            <td>
                                                                                <input type="submit" name="command" value={item.sButtonDisEnableText} onClick={(e) =>  { setCommand(item.sButtonDisEnableText); setDivId(item.id) }} />
                                                                            </td>
                                                                            <td>
                                                                                <input type="submit" name="command" value='Delete' style={{ display: item.sDelButton }} onClick={() => {
                                                                                if (window.confirm('Are you sure you want to delete this collection? Click OK to continue.')) { setCommand('Delete'); setDivId(item.id) }    }  } />
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : <tr><td colSpan={5} style={{ textAlign: 'center' }}>"NO GROUPS FOUND."</td></tr>
                                                        }

                                                        <tr>
                                                            <td colSpan={5} style={{ textAlign: 'center' }}>
                                                            <Link to={"/admin/group_admin/group_defaults_add/"+affiliate} className="btn btn-result-open">
                                                                <input name="add_group" type="submit" value="Add Group" /></Link>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} style={{ textAlign: 'center' }}>
                                                                <Link to={"/admin/group_admin/affiliates"} className="btn btn-result-open">
                                                                    <input type="button" value="Back" /></Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );

}
export default Group_fsp