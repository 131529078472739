import VechileAccessories_menu from "./VechileAccessories_menu"
import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom"
import axios from "../../axios";
import Pagination from "../../Components/Pagination";

function Add_accessory_to_product() {
    document.title = "Products | Seriti - Vehicle & Accessories";
    const { accessory_id } = useParams();
    // console.log(accessory_id);
    const location = useLocation()
    
    const navigate = useNavigate();

    const [search_text, setSearchText] = useState('');
    const [category, setCategory] = useState('');
    const [allCategory, setAllCategory] = useState([]);
    const [sAccessoryName, setsAccessoryName] = useState('');
    const [template_id, settemplate_id] = useState('')
    const [searchData, setSearchData] = useState([]);
    // const [isShown, setIsShown] = useState(false);

    const [backBtnValue, setbackBtnValue] = useState('');

    const [command, setCommand] = useState('');
    const [productId, setProductId] = useState('');

    var url = new URL(window.location.href);
    const page = url.searchParams.get('PageNo') || 1
    const limit = url.searchParams.get('Limit') || 10
    const [ServiceCall, setServiceCall] = useState(true);
    const [flag, setFlag] = useState(0);
    const [TotalRecords, setTotalRecords] = useState(0);
    // alert(template_id);

    //----------------------------fetch initial data-----------------------------// 
    useEffect(() => {
            const payload = {
                id: accessory_id,
                template_id: template_id
            };
            axios
                .post("product/accessory_admin", payload)
                .then((response) => {
                    // console.log(response.data.data)
                    setsAccessoryName(response.data.data.sAccessoryName);
                    settemplate_id(response.data.data.iTemplateID)
                })
                .catch((response) => {
                    //alert(response.data);
                });
    }, [accessory_id, template_id]);

    //----------------------------dropdown of product----------------------------------// 
    useEffect(() => {
        axios
            .get("/product/dropdown")
            .then((response) => {
                // console.log(response.data.data)
                setAllCategory(response.data.data);
                setbackBtnValue("Back to " + sAccessoryName)
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
                //alert(response.data);
            });
    }, [sAccessoryName]);


    useEffect(() => {
        setServiceCall(true);
    }, [limit, page, command,search_text, category, productId, accessory_id, flag]);

    //-----------------------------search data with pagination-------------------------// 
    useEffect(() => {
        if (ServiceCall && page) {
            // alert('sdsd');
            const payload = {
                command: 'Search',
                search_text: search_text,
                category: category,
                product_id: productId,
                accessory_id: accessory_id,
                limit: limit,
                page: page,
            };
            axios
                .post("product/add_accessory_to_product", payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        // console.log(response.data)
                        // setIsShown(true);
                        setTotalRecords(response.data.total_record)

                        if (location.state) {
                            setSearchData(location.state.data);
                            setTotalRecords(location.state.total_record)
                        } else {
                            setSearchData(response.data.data);
                        }
                    }
                })
                .catch((response) => {
                });
        }
    }, [ServiceCall,command, page, limit, search_text, category, productId, accessory_id, flag, location])


    //-----------------------------search data----------------------------------------// 
   // console.log(accessory_id)
    const handleSubmit = (e) => {
        // setGroup(group)
        // alert(command)
        e.preventDefault();

        const payload = {
            command: command,
            search_text: search_text,
            category: category,
            product_id: productId,
            accessory_id: accessory_id,
            limit: limit,
            page: page,
        };
        axios
            .post("product/add_accessory_to_product", payload)
            .then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data.data)
                    
                    if (command === 'Add Accessory') {
                        // alert(command);
                        navigate('/products/accessory_admin/' + accessory_id + '/' + template_id);
                    }
                    if (command === 'Search') {
                        // setProductDetails(response.data.data)
                        setTotalRecords(response.data.total_record)
                        navigate('/products/add_accessory_to_product/'+ accessory_id,{state:response.data});
                    }
                    // setIsShown(true);
                    // setSearchData(response.data.data);
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
            .catch((response) => {
            });

    };

    useEffect(() => {
        if (location.state) {
            setSearchData(location.state.data);
            setTotalRecords(location.state.total_record);
            navigate('/products/add_accessory_to_product/'+ accessory_id, {
            state: null,
          });
        }
      }, [location,accessory_id, navigate]);

    return (
        <>
            <VechileAccessories_menu />
            <form method="post" action="" onSubmit={handleSubmit} >
                <table width="100%" height="1024" id="mainContainer" border="0" cellSpacing="0" cellPadding="0" className="custom-body bg-white">
                    <tbody>
                        <tr valign="top" className="bg-white">
                            <td></td>
                            <td width="100%" className="bg-white">
                                <div id="parent_wrapper" className="custom-parent_wrapper"></div>
                                <table width="100%" border="0" cellSpacing="5" cellPadding="5">
                                    <tbody>
                                        <tr>
                                            <td valign="top" align="center">

                                                <table className="defaultTbl" width="500" cellSpacing="1">
                                                    <tbody>
                                                        <tr><th colSpan={2} className="text-center">Search for a Product</th></tr>

                                                        <tr><td className="caption">Product Type</td><td>
                                                            <select name="category" id="category" className="text-uppercase" value={category} onChange={(e) =>
                                                                setCategory(e.target.value)} style={{ width: '382px' }}>
                                                                {/* onChange = {(e) => setBranch(e.target.value)} */}
                                                                <option value="0">-- Select option --</option>
                                                                {allCategory.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id} >
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </td></tr>

                                                        <tr>
                                                            <td className="caption">Search Criteria</td>
                                                            <td>
                                                                <input type="text" name="search_text" className="text-uppercase" value={search_text} onChange={(e) => setSearchText(e.target.value)} />
                                                            </td>
                                                        </tr>

                                                        <tr className="caption">
                                                            <td colSpan={2} style={{ textAlign: "center" }}>
                                                                <input type="submit" name="command" value="Search" onClick={(e) => setCommand('Search')} />

                                                                <Link to={"/products/accessory_admin/" + accessory_id + "/" + template_id} >
                                                                    <input type="button" name="cancel" value={backBtnValue} />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <br />
                                                {/* {isShown && ( */}
                                                    <table className="defaultTbl" width="800" >
                                                        <tbody>
                                                            <tr><th colSpan="2" style={{ textAlign: "center" }}>Products Found</th></tr>
                                                            <tr className="caption"><td>&nbsp;</td><td width="90%" style={{ textAlign: "center" }}>Product</td></tr>
                                                            {
                                                                searchData && searchData.length ? (
                                                                    searchData.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    {/* <Link to={"/products/accessory_admin/" + item.id+"/"+item.template_id}> */}
                                                                                    <input type="submit" value="Add Accessory" onClick={(e) => { setCommand('Add Accessory'); setProductId(item.id) }} />
                                                                                    {/* </Link> */}
                                                                                </td>

                                                                                <td>{item.name}</td>

                                                                            </tr>
                                                                        );
                                                                    })
                                                                ) : <tr><td colSpan={2}>"No results returned."</td></tr>
                                                            }
                                                            <tr><td colSpan={2} className="caption" style={{ textAlign: "center" }}>
                                                                <Link to={"/products/accessory_admin/" + accessory_id + "/" + template_id} >
                                                                    <input type="button" name="cancel" value={backBtnValue} />
                                                                </Link>
                                                            </td></tr>


                                                            <tr><td colSpan={4} width="90%" style={{textAlign:"center"}}>
                                                                {
                                                                    TotalRecords > 0 ?
                                                                        <Pagination
                                                                            records={searchData.length}
                                                                            link={'products/add_accessory_to_product/'+ accessory_id}
                                                                            SelectedPage={page}
                                                                            //search={''}
                                                                           search={"&command="+ command +"&search_text=" + search_text  + "&category=" + category + "&product_id=" + productId + "&accessory_id=" + accessory_id}
                                                                            NoOfRecords={TotalRecords}
                                                                        /> : null
                                                                }
                                                            </td></tr>

                                                        </tbody>
                                                    </table>
                                            

                                                {/* )} */}
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>

                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );
}

export default Add_accessory_to_product